/* ----------------------------------------------------------
CONTACT
---------------------------------------------------------- */
.contact-map-cont{
    float:left;
    width:100%;
    padding:30px 0px 30px 0px;
}
.contact-map-cont iframe{
    width: 100%;
    margin: auto;
}
.contact-info{
    float:left;
    padding:0px 0px 0px 20px;
    width:50%;
    box-sizing:border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.contact-info h2{
    padding:0px 0px 0px 0px!important;
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'latobold';
    font-size: 22px;
    color: #7e7e7e;
}
.contact-info p{
    float:left;
    color: #7e7e7e;
    font-family: "latoregular";
    font-size: 14px;
    text-align:left;
    padding:10px 0px 20px;
    line-height:140%;
    width:100%;
}

.contact-info span{
    float:left;
    padding:1px 0px 0px 20px;
    margin:5px 0px 0px;
    height:25px;
    color: #7e7e7e;
    font-family: "latoregular";
    font-size: 14px;
    text-align:left;
    width:100%;
}
span.contact-phone{
    background:url("../../images/contact-icons.png") no-repeat 0px 0px;
}
span.contact-email{
    background:url("../../images/contact-icons.png") no-repeat 0px -25px;
}
span.contact-twitter{
    background:url("../../images/contact-icons.png") no-repeat 0px -50px;
}
.contact-info span a{
    color: #818181;
    text-decoration:none;
}
.contact-info span a:hover{
    text-decoration:underline;
}
