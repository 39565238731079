@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';



#cake-top-banner {
  // @include top-banner('/images/landing-pages/banners/cake-top-banner.jpg', #fff, '/images/landing-pages/banners/cake-top-banner-md.jpg');
	// @extend %background-property;
  .cake-top-banner-flex-center {
    height: calc(100vh - 70px);
    @extend %flex-center;
    .cake-logo {
      width: 220px;
      margin-bottom: 10px;
      @include mquery (600px) {
        width: 170px;
        margin-top: 40px;
      }
    }
    .cake-subtitle {
      line-height: 35px;
      font-family: 'latolight';
      font-size: 30px;
      text-align: center;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}

.cake-top-quote {
  @include mquery (600px) {
    padding: 60px 0 0;
  }
}

.cake-wholesale-text {
  font-size: 24px;
}

.cake-title {
  font-size: 36px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

.cake-register-title {
  font-size: 30px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

.cake-big-title {
  font-size: 40px;
  padding-bottom: 32px;
  @include mquery (600px) {
    font-size: 30px;
  }
}


// #cake-paralax-sec {
//   @include parallax-sec('/images/landing-pages/banners/cake-paralax-banner.jpg', '/images/landing-pages/banners/cake-paralax-banner-sm.jpg');
//   .cake-paralax-layer {
//     @include parallax-layer(rgba(1, 1, 1, 0.5));
//   }
// }


#cake-intro-sec {
  padding: 60px 0;
  .cake-intro-text {
    padding: 0 20px;
  }
}

#cake-quote-sec {
  padding: 60px 0;
  @include mquery(600px) {
    padding: 90px 0 0;
  }
}

.cake-dashed-border {
  border: 6px dashed #d9db2f;
  padding: 40px 30px;
  position: relative;
}

.cake-icon {
  position: absolute;
  top: -98px;
  right: -100px;
  width: 100px;
  @include mquery(1280px) {
    right: -6px;
  }
}

#cake-story-sec {
  padding: 60px 0;
  @include mquery(1200px) {
    padding: 0;
  }

  .cake-list {
    list-style: disc inside;
    font-size: 17px;
    li:nth-child(1), li:nth-child(2), li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
      list-style-image: url('/images/landing-pages/cake-list-dotts.png');
    }
    li {
      margin-bottom: 10px;
      line-height: 1.7rem;
    }
    li::before {
      content: "";
      padding-left: 10px;
    }
  }
  .cake-story-text {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 60px 0 0;
    }
  }
  .cake-story-text-2 {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 0 0 60px;
    }
  }

  .cake-revenue-bk {
  	@extend %background-property;
    background-image: url(/images/landing-pages/banners/cake-half-banner-1.jpg);
    height: 592px;
    @extend %flex-center;
    @include mquery(768px) {
      height: 600px;
    }
    @include mquery(600px) {
      height: 310px;
    }
  }

  .cake-story-bk {
    @extend %background-property;
    background-image: url(/images/landing-pages/banners/cake-half-banner-2.jpg);
    height: 520px;
    @extend %flex-center;
  }
}

// #cake-market-sec {
//   padding: 60px 0;
//   @include mquery(1200px) {
//     padding: 0 0 60px;
//   }
//   .cake-market-bk {
//     @extend %background-property;
//     background-image: url(/images/landing-pages/banners/cake-half-banner-3.jpg);
//     height: 630px;
//     @extend %flex-center;
//     @include mquery(768px) {
//       height: 520px;
//     }
//     @include mquery(600px) {
//       height: 340px;
//     }
//   }
//   .cake-market-text {
//     padding: 0 20px;
//     @include mquery(1200px) {
//      padding: 60px 0 0;
//     }
//   }
// }

.cake-link {
  @include link(#d8da2f, darken(#af3e19, 2.5), underline);
}

.subscription_link_color {
  @include subscription_link_color(#d8da2f, darken(#d8da2f, 2.5), underline)
}

.cake-button-style {
  @include button-style(#d8da2f, #fff, #bfbf2b, #fff, .8);
}
