/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
FOOTER
---------------------------------------------------------- */
#footer {
  float: left;
  width: 100%;
  margin: 0px;
  background-color: #ebebeb;
  text-align: center;
  padding: 5px 0 30px;
  /* Footer inner div ends here */
}

#footer .warning-statement-section {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer .warning-statement-section .link-wrap {
  padding-top: 25px;
}

#footer .find-out-more-section {
  background-color: #ebebeb;
  color: #fff;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#footer .find-out-more-section .find-out-more-text p {
  color: #333;
  font-size: 14px;
  line-height: 21px;
}

#footer .footer-main {
  min-height: 350px;
  display: block;
  margin: 0 auto;
  padding-top: 30px;
}

#footer > div {
  /* Footer navigation ends here */
  /* Footer newsletter ends here */
  /* Footer copyright ends here */
}

#footer > div .footer-navigation {
  width: 100%;
  max-width: 770px;
  /* Media queries */
}

#footer > div .footer-navigation > ul {
  float: left;
  width: 33.3%;
  margin: 0px;
  padding: 0px 30px 0px 10px;
}

#footer > div .footer-navigation > ul > li {
  float: left;
  width: 100%;
  margin: 8px 0px 0px 0px;
  list-style: none;
  position: relative;
  background-repeat: no-repeat;
  background-position: left 4px;
}

#footer > div .footer-navigation > ul > li:first-of-type {
  background-image: none;
  padding-left: 0px;
  padding-bottom: 5px;
  margin-top: 0px;
}

#footer > div .footer-navigation > ul > li > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 10px 0px;
  font-family: 'latoregular';
  font-size: 16px;
  color: #333;
}

#footer > div .footer-navigation > ul > li > a {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 14px;
  color: #6b7984;
  text-decoration: none;
}

#footer > div .footer-navigation > ul > li > a:hover {
  color: #333;
}

@media all and (max-width: 1210px) {
  #footer > div .footer-navigation {
    max-width: 100%;
    text-align: center;
  }
  #footer > div .footer-navigation > ul {
    display: inline-block;
    float: none;
    max-width: 220px;
    vertical-align: top;
  }
}

@media all and (max-width: 1210px) and (max-width: 705px) {
  #footer > div .footer-navigation > ul {
    width: 100%;
    max-width: 100%;
    margin: 30px 0px 0px 0px;
  }
}

@media all and (max-width: 1210px) and (max-width: 705px) and (max-width: 440px) {
  #footer > div .footer-navigation > ul > li {
    width: 100%;
  }
}

@media all and (max-width: 1210px) {
  #footer > div .footer-navigation {
    padding-bottom: 30px;
    border-right: 0px;
    border-style: solid;
    border-color: #ccc;
    border-bottom-width: 2px;
  }
}

#footer > div .footer-newsletter {
  float: right;
  margin: 0px 0px 0px 10px;
}

@media all and (max-width: 1200px) {
  #footer > div .footer-newsletter {
    max-width: 390px;
    text-align: center;
    display: block;
    float: none;
    margin: 30px auto;
  }
}

#footer > div .footer-newsletter .mn-footer-newsletter-header {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

@media all and (max-width: 1210px) {
  #footer > div .footer-newsletter .mn-footer-newsletter-header {
    text-align: center;
  }
}

#footer > div .footer-newsletter > form {
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin-top: 20px;
  display: inline-block;
}

#footer > div .footer-newsletter > form .footer-newsletter-input {
  float: left;
  width: 100%;
  height: 45px;
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  margin: 20px 0px 0px 0px;
  padding: 0px 10px;
  font-family: 'latolight';
  font-size: 16px;
  background-color: #7fb0d3;
  border-width: 0px;
  border-style: solid;
  border-color: #469edc;
  border-bottom-width: 2px;
  /* Media queries */
}

@media all and (max-width: 1210px) {
  #footer > div .footer-newsletter > form .footer-newsletter-input {
    float: none;
    display: inline-block;
  }
}

#footer > div .footer-newsletter > form > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 5px 0px 0px 0px;
  font-family: 'latolight';
  font-size: 18px;
  color: #333;
}

#footer > div .footer-newsletter > form .footer-subscribe-button {
  display: inline-block;
  cursor: pointer;
  margin: 40px 0px 0px 0px;
  padding: 10px 120px 10px 10px;
  border: none;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  font-size: 18px;
  font-family: 'latolight';
  color: #ffffff;
  background-color: #469edc;
}

#footer > div .footer-newsletter > form .footer-subscribe-button:hover {
  background-color: #4095d0;
}

#footer > div .footer-newsletter > form .footer-subscribe-button:after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  margin: -5px 0px 0px -5px;
  height: 200%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
  transform: rotate(-20deg);
  transform-origin: 0 0 0;
  width: 30%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#footer > div .footer-newsletter > form .footer-subscribe-button:hover:after {
  width: 40%;
}

#footer > div .footer-newsletter > form .footer-subscribe-button:before {
  font-size: 140%;
  height: 100%;
  left: auto;
  line-height: 3;
  position: absolute;
  right: 10px;
  top: 0px;
  width: 60px;
  z-index: 2;
  content: "";
  background-image: url(../../images/footer-news-arrow.png);
  background-repeat: no-repeat;
  background-position: right 15px top 15px;
}

#footer > div .form-inline {
  margin-top: 20px;
}

#footer > div .form-control {
  height: 51px;
  width: 260px;
  padding: 10px;
}

@media all and (max-width: 736px) {
  #footer > div .form-control {
    border-radius: 0;
    text-align: center;
  }
}

#footer > div .footer-btn-default {
  height: 51px;
  background-color: #469edc;
  color: white;
}

#footer > div .footer-copyright {
  float: left;
  width: 100%;
  margin: 30px 0px 30px 0px;
  padding: 20px 0px 0px 0px;
  text-align: center;
}

#footer > div .footer-copyright > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  max-width: 978px;
  font-size: 13px;
  font-family: 'latolight';
  color: #6b7984;
  line-height: 18px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  #footer > div .footer-copyright > p {
    font-size: 14px;
    text-align: center;
  }
}

@media all and (max-width: 736px) {
  #footer > div .footer-copyright > p {
    font-size: 13px;
    text-align: center;
  }
}

#footer > div .footer-copyright .logo-grey {
  float: left;
  margin-right: 30px;
  margin-top: 6px;
  margin-bottom: 10px;
}

@media all and (max-width: 1210px) {
  #footer > div .footer-copyright .logo-grey {
    margin-bottom: 30px;
    float: none;
    display: inline-block;
    text-align: center;
  }
}

@media all and (max-width: 736px) {
  #footer > div .footer-copyright .logo-grey {
    margin-left: 0;
    margin: 10px;
  }
}

.footer-content {
  padding: 10px;
  background-color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  font-size: 15px;
  opacity: 0.75;
}

@media all and (max-width: 736px) {
  .footer-content {
    padding: 1rem 4.5rem 1rem 1rem;
    text-align: left;
    font-size: .75rem;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .footer-content {
    padding: 1rem 4.5rem 1rem 1rem;
    font-size: 17px;
    text-align: left;
  }
}

.footer-social-btns {
  margin: 0px;
  padding: 0px;
}

.footer-social-btns > h1 {
  margin: 20px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #333;
}

@media all and (max-width: 1210px) {
  .footer-social-btns > h1 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }
}

.footer-social-btns > div {
  margin-top: 20px;
  padding: 0px;
}

.footer-social-btns > div > a {
  float: left;
  height: 25px;
  width: 24px;
  margin-right: 20px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media all and (max-width: 1210px) {
  .footer-social-btns > div > a {
    margin-left: 20px;
  }
}

.footer-social-btns > div > a.footer-fb {
  background-image: url(../../images/icons/005-facebook-logo.png);
  background-repeat: no-repeat;
}

.footer-social-btns > div > a.footer-tw {
  background-image: url(../../images/icons/twitter-1.png);
  background-repeat: no-repeat;
}

.footer-social-btns > div > a.footer-ins {
  background-image: url(../../images/icons/001-instagram.png);
  background-repeat: no-repeat;
}

.footer-social-btns > div > a.footer-li {
  background-image: url(../../images/icons/linkedin.png);
  background-repeat: no-repeat;
}

.footer-social-btns > div > a.footer-g {
  background-image: url(../../images/icons/003-google-plus.png);
  background-repeat: no-repeat;
}

@media all and (max-width: 1210px) {
  .footer-social-btns {
    display: inline-block;
    float: none;
    padding-bottom: 20px;
  }
}

/* Footer social buttons ends here */
@media all and (max-width: 736px) {
  .mn-input-group-btn {
    white-space: normal;
    display: block;
  }
}

@media all and (max-width: 736px) {
  .mn-btn-default {
    width: 260px;
    border-radius: 0;
    margin-top: 10px;
  }
}
