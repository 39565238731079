/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#dit-top-banner-sec {
  background: url(/images/landing-pages/dit-Newimage.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: table;
}

@media all and (max-width: 768px) {
  #dit-top-banner-sec {
    background-image: url(/images/landing-pages/dit-Newimage-mobile.jpg);
  }
}

.dit-logo {
  width: 300px;
  margin-bottom: 20px;
}

.dit-align-top-table {
  height: 100%;
  width: 750px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}

@media all and (max-width: 1024px) {
  .dit-align-top-table {
    width: 100%;
  }
}

.dit-link {
  color: #105090;
  text-decoration: underline;
}

.dit-link:hover {
  color: #0f4b87;
}

.dit-link-white {
  color: #fff;
  text-decoration: underline;
  transition: .5s;
}

.dit-link-white:hover {
  background-color: #ccc;
}

.dit-button-style {
  background-color: #105090;
  border: 1px solid #105090;
}

.dit-button-style:hover {
  background-color: #0f4b87;
  color: #fff;
}

.dit-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #105090;
  border: 1px solid #105090;
  color: #fff;
  transition: all 300ms ease;
  animation: bounce 3s;
}

.dit-view-offer-btn:hover {
  background-color: #0f4b87;
}

#dit-second-section {
  background-color: #105090;
  padding: 60px 0;
}

#dit-third-section {
  background-color: #fff;
}

#dit-third-section .dit-third-row {
  overflow: hidden;
}

#dit-third-section .dit-third-half-img {
  background: url(/images/landing-pages/dit-sheep-eating.jpg) no-repeat;
  background-size: cover;
  height: 600px;
  background-position: right;
}

@media all and (max-width: 1500px) {
  #dit-third-section .dit-third-half-img {
    height: 650px;
  }
}

@media all and (max-width: 1380px) {
  #dit-third-section .dit-third-half-img {
    height: 730px;
  }
}

#dit-third-section .dit-sec-three-content {
  padding: 50px;
}

@media all and (max-width: 768px) {
  #dit-third-section .dit-sec-three-content {
    padding: 50px 15px;
  }
}

#dit-paralax-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/dit-top-banner-car.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

.dit-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

#dit-last-table-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #dit-last-table-sec {
    flex-wrap: wrap;
  }
}

.dit-last-form-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 460px;
  width: 32%;
}

@media all and (max-width: 1420px) {
  .dit-last-form-cnt {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  .dit-last-form-cnt {
    width: 100%;
    order: 3;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.dit-cow-looking-img {
  background: url(/images/landing-pages/dit-half-cow.jpg) no-repeat center;
  background-size: cover;
  width: 34%;
  height: 460px;
}

@media all and (max-width: 1024px) {
  .dit-cow-looking-img {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  .dit-cow-looking-img {
    height: 180px;
  }
}

.dit-sheep-looking-img {
  background: url(/images/landing-pages/dit-sheep-looking.jpg) no-repeat center;
  background-size: cover;
  height: 460px;
  width: 34%;
}

@media all and (max-width: 1024px) {
  .dit-sheep-looking-img {
    width: 50%;
    order: 1;
  }
}

@media all and (max-width: 500px) {
  .dit-sheep-looking-img {
    height: 180px;
  }
}

.last-table-title {
  max-width: 601px;
}

@media all and (max-width: 768px) {
  .last-table-title {
    max-width: 391px;
  }
}
