/* General variables */
$blue-hover-text: #469edc;
$tooltip-color: #7089a3;
$default-text-color: #7e7e7e;
$default-blue-color: #469edc;
$default-pink-color: #de518e;
$default-green-color: #68cfad;
$default-white-color: #fff;
$form-group-bg: #f3f5f6;
$form-group-helper-color: #ffffff;
$form-group-helper-bg: #9ec0d1;
$fg-helper-tooltip-bg: #6c99af;
$input-field-text: #7e7e7e;
$input-field-border: #dfe6eb;
$default-submitBtn-color: #ffffff;
$fb-color: #3b5998;
$linkedin-color: #0077b5;
$main-columns-bg: #ffffff;
$default-save-btn-bg: #aad155;
$default-back-btn-bg: #6f8899;
$default-section-title: #7e7e7e;
$error-tooltip-bg: #e04b71;
$text-link-hover: #5ba9e0;
$img-hover-popup-bg: #e9e9e9;
$img-overlay: rgba(0,0,0,0.5);
$txt-white: #ffffff;
$txt-main-color: #469edc;
$main-bg: #469edc;
$main-darker-bg: #2c6892;
$main-light-bg: #7fb0d3;
$main-light-worning-bg: #64a0ca;
$txt-light-main-color: #6195b9;
$txt-green-color: #95c232;

// Global borders variables
$white-border: #ffffff;
$main-color-border: #469edc;
$light-grey-border-opacity: rgba(179, 179, 179, 0.3);

// Global links variables
$main-blue-link-hover: #469edc;

$default-info-tooltip: #e0edf6;
$default-info-tooltip-text: #6195b9;

// Datepicker variables
$datepicker-bg: #f3f5f6;
$datepicker-light-bg: #dee5e8;
$datepicker-main-bg: #469edc;
$datepicker-color: #7e7e7e;
$datepicker-main-color: #469edc;
$datepicker-lightcolor: #ffffff;
$datepicker-light-border: #dfe6eb;


/* Default rightcol widget */
$drw-title-bg: #8f9ea9;

/* Header & Home Top Wrapper variables */
$main-header-notification-bg: #34495e;
$htw-text: #ffffff;
$htw-btn-border: #469edc;
$htw-btn-blue-hover: #5ba9e0;
$htw-btn-bg: #469edc;
$dark-header-bg: #333333;
$mobile-nav-bg: #252424;
$mobile-nav-separator: #3E3E3E;

/* Home Sections variables */
$section-bg: #ffffff;
$home-section-title-color: #3f3e3e;
$home-section-title-bg: #469edc;

$bright-home-section-title-color: $default-text-color;
	/* Offer tiles variables */
	$offer-tile-border: #efefef;
	$offer-tile-bg: #ffffff;
	$offer-tile-hover-bg: #fafafa;
	$hot-viewOfferBtn-bg: #469edc;
	$offer-tile-title-color: #5f5f5f;
	$offer-tile-text-color: #666666;
	$offer-tile-details-color: #469edc;
	/* Additional offers section variables */
	$aos-leftcont-bg: white;
	$aos-rightcont-bg: white;
	$additional-offer-text-color: black;
	$view-additional-offers-btn: #469edc;
	/* Warning statement variables */
	$ws-section-bg: #ffffff;
	$warning-statement-bg: #f0f7e2;
	$ws-exclamation-bg: #aad155;
	$ws-text: #729525;

/* Register & Login pages variables */
$reg-log-col-bg: #ffffff;
$faq-section-text: #666666;

/* Settings pages variables */
$settings-sidebar-nav-bg: #ffffff;
$settings-sidebar-nav-color: #6f8899;

/* User profile variables */
$up-subtitle-color: #b3b3b3;
$up-tabs-links-color: #ffffff;
$up-tabs-bg: #8f9ea9;
$up-tabs-border: #7c8b97;
$completeness-bar-bg: #FFFFFF;
$completeness-bar-active-bg: #aad155;
$completeness-bar-border: #EEEEEE;
$completeness-bar-text: #ffffff;

$up-edit-profile-btn-color: #ffffff;
$up-edit-profile-bg: #8F9EA9;

$up-last-investment-block-bg: #f3f5f6;
$up-li-sum-color: #95c232;

$up-cr-block-bg: #f3f5f6;
$cr-unfollow-bg: #e3546f;
$cr-role-bg: #8f9ea9;

$cert-file-default-bg: #e7e7e7;
$cert-file-text-bg: #8fb9cd;
$cert-file-pdf-bg: #dc6e88;

$cert-file-downld-btn-color: #61a0cb;

/* Create syndicate variables */
$cs-blocks-bg: #ffffff;
$cs-ss-cr-tabs-color: #ffffff;

$cs-cr-rb-bg-color: #f3f5f6;
$cs-cr-rb-border-color: #e9eaea;
$cs-cr-rb-imgBorder-color: #d8dadb;

/* My syndicates colors */
$mySyndicates-leftcol-bg: #ffffff;
$mySyndicates-text-lightcolor: #ffffff;

/* Browse offers variables */
$filter-text-color: #ffffff;
$filter-border-color: #ffffff;

$invest-tiles-bg: #ffffff;
$invest-tiles-border: #f1f1f1;
$it-syndicate-badge-bg: #5adfd1;

// $it-stats-bg: #8f9ea9;
$it-stats-bg: #BCC5CA;
$it-stats-border: #ebeef0;
$it-stats-border-li: #c2d1dc;

/* Offer details variables */
$check-prospectus-link: #71baed;
$od-ts-text: #ffffff;
$od-ts-tags: #000000;
$od-white-bg: #ffffff;
$od-mc-top-details-value: #7e7e7e;
$od-mc-top-details-valueTitle: #b3b3b3;
$od-mc-amount-invested: #aad155;

$offer-details-status-bg: #5fa3b4;
$offer-details-status-text: #21697b;

$od-progresbar-bg: #eff1eb;
$od-progresbar: #aad155;
$od-progresbar-txt-lightcolor: #ffffff;

$od-main-tabs-bg: #71baed;
$od-main-tabs-counter: #dd1c52;

$od-maintabs-sidebar-navigation: #f3f5f6;

$od-uq-block-bg: #85afcd;
$od-uq-reply-block-bg: #e8edf0;

/* Footer variables */
$footer-bg: #2c6892;
$footer-border-color: #3b739a;
$footer-nav-border-color: #3f90c9;
$footer-text: #ffffff;
$footer-input: #7fb0d3;
$footer-input-border: #469edc;
$subscribe-btn-bg: #469edc;

// Paginations variables
$pagination-active-bg: #469edc;
$pagination-disabled-bg: transparent;
$pagination-active-color: #ffffff;
$pagination-a-color: #469edc;
$pagination-disable-color: rgba(70, 158, 220, 0.8);

// My variables
$button-foo: red;
