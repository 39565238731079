@import 'extensions/variables';
@import 'extensions/mixins';



#moneyball-top-banner {
  padding-top: 120px;
  position: relative;
  background-color: #fff;
  height: calc(100vh - 70px);
  @include mquery(1200px) {
    padding-top: 20px;
  }

  .moneyball-top-banner-layer {
    position: absolute;
    background-color: rgba(1, 1, 1, 0.7);
    height: calc(100vh - 70px);
    top: 0;
    width: 100%;
  }

  .half-banner-iphone {
    background-image: url(/images/landing-pages/moneyball-iphone.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    // margin-top: 50px;
    height: 715px;
    @include mquery(1200px) {
      height: 340px;
    }
  }

  .moneyball-logo {
    margin: 0 auto 70px;
    display: block;
    width: 300px;

    @include mquery(768px) {
      margin: 0 auto 30px;

    }
    @include mquery(320px) {
      width: 200px;
    }

  }


  .moneyball-title-max-width {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }


}

#moneyball-top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/images/landing-pages/moneynball-banner-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 70px);
  width: 100%;
}


#moneyball-content-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @include mquery(1200px) {
    flex-direction: column;
  }
  .half-banner-trophy-guy {
    background-image: url(/images/landing-pages/moneyball-happy-punter.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    // margin-top: 50px;
    height: 580px;
    @include mquery(1200px) {
      height: 340px;
    }
  }

  .moneyball-content-cnt {
    padding: 30px 0;
    @include mquery(1200px) {
      padding: 20px 15px;
    }
    @include mquery(320px) {
      padding: 20px 7px;
    }
  }
}


.second-form {
  background-color: #455a64;
  padding: 30px 30px;
  @include mquery(768px) {
    padding: 30px 0;
  }
  @include mquery(320px) {
    padding-right: 7px;
    padding-left: 7px;
  }

}


.moneyball-button-style {
  background-color: #48B115;
  border: none;
}
.moneyball-button-style:hover {
  opacity: .9;
  transition: .9s;
}

.moneyball-link-color {
  color: #48B115;
}

.moneyball-link-color:hover {
  color: #30770d;
}

.equitise-about-box {
  padding: 15px 30px;
  height: 100%;
  @include mquery(768px) {
    padding: 15px 15px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}



.first-form {
  @include mquery(1200px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.moneyball-down-form {
  @include mquery(1200px) {
    padding: 40px 0;
  }
}



#moneyball-video {
  .maneyball-video-row {
    width: 100%;
    display: flex;
    align-items: center;
    @include mquery(1200px) {
      display: flex;
      flex-direction: column;
    }
  }

  .video {
    max-width: 100%;
    height: auto;
  }

  .no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

   @include mquery(1200px) {
    .manyball-second-table {
      flex-basis: 100%;
      order: 1;
    }
  }
}
