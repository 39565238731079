/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#dhd-landing-page .dhd-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100vh - 70px);
}

#dhd-landing-page .dhd-content-cnt {
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0px;
}

#dhd-landing-page .dhd-content-cnt .dhd-logo {
  display: block;
  margin: 50px auto;
}

#dhd-landing-page .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

#dhd-landing-page .carousel-fade .carousel-inner .active {
  opacity: 1;
}

#dhd-landing-page .carousel-fade .carousel-inner .active.left,
#dhd-landing-page .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

#dhd-landing-page .carousel-fade .carousel-inner .next.left,
#dhd-landing-page .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

#dhd-landing-page .carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  #dhd-landing-page .carousel-fade .carousel-inner > .item.next,
  #dhd-landing-page .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #dhd-landing-page .carousel-fade .carousel-inner > .item.prev,
  #dhd-landing-page .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #dhd-landing-page .carousel-fade .carousel-inner > .item.next.left,
  #dhd-landing-page .carousel-fade .carousel-inner > .item.prev.right,
  #dhd-landing-page .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

#dhd-landing-page .one {
  background-image: url(/images/landing-pages/dhd-top-banner-1.jpg);
}

#dhd-landing-page .two {
  background-image: url(/images/landing-pages/dhd-top-banner-4.jpg);
}

#dhd-landing-page .tree {
  background-image: url(/images/landing-pages/dhd-top-banner-3.jpg);
}

#dhd-landing-page .dhd-carousel-control {
  width: 5%;
  z-index: 10;
}

.dhd-content-section {
  display: flex;
}

.dhd-content-section .dhd-half-banner {
  width: 100%;
}

.dhd-content-section .dhd-half-content {
  padding-top: 80px;
  padding-bottom: 80px;
  flex: 1;
  order: 1;
}

@media all and (max-width: 1024px) {
  .dhd-content-section .dhd-half-content {
    padding-top: 0;
  }
}

.dhd-video-row {
  width: 100%;
  display: table;
}

.video {
  max-width: 100%;
  height: auto;
}

.collage-img {
  margin-top: 180px;
}

.video-width-wrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

.iframe-width {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dhd-button-style {
  background-color: rgba(1, 1, 1, 0.8);
}

.second-table {
  padding: 80px 80px 20px 80px;
  height: 400px;
}

.dhd-input-border-black {
  border: 1px solid rgba(1, 1, 1, 0.2) !important;
}

.dhd-p-width {
  max-width: 780px;
  margin: 30px auto;
}

@media all and (max-width: 1200px) {
  .dhd-p-width {
    margin: auto;
  }
}

.dhd-pro-img {
  background-image: url(/images/landing-pages/dhd-Modom-Products-04.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 790px;
  margin-left: -15px;
  margin-right: -15px;
}

@media all and (max-width: 1024px) {
  .dhd-pro-img {
    height: 530px;
  }
}

@media all and (max-width: 767px) {
  .dhd-pro-img {
    height: 230px;
  }
}

@media all and (max-width: 1200px) {
  .second-table {
    padding: 30px 20px 20px 20px;
  }
}

@media all and (max-width: 1024px) {
  .dhd-content-section {
    display: flex;
    flex-direction: column;
  }
  .dhd-content-section .dhd-first-col {
    flex-basis: 100%;
    width: 100%;
    order: 0;
  }
  .dhd-content-section .dhd-sec-col {
    flex-basis: 100%;
    width: 100%;
    order: 1;
  }
  .collage-img {
    margin-top: 20px;
  }
  .dhd-video-row {
    display: flex;
    flex-direction: column;
  }
  .second-table {
    flex-basis: 100%;
    width: 100%;
    order: 1;
    padding: 80px 20px;
    height: 100%;
  }
  .video {
    flex-basis: 100%;
    width: 100%;
    order: 0;
  }
}

.dhd-event-header {
  padding: 70px 25px;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}
