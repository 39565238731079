/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#macro-top-banner {
  background-image: url(/images/landing-pages/macrovue-vr-top-banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  display: table;
}

@media all and (max-width: 1200px) {
  #macro-top-banner {
    height: fit-content;
  }
}

@media all and (max-width: 768px) {
  #macro-top-banner {
    height: calc(100vh - 70px);
  }
}

#macro-top-banner .macro-layer {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media all and (max-width: 1440px) {
  #macro-top-banner .macro-layer {
    align-items: baseline;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

#macro-top-banner .macro-layer .macro-logo {
  margin-bottom: 50px;
}

@media all and (max-width: 1440px) {
  #macro-top-banner .macro-layer .macro-logo {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 400px) {
  #macro-top-banner .macro-layer .macro-logo {
    width: 250px;
  }
}

#macro-top-banner .macro-layer .macro-title-width {
  max-width: 660px;
  margin: 0 auto 40px;
}

@media all and (max-width: 1440px) {
  #macro-top-banner .macro-mobile-img {
    width: 330px;
  }
}

@media all and (max-width: 768px) {
  #macro-top-banner .macro-mobile-img {
    width: 200px;
  }
}

.mm::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  -webkit-filter: hue-rotate(180deg);
  /* Safari 6.0 - 9.0 */
  filter: hue-rotate(180deg);
  transition: .8;
}

#section-two .macro-half-banner {
  background-image: url(/images/landing-pages/macrovie-content-car-of-the-future.jpg);
  background-repeat: no-repeat;
  background-position: center;
  /*background-size: cover;*/
  height: 806px;
  padding-right: 0;
  padding-left: 0;
  background-size: contain;
  background-position-y: center;
}

@media all and (max-width: 1451px) {
  #section-two .macro-half-banner {
    height: 789px;
  }
}

@media all and (max-width: 1236px) {
  #section-two .macro-half-banner {
    height: 851px;
  }
}

@media all and (max-width: 1024px) {
  #section-two .macro-half-banner {
    height: 320px;
  }
}

#section-two .second-img-layer {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.3);
}

#section-two .macro-first-content-cnt {
  padding: 50px;
}

@media all and (max-width: 1200px) {
  #section-two .macro-first-content-cnt {
    padding: 50px 0;
  }
}

.macro-third-half-banner {
  background-image: url(/images/landing-pages/macrovue-apple_tech-Stars.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 736px;
  padding-right: 0;
  padding-left: 0;
}

@media all and (max-width: 1521px) {
  .macro-third-half-banner {
    height: 816px;
  }
}

@media all and (max-width: 1329px) {
  .macro-third-half-banner {
    height: 876px;
  }
}

@media all and (max-width: 1024px) {
  .macro-third-half-banner {
    height: 320px;
  }
}

@media only screen and (min-width: 1440px) {
  #second-content-sec .macro-second-content-cnt {
    padding: 50px 130px;
  }
}

@media all and (max-width: 1440px) {
  #second-content-sec .macro-second-content-cnt {
    padding: 50px;
  }
}

@media all and (max-width: 1200px) {
  #second-content-sec .macro-second-content-cnt {
    padding: 50px 0 50px;
    max-width: 780px;
    margin: auto;
  }
}

#second-form-section {
  background: rgba(1, 1, 1, 0.9);
}

#second-form-section .flex-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 100px;
}

#second-form-section .center-video {
  display: flex;
  justify-content: center;
}

@media all and (max-width: 1046px) {
  #second-form-section .center-video {
    margin-bottom: 80px;
  }
}

.macro-third-content-cnt {
  padding: 50px;
}

@media all and (max-width: 1200px) {
  .macro-third-content-cnt {
    padding: 0 0 50px;
  }
}

.macro-button-style {
  background-color: #4cb854;
  border: 1px #4cb854;
  color: white;
}

.macro-create-account-btn {
  width: 240px;
  color: white;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
}

@media all and (max-width: 1200px) {
  .macro-create-account-btn {
    display: block;
    margin: auto;
  }
}

#mySVG {
  position: fixed;
  top: 15%;
  width: 410px;
  height: 1000px;
  margin-left: -50px;
}

.ms-link {
  color: #4cb854;
}

.ms-link:hover {
  color: #38913e;
}

.ms-iframe {
  width: 516px;
  height: 315px;
}

@media all and (max-width: 600px) {
  .ms-iframe {
    width: 100%;
    height: 100%;
  }
}

ul.li {
  list-style-type: circle;
  padding-left: 40px;
  color: white;
}

#macro-forth-sec {
  background-image: url(/images/landing-pages/macrovue-Car_of_the_Future.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;
}

#macro-forth-sec .macro-forth-layer {
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (min-width: 1440px) {
  #macro-forth-sec .macro-forth-layer .macro-forth-content-cnt {
    padding: 50px 130px;
  }
}

@media all and (max-width: 1440px) {
  #macro-forth-sec .macro-forth-layer .macro-forth-content-cnt {
    padding: 50px;
  }
}

@media all and (max-width: 1200px) {
  #macro-forth-sec .macro-forth-layer .macro-forth-content-cnt {
    padding: 50px 0;
    max-width: 780px;
  }
}
