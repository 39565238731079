@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
DATEPICKER RESTYLE
---------------------------------------------------------- */
.ui-widget-header {
    font-family: 'latolight';
    border: 1px solid $datepicker-light-border;
    background: $datepicker-bg;
    color: $datepicker-color;
    font-weight: initial;
}
.ui-datepicker-calendar span{
    font-family: 'latoregular';
    color: $datepicker-color;
}
.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default, 
.ui-button, 
html .ui-button.ui-state-disabled:hover, 
html .ui-button.ui-state-disabled:active{
    font-family: 'latolight';
    border: 1px solid $datepicker-light-border;
    background: $datepicker-bg;
    color: $datepicker-color;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover{
    border: 1px solid $datepicker-main-bg;
    background: $datepicker-main-bg;
    font-weight: normal;
    color: $datepicker-lightcolor;
}
.ui-state-highlight, 
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight{
    border: 1px solid $datepicker-main-bg;
    background: $datepicker-light-bg;
    font-weight: normal;
    color: $datepicker-main-color;
}
.ui-widget-header .ui-icon{
    background-image: url("../../../images/left-arrow.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    opacity: 0.8;
    @include transition(.3s);
}
.ui-datepicker-next .ui-icon{
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
}
.ui-datepicker-prev,
.ui-datepicker-next{
    @include transition(.3s);
    border-width: 0px;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    top: 2px;
}
.ui-datepicker-prev:hover,
.ui-datepicker-next:hover{
    background-color: transparent !important;
    border-width: 0px;
}
.ui-datepicker-prev:hover .ui-icon,
.ui-datepicker-next:hover .ui-icon{
    opacity: 1;
}
.ui-datepicker .ui-datepicker-title select{
    font-family: 'latolight';
    color: $datepicker-color;
    font-size: 14px;
    -moz-appearance: none;
    background-color: white;
    border: thin solid $datepicker-main-bg;
    border-radius: 0px;
    box-sizing: border-box;
    display: inline-block;
    font-feature-settings: inherit;
    font-kerning: inherit;
    font-language-override: inherit;
    font-stretch: inherit;
    font-style: inherit;
    font-synthesis: inherit;
    font-variant: inherit;
    font-weight: inherit;
    line-height: 15px;
    margin: 0;
    padding: 0 25px 0 5px;
    //background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-image: linear-gradient(45deg, transparent 50%, $datepicker-main-bg 50%), linear-gradient(135deg, $datepicker-main-bg 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    //background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-position: calc(100% - 10px) calc(7px + 2px), calc(100% - 5px) calc(7px + 2px), calc(100% - 20px) 0;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    &.ui-datepicker-month{
        margin-right: 1px;
    }
    &.ui-datepicker-year{
        margin-left: 1px;
    }
}


.dropdown-with-arrow {
    .select2-selection--multiple {
        &:after {
            content: "";
            position: absolute;
            top: 16px;
            right: 20px;


            width: 0;
            height: 0;
            transition: all 0.2s;
            border-style: solid;
            border-width: 7px 6px 0 6px;
            border-color: #6f8899 transparent transparent transparent;
        }
        &[aria-expanded=true]:after {
            transform: scaleY(-1);
        }
    }
}

.featured-syndicates-section {
    // background-image: url('../../../images/featured-syndicates-bg.jpg')
}

*[data-href] {
    cursor: pointer;
}

.ui-slider .ui-slider-handle {
    width: 22px;
    height: 22px;
}

.synd-member-tile-buttons{
    text-align: right;
    line-height: 26px;
}
.synd-member-tile-buttons a{
    padding: 5px;
    text-decoration: none;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'latoregular';
}
.synd-member-tile-buttons a.profile{
    border: 1px solid #469edc;
    background-color: #469edc;
    color: #FFFFFF;
}
.synd-member-tile-buttons a.reject{
    border: 1px solid #C9302C;
    color: #C9302C;
}
.synd-member-tile-buttons a.accept{
    border: 1px solid #449D44;
    color: #449D44;
}

.synd-team-member-section{
    // width: 33%;
    float: left;
    padding: 5px;
    @media (max-width: 430px) {
        width: 100%;
    }
}

.synd-team-member-section-block{
    border: 1px solid #e9eaea;
    background-color: #f3f5f6;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.synd-team-member-section-avatar{
    height: 100px;
    widht: 100px;
}

.synd-team-member-section-details{
    float: right;
    padding-left: 10px;
    width: 240px;
}

.syndicates-members-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
}

.synd-team-member-section-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}