/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#kitfit-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/kitfit/backgroundfooter.jpg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 600px) {
  #kitfit-paralax-sec {
    background-image: url("/images/landing-pages/kitfit/backgroundfooter.jpg");
  }
}

#kitfit-paralax-sec .kitfit-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}
