@import 'extensions/variables';
@import 'extensions/mixins';




#mangras-landing-page {
  background-image: url(/images/landing-pages/manrags-banner-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 800px;
  width: 100%;
  position: relative;
  display: table;

  @include mquery(768px) {
    background-image: url(/images/landing-pages/manrags-banner-mobile.jpg);
}

  .layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, .1);
    display: flex;
    align-items: center;
    justify-content: center;
    @include mquery(767px) {
      background-color: rgba(1, 1, 1, .2);
      align-items: start;
    }
  }

  .manrags-title-width {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }

  .manrags-logo {
    display: block;
    margin: 0 auto 50px;
    @include mquery(767px) {
         width: 280px;
         margin: 30px auto;
  }
  }


}

.manrags-line-banner {
  width: 100%;
}

  #manrags-content-section {
    display: flex;
    flex-direction: row;
    @include mquery(1200px) {
      flex-direction: column-reverse;

  }

    .manrags-half-banner {
      background-image: url(/images/landing-pages/manrags-002-min.jpeg);
      background-size: contain;
      background-position: top;
      background-color: #fff;
      background-repeat: repeat-y;


    }
    .manrags-half-content {
      padding: 80px 50px;
      @include mquery(768px) {
        padding: 80px 20px;
    }
    }
}

.sec-eoi {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  @include mquery(1024px) {
    height: 400px;
}
}

.manrags-button-style {
  background-color: rgba(1, 1, 1, .8);
  border: 1px solid black;
  transition: .5s;
  &:hover {
    color: #fff;
    opacity: .7;
  }
}


.manrags-down-half-banner-top {
  background-image: url(/images/landing-pages/manrags-001-min.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  //remove the padding for col in bootstrap insted of using !important
  margin: 0px -15px 0px -15px;
  height: 500px;
}

.manrags-view-offer-button-style {
  border: 1px solid black;
  color: white;
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: rgba(1, 1, 1, .8);
  animation: bounce 3s;
  transition: .3s;
}

.manrags-view-offer-button-style:hover {
  background-color: #3d3c3c;
  border: 1px solid #3d3c3c;
}
