@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
ABOUT EQUITISE
---------------------------------------------------------- */
.about-equitise-section {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    span.about-equitise-subheading {
        color: $default-text-color;
        float: left;
        font-family: "latoregular";
        font-size: 20px;
        margin: 50px 0px 0px 0;
        padding: 0 0 20px;
        width: 100%;
    }
    > p {
        color: $default-text-color;
        float: left;
        font-family: "latolight";
        font-size: 14px;
        line-height: 150%;
        margin: 15px 0 0;
        padding: 0;
        width: 100%;
    }
}
.about-equitise-steps {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 50px 0px;
    > .about-equitise-step {
        float: left;
        width: 33.33%;
        margin: 0px;
        padding: 0px 10px;
        > div {
            float: left;
            width: 100%;
            min-height: 284px;
            margin: 0px;
            padding: 50px 30px;
            text-align: center;
            border: 1px solid #ededed;
            > img {
                display: inline-block;
                margin: 0px;
                padding: 0px;
            }
            > span {
                color: $default-text-color;
                float: left;
                font-family: "latobold";
                font-size: 18px;
                margin: 0;
                padding: 20px 0 10px;
                text-align: center;
                width: 100%;
            }
            > p {
                color: #7e7e7e;
                float: left;
                font-family: "latolight";
                font-size: 16px;
                line-height: 150%;
                margin: 0px;
                padding: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
}
ul.about-equitise-investors {
    float: left;
    width: 100%;
    margin: 50px 0px;
    padding: 0px;
    text-align: center;
    > li {
        display: inline-block;
        width: 300px;
        height: 90px;
        margin: 10px;
        padding: 0px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        @include transition(.3s);
        &:hover {
            border-color: #ededed;
        }
        > a {
            float: left;
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
            > img {
                float: left;
                width: 100%;
                height: 100%;
                margin: 0px;
                padding: 0px;
            }
        }
    }
}
ul.default-info-list {
    float: left;
    width: 100%;
    margin: 50px 0px 30px 0px;
    padding: 0px 0px 0px 16px;
    > li {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 7px 0px;
        list-style: circle;
        color: #6195b9;
        > p {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            line-height: 150%;
            font-family: 'latoregular';
            font-size: 13px;
            color: #6195b9;
        }
    }
}
