/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
OFFER INVEST
---------------------------------------------------------- */
.oi-top-navigation {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

.oi-top-navigation > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.oi-top-navigation > ul > li {
  float: left;
  width: 33.33333333%;
  margin: 0px;
  padding: 0px 2px 0px 0px;
  list-style: none;
}

.oi-top-navigation > ul > li:first-of-type > a:before {
  border: none;
}

.oi-top-navigation > ul > li:last-of-type > a:after {
  border: none;
}

.oi-top-navigation > ul > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 10px 30px;
  font-family: 'latobold';
  font-size: 16px;
  color: #bdc7cc;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #ffffff;
  position: relative;
}

.oi-top-navigation > ul > li > a:hover {
  cursor: pointer;
}

.oi-top-navigation > ul > li > a:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
  border-left: 15px solid #F3F5F6;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.oi-top-navigation > ul > li > a:after {
  content: "";
  position: absolute;
  top: 0px;
  right: -16px;
  width: 0px;
  height: 0px;
  border-left: 16px solid #ffffff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}

.oi-top-navigation > ul > li > a.current {
  color: #ffffff;
  background-color: #469edc;
}

.oi-top-navigation > ul > li > a.current:after {
  border-left-color: #469edc;
}

.oi-top-navigation > ul > li > a.completed {
  color: #ffffff;
  background-color: #b5c9d7;
}

.oi-top-navigation > ul > li > a.completed:after {
  border-left-color: #b5c9d7;
}

@media all and (max-width: 1180px) {
  .oi-top-navigation > ul > li > a {
    padding: 11px 15px;
    font-size: 14px;
  }
  .oi-top-navigation > ul > li > a:before, .oi-top-navigation > ul > li > a:after {
    content: none;
  }
}

.oi-top-navigation > ul > li > a.completed-tab:hover {
  cursor: default;
}

@media all and (max-width: 960px) {
  .oi-top-navigation > ul > li {
    width: 50%;
    margin-bottom: 1px;
  }
}

@media all and (max-width: 500px) {
  .oi-top-navigation > ul > li {
    width: 100%;
  }
}

/* Offer invest form container */
.oi-form-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.oi-form-container .oi-form-step-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.oi-form-container .oi-form-step-cont.oi-step-three {
  background-color: #ffffff;
  padding: 30px 20px;
  margin-top: 30px;
}

.oi-form-container .oi-form-step-cont .oi-form-left {
  float: left;
  width: 50%;
  margin: 25px 0px 0px 0px;
  padding: 0px 15px 0px 0px;
}

.oi-form-container .oi-form-step-cont .oi-form-right {
  float: left;
  width: 50%;
  margin: 25px 0px 0px 0px;
  padding: 0px 0px 0px 15px;
}

@media all and (max-width: 890px) {
  .oi-form-container .oi-form-step-cont .oi-form-left, .oi-form-container .oi-form-step-cont .oi-form-right {
    width: 100%;
    padding: 0px;
  }
}

.oi-thanks-text {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latobold';
  font-size: 26px;
  color: #7e7e7e;
}

.oi-thanks-subtext {
  float: left;
  width: 100%;
  margin: 20px 0px 20px 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
}

.oi-thanks-links {
  float: left;
  width: 100%;
  margin: 25px 0px 0px 0px;
  padding: 0px;
}

.oi-thanks-links > li {
  float: left;
  width: 20%;
  margin: 0px;
  padding: 0px 0px 0px 1px;
  list-style: none;
}

@media all and (max-width: 670px) {
  .oi-thanks-links > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

.oi-thanks-links > li:first-of-type {
  padding-left: 0px;
}

.oi-thanks-links > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 15px;
  text-decoration: none;
  background-color: #e1e1e1;
  font-family: 'latoblack';
  font-size: 15px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.oi-thanks-links > li > a.oi-tl-fb {
  background-color: #3b5998;
  background-image: url(../../images/oi-thanks-fb-icon.png);
}

.oi-thanks-links > li > a.oi-tl-fb:hover {
  background-color: #4c70ba;
}

.oi-thanks-links > li > a.oi-tl-twitter {
  background-color: #33ccff;
  background-image: url(../../images/oi-thanks-twitter-icon.png);
}

.oi-thanks-links > li > a.oi-tl-twitter:hover {
  background-color: #66d9ff;
}

.oi-thanks-links > li > a.oi-tl-linkedin {
  background-color: #0077b5;
  background-image: url(../../images/oi-thanks-linkedin-icon.png);
}

.oi-thanks-links > li > a.oi-tl-linkedin:hover {
  background-color: #0099e8;
}

.oi-thanks-links > li > a.oi-tl-google {
  background-color: #dd4b39;
  background-image: url(../../images/oi-thanks-google-icon.png);
}

.oi-thanks-links > li > a.oi-tl-google:hover {
  background-color: #e47365;
}

.oi-thanks-links > li > a.oi-tl-email {
  background-color: #c6936a;
  background-image: url(../../images/oi-thanks-email-icon.png);
}

.oi-thanks-links > li > a.oi-tl-email:hover {
  background-color: #d4ae8f;
}

.oi-form-block {
  float: left;
  width: 100%;
  margin: 25px 0px 0px 0px;
  padding: 30px 20px;
  background-color: #ffffff;
}

.oi-form-block:first-of-type {
  margin-top: 0px;
}

.oi-form-block .oi-form-block-form-fields {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.oi-form-block .oi-form-block-form-fields .settings-form-group .sfg-field {
  padding-left: 0px;
  max-width: 395px;
}

.oi-form-block .oi-form-block-form-fields .error-tooltip {
  z-index: 9999;
}

.download-ia-button {
  float: left;
  margin: 20px 0px 0px 0px;
  padding: 0px 55px 1px 10px;
  min-height: 40px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background-image: url(../../images/download-ia-button-icon.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-color: #469edc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  border: 0px;
  /* Media queries */
}

.download-ia-button:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 375px) {
  .download-ia-button {
    font-size: 12px;
  }
}

@media all and (max-width: 330px) {
  .download-ia-button {
    font-family: 'latobold';
    font-size: 11px;
  }
}

.oi-id-groups {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.oi-id-groups .oi-id-group {
  float: left;
  width: 100%;
  min-height: 46px;
  margin: 0px;
  padding: 15px 0px 14px;
  border-style: solid;
  border-color: #cad7de;
  border-bottom-width: 1px;
}

.oi-id-groups .oi-id-group:last-of-type {
  border-bottom-width: 0px;
}

.oi-id-groups .oi-id-group > span {
  float: left;
  width: 30%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.oi-id-groups .oi-id-group > p {
  float: left;
  width: 70%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 16px;
  color: #469edc;
  text-align: right;
}

.oi-step-two-fields .form-group {
  padding: 10px;
}

.oi-step-two-fields .form-group .form-group-title {
  float: left;
  position: relative;
  max-width: 100%;
  top: auto;
  left: auto;
  padding: 0px;
}

.oi-account-number-field {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 10px 0px 0px;
  position: relative;
}

.oi-account-number-field.first {
  max-width: 15%;
}

.oi-account-number-field.second {
  max-width: 100px;
}

.oi-account-number-field.third {
  max-width: 35%;
}

.oi-account-number-field.fourth {
  max-width: 30%;
  padding-right: 0px;
}

.form-submit-action {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  border-top-width: 1px;
  border-style: solid;
  border-color: #e0e3e5;
}

.form-submit-action .disabled {
  background-color: grey;
}

.form-submit-action .disabled:hover {
  cursor: not-allowed;
}

.oi-multiple-fields {
  padding-left: 140px !important;
}

.oi-multiple-fields .sfg-title {
  left: 0px;
}

@media all and (max-width: 580px) {
  .oi-multiple-fields .sfg-title {
    position: absolute !important;
    top: 10px;
    left: 5px;
  }
}

.oi-multiple-fields .sfg-field {
  max-width: 100% !important;
}

@media all and (max-width: 400px) {
  .oi-multiple-fields {
    padding-left: 0px !important;
  }
  .oi-multiple-fields .sfg-title {
    position: relative !important;
    left: auto;
    top: auto;
  }
  .oi-multiple-fields .sfg-field {
    padding-left: 10px !important;
  }
  .oi-multiple-fields .sfg-field.first {
    margin-top: 0px;
  }
}
