/* Select 2 style */
.select2-container {
    margin: 9px 0px 0px 0px;
}
.select2-container .select2-selection--multiple {
    height: 42px;
    overflow-y: auto;
}
.select2-container--default .select2-selection--multiple {
    border-radius: 0px;
    border-color: #dfe6eb;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #dfe6eb;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0px 10px;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 9px;
    font-family: "latobold";
    font-size: 14px;
    color: #7e7e7e;
}
.select2-container--open .select2-dropdown {
    top: -10px;
    border-color: #dfe6eb;;
    border-radius: 0px;
    font-family: "latobold";
    font-size: 14px;
    color: #7e7e7e;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 9px 10px 9px 25px;
    border-radius: 3px;
    border: 0px;
    background-color: #E4ECF1;
    font-family: "latobold";
    font-size: 12px;
    color: #6f8899;
    position: relative;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    position: absolute;
    top: 8px;
    left: 10px;
    color: #8AB5D3;
}

.document-description {
    padding: 0;
    .sfg-title {
        width: auto !important;
    }
    .sfg-field {
        margin: 10px 0;
        position: static !important;
        float: right;
    }
    textarea {
        position: static !important;
        height: 210px !important;
        &:focus {
            height: inherit !important;
        }
    }
}