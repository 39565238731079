/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
PROFILE SETTINGS
---------------------------------------------------------- */
.settings-container {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px 340px 0px 0px;
  position: relative;
}

.settings-container .settings-leftcol {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px;
  position: relative;
  background-color: #ffffff;
}

@media all and (max-width: 1023px) {
  .settings-container .settings-leftcol {
    margin-top: 30px;
  }
}

.settings-container .profile-settings-container {
  position: relative;
}

.settings-container .profile-settings-container .reg-log-title {
  float: none;
  display: block;
}

.settings-container .profile-settings-container .reg-log-subtitle {
  float: none;
  display: block;
}

.settings-container .settings-rightcol {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 320px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 1023px) {
  .settings-container .settings-rightcol {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%;
  }
}

@media all and (max-width: 1023px) {
  .settings-container {
    padding: 0px;
  }
}

.form-groups-wrapper {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

.selected-corporate-trustee {
  float: left;
  margin: 10px 0px 0px 0px;
}

.settings-form-group {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 10px 0px 0px 0px;
  padding: 0px 400px 0px 0px;
  position: relative;
  background-color: #f3f5f6;
  /* sfg field ends here */
}

.settings-form-group:first-of-type {
  margin-top: 0px;
}

.settings-form-group.radio-form-group {
  padding: 0px 160px 0px 0px;
}

@media all and (max-width: 580px) {
  .settings-form-group.radio-form-group {
    padding: 0px;
  }
}

.settings-form-group.radio-form-group .sfg-field {
  margin-top: 18px;
  max-width: 200px;
}

.settings-form-group .sfg-title {
  position: relative;
  float: left;
  width: 100%;
  margin: 0px;
  padding: 22px 15px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-title {
    padding: 10px;
  }
}

.settings-form-group .sfg-title-optional {
  font-family: "latoregular";
  font-style: italic;
}

.settings-form-group .sfg-field {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px 10px 0px 52px;
  width: 100%;
  max-width: 400px;
  /* sfg select field ends here */
  /* sfg file input ends here */
}

@media all and (max-width: 670px) {
  .settings-form-group .sfg-field {
    max-width: 320px;
  }
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field {
    max-width: 100%;
    padding: 0px 10px 0px 62px;
    position: relative;
    float: left;
    top: auto;
    right: auto;
  }
}

.settings-form-group .sfg-field.prof-sett-industries {
  padding-top: 9px;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field.prof-sett-industries {
    padding-top: 0;
  }
}

.settings-form-group .sfg-field .sfg-input-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 9px 0px 0px 0px;
  padding: 0px 10px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field .sfg-input-field {
    margin: 0px;
  }
}

.settings-form-group .sfg-field .sfg-select-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 9px 0px 0px 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.settings-form-group .sfg-field .sfg-select-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/select-field-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.settings-form-group .sfg-field .sfg-select-field > select {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px 1px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field .sfg-select-field {
    margin: 0px;
  }
}

.settings-form-group .sfg-field .sfg-file-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 9px 0px 0px 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.settings-form-group .sfg-field .sfg-file-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/file-field-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.settings-form-group .sfg-field .sfg-file-field > button.default-documents {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-image: url(../../images/cert-upload-btn-icon.png);
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.settings-form-group .sfg-field .sfg-file-field > button.legal-documents {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-image: url(../../images/cert-upload-btn-icon.png);
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.settings-form-group .sfg-field .sfg-file-field > input {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  text-indent: -99999px;
  opacity: 0;
  cursor: pointer;
}

.settings-form-group .sfg-field .sfg-file-field > input:disabled {
  background-color: #ededed;
}

.settings-form-group .sfg-field .sfg-file-field > span {
  position: absolute;
  top: 12px;
  left: 0px;
  margin: 0px;
  padding: 0px 42px 3px 15px;
  width: 100%;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field .sfg-file-field {
    margin: 0px;
  }
}

.settings-form-group .sfg-field .sfg-textarea-field {
  float: left;
  width: 100%;
  min-height: 160px;
  margin: 9px 0px 0px 0px;
  padding: 10px 10px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  resize: none;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field .sfg-textarea-field {
    margin: 0px;
  }
}

.settings-form-group .sfg-field-privacy {
  position: absolute;
  top: 9px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 42px;
  display: none;
  overflow: hidden;
}

.settings-form-group .sfg-field-privacy.visible {
  display: inline-block;
}

.settings-form-group .sfg-field-privacy.main-privacy-switch {
  top: auto;
  bottom: 9px;
  left: 10px;
}

@media all and (max-width: 580px) {
  .settings-form-group .sfg-field-privacy {
    top: 0px;
    left: 10px;
  }
}

.settings-form-group.multiple-fields {
  padding: 0px 0px 9px 0px;
}

.settings-form-group.multiple-fields .sfg-title {
  position: absolute;
  padding: 22px 410px 22px 15px;
}

@media all and (max-width: 580px) {
  .settings-form-group.multiple-fields .sfg-title {
    padding: 10px;
    float: left;
    width: 100%;
    margin: 0px;
    position: relative;
  }
}

.settings-form-group.multiple-fields .sfg-field {
  top: auto;
  right: auto;
  float: right;
  position: relative;
  clear: both;
}

@media all and (max-width: 580px) {
  .settings-form-group.multiple-fields .sfg-field {
    margin-top: 9px;
  }
}

@media all and (max-width: 580px) {
  .settings-form-group.multiple-fields .main-privacy-switch {
    left: auto;
    bottom: auto;
    top: 10px;
    right: 10px;
  }
}

@media all and (max-width: 580px) {
  .settings-form-group.address-multiple-fields .sfg-field {
    padding: 0px 10px;
  }
}

.settings-form-group.address-multiple-fields .sfg-field.first {
  margin-top: 28px;
}

.settings-form-group .profile-picture-preview {
  position: absolute;
  bottom: 9px;
  right: 10px;
  width: 164px;
  height: 164px;
  margin: 0px;
  padding: 0px;
}

.settings-form-group .profile-picture-preview > img {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 580px) {
  .settings-form-group .profile-picture-preview {
    display: inline-block;
    position: relative;
    bottom: auto;
    right: auto;
    margin: 10px 0px 0px 0px;
  }
}

@media all and (max-width: 670px) {
  .settings-form-group {
    padding: 0px 330px 0px 0px;
  }
}

@media all and (max-width: 580px) {
  .settings-form-group {
    padding: 0px 0px 10px 0px;
    text-align: center;
  }
}

/* Settings form group ends here */
.include-field-link {
  min-height: 80px;
}

.sfg-field {
  max-width: 160px;
  padding: 0px 25px 0px 10px;
  margin-top: 18px;
}

@media all and (max-width: 580px) {
  .sfg-field {
    margin-top: 0px;
    padding: 0px 25px 10px 10px;
  }
}

.is-sfg-field {
  float: left;
  margin-top: 4px;
  padding: 0px 0px 0px 25px;
}

.existed-file {
  font-size: 11px;
  color: #469edc;
  float: right;
  padding: 7px 0px;
}

.existed-file:hover {
  text-decoration: underline;
}

.cp-image-info {
  font-size: 11px;
  color: #469edc;
  padding: 7px 0px;
  display: block;
}

.cp-form-group-with-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 10px 10px 0 !important;
}

.cp-form-group-with-info .cp-form-group-title {
  float: none;
  display: block;
  flex: 1 0 200px;
  margin-top: 12px;
  margin-left: 15px;
}

.cp-form-group-with-info .cp-fg-input-cont {
  position: relative;
  top: 0;
  left: 0;
  flex-basis: 280px;
  margin-left: 10px;
}

.cp-form-group-with-info .cp-fg-image-preview {
  background-position: top right;
  background-size: cover;
}

.cp-form-group-with-info .cp-fg-image-preview .cp-fg-ip-thumbnail-image {
  width: auto !important;
}

.cp-form-group-with-info .cp-fg-image-preview .cp-fg-ip-thumbnail-image img {
  max-height: 150px !important;
  height: 150px !important;
  width: auto !important;
  max-width: unset !important;
}

.cp-form-group-with-info .cp-fg-image-preview:hover .cp-fg-ip-thumbnail-image {
  opacity: 1 !important;
  visibility: visible !important;
}

@media all and (max-width: 580px) {
  .cp-form-group-with-info {
    display: block;
    padding-right: 10px;
  }
  .cp-form-group-with-info .cp-fg-input-cont {
    width: 100%;
    box-sizing: border-box;
  }
  .cp-form-group-with-info .cp-form-group-title {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 5px;
    margin-right: 10px;
  }
  .cp-form-group-with-info .cp-form-helper {
    right: 15px;
  }
  .cp-form-group-with-info .cp-fg-input-cont {
    padding-right: 15px !important;
  }
}

.cp-form-group-with-info .cp-fg-file-field {
  float: none !important;
}

.cp-form-group-with-info .cp-image-info {
  padding-top: 10px;
  display: block;
}

.settings-sidebar-navigation {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.settings-sidebar-navigation > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  /* Navigation list items ends here */
}

.settings-sidebar-navigation > ul > li {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px;
  list-style: none;
  /* nth-child media queries - sidebar navigation links text adjustment */
}

.settings-sidebar-navigation > ul > li > a {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px;
  padding: 16px 15px 15px 15px;
  background-color: #ffffff;
  font-family: 'latoregular';
  font-size: 16px;
  color: #6f8899;
  text-align: right;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  border-style: solid;
  border-color: #f3f5f6;
  border-bottom-width: 1px;
}

.settings-sidebar-navigation > ul > li > a:hover, .settings-sidebar-navigation > ul > li > a.active {
  background-color: #e4ecf1;
}

.settings-sidebar-navigation > ul > li > a:hover:after, .settings-sidebar-navigation > ul > li > a.active:after {
  visibility: visible;
  opacity: 1;
  left: 15px;
}

.settings-sidebar-navigation > ul > li > a:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 40px;
  background-image: url(../../images/settings-sidebar-nav-arrow.png);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

@media all and (max-width: 1023px) {
  .settings-sidebar-navigation > ul > li > a:after {
    content: none;
  }
}

@media all and (max-width: 1023px) {
  .settings-sidebar-navigation > ul > li > a {
    text-align: center;
    font-size: 14px;
    padding: 17px 15px 15px;
  }
}

@media all and (max-width: 610px) {
  .settings-sidebar-navigation > ul > li > a {
    padding: 17px 15px 15px !important;
  }
}

@media all and (max-width: 1023px) {
  .settings-sidebar-navigation > ul > li {
    width: 20%;
    border-style: solid;
    border-color: #f3f5f6;
    border-right-width: 1px;
  }
}

@media all and (max-width: 610px) {
  .settings-sidebar-navigation > ul > li {
    width: 50%;
  }
}

@media all and (max-width: 420px) {
  .settings-sidebar-navigation > ul > li {
    width: 100%;
  }
}

@media all and (max-width: 990px) {
  .settings-sidebar-navigation > ul > li:nth-child(3) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 818px) {
  .settings-sidebar-navigation > ul > li:nth-child(2) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 798px) {
  .settings-sidebar-navigation > ul > li:nth-child(5) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 747px) {
  .settings-sidebar-navigation > ul > li:nth-child(4) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 652px) {
  .settings-sidebar-navigation > ul > li:nth-child(1) > a {
    padding-top: 10px;
  }
}

/* Investment settings */
.investment-settings-wrapper {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  /* Investment settings action buttons ends here */
}

.investment-settings-wrapper .is-action-buttons {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 18px;
  background-color: #f3f5f6;
  text-align: center;
  position: relative;
}

.investment-settings-wrapper .is-action-buttons .is-add-company-btn {
  float: left;
  width: 190px;
  height: 34px;
  margin: 0px;
  padding: 10px 10px;
  font-family: 'latoblack';
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #aad155;
  text-decoration: none;
  background-image: url(../../images/is-addcompany-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media all and (max-width: 690px) {
  .investment-settings-wrapper .is-action-buttons .is-add-company-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

.investment-settings-wrapper .is-action-buttons .is-add-company-btn:hover {
  background-color: #b4d669;
}

.investment-settings-wrapper .is-action-buttons .is-add-company-btn.investment-trust {
  display: inline-block;
  float: none;
}

.investment-settings-wrapper .is-action-buttons .is-add-company-btn.investment-smsf {
  float: right;
}

.investment-settings-wrapper .is-action-buttons .is-back-btn {
  display: none;
  float: left;
  width: 190px;
  height: 34px;
  margin: 0px;
  padding: 10px 10px;
  font-family: 'latoblack';
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #6f8899;
  text-decoration: none;
  background-image: url(../../images/is-back-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.investment-settings-wrapper .is-action-buttons .is-back-btn:hover {
  background-color: #7e94a4;
}

@media all and (max-width: 690px) {
  .investment-settings-wrapper .is-action-buttons .is-back-btn {
    width: 34px;
    text-indent: -99999px;
    background-position: right 12px center;
  }
}

@media all and (max-width: 485px) {
  .investment-settings-wrapper .is-action-buttons .is-back-btn {
    margin-top: 20px;
    text-indent: 0;
    width: 190px;
  }
}

.investment-settings-wrapper .is-action-buttons .is-checkbox-group {
  display: none;
  float: right;
  margin: 2px 0px 0px 0px;
  padding: 0px 0px 0px 18px;
}

@media all and (max-width: 485px) {
  .investment-settings-wrapper .is-action-buttons .is-checkbox-group {
    padding: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
  }
}

.investment-settings-wrapper .is-action-buttons .is-checkbox-group > .default-checkbox-label {
  float: none;
  display: inline-block;
  padding: 0px 10px 0px 0px;
}

.investment-settings-wrapper .is-action-buttons .is-checkbox-group > .is-sfg-field {
  float: none;
  display: inline-block;
  padding: 0px 0px 0px 10px;
}

.investment-settings-wrapper .is-newcompany-form {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  position: relative;
  display: none;
}

.is-table-container {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

a.invite-team-user {
  color: #469edc;
}

a.invite-team-user:visited {
  color: #469edc;
}

.is-company-members-wrapper {
  float: left;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px;
  position: relative;
}

.is-company-members-wrapper.create-corporate-synd {
  margin: 0;
}

.is-company-members-wrapper .is-cm-tabs-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.is-company-members-wrapper .is-cm-tabs-content .is-cd-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-cs-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: none;
}

.is-company-members-wrapper .is-cm-tabs-content .is-ci-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: none;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  position: relative;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(2n+1) {
  padding: 20px 10px 0px 0px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(2n+1) {
    padding: 20px 0px 0px 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(2n+2) {
  padding: 20px 0px 0px 10px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(2n+2) {
    padding: 20px 0px 0px 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(-n+2) {
  padding-top: 0px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:nth-child(-n+2) {
    padding: 20px 0px 0px 0px;
  }
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block {
    width: 100%;
  }
  .is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block:first-of-type {
    padding-top: 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div {
  float: left;
  width: 100%;
  min-height: 90px;
  margin: 0px;
  padding: 0px 0px 0px 95px;
  position: relative;
  background-color: #f3f5f6;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 90px;
  height: 90px;
  margin: 0px;
  padding: 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 25px 0px 10px;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div > span.member-position-title {
  font-family: 'latoregular';
  margin-top: 5px;
  font-size: 12px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div > a {
  background-image: url("../../images/blue-button-arrow.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  color: #469edc;
  float: left;
  font-family: "latoblack";
  font-size: 11px;
  margin: 12px 0 0 10px;
  min-height: 26px;
  padding: 6px 35px 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div > a:hover {
  background-position: right 7px center;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div > div a.see-profile-button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 10px;
  margin-right: 10px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-members-blocks .is-member-block > div .is-member-block-remove {
  position: absolute;
  width: 11px;
  height: 11px;
  margin: 0px;
  padding: 0px;
  background-image: url(../../images/is-cm-remove-icon.png);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  top: 5px;
  right: 5px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  position: relative;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(2n+1) {
  padding: 20px 10px 0px 0px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(2n+1) {
    padding: 20px 0px 0px 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(2n+2) {
  padding: 20px 0px 0px 10px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(2n+2) {
    padding: 20px 0px 0px 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(-n+2) {
  padding-top: 0px;
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:nth-child(-n+2) {
    padding: 20px 0px 0px 0px;
  }
}

@media all and (max-width: 768px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block {
    width: 100%;
  }
  .is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block:first-of-type {
    padding-top: 0px;
  }
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div {
  float: left;
  width: 100%;
  min-height: 75px;
  margin: 0px;
  padding: 0px 0px 0px 80px;
  position: relative;
  background-color: #f3f5f6;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 75px;
  height: 75px;
  margin: 0px;
  padding: 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 0px 0px 0px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 25px 0px 10px;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div > div > a {
  background-image: url("../../images/blue-button-arrow.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  color: #469edc;
  float: right;
  font-family: "latoblack";
  font-size: 11px;
  margin: 0 10px 10px 0;
  min-height: 26px;
  padding: 6px 35px 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div > div > a:hover {
  background-position: right 7px center;
}

.is-company-members-wrapper .is-cm-tabs-content .is-company-blocks .is-company-block > div .company-type {
  font-family: 'latoregular';
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user {
  display: inline-block;
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user > span {
  width: 100%;
  margin: 20px 0;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  text-align: center;
  line-height: 150%;
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user > .is-cm-filegroup {
  padding-right: 0;
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user > .is-cm-filegroup .invited_email {
  margin-top: 0;
  float: none;
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user > .is-cm-filegroup .sfg-field button {
  top: 10px;
}

.is-company-members-wrapper .is-cm-tabs-content .is-addnew-user .remove-block-for-invite {
  top: 25px !important;
  right: -20px !important;
}

@media all and (max-width: 480px) {
  .is-company-members-wrapper .is-cm-tabs-content .is-addnew-user .remove-block-for-invite {
    top: 5px !important;
    right: 5px !important;
  }
}

.is-company-members-wrapper .remove-block-for-invite {
  position: absolute;
  width: 11px;
  height: 11px;
  margin: 0px;
  padding: 0px;
  background-image: url(../../images/is-cm-remove-icon.png);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  top: 5px;
  right: 5px;
}

/* Certificate tabs container ends here */
.is-cm-tabs {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.is-cm-tabs.two-tabs > li {
  width: 50%;
}

.is-cm-tabs.three-tabs > li {
  width: 33.3%;
}

.is-cm-tabs.four-tabs > li {
  width: 25%;
}

.is-cm-tabs.five-tabs > li {
  width: 20%;
}

.is-cm-tabs.blue > li {
  border-color: #ffffff;
}

.is-cm-tabs.blue > li > a:hover, .is-cm-tabs.blue > li > a.active {
  background-color: #469edc;
}

.is-cm-tabs > li {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

.is-cm-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 420px) {
  .is-cm-tabs > li {
    width: 100%;
    border-right: 0px;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
}

.is-cm-tabs > li > a {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 9px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.is-cm-tabs > li > a:hover, .is-cm-tabs > li > a.active {
  background-color: #469edc;
}

/* Certificates tabs UL ends here */
.is-cm-filegroup .sfg-field {
  padding: 9px 60px 0px 10px;
}

.is-cm-filegroup .sfg-field button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-image: url(../../images/cert-upload-btn-icon.png);
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.is-cm-filegroup .sfg-field button:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 580px) {
  .is-cm-filegroup .sfg-field button.mob-position-fix {
    top: auto;
    bottom: 1px;
  }
}

@media all and (max-width: 580px) {
  .is-cm-filegroup .sfg-field button.add-company.mob-position-fix {
    top: auto;
    bottom: 0px;
  }
}

input[type=checkbox].sfg-privacy-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type=checkbox].sfg-privacy-checkbox + label.sfg-privacy-checkbox-label {
  padding-left: 42px;
  height: 42px;
  display: inline-block;
  line-height: 42px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 42px;
  vertical-align: middle;
  cursor: pointer;
}

input[type=checkbox].sfg-privacy-checkbox:checked + label.sfg-privacy-checkbox-label {
  background-position: 0 -42px;
}

label.sfg-privacy-checkbox-label {
  background-image: url(../../images/sfg-privacy-checkbox.png);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-indent: -9999px;
}

.total-investments {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  color: #ffffff;
  background-color: #8f9ea9;
  margin-bottom: 20px;
  padding: 11px 15px;
}

.total-investments p {
  font-family: 'latoregular';
  font-size: 16px;
}

.identity-align-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.confirm-btn-cnt {
  padding: 10px 20px;
  margin-right: 10px;
}

.update-detail-btn {
  padding: 9px 20px;
  color: #469edc;
}

.update-detail-btn:hover {
  background-color: #469edc;
  transition: 0.3s;
  color: #fff;
}
