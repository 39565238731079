.unknown-address {
  display: inline-block;
}

.unknown-address, .search-address {
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  color: #7e7e7e;
}

.unknown-address:hover, .search-address:hover {
  cursor: pointer;
}

.address-wrapper, .search-address {
  display: none;
}

ul.addResults {
  position: absolute;
  z-index: 5;
  background-color: white;
  top: 51px;
}

ul.addResults > li {
  padding: 10px;
  font-size: 15px;
  border: 1px solid #dfe6eb;
  border-top: none;
  top: 51px;
}

ul.addResults > li:hover {
  cursor: pointer;
  background-color: #e4ecf1;
}

@media (min-width: 670px) {
  ul.addResults > li {
    width: 338px;
  }
}

.add_new_wbi {
  font-size: 14px !important;
}

#company_id {
  font-size: 14px !important;
}

@media (max-width: 321px) {
  .ui-autocomplete {
    width: 230px !important;
    font-size: 11px !important;
  }
  .company-autocomplete > li > a.ui-corner-all > button {
    background-color: #469edc;
    border: 0 none;
    color: #fff;
    font-family: "latoregular";
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
  }
}

.address-wrapper {
  display: none;
}

input[readonly="readonly"], input[disabled="disabled"], textarea[readonly="readonly"], textarea[disabled="disabled"] {
  background-color: #f3f5f6 !important;
  border: none !important;
}

.remodal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ipo-fsg-prospectus .notice {
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
}

.api-investment-errors {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.api-investment-errors .exclamation-mark {
  height: 55px;
  width: 55px;
  margin-bottom: 10px;
}

.api-investment-errors .modal-heading {
  color: #2b2e38;
  font-size: 22px;
  font-family: 'latoregular';
  margin-bottom: 22px;
}

@media (max-width: 321px) {
  .api-investment-errors {
    padding: 25px;
  }
  .api-investment-errors .exclamation-mark, .api-investment-errors .modal-heading {
    display: none;
  }
}

.api-investment-errors .my-modal-container {
  display: flex;
  flex-direction: column;
  box-shadow: none;
}

.api-investment-errors .my-modal-container .my-modal-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  max-width: 650px;
}

.api-investment-errors .my-modal-container .my-modal-item .icon {
  width: auto !important;
}

.api-investment-errors .my-modal-container .my-modal-item p.modal-p {
  font-size: 16px;
  line-height: 24px;
  margin-left: 15px;
  display: inline-block;
  width: initial;
  padding-bottom: 0;
  float: none;
}

.api-investment-errors .my-modal-container a.modal-link {
  color: #71baed;
}

.api-investment-errors button.close {
  border-radius: 3px;
  padding: 10px 20px;
  margin: 10px;
}

.api-investment-errors button.cancel {
  border-radius: 3px;
  padding: 10px 20px;
  background-color: #f0ad4e;
  margin: 10px;
}

.up-bio-maintext:empty:after {
  color: #7e7e7e;
  font-family: "latolight";
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
  padding-bottom: 32px;
}

.img-responsive img {
  width: 100%;
}

.submitting-modal {
  background-color: white;
  border-radius: 5px;
}

.submitting-modal .partner-subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  padding-bottom: 0;
}

.submitting-modal .partner-subscribe-container h2 {
  font-size: 30px;
  margin-bottom: 45px;
  text-align: center;
}

.submitting-modal .partner-subscribe-container .exclamation-mark {
  text-align: center;
  width: 60px;
  margin-bottom: 30px;
}

.submitting-modal .partner-subscribe-container button {
  border-radius: 3px;
}

.submitting-modal .submitting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.submitting-modal .submitting-container h2 {
  font-size: 30px;
  margin-bottom: 45px;
}

.submitting-modal #circleG {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.submitting-modal .circleG {
  background-color: #5ba9e0;
  height: 30px;
  width: 30px;
  animation-name: bounce_circleG;
  -o-animation-name: bounce_circleG;
  -ms-animation-name: bounce_circleG;
  -webkit-animation-name: bounce_circleG;
  -moz-animation-name: bounce_circleG;
  animation-duration: 2.24s;
  -o-animation-duration: 2.24s;
  -ms-animation-duration: 2.24s;
  -webkit-animation-duration: 2.24s;
  -moz-animation-duration: 2.24s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  border-radius: 24px;
  -o-border-radius: 24px;
  -ms-border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
}

.submitting-modal #circleG_1 {
  margin-left: 0;
  animation-delay: 0.45s;
  -o-animation-delay: 0.45s;
  -ms-animation-delay: 0.45s;
  -webkit-animation-delay: 0.45s;
  -moz-animation-delay: 0.45s;
}

.submitting-modal #circleG_2 {
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

.submitting-modal #circleG_3 {
  margin-right: right;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

@keyframes bounce_circleG {
  0% {
  }
  50% {
    background-color: white;
  }
  100% {
  }
}

@-o-keyframes bounce_circleG {
  0% {
  }
  50% {
    background-color: white;
  }
  100% {
  }
}

@-ms-keyframes bounce_circleG {
  .submitting-modal 50% {
    background-color: white;
  }
}

@-webkit-keyframes bounce_circleG {
  0% {
  }
  50% {
    background-color: white;
  }
  100% {
  }
}

@-moz-keyframes bounce_circleG {
  0% {
  }
  50% {
    background-color: white;
  }
  100% {
  }
}

a.completedNoGo:hover {
  cursor: default !important;
}

#agreement-text h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

#accept-terms-direct-debits {
  padding-left: 20px;
  padding-right: 20px;
}

#accept-terms-direct-debits ol {
  margin-left: 20px;
  list-style-type: lower-alpha;
}

#accept-terms-direct-debits ol p {
  margin-bottom: 5px;
  line-height: 16px;
}

#accept-terms-direct-debits ol li {
  margin-bottom: 10px;
  line-height: 16px;
}

#accept-terms-direct-debits ol li ol.inner-list {
  margin-top: 5px;
  list-style-type: lower-roman;
}

strong {
  font-family: 'latoregular';
}

#agreement-text p {
  margin-bottom: 10px;
}

#agreement-text h3 {
  font-family: 'latoregular';
  font-size: 18px;
  margin-bottom: 10px;
}

.warning-static-ul {
  margin-bottom: 10px;
}

.warning-static-ul li {
  margin-bottom: 10px;
}

#warning-text p, #warning-text h3 {
  margin-bottom: 10px;
}

#warning-text h3 {
  font-family: 'latoregular';
  font-size: 18px;
}

a.invest-link {
  font-size: 14px;
  font-family: 'latolight';
  vertical-align: middle;
}

.reg-log-leftcol > .default-warning-tooltip {
  padding: 20px 20px 20px 60px;
  background-position: 20px 17px;
}

#invest-text {
  padding-bottom: 60px;
}

#invest-text h1 {
  margin: 0px;
  padding: 0px 0px 20px 0px;
  font-family: 'latolight';
  font-size: 26px;
  color: #7e7e7e;
  position: relative;
  text-transform: uppercase;
}

#invest-text h1:after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 85px;
  height: 3px;
  background-color: #469edc;
  content: "";
}

#invest-text h2 {
  margin-top: 50px;
  font-family: "latolight";
  font-size: 24px;
  color: #7c7c7c;
}

#invest-text h3 {
  margin-top: 30px;
  font-family: "latolight";
  font-size: 20px;
  color: #7e7e7e;
}

#invest-text p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
}

#invest-text li {
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;
}

.center {
  text-align: center;
  display: block;
}

.prospectus-info {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.prospectus-info p {
  padding: 20px;
  color: #7e7e7e;
  font-family: "latolight";
  font-size: 16px;
  line-height: 24px;
}

.prospectus-info a {
  background-color: #68cfad;
  color: white;
  font-family: "latoregular";
  padding: 15px;
  width: 250px;
  display: block;
  margin: 0 auto;
}

@media all and (max-width: 414px) {
  .prospectus-info a {
    width: 100%;
  }
}

a:hover {
  text-decoration: none;
}

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media all and (max-width: 736px) {
  .modal-dialog {
    margin-top: 200px;
  }
}

#mySophisticatedModal2 .modal-body, #mySophisticatedModal0 .modal-body {
  text-align: center;
  padding: 20px;
}

#mySophisticatedModal2 .modal-body .glyphicon.glyphicon-thumbs-up, #mySophisticatedModal0 .modal-body .glyphicon.glyphicon-thumbs-up {
  font-size: 38px;
  color: #469edc;
  margin: 15px;
}

#mySophisticatedModal2 .modal-body .glyphicon.glyphicon-exclamation-sign, #mySophisticatedModal0 .modal-body .glyphicon.glyphicon-exclamation-sign {
  font-size: 38px;
  color: #FF7F00;
  margin: 15px;
}

#mySophisticatedModal2 .modal-body p, #mySophisticatedModal0 .modal-body p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: 'latoregular';
  margin-top: 20px;
}

#mySophisticatedModal2 .modal-body button, #mySophisticatedModal0 .modal-body button {
  margin: 10px;
  margin-top: 60px;
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background 0.3s ease;
}

#mySophisticatedModal2 .modal-body button:hover, #mySophisticatedModal0 .modal-body button:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
}

.syndicates-buttons-container {
  text-align: center;
}

#number-swapper .od-mc-td-amount-invested {
  font-size: 40px;
  color: #68cfad;
  float: right;
  font-family: 'latoregular';
}

@media all and (max-width: 1023px) {
  #number-swapper .od-mc-td-amount-invested {
    font-size: 25px;
  }
}

#number-swapper .underarm-investment {
  display: none;
  font-size: 40px;
  color: #469EDC;
  float: right;
  font-family: 'latoregular';
}

@media all and (max-width: 1023px) {
  #number-swapper .underarm-investment {
    font-size: 25px;
  }
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont p.private-p {
  margin-top: 10px;
}

.p-underwrite-remaining {
  display: none;
}
