@import 'extensions/variables';
@import 'extensions/mixins';

#dit-top-banner-sec {
  background: url(/images/landing-pages/dit-Newimage.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: table;
  @include mquery (768px) {
    background-image: url(/images/landing-pages/dit-Newimage-mobile.jpg);
  }
}
.dit-logo {
  width: 300px;
  margin-bottom: 20px;
}
.dit-align-top-table {
  height: 100%;
  width: 750px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.6);
  @include mquery(1024px) {
    width: 100%;
  }
}
.dit-link {
  color: #105090;
  text-decoration: underline;
  &:hover {
    color: darken(#105090, 1.9);
  }
}
.dit-link-white {
  color: #fff;
  text-decoration: underline;
  transition: .5s;
  &:hover {
    background-color: #ccc;
  }
}

.dit-button-style {
  background-color: #105090;
  border: 1px solid #105090;
  &:hover {
    background-color: darken(#105090, 1.9);
    color: #fff;
  }
}

.dit-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #105090;
  border: 1px solid #105090;
  color: #fff;
  transition: all 300ms ease;
  animation: bounce 3s;
}

.dit-view-offer-btn:hover {
  background-color: darken(#105090, 1.9);
}

#dit-second-section {
  background-color: #105090;
  padding: 60px 0;
}
#dit-third-section {
  background-color: #fff;

  .dit-third-row {
    overflow: hidden;
  }
  .dit-third-half-img {
    background: url(/images/landing-pages/dit-sheep-eating.jpg) no-repeat;
    background-size: cover;
    height: 600px;
    background-position: right;
    @include mquery(1500px) {
      height: 650px;
    }
    @include mquery(1380px) {
      height: 730px;
    }
  }
  .dit-sec-three-content {
    padding: 50px;
    @include mquery(768px) {
      padding: 50px 15px;
    }
  }
}
#dit-paralax-sec{
  background-attachment: fixed;
  background-image: url(/images/landing-pages/dit-top-banner-car.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}
.dit-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}
#dit-last-table-sec {
  display: flex;
  flex-direction: row;
  @include mquery(1024px) {
    flex-wrap: wrap;
  }
}
.dit-last-form-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 460px;
  width: 32%;
  @include mquery(1420px) {
    width: 52%;
  }
  @include mquery(1024px) {
    width: 100%;
    order: 3;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.dit-cow-looking-img {
  background: url(/images/landing-pages/dit-half-cow.jpg) no-repeat center;
  background-size: cover;
  width: 34%;
  height: 460px;
  @include mquery(1024px) {
    width: 50%;
  }
  @include mquery(500px) {
    height: 180px;
  }
}
.dit-sheep-looking-img {
  background: url(/images/landing-pages/dit-sheep-looking.jpg) no-repeat center;
  background-size: cover;
  height: 460px;
  width: 34%;
  @include mquery(1024px) {
    width: 50%;
    order: 1;
  }
  @include mquery(500px) {
    height: 180px;
  }
}
.last-table-title{
  max-width: 601px;
  @include mquery(768px) {
    max-width: 391px;
  }
}
