/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
CREATE SYNDICATE
---------------------------------------------------------- */
.head-top-synd-title {
  float: left;
  margin: 0px;
  padding: 35px 0px 20px 0px;
  margin: 0 0 20px;
  font-family: 'latobold';
  font-size: 22px;
  color: #7e7e7e;
  position: relative;
  text-transform: uppercase;
}

@media all and (max-width: 580px) {
  .head-top-synd-title {
    font-size: 18px;
    padding-bottom: 10px;
  }
}

.head-top-synd-title:after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 85px;
  height: 3px;
  background-color: #469edc;
  content: "";
}

@media all and (max-width: 580px) {
  .head-top-synd-title:after {
    width: 60px;
    height: 2px;
  }
}

/* Create syndicate first step */
.cs-firststep-wrapper {
  text-align: center;
}

.cs-firststep-container {
  display: inline-block;
  width: 100%;
  max-width: 710px;
  padding: 25px;
  background-color: #ffffff;
  margin: 60px 0px 0px 0px;
}

.cs-fs-form {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

/* Create syndicate second step */
.cs-secondstep-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cs-secondstep-container .cs-ss-heading-container {
  float: left;
  margin: 40px 0px 0px 0px;
  padding: 25px;
  background-color: #ffffff;
}

.cs-ss-form-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cs-ss-form-container > div.half {
  float: left;
  width: 50%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.cs-ss-form-container > div.half:nth-child(1) {
  padding-right: 10px;
}

.cs-ss-form-container > div.half:nth-child(2) {
  padding-left: 10px;
}

@media all and (max-width: 1060px) {
  .cs-ss-form-container > div.half {
    width: 100%;
  }
  .cs-ss-form-container > div.half:nth-child(1), .cs-ss-form-container > div.half:nth-child(2) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.cs-ss-form-container > div.half > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 25px;
  background-color: #ffffff;
  position: relative;
  min-height: 580px;
}

@media all and (max-width: 1060px) {
  .cs-ss-form-container > div.half > div {
    min-height: auto;
  }
}

.cs-ss-form-container > div.full {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.cs-ss-form-container > div.full > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 25px;
  background-color: #ffffff;
  position: relative;
}

.cs-ss-form-group {
  padding: 0px 350px 0px 0px;
}

.cs-ss-form-group .sfg-field {
  padding: 0px 10px 0px 0px;
  max-width: 350px;
}

@media all and (max-width: 1210px) {
  .cs-ss-form-group {
    padding: 0px 275px 0px 0px;
  }
  .cs-ss-form-group .sfg-field {
    max-width: 275px;
  }
}

@media all and (max-width: 1060px) {
  .cs-ss-form-group {
    padding: 0px 350px 0px 0px;
  }
  .cs-ss-form-group .sfg-field {
    max-width: 350px;
  }
}

@media all and (max-width: 620px) {
  .cs-ss-form-group {
    padding: 0px 275px 0px 0px;
  }
  .cs-ss-form-group .sfg-field {
    max-width: 275px;
  }
}

@media all and (max-width: 580px) {
  .cs-ss-form-group {
    padding: 0px;
  }
  .cs-ss-form-group .sfg-field {
    max-width: 100%;
    padding: 0px 10px 10px 10px;
  }
}

.cs-ss-choose-role {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px 370px 0px 0px;
  position: relative;
}

@media all and (max-width: 980px) {
  .cs-ss-choose-role {
    padding: 0px;
  }
}

.cs-ss-choose-role-input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.cs-ss-chose-role-filegroup .sfg-field {
  padding: 0px 60px 0px 10px;
}

@media all and (max-width: 580px) {
  .cs-ss-chose-role-filegroup .sfg-field {
    padding: 0px 30px 0px 5px;
  }
}

.cs-ss-chose-role-filegroup .sfg-field > button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-image: url(../../images/cert-upload-btn-icon.png);
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cs-ss-chose-role-filegroup .sfg-field > button:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 580px) {
  .cs-ss-chose-role-filegroup .sfg-field > button {
    top: 1px;
  }
}

.cs-ss-cr-adduser {
  padding: 0px 330px 0px 0px;
}

.cs-ss-cr-adduser .sfg-field {
  max-width: 340px;
}

@media all and (max-width: 580px) {
  .cs-ss-cr-adduser {
    padding: 0px 0px 10px 0px;
  }
  .cs-ss-cr-adduser .sfg-field {
    max-width: 100%;
  }
}

.cs-ss-cr-tabs {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  max-width: 360px;
}

@media all and (max-width: 980px) {
  .cs-ss-cr-tabs {
    position: relative;
    top: auto;
    right: auto;
    float: left;
    max-width: 100%;
  }
}

.cs-ss-cr-tabs > a {
  float: left;
  width: 50%;
  height: 60px;
  margin: 0px;
  padding: 15px 20px;
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 15px;
  background-color: #8f9ea9;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
}

.cs-ss-cr-tabs > a:hover, .cs-ss-cr-tabs > a.active {
  background-color: #9daab4;
}

@media all and (max-width: 980px) {
  .cs-ss-cr-tabs > a {
    height: 35px;
    padding: 9px 20px;
    text-transform: none;
    font-size: 14px;
  }
}

@media all and (max-width: 450px) {
  .cs-ss-cr-tabs > a {
    width: 100%;
  }
}

.cs-cr-results-container {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  position: relative;
  text-align: center;
}

.cs-cr-results-container .cs-cr-no-results {
  display: none;
  width: 100%;
  max-width: 500px;
  margin: 0px;
  padding: 50px 0px;
}

.cs-cr-results-container .cs-cr-no-results > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  font-family: 'latolight';
  font-size: 14px;
  line-height: 18px;
  color: #7e7e7e;
  text-align: center;
}

.cs-cr-results-container .cs-cr-result-blocks-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block {
  float: left;
  width: 33.3%;
  margin: 0px;
  padding: 10px;
}

@media all and (max-width: 1023px) {
  .cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block {
    width: 50%;
  }
}

@media all and (max-width: 660px) {
  .cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block {
    width: 100%;
    padding: 10px 0px;
  }
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div {
  float: left;
  width: 100%;
  min-height: 95px;
  margin: 0px;
  padding: 0px 0px 0px 95px;
  position: relative;
  background-color: #f3f5f6;
  border-style: solid;
  border-color: #e9eaea;
  border-width: 1px;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div > img {
  position: absolute;
  top: 8px;
  left: 15px;
  margin: 0px;
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 40px;
  border-style: solid;
  border-color: #d8dadb;
  border-width: 4px;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div > div {
  float: left;
  width: 100%;
  height: 100%;
  min-height: 93px;
  margin: 0px;
  padding: 13px 15px 15px 15px;
  position: relative;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: right;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  text-transform: uppercase;
  line-height: 18px;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div > div > a {
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 10px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div > div > a:hover {
  text-decoration: underline;
}

.cs-cr-results-container .cs-cr-result-blocks-cont .cs-cr-result-block > div .cs-cr-result-block-remove {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  background-image: url(../../images/cr-result-block-remove.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.syndicate-hiw-blocks-wrapper {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px 0px 30px 0px;
  text-align: center;
}

.shiw-blocks-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: 'montserratbold';
  font-size: 28px;
  color: #898888;
}

.syndicate-hiw-blocks-wrapper .shiw-block {
  margin: 0px 18px 0px;
  padding: 45px 10px;
  width: 100%;
}

.syndicate-hiw-blocks-wrapper .shiw-block:first-of-type {
  margin-left: 0px;
  margin-right: 18px;
}

.syndicate-hiw-blocks-wrapper .shiw-block:last-of-type {
  margin-right: 0px;
  margin-left: 18px;
}

.about-us-our-investors {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  text-align: center;
}

.about-us-our-investors > a {
  display: inline-block;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  width: 300px;
  height: 90px;
  border: 1px solid transparent;
}

.about-us-our-investors > a:hover {
  border: 1px solid #e1e1e1;
}

.syndicate-hiw-blocks-wrapper {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px 0px 30px 0px;
}

.shiw-blocks-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latobold';
  font-size: 28px;
  color: #898888;
}

/* Dijana update - 03.08.2016 */
.all-syndicates-title {
  border-top: 1px solid #d5d5d5;
  margin: 40px 0 0 0;
  padding: 40px 0 0 0;
}

/* Dijana update - 03.08.2016 */
.shiw-blocks-subtitle {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 16px;
  color: #898888;
  text-align: center;
}

.shiw-block {
  display: inline-block;
  margin: 0px 15px;
  padding: 0px;
  width: 100%;
  max-width: 300px;
  min-height: 300px;
  border-width: 1px;
  border-style: solid;
  border-color: #d3dcde;
  background-color: #ffffff;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
  position: relative;
}

.shiw-block > img {
  display: inline-block;
  margin: 0;
  padding: 0px;
  float: none;
}

.shiw-block > div {
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
  padding: 0px 20px;
  position: absolute;
  top: 160px;
  left: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.syndicate-inner-cont {
  bottom: 44px !important;
}

.shiw-block > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  text-align: center;
  font-family: 'latobold';
  color: #898888;
  font-size: 18px;
  text-transform: uppercase;
}

.shiw-block > div > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #898888;
  border-top-width: 1px;
  border-style: solid;
  border-color: #e2e2e2;
  text-align: center;
}

.syn-blocks-buttons {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
