/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#firstcheck-top-banner #firstcheck-top-banner-layer, #firstcheck-story-sec-one .firstcheck-story-video-bk, #firstcheck-stats-sec-2 .firstcheck-stats-circle, #firstcheck-story-sec-three .firstcheck-story-sec-three-bk {
  display: flex;
  align-items: center;
  justify-content: center;
}

#firstcheck-story-sec-one .firstcheck-story-video-bk, #firstcheck-story-sec-three .firstcheck-story-sec-three-bk {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#firstcheck-top-banner {
  background-image: url("/images/landing-pages/banners/Firstcheck-home image-menu-screen.jpg");
  height: calc(100vh - 70px);
  width: 100%;
  background-color: #fff;
  display: table;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

@media all and (max-width: 600px) {
  #firstcheck-top-banner {
    background-image: url("/images/landing-pages/banners/Firstcheck-home image-menu-screen-small.jpg");
  }
}

#firstcheck-top-banner #firstcheck-top-banner-layer {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  padding: 50px 0;
  height: calc(100vh - 70px);
}

#firstcheck-top-banner #firstcheck-top-banner-layer .firstcheck-logo {
  margin-bottom: 40px;
  width: 350px;
}

@media all and (max-width: 500px) {
  #firstcheck-top-banner #firstcheck-top-banner-layer .firstcheck-logo {
    width: 250px;
  }
}

#firstcheck-top-banner #firstcheck-top-banner-layer .firstcheck-title-width {
  max-width: 600px;
  margin: 0 auto 40px;
}

#firstcheck-story-sec-one {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-one {
    padding: 0 0 60px;
  }
}

#firstcheck-story-sec-one .firstcheck-story-sec-one-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-one .firstcheck-story-sec-one-text {
    padding: 60px 0 0;
  }
}

#firstcheck-story-sec-one .firstcheck-story-video-bk {
  background-image: url(/images/landing-pages/banners/firstcheck-story-bk.jpg);
  height: 440px;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-one .firstcheck-story-video-bk {
    margin-top: 60px;
  }
}

@media all and (max-width: 768px) {
  #firstcheck-story-sec-one .firstcheck-story-video-bk {
    height: 210px;
  }
}

#firstcheck-story-sec-one .firstcheck-story-video-bk .firstcheck-story-video-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(1, 1, 1, 0.7);
}

#firstcheck-story-sec-one .firstcheck-story-video-bk .firstcheck-video-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: latoregular;
}

#firstcheck-story-sec-one .firstcheck-story-video-bk .firstcheck-video-btn {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  transition: .3s;
  width: 120px;
  text-align: center;
  cursor: pointer;
}

#firstcheck-story-sec-one .firstcheck-story-video-bk .firstcheck-video-btn:hover {
  background-color: #229dd4;
  border: 1px solid #229dd4;
}

#firstcheck-story-sec-one .firstcheck-story-video-bk .firstcheck-story-video-cnt {
  z-index: 10;
}

#firstcheck-stats-sec {
  padding: 60px 0;
  background-color: #229dd4;
}

#firstcheck-stats-sec .firstcheck-stats-cnt {
  text-align: center;
  margin: 0 auto 20px;
}

.firstcheck-stats-img {
  width: 70px;
}

@media all and (max-width: 767px) {
  .firstcheck-stats-margin {
    margin-bottom: 40px;
  }
}

#firstcheck-stats-sec-2 {
  padding: 60px 0;
}

#firstcheck-stats-sec-2 .firstcheck-stats-circle {
  background-color: #229dd4;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  padding: 25px;
  text-align: center;
  margin: 0 auto 20px;
}

#firstcheck-stats-sec-2 .firstcheck-stats-text {
  color: #229dd4;
}

#firstcheck-story-sec-three {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-three {
    padding: 0;
  }
}

#firstcheck-story-sec-three .firstcheck-story-sec-three-bk {
  background-image: url(/images/landing-pages/banners/firstcheck-story-sec-two.jpg);
  height: 470px;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-three .firstcheck-story-sec-three-bk {
    margin-top: 60px;
  }
}

@media all and (max-width: 768px) {
  #firstcheck-story-sec-three .firstcheck-story-sec-three-bk {
    height: 210px;
  }
}

#firstcheck-story-sec-three .firstcheck-story-sec-three-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-three .firstcheck-story-sec-three-text {
    padding: 60px 0 0;
  }
}

#firstcheck-story-sec-four {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #firstcheck-story-sec-four {
    padding: 0 0 60px 0;
  }
}

#firstcheck-story-sec-four .firstcheck-story-sec-four-text {
  padding: 0 20px;
}

#firstcheck-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/banners/firstcheck-parallax-banner.jpg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 600px) {
  #firstcheck-paralax-sec {
    background-image: url("/images/landing-pages/banners/firstcheck-parallax-banner-mobile.jpg");
  }
}

@media all and (max-width: 768px) {
  #firstcheck-paralax-sec {
    background-attachment: initial;
  }
}

#firstcheck-paralax-sec .firstcheck-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}

#firstcheck-last-form-cnt {
  background: #229dd4;
  padding: 60px 0;
}

.firstcheck-bottom-button-style {
  background-color: transparent;
  border: 2px solid #fff !important;
  transition: .3s;
}

.firstcheck-bottom-button-style:hover {
  background-color: white;
  border: 2px solid #fff !important;
  color: #333;
}

.firstcheck-bottom-link {
  color: #333;
  transition: .2s;
}

.firstcheck-bottom-link:hover {
  color: #333;
  text-decoration: underline;
}

.firstcheck-link {
  color: #229dd4;
}

.firstcheck-link:hover {
  color: #2095c9;
  text-decoration: underline;
}

.firstcheck-button-style {
  background-color: #229dd4;
  color: #fff;
  transition: all 300ms ease;
}

.firstcheck-button-style:hover {
  background-color: #229dd4;
  color: #fff;
  opacity: 0.8;
}
