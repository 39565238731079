/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
HOW TO INVEST
---------------------------------------------------------- */
#mn-how-to-invest-top-banner {
  background-image: url(../../images/how-to-invest-header-image.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: table;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  #mn-how-to-invest-top-banner {
    height: 50%;
    padding: 50px 0;
  }
}

#mn-how-to-invest-top-banner .mn-hti-top-banner-content-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mn-how-to-invest-top-banner .mn-hti-top-banner-content-container .mn-how-to-invest-title {
  font-size: 48px;
  font-family: latoregular;
  margin-bottom: 22px;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  #mn-how-to-invest-top-banner .mn-hti-top-banner-content-container .mn-how-to-invest-title {
    font-size: 38px;
  }
}

@media all and (max-width: 600px) {
  #mn-how-to-invest-top-banner .mn-hti-top-banner-content-container .mn-how-to-invest-title {
    font-size: 32px;
  }
}

@media all and (max-width: 736px) {
  #mn-how-to-invest-top-banner {
    background-image: url(../../images/hti-bg-image-min.jpg);
  }
}

.mn-hti-gstart-title {
  font-family: latoregular;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  color: #469edc;
  margin-bottom: 34px;
}

.mn-hti-gstart-subtitle {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 75px;
}

.mn-hti-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mn-hti-flex-container .mn-hti-tile {
  border-radius: 10px;
  padding: 30px 31px;
  width: 450px;
  height: 274px;
  margin-right: 10px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-hti-flex-container .mn-hti-tile {
    margin-bottom: 30px;
    margin-right: 16px;
  }
}

@media all and (max-width: 736px) {
  .mn-hti-flex-container .mn-hti-tile {
    height: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.mn-hti-flex-container .mn-hti-tile .mn-hti-gstart-sm-title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 26px;
}

.mn-hti-flex-container .mn-hti-tile .mn-hti-gstart-sm-subtitle {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  line-height: 24px;
}

.mn-hti-flex-container .mn-blue-border {
  border: solid 2px #469edc;
  margin-top: 10px;
}

.mn-hti-flex-container .mn-pink-border {
  border: solid 2px #de518e;
  margin-top: 10px;
}

.mn-hti-flex-container .mn-green-border {
  border: solid 2px #68cfad;
  margin-top: 10px;
}

.mn-hti-flex-container .mn-blue-title {
  color: #469edc;
}

.mn-hti-flex-container .mn-pink-title {
  color: #de518e;
}

.mn-hti-flex-container .mn-green-title {
  color: #68cfad;
}

.mn-hti-access-offers-container {
  background-image: url(../../images/hti-access-offer-min.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 60%;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
  z-index: -1;
}

.mn-hti-access-offers-container .mn-hti-access-offers-title-container {
  padding-top: 109px;
}

.mn-hti-access-offers-container .mn-hti-access-offers-title-container .mn-hti-access-offers-title {
  font-family: latoregular;
  font-size: 40px;
  text-align: center;
  color: #fff;
  margin-bottom: 33px;
}

.mn-hti-access-offers-container .mn-hti-access-offers-title-container .mn-hti-access-offers-subtitle {
  font-size: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 89px;
  max-width: 756px;
  margin-right: auto;
  margin-left: auto;
}

.mn-view-deals-container {
  padding-top: 114px;
  padding-bottom: 109px;
}

.mn-view-deals-container .mn-view-deals-title {
  font-family: Latoregular;
  font-size: 40px;
  text-align: center;
  color: #333;
  margin-bottom: 50px;
}

.mn-customer-story-background {
  background-image: url(../../images/customer-story-bg-min.jpg);
  width: 100%;
  height: 602px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 164px;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  color: #fff;
  line-height: 10px;
}

.mn-customer-story-background .mn-custome-story-container {
  max-width: 441px;
}

.mn-customer-story-background .mn-custome-story-container .mn-customer-story-quote {
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 29px;
}

@media all and (max-width: 500px) {
  .mn-customer-story-background .mn-custome-story-container .mn-customer-story-quote {
    text-align: center;
  }
}

@media all and (max-width: 500px) {
  .mn-customer-story-background .mn-hti-ceo-name {
    text-align: center;
  }
}

.mn-customer-story-background .mn-customer-story-speech-bubble {
  margin-bottom: 25px;
}

@media all and (max-width: 600px) {
  .mn-customer-story-background .mn-customer-story-speech-bubble {
    text-align: center;
  }
}

.mn-customer-story-background .mn-customer-story-speech-bubble .mn-speech-bubble-img {
  margin-right: 11px;
}

.mn-hti-single-tile {
  border: 1px solid #e1e1e1;
  max-width: 738px;
  padding: 20px;
  margin: auto;
}

@media all and (max-width: 1200px) {
  .mn-hti-single-tile {
    max-width: 357px;
  }
}

.mn-hti-list-container {
  margin-left: 20px;
}

@media all and (max-width: 400px) {
  .mn-hti-list-container {
    margin-left: 0;
  }
}

.mn-hti-list-container .mn-hti-list {
  font-size: 16px;
  line-height: 70px;
}

@media all and (max-width: 400px) {
  .mn-hti-list-container .mn-hti-list {
    font-size: 15px;
  }
}

.mn-hti-free-container {
  margin-top: 80px;
}

@media all and (max-width: 1200px) {
  .mn-hti-free-container {
    margin-top: 0;
  }
}

.mn-check-icon {
  margin-right: 19px;
}

.mn-hti-all-delas-container {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
}

.mn-glyphicon-left {
  margin-left: 10px !important;
}

.mn-glyphicon-right {
  margin-right: 10px !important;
}
