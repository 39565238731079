@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
OFFER DETAILS
---------------------------------------------------------- */
.od-project-name-header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 60px;
    background-color: #313131;
    z-index: 1005;
    visibility: hidden;
    opacity: 0;
    @include transition(.3s);
    &.od-pnh-visible {
        visibility: visible;
        opacity: 1;
    }
    > div {
        margin: 0px auto;
        width: 100%;
        max-width: 1200px;
        padding: 0px;
        min-height: 60px;
        position: relative;
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 17px 240px 3px 55px;
            font-family: 'latobold';
            font-size: 22px;
            color: #ffffff;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            @include mquery(768px) {
                padding: 21px 130px 3px 55px;
            }
            @include mquery(550px) {
                font-size: 16px;
            }
        }
        > a {
            background-color: #aad155;
            background-image: url("../../images/invest-btn-icon.png");
            background-position: right 15px center;
            background-repeat: no-repeat;
            border: 0 none;
            color: #ffffff;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 0px;
            font-family: "latoblack";
            font-size: 14px;
            margin: 0;
            padding: 13px 45px 15px 15px;
            text-transform: uppercase;
            @include transition(.3s);
            width: 220px;
            height: 40px;
            &:before {
                content: "Invest in this offer";
            }
            @include mquery(768px) {
                width: 110px;
                &:before {
                    content: "Invest";
                }
            }
            &:hover {
                background-color: lighten(#aad155, 5%);
            }
            @include mquery(1240px) {
                right: 10px;
            }

        }
    }
}
.od-pnh-share-cont {
    position: absolute;
    top: 10px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    @include mquery(1240px) {
        left: 10px;
    }

    > a {
        float: left;
        width: 40px;
        height: 40px;
        margin: 0px;
        padding: 0px;
        border-radius: 20px;
        background-color: #68cfad;
        background-image: url(../../images/offer-details-share-icon.png);
        background-repeat: no-repeat;
        background-position: center center;
        @include transition(.3s);
        position: relative;
        z-index: 2;
        &:hover {
            background-color: darken(#68cfad, 5%);
        }
    }
    > div {
        float: left;
        margin: 0px;
        padding: 8px 0px 0px 0px;
        height: 40px;
        border-radius: 0px 20px 20px 0px;
        background-color: #68cfad;
        position: relative;
        left: -20px;
        z-index: 1;
        width: 0;
        padding-left: 0;
        overflow: hidden;
        > a {
            float: left;
            margin: 0px 10px 10px 0px;
            padding: 0px;
            > img {
                float: left;
                margin: 0px;
                padding: 0px;
            }
        }
        //@include mquery(580px) {
        //    width: 125px;
        //    height: 135px;
        //    padding: 13px 25px;
        //    border-radius: 0px 20px 20px 20px;
        //}
    }
}
.od-ts-share-cont {
    right: 15px;
    top: -70px;
    left: auto;
    > a {
        float: right;
    }
    > div {
        border-radius: 20px 0px 0px 20px;
        left: 20px;
    }
}
#od-key-statistics {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.offer-details-top-section {
    float: left;
    width: 100%;
    min-height: 440px;
    margin: 0px;
    padding: 0px;
    position: relative;
    background-image: url('../../images/offer-details-ts-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include mquery(1240px) {
        padding: 0px 10px;
    }
    &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $img-overlay;
    }
    .od-ts-inner-cont {
        margin: 0px auto;
        width: 100%;
        max-width: 1200px;
        padding: 0px;
        position: relative;
        .od-ts-info-cont {
            float: left;
            width: 100%;
            margin: 95px 0px 0px 0px;
            padding: 0px 0px 0px 220px;
            position: relative;
            text-align: center;
            @include mquery(1240px) {
                padding: 0px 0px 150px 180px;
            }
            @include mquery(580px) {
                padding: 0px 0px 150px 0px;
            }
            .od-ts-logo {
                position: absolute;
                top: 0px;
                left: 0px;
                margin: 0px;
                padding: 0px;
                @include mquery(580px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: inline-block;
                }
                > img {
                    float: left;
                    width: 195px;
                    height: 195px;
                    margin: 0px;
                    padding: 0px;
                    @include mquery(1240px) {
                        width: 160px;
                        height: 160px;
                    }
                }
            } /* Offer Details logo container ends here */
            .od-ts-info-details {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                position: relative;
                > p {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    font-family: 'latolight';
                    font-size: 18px;
                    color: $od-ts-text;
                    line-height: 1.5;
                    @include mquery(1240px) {
                        font-size: 15px;
                    }
                    @include mquery(580px) {
                        text-align: center;
                        margin-top: 10px;
                    }
                }
                > span {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    color: $od-ts-text;
                    text-transform: uppercase;
                    @include mquery(580px) {
                        text-align: center;
                    }
                    &.od-ts-id-title {
                        font-size: 35px;
                        font-family: 'latobold';
                        @include mquery(1240px) {
                            font-size: 30px;
                            margin-top: 10px;
                        }
                    }
                    &.od-ts-id-subtitle {
                        font-family: 'latolight';
                        font-size: 18px;
                        line-height: 1.5;
                        @include mquery(1240px) {
                            font-size: 15px;
                            margin-top: 10px;
                        }
                    }
                }
                > div {
                    float: left;
                    width: 100%;
                    margin: 30px 0px 0px 0px;
                    padding: 0px;
                    text-align: center;
                    > a {
                        float: left;
                        margin: 0px 10px 10px 0px;
                        padding: 0px;
                        text-decoration: none;
                        background-repeat: no-repeat;
                        color: $txt-white;
                        font-size: 35px;
                        @include transition(.3s);
                        &:hover{
                            color: $txt-main-color;
                        }
                        &.od-id-follow {
                            width: 125px;
                            height: 36px;
                            font-family: 'latobold';
                            font-size: 12px;
                            color: $od-ts-text;
                            padding: 8px 10px;
                            @include border(full, 3px, solid, $od-ts-text);
                            @include transition(.3s);
                            background-image: url('../../images/od-id-follow-arrow.png');
                            background-position: right 15px center;
                            border-radius: 20px;
                            &:hover {
                                background-position: right 10px center;
                            }
                        }
                        @include mquery(580px) {
                            display: inline-block;
                            float: none;
                            vertical-align: top;
                        }
                    }
                } /* Order Details top section buttons */
                > ul {
                    float: left;
                    width: 100%;
                    margin: 5px 0px 0px 0px;
                    padding: 0px;
                    text-align: center;
                    @include mquery(580px) {
                        margin-top: 15px;
                    }
                    > li {
                        float: left;
                        margin: 10px 10px 0px 0px;
                        padding: 0px;
                        list-style: none;
                        @include mquery(580px) {
                            display: inline-block;
                            float: none;
                            vertical-align: top;
                        }
                        > span {
                            float: left;
                            height: 26px;
                            margin: 0px;
                            padding: 6px 10px 6px 10px;
                            background-color: rgba(0,0,0,0.25);
                            font-family: 'latobold';
                            font-size: 12px;
                            color: $od-ts-text;
                            border-radius: 5px;
                            position: relative;
                            &:before{
                                content: none;
                            }
                        }
                    }
                }
            } /* Offer Details info details ends here */
        } /* Offer Details info container ends here */
    }
} /* Offer Details top section ends here */

.manrags-offer-image {
  background-image: url(/images/manrags-offers-image.jpg);
  @include mquery(768px) {
    background-image: url(/images/manrags-offer-sm-min.jpg);
  }
}

.offer-details-main-content {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
    @include mquery(1240px) {
        padding: 0px 10px;
    }
    .od-mc-inner-cont {
        position: relative;
        top: -65px;
        margin: 0px auto;
        width: 100%;
        max-width: 1200px;
        padding: 0px;
        background-color: $od-white-bg;
        clear: both;
        z-index: 10;
        .offer-details-status-wrapper {
            position: absolute;
            top: -74px;
            left: 0px;
            width: 100%;
            margin: 0px;
            padding: 0px 15px;
            @include transition(.3s);
            z-index: 1;
            -webkit-animation: odStatusAnimation 1.5s forwards; /* Safari 4+ */
            -moz-animation:    odStatusAnimation 1.5s forwards; /* Fx 5+ */
            -o-animation:      odStatusAnimation 1.5s forwards; /* Opera 12+ */
            animation:         odStatusAnimation 1.5s forwards;
            > div {
                float: left;
                width: 100%;
                min-height: 56px;
                margin: 0px;
                padding: 0px 0px 0px 50px;
                border-radius: 5px 5px 0px 0px;
                background-color: #469edc;
                background-image: url('../../images/overfunding-status-icon.png');
                background-repeat: no-repeat;
                background-position: 15px center;
                .offer-details-status {
                    float: left;
                    margin: 0px;
                    padding: 13px 0px 0px;
                    font-size: 20px;
                    font-family: 'latoblack';
                    color: #fff;
                }
                > p {
                    float: right;
                    margin: 0px;
                    padding: 16px 15px;
                    font-family: 'latobold';
                    font-size: 14px;
                    color: #ffffff;
                    text-transform: uppercase;
                }
            }
        }
        .od-mc-top {
            float: left;
            width: 100%;
            margin: 0px;
            //padding: 30px 430px 30px 30px;
            position: relative;
            z-index: 2;
            min-height: 506px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            background: #fff;
            > div {
                flex: 1;
            }

            @include mquery(1023px) {
                display: block;
                padding: 30px;
            }
            .od-mc-video {
                float: left;
                width: auto;
                margin: 0px;
                padding: 0px;
                background-color: #000000;
                text-align: center;
                display: block;
                height: 508px;


                > div {
                    width: auto;
                    display: block;
                    vertical-align: middle;
                    position: relative;
                    //padding-bottom: 56.25%;
                    //padding-top: 30px; height: 0; overflow: hidden;
                    iframe, object, embed {
                        height: 508px;
                        width: 800px;
                        @include mquery(1220px) {
                            width: 600px;
                        }
                    }
                    > img {
                        max-height: 100%;
                        //float: left;
                        //width: 100%;
                    }
                }


                @include mquery(1023px) {
                    height: auto;
                    width: 100%;
                    iframe, object, embed {
                        width: 100% !important;
                    }
                    img {
                        width: 100%;
                        max-height: unset !important;
                    }
                }

                @include mquery(600px) {
                    iframe, object, embed {
                        height: 300px !important;

                    }
                }
                @include mquery(450px) {
                    iframe, object, embed {
                        height: 200px !important;

                    }
                }
            }
            .od-mc-top-details {
                box-sizing: border-box;
                //position: absolute;
                //top: 30px;
                //right: 30px;
                margin: 0px;
                padding: 30px;
                //width: 100%;
                max-width: 400px;
                flex: 1 0 400px;
                //@include mquery(1240px) {
                //    max-width: 330px;
                //}
                //@include mquery(1165px) {
                //      max-width: 290px;
                //}
                @include mquery(1023px) {
                    max-width: 100%;
                    float: left;
                    position: relative;
                    right: 0px;
                    padding: 0px;
                    top: 0px;
                    margin-top: 30px;
                    text-align: center;
                }
                > ul {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    > li {
                        float: left;
                        width: 100%;
                        margin: 20px 0px 0px 0px;
                        padding: 0px;
                        list-style: none;
                        &.bar-li {
                            > p {
                                margin-top: 0px;
                            }
                        }
                        &:first-of-type {
                            margin-top: 0px;
                            @include mquery(950px) {
                                padding: 8px 12px;
                            }
                        }
                        @include mquery(1023px) {
                            width: 16.5%;
                            padding: 0px 15px;
                            margin-top: 0px;
                            min-height: 60px;
                            @include border(right, 1px, solid, #ededed);
                            &:last-of-type {
                                border-right-width: 0px;
                            }
                        }
                        @include mquery(950px) {
                            width: 33.3%;
                            padding: 12px 15px;
                            @include border(bottom, 1px, solid, #ededed);
                            &:nth-child(3n+3) {
                                border-right-width: 0px;
                                @include mquery(690px) {
                                    border-right-width: 1px;
                                }
                                @include mquery(480px) {
                                    border-right-width: 0px;
                                }
                            }
                            &:nth-child(1n+4) {
                                border-bottom-width: 0px;
                                @include mquery(690px) {
                                    border-bottom-width: 1px;
                                }
                            }
                        }
                        @include mquery(690px) {
                            width: 50%;
                            &:nth-child(1n+5) {
                                border-bottom-width: 0px;
                            }
                            &:nth-child(2n+2) {
                                border-right-width: 0px;
                            }
                        }
                        @include mquery(480px) {
                            width: 100%;
                            border-right-width: 0px;
                            &:nth-child(1n+5) {
                                border-bottom-width: 1px;
                            }
                        }
                        > span {
                            float: left;
                            width: 100%;
                            margin: 0px;
                            padding: 0px;
                            font-family: 'latoblack';
                            font-size: 18px;
                            color: $od-mc-top-details-value;
                            text-align: right;
                            @include mquery(1023px) {
                                font-size: 13px;
                            }
                            &.od-mc-td-amount-invested {
                                font-size: 40px;
                                color: #68cfad;
                                @include mquery(1023px) {
                                    font-size: 25px;
                                }
                            }
                        }
                        > p {
                            float: left;
                            width: 100%;
                            margin: 5px 0px 0px 0px;
                            padding: 0px;
                            font-family: 'latoregular';
                            font-size: 18px;
                            color: $od-mc-top-details-valueTitle;
                            text-align: right;
                            @include mquery(1023px) {
                                font-size: 13px;
                            }
                        }
                        .od-mc-td-progresbar {
                            float: left;
                            width: 100%;
                            height: 10px;
                            margin: 10px 0px 0px 0px;
                            padding: 0px;
                            background-color: $od-progresbar-bg;
                            overflow: hidden;
                            > div {
                                float: left;
                                height: 30px;
                                margin: 0px;
                                padding: 0px;
                                background: #68cfad;
                            }
                        }
                    }
                } /* Top details list ends here */
                .invest-offer-button {
                    float: right;
                    width: 100%;
                    margin: 20px 0px 0px 0px;
                    padding: 15px 70px 15px 15px;
                    font-family: 'latoblack';
                    color: $default-submitBtn-color;
                    background-color: #68cfad;
                    @include transition(.3s);
                    background-image: url("../../images/invest-btn-icon.png");
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                    border: 0px;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:hover {
                        background-color: darken(#68cfad, 5%);
                    }
                    &.large {
                        height: 52px;
                        font-size: 14px;
                    }
                    &.funding-complete-inactive {
                        background-color: grey;
                        cursor: default;
                    }
                    @include mquery(1023px) {
                        max-width: 320px;
                        float: none;
                        display: inline-block;
                    }
                }
                .check-prospectus{
                    float: left;
                    color: $default-text-color;
                    font-family: "latolight";
                    font-size: 15px;
                    margin: 5px 0 0;
                    padding: 0;
                    width: 100%;
                    text-align: right;
                    @include mquery(1023px) {
                        text-align: center;
                        margin: 15px 0 0;
                    }
                    input[type=checkbox].default-checkbox + label.default-checkbox-label {
                        padding-left: 30px;
                        height: 20px;
                        line-height: 20px;
                        font-family: "latolight";
                        font-size: 14px;
                        /* Media queries */
                        @include mquery(479px) {
                            line-height: 16px;
                        }
                        @include mquery(330px) {
                            font-size: 12px;
                        }
                    }
                    input[type=checkbox].default-checkbox:checked + label.default-checkbox-label {
                        background-position: 0 -20px;
                    }
                    label.default-checkbox-label {
                        background-image:url('../../../images/checkbox-sprite-small.png');
                        > a {
                            text-decoration: none;
                            color: $check-prospectus-link;
                            font-family: 'latoregular';
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        } /* Offer details main content top section ends here */

        .od-mc-content-wrapper {
            float: left;
            width: 100%;
            padding: 0px;
            margin: 0px;
            position: relative;
            .od-mc-main-tabs-navigation {
                position: relative;
                z-index: 100;

                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                > div {
                    float: left;
                    width: 100%;
                    min-height: 40px;
                    margin: 0px 0px 1px 0px;
                    padding: 0px;
                    background-color: $default-blue-color;
                    display: none;
                    @include mquery(580px) {
                        display: inline-block;
                    }
                    > span {
                        float: left;
                        margin: 0px;
                        padding: 13px 15px;
                        font-family: 'latoblack';
                        font-size: 13px;
                        color: #ffffff;
                    }
                    .od-mc-main-tabs-menu {
                        float: right;
                        width: 22px;
                        height: 18px;
                        margin: 11px;
                        padding: 0px;
                        background-image: url('../../images/od-tabs-menu-icon.png');
                        background-repeat: no-repeat;
                        background-position: 0px 0px;
                        &.active {
                            background-position: 0px -18px;
                        }
                    }
                }
                .od-mc-main-tabs {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    position: relative;

                    @include mquery(580px) {
                        display: none;
                    }
                    > li {
                        float: left;
                        margin: 0px;
                        padding: 0px;
                        list-style: none;
                        position: relative;
                        @include border(right, 1px, solid, #ffffff);
                        &:last-of-type {
                            border-right: 0px;
                        }
                        > a {
                            float: left;
                            width: 100%;
                            min-height: 40px;
                            margin: 0px;
                            padding: 13px 10px;
                            text-decoration: none;
                            background-color: $od-main-tabs-bg;
                            font-family: 'latobold';
                            font-size: 13px;
                            color: #ffffff;
                            text-transform: uppercase;
                            text-align: center;
                            @include transition(.3s);
                            &:hover, &.active {
                                background-color: darken($od-main-tabs-bg, 10%);
                            }
                            @include mquery(930px) {
                                font-size: 11px;
                            }
                            @include mquery(800px) {
                                min-height: 48px;
                            }
                        }
                        .od-mc-main-tabs-counter {
                            position: absolute;
                            top: -15px;
                            right: 7px;
                            margin: 0px;
                            padding: 4px 0px;
                            width: 26px;
                            height: 26px;
                            background-color: $od-main-tabs-counter;
                            border-radius: 15px;
                            text-align: center;
                            font-family: 'latobold';
                            font-size: 9px;
                            color: #ffffff;
                            z-index: 10;
                            @include border(full, 4px, solid, #ffffff);
                            @include mquery(950px) {
                                top: auto;
                                bottom: -12px;
                            }
                            @include mquery(420px) {
                                bottom: 6px;
                            }
                        }
                        @include mquery(950px) {
                            width: 33.3% !important;
                            &:nth-child(-n+3) {
                                border-bottom-width: 1px;
                            }
                            &:nth-child(3) {
                                border-right: 0px;
                            }
                        }
                        @include mquery(580px) {
                            width: 50% !important;

                            &:nth-child(-n+3) {
                                border-bottom-width: 1px;
                            }
                            &:nth-child(3) {
                                border-right: 1px solid #ffffff !important;
                            }
                            border-bottom-width: 1px;
                            border-right-width: 1px !important;
                            &:nth-child(2n+2) {
                                border-right: none;
                            }
                        }
                        @include mquery(420px) {
                            width: 100% !important;
                            border-right: 0px;
                            &:nth-child(3) {
                                border-right: none !important;
                            }
                        }
                    }
                    &.six-tabs {
                        > li {
                            width: 16.66%;
                        }
                    }
                    &.five-tabs {
                        > li {
                            width: 20%;
                        }
                    }
                    &.four-tabs {
                        > li {
                            width: 25%;
                        }
                    }
                    &.three-tabs {
                        > li {
                            width: 33.3%;
                        }
                    }
                } /* Offer details main tabs ends here */
            }
            .od-main-tabs-content {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                position: relative;
                background-color: $od-white-bg;
                    .od-main-tab {
                        > div:first-child {
                            // margin-top: -30px !important;
                        }
                    }
                    //
                    //.od-tab-first .od-main-tabs-cont-leftcol {
                    //    padding-left: 0 !important;
                    //    padding-right: 0 !important;
                    //    > *:not('.no-padding') {
                    //        margin-left: 20px !important;
                    //        margin-right: 20px !important;
                    //    }
                    //}
                    //
                    .od-main-tab {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        position: relative;
                        &.od-main-tab-padding-right {
                            padding-right: 340px;
                            @include mquery(920px) {
                                padding-right: 0px;
                            }
                        }
                        .od-main-tabs-cont-leftcol {
                            float: left;
                            width: 100%;
                            margin: 0px;
                            padding: 30px 20px;
                            position: relative;
                            .default-section-title {
                                margin-top: 40px;
                                margin-bottom: 0px;
                                &:first-of-type {
                                    margin-top: 0px;
                                }
                            }
                        }

                        .no-padding {
                            margin-top: 20px;
                        }


                    }

            }
        }
    }
    .offer-details-status-wrapper {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            margin: 0px;
            padding: 0px 15px;
            @include transition(.3s);
            z-index: 1;
            -webkit-animation: odStatusAnimation 1.5s forwards; /* Safari 4+ */
            -moz-animation:    odStatusAnimation 1.5s forwards; /* Fx 5+ */
            -o-animation:      odStatusAnimation 1.5s forwards; /* Opera 12+ */
            animation:         odStatusAnimation 1.5s forwards;
            > div {
                width: 100%;
                max-width: 1200px;
                min-height: 46px;
                margin: 0px auto;
                padding: 0px 15px;

                > div {
                    float: left;
                    width: 100%;
                    min-height: 46px;
                    margin: 0px;
                    padding: 0px 0px 0px 50px;
                    border-radius: 5px 5px 0px 0px;
                    background-image: url('../../images/overfunding-status-icon.png');
                    background-repeat: no-repeat;
                    background-position: 15px center;
                    position: relative;
                    border-radius: 5px 5px 0px 0px;
                    background-color: #469edc;
                    @include mquery(720px) {
                        min-height: 65px;
                    }
                    .offer-details-status {
                        float: left;
                        margin: 0px;
                        padding: 13px 0px 0px;
                        font-size: 20px;
                        font-family: 'latoblack';
                        color: #fff;
                        @include mquery(620px) {
                            font-size: 16px;
                        }
                    }
                    > p {
                        float: right;
                        margin: 0px;
                        padding: 16px 15px;
                        font-family: 'latobold';
                        font-size: 14px;
                        color: #ffffff;
                        text-transform: uppercase;
                        @include mquery(720px) {
                            float: left;
                            width: 100%;
                            padding: 5px 10px 0px 0px;
                        }
                        @include mquery(620px) {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
}

//////offer info banner//////

/////////////mina/////////////////////////////

.mn-container {
  margin-top: 46px;
  margin-left: 20px;
  margin-right: 20px;
  // background-color: #f3f5f6;

  @include mquery(768px) {
    margin-left: 0;
    margin-right: 0;
  }


  .mn-amount-raise-invest-container {
   display: inline-flex;
   margin: 10px;
   .mn-amount-raise-invest-content {
     margin-right: 20px;
     .total-title {
       font-family: latobold;
       @include mquery(500px) {
           font-size: 13px;
       }
       color: #6f8899;
       line-height: 30px;
     }
   }
  }
  .list-container{
    // margin-top: 31px;
    background-color: #f3f5f6;
    padding-top: 12px;
    padding-bottom: 12px;
    // border-radius: 1em;
    border: 1px solid #e4ecf1;
    li {
      font-family: latobold;
      /* background-color: #f3f5f6; */
      padding: 16px 15px 15px 15px;
      /* border-bottom: 1px solid white; */
      width: 100%;
      height: 50px;

    .tooltip-li {
      position: relative;
      display: inline-block;
      .title {
        font-family: latobold;
        font-size: 16px;
        color: #6f8899;
      }
      .title:after {
        @include mquery(500px) {
          content:"\a";
          white-space: pre;
        }

      }
      .mn-info {
        font-size: 15px;
        padding-bottom: 5px;
        border-bottom: 1px dotted #6f8899;
      }


      .mn-no-border {
        border-bottom: none;
      }
    }
    .mn-link-black {
      font-size: 15px;
      font-family: "latolight";
      color: #333;
      .a-blue {
        color: #333;
        font-family: latolight;
      }
    }

    .tooltip-li .tooltiptext {
      display: none;
      width: 100%;
      height: auto;
      background-color: #6f8899;
      color: white;
      font-family: latoregular;
      text-align: left;
      /* border-radius: 6px; */
      padding: 15px 10px;
      position: absolute;
      z-index: 999999;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      font-size: 13px;
      line-height: 20px;
      @include respond-to(medium-screens) {
         width: 90%;

      }
     }

     .tooltip-li .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #6f8899 transparent transparent transparent;
      }

      .tooltip-li:hover .tooltiptext {
        display: flex;
      }

      }
     }
}


//////offer info banner end//////

.od-main-tabs-cont-rightcol {
    margin: 0;
    max-width: 320px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 45px;
    width: 100%;
    .od-maintabs-sidebar-navigation {
        float: left;
        width: 100%;
        padding: 0px;
        position: relative;
        margin-bottom: 10px;
        > ul {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            > li {
                float: left;
                width: 100%;
                height: 50px;
                margin: 0px 0px 1px 0px;
                list-style: none;
                > a {
                    float: left;
                    width: 100%;
                    height: 50px;
                    margin: 0px;
                    padding: 16px 15px 15px 15px;
                    background-color: $od-maintabs-sidebar-navigation;
                    font-family: 'latobold';
                    font-size: 16px;
                    color: $settings-sidebar-nav-color;
                    text-align: right;
                    @include transition(.3s);
                    position: relative;
                    @include border(bottom, 1px, solid, $form-group-bg);
                    &:hover, &.active {
                        background-color: #e4ecf1;
                        &:after {
                            visibility: visible;
                            opacity: 1;
                            left: 15px;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 14px;
                        left: 40px;
                        background-image: url(../../images/settings-sidebar-nav-arrow.png);
                        background-repeat: no-repeat;
                        width: 22px;
                        height: 22px;
                        opacity: 0;
                        visibility: hidden;
                        @include transition(.7s);
                    }
                    @include mquery(610px) {
                        padding: 17px 15px 15px !important;
                    }
                }
                @include mquery(610px) {
                    width: 50%;
                }
                @include mquery(420px) {
                    width: 100%;
                }

                /* nth-child media queries - sidebar navigation links text adjustment */
                &:nth-child(3) > a {
                    @include mquery(990px) {
                        padding-top: 10px;
                    }
                }
                &:nth-child(2) > a {
                    @include mquery(818px) {
                        padding-top: 10px;
                    }
                }
                &:nth-child(5) > a {
                    @include mquery(798px) {
                        padding-top: 10px;
                    }
                }
                &:nth-child(4) > a {
                    @include mquery(747px) {
                        padding-top: 10px;
                    }
                }
                &:nth-child(1) > a {
                    @include mquery(652px) {
                        padding-top: 10px;
                    }
                }
            } /* Navigation list items ends here */
        }
        @include mquery(992px) {
            display: none;
        }

    }
    @include mquery(992px) {
        position: relative;
        top: 0 !important;
        margin: 0 auto 50px;
        position: relative;
        max-width: 100%;
        text-align: center;

    }
} /* Offer details main tabs rightcol */

/*  Add this class to fix the right col nav  */

.fix-right-nav {
  margin: 0;
  max-width: 320px;
  padding: 0;
  top: 10px;
  width: 100%;
  position: fixed;

  @include mquery (991px) {
    position: relative;
  }
}

/* Offer details rightcol navigation under 950px */
.od-rightcol-fixed-nav {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background-color: $up-tabs-bg;
    z-index: 1001;
    visibility: hidden;
    opacity: 0;
    @include transition(.3s);
    &.odfixednav-visible {
        opacity: 1;
        visibility: visible;
    }
    .od-rc-fixed-nav-title {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 10px 50px;
            font-family: 'latobold';
            font-size: 18px;
            color: #ffffff;
            text-align: center;
        }
        > a {
            position: absolute;
            width: 22px;
            height: 22px;
            margin: 0px;
            padding: 0px;
            background-image: url('../../images/offer-details-fixed-arrows.png');
            background-repeat: no-repeat;
            &:first-of-type {
                top: 9px;
                left: 15px;
                background-position: 0px 0px;
            }
            &:last-of-type {
                top: 9px;
                right: 15px;
                background-position: -22px 0px;
            }
        }
    }
}
.od-tabs-file-blocks {
    float: left;
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 0px 10px 30px;
    position: relative;
    .od-tabs-file-block {
        float: left;
        width: 33.3%;
        margin: 20px 0px 0px 0px;
        padding: 0px 10px;

        @include mquery(650px) {
            padding: 0px !important;
            width: 100%;
            margin-top: 20px !important;
            &:first-of-type {
                margin-top: 0px !important;
            }
        }
        > div {
            float: left;
            width: 100%;
            min-height: 110px;
            margin: 0px;
            padding: 0px;
            position: relative;
            background-color: $up-cr-block-bg;
            .up-cert-fileblock-titlebar {
                float: left;
                width: 100%;
                min-height: 40px;
                margin: 0px;
                padding: 0px 20px 0px 40px;
                position: relative;
                > span {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: $cert-file-default-bg;
                    background-repeat: no-repeat;
                    background-position: center center;
                    &.text-file {
                        background-color: $cert-file-pdf-bg;
                        background-image: url(../../images/cert-file-icon.png);
                        &.docx-file {
                            background-color: $cert-file-text-bg;
                            background-image: url(../../images/cert-text-file-icon.png);
                        }
                        &.pdf-file {
                            background-color: $cert-file-pdf-bg;
                            background-image: url(../../images/cert-pdf-file-icon.png);
                        }
                        &.doc-file {
                            background-color: $cert-file-text-bg;
                            background-image: url(../../images/cert-text-file-icon.png);
                        }
                    }
                }
                > p {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 11px 0px 11px 15px;
                    font-family: 'latobold';
                    font-size: 18px;
                    color: $default-text-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            } /* Certificate file titlebar */
            > p {
                float: left;
                width: 100%;
                margin: 15px 0px 0px 0px;
                padding: 0px 15px 50px;
                font-family: 'latolight';
                font-size: 14px;
                color: $default-text-color;
                line-height: 18px;
                height: 150px;
            }
            .cert-file-download-btn {
                position: absolute;
                bottom: 15px;
                right: 15px;
                margin: 0px;
                padding: 7px 40px 7px 10px;
                height: 26px;
                color: $cert-file-downld-btn-color;
                text-decoration: none;
                font-family: 'latobold';
                font-size: 10px;
                text-transform: uppercase;
                text-align: left;
                background-image: url(../../images/cert-file-download-btn-icon.png);
                background-repeat: no-repeat;
                background-position: right 10px center;
                @include border(full, 1px, solid, $cert-file-downld-btn-color);
                @include transition(.3s);
                &:hover {
                    background-position: right 10px top 6px;
                }
            }
        }
    }
}
@-webkit-keyframes odStatusAnimation {
  0%   { top: 0px; }
  50%  { top: 0px; }
  100% { top: -110px; }
}
@-moz-keyframes odStatusAnimation {
  0%   { top: 0px; }
  50%  { top: 0px; }
  100% { top: -110px; }
}
@-o-keyframes odStatusAnimation {
  0%   { top: 0px; }
  50%  { top: 0px; }
  100% { top: -110px; }
}
@keyframes odStatusAnimation {
  0%   { top: 0px; }
  50%  { top: 0px; }
  100% { top: -110px; }
}
@include mquery(720px) {
    @-webkit-keyframes odStatusAnimation {
      0%   { top: 0px; }
      50%  { top: 0px; }
      100% { top: -130px; }
    }
    @-moz-keyframes odStatusAnimation {
      0%   { top: 0px; }
      50%  { top: 0px; }
      100% { top: -130px; }
    }
    @-o-keyframes odStatusAnimation {
      0%   { top: 0px; }
      50%  { top: 0px; }
      100% { top: -130px; }
    }
    @keyframes odStatusAnimation {
      0%   { top: 0px; }
      50%  { top: 0px; }
      100% { top: -130px; }
    }
}
.offer-details-team-tab {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 30px 20px 0px;
    > p {
        color: $default-text-color;
        float: left;
        font-family: "latolight";
        font-size: 15px;
        line-height: 1.5;
        margin: 30px 0 0;
        padding-bottom: 20px;
        width: 100%;
    }
}
.offer-details-team-section {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0 10px;
    @include mquery(660px) {
        padding: 20px;
    }
    .od-team-section-block {
        float: left;
        width: 33.3%;
        margin: 0px;
        padding: 10px;
        @include mquery(1023px) {
            width: 50%;
        }
        @include mquery(660px) {
            width: 100%;
            padding: 10px 0px;
        }
        > div {
            float: left;
            width: 100%;
            min-height: 95px;
            margin: 0px;
            padding: 0px 0px 0px 95px;
            position: relative;
            background-color: $cs-cr-rb-bg-color;
            @include border(full, 1px, solid, $cs-cr-rb-border-color);
            > img {
                position: absolute;
                top: 17px;
                left: 15px;
                margin: 0px;
                padding: 0px;
                width: 78px;
                height: 78px;
                border-radius: 40px;
                @include border(full, 4px, solid, $cs-cr-rb-imgBorder-color);
            }
            > div {
                float: left;
                width: 100%;
                height: 100%;
                min-height: 110px;
                margin: 0px;
                padding: 13px 15px 35px 15px;
                position: relative;
                > span {
                    font-family: 'latobold';
                    font-size: 14px;
                    color: $default-text-color;
                    text-transform: uppercase;
                    line-height: 18px;
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    float: left;
                    text-align: right;
                    overflow: hidden;

                }
                > p {
                    margin: 6px 0px 0px 0px;
                    font-family: 'latolight';
                    font-size: 13px;
                    color: $default-text-color;
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    float: left;
                    text-align: right;
                    > span {
                      white-space: nowrap;
                      width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      float: left;
                      text-align: right;
                    }
                }
                > a {
                    position: absolute;
                    bottom: 12px;
                    right: 15px;
                    margin: 0px;
                    padding: 5px 20px 3px 10px;
                    font-family: 'latoregular';
                    font-size: 10px;
                    text-transform: uppercase;
                    background-color: $default-blue-color;
                    background-image: url(../../images/submit-btn-arrow.png);
                    background-repeat: no-repeat;
                    background-position: right 5px center;
                    background-size: 5px;
                    color: white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .od-team-section-block-remove {
                position: absolute;
                top: 5px;
                left: 5px;
                width: 12px;
                height: 12px;
                background-image: url(../../images/cr-result-block-remove.png);
                background-repeat: no-repeat;
                background-position: 0px 0px;
            }
        }
    }
}

.offer-investor-limit-height-950 {
  height: 950px;
  overflow: hidden;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.offer-details-team-tabs-wrapper {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.od-team-tabs {
    padding: 0px 20px;
    margin-top: 62px;
    @include mquery(600px) {
        padding: 0px;
    }
    > li {
        @include mquery(600px) {
            width: 100%;
            border-right: 0px;
            &:last-of-type {
                border-right: 0px;
            }
        }
    }
}
.offer-details-team-tabs-content {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}

.od-warning-statement-section {
    margin-top: 70px;
    display: block;
    margin: 0 -20px;
    width: calc(100% + 40px);
    .warning-statement {
        max-width: unset;
    }
    .ws-text {
        padding-left: 20px;
        padding-right: 20px;
    }
    .ws-title {
        padding-left: 0 !important;
    }
}

.od-tab-second, .od-tab-third, .od-tab-fourth, .od-tab-fifth, .od-tab-sixth {
 display: none;
}
.od-members-blocks {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 20px 0px 0px 0px;
    position: relative;
    > p {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        text-align: center;
        font-family: 'latoregular';
        font-size: 14px;
        color: $default-text-color;
    }
    .od-member-block {
        float: left;
        width: 50%;
        margin: 0px;
        padding: 20px 0px 0px 0px;
        &:nth-child(2n+1) {
            padding: 20px 10px 0px 0px;
            @include mquery(768px) {
                padding: 20px 0px 0px 0px;
            }
        }
        &:nth-child(2n+2) {
            padding: 20px 0px 0px 10px;
            @include mquery(768px) {
                padding: 20px 0px 0px 0px;
            }
        }
        &:nth-child(-n+2) {
            padding-top: 0px;
            @include mquery(768px) {
                padding: 20px 0px 0px 0px;
            }
        }
        @include mquery(768px) {
            width: 100%;
            &:first-of-type {
                padding-top: 0px;
            }
        }
        > div {
            float: left;
            width: 100%;
            min-height: 75px;
            margin: 0px;
            padding: 0px 0px 0px 90px;
            position: relative;
            background-color: $form-group-bg;
            &.no-image {
                padding: 0px;
            }
            > img {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 75px;
                height: 75px;
                margin: 0px;
                padding: 0px;
            }
            > div {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 10px 0px 10px 0px;
                > span {
                    float: left;
                    width: 100%;
                    margin: 0px 0px 10px 0px;
                    padding: 0px 25px 0px 10px;
                    font-family: 'latobold';
                    font-size: 15px;
                    color: $default-text-color;
                }
                > p {
                    float: left;
                    width: 100%;
                    margin: 5px 0px 0px 0px;
                    padding: 0px 10px;
                    font-family: 'latoregular';
                    font-size: 13px;
                    color: $default-text-color;
                    > span {
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latobold';
                        color: $default-text-color;
                    }
                }
                > a {
                    background-image: url("../../images/blue-button-arrow.png");
                    background-position: right 10px center;
                    background-repeat: no-repeat;
                    @include border(full, 1px, solid, $default-blue-color);
                    color: $default-blue-color;
                    float: left;
                    font-family: "latoblack";
                    font-size: 11px;
                    margin: 15px 0 0 10px;
                    min-height: 26px;
                    padding: 6px 35px 5px 10px;
                    text-decoration: none;
                    text-transform: uppercase;
                    @include transition(.3s);
                    &:hover {
                        background-position: right 7px center;
                    }
                }
            }
            .od-member-block-remove {
                position: absolute;
                width: 11px;
                height: 11px;
                margin: 0px;
                padding: 0px;
                background-image: url(../../images/is-cm-remove-icon.png);
                background-position: 0px 0px;
                background-repeat: no-repeat;
                top: 5px;
                right: 5px;
            }
        }
    }
}


/* Updates and Q.A. */
.od-uq-tab {
    padding: 30px 20px !important;
}
.uq-comments-wrapper {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.uq-comments-blocks {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
}
.uq-comment-block {
    float: left;
    width: 100%;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    > div {
        float: left;
        width: 100%;
        min-height: 165px;
        margin: 0px;
        padding: 0px 20px 0px 170px;
        background-color: $od-uq-block-bg;
        position: relative;
        @include mquery(580px) {
            padding: 0px 20px 0px 100px;
        }
        > img {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 100%;
            max-width: 150px;
            margin: 0px;
            padding: 0px;
            border-width: 2px;
            border-style: solid;
            border-color: #ffffff;
            @include mquery(580px) {
                max-width: 80px;
            }
        }
        > .overlay-pic {
          position: absolute;
          width: 50px;
          @include mquery(580px) {
            width: 30px;
          }
        }
        > div {
            float: left;
            width: 100%;
            min-height: 165px;
            margin: 0px;
            padding: 15px 0px 35px;
            position: relative;
            @include mquery(580px) {
                padding: 15px 0px 50px;
            }
            > span {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                font-family: 'latobold';
                font-size: 20px;
                color: #ffffff;
            }
            > p {
                float: left;
                width: 100%;
                margin: 10px 0px 0px 0px;
                padding: 0px;
                font-family: 'latolight';
                font-size: 14px;
                color: #ffffff;
                line-height: 1.5;
                 -ms-word-break: break-all;
                word-break: break-all;

                /* Non standard for WebKit */
                word-break: break-word;

                -webkit-hyphens: auto;
                -moz-hyphens: auto;
                        hyphens: auto;
            }
            .uq-comment-users {
                width: 100%;
                margin: 0px;
                padding: 0px;
                position: absolute;
                bottom: 15px;
                left: 0px;
                > span {
                    float: left;
                    margin: 0px;
                    padding: 0px;
                    font-family: 'latolight';
                    font-size: 12px;
                    color: #ffffff;
                }
                > p {
                    float: right;
                    margin: 0px;
                    padding: 0px;
                    font-family: 'latolight';
                    font-size: 12px;
                    color: #ffffff;
                }
            }
        }
    }
    &.reply {
        margin-top: 2px;
        padding: 0px 0px 0px 30px;
        > div {
            background-color: $od-uq-reply-block-bg;
            > div {
                @include mquery(840px) {
                    padding-bottom: 50px;
                }
                @include mquery(580px) {
                    padding-bottom: 65px;
                }
                > span, > p, .uq-comment-users > span, .uq-comment-users > p {
                    color: $default-text-color;
                }
                .uq-comment-users > p {
                    @include mquery(840px) {
                        float: left;
                        clear: both;
                        margin-top: 3px;
                    }
                }
            }

        }
    }
}
.uq-reply-btn {
    position: absolute;
    top: 15px;
    right: -5px;
    margin: 0px;
    padding: 0px;
    width: 17px;
    height: 13px;
    background-image: url(../../images/uqa-reply-icon.png);
    background-repeat: no-repeat;
}
.uq-new-comment {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 20px;
    background-color: $od-uq-reply-block-bg;
    position: relative;
    > input {
        float: left;
        width: 100%;
        height: 36px;
        margin: 0px;
        padding: 0px 10px;
        font-family: 'latolight';
        font-size: 13px;
        color: $default-text-color;
        border: 0px;
    }
    > textarea {
        float: left;
        width: 100%;
        min-height: 95px;
        height: 36px;
        margin: 10px 0px 0px 0px;
        padding: 10px;
        font-family: 'latolight';
        font-size: 13px;
        color: $default-text-color;
        border: 0px;
        resize: none;
    }
    > div {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        text-align: center;
        > span {
            float: left;
            margin: 0px;
            padding: 27px 0px 0px 0px;
            font-family: 'latolight';
            font-size: 12px;
            color: $default-text-color;
            @include mquery(480px) {
                width: 100%;
                text-align: center;
            }
            > a {
                color: $default-blue-color;
                font-weight: bold;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        > button {
            float: right;
            width: 110px;
            height: 28px;
            margin: 20px 0px 0px 0px;
            padding: 0px 10px;
            font-family: 'latobold';
            font-size: 12px;
            color: #ffffff;
            text-align: left;
            cursor: pointer;
            border: 0px;
            background-color: $default-blue-color;
            @include transition(.3s);
            text-transform: uppercase;
            background-image: url(../../images/eq-new-comment-icon.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            &:hover {
                background-color: lighten($default-blue-color, 5%);
            }
            @include mquery(480px) {
                display: inline-block;
                float: none;
            }
        }
        > input{
            float: right;
            width: 110px;
            height: 28px;
            margin: 20px 0px 0px 0px;
            padding: 0px 10px;
            font-family: 'latobold';
            font-size: 12px;
            color: #ffffff;
            text-align: left;
            cursor: pointer;
            border: 0px;
            background-color: $default-blue-color;
            @include transition(.3s);
            text-transform: uppercase;
            background-image: url(../../images/eq-new-comment-icon.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            &:hover {
                background-color: lighten($default-blue-color, 5%);
            }
            @include mquery(480px) {
                display: inline-block;
                float: none;
            }
        }
    }
}
.new-reply {
    display: none;
    .uq-new-comment{
        margin: 3px 0px 0px 0px;
    }
}
.error-comment{
    position: relative;
    > .error-tooltip {
        color: #ffffff;
        padding: 5px 10px;
    }
}

/* Prefunding form wrapper */
.prefunding-form-wrapper {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px 50px 0px 0px;
    background-color: #F3F5F6;
    position: relative;
    display: none;
    @include mquery(580px) {
        padding: 0px 0px 50px 0px;
    }
}
ul.prefunding-form {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    > li {
        float: left;
        width: 50%;
        margin: 0px;
        padding: 0px;
        @include mquery(1060px) {
            width: 100%;
        }
    }
}
.prefunding-form-group {
    float: left;
    margin: 0;
    min-height: 60px;
    padding: 22px 300px 22px 15px;
    position: relative;
    width: 100%;
    background-color: #F3F5F6;
    @include mquery(580px) {
        padding: 15px;
        &.pfg-radio {
            padding: 15px 155px 15px 15px;
        }
    }
}
.prefunding-form-group-title {
    color: #7e7e7e;
    float: left;
    font-family: "latoregular";
    font-size: 14px;
    margin: 0;
    padding: 0 20px 0 0;
    position: relative;
    width: 100%;
}
.prefunding-input-cont {
    max-width: 280px;
    position: absolute;
    right: 10px;
    top: 9px;
    width: 100%;
    @include mquery(580px) {
        position: relative;
        top: auto;
        right: auto;
        float: left;
        max-width: 100%;
        margin-top: 10px;
    }
}
.prefunding-input-cont > input[type="text"],
.prefunding-input-cont> input[type="password"] {
    @include border(full, 1px, solid, #dfe6eb);
    color: #7e7e7e;
    float: left;
    font-family: "latobold";
    font-size: 14px;
    height: 42px;
    margin: 0;
    padding: 0 10px;
    width: 100%;
}
.prefunding-add-member {
    background-color: #469edc;
    background-image: url("../../images/cert-upload-btn-icon.png");
    background-position: center center;
    background-repeat: no-repeat;
    border: 0 none;
    cursor: pointer;
    height: 40px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 40px;
    @include transition(.3s);
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
    @include mquery(580px) {
        right: 50%;
        margin-right: -20px;
    }
}
.prefunding-radio-input-cont {
    max-width: 130px;
    position: absolute;
    right: 10px;
    top: 16px;
    width: 100%;
}
.prefunding-members-blocks {
    display: none;
}
.select2-container {
    margin: 0px;
}
.select2-container--default .select2-selection--single {
    height: 40px;
    border-radius: 0px;
    border-color: #dfe6eb;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-width: 7px 6px 0;
    margin-left: -15px;
    margin-top: 3px;
    border-color: #6f8899 transparent transparent;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width:  0px 6px 7px !important;
    margin-left: -15px;
    margin-top: 3px;
    border-color:  transparent transparent #6f8899 !important;
}

.header_span{
    padding: 30px 0 10px 0;
    font-weight: bold;
}
.header_span_sub{
    padding: 15px 0 5px 0;
    font-weight: bold;
}

/** Financial services **/
.financial-service-table
{
    margin: 15px 0px;
    width: 50%;
}
.financial-service-table tr{
    border-top: 1px solid #7cbee5;
}
.financial-service-table tr:last-child
{
    border-top: 1px solid #7cbee5;
    border-bottom: 1px solid #7cbee5;
}
.financial-service-table td{
    padding:5px;
}

.financial-service-circle-ul{
    list-style-type: circle;
    margin-left: 30px;
}
.disc-investor-list{
    list-style: disc;
    margin-left: 30px;
}

.mn-offer-event-img-cnt {
  display: inline-block;
  position: relative;
  width: 100%;
  @include mquery(991px){
    display: block;
  }
}

.offer-event-flex {
  display: inline-flex;
}


.offer-event-image {
  width: 320px;
  height: 160px;
  object-fit: cover;

  @include mquery(991px) {
    width: 100%;
    height: 240px;
  }
}

.offer-event-image-offer-page {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include mquery(991px) {
    width: 100%;
    height: 160px;
  }
}


.mn-offer-event-content {
  width: 100%;
  display: inline-block;
  background-color: #f3f5f6;
  padding: 20px 9px;
  color: #6f8899;
  .mn-offer-event-title {
    margin-bottom: 8px;
    font-size: 16px;
  }
  .mn-offer-event-desc {
    margin-bottom: 8px;
    font-family: latoregular;
  }
}



  .event-banner {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    background-color: rgba(1,1,1, .6);
    width: 100%;
    padding: 13px;
    color: white;
    font-size: 24px;
  }


#offer-left-sidebar {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  @include mquery(768px) {
    padding-right: 0;
    padding-left: 0;
    float: none;
  }
}


.fixed-top {
  position: fixed;
  top: 110px;
  max-width: 320px;
  @include mquery(768px) {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%;
  }

}


.fixed-bottom {
  position: relative;
  bottom: 14705px;
}
