@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
HOMEPAGE
---------------------------------------------------------- */
.hot-details-tooltip {
  position: absolute;
  bottom: 40px;
  right: 0px;
  padding: 5px 10px 6px !important;
  min-height: 24px;
  min-width: 170px;
  border-radius: 2px;
  background-color: $tooltip-color;
  font-family: 'latolight';
  line-height: 15px;
  font-size: 12px;
  color: #ffffff !important;
  margin: 0px;
  opacity: 0;
  visibility: hidden;
  @include transition(.3s);
  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #7089a3;
    bottom: -6px;
    content: "";
    height: 0px;
    left: 10px;
    position: absolute;
    width: 0px;
  }
}

.syndicate-badge-tooltip {
  position: absolute;
  bottom: 45px;
  right: 0px;
  padding: 5px 10px 6px !important;
  min-height: 24px;
  min-width: 170px;
  border-radius: 2px;
  background-color: $tooltip-color;
  font-family: 'latolight';
  line-height: 15px;
  font-size: 12px;
  color: #ffffff !important;
  margin: 0px;
  @include transition(.3s);
  opacity: 0;
  visibility: visible;
  z-index: 10;
  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #7089a3;
    bottom: -6px;
    content: "";
    height: 0px;
    right: 10px;
    position: absolute;
    width: 0px;
  }
}

/* Main header notification */
#main-header-notification {
  float: left;
  width: 100%;
  // min-height: 60px;
  margin: 0px;
  padding: 0px;
  > div {
    position: relative;
    margin: 0px auto;
    width: 100%;
    max-width: 1200px;
    padding: 0px;
    // min-height: 60px;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 10px 21px;
    display: flex;
    flex-direction: row;

    > span {
      margin: 0px;
      padding: 20px;
      font-size: 17px;
      color: #ffffff;
      font-family: 'latoregular';

      @include mquery(600px) {
        display: none;
      }
    }
    > img {
      margin: 14px;
    }
    .subscribe-xinja {
      color: white;
      text-decoration: underline;
      font-family: "Montserrat";
      padding: 21px 10px;

      @include mquery(700px) {
        padding: 20px 0px 0px 5px;
        overflow: hidden;
      }
    }
  }
}

/* Homepage header */
#homepage-header {
  float: left;
  width: 100%;
  display: block;
  margin: 0px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  > div {
    margin: 0px auto;
    padding: 0px;
    width: 100%;
    max-width: 1200px;

  }
  .main-logo {
    float: left;
    margin-top: 15px;
    padding: 10px 0px 5px 0;
    height: 40px;
    // @include mquery(1024px) {
    //   margin-left: 10px;
    // }
    @include mquery(320px) {
      width: 138px;
      padding: 5px 0px 0px 5px;
      overflow: hidden;
    }

    .mn-header-logo {
      float: left;
      margin: 0px;
      padding: 0px;
      width: 127px;
    }
  }
  ul.header-login-reg-nav {
    float: right;
    margin: 18px 0px 0px 0px;
    padding: 0px;
    > li {
      float: left;
      list-style: none;
      margin: 0px;
      padding: 10px 15px 0px;
      > a {
        float: left;
        padding: 0px 0px 10px;
        margin: 0px;
        position: relative;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 14px;
        font-family: 'latolight';
        &:hover:after {
          opacity: 1;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          transform: translateY(0px);
        }
        &:after {
          background-color: rgba(255, 255, 255, 0.7);
          content: "";
          height: 2px;
          left: 0px;
          opacity: 0;
          position: absolute;
          top: 100%;
          width: 100%;
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          -moz-transition: opacity 0.3s, -moz-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;
          -webkit-transform: translateY(10px);
          -moz-transform: translateY(10px);
          transform: translateY(10px);
        }
      }
      &.nav-register {
        padding: 0;
        > a {
          float: left;
          padding: 10px 20px;
          position: relative;
          text-transform: uppercase;
          margin: 0px;
          font-size: 14px;
          font-family: 'latolight';
          color: #ffffff;
          overflow: hidden;
          background-color: $default-blue-color;
          @include transition(.3s);
          &.reg-icon:before {
            content: "";
            height: 30px;
            width: 100%;
            background-image: url(../../images/reg-btn-icon.png);
            background-repeat: no-repeat;
            background-position: center center;
          }
          &:before {
            font-size: 180%;
            height: 100%;
            line-height: 2.5;
            position: absolute;
            width: 100%;
            @include transition(.3s);
            left: 0px;
            top: -100%;
          }
          &:hover {
            background-color: lighten($default-blue-color, 5%);
            &:before {
              top: 0px;
            }
            > span {
              -webkit-transform: translateY(300%);
              -moz-transform: translateY(300%);
              -ms-transform: translateY(300%);
              -o-transform: translateY(300%);
              transform: translateY(300%);
            }
          }
          > span {
            backface-visibility: hidden;
            display: inline-block;
            font-family: 'latolight';
            // height: 100%;
            width: 100%;
            @include transition(.3s);
          }
        }
      }
      /* Navigation register button */
    }
  }
  ul.header-navigation {
    float: right;
    border-right-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    margin: 18px 0px 0px;
    @include mquery (1024px) {
      border: none;
    }
    li.header-event-list {
      float: left;
      padding: 0;
      position: relative;
      list-style: none;
    }
    li {
      float: left;
      padding: 10px 15px 0px;
      position: relative;
      list-style: none;

      &:hover {
        cursor: pointer;
      }

      a.header-invest-link {
        border: 1px solid white;
        margin-top: -2px;
        padding: 10px 20px;
        position: relative;
        &:hover .header-tooltiptext {
           display: flex;
        }
       &:hover:after {
         opacity: 0;
       }

      }

      .header-justify-content {
        text-align: center;
        display: inline-table;
        @media only screen and (min-width: 1200px) {
          min-width: 160px;
          margin: auto;
       }
      }

      .tooltip-cnt {
        position: absolute;
        top: 47px;
        left: -160px;
        @include mquery(1118px) {
          left: 70px;
        }
      }

      .not-active {
        @media only screen and (min-width: 1118px) {
          pointer-events: none;
          cursor: default;
          text-decoration: none;
        }
      }

      .header-tooltiptext {
        display: none;
        background-color: rgba(255,255,255,.9);
        color: #333;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        z-index: 10;
        margin-bottom: 2px;
        transition: all 0.2s fadeIn;
        position: relative;
        @include mquery(767px) {
          display: none;
        }
        &:hover {
          display: flex;
          transition: 2s fadeIn linear;

        }
      }

      @-webkit-keyframes fadeIn {
          from { opacity: 0; }
            to { opacity: 1; }
      }
      @keyframes fadeIn {
          from { opacity: 0; }
            to { opacity: 1; }
      }




      .event-btn {
        border: 1px solid white;
        margin-top: -2px;
        padding: 10px 20px;
        position: relative;
        color: white;
        @include mquery(1118px) {
          text-align: center;
        }
      }

      .tooltip-text-cnt {
        width: 210px;
        padding-right: 8px;
        color: #333;
        @include mquery(1118px) {
          padding: 8px;
          width: 240px;
        }
      }

      .tooltip-title {
        margin-bottom: 15px;
        font-size: 16px;
        margin-top: 13px;
      }

      .tooltip-desc {
        margin-bottom: 25px;
        font-family: latoregular;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        height: 43px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 1;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tooltip-date {
        position: absolute;
        bottom: 7px;
        font-size: 13px;
      }

      .tooltip-image {
        width:240px;
        max-height: 130px;
        min-height: 130px;
        height: 100%;
        padding:2px 8px;
        object-fit: cover;
      }

        .header-tooltiptext:after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent rgba(255,255,255,.6) transparent;
        }

      a {
        float: left;
        padding: 0px 0px 10px;
        margin: 0px;
        position: relative;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 14px;
        font-family: 'latolight';
        display: flex;
        &:hover:after {
          opacity: 1;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          transform: translateY(0px);
        }
        &:after {
          background-color: rgba(255, 255, 255, 0.7);
          content: "";
          height: 2px;
          left: 0px;
          opacity: 0;
          position: absolute;
          top: 100%;
          width: 100%;
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          -moz-transition: opacity 0.3s, -moz-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;
          -webkit-transform: translateY(10px);
          -moz-transform: translateY(10px);
          transform: translateY(10px);
          cursor: pointer;
        }
        @include mquery(1118px ) {
          width: 100%;
          padding: 13px 15px;
          text-align: center;
          transition: 0.3s;
          display: block;
          float: none;
        }
      }

    }
    @include mquery(1118px) {
      width: 100%;
      background-color: $mobile-nav-bg;
      display: none;
      position: absolute;
      top: 70px;
      left: 0px;
      margin-top: 0px;
      > li {
        width: 25%;
        padding: 0px;
        @include border(right, 1px, solid, $mobile-nav-separator);
        border-bottom-width: 1px;
        @include mquery(600px) {
          width: 50%;
        }
        @include mquery(1045px) {
          width: 100%;
          border-right: 0px;
        }
        > a {
          width: 100%;
          padding: 13px 15px;
          text-align: center;
          @include transition(.3s);
          &:after {
            content: none;
          }
          &:hover {
            background-color: darken($mobile-nav-bg, 5%)
          }
          @include mquery(736px) {
            background-color: $mobile-nav-bg;
          }
        }
      }
      @include mquery(736px) {
        background-color: none;
      }
    }
  }

}

.open-nav-btn-li {
  display: none;
  float: left;
  margin: 0px;
  padding: 12px 2px 12px 12px !important;

  // @include mquery (1024px){
  //   padding: 12px 10px 12px 12px !important;
  // }

  > a {
    float: left;
    width: 25px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    background-image: url('../../images/open-nav-icon.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    &.active {
      background-position: 0px -20px;
    }
    &:after {
      content: none;
    }
  }
  @include mquery(1118px) {
    display: inline-block;
  }

}

.open-nav-btn {
  padding: 21px 21px 12px 12px;
}

.onb-logged-in {
  padding: 25px 21px 12px !important;
  @include mquery(320px) {
    padding: 25px 4px 25px 9px !important
  }
}

ul.header-nav-dropdown {
  display: none;
  background-color: #469edc;
  left: -30px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  min-width: 130px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.15);
  // @include mquery(1024px) {
  //   position: static;
  // }
  @include mquery(1045px) {
    display: none;
    left: 0;
  }
  &:before {
    border-bottom: 7px solid #469edc;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    left: 50%;
    margin: 0 0 0 -5px;
    padding: 0;
    position: absolute;
    top: -7px;
    // top: 33px;
  }
  > li {
    float: left;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    // background-color: #469edc;
    > a {
      color: #ffffff;
      float: left;
      font-family: "latoregular";
      font-size: 12px;
      height: 35px;
      margin: 0;
      padding: 10px 15px;
      text-decoration: none;
      @include transition(.3s);
      width: 100%;
      &:hover {
        background-color: #2f87c5;
      }
    }
  }
}

.header-logged-in {
  float: right;
  margin: 0px;
  padding: 0px;
}

.header-logged-in ~ .header-navigation {
  border: 0px !important;
}

.header-nav-more {
  position: relative !important;
}

.header-logged-user {
  float: left;
  margin: 0px;
  padding: 20px 10px 20px 60px;
  height: 70px;
  min-width: 220px;
  @include border(left, 1px, solid, #151515);
  border-right-width: 1px;
  position: relative;
  @include transition(.3s);
  &:hover {
    cursor: pointer;
    background-color: #3D3D3D;
  }
  @include mquery(480px) {
    min-width: 60px;
    padding-right: 0;
    background-image: none;
  }
}

.header-logged-user > img {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  @include border(full, 1px, solid, #151515);
}

.header-logged-user > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 0px 0px 0px;
  font-size: 12px;
  color: #ffffff;
  font-family: 'latolight';
  position: relative;
  @include mquery(480px) {
    display: none;
  }
}

.header-logged-user > span:before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "Hi, ";
  font-family: 'latobold';
}

.header-logged-user-dropdown {
  position: absolute;
  top: 70px;
  right: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: #469edc;
  display: none;
  @include mquery(480px) {
    min-width: 220px;
  }
}

.header-logged-user-dropdown > li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.header-logged-user-dropdown > li > a {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 10px;
  text-decoration: none;
  font-family: 'latoregular';
  font-size: 12px;
  color: #ffffff;
  @include transition(.3s);
  background-image: url(../../images/user-dropdown-icons.png);
  background-repeat: no-repeat;
  &:hover {
    background-color: #2f87c5;
  }
  &.hlud-profile {
    background-position: right 0px top -83px;
  }
  &.hlud-capital-raisings {
    background-position: right 0px top 11px;
  }
  &.hlud-users {
    background-position: right 2px top -147px;
  }
  &.hlud-settings {
    background-position: right 0px top -21px;
  }
  &.hlud-logout {
    background-position: right 0px top -54px;
  }
}

.header-logged-notification {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0px;
  padding: 0px;
  @include border(right, 1px, solid, #151515);
  position: relative;
  @include mquery(450px) {
    float: right;
  }
}

.header-logged-notification > a {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  background-image: url(../../images/header-notification-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  @include transition(.3s);
  position: relative;
  &:hover {
    background-color: #3D3D3D;
  }
}

.header-logged-notification > a > span {
  position: absolute;
  top: 6px;
  right: 6px;
  font-family: 'latobold';
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  background-color: #dd1c52;
  border-radius: 6px;
  margin: 0px;
  padding: 1px 5px 2px;
}

.header-logged-notification-dropdown {
  position: absolute;
  top: 70px;
  right: 0px;
  width: 100%;
  min-width: 340px;
  margin: 0px;
  padding: 0px;
  background-color: #5fa3b4;
  display: none;
}

.header-logged-notification-dropdown > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.header-logged-notification-dropdown > ul > li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  @include border(bottom, 1px, solid, #6EB4C8);
}

.header-logged-notification-dropdown > ul > li > a {
  float: left;
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 12px 10px 12px 60px;
  text-decoration: none;
  position: relative;
  @include transition(.3s);
  &:hover {
    background-color: #6EB4C8;
  }
  & > img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    margin: 0px;
    padding: 0px;
    @include border(full, 2px, solid, #ffffff);
  }
  & > span {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'latolight';
    font-size: 13px;
    color: #ffffff;
  }
  & > p {
    float: left;
    width: 100%;
    margin: 5px 0px 0px 0px;
    padding: 0px;
    font-family: 'latolight';
    font-size: 12px;
    color: #b8f4ff;
  }
}

.header-notification-see-all {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 10px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #ffffff;
  @include transition(.3s);
  &:hover {
    background-color: #6EB4C8;
  }
}

.header-logged-home .header-logged-user:hover {
  background-color: transparent;
}

.header-logged-home .header-logged-notification > a {
  background-image: url(../../images/header-notification-icon-white.png)
}

.header-logged-home .header-logged-notification > a:hover {
  background-color: transparent;
}

.header-logged-home .header-logged-user .header-logged-user-dropdown:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  margin: 0px 0px 0px -5px;
  padding: 0px;

  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #5fa3b4;
}

.header-logged-home .header-logged-notification .header-logged-notification-dropdown:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 27px;
  margin: 0px;
  padding: 0px;

  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #5fa3b4;
}

/* Home top section wrapper */

#home-top-carousel-wrapper {

  .homepage-carousel-banner {
    background-size: cover;
    height: 600px;
    @include mquery(1280px) {
      height: calc(100vh - 70px);
    }
    @include mquery(1024px) {
      height: 600px;
    }
    @include mquery(767px) {
      height: 530px;
    }
  }


  .carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
  }

  .carousel-fade .carousel-inner .active {
    opacity: 1;
  }

  .carousel-fade .carousel-inner .active.left,
  .carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
  }

  .carousel-fade .carousel-inner .next.left,
  .carousel-fade .carousel-inner .prev.right {
    opacity: 1;
  }

  .carousel-fade .carousel-control {
    z-index: 2;
  }


  @media all and (transform-3d), (-webkit-transform-3d) {
      .carousel-fade .carousel-inner > .item.next,
      .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.prev,
      .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.next.left,
      .carousel-fade .carousel-inner > .item.prev.right,
      .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
  }

  
  // One
  .one {
    background: url(/images/homepage-carousel.jpg) no-repeat center center;
    background-size: cover;
  }
  // Two
  .three {
   background: url(/images/banners/rhinohide-banner2.jpg) no-repeat center center;
   background-size: cover;
  }
  // Three
  .two {
    background: url(/images/banners/agunity.png) no-repeat center right;
    background-size: cover;
  }
  // Four
  .four {
   background: url(/images/banners/fini-awards.png) no-repeat center;
   background-size: cover;
  }
  // Five
  .five {
    background: url(/images/homepage-carousel-deal-banner.jpg) no-repeat center center;
    background-size: cover;
    @include mquery(800px){
      background: url(/images/homepage-carousel-deal-mobile.jpg) no-repeat top right;
    }
  }


  .homepage-flex-logos {
    height: 170px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    @include mquery(767px){
      height: 120px;
    }
   }

    .homepage-carousel-xinja-logo {
      width: 290px;
      @include mquery(768px){
        width: 260px;
      }
      @include mquery(600px){
        width: 220px;
      }
      @include mquery(320px){
        width: 200px;
      }
    }

    .homepage-carousel-dit-logo {
      width: 350px;
      @include mquery(768px){
        width: 300px;
      }
      @include mquery(600px){
        width: 270px;
      }
      @include mquery(320px){
        width: 250px;
      }
    }


    .homepage-carousel-booktopia-logo {
      width: 350px;
      @include mquery(768px){
        width: 300px;
      }
      @include mquery(600px){
        width: 230px;
      }
      @include mquery(320px){
        width: 200px;
      }
    }

  }

  .homepage-carousel-control{
   width: 5%;
   z-index: 10;
  }

  .carousel-x-img {
    padding: 10px;
    box-sizing: border-box;
    width: 59px;
    height: 59px;
    @include mquery(600px){
      padding: 3px;
      width: 25px;
      height: 25px;
    }
  }

  .homepage-carousel-layer {
    height: 600px;
    background-color: rgba(1,1,1,.6);
    display: flex;
    align-items: center;
    justify-content: center;
    @include mquery(1280px) {
      height: calc(100vh - 70px);
    }
    @include mquery(1024px) {
      height: 600px;
    }
    @include mquery(767px) {
      height: 530px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .homepage-carousel-layer-content-cnt {
      @include mquery(600px) {
        width: 345px;
        height: 447px;
      }
      .homepage-carousel-title-cnt {
        height: 170px;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        @include mquery(600px) {
          height: 120px;
        }
        .carousel-title {
          max-width: 764px;
          font-size: 48px;
          font-family: 'latoregular';
          text-align: center;
          @include mquery(768px) {
            font-size: 38px;
          }
        }
      }
      .homepage-carousel-subtitle-cnt {
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;

        .carousel-subtitle {
          font-size: 24px;
          font-family: latolight;
          max-width: 680px;
          line-height: 30px;
          text-align: center;
          margin: auto;
          @include mquery(768px){
          font-size: 18px;
          line-height: 24px;
          }
        }

      }
    }
  }

  .homepage-btn-container {
      padding: 30px 0px;
      height: 115px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      @include mquery (600px) {
        height: 200px;
      }
      > a {
          float: left;
          width: 100%;
          font-size: 15px;
          margin: 0px;
          padding: 17px;
          text-align: center;
          color: $htw-text;
          font-family: latoregular;

          @include transition(.3s);
          &:hover {
              background-color: $htw-btn-bg;

          }
          &.mn-home-blue-btn {
              margin: 0px 30px 0px 30px;
              height: 55px;
              width: 216px;
              background-color: $default-blue-color;
              transition: .3s;
              @include border(full, 2px, solid, $htw-btn-border);

              @include mquery(600px) {
                margin-right: 0;
                width: 253px;
                margin-left: 0;
              }
              &:hover {
                opacity: .8;
              }
          }
          &.mn-home-border-white-btn {
              @include border(full, 2px, solid, $white-border);
              height: 55px;
              width: 258px;
              &:hover {
                  cursor: pointer;
                  @include border(full, 2px, solid, $htw-btn-border);
              }
              @include mquery(481px) {
                  margin-top: 15px;
                  min-width: 243px;
              }
          }
          /* Media queries */
          @include mquery(700px) {
              max-width: 260px;
          }
          @include mquery(600px) {
              font-size: 15px;
              background-position: right 15px center;
          }
          @include mquery(530px) {
              float: none;
              display: inline-block;
          }
      }
      @include mquery(530px) {
          text-align: center;
      }

  } /* Home top buttons ends here */

#home-additional-offer {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: row;

  .container {
    width: 100%;
    max-width: 1200px;
    padding-top: 0;
    display: flex;
    flex-direction: column;

    .info-container {
      padding-top: 0;
      display: flex;
      max-width: 920px;
      width: 100%;

      @media (max-width: 825px) {
        flex-direction: column;

        .aos-completed-offers,
        .aos-coming-soon-offers {
          align-items: center;
          padding-left: 50px;
          padding-right: 50px;
          padding-bottom: 30px;
          flex: 1;
        }
      }
    }
  }

}

/* Home section */
.home-section {
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  text-align: center;
  background-color: $section-bg;
}

.home-section {
  header {
    flex-direction: column;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  > div {
    max-width: 1200px;
    padding: 80px 10px;
    font-size: 0;
    display: inline-block;
    text-align: center;
    position: relative;

    .home-section-title {
      min-width: 150px;
      display: inline-block;
      margin: 0px 0px 35px;
      padding: 0px 0px 10px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      font-family: 'latolight';
      font-size: 34px;
      color: $home-section-title-color;

      &.white {
        color: #ffffff;
      }
      &.tagline {
        float: left;
        width: 100%;
        text-align: center;
        font-size: 20px;
        text-transform: initial;
        &:after {
          content: none;
        }
      }
      &:after {
        bottom: 0px;
        content: "";
        display: inline-block;
        height: 2px;
        left: 0px;
        right: 0px;
        margin: 0px auto;
        position: absolute;
        width: 150px;
        background-color: $home-section-title-bg;
      }
    }
  }

  .home-offer-tiles-wrapper {
    margin: 0px;
    padding: 20px 7px 0;
    display: inline-block;
    vertical-align: top;

    .home-offer-tile {
      overflow: hidden;
      float: left;
      width: 100%;
      max-width: 280px;
      min-height: 400px;
      margin: 0px;
      padding: 0px;
      @include border(full, 1px, solid, $offer-tile-border);
      background-color: $offer-tile-bg;
      cursor: pointer;
      @include transition(.3s);
      position: relative;

      .private-hot-bottom-cont {
        display: block;
      }

      &:hover {
        .private-hot-hover-overlay {
          visibility: visible;
          opacity: 1;
          transition: 0.3s;
        }

        .hot-hover-overlay {
          position: absolute;
          top: 0px;
          left: 0px;
          margin: 0px;
          padding: 0px;
          width: 100%;
          height: 100%;
          background-color: #000000;
          background-color: rgba(0, 0, 0, 0.45);
          z-index: 2;
          opacity: 1;
          visibility: visible;
          pointer-events: none;
          $overlay-width: 140px;
          @include transition(.3s);

          div {
            position: absolute;
            top: 0;
            left: 0px;
            margin: 0px;
            padding: 0px 10px;
            width: $overlay-width;
            @include transition(.3s);
            -webkit-transition-delay: .1s;
            transition-delay: .1s;

            a {
              float: left;
              width: auto;
              height: 35px;
              margin: 0px;
              padding: 0px;
              background-color: $hot-viewOfferBtn-bg;
              background-image: url(../../images/view-invest-offer-icon.png);
              background-repeat: no-repeat;
              background-position: 10px center;
              border-radius: 0 0 4px 4px;

              span {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 9px 10px 0px 25px;
                text-align: right;
                font-family: 'latobold';
                font-size: 14px;
                color: #ffffff;
              }
            }

            span {
              float: left;
              width: 100%;
              height: 35px;
              margin: 0px;
              padding: 0px;
              background-color: $hot-viewOfferBtn-bg;
              background-image: url(../../images/view-invest-offer-icon.png);
              background-repeat: no-repeat;
              background-position: ($overlay-width - 45px) center;
              border-radius: 0 0 4px 4px;
              padding: 9px 30px 0px 10px;
              text-align: left;
              font-family: 'latobold';
              font-size: 14px;
              color: #ffffff;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              -o-box-sizing: border-box;
              box-sizing: border-box;
            }
          }
        }

        .private-hot-bottom-cont {
          display: none;
        }
      }
      .syndicate-badge-wrapper {
        position: absolute;
        top: 0px;
        right: 10px;
        margin: 0px;
        padding: 0px;

        .syndicate-badge {
          float: left;
          width: 29px;
          height: 29px;
          margin: 0px;
          padding: 0px;
          background-image: url(../../images/hot-tile-syndicate-icon.png);
          background-repeat: no-repeat;
          position: relative;
          z-index: 3;

          &:hover ~ .syndicate-badge-tooltip {
            opacity: 1;
            visibility: visible;
            bottom: 35px;
          }
        }
      }

      .private-hot-hover-overlay {
        opacity: 0;
        display: flex;
        height: 100%;
        width: 100%;
        position: absolute;
        transition: .5s ease;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px;

        .private-offer-request-form {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-top: 160px;
          padding-left: 0;
          padding-right: 0;
          align-items: start;
          @include transition(.3s);

          h2 {
            margin: 10px 0px;
            font-family: 'latoregular';
            font-size: 16.5px;
            line-height: 30px;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            transition: 0.3s;
          }

          .form-fields {
            display: flex;
            flex-direction: column;
            width: 100%;

            input[type=text], input[type=email] {
              height: 40px;
              flex: 1;
              border: 1px solid #ccc;
              margin-bottom: 10px;
              padding: 10px;
              color: #333;
              font-size: 15px;
              border-radius: 2px;
            }
          }

          button.request-access {
            padding: 10px;
            min-width: 140px;
            font-size: 16px;
            font-family: 'latolight';
            background: #469edc;
            border: 1px solid #469edc;
            color: #fff;
            border-radius: 2px;
            transition: background 0.3s ease;
            border-radius: 3px;
            width: 100%;
            height: 40px;

            &:hover {
              cursor: pointer;
              background: #469edc;
              color: #fff;
            }
          }
        }
      }
    }

    .hot-top-cont {
      float: left;
      width: 100%;
      height: 152px;
      margin: 0px;
      padding: 0px;
      position: relative;

      a.logo-link {
        float: left;
        width: 100%;
        height: 100%;

        img:last-child {
          top: unset !important;
          bottom: -20px !important;
          left: 10px !important;
          width: 70px !important;
          background-color: white;
          z-index: 3;
          @include border(full, 1px, solid, $offer-tile-border);
        }
      }

      img {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        max-height: 100%;
      }

      .hot-hover-overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.45);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        $overlay-width: 140px;
        @include transition(.3s);

        div {
          position: absolute;
          top: -35px;
          left: 0px;
          margin: 0px;
          padding: 0px 10px;
          width: $overlay-width;
          @include transition(.3s);
          -webkit-transition-delay: .1s;
          transition-delay: .1s;

          a {
            float: left;
            width: auto;
            height: 35px;
            margin: 0px;
            padding: 0px;
            background-color: $hot-viewOfferBtn-bg;
            background-image: url(../../images/view-invest-offer-icon.png);
            background-repeat: no-repeat;
            background-position: 10px center;
            border-radius: 0 0 4px 4px;

            span {
              float: left;
              width: 100%;
              margin: 0px;
              padding: 9px 10px 0px 25px;
              text-align: right;
              font-family: 'latobold';
              font-size: 14px;
              color: #ffffff;
            }
          }

          span {
            float: left;
            width: 100%;
            height: 35px;
            margin: 0px;
            padding: 0px;
            background-color: $hot-viewOfferBtn-bg;
            background-image: url(../../images/view-invest-offer-icon.png);
            background-repeat: no-repeat;
            background-position: ($overlay-width - 45px) center;
            border-radius: 0 0 4px 4px;
            padding: 9px 30px 0px 10px;
            text-align: left;
            font-family: 'latobold';
            font-size: 14px;
            color: #ffffff;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
          }
        }
      }

      .corner-ribbon {
        width: 120px;
        background: #2c7;
        position: relative;
        top: 25px;
        left: -50px;
        text-align: center;
        line-height: 120px;
        color: #f0f0f0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .shadow {
        box-shadow: 0 0 3px rgba(0, 0, 0, .3);
      }

      .top-right {
        top: -178px;
        right: -127px;
        bottom: 0;
        font-size: 16px;
        font-family: 'latoregular';
        left: auto;
        padding: 0;
        transform: rotate(-315deg);
        -webkit-transform: rotate(-315deg);
        position: relative;
        height: 75px;
      }

    }
    /* Home offer tile top container ends */

    .hot-bottom-cont {
      float: left;
      width: 100%;
      margin: 0px;
      padding: 0px 10px;
      padding-top: 20px;

      div {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        position: relative;

        span,
        a {
          float: left;
          max-width: 100%;
          //margin: 0px;
          margin: 15px 0px;
          font-family: 'latobold';
          font-size: 16.5px;
          color: $offer-tile-title-color;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @include transition(.3s);

          &:hover {
            color: $txt-main-color;
          }
        }

        p {
          float: left;
          width: 100%;
          margin: 0px;
          padding: 0px;
          font-family: 'latolight';
          font-size: 13px;
          color: $offer-tile-text-color;
          line-height: 18px;
        }

        .hot-details {
          float: left;
          width: 100%;
          margin: 5px 0px 0px 0px;
          padding: 0px;

          li {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 8px 0px 10px;
            list-style: none;
            position: relative;
            @include border(bottom, 1px, solid, $offer-tile-border);

            span {
              float: left;
              margin: 0px;
              padding: 0px;
              color: $offer-tile-details-color;
            }

            span.name {
              width: 40%;
              font-family: 'latoregular';
              font-size: 14px;
              text-align: left;
              padding-top: 2px;
            }

            span.value {
              width: 60%;
              font-family: 'latobold';
              font-size: 16px;
              text-align: right;
            }

            span.name:hover ~ .hot-details-tooltip, span.value:hover ~ .hot-details-tooltip {
              visibility: visible;
              opacity: 1;
              bottom: 30px;
            }
          }
        }
        .hot-tags {
          float: left;
          width: 100%;
          margin: 0px;
          padding: 10px 0px 15px 25px;
          background-image: url(../../images/offer-tile-tags.png);
          background-repeat: no-repeat;
          background-position: 0px 14px;

          li {
            float: left;
            padding: 5px 5px 0px 0px;
            margin: 0px;
            list-style: none;

            a {
              float: left;
              margin: 0px;
              padding: 0px;
              font-family: 'latolight';
              font-size: 12px;
              color: $offer-tile-text-color;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
                color: $blue-hover-text;
              }

              &:after {
                content: ", ";
                font-family: 'latolight';
                font-size: 12px;
                color: $blue-hover-text;
              }
            }

            &:last-of-type a:after {
              content: "";
            }
          }
        }

      }
    }
    /* Home offer tile bottom container ends */
  }
  .link-wrap {
    padding-top: 55px;
  }
}

.home-section-title {
  min-width: 150px;
  display: inline-block;
  margin: 0px 0px 35px;
  padding: 0px 0px 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  color: $home-section-title-color;
  &.white {
    color: #ffffff;
  }
  &.tagline {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 20px;
    text-transform: initial;
    &:after {
      content: none;
    }
  }
  &:after {
    bottom: 0px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    position: absolute;
    width: 150px;
    background-color: $home-section-title-bg;
  }
}

.no-live-offers {
  // Honestly, this is getting annoying that I have to overwrite rules,
  // because everything is so specific
  padding-top: 0 !important;

  p {
    text-align: center;
    font-size: 20px;
    color: #3f3e3e;
  }
}

#home-curr-off {
  padding-top: 80px;
  padding-bottom: 120px;
  @include border(bottom, 1px, solid, $light-grey-border-opacity);
}

#home-feat-syndi {
  background-image: none;

  > div {
    min-height: auto;
    padding: 85px 0;
  }
  .link-wrap {
    padding-top: 0;
  }
}

#home-raise-capital {
  background-image: none;
  background-color: $dark-header-bg;

  .landing-featured-box-out {
    padding-top: 50px;
  }

  .single-capital-box {
    background-color: transparent;
    max-width: 370px;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    @include mquery(1200px) {
      max-width: 300px;
    }

    img {
      float: none;
      display: inline-block;
      max-width: 300px;
      @include mquery(580px) {
        max-width: 250px;
      }
    }

    span {
      float: left;
      width: 100%;
      font-family: 'latolight';
      font-size: 25px;
      color: $txt-white;
      text-align: center;
      @include mquery(580px) {
        font-size: 20px;
      }
    }
    p {
      float: left;
      width: 100%;
      font-family: 'latolight';
      font-size: 18px;
      color: $txt-white;
      text-align: center;
      padding: 0;
      margin: 35px 0 0;
      line-height: 24px;
      @include mquery(580px) {
        font-size: 16px;
      }
    }
  }
  .link-wrap {
    padding-top: 0;
    padding-bottom: 5px;
  }
}

/* Homepage call to action section */
.homepage-cta-wrapper > div {
  min-height: auto;
  margin-top: 40px;
}

.home-cta-section {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;

  .hcs-buttons {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    text-align: center;

    a {
      display: inline-block;
      width: 100%;
      max-width: 300px;
      height: 50px;
      margin: 0px 10px;
      padding: 14px 0px 0px 65px;
      font-family: 'latobold';
      font-size: 15px;
      color: $default-blue-color;
      @include border(full, 2px, solid, $default-blue-color);
      text-align: left;
      position: relative;
      text-transform: uppercase;
      @include transition(.3s);

      &:hover {
        background-color: lighten($default-blue-color, 5%);
        color: #ffffff;
      }

      @include mquery(1000px) {
        max-width: 235px;
      }

      @include mquery(805px) {
        font-size: 12px;
        padding: 17px 0px 0px 65px;
        max-width: 200px;
      }

      @include mquery(700px) {
        max-width: 400px;
        font-size: 15px;
        margin-bottom: 15px;
        margin-left: 0px;
        margin-right: 0px;
        padding: 14px 0px 0px 65px;
      }

      span {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 46px;
        height: 46px;
        margin: 0px;
        padding: 14px 0px;
        text-align: center;
        font-family: 'latobold';
        font-size: 15px;
        color: #ffffff;
        background-color: $default-blue-color;
      }
    }
  }

  .hcs-text {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px;

    span {
      float: left;
      width: 100%;
      margin: 0px;
      padding: 0px;
      font-family: 'latolight';
      font-size: 34px;
      color: #3f3e3e;
      text-align: center;
    }

    p {
      float: left;
      width: 100%;
      margin: 20px 0px 0px 0px;
      padding: 0px;
      font-family: 'latolight';
      font-size: 18px;
      color: $default-text-color;
      line-height: 150%;
      text-align: center;
    }
  }
}

.aos-completed-offers {
  background-image: none;

  h4 {
    background-image: url("../../../images/single-person.svg");
    background-size: 38px 38px;
    background-repeat: no-repeat;
    padding: 5px 0px 5px 55px;
    font-size: 28px;
    color: #3f3e3e
  }
}

.aos-coming-soon-offers {
  background-image: none;
  display: flex;
  flex-direction: column;

  h4 {
    background-image: url("../../../images/people.svg");
    background-size: 38px 38px;
    background-repeat: no-repeat;
    background-position: right center;
    padding: 5px 65px 5px 0px;
    text-align: right;
    font-size: 28px;
    color: #3f3e3e;
  }
}

.additional-offers-section {
  margin: 0px;
  padding: 0px;
  min-height: 250px;
  position: relative;
  background-color: $aos-rightcont-bg;

  .green-link {
    display: inline-block;
    width: 100%;
    max-width: 250px;
    text-align: center;
    font-size: 16px;
    font-family: 'latolight';
    margin: 0;
    margin-top: 40px;
    padding: 0 5px;
    color: #ffffff;
    background-color: #aad155;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;
  }

  .foo-button {
    // Pass in margin, base-color
    @include btn(0);
  }

  &.home-section {

    @include mquery(825px) {
      background-color: $aos-rightcont-bg;
    }

    div {
      @include mquery(825px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 45px;
      }

      .aos-completed-offers,
      .aos-coming-soon-offers {
        display: flex;
        flex-direction: column;
        max-width: 460px;
        padding-left: 20px;
        padding-right: 20px;

        @include mquery(825px) {
          width: 100%;
          max-width: none;
          text-align: center;

          span {
            float: none;
            display: inline-block !important;
            width: auto !important;
          }

          p {
            text-align: center;
          }
        }
      }
      .aos-completed-offers {
        align-items: flex-start;
      }
      .aos-coming-soon-offers {
        align-items: flex-end;
      }
    }
  }

  div {
    margin: 0px auto;
    max-width: 1200px;
    padding: 0px;
    position: relative;
    z-index: 2;
  }
}

.aos-completed-offers {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: right 10px bottom 0px;

  span {
    margin: 30px 0px 0px 0px;
    padding: 5px 0px 5px 55px;
    font-size: 28px;
    color: $additional-offer-text-color;
    background-repeat: no-repeat;
    background-position: left center;
    @include mquery(410px) {
      font-size: 24px;
      padding: 9px 0px 9px 65px;
    }
  }

  p {
    float: left;
    width: 100%;
    margin: 20px 0px 40px;
    padding: 0px;
    font-family: 'latolight';
    font-size: 16px;
    color: $additional-offer-text-color;
    line-height: 22px;
    @include mquery(350px) {
      margin-top: 10px;
    }
  }

  @include mquery(825px) {
    width: 100%;
    background-color: $aos-leftcont-bg;
  }
}

.aos-coming-soon-offers {
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0px;
  padding: 0px 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: left 10px bottom 0px;

  span {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 5px 65px 5px 0px;
    font-size: 28px;
    color: $additional-offer-text-color;
    background-image: url(../../images/coming-soon-offers-icon.png);
    background-repeat: no-repeat;
    background-position: right center;
    text-align: right;
    @include mquery(825px) {
      text-align: left;
      padding: 5px 0px 5px 65px;
      background-position: left center;
    }
    @include mquery(410px) {
      font-size: 24px;
      padding: 9px 0px 9px 55px;
    }
  }

  p {
    float: left;
    width: 100%;
    margin: 20px 0px 40px;
    padding: 0px;
    font-family: 'latolight';
    font-size: 16px;
    color: $additional-offer-text-color;
    text-align: right;
    line-height: 22px;
    @include mquery(825px) {
      text-align: left;
    }
    @include mquery(350px) {
      margin-top: 10px;
    }
  }

  @include mquery(825px) {
    width: 100%;
    background-color: $aos-rightcont-bg;
    background-position: right 10px bottom 0px;
  }
}

/* aos-coming-soon-offers ends here */

/* Additional offer section ends */
.what-wedo-section {
  background-image: url('../../../images/space-bg-l.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  // background-attachment: fixed;

  @include mquery(650px) {
    background-image: url('../../../images/space-bg-p.jpg');
  }

  > div > div {
    display: inline-block;
    max-width: 960px;
    padding: 20px 0px 0px 0px;
    width: 100%;
  }
}

// .warning-statement-section {
//     float: left;
//     width: 100%;
//     margin: 0px;
//     padding: 100px 50px 75px 70px;
//     text-align: center;
//     background-color: $main-light-bg;
//     @include mquery(670px) {
//         padding: 100px 25px 75px;
//     }
//     .link-wrap{
//         padding-top: 75px;
//     }
// }

.find-out-more-section {
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;

  .find-out-more-text {
    color: $faq-section-text !important;
    max-width: 950px;
    margin: 0 auto;
    p {
      margin: 20px 0;
      line-height: 1.25;
    }
  }

  &.show {
    padding: 75px 0 75px 0 !important;
    max-height: 1000px;
  }
}

.warning-statement {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 100%;
  // max-width: 950px;
  min-height: 200px;
  //background-color: $main-light-worning-bg;
  position: relative;
  .ws-exclamation {
    display: none;
    position: absolute;
    left: -40px;
    top: -40px;
    width: 95px;
    height: 95px;
    margin: 0px;
    padding: 0px;
    background-image: url(../../images/ws-exclamation.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $main-darker-bg;
    @include mquery(670px) {
      width: 60px;
      height: 60px;
      background-size: 30px 30px;
      left: 50%;
      margin-left: -30px;
    }
  }
  .ws-text {
    // float: left;
    // width: 100%;
    // margin: 0px;
    // padding-right: 20px;
    // padding-left: 20px;
    @include mquery(670px) {
      // padding: 50px 25px 25px 25px;
    }
    .ws-title {
      float: left;
      width: 100%;
      margin: 0px;
      // padding: 0px 10px 0px 25px;
      font-family: 'latoregular';
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      @include mquery(670px) {
        // padding: 0px 10px;
      }
    }
    > ul {
      float: left;
      margin: 3px 0px 0px;
      // padding: 0px 0px 0px 25px;
      width: 100%;
      > li {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 4px 0px;
        // list-style: disc;
        font-family: 'latolight';
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;

      }
    }
  }
}

.home-button {
  @include btn(0);
}

.home-button-reverse {
  @include btn(10px, #fff);
  background: rgba(100, 100, 100, 0.3);
  min-width: 190px;
  display: inline-block;

  &:hover {
    border: none;
  }
}

.find-out-more-button {
  @include btn(0, #fff);
  border: 1px solid #469edc;
  color: #469edc;
}

#equitise-raise-banner {
  background: url(../../images/equitise-raise-banner.png);
  min-height: 300px;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  letter-spacing: .5px;
  padding: 40px;

  @include mquery(950px) {
    flex-direction: column;
    padding: 40px;

    .equitise-raise-banner-info {
      text-align: center;

      h3 {
        text-align: center;
        line-height: 26px;
        margin-bottom: 10px;
      }

      span {
        text-align: center;
        line-height: initial;
      }
    }
  }

  h3 {
    font-family: latoregular;
    font-size: 24px;
    line-height: 36px;
  }

  span {
    font-family: latolight;
    max-width: 600px;
  }

  button {
    margin: 10px;
    padding: 10px;
    min-width: 140px;
    font-size: 16px;
    font-family: 'latolight';
    background: #fff;
    border: 1px solid #fff;
    color: #469edc;
    border-radius: 2px;
    transition: background border 0.3s ease;
    width: 200px;

    &:hover {
      background: #469edc;
      border: 1px solid #469edc;
      color: #fff;
      cursor: pointer;
    }
  }

  .equitise-raise-banner-info {
    width: 100%;
    max-width: 650px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .clock-timer {
    max-width: 400px;

    @include mquery(950px) {
      text-align: center;
      margin: 0 auto;
    }
  }

  #wrapping-clock {
    @include mquery(950px) {
      text-align: center;
    }
  }

  #CountDownCanvas {
    width: 500px;

    @include mquery(950px) {
      width: 350px;
      margin: 25px auto 0;
      display: block;
    }

    @include mquery(650px) {
      width: 300px;
    }
  }

  #CountDownCanvas {
    @include mquery(570px) {
      width: 250px;
    }
  }

  .equitise-raise-form {
    display: flex;
    flex-direction: column;
    min-width: 150px;

    @include mquery(950px) {
      margin: 40px;
    }

    input {
      height: 40px;
      width: 200px;
      margin: 10px;
      text-align: center;
      font-size: 16px;
      font-family: 'latolight';
      border-radius: 2px;
      border: 1px solid #fff;
      color: #5f5f5f;
    }

  }
}

/*---------------------------
      Mina CSS
-----------------------------*/

/* Homepage - Equity Crowdfunding */

.mn-video-container {
  text-align: center;
  border: 1px solid #dad6d6;
  position: relative;
  .img-play-botton {
    position: absolute;
    top: 33%;
    right: 45%;
    width: 120px;
    cursor: pointer;
    @include respond-to(medium-screens) {
      right: 42%;
    }
    @include mquery(736px) {
      right: 43%;
      width: 42px;
    }
  }
}

.mn-dollar-sign-banner {
  background-image: url(../../images/callout-bg-min.jpg);
  width: 100%;
  height: 380.5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 94px;
  position: relative;
  align-items: center;
  @include mquery(736px) {
    padding-top: 30px;
  }

  img {
    text-align: center;
    display: block;
    margin: auto;
    margin-bottom: 43px;

  }
  .mn-invest-50dollar {
    font-family: latolight;
    font-size: 29.9px;
    text-align: center;
    color: #ffffff;
    margin-top: 43px;
    margin: auto;
    max-width: 564px;
    line-height: 40px;
    @include mquery(736px) {
      font-size: 20px;
      width: 80%;
    }

  }
  @include mquery(1200px) {
    background-image: url(../../images/home-callout-bg-min.jpg);
  }

}

.mn-case-study {
  background-image: url(../../images/case-study-bg-min.jpg);
  width: 100%;
  height: 602px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 94px;
  position: relative;
  align-items: center;
  color: #fff;
  line-height: 10px;
  @include respond-to(medium-screens) {
    padding-top: 244px;
  }
  @include mquery(736px) {
    padding-top: 203px;
  }
  .container {
    margin-left: 0;
    padding-left: 0;
    @include mquery(736px) {
      padding-right: 0;
    }
    .mn-case-study-briefcase {
      margin-left: 252px;
      margin-bottom: 30px;
      font-size: 16px;
      color: #de518e;
      vertical-align: middle;
      @include mquery(736px) {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
      }
      @include respond-to(medium-screens) {
        margin-left: 116px;
      }

    }
    .mn-ceo-name {
      margin-left: 252px;
      margin-bottom: 10px;
      font-size: 20px;
      @include mquery(736px) {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
      }
      @include respond-to(medium-screens) {
        margin-left: 116px;
      }
    }
  }
  .mn-case-study-pink {
    width: 654px;
    height: 67px;
    margin-top: 30px;
    margin-bottom: 60px;
    background-color: #de518e;
    padding-top: 15px;
    @include mquery(736px) {
      width: 100%;
      padding-top: 20px;
    }
    @include respond-to(medium-screens) {
      width: 511px;
    }
    .mn-raise {
      margin-left: 252px;
      font-size: 18px;
      @include mquery(736px) {
        margin-left: 20px;
        margin-right: 20px;
        text-align: center;
        font-size: 15px;
      }
      @include respond-to(medium-screens) {
        margin-left: 116px;
      }

      #mn-bg-num {
        font-size: 30px;
        @include mquery(736px) {
          font-size: 25px;
        }
      }
    }
    .mn-customer-quote {
      margin-left: 252px;
      margin-top: 60px;
      @include mquery(736px) {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
      }
      @include respond-to(medium-screens) {
        margin-left: 116px;
      }
    }

  }
  @include mquery(736px) {
    background-image: url(../../images/home-case-study-bg-min.jpg);
  }

}

#awards {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #ebebeb;
  @include mquery(736px) {
    display: none;
  }

  .awards-item {
    h4, p {
      text-align: center;
      line-height: 24px;
    }

    .img-wrap {
      min-height: 40px;
      position: relative;
      margin-top: 60px;
      margin-bottom: 20px;
      cursor: pointer;
    }

    img {
      margin-right: auto;
      margin-left: auto;
      display: block;
      max-width: 300px;
    }

    h4 {
      font-size: 1.4rem;
      line-height: 2.1rem;
      font-weight: 700;
    }

  }

}

#brands {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #fff;
  @include mquery(992px) {
    padding-bottom: 60px;
  }
  .mn-brands-content {
    margin-top: 94px;
    margin-bottom: 121px;
    @include mquery(992px) {
      margin-bottom: 30px;
    }
  }

  img {
    vertical-align: middle;
    height: auto;
    width: auto;
    margin: 0 auto;

    @include mquery(992px) {
      max-width: 250px;
    }
  }
}

.mn-home-blur-box-container {
  padding-left: 0;
  padding-right: 5px;
  background-color: white;
  @include respond-to(medium-screens) {
    padding-right: 0;
  }
  @include mquery(736px) {
    padding-right: 0;
  }

}

.mn-home-pink-blur-box-container {
  padding-right: 0;
  background-color: white;
  padding-left: 5px;
  @include respond-to(medium-screens) {
    padding-left: 0;
  }
  @include mquery(736px) {
    padding-left: 0;
  }
}

.mn-home-box-title-cont {
  width: 100%;
  height: 226px;
  @include mquery(736px) {
    height: 200px;
  }
  .mn-home-box-title {
    font-size: 30px;
    text-align: center;
    font-family: latoregular;
    @include mquery(736px) {
      font-size: 24px;
    }
  }
  .subtitle-white {
    color: white;
    font-size: 24px;
    font-family: latolight;
    max-width: 462px;
    margin: 40px auto;
    text-align: center;
    line-height: 27px;
    @include mquery(736px) {
      font-size: 20px;
    }
  }
}

.first-col {
  background-image: url(../../images/blue-blur.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 114px;
  position: relative;
  text-align: center;
  color: #fff;
  @include mquery(736px) {
    padding-top: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }

}

.second-col {
  background-image: url(../../images/pink-blur.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 114px;
  position: relative;
  text-align: center;
  color: #fff;
  @include mquery(736px) {
    padding-top: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }

}

.mn-home-white-center-btn {
  margin: auto;
  max-width: 253px;
  height: 55px;
  background-color: #fff;
  padding: 20px;
  color: #469edc;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
}

.mn-home-white-center-btn:hover {
  opacity: .8;
}

.home-btn-container-blur {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
  @include mquery(736px) {
    padding-left: 0;
  }
  .mn-home-blue-btn-center {
    height: 55px;
    background-color: #ffffff;
    padding: 19px 35px;
    color: #469edc;
    margin-right: 20px;
    min-width: 253px;
    text-align: center;
    font-family: latoregular;
    transition: .3s;

    @include mquery(500px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }

  .mn-home-blue-btn-center:hover {
    opacity: .8;
  }

  .pink {
    color: #de518e;
  }
  .mn-home-border-white-btn-center {
    width: 253px;
    height: 55px;
    border: solid 2px #ffffff;
    padding: 16px 25px;
    color: #fff;
    text-align: center;
    font-family: latoregular;
    @include transition(.3s);
    &:hover {
      background-color: $htw-btn-bg;
      @include border(full, 2px, solid, $htw-btn-border);

    }

  }

  .mn-pink-hover {
    @include transition(.3s);
    &:hover {
      background-color: #de518e;
      @include border(full, 2px, solid, #de518e);

    }
  }

}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
#awards-mobile {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ebebeb;
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  .carousel-inner {
    height: 250px;
  }
  .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    margin-right: 0 !important;
  }
  .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    margin-left: 0 !important;
  }

  .awards-item {
    h4, p {
      text-align: center;
      line-height: 24px;
    }

    .img-wrap {
      min-height: 40px;
      position: relative;
      margin-top: 60px;
      margin-bottom: 20px;
      @include mquery(400px) {
        margin-top: 37px;
      }
    }

    img {
      margin-right: auto;
      margin-left: auto;
      display: block;
      max-width: 300px;
      @include mquery(400px) {
        max-width: 270px;
      }

    }

    h4 {
      font-size: 1.4rem;
      line-height: 2.1rem;
      font-weight: 700;
    }

  }

  @media screen and (min-width: 736px) {
    display: none;
  }
}
