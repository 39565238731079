@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';



#goodments-top-banner {
  background-image: url(/images/landing-pages/banners/goodments-img_header.jpg);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  display: table;
  @media only screen and (min-width: 2000px) {
    background-image: url(/images/landing-pages/banners/goodments-img_header@2x.png);
  }
  @include mquery (768px) {
    background-size: contain;
    background-image: url(/images/landing-pages/banners/goodments-header_mobile@3x.png);
  }
  .goodments-logo-cnt {
    width: 100%;
    @extend %flex-center;
    display: none;
    @include mquery (768px) {
      display: block;
    }
    .goodments-logo {
      @include logo-style(0, 400px, 250px);
      @include mquery(768px) {
        margin-top: 275px;
        margin-bottom: 40px;
        width: 300px;
      }
      @include mquery(600px) {
        margin-top: 240px;
        margin-bottom: 20px;
        width: 250px;
      }
      @include mquery(500px) {
        margin-top: 210px;
      }
      @include mquery(400px) {
        margin-top: 180px;
      }
      @include mquery(320px) {
        margin-top: 150px;
        width: 200px;
      }
    }
  }
  .goodments-register-cnt {
    margin-top: 250px;
    margin-bottom: 50px;
    @media only screen and (min-width: 1800px) {
      margin-top: 260px;
    }
    @media only screen and (min-width: 2000px) {
      margin-top: 310px;
    }
    @media only screen and (min-width: 2400px) {
      margin-top: 350px;
    }
    @media only screen and (min-width: 2700px) {
      margin-top: 380px;
    }
    @include mquery (768px) {
    margin-top: 20px;
    position: relative;
    }
  }
  .goodments-form-header {
    color: #3E61DF;
    font-size: 40px;
    max-width: 600px;
    margin-bottom: 40px;
    line-height: 1.2;
    letter-spacing: -1.14px;

    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
      margin-top: 0;
    }
  }

  .goodments-form-subheader {
    max-width: 600px;
    margin: 0 auto 40px;
    line-height: 1.5;
    font-size: 18px;
  }
  .goodments-form-register-text {
    font-size: 24px;
    // margin-bottom: 40px;
    letter-spacing: -0.5px;
  }
  .goodments-form-filed-cnt {
    max-width: 849px;
    padding: 40px;
    background-color: #f8f8f8;
  }
  .goodments-form-name {
    padding: 10px;
    margin-right: 20px;
    height: 50px;
    border: none;
    margin-bottom: 25px;
    width: 48%;
    border: 1px solid #bdbdbd;
    &::placeholder {
      color: #D9D9D9;
    }
    @include mquery(720px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .goodments-form-email {
    padding: 10px;
    font-weight: 400;
    height: 50px;
    border: none;
    margin-bottom: 25px;
    width: 48%;
    border: 1px solid #bdbdbd;
    &::placeholder {
      color: #D9D9D9;
    }
    @include mquery(720px) {
      width: 100%;
    }
  }

  .goodments-form-button {
    width: 280px;
    color: white;
    border-radius: 34px;
    height: 50px;
    background-color: #32DB9A;
    border: 1px solid #32DB9A;
    transition: .5s;
    font-size: 20px;
    letter-spacing: -0.56px;
    &:hover {
      background-color: #fff;
      color: #32DB9A;
      border: 1px solid #32DB9A;
    }
    @include mquery(720px) {
      width: 100%;
    }
  }
}

#goodments-mission-sec{
  height: 424px;
  background-color: #3E61DF;
  position: relative;
  .goodments-mission-title {
    font-size: 40px;
    color: #fff;
    margin-bottom: 40px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-mission-subtitle {
    font-size: 40px;
    color: #fff;
    font-size: 28px;
    max-width: 511px;
    line-height: 1.3;
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-iphone-cnt {
    height: 424px;
    width: 40%;
    @include mquery (768px) {
      width: 100%;
    }
  }
  .goodments-hand-icon {
    background-image: url(/images/landing-pages/icons/goodments_ic_rockHand.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 315px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mquery (1000px) {
      height: 424px;
      background-position: center;
    }
  }
  .goodments-iphone {
    position: absolute;
    bottom: 0;
    @include mquery (768px) {
      right: 0;
    }
  }
}


#goodments-video-sec{
  background-color: #000;
  width: 100%;
  .goodments-iframe-cnt {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    .goodments-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

#goodments-about-sec {
  padding: 0 0 60px;
  .goodments-about-header {
    color: #CDB3F1;
    font-size: 40px;
    letter-spacing: -0.91px;
    line-height: 1.2;
    margin-bottom: 40px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    @include mquery(1200px) {
      text-align: center;
    }
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-about-copy {
    color: #4B4B4B;
    font-size: 18px;
    letter-spacing: -0.41px;
    line-height: 1.5;
    margin-bottom: 65px;
  }

  .goodments-impact-so-far-cnt {
    border-radius: 8px;
    background-color: rgba(205,179,241,0.7);
    padding: 44px;
    .goodments-impact-header {
      color: #fff;
      font-size: 40px;
      letter-spacing: -0.91px;
      line-height: 1.2;
      margin-bottom: 42px;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 700;
      @include mquery (768px) {
        font-size: 30px;
      }
      @include mquery (600px) {
        font-size: 26px;
      }
    }
    .goodments-impact-icon {
      margin-bottom: 20px;
    }
    .goodments-impact-copy {
      letter-spacing: -0.41px;
      line-height: 1.4;
      color: #2E3074;
      @include mquery(1200px) {
        margin-bottom: 40px;
      }

    }
  }
}

#goodments-sustainable-means{
  padding: 120px 0;
  @include mquery (600px) {
    padding: 60px 0;
  }
  .goodments-ustainable-means-header {
  	color: #CDB3F1;
  	font-size: 40px;
    letter-spacing: -0.91px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-sustainable-means-subheader {
  	color: #4B4B4B;
  	font-size: 21px;
    letter-spacing: -0.48px;
    line-height: 1.5;
    margin-bottom: 50px;
    max-width: 639px;
  }
  .goodments-sustainable-means-circle {
    width: 220px;
    height: 220px;
    border-radius: 100%;
    margin: auto;
    padding: 30px;
    .goodments-sustainable-means-circle-header-cnt {
      padding: 0 23px;
      position: relative;
    }
    .goodments-sustainable-means-orange-circle-header-cnt {
      padding: 0 3px;
      position: relative;
    }
    .goodments-sustainable-means-circle-header {
      	font-size: 80px;
        letter-spacing: -2.05px;
        line-height: 109px;
        color: #fff;
        font-family: 'Noto Sans JP', sans-serif;
    }
    .goodments-sustainable-means-circle-header-dolor {
      color: #fff;
      font-size: 30px;
      font-family: latobold;
      position: absolute;
      top: 16px;
      left: -10px;
      font-family: 'Noto Sans JP', sans-serif;
    }
    .goodments-sustainable-means-circle-header-percent {
      color: #fff;
      font-size: 30px;
      font-family: latobold;
      position: absolute;
      top: 16px;
      font-family: 'Noto Sans JP', sans-serif;
    }
    .goodments-sustainable-means-circle-header-orange  {
      font-size: 70px;
      letter-spacing: -1.82px;
      line-height: 109px;
      color: #fff;
      font-family: 'Noto Sans JP', sans-serif;

    }
    .goodments-sustainable-means-circle-subheader {
      	font-size: 15px;
        letter-spacing: -0.34px;
        line-height: 1.1;
        color: #2E3074;
        text-align: center;
    }
  }

  .how-goodments-track {
    background-color: #F8F8F8;
    padding: 30px;
    background-image: url(/images/landing-pages/icons/goodments-ic_rocket.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 328px;
    margin-top: 80px;
    @include mquery(768px) {
      background-size: cover;
      height: 100%;
    }
    .how-goodments-track-top-header  {
    	color: #CDB3F1;
      font-size: 40px;
      letter-spacing: -0.91px;
      line-height: 1.2;
      margin-bottom: 40px;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 700;
      @include mquery (768px) {
        font-size: 30px;
      }
      @include mquery (600px) {
        font-size: 26px;
      }
    }
    .how-goodments-track-header {
      color: #3E61DF;
      font-size: 60px;
      letter-spacing: -1.82px;
    }
    .how-goodments-track-subheader {
      color: #4B4B4B;
      font-size: 21px;
      letter-spacing: -0.48px;
      line-height: 1.5;
      @include mquery(767px) {
        margin-bottom: 20px;
      }
    }
  }
  .goodments-since-nov-text {
    color: #BDBDBD;
    font-size: 15px;
    letter-spacing: -0.34px;
    line-height: 1.5;
    text-align: center;
    text-align: center;
    margin-top: 89px;
  }
  .goodments-sustainable-source-txt {
    color: #959595;
    letter-spacing: -0.23px;
    font-size: 10px;
    line-height: 12px;
    margin-top: 10px;
    @include mquery (768px) {
      margin-bottom: 40px;
    }
  }
}

#goodments-why-raise {
	background-color: #32DB9A;
	height: 430px;
  position: relative;
  @include mquery(1200px) {
    height: initial;
    padding: 60px 0;
  }
  .goodments-why-raise-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    @include mquery(1200px) {
      flex-direction: column;
    }
  }

  .goodments-lululemon-iphone {
    @include mquery(1200px) {
      margin-bottom: 40px;
    }
  }
  .goodments-why-raise-header {
    font-size: 35px;
  	letter-spacing: -0.8px;
    color: #fff;
    margin-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-why-raise-subheader {
    font-size: 21px;
    letter-spacing: -0.8px;
    color: #fff;
    line-height: 1.5;
    margin: auto;
    max-width: 639px;
    @include mquery(1024px) {
      margin: 0 auto 40px;
    }
  }
  .goodments-partners-img {
    padding: 4px;
  }
}

#goodments-quote {
  padding: 120px 0;
  background-color: #CDB3F1;
  @extend %flex-center;
  @include mquery (1200px) {
    height: initial;
    padding: 60px 0;
  }
  .goodments-quote-header {
    max-width: 1016px;
    margin: auto;
    color: #fff;
    font-size: 40px;
    letter-spacing: -0.5px;
    line-height: 1.2;
    margin-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;

    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .goodments-quote-subheader {
    margin: auto;
    color: #fff;
    font-size: 20px;
    letter-spacing: -0.25px;
    line-height: 1.2;
  }
}

.goodments-link {
   color: #3E61DF;
   &:hover {
     text-decoration: underline;
     color: #3E61DF;
   }
}


.goodments-blue {
  color: #3E61DF;
}

.goodments-purple {
  background-color: #CDB3F1;
}
.goodments-orange {
  background-color: #FFA377;
}
.goodments-green {
  background-color: #A7D9C5;
}
