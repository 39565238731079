@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
BROWSE OFFERS
---------------------------------------------------------- */ 
.invest-filter-options-wrapper {
    float: left;
    width: 100%;
    min-height: 70px;
    margin: 30px 0px 0px 0px;
    padding: 0px 15px 0px 140px;
    position: relative;
    background-color: $default-blue-color;
    .invest-filter-title {
        position: absolute;
        top: 20px;
        left: 20px;
        margin: 0px;
        padding: 0px;
        font-size: 28px;
        font-family: 'latolight';
        color: $filter-text-color;
    }
    .invest-filter-options {
        float: right;
        margin: 15px 0px 0px 0px;
        padding: 0px;
        > form {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            .if-form-group {
                float: left;
                margin: 0px;
                padding: 0px 10px 15px 0px;
                position: relative;
                &:last-of-type {
                    padding-right: 0px;
                }
                > a {
                    float: left;
                    height: 38px;
                    width: 130px;
                    margin: 0px;
                    padding: 9px 34px 0px 10px;
                    font-family: 'latolight';
                    font-size: 14px;
                    color: $filter-text-color;
                    background-position: right 10px center;
                    background-repeat: no-repeat;
                    @include border(full, 1px, solid, $filter-border-color);
                    @include transition(.3s);
                    &.active, &:hover {
                        // background-color: darken($default-blue-color, 10%);
                        background-color: #ffffff;
                        color: $default-blue-color;
                    }
                    &.if-latest-filter {
                        background-image: url('../../images/if-latest-filter-icon.png');
                    }
                    &.if-popular-filter {
                        background-image: url('../../images/if-popular-filter-icon.png');
                    }
                    &.if-funded-filter {
                        background-image: url('../../images/if-funded-filter-icon.png');
                    }
                    &.if-comingsoon-filter {
                        background-image: url('../../images/if-comingsoon-filter-icon.png');
                    }
                }
                .if-select-field {
                    float: left;
                    width: 130px;
                    height: 38px;
                    margin: 0px;
                    padding: 0px;
                    position: relative;
                    z-index: 1;
                    @include border(full, 1px, solid, $filter-border-color);
                    @include transition(.3s);
                    &:before {
                        background-color: $default-blue-color;
                        background-image: url(../../images/if-select-field-arrow.png);
                        background-position: center center;
                        background-repeat: no-repeat;
                        content: "";
                        height: 36px;
                        width: 36px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        z-index: 2;
                        @include transition(.3s);
                    }
                    > select {
                        float: left;
                        width: 100%;
                        height: 36px;
                        margin: 0px;
                        padding: 0px 35px 3px 10px;
                        font-family: 'latolight';
                        font-size: 14px;
                        color: $filter-text-color;
                        background-color: transparent;
                        border: 0px;
                        cursor: pointer;
                        z-index: 3;
                        position: relative;
                        > option {
                            background-color: #BBBBBB;
                        }
                    }
                    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                        color:    #ffffff;
                    }
                    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                        color:    #ffffff;
                        opacity:  1;
                    }
                    &::-moz-placeholder { /* Mozilla Firefox 19+ */
                        color:    #ffffff;
                        opacity:  1;
                    }
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color:    #ffffff;
                    }
                } /* if-select-field ends here */
                .if-search-field {
                    float: left;
                    width: 130px;
                    height: 38px;
                    margin: 0px;
                    padding: 0px 36px 3px 10px;
                    font-family: 'latolight';
                    font-size: 14px;
                    color: $filter-text-color;
                    background-color: $default-blue-color;
                    @include border(full, 1px, solid, $filter-border-color);
                    @include transition(.3s);
                    &:hover, &:focus {
                        background-color: darken($default-blue-color, 10%);
                    }

                    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                        color:    #ffffff;
                    }
                    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                        color:    #ffffff;
                        opacity:  1;
                    }
                    &::-moz-placeholder { /* Mozilla Firefox 19+ */
                        color:    #ffffff;
                        opacity:  1;
                    }
                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color:    #ffffff;
                    }

                }
                .if-search-field-btn {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 36px;
                    height: 36px;
                    margin: 0px;
                    padding: 0px;
                    cursor: pointer;
                    border: 0px;
                    background-color: transparent;
                    background-image: url('../../images/if-search-icon.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
}
.invest-tiles-container {
    float: left;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    width: 100%;
    text-align: center;
    .invest-tiles-half {
        float: left;
        margin: 0px;
        padding: 10px 0px 10px 0px;
        width: 50%;
        &:nth-child(2n+1) {
            padding-right: 10px;
        }
        &:nth-child(2n+2) {
            padding-left: 10px;
        }
        @include mquery(580px) {
            display: inline-block;
            float: none;
            width: 100%;
            max-width: 360px;
            &:nth-child(2n+1) {
            padding-right: 0px;
            }
            &:nth-child(2n+2) {
                padding-left: 0px;
            }
        }
        .single-invest-tile {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            position: relative;
            background-color: $invest-tiles-bg;
            @include border(full, 1px, solid, $invest-tiles-border);
            -webkit-box-shadow: 1px 1px 2px $invest-tiles-border;
            -moz-box-shadow: 1px 1px 2px $invest-tiles-border;
            -o-box-shadow: 1px 1px 2px $invest-tiles-border;
            box-shadow: 1px 1px 2px $invest-tiles-border;
        }
        .sit-status {
            position: absolute;
            top: 17px;
            left: 0px;
            margin: 0px;
            padding: 0px;
            max-width: 130px;
            width: 100%;
            > div {
                float: left;
                margin: 0px;
                padding: 0px;
                width: 100%;
                > span {
                    float: left;
                    width: 100%;
                    height: 30px;
                    margin: 0px;
                    padding: 8px 10px;
                    font-family: 'latoblack';
                    font-size: 13px;
                    color: #ffffff;
                    text-align: left;
                    position: relative;
                    z-index: 1;
                    &.completed {
                        display: none;
                        background-color: #aad155;
                        &:after {
                            content: "";
                            height: 0px;
                            width: 0px; 
                            position: absolute;
                            top: 0px;
                            right: -5px;
                            z-index: 2;
                            border-bottom: 15px solid transparent;  
                            border-left: 5px solid #aad155; 
                            border-top: 15px solid transparent; 
                        }
                        &:before {
                            background-image: url("../../images/hot-tile-completed-checkmark.png");
                            background-repeat: no-repeat;
                            bottom: 0px;
                            right: -3px;
                            width: 27px;
                            height: 22px;
                            margin: 0px;
                            padding: 0px;
                            position: absolute;
                            content: "";
                            z-index: 3;
                        }
                        &.active {
                            display: inline-block;
                        }
                    }
                    &.closed {
                        display: none;
                        background-color: #dd5871;
                        &:after {
                            content: "";
                            height: 0px;
                            width: 0px; 
                            position: absolute;
                            top: 0px;
                            right: -5px;
                            z-index: 2;
                            border-bottom: 15px solid transparent;  
                            border-left: 5px solid #dd5871; 
                            border-top: 15px solid transparent; 
                        }
                        &:before {
                            background-image: url("../../images/hot-tile-closed-icon.png");
                            background-repeat: no-repeat;
                            bottom: 0px;
                            right: -3px;
                            width: 23px;
                            height: 22px;
                            margin: 0px;
                            padding: 0px;
                            position: absolute;
                            content: "";
                            z-index: 3;
                        }
                        &.active {
                            display: inline-block;
                        }
                    }
                }
            }
        } /* Invest tile status ends here */
        .sit-syndicate-badge-wrapper {
            position: absolute;
            top: 0px;
            right: 10px;
            margin: 0px;
            padding: 0px;
            z-index: 5;
            .sit-syndicate-badge {
                float: left;
                width: 29px;
                height: 24px;
                margin: 0px;
                padding: 0px;
                background-color: $it-syndicate-badge-bg;
                &:after {
                    position: absolute;
                    bottom: -5px;
                    right: 0px;
                    width: 0px;
                    height: 0px;
                    margin: 0px;
                    padding: 0px;
                    border-right: 14px solid transparent;   
                    border-left: 15px solid transparent;    
                    border-top: 5px solid $it-syndicate-badge-bg;   
                    content: "";
                    z-index: 2;
                }
                &:before {
                    position: absolute;
                    bottom: -13px;
                    right: -11px;
                    margin: 0px;
                    padding: 0px;
                    width: 32px;
                    height: 30px;
                    background-image: url("../../images/hot-tile-syndicate-icon2.png");
                    background-repeat: no-repeat;
                    background-size: 21px 20px;
                    content: "";
                    z-index: 3;
                }
            }
            .sit-badges-tooltip {
                background-color: $tooltip-color;
                border-radius: 2px;
                bottom: 35px;
                color: #ffffff;
                font-family: "latolight";
                font-size: 12px;
                line-height: 15px;
                margin: 0;
                min-height: 24px;
                min-width: 170px;
                opacity: 0;
                padding: 5px 10px 6px;
                position: absolute;
                right: -2px;
                visibility: hidden;
                z-index: 100;
                &:after {
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $tooltip-color;
                    bottom: -6px;
                    content: "";
                    height: 0;
                    position: absolute;
                    right: 10px;
                    width: 0;
                }
            }

        } /* Invest tile syndicate badge ends here */
        .sit-top-info {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 10px 10px 10px 150px;
            min-height: 150px;
            position: relative;
            @include mquery(775px) {
                padding: 0px;
            }
            .sit-offer-image {
                height: 130px;
                left: 10px;
                margin: 0;
                overflow: hidden;
                padding: 0;
                position: absolute;
                top: 10px;
                width: 130px;
                > img {
                    height: 100%;
                    left: -40px;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                }
                @include mquery(775px) {
                    float: left;
                    height: auto;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    position: relative;
                    > img {
                        float: left;
                        width: 100%;
                        left: 0px;
                        position: relative;
                        height: auto;
                    }
                }
            }
            .sit-brief-info {
                float: left;
                margin: 0;
                min-height: 130px;
                padding: 0 0 35px;
                position: relative;
                width: 100%;
                @include mquery(775px) {
                    padding: 0px 10px 35px;
                }
                .sit-info-title {
                    color: $default-text-color;
                    float: left;
                    font-family: "latobold";
                    font-size: 20px;
                    margin: 10px 0 0;
                    padding: 0 35px 0 0;
                    width: 100%;
                }
                p.sit-info-desc {
                    color: $offer-tile-text-color;
                    float: left;
                    font-family: "latolight";
                    font-size: 14px;
                    line-height: 18px;
                    margin: 10px 0 0;
                    padding: 0;
                    width: 100%;
                }
                span.sit-info-location {
                    background-image: url("../../images/offer-tile-location.png");
                    background-position: 2px center;
                    background-repeat: no-repeat;
                    bottom: 10px;
                    color: $offer-tile-text-color;
                    font-family: "latolight";
                    font-size: 12px;
                    left: 0;
                    margin: 0;
                    padding: 0 0 0 20px;
                    position: absolute;
                    width: 100%;
                    @include mquery(775px) {
                        background-position: 12px center;
                        bottom: 14px;
                        padding: 0px 0px 0px 30px;
                    }
                }
            }
        }
        .sit-stats-cont {
            background-color: $it-stats-bg;
            @include border(top, 1px, solid, $it-stats-border);
            border-bottom-width: 1px;
            float: left;
            margin: 0;
            padding: 0;
            width: 100%;
            > li {
                @include border(right, 1px, solid, $it-stats-border-li);
                float: left;
                height: 60px;
                margin: 0;
                padding: 0;
                width: 25%;
                @include mquery(1110px) {
                    width: 50%;
                    &:nth-child(2n+2) {
                        border-right: 0px;
                    }
                    &:nth-child(-n+2) {
                        @include border(bottom, 1px, solid, $it-stats-border-li);
                    }
                }
                &.last-stat {
                    width: 100%;
                    border-top-width: 1px;
                    height: 38px;
                    > span {
                        width: 50%;
                        &.sit-stat-name {
                            margin-top: 10px;
                        }
                        &.sit-stat-value {
                            text-align: right;
                            @include mquery(1110px) {
                                text-align: left;
                            }
                        }
                    }
                }
                .sit-stat-name {
                    color: #ffffff;
                    float: left;
                    font-family: "latolight";
                    font-size: 13px;
                    margin: 9px 0 0;
                    padding: 0 10px;
                    width: 100%;
                }
                .sit-stat-value {
                    color: #ffffff;
                    float: left;
                    font-family: "latobold";
                    font-size: 16px;
                    margin: 9px 0 0;
                    padding: 0 10px;
                    width: 100%;
                }
            }
        }
        .sit-tags-cont {
            background-image: url("../../images/offer-tile-tags.png");
            background-position: 10px 10px;
            background-repeat: no-repeat;
            float: left;
            margin: 0;
            padding: 10px 0 12px 32px;
            width: 100%;
            > li {
                float: left;
                list-style: outside none none;
                margin: 0;
                padding: 0;
                > a {
                    color: $offer-tile-text-color;
                    float: left;
                    font-family: "latolight";
                    font-size: 12px;
                    margin: 0 5px 0 0;
                    padding: 0;
                    text-decoration: none;
                    &:after {
                        color: $default-blue-color;
                        content: ", ";
                        font-family: "latolight";
                        font-size: 12px;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}