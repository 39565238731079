@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
CREATE PORJECT PAGE
---------------------------------------------------------- */

/* Create project tabs */
ul.create-project-tabs {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px;
    > li {
        float: left;
        width: 16.66%;
        min-height: 39px;
        margin: 0px;
        padding: 0px 0px 0px 1px;
        list-style: none;
        position: relative;
        &:first-of-type {
            padding-left: 0px;
        }
        @include mquery(920px) {
            width: 33.3%;
            padding: 0px 0px 1px 1px;
        }
        @include mquery(500px) {
            width: 50%;
            padding: 0px 0px 1px 1px;
        }
        @include mquery(400px) {
            width: 100%;
            padding: 0px 0px 1px 0px;
        }
        > a {
            float: left;
            width: 100%;
            min-height: 39px;
            margin: 0px;
            padding: 10px;
            background-color: #ffffff;
            font-family: 'latobold';
            font-size: 15px;
            color: #7e7e7e;
            text-decoration: none;
            text-align: center;
            &:hover {
                text-decoration:underline;
            }
            &.active {
                background-color: $default-blue-color;
                color: #ffffff;
                &:hover {
                    text-decoration: none;
                }
            }
            &.error {
                background-color: #f67f7f;
                color: #ffffff;
                &:hover {
                    text-decoration: none;
                }
                & ~ span.cpt-error-number {
                    display: inline-block;
                }
            }
        }
        > span.cpt-error-number {
            display: none;
            position: absolute;
            top: -13px;
            right: 7px;
            width: 24px;
            height: 24px;
            margin: 0px;
            padding: 4px 0px;
            @include border(full, 2px, solid, #ffffff);
            border-radius: 12px;
            font-family: 'latoblack';
            font-size: 11px;
            color: #ffffff;
            text-align: center;
            background-color: #f67f7f;
        }
    }
    &.three-tabs {
        > li {
            width: 33.33%;
            @include mquery(850px) {
                width: 33.3%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(560px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.four-tabs {
        > li {
            width: 25%;
            @include mquery(800px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(460px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.five-tabs {
        > li {
            width: 20%;
            @include mquery(850px) {
                width: 33.3%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(550px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(430px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.six-tabs {
        > li {
            width: 16.66%;
            @include mquery(920px) {
                width: 33.3%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(500px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(400px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
}

/* Create project wrapper */
.create-project-wrapper {
    float: left;
    width: 100%;
    margin: 25px 0px 25px 0px;
    padding: 20px;
    background-color: #ffffff;
    .cp-form-group-wrapper {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > form {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
    }

    .default-success-tooltip {
        margin-bottom: 25px;
        margin-top: 0;
    }

    .default-warning-tooltip.id-info-tooltip {
        display: none;
    }


}
.cp-add-block-btn {
    float: left;
    width: 100%;
    min-height: 200px;
    margin: 0px;
    padding: 125px 100px 56px;
    background-color: #e6f1f8;
    background-image: url(../../images/add-new-block-icon.png);
    background-repeat: no-repeat;
    background-position: center top 60px;
    font-family: 'latobold';
    font-size: 18px;
    color: #99b9cf;
    text-align: center;
    @include transition(.3s);
    &:hover {
        background-color: #f0f5f8;
    }
}
.cp-sections-title {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px;
    font-family: 'latobold';
    font-size: 18px;
    color: #7e7e7e;
    text-transform: uppercase;
    &:first-of-type {
        margin-top: 0px;
    }
}
.cp-form-group-ul {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    > li {
        float: left;
        width: 50%;
        margin: 0px 0px 10px 0px;
        padding: 0px;
        &:nth-child(odd) {
            padding-right: 5px;
        }
        &:nth-child(even) {
            padding-left: 5px;
        }
        @include mquery(950px) {
            width: 100%;
            &:nth-child(odd) {
                padding-right: 0px;
            }
            &:nth-child(even) {
                padding-left: 0px;
            }
        }

        .cp-fg-textarea-container {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 15px;
            background-color: #f3f5f6;
            position:relative;
            .cp-fg-tc-title {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                > span {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    font-family: 'latoregular';
                    font-size: 15px;
                    color: #7e7e7e;
                }
            }
            > textarea {
                fborder-color: #dfe6eb;
                border-style: solid;
                border-width: 1px;
                color: #7e7e7e;
                float: left;
                font-family: "latobold";
                font-size: 14px;
                height: 140px;
                margin: 15px 0 0;
                padding: 10px;
                width: 100%;
                resize: none;
            }
        }
    }
}
.cp-form-group {
    float: left;
    width: 100%;
    min-height: 60px;
    margin: 0px;
    padding: 22px 300px 22px 15px;
    position: relative;
    background-color: #f3f5f6;
    @include mquery(1023px) {
        padding: 22px 235px 22px 15px;
    }
    @include mquery(950px) {
        padding: 22px 365px 22px 15px;
    }
    @include mquery(750px) {
        padding: 22px 265px 22px 15px;
    }
    @include mquery(580px) {
        padding: 15px;
    }
}
.cp-form-group-title {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px 20px 0px 0px;
    font-family: 'latoregular';
    font-size: 14px;
    color: #7e7e7e;
    position: relative;
}
.cr-fg-input-cont {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 100%;
    max-width: 280px;
    @include mquery(1023px) {
        max-width: 215px;
    }
    @include mquery(950px) {
        max-width: 340px;
    }
    @include mquery(745px) {
        max-width: 240px;
    }
    @include mquery(580px) {
        position: relative;
        top: auto;
        right: auto;
        float: left;
        max-width: 100%;
        margin-top: 10px;
    }
    > textarea {
        float: left;
        width: 100%;
        height: 112px;
        margin: 0px;
        padding: 0px;
        border-style: solid;
        border-color: #dfe6eb;
        border-width: 1px;
        color: #7e7e7e;
        padding: 9px 10px;
        font-family: 'latobold';
        font-size: 14px;
        resize: none;
        z-index: 1000;
    }
}

.cp-fg-input-cont {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 100%;
    max-width: 280px;
    @include mquery(1023px) {
        max-width: 215px;
    }
    @include mquery(950px) {
        max-width: 340px;
    }
    @include mquery(745px) {
        max-width: 240px;
    }
    @include mquery(580px) {
        position: relative;
        top: auto;
        right: auto;
        float: left;
        max-width: 100%;
        margin-top: 10px;
    }
    > textarea {
        float: left;
        width: 100%;
        height: 112px;
        margin: 0px;
        padding: 0px;
        border-style: solid;
        border-color: #dfe6eb;
        border-width: 1px;
        color: #7e7e7e;
        padding: 9px 10px;
        font-family: 'latobold';
        font-size: 14px;
        resize: none;
        // z-index: 1000;
        position: absolute;
        top: 0px;
        right: 0px;
        &:focus {
            height: 112px;
            z-index: 1000;
        }
    }
    > input[type="text"], > input[type="password"] {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        border-style: solid;
        border-color: #dfe6eb;
        border-width: 1px;
        color: #7e7e7e;
        padding: 0px 10px;
        font-family: 'latobold';
        font-size: 14px;
    }
    .cp-fg-select-field {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        @include border(full, 1px, solid, $input-field-border);
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            width: 40px;
            height: 40px;
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            background-image: url(../../images/select-field-arrow.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;
            z-index: 2;
        }
        > select {
            float: left;
            width: 100%;
            height: 40px;
            margin: 0px;
            padding: 0px 10px 1px;
            margin: 0px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            border: 0px;
            position: relative;
            z-index: 3;
            background-color: transparent;
            cursor: pointer;
        }
        @include mquery(580px) {
            margin: 0px;
        }
    }
    .cp-fg-radiobutton-cont {
        float: right;
        padding: 9px 10px;
    }
    .cp-fg-file-field {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        @include border(full, 1px, solid, $input-field-border);
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            width: 40px;
            height: 40px;
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            background-image: url(../../images/file-field-icon.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;
            z-index: 2;
        }
        > input {
            float: left;
            width: 100%;
            height: 40px;
            margin: 0px;
            padding: 0px 10px;
            margin: 0px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            border: 0px;
            position: relative;
            z-index: 3;
            background-color: transparent;
            text-indent: -99999px;
            opacity: 0;
            cursor: pointer;
        }
        > span {
            position: absolute;
            top: 12px;
            left: 0px;
            margin: 0px;
            padding: 0px 42px 3px 15px;
            width: 100%;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        @include mquery(580px) {
            margin: 0px;
        }
    } /* sfg file input ends here */
}
.cp-fg-image-preview {
    position: absolute;
    top: 1px;
    left: -45px;
    width: 39px;
    height: 39px;
    cursor: pointer;
    text-align: center;
    .cp-fg-ip-thumbnail {
        float: none;
        display: inline-block;
        max-width: 39px;
        max-height: 39px;
    }
}
.cp-fg-ip-thumbnail-image {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 45px;
    left: 0px;
    width: 150px;
    height: 150px;
    padding: 1px;
    background-color: $img-hover-popup-bg;
    border-radius: 3px;
    z-index: 1005;
    @include transition(.3s);
    &:before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 14px;
        margin: 0px;
        padding: 0px;
        border-top: 6px solid $img-hover-popup-bg;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
    }
    > img {
        float: left;
        max-width: 100%;
        max-height: 100%;
        margin: 0px;
        padding: 0px;
        border-radius: 3px;
    }
}
.cp-fg-image-preview > img:hover ~ .cp-fg-ip-thumbnail-image {
    opacity: 1;
    visibility: visible;
}
.cp-form-helper {
    background-color: #9ec0d1;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    float: right;
    font-family: "latoblack";
    font-size: 12px;
    height: 16px;
    margin: 0;
    padding: 2px 0 0;
    text-align: center;
    width: 16px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.cp-form-helper:hover .cp-form-helper-text {
    opacity: 1;
    visibility: visible;
}
.cp-form-helper-text {
    background-color: #6c99af;
    border-radius: 2px;
    bottom: 24px;
    color: #ffffff;
    font-family: "latolight";
    font-size: 12px;
    margin: 0;
    min-height: 22px;
    min-width: 200px;
    padding: 10px;
    position: absolute;
    right: -8px;
    text-align: center;
    @include transition(.3s);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    line-height: 130%;
    &:before {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #6c99af;
        bottom: -5px;
        content: "";
        height: 0;
        right: 10px;
        position: absolute;
        width: 0;
    }
}
.cp-action-buttons-cont {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    min-height: 80px;
    background-color: #3b739a;
    z-index: 1005;

    @include mquery(420px) {
        height: 60px;
        min-height: 60px !important;
        max-height: 60px;
    }

}
.cp-action-buttons-cont > div {
    margin: 0px auto;
    width: 100%;
    max-width: 1200px;
    padding: 8px 10px 0px;

    @include mquery(420px) {
        padding: 0;
    }
}
.cp-action-buttons-info {
    float: left;
    width: 100%;
    max-width: 300px;
    margin: 0px;
    background-color: #e0edf6;
    background-image: url("../../images/default-info-tooltip-icon.png");
    background-position: 20px 20px;
    background-repeat: no-repeat;
    padding: 14px 15px 16px 63px;
    position: relative;
    @include mquery(980px) {
        max-width: 100%;
        background-position: 20px 12px;
    }
}
.cp-action-buttons-info > span {
    color: #6195b9;
    float: left;
    font-family: "latoregular";
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    padding: 0;
    width: 100%;
}
.cp-action-buttons {
    float: right;
    margin: 0px;
    padding: 15px 0px;
    min-width: 630px;
    @include mquery(980px) {
        min-width: 100%;
        text-align: center;
        padding: 15px 20px;
        > a {
            float: none !important;
            display: inline-block;
            margin-bottom: 10px !important;
            @include mquery(456px) {
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }
    }

    @include mquery(700px) {
        display: flex;
        flex-direction: row;
        a {
            flex: 1;
        }
    }

    .offer-word {
        font: inherit;
    }

    @include mquery(580px) {
        .offer-word {
            display: none;
        }
    }


    @include mquery(420px) {
        padding: 5px 0px;
        a {
            margin: 5px !important;
            font-size: 12px;
        }
    }
}
.cp-action-buttons > a {
    float: left;
    width: 100%;
    max-width: 200px;
    height: 40px;
    margin: 0px 15px 0px 0px;
    padding: 12px 15px;
    font-family: 'latobold';
    font-size: 14px;
    color: #ffffff;
    background-repeat: no-repeat;
    background-position: right 15px center;
    @include transition(.3s);
    &:last-of-type {
        margin-right: 0px;
    }
}
.cp-action-buttons .cp-save-offer {
    background-color: #ff9e20;
    background-image: url(../../images/cp-save-icon.png);
    &:hover {
        background-color: lighten(#ff9e20, 5%);
    }
}
.cp-action-buttons .cp-preview-offer {
    background-color: #469edc;
    background-image: url(../../images/cp-preview-icon.png);
    &:hover {
        background-color: lighten(#469edc, 5%);
    }
}
.cp-action-buttons .cp-submit-offer {
    background-color: #aad155;
    background-image: url(../../images/cp-submit-icon.png);
    &:hover {
        background-color: lighten(#aad155, 5%);
    }
}
.cp-formgroup-textarea {
    min-height: 130px !important;
}
.cr-announcements-form > .cp-form-group {
    margin-top: 10px;
    &:first-of-type {
        margin-top: 0px;
    }
}
.cp-add-announcement-btn {
    float: right;
    width: 100%;
    max-width: 260px;
    height: 45px;
    margin: 20px 0px 0px 0px;
    padding: 14px 15px;
    font-family: 'latoblack';
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    background-color: $default-blue-color;
    @include transition(.3s);
    text-transform: uppercase;
    background-image: url("../../images/cert-upload-btn-icon.png");
    background-repeat: no-repeat;
    background-position: right 15px center;
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
}

.document-cont-list {
    .error-tooltip {
        margin-right: 50px !important;
    }
}
/* Create project who is behind tab */
.cp-who-behind-wrapper {
    float: left;
    width: 100%;
    margin: 25px 0px 0px 0px;
    padding: 20px;
    background-color: #ffffff;
}
.cp-documents-browse-input {
    margin-top: 2px;
}
.cp-editable-input {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.cp-editable-input > input {
    border-color: #dfe6eb;
    border-style: solid;
    border-width: 1px;
    color: #7e7e7e;
    float: left;
    font-family: "latobold";
    font-size: 14px;
    height: 42px;
    margin: 0;
    padding: 0 10px;
    width: 100%;
}
.cp-editable-block > textarea {
    height: 113px !important;
}
.tabs-control{}
.tabs-control-tab{display:none;}
.tabs-control-tab.active{display:block;}



/* ----------------------------------------------------------
CREATE PORJECT PAGE
---------------------------------------------------------- */
/* Create project tabs */
ul.create-project-tabs {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px;
    > li {
        float: left;
        min-height: 39px;
        margin: 0px;
        padding: 0px 0px 0px 1px;
        list-style: none;
        position: relative;
        &:first-of-type {
            padding-left: 0px;
        }
        > a {
            float: left;
            width: 100%;
            min-height: 39px;
            margin: 0px;
            padding: 10px;
            background-color: #ffffff;
            font-family: 'latobold';
            font-size: 15px;
            color: #7e7e7e;
            text-decoration: none;
            text-align: center;
            &:hover {
                text-decoration:underline;
            }
            &.active {
                background-color: $default-blue-color;
                color: #ffffff;
                &:hover {
                    text-decoration: none;
                }
            }
            &.error {
                background-color: #f67f7f;
                color: #ffffff;
                &:hover {
                    text-decoration: none;
                }
                & ~ span.cpt-error-number {
                    display: inline-block;
                }
            }
        }
        > span.cpt-error-number {
            display: none;
            position: absolute;
            top: -13px;
            right: 7px;
            width: 24px;
            height: 24px;
            margin: 0px;
            padding: 4px 0px;
            @include border(full, 2px, solid, #ffffff);
            border-radius: 12px;
            font-family: 'latoblack';
            font-size: 11px;
            color: #ffffff;
            text-align: center;
            background-color: #f67f7f;
        }
    }
    &.tabs-3 {
        > li {
            width: 33.33%;
            @include mquery(850px) {
                width: 33.3%;    padding: 0px 0px 1px 1px;
            }
            @include mquery(560px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.tabs-4 {
        > li {
            width: 25%;
            @include mquery(800px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(460px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.tabs-5 {
        > li {
            width: 20%;
            @include mquery(850px) {
                width: 33.3%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(550px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(430px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
    &.tabs-6 {
        > li {
            width: 16.66%;
            @include mquery(920px) {
                width: 33.3%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(500px) {
                width: 50%;
                padding: 0px 0px 1px 1px;
            }
            @include mquery(400px) {
                width: 100%;
                padding: 0px 0px 1px 0px;
            }
        }
    }
}

/* Create project wrapper */
.create-project-wrapper {
    float: left;
    width: 100%;
    margin: 25px 0px 25px 0px;
    padding: 20px;
    background-color: #ffffff;
    .cp-form-group-wrapper {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > form {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
    }
}
.cp-add-block-btn {
    float: left;
    width: 100%;
    min-height: 200px;
    margin: 0px;
    padding: 125px 100px 56px;
    background-color: #e6f1f8;
    background-image: url(../../images/add-new-block-icon.png);
    background-repeat: no-repeat;
    background-position: center top 60px;
    font-family: 'latobold';
    font-size: 18px;
    color: #99b9cf;
    text-align: center;
    @include transition(.3s);
    &:hover {
        background-color: #f0f5f8;
    }
}
.cp-sections-title {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px;
    font-family: 'latobold';
    font-size: 18px;
    color: #7e7e7e;
    text-transform: uppercase;
    &:first-of-type {
        margin-top: 0px;
    }
}
.cp-form-group-ul {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    > li {
        float: left;
        width: 50%;
        margin: 0px 0px 10px 0px;
        padding: 0px;
        &:nth-child(odd) {
            padding-right: 5px;
        }
        &:nth-child(even) {
            padding-left: 5px;
        }
        @include mquery(950px) {
            width: 100%;
            &:nth-child(odd) {
                padding-right: 0px;
            }
            &:nth-child(even) {
                padding-left: 0px;
            }
        }

        .cp-fg-textarea-container {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 15px;
            background-color: #f3f5f6;
            .cp-fg-tc-title {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                > span {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    font-family: 'latoregular';
                    font-size: 14px;
                    color: #7e7e7e;
                }
            }
            > textarea {
                border-color: #dfe6eb;
                border-style: solid;
                border-width: 1px;
                color: #7e7e7e;
                float: left;
                font-family: "latobold";
                font-size: 14px;
                height: 140px;
                margin: 15px 0 0;
                padding: 10px;
                width: 100%;
                resize: none;
            }
        }
    }
}
.cp-form-group {
    float: left;
    width: 100%;
    min-height: 60px;
    margin: 0px;
    padding: 22px 300px 22px 15px;
    position: relative;
    background-color: #f3f5f6;
    @include mquery(1023px) {
        padding: 22px 235px 22px 15px;
    }
    @include mquery(950px) {
        padding: 22px 365px 22px 15px;
    }
    @include mquery(750px) {
        padding: 22px 265px 22px 15px;
    }
    @include mquery(580px) {
        padding: 15px;
    }
}
.cp-form-group-title {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px 20px 0px 0px;
    font-family: 'latoregular';
    font-size: 14px;
    color: #7e7e7e;
    position: relative;
}
.cp-fg-input-cont {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 100%;
    max-width: 280px;
    min-height: 42px;
    @include mquery(1023px) {
        max-width: 215px;
    }
    @include mquery(950px) {
        max-width: 340px;
    }
    @include mquery(745px) {
        max-width: 240px;
    }
    @include mquery(580px) {
        position: relative;
        top: auto;
        right: auto;
        float: left;
        max-width: 100%;
        margin-top: 10px;
    }
    &.mob-img-input-fix{
        @include mquery(580px) {
            padding-left: 45px;
        }
        .cp-fg-image-preview{
          @include mquery(580px) {
                left: 0;
            }
        }
    }
    > textarea {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        border-style: solid;
        border-color: #dfe6eb;
        border-width: 1px;
        color: #7e7e7e;
        padding: 9px 10px;
        font-family: 'latobold';
        font-size: 14px;
        resize: none;
        // z-index: 1000;
        position: absolute;
        top: 0px;
        right: 0px;
        &:focus {
            height: 112px;
            z-index: 1000;
        }
        @include mquery(580px) {
            position: relative;
            top: auto;
            right: auto;
        }
    }
    > input[type="text"], > input[type="password"] {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        border-style: solid;
        border-color: #dfe6eb;
        border-width: 1px;
        color: #7e7e7e;
        padding: 0px 10px;
        font-family: 'latobold';
        font-size: 14px;
    }
    .cp-fg-select-field {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        @include border(full, 1px, solid, $input-field-border);
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            width: 40px;
            height: 40px;
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            background-image: url(../../images/select-field-arrow.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;
            z-index: 2;
        }
        > select {
            float: left;
            width: 100%;
            height: 40px;
            margin: 0px;
            padding: 0px 10px 1px;
            margin: 0px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            border: 0px;
            position: relative;
            z-index: 3;
            background-color: transparent;
            cursor: pointer;
        }
        @include mquery(580px) {
            margin: 0px;
        }
    }
    .cp-fg-radiobutton-cont {
        float: right;
        padding: 9px 10px;
    }
    .cp-fg-file-field {
        float: left;
        width: 100%;
        height: 42px;
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        @include border(full, 1px, solid, $input-field-border);
        position: relative;
        z-index: 1;
        &:after {
            content: "";
            width: 40px;
            height: 40px;
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            background-image: url(../../images/file-field-icon.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;
            z-index: 2;
        }
        > input {
            float: left;
            width: 100%;
            height: 40px;
            margin: 0px;
            padding: 0px 10px;
            margin: 0px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            border: 0px;
            position: relative;
            z-index: 3;
            background-color: transparent;
            text-indent: -99999px;
            opacity: 0;
            cursor: pointer;
        }
        > span {
            position: absolute;
            top: 12px;
            left: 0px;
            margin: 0px;
            padding: 0px 42px 3px 15px;
            width: 100%;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        @include mquery(580px) {
            margin: 0px;
        }
    } /* sfg file input ends here */
}
.cp-form-helper {
    background-color: #9ec0d1;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    float: right;
    font-family: "latoblack";
    font-size: 12px;
    height: 16px;
    margin: 0;
    padding: 2px 0 0;
    text-align: center;
    width: 16px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.cp-form-helper:hover .cp-form-helper-text {
    opacity: 1;
    visibility: visible;
}
.cp-form-helper-text {
    background-color: #6c99af;
    border-radius: 2px;
    bottom: 24px;
    color: #ffffff;
    font-family: "latolight";
    font-size: 12px;
    margin: 0;
    min-height: 22px;
    min-width: 200px;
    padding: 10px;
    position: absolute;
    right: -8px;
    text-align: center;
    @include transition(.3s);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    line-height: 130%;
    &:before {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #6c99af;
        bottom: -5px;
        content: "";
        height: 0;
        right: 10px;
        position: absolute;
        width: 0;
    }
}
// added by Dijana
.left-aligned-form-helper {
    margin: 0px 10px;
    left: auto;
    right: initial;
}
// added by Dijana
.diff-left-aligned-helper {
    margin: 20px 10px;
    left: auto;
    right: initial;
}
.cp-action-buttons-cont {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    min-height: 80px;
    background-color: #3b739a;
    z-index: 1001;
}

/* Create project who is behind tab */
.cp-who-behind-wrapper {
    float: left;
    width: 100%;
    margin: 25px 0px 0px 0px;
    padding: 20px;
    background-color: #ffffff;
}
.cp-documents-browse-input {
    margin-top: 2px;
}
.cp-editable-input {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.cp-editable-input > input {
    border-color: #dfe6eb;
    border-style: solid;
    border-width: 1px;
    color: #7e7e7e;
    float: left;
    font-family: "latobold";
    font-size: 14px;
    height: 42px;
    margin: 0;
    padding: 0 10px;
    width: 60%;
}
.cp-editable-block > textarea {
    height: 113px !important;
}
