@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
CAPITAL RAISINGS
---------------------------------------------------------- */ 
.capital-raisings-tabs {
    float: left;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    width: 100%;
}
.capital-raisings-tabs > li {
    float: left;
    width: 50%;
    min-height: 40px;
    margin: 0px;
    padding: 0px;
    list-style: none;
    @include mquery(420px) {
        width: 100%;
    }
    @include border(right, 1px, solid, $white-border);
    &:last-of-type {
        border: 0px;
    }
}
.capital-raisings-tabs > li > a {
    float: left;
    width: 100%;
    min-height: 40px;
    margin: 0px;
    padding: 11px 0px 0px;
    font-family: 'latobold';
    font-size: 15px;
    color: $up-tabs-links-color;
    background-color: $up-tabs-bg;
    text-decoration: none;
    text-align: center;
    @include transition(.3s);
    &:hover, &.active {
        background-color: $main-blue-link-hover;
    }
}
.cr-leftcol {
    @include mquery(730px) {
            padding-top: 60px;
    }
    @include mquery(420px) {
        padding-top: 110px;
    }
}

.cr-tabs-cont {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    .cr-tabs-content {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 15px 0px;
        background-color: $main-columns-bg;
        .cr-statistics, .cr-investments, .cr-announcements {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            position: relative;
        }
    } /* Capital raisings tabs content ends here */
} /* Capital raisings tabs container ends here */
.cr-tabs {
    float: left;
    width: 100%;
    min-height: 40px;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    position: relative;
    @include mquery(600px) {
        background-color: $up-tabs-bg;
    }
    > li {
        float: left;
        width: 33.3%;
        min-height: 40px;
        margin: 0px;
        padding: 0px;
        list-style: none;
        @include border(right, 1px, solid, $white-border);
        &:last-of-type {
            border: 0px;
        }
        @include mquery(600px) {
            width: 50%;
            @include border(bottom, 1px, solid, $up-tabs-border);
            &:last-of-type {
                @include border(right, 1px, solid, $up-tabs-border);
            }
        }
        > a {
            float: left;
            width: 100%;
            min-height: 40px;
            margin: 0px;
            padding: 11px 0px 0px 0px;
            text-decoration: none;
            font-family: 'latobold';
            font-size: 15px;
            color: $up-tabs-links-color;
            background-color: $up-tabs-bg;
            text-align: center;
            @include transition(.3s);
            &:hover, &.active {
                background-color: $main-blue-link-hover;
            }
        }
    }
} /* User profile tabs ends here */
.cr-table-container{
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
}
.cr-investments {
    display: none;
}
.cr-announcements {
    display: none;
}