@import 'extensions/variables';
@import 'extensions/mixins';

#ph-top-banner {
  background-image: url(/images/landing-pages/purahealth-leaf-desktop.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: relative;
  height: 800px;
  @include mquery(1440px) {
    height: calc(100vh - 70px);
  }
  @include mquery(1024px) {
    background-image: url(/images/landing-pages/purahealth-leaf-ipad.jpg);
    height: 650px;
  }
  @include mquery(600px) {
   background-image: url(/images/landing-pages/purahealth-leaf-mobile.jpg);
   height: calc(100vh - 70px);
 }

  .ph-middle-tone-title {
    font-size: 46px;
    @include mquery(768px) {
      font-size: 26px;
    }
  }

  .ph-layer-removed {
    height: 100%;
    background-color: rgba(255,255,255,.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ph-logo {
    margin-bottom: 50px;
    @include mquery(768px) {
      width: 250px;
      margin-top: 50px;
    }
  }

  .ph-title-width {
    max-width: 780px;
    margin-right: auto;
    margin-left: auto;
  }

  .ph-layer {
    // width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: rgba(255, 255, 255, .4);
    @include mquery(768px) {
      height: 100%;
      width: 100%;
    }
  }

  .ph-top-banner-cnt {
    width: 660px;
    height: 100%;
    padding: 10% 15px 0 ;
    position: absolute;
    left: 10%;
    background-color: rgba(1,1,1,.4);
    @include mquery(1024px) {
      width: 100%;
      left: 0;
    }
    @include mquery(767px) {
      padding-top: 0;
    }
  }
}

#middle-tone {
  padding-top: 80px;
  padding-bottom: 60px;
  @include mquery(768px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .middle-tone-title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 34px;
    line-height: 2rem;
    @media (max-width: 736px) {
      font-size: 28px;
    }
  }

}

#ph-first-content-cnt {
  .ph-first-content-cnt {
    padding-top: 30px;
  }
  .ph-first-half-banner {
    background: url(/images/landing-pages/purahealth-image-half-min.jpg) no-repeat center;
    background-size: cover;
    height: 100%;
    /*height: 1200px;
    @include mquery(1350px) {
      height: 1350px
    }
    @include mquery(1200px) {
      height: 210px;
      background-position: center;
    }*/
  }

}


.ph-first-half-banner-cnt {
  height: 1064px;
  overflow: hidden;
  padding-left: 0;

  @include mquery(1500px) {
    height: 1220px;
  }
}

#ph-sec-content-cnt {
  .ph-sec-half-banner {
    background-image: url(/images/landing-pages/purehealth-half-banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 660px;
    @include mquery(1504px) {
      height: 760px;
    }
    @include mquery(1272px) {
      height: 760px;
    }
    @include mquery(1200px) {
      height: 210px;
    }
  }

  .ph-sec-content {
    padding-top: 50px;
    @include mquery(1024px) {
      padding-top: 0;
    }
  }
}

.ph-button-style {
  background-color: rgba(1, 1, 1, 0.8);
  transition: all 0.5s ease;
}

.ph-button-style:hover {
  background-color: black;
}

.ph-view-offer-btn {
  border-radius: 4px;
  width: 200px;
  text-align: center;
  background-color: rgba(1, 1, 1, 0.8);
  transition: all 0.5s ease;
  animation: bounce 3s;
  color:#fff;
  padding: 16px;
  &:hover {
    background-color: black;
    color:#fff;
  }
}

#sec-form-sec {
  padding-top: 70px;
  padding-bottom: 70px;
}

.purahealth-list {
  font-size: 1.05rem;
  padding-left: 30px;
  margin: 20px 0;
   >li {
    line-height: 1.7rem;
   }
}
