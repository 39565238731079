@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
LOGIN & REGISTER PAGES
---------------------------------------------------------- */
/* Register page */
.reg-log-page-content {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px 400px 0px 0px;
    position: relative;
    /* Media queries */
    @include mquery(885px) {
        padding: 0px;
    }
    .default-success-tooltip {
        padding: 10px 10px 10px 70px;
    }
}

.reg-log-title {
  margin-top: 40px;
  font-family: 'latoregular';
  font-size: 22px;
  color: $default-text-color;
  text-transform: uppercase;
  text-align: left;
}
.warning-desc {
  line-height: 1.7rem;
  margin-bottom: 20px;
}
.reg-log-subtitle {
    float: left;
    width: 100%;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    font-family: 'latolight';
    font-size: 14px;
    color: $default-text-color;
    line-height: 18px;
    > a {
        margin: 0px;
        padding: 0px;
        color: $default-blue-color;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.reg-log-subtitle a {
    color: $default-text-color;
    text-decoration: underline;
}
/* Register & Login Leftcol */
.reg-log-leftcol {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 20px;
    background-color: $reg-log-col-bg;
    position: relative;
    > div {
        float: left;
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0px;
        position: relative;
        .reg-log-column {
            float: left;
            width: 50%;
            margin: 0px;
            padding: 0px;
            position: relative;
            &:nth-child(1) {
                padding-right: 5px;
            }
            &:nth-child(2) {
                padding-left: 5px;
            }
            /* Media queries */
            @include mquery(1220px) {
                width: 100%;
                &:nth-child(1), &:nth-child(2) {
                    padding: 0px;
                }
            }
        }
    }
} /* Register & Login leftcol ends here */
.forgot-password-form {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 20px !important;
    margin-top: 0px !important;
    opacity: 0;
    visibility: hidden;
    @include transition(.7s);
    > div {
        float: left;
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0px;
    }
    &.fpf-visible {
        opacity: 1;
        visibility: visible;
    }
}
.forgot-pass-link {
    float: left;
    margin: 33px 0px 0px 0px;
    padding: 0px;
    font-family: 'latoregular';
    font-size: 12px;
    color: #9b9c9c;
    text-decoration: none;
}
.forgot-pass-link:hover {
    color: $default-blue-color;
    text-decoration: underline;
}
/* Register & Login Rightcol */
.reg-log-rightcol {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    max-width: 380px;
    margin: 0px;
    padding: 20px;
    background-color: $reg-log-col-bg;
    @include mquery(885px) {
        position: relative;
        float: left;
        top: auto;
        right: auto;
        max-width: 100%;
        margin-top: 30px;
    }
    > div {
        float: left;
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0px;
        position: relative;
        > a {
            float: left;
            width: 100%;
            height: 46px;
            margin: 0px;
            padding: 13px 15px;
            @include transition(.3s);
            font-family: 'latolight';
            font-size: 17px;
            color: $default-submitBtn-color;
            text-decoration: none;
            background-repeat: no-repeat;
            background-position: right 15px center;
            &.fb-login {
                background-color: $fb-color;
                background-image: url('../../images/fb-btn-icon.png');
                &:hover {
                    background-color: lighten($fb-color, 5%);
                }
            }
            &.lin-login {
                background-color: $linkedin-color;
                background-image: url('../../images/linkedin-btn-icon.png');
                &:hover {
                    background-color: lighten($linkedin-color, 5%);
                }
            }
        }
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 15px 0px;
            font-family: 'latoregular';
            font-size: 12px;
            color: #b2b2b2;
            text-align: center;
            &.rc-faq-question {
                font-family: 'latobold';
                font-size: 15px;
                color: $faq-section-text;
                text-align: left;
                padding: 0px;
                margin: 20px 0px 0px 0px;
                line-height: 22px;
                &:first-of-type {
                    margin-top: 0px;
                }
            }
        }
        .rc-faq-answer {
            float: left;
            width: 100%;
            margin: 20px 0px 0px 0px;
            padding: 0px;
            font-family: 'latolight';
            font-size: 14px;
            color: $faq-section-text;
            line-height: 18px;
        }
    }
}
/* ----------------------------------------------------------
LOGIN STEPS
---------------------------------------------------------- */
.login-steps-separator {
    float: left;
    width: 100%;
    text-align: center;
    font-family: 'latolight';
    font-size: 14px;
    color: #7e7e7e;
    margin: 20px 0px 0px 0px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        border-bottom: 1px solid #7e7e7e;
        top: 8px;
        left: 0px;
        width: 45%;
    }
    &:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #7e7e7e;
        top: 8px;
        right: 0px;
        width: 45%;
    }
}
.ls-main-container {
    text-align: center;
}
.login-steps-container {
    display: inline-block;
    max-width: 600px;
    width: 100%;
    margin: 70px 0px 0px 0px;
    padding: 25px;
    background-color: #ffffff;
}
.login-steps-buttons {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
}
.ls-button {
    float: left;
    width: 100%;
    height: 50px;
    margin: 0px 0px 10px 0px;
    position: relative;
    /* Login steps button */
    > a {
        float: left;
        width: 100%;
        height: 50px;
        margin: 0px;
        padding: 22px 10px 15px 60px;
        background-color: #6aa1c8;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        color: #ffffff;
        font-family: 'latobold';
        font-size: 14px;
        @include transition(.3s);
        background-position: 15px 10px;
        background-repeat: no-repeat;
        &:hover {
            background-color: lighten(#6aa1c8, 5%);
        }
        &:before {
            content: "";
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 9px;
            left: 59px;
            font-family: 'latolight';
            font-size: 11px;
            color: #ffffff;
            text-transform: none;
        }
        &.required:before {
            content: "Required";
        }
        &.optional:before {
            content: "Optional";
        }
        &.completed {
            background-color: #f3f5f6;
            color: #a7a7a7;
            background-position: 15px -60px;
            &:before {
                color: #a7a7a7;
            }
            & ~ .ls-button-helper {
                border-color: #ffffff;
                text-indent: -99999px;
                background-image: url(../../images/login-steps-checkmark.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 12px 10px;
                background-color: #aad155;
            }
        }
        &.ls-validate-id {
            background-image: url(../../images/ls-validate-id-icon.png);
        }
        &.ls-investor-check {
            background-image: url(../../images/ls-investor-check.png);
        }
        &.ls-company-trust {
            background-image: url(../../images/ls-company-trust.png);
        }
    }
    /* Login steps button helper */
    .ls-button-helper {
        position: absolute;
        top: 12px;
        right: 15px;
        width: 26px;
        height: 26px;
        border-radius: 16px;
        background-color: #ffffff;
        border-width: 3px;
        border-style: solid;
        border-color: #82b9e0;
        text-align: center;
        font-family: 'latoblack';
        font-size: 16px;
        color: #9a9a99;
        cursor: pointer;
        padding-top: 2px;
    }
}
.ls-button-helper-tooltip {
    position: absolute;
    bottom: 55px;
    right: 12px;
    margin: 0px;
    padding: 5px 15px 7px;
    font-family: 'latolight';
    font-size: 12px;
    color: #ffffff;
    background-color: #484848;
    border-radius: 3px;
    max-width: 300px;
    @include transition(.3s);
    opacity: 0;
    visibility: hidden;
    &:before {
        content: "";
        bottom: -6px;
        right: 10px;

        width: 0px;
        height: 0px;
        margin: 0px;
        padding: 0px;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;

        border-top: 6px solid #484848;
        position: absolute;

    }
}
.ls-button-helper:hover ~ .ls-button-helper-tooltip {
    bottom: 45px;
    opacity: 1;
    visibility: visible;
}
.ls-alternative-buttons {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
}
.ls-alternative-buttons > a {
    float: left;
    width: 100%;
    height: 50px;
    margin: 0px 0px 10px 0px;
    padding: 16px 15px;
    font-family: 'latobold';
    font-size: 14px;
    color: #ffffff;
    background-color: #aad155;
    text-align: right;
    text-transform: uppercase;
    @include transition(.3s);
    background-repeat: no-repeat;
    background-position: 15px center;
    &:hover {
        background-color: lighten(#aad155, 5%);
    }
    &.ls-browse-offers{
        background-image: url(../../images/ls-browse-offer-icon.png);
    }
    &.ls-raise-capital{
        background-image: url(../../images/ls-raise-capital-icon.png);
        background-position: 15px 11px;
    }
}
