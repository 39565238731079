@import 'extensions/variables';
@import 'extensions/mixins';


#dhd-landing-page {

    .dhd-banner {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: calc(100vh - 70px);
    }


    .dhd-content-cnt {
      width: 100%;
      position: absolute;
      top: 90px;
      left: 0px;

      .dhd-logo {
        display: block;
        margin: 50px auto;
      }
    }

      .carousel-fade .carousel-inner .item {
        opacity: 0;
        transition-property: opacity;
      }

      .carousel-fade .carousel-inner .active {
        opacity: 1;
      }

      .carousel-fade .carousel-inner .active.left,
      .carousel-fade .carousel-inner .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
      }

      .carousel-fade .carousel-inner .next.left,
      .carousel-fade .carousel-inner .prev.right {
        opacity: 1;
      }

      .carousel-fade .carousel-control {
        z-index: 2;
      }


      @media all and (transform-3d), (-webkit-transform-3d) {
          .carousel-fade .carousel-inner > .item.next,
          .carousel-fade .carousel-inner > .item.active.right {
            opacity: 0;
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
          }
          .carousel-fade .carousel-inner > .item.prev,
          .carousel-fade .carousel-inner > .item.active.left {
            opacity: 0;
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
          }
          .carousel-fade .carousel-inner > .item.next.left,
          .carousel-fade .carousel-inner > .item.prev.right,
          .carousel-fade .carousel-inner > .item.active {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
          }
      }



      .one {
        background-image: url(/images/landing-pages/dhd-top-banner-1.jpg);
      }

      .two {
       background-image: url(/images/landing-pages/dhd-top-banner-4.jpg);
      }

      .tree {
        background-image: url(/images/landing-pages/dhd-top-banner-3.jpg);
      }

      .dhd-carousel-control {
       width: 5%;
       z-index: 10;
      }



  }

  .dhd-content-section {
    display: flex;

    .dhd-half-banner {
      width: 100%;
    }



    .dhd-half-content {
      padding-top: 80px;
      padding-bottom: 80px;
      flex: 1;
      order: 1;
      @include mquery(1024px) {
        padding-top: 0;
      }
    }

  }

  .dhd-video-row {
    width: 100%;
    display: table;
  }


  .video {
    max-width: 100%;
    height: auto;
  }

  .collage-img {
    margin-top: 180px;
  }

  .video-width-wrapper {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
  }

  .iframe-width {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .dhd-button-style {
    background-color: rgba(1, 1, 1, .8);
  }

  .second-table {
    padding: 80px 80px 20px 80px;
    height: 400px;
  }

  .dhd-input-border-black {
    border: 1px solid rgba(1,1,1,.2) !important;
  }

  .dhd-p-width {
    max-width: 780px;
    margin:30px auto;
    @include mquery(1200px) {
    margin: auto;
  }

  }

  .dhd-pro-img {
    background-image: url(/images/landing-pages/dhd-Modom-Products-04.jpg);
    // background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 790px;
    margin-left: -15px;
    margin-right: -15px;
    @include mquery(1024px) {
      height: 530px;
    }
    @include mquery(767px) {
      height: 230px;
    }

  }

  @include mquery(1200px) {
    .second-table {
      padding: 30px 20px 20px 20px;
    }

  }

 @include mquery(1024px) {
  .dhd-content-section {
    display: flex;
    flex-direction: column;

    .dhd-first-col {
      flex-basis: 100%;
    width: 100%;
    order: 0;
    }

    .dhd-sec-col {
      flex-basis: 100%;
    width: 100%;
    order: 1;

    }
  }

  .collage-img {
    margin-top: 20px;
  }

  .dhd-video-row {
    display: flex;
    flex-direction: column;
  }

  .second-table {
    flex-basis: 100%;
    width: 100%;
    order: 1;
    padding: 80px 20px;
    height: 100%;
  }

  .video {
    flex-basis: 100%;
    width: 100%;
    order: 0;
  }
}

.dhd-event-header {
  padding: 70px 25px;
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}
