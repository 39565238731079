@import 'extensions/variables';
@import 'extensions/mixins';


#douugh-hero {
  .douugh-hero-bg {
    background: black;
    background-size: cover;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 130px;
    background-position: right bottom;
    @include mquery(1440px) {
      padding-top: 50px;
      padding-bottom: 0;
    }
    @include mquery(768px) {
      background-position: left;
    }
  }

  .douugh-logo {
    width: 200px;
    margin-bottom: 20px;
    @include mquery(500px) {
      width: 170px;
    }
  }


  .douugh-hero-title {
    font-family: 'latoregular';
    font-size: 36px;
    margin-bottom: 10px;
    line-height: 2.7rem;
    @include mquery(500px) {
      font-size: 30px;
    }
  }

  .douugh-hero-subtitle {
    font-family: 'latolight';
    font-size: 26px;
    margin-bottom: 34px;
    line-height: 2.7rem;
    @include mquery(1440px) {
      margin-bottom: 14px;
    }
    @include mquery(500px) {
      font-size: 19px;
    }
  }

  .douugh-hero-subtitle-register {
    font-family: 'latoregular';
    font-size: 22px;
    margin-bottom: 34px;
    line-height: 2.7rem;
    @include mquery(768px) {
      font-size: 20px;
      line-height: 2rem;
    }
  }

    .douugh-hero-image-cnt {
      @include mquery(1024px) {
        padding-top: 20px;
      }
      @include mquery(500px) {
        padding-top: 0;
      }
    }
    .douugh-hero-image {
      background: url(/images/landing-pages/douugh/hero.png) no-repeat center;
      background-size: contain;
      height: 550px;
      @include mquery(500px) {
        height: 250px;
      }
    }
}

.douugh-gradient-bk {
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
}

.douugh-mastercard-logo-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  .mastercard-logo {
    opacity: 0.5;
    width: 100px;
    margin-bottom: 20px;
  }
  .chart-logo {
    opacity: 0.5;
    width: 80px;
    margin-bottom: 20px;
  }
}

.douugh-button-style {
  background: linear-gradient(160.34deg, #FFC303 0.05%, #FE4D1F 100%, #FE4D1F 100%);
  border: none;
  &:hover {
    background: #FF8800;
  }
}

.douugh-link {
  color: #FF8800;
  &:hover {
    color: #FE4D1F;
  }
}

.douugh-grey-color {
  color: #707070;
}


#quote-sec {
  padding: 100px 10px 20px;
  @include mquery(768px) {
    padding: 50px 10px 50px;
  }
  p {
    font-size: 36px;
    color: #707070;
    @include mquery(768px) {
      font-size: 24px;
    }
  }
}



#douugh-iphone-sec {
  //background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  background: black;
  //opacity: 0.9;
  //padding: 100px 0;
  @include mquery(768px) {
    padding: 50px 0;
  }
  .douugh-iphone-cnt {
    @include mquery(1024px) {
      padding-top: 10px;
    }
    @include mquery(768px) {
      order: 1;
    }
  }

  .douugh-iphone-slider {
    width: 324px;
    background: url(/images/landing-pages/douugh-iPhoneX-clay-light3_2x-min.png) no-repeat center;
    background-size: contain;
    margin: auto;
    padding: 78px 21px 103px 21px;
    @include mquery(1440px) {
      width: 264px;
    }
    @include mquery(500px) {
      width: 224px;
    }
  }

  .douugh-partnership-header {
    font-size: 26px;
    line-height: 1.33;
    height: 68px;
    @include mquery(768px) {
      height: 100%;
    }
  }
  .douugh-partnership-subheader {
    font-size: 18px;
    height: 162px;
    @include mquery(768px) {
      height: 100%;
    }
  }

  .douugh-align-center {
    display: flex;
    align-items: center;
    @include mquery(768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}


#douugh-first-info-sec {
  //padding: 100px 0 100px;
  padding: 30px 0 100px 0;
  @include mquery(768px) {
    padding: 0 0 50px;
  }
}


  .douugh-landing-page-content-header {
    width: 100%;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }


#douugh-third-info-sec {
  padding-top: 100px;
  padding-bottom: 100px;
  // background: linear-gradient(45deg, rgba(255,196,4,1) 0%, rgba(255,95,26,1) 100%);
  @include mquery(768px) {
    padding: 50px 0;
  }
}


#douugh-paralax-sec {
  background-attachment: fixed;
    background-image: url(/images/landing-pages/douugh-notifications-background-03-min.jpg);
    width: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -10;
    position: relative;

    @include mquery(768px) {
      background-image: url(/images/landing-pages/douugh-notifications-background-mobile-min.jpg);
    }

    @include mquery(500px) {
      background-attachment: inherit;
    }

    .douugh-paralax-layer {
      height: 100%;
      background-color: rgba(1, 1, 1, 0.5);
      padding: 100px 50px;
      width: 760px;
      @include mquery(1024px) {
        width: 100%;
        padding: 100px 10px;
      }
      @include mquery(768px) {
        padding: 50px 0;
      }
    }
}


.douugh-be-part-of-image {
  background: url(/images/landing-pages/douugh-phone-motion-min.png) no-repeat center;
  background-size:contain;
  height: 440px;
  @include mquery(500px) {
    height: 240px;
  }
}

.douugh-be-part-of-content {
  padding-top: 110px;
  padding-bottom: 0;
  @include mquery(1024px) {
    padding-bottom: 110px;
  }
  @include mquery(768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#douugh-last-form-sec {
  padding: 50px 0;
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
  @include mquery(768px) {
    padding: 50px 0;
  }
}

.douugh-be-part-of-title {
  width: 100%;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 20px;
  @include mquery(768px) {
    font-size: 28px;
  }
}

#douugh-video-sec {
  position: relative;
  width: 100%;
  padding-top: 28.25%;
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
  @include mquery(1024px) {
    padding-top: 56.25%;
  }
  .douugh-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
