@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';


#rhinohide-top-banner {
  @include top-banner('/images/landing-pages/banners/rhinohide-top-banner.jpg', #000, '/images/landing-pages/banners/rhinohide-top-banner-mobile.jpg');
	@extend %background-property;
  #rhinohide-top-banner-layer {
    @include top-banner-layer(rgba(0,0,0,.6));
    @extend %flex-center;
    .rhinohide-logo {
      @include logo-style(40px, 400px, 250px);
    }
  }
}

.rhinohide-view-offer-btn {
  border-radius: 4px;
  height: 52px;
  width: 250px;
  background-color: #af3e19;
  border: 1px solid #af3e19;
  color: #fff;
  font-size: 20px;
  /* transition: all 0.5s ease;
  animation: bounce 1.5s; */
}


#rhinohide-paralax-sec {
  @include parallax-sec('/images/landing-pages/banners/rhinohide-parallax-banner.jpg', '/images/landing-pages/banners/rhinohide-parallax-banner-mobile.jpg');
  .rhinohide-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.7));
  }
}

#rhinohide-last-form-sec {
    display: flex;
    flex-direction: row;
    @include mquery(1024px) {
      flex-wrap: wrap;
    }
  .rhinohide-last-form-sec-1 {
    @include lastFormSecOneImg(#000, '/images/landing-pages/banners/rhinohide-exploring-WA-coast-563x350.jpg');
    @extend %background-property;
  }

  .rhinohide-last-form-sec-2 {
    @extend %flex-center;
    height: 400px;
    width: 32%;
    padding: 10px;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
    }
  }
  .rhinohide-last-form-sec-3 {
    @include lastFormSecThreeImg(#000, '/images/landing-pages/banners/rhinohide-bottom-right-banner.jpg');
    @extend %background-property;
  }
}

#rhinohide-intro-sec {
  padding: 60px 0;
  .rhinohide-intro-text {
    padding: 0 20px;
  }
}

#rhinohide-story-sec {
  padding: 60px 0;
  @include mquery(1200px) {
    padding: 0;
  }
  .rhinohide-story-text {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 60px 0 0;
    }
  }
  .rhinohide-story-text-2 {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 0 0 60px;
    }
  }

  .rhinohide-video-bk {
  	@extend %background-property;
    background-image: url(/images/landing-pages/banners/rhinohide-Screen-Shot-2018-02-01-at-3.09.29-pm-535x344.png);
    height: 440px;
    @extend %flex-center;
    @include mquery(768px) {
      height: 210px;
    }
    .rhinohide-video-title {
      font-size: 24px;
      margin-bottom: 20px;
      font-family: latoregular;
    }
    .rhinohide-video-btn {
      border: 1px solid $default-white-color;
      color: $default-white-color;
      padding: 10px 20px;
      transition: .3s;
      width: 120px;
      text-align: center;
      &:hover {
        background-color: #af3e19;
        border: 1px solid #af3e19;
      }
    }
    .rhinohide-video-info-cnt {
      z-index: 1
    }
  }

  .rhinohide-video-bk::after {
    background: rgba(0,0,0,.45);
    bottom: 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    width: 100%;
  }

  .rhinohide-story-bk {
    @extend %background-property;
    background-image: url(/images/landing-pages/banners/rhinohide-bottom-right-banner-2.jpg);
    height: 160px;
    @extend %flex-center;
  }
}

#rhinohide-market-sec {
  padding: 60px 0;
  @include mquery(1200px) {
    padding: 0 0 60px;
  }
  .rhinohide-market-bk {
    @extend %background-property;
    background-image: url(/images/landing-pages/banners/rhinohide-RHD00181_SEMA_Digital_Showcase_3.jpg);
    height: 470px;
    @extend %flex-center;
    @include mquery(768px) {
      height: 210px;
    }
  }
  .rhinohide-market-text {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 60px 0 0;
    }
  }
}

.rhinohide-link {
  @include link(#af3e19, darken(#af3e19, 2.5), underline);
}

.subscription_link_color {
  @include subscription_link_color(#af3e19, darken(#af3e19, 2.5), underline)
}

.rhinohide-button-style {
  @include button-style(#af3e19, #fff, #af3e19, #fff, .8);
}
