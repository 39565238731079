@import 'extensions/variables';
@import 'extensions/mixins';





#careers-top-banner {
  background-image: url(/images/banners/career-banner-2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
  display: table;
  @include mquery(1440px) {
    height: calc(100vh - 70px);
  }

  @include mquery(768px) {
    background-image: url(/images/banners/career-banner-2-sm.jpg);
    height: 50%;
  }

  .careers-top-banner-title {
    font-size: 48px;
    font-family: latoregular;
    margin-bottom: 22px;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    @include mquery(768px) {
      font-size: 38px;
    }
    @include mquery(600px) {
      font-size: 32px;
    }
  }
  .careers-top-banner-subtitle {
    font-size: 24px;
    font-family: latolight;
    max-width: 780px;
    margin-bottom: 40px;
    line-height: 30px;
    text-align: center;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    @include mquery(768px) {
      font-size: 18px;
    }
  }


  .career-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,.5);
    display: flex;
    align-items: center;
  }


  .scroll-hide-career {
    @media only screen and (min-width: 1440px) {
      display: none;
    }
  }


}

.top-snippet {
  padding-top: 90px;
  padding-bottom: 90px;
  @include mquery(1280px) {
    padding-bottom: 0;
  }
  @include mquery(768px) {
    padding-top: 40px;
  }
}

.snippet-header {
  font-size: 20px;
}

.snippet-width {
  max-width: 500px;
  @include mquery(768px) {
    max-width: 100%;
  }
}

.snippet-content {
  line-height: 1.7rem;
}

.open-position-content {
  max-width: 40em;
  line-height: 1.7rem;
  margin: auto;
  text-align: center;
}


.position-cnt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include mquery(1200px) {
    justify-content: center;
  }

  .position-tile {
    width: 30%;
    height: 200px;
    border: 1px solid rgba(0,0,0,.3);
    padding: 40px;
    margin-right: 56px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #333;
    cursor: pointer;
    @include mquery(1024px) {
      margin-right: 30px;
      margin-bottom: 0;
      width: 45%;
    }
    @include mquery(768px) {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
    }
    .position-name {
      font-size: 18px;
      font-family: latoregular;
      margin-bottom: 5px;
    }
    .position-city {
      font-size: 14px;
    }
  }

  .position-tile:last-child {
  margin-right: 0;
  }



  .position-tile:hover {
    box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.3);
  }
}


.perks-cnt {
   display: flex;
   overflow-x: scroll;
   overflow-y: hidden;

  }


  .perks-cnt::-webkit-scrollbar {
   -webkit-appearance: none;
   width: 10px;
 }

 .perks-cnt::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}


 .perks-cnt::-webkit-scrollbar-thumb {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
   border-radius: 10px;
 }


 .circular-quay-sec {

   background-image: url(/images/circular-quay-min.jpg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   width: 100%;
   height: 390px;

 }


 .snippet-title {
   font-size: 40px;
   font-family: latoregular;
   margin-bottom: 50px;

 }

 .sydney-img-title {
   font-size: 40px;
   @include mquery(768px) {
     font-size: 30px;
   }
 }

 .tabs-list {
   margin-left: 20px;
   border-bottom: 1px solid #ddd;
   display: inline-flex;
   @include mquery(560px) {
      margin-left: 0;
      margin-top: 20px;
   }
 }


   .tab-cnt {
     display: flex;
     align-items: flex-end;
     @include mquery(560px) {
       align-items: flex-start;
       flex-direction: column;

     }
   }

   .nav-tab-link {
     color: #fff;
     padding: 10px 15px;
     display: block;
   }

   .nav-tab-link:hover  {
     background-color: rgba(255, 255, 255, 0.5);
     color: #fff;
     text-decoration: none;
   }



   .banner-layer {
     width: 100%;
     height: 100%;
     background-color: rgba(1, 1, 1, 0.6);
     position: relative;
     display: flex;
     align-items: center;

     }


 .snippet-subtitle {
   font-size: 24px;
   line-height: 35px;
   margin-bottom: 80px;
 }

 .btn-ctn {
   padding: 15px 21px;

 }
 .view-job-btn {
    display: inline-block;
    vertical-align: middle;
    padding: 15px 10px;
    max-width: 160px;
    color: white;
    border: 1px solid rgba(255,255,255,.5);
    background-color: rgba(255,255,255,.5);
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
 }

  .view-job-btn:hover {
    border: 1px solid #fff;
    color: #fff;
  }


  .career-info {
    padding-top: 90px;
    padding-bottom: 100px;

    @include mquery(1024px) {
      padding-top: 0;
    }
    .career-title {
      font-size: 36px;
      font-family: latoregular;
      margin-bottom: 10px;
      margin-top: 60px;
    }

    .career-subtitle {
      font-size: 20px;
      font-family: latoregular;
      margin-bottom: 70px;
      margin-top: 90px;
      line-height: 1.7rem;
    }

    .about-us-text-first {
      line-height: 1.7rem;
      margin-top: 80px;
      font-size: 18px;
    }

    .about-us-text {
      line-height: 1.7rem;
      margin-top: 30px;
      font-size: 18px;
    }

    .responsibility-list-cnt {
      list-style-type: disc;
      .responsibility-list {
        margin-top: 10px;
        font-size: 18px;
        line-height: 1.6rem;
      }

    }
  }

  .form-label {
    color: rgba(0,0,0,.54);
    font-family: latoregular;
    margin-bottom: 10px;
  }

  .career-form-group {
    margin-top: 20px;
    max-width: 500px;
  }

  .career-submit-btn {
    width: 200px;
    height: 55px;
    background-color: #469edc;
    border: 1px solid #469edc;
    padding: 20px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-family: latoregular;
    margin-top: 40px;
  }

  .position-sketch {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
