@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
HOW TO INVEST
---------------------------------------------------------- */

//////////////////////////////////////////
// How-to-invest css by Mina////////////
/////////////////////////////////////////



#mn-how-to-invest-top-banner {
  background-image: url(../../images/how-to-invest-header-image.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: table;
  overflow: hidden;

    @include mquery(768px) {
      height: 50%;
      padding: 50px 0;
    }


  .mn-hti-top-banner-content-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mn-how-to-invest-title   {
      font-size: 48px;
      font-family: latoregular;
      margin-bottom: 22px;
      text-align: center;
      padding-right: 15px;
      padding-left: 15px;
      @include mquery(768px) {
        font-size: 38px;
      }
      @include mquery(600px) {
        font-size: 32px;
      }
    }

   }
   @include mquery(736px) {
      background-image: url(../../images/hti-bg-image-min.jpg);

   }

  }


    .mn-hti-gstart-title  {
      font-family: latoregular;
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      color: #469edc;
      margin-bottom: 34px;
   }
   .mn-hti-gstart-subtitle  {
     font-size: 24px;
     font-weight: 300;
     text-align: center;
     margin-bottom: 75px;
   }
   .mn-hti-flex-container {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
     .mn-hti-tile {
       border-radius: 10px;
       padding: 30px 31px;
       width: 450px;
       height: 274px;
       margin-right: 10px;
       @include respond-to(medium-screens) {
          margin-bottom: 30px;
          margin-right: 16px;

       }
       @include mquery(736px) {
          height: 100%;
          margin-bottom: 20px;
          margin-right: 0;
          padding-right: 15px;
          padding-left: 15px;
       }

       .mn-hti-gstart-sm-title {
         font-size: 24px;
         font-weight: 500;
         text-align: center;
         margin-bottom: 26px;
       }
       .mn-hti-gstart-sm-subtitle {
         font-size: 16px;
         font-weight: 300;
         text-align: center;
         line-height: 24px;
       }

     }
     .mn-blue-border {
       border: solid 2px #469edc;
       margin-top: 10px;

     }
     .mn-pink-border {
       border: solid 2px #de518e;
       margin-top: 10px;

     }
     .mn-green-border {
       border: solid 2px #68cfad;
       margin-top: 10px;

     }
     .mn-blue-title {
       color: #469edc;
     }
     .mn-pink-title {
       color: #de518e
     }
     .mn-green-title {
       color: #68cfad;
     }
   }


  .mn-hti-access-offers-container {
    background-image: url(../../images/hti-access-offer-min.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60%;
    margin: 0px;
    padding: 0px;
    position: relative;
    top: 0px;
    left: 0px;
    display: table;
    z-index: -1;
    .mn-hti-access-offers-title-container {
      padding-top: 109px;
      .mn-hti-access-offers-title {
        font-family: latoregular;
        font-size: 40px;
        text-align: center;
        color: #fff;
        margin-bottom: 33px;
      }
      .mn-hti-access-offers-subtitle{
        font-size: 24px;
        text-align: center;
        color: #fff;
        margin-bottom: 89px;
        max-width: 756px;
        margin-right: auto;
        margin-left: auto;
      }

    }

  }


  .mn-view-deals-container {
    padding-top: 114px;
    padding-bottom: 109px;
    .mn-view-deals-title {
      font-family: Latoregular;
      font-size: 40px;
      text-align: center;
      color: #333;
      margin-bottom: 50px;
    }
  }

  .mn-customer-story-background {
    background-image: url(../../images/customer-story-bg-min.jpg);
    width: 100%;
    height: 602px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding-top: 164px;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    color: #fff;
    line-height: 10px;
    .mn-custome-story-container {
      max-width: 441px;
      .mn-customer-story-quote {
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 29px;
        @include mquery(500px) {
           text-align: center;

        }
      }
    }
    .mn-hti-ceo-name {
      @include mquery(500px) {
        text-align: center;

      }
    }
    .mn-customer-story-speech-bubble {
      margin-bottom: 25px;
      @include mquery(600px) {
         text-align: center;

      }
      .mn-speech-bubble-img {
        margin-right: 11px;
      }
    }
  }

.mn-hti-single-tile {
  border: 1px solid #e1e1e1;
  max-width: 738px;
  padding: 20px;
  margin: auto;
  @include mquery(1200px) {
     max-width: 357px;
  }

}


.mn-hti-list-container {
  margin-left: 20px;
  @include mquery(400px) {
    margin-left: 0;
  }
  .mn-hti-list {
    font-size: 16px;
    line-height: 70px;
    @include mquery(400px) {
      font-size: 15px;
    }
  }
}



.mn-hti-free-container {
  margin-top: 80px;
  @include mquery(1200px) {
    margin-top: 0;

  }
}
.mn-check-icon {
  margin-right: 19px;
}

.mn-hti-all-delas-container {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
}

.mn-glyphicon-left {
  margin-left: 10px !important;
}

.mn-glyphicon-right {
  margin-right: 10px !important;
}
