/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#plastiq-story-sec .plastiq-video-bk, #plastiq-story-sec .plastiq-story-bk, #plastiq-market-sec .plastiq-market-bk {
  display: flex;
  align-items: center;
  justify-content: center;
}

#plastiq-last-form-sec .plastiq-last-form-sec-1, #plastiq-last-form-sec .plastiq-last-form-sec-3, #plastiq-story-sec .plastiq-video-bk, #plastiq-story-sec .plastiq-story-bk, #plastiq-market-sec .plastiq-market-bk {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.plastiq-view-offer-btn {
  border-radius: 4px;
  height: 52px;
  width: 250px;
  background-color: #af3e19;
  border: 1px solid #af3e19;
  color: #fff;
  font-size: 20px;
  /* transition: all 0.5s ease;
  animation: bounce 1.5s; */
}

#plastiq-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 600px) {
  #plastiq-paralax-sec {
    background-image: url("/images/landing-pages/");
  }
}

#plastiq-paralax-sec .plastiq-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}

#plastiq-last-form-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #plastiq-last-form-sec {
    flex-wrap: wrap;
  }
}

#plastiq-last-form-sec .plastiq-last-form-sec-1 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/plastiq/4.jpg");
}

@media all and (max-width: 1024px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-1 {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-1 {
    height: 180px;
  }
}

#plastiq-last-form-sec .plastiq-last-form-sec-2 {
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 400px;
  width: 32%;
  padding: 20px 10px 10px 10px;
}

@media all and (max-width: 1420px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-2 {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-2 {
    width: 100%;
    order: 3;
  }
}

#plastiq-last-form-sec .plastiq-last-form-sec-3 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/plastiq/3.jpg");
}

@media all and (max-width: 1024px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-3 {
    width: 50%;
    order: 1;
  }
}

@media all and (max-width: 500px) {
  #plastiq-last-form-sec .plastiq-last-form-sec-3 {
    height: 180px;
  }
}

#plastiq-intro-sec {
  padding: 60px 0;
}

#plastiq-intro-sec .plastiq-intro-text {
  padding: 0 20px;
}

#plastiq-story-sec {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #plastiq-story-sec {
    padding: 0;
  }
}

#plastiq-story-sec .plastiq-story-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #plastiq-story-sec .plastiq-story-text {
    padding: 60px 0 0;
  }
}

#plastiq-story-sec .plastiq-story-text-2 {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #plastiq-story-sec .plastiq-story-text-2 {
    padding: 0 0 60px;
  }
}

#plastiq-story-sec .plastiq-video-bk {
  background-image: url(/images/landing-pages/plastiq/);
  height: 440px;
}

@media all and (max-width: 768px) {
  #plastiq-story-sec .plastiq-video-bk {
    height: 210px;
  }
}

#plastiq-story-sec .plastiq-video-bk .plastiq-video-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: latoregular;
}

#plastiq-story-sec .plastiq-video-bk .plastiq-video-btn {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  transition: .3s;
  width: 120px;
  text-align: center;
}

#plastiq-story-sec .plastiq-video-bk .plastiq-video-btn:hover {
  background-color: #af3e19;
  border: 1px solid #af3e19;
}

#plastiq-story-sec .plastiq-video-bk .plastiq-video-info-cnt {
  z-index: 1;
}

#plastiq-story-sec .plastiq-video-bk::after {
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  width: 100%;
}

#plastiq-story-sec .plastiq-story-bk {
  background-image: url(/images/landing-pages/banners/plastiq-bottom-right-banner-2.jpg);
  height: 160px;
}

#plastiq-market-sec {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #plastiq-market-sec {
    padding: 0 0 60px;
  }
}

#plastiq-market-sec .plastiq-market-bk {
  background-image: url(/images/landing-pages/banners/plastiq-RHD00181_SEMA_Digital_Showcase_3.jpg);
  height: 470px;
}

@media all and (max-width: 768px) {
  #plastiq-market-sec .plastiq-market-bk {
    height: 210px;
  }
}

#plastiq-market-sec .plastiq-market-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #plastiq-market-sec .plastiq-market-text {
    padding: 60px 0 0;
  }
}

.plastiq-link {
  color: #af3e19;
}

.plastiq-link:hover {
  color: #a43a17;
  text-decoration: underline;
}

.subscription_link_color {
  color: #af3e19;
}

.subscription_link_color:hover {
  color: #a43a17;
  text-decoration: underline;
}

.plastiq-button-style {
  background-color: #af3e19;
  color: #fff;
  transition: all 300ms ease;
}

.plastiq-button-style:hover {
  background-color: #af3e19;
  color: #fff;
  opacity: 0.8;
}
