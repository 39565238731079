@import 'extensions/variables';
@import 'extensions/mixins';



#wse-top-banner {
  background: url(/images/landing-pages/wse-top-banner.jpg) no-repeat center center;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  display: table;

  .wse-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    background-color: rgba(1,1,1,.5);

    @include mquery(1024px) {
      width: 100%;
    }
  }
}



  .wse-button-style {
    background-color: #3754bf;
    border: 1px solid #3754bf;
    color: #fff;
    &:hover {
      opacity: .5;
    }
  }



  .wse-link {
    color: #50d253;
    &:hover {
      color:#d88713;
    }
  }

.lp-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .lp-flex-col {
    width: 50%;
    @include mquery(1200px) {
      width: 100%;
    }
  }
}

.wse-first-half-content {
  padding: 60px;
  @include mquery(1500px) {
    padding: 20px 60px;
  }

  @include mquery(1250px) {
    padding: 20px 40px;
  }
  @include mquery(768px) {
    padding: 25px;
  }

}



.wse-first-half-img {
  background: url(/images/landing-pages/wse-second-banner.jpg) no-repeat center center;
  background-size: cover;
  height: 731px;

  @include mquery(1200px) {
    width: 100%;
    display: none;
  }

}

.wse-section-three {
  padding: 20px 0;

  @include mquery(1280px) {
    padding: 0;
  }

  @include mquery(1200px) {
    height: auto;
    padding: 50px 0 0;
  }

  @include mquery(768px) {
    height: auto;
    padding: 0;
  }
}


#wse-full-height-img {
  background: url(/images/landing-pages/wse-full-height-img.jpg) no-repeat center center;
  background-size: cover;
  height: calc(100vh);
}


.wse-second-half-img {
  background: url(/images/landing-pages/wse-third-banner.jpg) no-repeat center center;
  background-size: contain;
  height: 481px;
  @include mquery(768px) {
    background-size: contain;
    height: 511px;
  }
  @include mquery(600px) {
    background-size: contain;
    height: 251px;
  }
}

.wse-second-half-content {
  padding: 60px;
  @include mquery(1350px) {
    padding: 50px;
  }
  @include mquery(768px) {
    padding: 25px;
  }
}

.wse-bluegrey-back {
  background-color: #6a7c88;
}

#wse-paralax-sec{
  background-attachment: fixed;
  background-image: url(/images/landing-pages/wse-paralex-img.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;

  .wse-paralax-layer {
    height: 100%;
    background-color: rgba(1, 1, 1, 0.7);
    padding-top: 50px;
    padding-bottom: 50px;
  }
}


#wse-last-table-sec {
  background-color: #fff;
  padding: 50px 0;
}


.wse-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #3754bf;
  border: 1px solid #3754bf;
  color: #fff;
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.wse-view-offer-btn:hover {
  opacity: .5;
}
