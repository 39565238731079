@import 'extensions/variables';
@import 'extensions/mixins';



#mn-hashching-tp-banner {
    background-image: url(/images/landing-pages/hashching-bk-img-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ccc;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);
    display: table;
    @include mquery(736px){
     background-image: url(/images/landing-pages/hashching-mobile.jpg);
    }
    .hashching-layer {
      background-color: rgba(23,50,77,0.8);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

      .mn-max-width {
        max-width: 470px;
        display: block;
        margin: auto;
      }
      .mn-hashching-logo {
        width: 430px;
        margin-bottom: 80px;
        @include mquery(736px){
          margin-bottom: 30px;
        }
      }

}

.hashching-BlueGreen {
  color: #157B98;
}

.hashching-BabyBlue {
  color: #0AB5DD;
}

.hashching-BabyBlue:hover {
  color: #747474;
}


.hashching-RoyalBlue {
  color: #1F3F5F;
}



.hashching-RoyalBlue-bk {
  background-color: #1F3F5F;
}
.padding-top-150 {
  padding-top: 150px;
  @include mquery(736px) {
    padding-top: 50px;
  }

}
#mn-hashching-blue-bk {
  background-color: #0ab5dd;
  position: relative;
  @include mquery(1200px) {
    background-image: none;
    background-color: #0AB5DD;
    padding-bottom: 60px;
  }
}

.mn-hashching-content-cnt {
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 90px;

  @include mquery(1024px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}



.mn-socila-proof {
  padding: 60px 0;
  left: 0;
  min-height: 170px;

  .mn-brand-cnt {
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      @include mquery(1200px) {
        display: block;
      }
    }

    .brands-img {
      width: 200px;
      margin-bottom: 20px;
    }
    .brands-img-bigger {
      width: 300px;
      margin-bottom: 20px;
    }
    .brands-img-small {
      width: 146px;
      margin-bottom: 20px;
    }
    .center-block{
      display: table;
      margin: auto;
      margin-bottom: 10px;
    }
  }
}


.hashching-broker-view-offer-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ff5b09;
  padding: 15px 30px;
  color: white;
  border-radius: 4px;
  // transform: translate(-50%, -50%);
  margin-left: -70px;
  width: 140px;
  @include mquery(736px) {
   position: inherit;
   display: block;
   margin: auto;
  }
}

.hashching-btn-bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

.hashching-broker-live-text {
  position: absolute;
  top: 30%;
  left: 50%;
  text-align: center;
  margin-left: -260px;
  max-width: 500px;
  font-size: 50px;
  font-family: latoregular;
}

.hashching-down-form  {
  margin-top: 90px;
  margin-bottom: 90px;
  height: 270px;
  @include mquery(1024px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

}


.hashching-down-register {
  width: 100%;
  background-color: white;
  border-color: white;
  color: grey;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
}



.hashching-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  .hashching-name {
    padding: 10px;
    border-radius: 4px;
    margin-right: 20px;
    font-weight: 400;
    height: 50px;
    border: none;
    margin-bottom: 10px;
    width: 192px;
    border: 1px solid #e8e8e8;
    @include mquery(736px) {
      width: 105px;
    }
    @include mquery(320px) {
      width: 193px;
      margin-right: 0;
    }
  }
  .hashching-email {
    padding: 10px;
    border-radius: 4px;
    /* margin-right: 20px; */
    font-weight: 400;
    height: 50px;
    border: none;
    margin-bottom: 10px;
    width: 192px;
    border: 1px solid #e8e8e8;


  }
}

  .hashching-btn {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
    height: 49px;  @include mquery(736px) {
        width: 324px;
      }
      @include mquery(320px) {
        width: 193px;
      }
  }
  .hashching-view-offer-btn {
    width: 200px;
    animation: bounce 3s;
  }

  .hashching-button-style {

    background-color: #157B98;
    border: none;
    color: white;

  }

  .hashching-button-style:hover {
    background-color: darken(#157B98, 10);
  }
