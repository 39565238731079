@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';


#firstcheck-top-banner {
    @include top-banner('/images/landing-pages/banners/Firstcheck-home image-menu-screen.jpg', #fff, '/images/landing-pages/banners/Firstcheck-home image-menu-screen-small.jpg');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    #firstcheck-top-banner-layer {
      @include top-banner-layer(rgba(0,0,0,.6));
      @extend %flex-center;
      height: calc(100vh - 70px);
      .firstcheck-logo {
        @include logo-style(40px, 350px, 250px);
      }
      .firstcheck-title-width {
        max-width: 600px;
        margin: 0 auto 40px;
      }
    }
  }


#firstcheck-story-sec-one {
    padding: 60px 0;
    @include mquery(1200px) {
      padding: 0 0 60px;
    }
    .firstcheck-story-sec-one-text {
      padding: 0 20px;
      @include mquery(1200px) {
        padding: 60px 0 0;
      }
    }
    .firstcheck-story-video-bk {
        @extend %background-property;
        background-image: url(/images/landing-pages/banners/firstcheck-story-bk.jpg);
        height: 440px;
        @extend %flex-center;
        @include mquery(1200px) {
            margin-top: 60px;
          }
        @include mquery(768px) {
          height: 210px;
      }
      .firstcheck-story-video-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(1,1,1,.7);
      }
      .firstcheck-video-title {
        font-size: 24px;
        margin-bottom: 20px;
        font-family: latoregular;
      }
      .firstcheck-video-btn {
        border: 1px solid $default-white-color;
        color: $default-white-color;
        padding: 10px 20px;
        transition: .3s;
        width: 120px;
        text-align: center;
        cursor: pointer;
        &:hover {
         background-color: #229dd4;
         border: 1px solid #229dd4;
        }
      }
      .firstcheck-story-video-cnt {
        z-index: 10;
      }
    }
}

#firstcheck-stats-sec{
  padding: 60px 0;
  background-color: #229dd4;
  .firstcheck-stats-cnt {
    text-align: center;
    margin: 0 auto 20px;
  }
  
}
.firstcheck-stats-img {
  width: 70px;
}

.firstcheck-stats-margin {
  @include mquery(767px){
    margin-bottom: 40px;
  }
}

#firstcheck-stats-sec-2 {
  padding: 60px 0;
  .firstcheck-stats-circle {
    background-color: #229dd4;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    padding: 25px;
    text-align: center;
    margin: 0 auto 20px;
    @extend %flex-center;
  }
  .firstcheck-stats-text  {
    color: #229dd4;
  }
}

#firstcheck-story-sec-three {
    padding: 60px 0;
    @include mquery(1200px) {
      padding: 0;
    }
.firstcheck-story-sec-three-bk {
    @extend %background-property;
    background-image: url(/images/landing-pages/banners/firstcheck-story-sec-two.jpg);
    height: 470px;
    @extend %flex-center;
    @include mquery(1200px) {
        margin-top: 60px;
    }
    @include mquery(768px) {
      height: 210px;
    }
  }
  .firstcheck-story-sec-three-text {
    padding: 0 20px;
    @include mquery(1200px) {
     padding: 60px 0 0;
    }
  }

}

#firstcheck-story-sec-four {
    padding: 60px 0;
    @include mquery(1200px) {
        padding: 0 0 60px 0;
      }
    .firstcheck-story-sec-four-text {
        padding: 0 20px;
    }
    } 
    


#firstcheck-paralax-sec {
    @include parallax-sec('/images/landing-pages/banners/firstcheck-parallax-banner.jpg', '/images/landing-pages/banners/firstcheck-parallax-banner-mobile.jpg');
    @include mquery(768px) {
      background-attachment: initial;
    }
    .firstcheck-paralax-layer {
      @include parallax-layer(rgba(1, 1, 1, 0.7));
    }
}

#firstcheck-last-form-cnt {
    background: #229dd4;
    padding: 60px 0;
}

.firstcheck-bottom-button-style{
    background-color: transparent;
    border: 2px solid #fff !important;
    transition: .3s;
    &:hover {
        background-color: white;
        border: 2px solid #fff !important;
        color: #333;
    }
}
.firstcheck-bottom-link {
    color:#333;
    transition: .2s;
    &:hover {
        color:#333;
        text-decoration: underline;
    }
}
.firstcheck-link {
@include link(#229dd4, darken(#229dd4, 2.5), underline);
}
.firstcheck-button-style {
@include button-style(#229dd4, #fff, #229dd4, #fff, .8);
}