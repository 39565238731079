/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
USER PROFILE PAGE
---------------------------------------------------------- */
.user-profile-maincont {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px 340px 0px 0px;
  position: relative;
  /* User profile leftcol ends here */
}

@media all and (max-width: 960px) {
  .user-profile-maincont {
    padding: 0px;
  }
}

.user-profile-maincont .up-leftcol {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* User profile top section ends here */
  /* User porfile tabs container ends here */
}

.user-profile-maincont .up-leftcol .up-top-section {
  position: relative;
  min-height: 300px;
  padding: 30px 30px 30px 300px;
  background-color: #ffffff;
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges {
  position: absolute;
  top: -30px;
  left: 30px;
  margin: 0px;
  padding: 0px;
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges > li {
  float: left;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges > li > span {
  float: left;
  width: 38px;
  height: 30px;
  margin: 0px 5px 0px 0px;
  padding: 0px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  display: none;
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges > li > span.visible {
  display: inline-block;
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges > li > span.up-sophisticated-badge {
  background-image: url(../../images/up-star-badge.png);
}

.user-profile-maincont .up-leftcol .up-top-section .up-badges > li > span.up-syndicated-badge {
  background-image: url(../../images/up-syndicate-badge.png);
}

.user-profile-maincont .up-leftcol .up-top-section > .left {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 240px;
  min-height: 240px;
}

.user-profile-maincont .up-leftcol .up-top-section > .left > img {
  width: 155px;
  height: 155px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .left > img {
    float: none;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div {
  bottom: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  width: 100%;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .left > div {
    position: relative;
    float: left;
    left: auto;
    bottom: auto;
    margin: 20px 0px 0px 0px;
    padding: 0px;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links {
  float: left;
  width: 100%;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  text-align: center;
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links > a {
  float: left;
  width: 20px;
  height: 20px;
  margin: 0px 7px 0px 0px;
  padding: 0px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links > a:hover {
  opacity: 0.7;
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links > a > img {
  float: left;
  width: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links > a {
    display: inline-block;
    float: none;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .left > div .up-social-links > a:last-of-type {
    margin: 0px;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .user-profile-name {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 22px;
  color: #7e7e7e;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .left > div .user-profile-name {
    text-align: center;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .left > div .user-profile-title {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #b3b3b3;
  line-height: 1.4em;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .left > div .user-profile-title {
    text-align: center;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .right {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 240px;
  text-align: right;
  margin-top: 30px;
}

.user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text {
  margin: 15px 0px 0px 0px;
  padding: 0px;
  text-align: inherit;
}

.user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text:first-of-type {
  margin-top: 0px;
}

.user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text.up-contact-details {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text.up-contact-details {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 20px 0px 0px 0px;
  }
}

.user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text > span {
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #b3b3b3;
}

.user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text > p {
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
  line-height: 18px;
  text-align: inherit;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text:first-of-type {
    margin-top: 20px;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text > span {
    text-align: center;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .right .up-detail-text > p {
    text-align: center;
  }
}

.user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px 10px 4px 10px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  background-color: #8F9EA9;
  z-index: 1;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn:hover {
  background-color: #469edc;
}

.user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn span {
  font-family: inherit;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-top-section {
    padding: 30px;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .left {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 220px;
    text-align: center;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .left > img {
    display: inline-block;
    width: 164px;
    height: 164px;
    margin: 0px;
    padding: 0px;
  }
  .user-profile-maincont .up-leftcol .up-top-section > .right {
    width: 100%;
    min-height: 220px;
    text-align: center;
    margin-top: 0;
  }
  .user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn {
    top: 8px;
    right: 8px;
    background: transparent;
    color: #0077B5;
    text-decoration: underline;
    font-size: 14px;
  }
  .user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn .hide-on-small {
    display: none;
  }
  .user-profile-maincont .up-leftcol .up-top-section .up-edit-profile-btn:hover {
    background-color: inherit;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* User profile tabs ends here */
  /* User profile tabs content ends here */
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs {
    background-color: #8f9ea9;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li {
    width: 50%;
    border-style: solid;
    border-color: #ffffff;
    border-bottom-width: 1px;
  }
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li:last-of-type {
    border-style: solid;
    border-color: #ffffff;
    border-right-width: 1px;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 0px 0px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a:hover, .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.active {
  background-color: #469edc;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.inv-team-link {
  padding: 0 5px;
  display: table;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.inv-team-link span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
}

@media all and (max-width: 580px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.inv-team-link span {
    font-size: 12px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.inv-team-link:hover, .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs > li > a.inv-team-link.active {
  background-color: #469edc;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.three-tabs > li {
  width: 33.3%;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li {
  width: 25%;
}

@media all and (max-width: 600px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li {
    border-style: solid;
    border-color: #ffffff;
    border-bottom-width: 0px;
  }
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li:last-of-type {
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 0px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li a {
  padding: 0 5px;
  display: table;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li a span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-family: 'latoregular';
  font-size: 15px;
  color: #ffffff;
}

@media all and (max-width: 580px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li a span {
    font-size: 12px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li a:hover, .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.four-tabs > li a.active {
  background-color: #469edc;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.five-tabs > li {
  width: 20%;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs.six-tabs > li {
  width: 16.66%;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 20px;
  background-color: #ffffff;
  /* User profile BIO tab ends here */
  /* Single capital raisings block ends here */
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-bio-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0;
  position: relative;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-bio-content .up-bio-maintext {
  width: 100%;
  padding: 0px;
  color: #7e7e7e;
  font-family: "latolight";
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-bio-content .up-bio-maintext:empty:after {
  display: inline-block;
  content: "This investor has not yet entered a biography.";
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .no-show {
  display: none;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cr-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cr-content .formatting-container {
  margin: 15px 0;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cr-content .up-bio-maintext {
  color: #7e7e7e;
  font-family: "latolight";
  font-size: 16px;
  line-height: 24px;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-fo-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding: 5px 0px 5px 0px;
  position: relative;
  display: none;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding: 5px 0px 5px 0px;
  position: relative;
  display: none;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .actions {
  text-align: right;
  margin: 0 0 8px 0;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .synd-link,
.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .learn-link {
  display: inline-block;
  margin: 4px;
  padding: 7px 35px 5px 10px;
  min-height: 26px;
  font-family: 'latoblack';
  font-size: 11px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url("../../images/blue-button-arrow.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .synd-link:hover,
.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .learn-link:hover {
  background-position: right 7px center;
}

@media all and (max-width: 538px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .synd-link,
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content.my-profile .learn-link {
    padding-right: 22px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content > p > a {
  color: #469edc;
  text-decoration: none;
  font-family: 'latoregular';
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-synd-content > p > a:hover {
  text-decoration: underline;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cert-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding: 5px 0px 5px 0px;
  position: relative;
  display: none;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cert-content > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cert-content > p > a {
  color: #469edc;
  text-decoration: none;
  font-family: 'latoregular';
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cert-content > p > a:hover {
  text-decoration: underline;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .up-cert-content > p > span {
  color: #469edc;
  font-family: 'latoregular';
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block {
  min-height: 135px;
  height: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px 0px 0px 150px;
  position: relative;
  background-color: #f3f5f6;
}

@media all and (max-width: 560px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block {
    padding: 0px 0px 0px 115px;
  }
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block {
    padding: 120px 0 0 0;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 135px;
  height: 135px;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 560px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > img {
    top: 10px;
    left: 10px;
    width: 110px;
    height: 110px;
  }
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > img {
    left: 50%;
    margin-left: -55px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .cr-unfollow {
  position: absolute;
  top: 0px;
  right: 20px;
  height: 19px;
  font-family: 'latoregular';
  font-size: 11px;
  color: #ffffff;
  margin: 0px;
  padding: 3px 10px;
  background-color: #e3546f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 5;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .cr-unfollow:hover {
  background-color: #e76a81;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .up-cr-role {
  position: absolute;
  top: 0px;
  right: 20px;
  height: 19px;
  font-family: 'latoregular';
  font-size: 11px;
  color: #ffffff;
  margin: 0px;
  padding: 3px 10px;
  background-color: #8f9ea9;
  z-index: 5;
  cursor: default;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div {
    padding-bottom: 60px !important;
  }
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div {
    padding: 10px !important;
    min-height: 110px;
    text-align: center;
  }
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
    display: block !important;
    text-align: center;
    float: none;
    clear: both;
    font-size: 16px !important;
  }
}

@media all and (max-width: 490px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div {
    padding: 20px 15px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > span {
  width: 100%;
  margin: 0px;
  padding: 0px 70px 0px 0px;
  font-family: 'latoblack';
  font-size: 19px;
  color: #7e7e7e;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > span {
    padding: 0;
  }
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > span {
    font-size: 16px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #7e7e7e;
  line-height: 18px;
  white-space: pre-wrap;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
    min-height: 105px;
  }
}

@media all and (max-width: 560px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
    min-height: 72px;
  }
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
    min-height: auto;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div .cr-project-invested {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #333333;
  position: relative;
  width: 100%;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  left: auto;
  bottom: auto;
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div .cr-project-invested {
    text-align: center;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > span {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 14px;
  color: #7e7e7e;
  clear: both;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > span {
    font-size: 18px;
    font-family: "latobold";
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div > p {
  float: left;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 12px;
  color: #7e7e7e;
  clear: both;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div span,
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block > div p {
    float: none;
    display: inline;
    padding: 0 0px 0 0;
    margin: 0;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons {
  position: absolute;
  bottom: 15px;
  right: 0px;
  margin: 0px;
  padding: 0px 15px;
  margin: 0px;
}

@media all and (max-width: 480px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}

@media all and (max-width: 490px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 15px 0px 5px 0;
    padding: 0px;
  }
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons > a {
  display: inline-block;
  margin: 0px 0px 0px 10px;
  padding: 7px 35px 5px 10px;
  min-height: 26px;
  font-family: 'latoblack';
  font-size: 11px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url("../../images/blue-button-arrow.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons > a:hover {
  background-position: right 7px center;
}

@media all and (max-width: 620px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons > a {
    margin: 0px 10px 0px 0px;
  }
}

@media all and (max-width: 490px) {
  .user-profile-maincont .up-leftcol .up-tabs-cont .up-tabs-content .single-cr-fo-block .single-cr-fo-buttons > a.cr-fo-see-profile-btn {
    margin: 10px 10px 0px 0px;
  }
}

.user-profile-maincont .up-rightcol {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 320px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}

@media all and (max-width: 960px) {
  .user-profile-maincont .up-rightcol {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    max-width: 100%;
    padding: 0px;
  }
}

.user-profile-maincont .up-comple-invest-cont {
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 320px;
  margin: 0px;
  padding: 0px;
  /* User profile completeness widget ends here */
}

@media all and (max-width: 960px) {
  .user-profile-maincont .up-comple-invest-cont {
    position: relative;
    float: left;
    max-width: 100%;
  }
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget {
  float: left;
  width: 100%;
  min-height: 140px;
  margin: 0 0 20px 0;
  padding: 0px 15px;
  background-color: #ffffff;
  text-align: center;
}

@media all and (max-width: 960px) {
  .user-profile-maincont .up-comple-invest-cont .up-completeness-widget {
    width: 49%;
    margin-top: 20px;
  }
}

@media all and (max-width: 650px) {
  .user-profile-maincont .up-comple-invest-cont .up-completeness-widget {
    width: 100%;
  }
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar {
  display: inline-block;
  width: 100%;
  max-width: 260px;
  height: 44px;
  margin: 35px 0px 0px 0px;
  padding: 0px;
  background-color: #FFFFFF;
  border-style: solid;
  border-color: #EEEEEE;
  border-width: 2px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar > div {
  float: left;
  height: 40px;
  margin: 0px;
  padding: 0px;
  background-color: #aad155;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar > div > span {
  float: left;
  width: 100%;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 19px;
  color: #ffffff;
  text-align: center;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar .up-click-text {
  position: absolute;
  width: 100%;
  color: #8f9ea9;
  display: none;
  font-size: 16px;
  text-decoration: underline;
  font-weight: normal;
  margin: 14px 0px 0px 0px;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar:hover .up-completeness-percentage {
  display: none;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget .up-completeness-bar:hover .up-click-text {
  display: block;
}

.user-profile-maincont .up-comple-invest-cont .up-completeness-widget > span {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latoregular';
  font-size: 13px;
  color: #b3b3b3;
}

.user-profile-maincont .up-comple-invest-cont .up-investment-widget {
  float: left;
  width: 100%;
  min-height: 140px;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  background-color: #ffffff;
  text-align: center;
}

@media all and (max-width: 960px) {
  .user-profile-maincont .up-comple-invest-cont .up-investment-widget {
    width: 49%;
    float: right;
  }
}

@media all and (max-width: 650px) {
  .user-profile-maincont .up-comple-invest-cont .up-investment-widget {
    width: 100%;
  }
}

.user-profile-maincont .up-comple-invest-cont .up-investment-widget > span {
  float: left;
  width: 100%;
  margin: 38px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 32px;
  color: #7e7e7e;
  text-align: center;
}

.user-profile-maincont .up-comple-invest-cont .up-investment-widget > p {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latoregular';
  font-size: 13px;
  color: #b3b3b3;
}

.syndicate-profile-maincont .up-rightcol {
  padding: 0px;
}

.syndicate-profile-maincont .up-rightcol > .default-rightcol-widget:first-of-type {
  margin-top: 0px;
}

.syndicate-profile-maincont .up-tabs-cont .up-tabs > li {
  border-style: solid;
  border-color: #ffffff !important;
  border-right-width: 1px;
}

.syndicate-profile-maincont .up-tabs-cont .up-tabs > li:last-of-type {
  border: 0px;
}

.syndicate-profile-team-section {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.syndicate-profile-team-section .sp-team-section-block {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 10px;
}

@media all and (max-width: 1023px) {
  .syndicate-profile-team-section .sp-team-section-block {
    width: 50%;
  }
}

@media all and (max-width: 660px) {
  .syndicate-profile-team-section .sp-team-section-block {
    width: 100%;
    padding: 10px 0px;
  }
}

.syndicate-profile-team-section .sp-team-section-block > div {
  float: left;
  width: 100%;
  min-height: 95px;
  margin: 0px;
  padding: 0px 0px 0px 95px;
  position: relative;
  background-color: #f3f5f6;
  border-style: solid;
  border-color: #e9eaea;
  border-width: 1px;
}

.syndicate-profile-team-section .sp-team-section-block > div > img {
  position: absolute;
  top: 17px;
  left: 15px;
  margin: 0px;
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 40px;
  border-style: solid;
  border-color: #d8dadb;
  border-width: 4px;
}

.syndicate-profile-team-section .sp-team-section-block > div > div {
  float: left;
  width: 100%;
  height: 100%;
  min-height: 110px;
  margin: 0px;
  padding: 15px;
  position: relative;
}

.syndicate-profile-team-section .sp-team-section-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: right;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  text-transform: uppercase;
  line-height: 18px;
}

.syndicate-profile-team-section .sp-team-section-block > div > div > p {
  float: left;
  width: 100%;
  margin: 10px 0px 10px 0px;
  text-align: right;
  font-family: 'latolight';
  font-size: 13px;
  color: #7e7e7e;
}

.syndicate-profile-team-section .sp-team-section-block > div > div > a {
  margin: 0px 0px 0px 10px;
  padding: 7px 35px 5px 10px;
  min-height: 26px;
  font-family: 'latoblack';
  font-size: 11px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url(../../images/blue-button-arrow.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: 10px;
  float: right;
}

.syndicate-profile-team-section .sp-team-section-block > div > div > a:hover {
  background-position: right 7px center;
}

.syndicate-profile-team-section .sp-team-section-block > div .od-team-section-block-remove {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  background-image: url(../../images/cr-result-block-remove.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.syndicate-profile-team-section .sp-team-section-block .sp-team-section-single {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.syndicate-profile-team-section .sp-team-section-block .sp-team-section-single h5 {
  font-family: 'latoregular';
  font-size: 16px;
  color: #7e7e7e;
  text-transform: uppercase;
  line-height: 18px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.syndicate-profile-team-section .sp-team-section-block .sp-team-section-single a.see-profile-button {
  width: 120px;
  margin: 0;
}

.up-add-portfolio-item {
  width: 100%;
  margin: 0 0 15px 0;
  padding: 0px;
}

.up-add-portfolio-item .up-add-portfolio-item select::placeholder, .up-add-portfolio-item .up-add-portfolio-item input::placeholder {
  font-family: 'latolight';
  font-weight: 'normal';
}

.up-api-inner {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 7px 60px 7px 10px;
  background-color: #f3f5f6;
}

.up-api-inner > div {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin: 0px -2px;
  padding: 0px;
}

@media all and (max-width: 600px) {
  .up-api-inner > div {
    width: 100%;
  }
}

.up-api-inner > div .up-api-formgroup {
  width: 100%;
  margin: 0px;
  padding: 10px;
  position: relative;
}

.up-api-inner > div .up-api-formgroup > span {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.up-api-inner > div .up-api-formgroup > input {
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  font-family: "latobold";
  font-size: 14px;
  height: 42px;
  margin: 9px 0 0;
  padding: 0 10px;
  width: 100%;
}

.up-api-inner > div .up-api-formgroup > span.select2 {
  margin-top: 10px;
}

.up-api-inner > div .up-api-formgroup > span.error-tooltip {
  color: #ffffff;
  width: auto;
  padding: 5px 10px;
}

.up-api-inner .up-api-add-item {
  background-color: #469edc;
  background-image: url("../../images/cert-upload-btn-icon.png");
  background-position: center center;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
  bottom: 18px;
  transition: all 0.3s ease 0s;
  width: 40px;
}

.up-cert-tabs-container {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  position: relative;
  /* Certificates tabs UL ends here */
  /* Certificates tabs accountants details content ends here */
  /* Certificates manual upload container ends here */
}

.up-cert-tabs-container .up-cert-tabs {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.up-cert-tabs-container .up-cert-tabs > li {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

.up-cert-tabs-container .up-cert-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 420px) {
  .up-cert-tabs-container .up-cert-tabs > li {
    width: 100%;
    border-right: 0px;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
}

.up-cert-tabs-container .up-cert-tabs > li > a {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 9px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.up-cert-tabs-container .up-cert-tabs > li > a:hover, .up-cert-tabs-container .up-cert-tabs > li > a.active {
  background-color: #469edc;
}

.up-cert-tabs-container .up-cert-ad-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.up-cert-tabs-container .up-cert-ad-cont > span,
.up-cert-tabs-container .up-cert-ad-cont .certificate-accaunt-spans {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
}

.up-cert-tabs-container .up-cert-ad-cont .form-groups-wrapper {
  margin-top: 20px;
}

.up-cert-tabs-container .up-cert-mu-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: none;
}

.upload-doc {
  width: 120px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  border: 0px;
  float: right;
  color: #fff;
  font-size: 14px;
  margin: 10px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.upload-doc:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 580px) {
  .upload-doc {
    top: 1px;
  }
}

/* Certificate tabs container ends here */
.up-cert-mu-filegroup .sfg-field {
  padding: 0px 60px 0px 10px;
}

.up-cert-mu-filegroup .sfg-field > .upload-doc {
  width: 70px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.up-cert-mu-filegroup .sfg-field > .upload-doc:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 580px) {
  .up-cert-mu-filegroup .sfg-field > .upload-doc {
    top: 1px;
  }
}

.up-cert-mu-filegroup .sfg-field > button.default-documents {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  background-color: #469edc;
  background-position: center center;
  background-image: url(../../images/cert-upload-btn-icon.png);
  background-repeat: no-repeat;
  border: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.portfolio-user-role {
  font-size: 11px !important;
  font-family: 'latoregular' !important;
  margin-top: 5px !important;
}

.up-cert-file-blocks {
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  padding: 0px;
  position: relative;
}

.up-cert-file-blocks .up-cert-file-block {
  float: left;
  width: 49%;
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.up-cert-file-blocks .up-cert-file-block:nth-child(-n+2) {
  margin-top: 0px;
}

@media all and (max-width: 650px) {
  .up-cert-file-blocks .up-cert-file-block {
    padding: 0px !important;
    width: 100%;
    margin-top: 20px !important;
  }
  .up-cert-file-blocks .up-cert-file-block:first-of-type {
    margin-top: 0px !important;
  }
}

.up-cert-file-blocks .up-cert-file-block div {
  float: left;
  width: 100%;
  margin: 0;
  position: relative;
  background-color: #f3f5f6;
  /* Certificate file titlebar */
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 0px 20px 0px 40px;
  position: relative;
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > span {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
  background-color: #e7e7e7;
  background-repeat: no-repeat;
  background-position: center center;
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > span.text-file {
  background-color: #dc6e88;
  background-image: url(../../images/cert-file-icon.png);
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > span.text-file.docx-file {
  background-color: #8fb9cd;
  background-image: url(../../images/cert-text-file-icon.png);
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > span.text-file.pdf-file {
  background-color: #dc6e88;
  background-image: url(../../images/cert-pdf-file-icon.png);
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > span.text-file.doc-file {
  background-color: #8fb9cd;
  background-image: url(../../images/cert-text-file-icon.png);
}

.up-cert-file-blocks .up-cert-file-block div .up-cert-fileblock-titlebar > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 11px 0px 11px 15px;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
}

.up-cert-file-blocks .up-cert-file-block div > p {
  float: left;
  width: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px 15px 50px;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
}

.up-cert-file-blocks .up-cert-file-block div .cert-file-download-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0px;
  padding: 7px 40px 7px 10px;
  height: 26px;
  color: #61a0cb;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  background-image: url(../../images/cert-file-download-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #61a0cb;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.up-cert-file-blocks .up-cert-file-block div .cert-file-download-btn:hover {
  background-position: right 10px top 6px;
}

.up-cert-file-blocks .up-cert-file-block div .cert-file-remove-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0px;
  padding: 7px 40px 7px 10px;
  height: 26px;
  color: #EA7483;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  background-image: url(../../images/cert-file-remove-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #EA7483;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.up-cert-file-blocks .up-cert-file-block div .cert-file-remove-btn:hover {
  background-position: right 7px center;
}

.default-rightcol-widget {
  float: left;
  width: 100%;
  margin: 0px 0px 20px 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 960px) {
  .default-rightcol-widget {
    width: 49%;
  }
}

@media all and (max-width: 650px) {
  .default-rightcol-widget {
    width: 100%;
  }
}

@media all and (max-width: 960px) {
  .default-rightcol-widget.right {
    float: right;
    width: 49%;
  }
}

@media all and (max-width: 650px) {
  .default-rightcol-widget.right {
    width: 100%;
  }
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 10px;
  background-color: transparent;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div a {
  float: left;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div a:hover {
  color: #469edc;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div .bold {
  font-family: 'latobold';
  display: inline;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .title {
  float: left;
  width: 100%;
  min-height: 30px;
  float: left;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .title.status {
  text-align: right;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .invest-from {
  float: left;
  width: 100%;
  color: #7e7e7e;
  font-size: 12px;
  font-family: 'latoregular';
  text-align: left;
  padding: 10px 0 0;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .invest-from a {
  display: inline;
  float: none;
  font-size: 12px;
  margin-right: 3px;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .investment {
  float: left;
  font-family: 'latoregular';
  font-size: 14px;
  color: #95c232;
}

.default-rightcol-widget.latest-ptf-invest-widget .up-li-person-block > div > .investment .bold {
  font-size: 16px;
  padding-left: 2px;
}

.default-rightcol-widget.people-like-widget .up-ply-person-block > div {
  background-color: transparent;
  padding: 0 0 0 10px;
}

.default-rightcol-widget.people-like-widget .up-ply-person-block > div span,
.default-rightcol-widget.people-like-widget .up-ply-person-block > div p {
  text-align: left;
}

.default-rightcol-widget.people-like-widget .up-ply-person-block > div .user-profile-title {
  font-family: 'latoregular';
  font-size: 12px;
}

.default-rightcol-widget .view-link {
  float: none;
  width: 93px;
  margin: 10px 0 0 0;
  padding: 0px 0px 0px 5px;
  height: 21px;
  line-height: 20px;
  font-family: 'latolight' !important;
  font-size: 9px !important;
  text-transform: uppercase;
  color: #469edc !important;
  text-decoration: none;
  background-image: url("../../images/blue-button-arrow.png");
  background-repeat: no-repeat;
  background-position: right 5px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.default-rightcol-widget .view-link:hover {
  background-position: right 3px center;
}

.default-rightcol-widget .view-link.investor, .default-rightcol-widget .view-link.profile {
  float: left !important;
}

.default-rightcol-widget .view-link.offer {
  float: right !important;
}

.default-rightcol-widget .drw-title {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 11px 15px;
  background-color: #8f9ea9;
}

.default-rightcol-widget .drw-title > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 16px;
  color: #ffffff;
}

.default-rightcol-widget .drw-content {
  float: left;
  width: 100%;
  min-height: 120px;
  margin: 0px;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
}

.up-li-person-block {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 0px 100px;
  position: relative;
}

.up-li-person-block > img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 100px;
  height: 100px;
}

.up-li-person-block > div {
  float: left;
  width: 100%;
  min-height: 100px;
  margin: 0px;
  padding: 10px 15px;
  background-color: #f3f5f6;
}

.up-li-person-block > div > span {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  text-align: right;
}

.up-li-person-block > div > span:first-of-type {
  margin-top: 0px;
}

.up-li-person-block > div > span:nth-child(1), .up-li-person-block > div > span:nth-child(4) {
  font-family: 'latoregular';
  font-size: 12px;
  color: #7e7e7e;
}

.up-li-person-block > div > span:nth-child(2) {
  font-family: 'latoblack';
  font-size: 25px;
  color: #95c232;
}

.up-li-person-block > div > span:nth-child(3) {
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
}

.up-ply-person-block {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 15px 0px 0px 0px;
  padding: 0px 0px 0px 60px;
  position: relative;
}

.up-ply-person-block:first-of-type {
  margin-top: 0px;
}

.up-ply-person-block > img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 60px;
  height: 60px;
}

.up-ply-person-block > div {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 0px;
  padding: 10px 15px;
  background-color: #f3f5f6;
}

.up-ply-person-block > div > span {
  float: left;
  width: 100%;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  text-align: right;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
}

.up-ply-person-block > div > p {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  text-align: right;
  font-family: 'latoregular';
  font-size: 12px;
  color: #7e7e7e;
}

.card {
  padding: 20px;
  min-height: 135px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #7e7e7e;
}

@media all and (max-width: 560px) {
  .card {
    min-height: 160px;
    padding: 20px 15px 50px;
  }
}

@media all and (max-width: 560px) {
  .card .cr-project-invested {
    margin: 0;
    padding: 0;
  }
}

@media all and (max-width: 380px) {
  .card .cr-project-invested span {
    font-size: 12px;
  }
}

@media all and (max-width: 380px) {
  .card .cr-project-invested p {
    font-size: 10px;
  }
}

.card h4 {
  font-family: 'latoregular';
  font-size: 18px;
  line-height: 27px;
}

.card h4 span {
  font-size: 14px;
}

.card .card-description p {
  font-size: 14px;
  line-height: 21px;
}

.card .see-offer-button {
  display: inline-block;
  margin: 0;
  padding: 7px 35px 5px 10px;
  min-height: 26px;
  font-family: 'latoblack';
  font-size: 11px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url(../../images/blue-button-arrow.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  transition: 0.3s;
  width: 145px;
  margin-left: auto;
}

@media all and (max-width: 560px) {
  .card .single-cr-fo-buttons {
    padding: 0;
    width: auto;
  }
}

@media all and (max-width: 560px) {
  .card .single-cr-fo-buttons .cr-fo-see-offer-btn {
    padding: 6px 20px 5px 10px;
    margin: 0 0 0 10px;
  }
}

span.text-file {
  background-color: #dc6e88;
  background-image: url(../../images/cert-file-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 32px;
  display: inline-block;
}

.file-upload-card {
  display: flex;
  flex-direction: row;
  background-color: #f3f5f6;
}

.file-upload-card .file-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.file-upload-card .file-info h4 {
  font-size: 16px;
  line-height: 24px;
  font-family: "latoregular";
  color: #7e7e7e;
}

.file-upload-card .file-info a.file-download-button {
  display: inline-block;
  margin: 0px;
  padding: 7px 40px 7px 10px;
  height: 26px;
  color: #61a0cb;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  background-image: url(../../images/cert-file-download-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #61a0cb;
  border-width: 1px;
  transition: 0.3s;
  margin-left: auto;
}

@media (max-width: 250px) {
  .investor {
    float: right;
  }
}

.investment-table {
  margin-top: 20px;
  color: #7e7e7e;
}

.investment-table th {
  background-color: #8f9ea9;
  color: #fff;
  font-family: latoregular;
}

.investment-table .download-ia-icon {
  color: #b9d879;
}

.investment-table .download-ia-icon:hover {
  cursor: pointer;
}
