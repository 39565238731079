/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
MY SYNDICATES
---------------------------------------------------------- */
.my-syndicates-wrapper {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px 340px 0px 0px;
  position: relative;
}

@media all and (max-width: 960px) {
  .my-syndicates-wrapper {
    padding: 0px;
  }
}

.my-syndicates-wrapper.mysindy-outer .my-synd-save-wrap {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right;
}

@media all and (max-width: 580px) {
  .my-syndicates-wrapper.mysindy-outer .my-synd-save-wrap {
    position: relative;
    width: 100%;
    margin: 0 20px 20px;
    text-align: left;
  }
  .my-syndicates-wrapper.mysindy-outer .my-synd-save-wrap a {
    margin-top: 0;
  }
}

.my-syndicates-wrapper.mysindy-outer .my-synd-save-wrap .default-submit-button.large {
  display: inline-block;
  float: none;
}

.my-syndicates-wrapper .reg-log-title, .my-syndicates-wrapper .reg-log-subtitle {
  display: block;
  float: none;
}

.my-syndicates-wrapper .ms-leftcol.cr-leftcol {
  padding-top: 20px;
}

.ms-leftcol {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px;
  background-color: #ffffff;
  position: relative;
}

.ms-rightcol {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 320px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 960px) {
  .ms-rightcol {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    max-width: 100%;
    padding: 0px;
  }
}

.ms-rightcol .default-rightcol-widget:first-of-type {
  margin-top: 0px;
}

@media all and (max-width: 960px) {
  .ms-rightcol .default-rightcol-widget:first-of-type {
    margin-top: 15px;
  }
}

.my-syndicates-container {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

.single-mysyndicates-block {
  float: left;
  width: 100%;
  min-height: 150px;
  margin: 15px 0px 0px 0px;
  padding: 0px 0px 0px 150px;
  position: relative;
  background-color: #f3f5f6;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.single-mysyndicates-block:first-of-type {
  margin-top: 0px;
}

.single-mysyndicates-block .long, .single-mysyndicates-block .short {
  font: inherit;
}

.single-mysyndicates-block .short {
  display: none;
}

@media all and (max-width: 560px) {
  .single-mysyndicates-block {
    padding: 0px 0px 0px 115px;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block {
    padding: 120px 0 0 0;
  }
  .single-mysyndicates-block .short {
    display: unset;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block.raising-syndi-blocks > div {
    min-height: 110px;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block.raising-syndi-blocks > div .ms-cr-info-action {
    width: 100% !important;
  }
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block.raising-syndi-blocks > div .ms-cr-info-action span,
  .single-mysyndicates-block.raising-syndi-blocks > div .ms-cr-info-action p {
    float: none;
    display: inline;
    padding: 0 5px 0 0;
  }
}

.single-mysyndicates-block > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 150px;
  height: 150px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 560px) {
  .single-mysyndicates-block > img {
    left: 10px;
    width: 110px;
    height: 110px;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > img {
    left: 50%;
    margin-left: -55px;
  }
}

.single-mysyndicates-block .ms-status {
  position: absolute;
  top: 0px;
  right: 20px;
  height: 19px;
  font-family: 'latoregular';
  font-size: 11px;
  color: #ffffff;
  margin: 0px;
  padding: 3px 10px;
  background-color: #8f9ea9;
  width: 90px;
  text-align: center;
}

.single-mysyndicates-block .ms-status.active {
  background-color: #aad155;
}

.single-mysyndicates-block .ms-status.pending {
  background-color: #f8b93e;
}

.single-mysyndicates-block .ms-status.disapproved {
  background-color: #ec5f73;
}

.single-mysyndicates-block .ms-status.draft {
  background-color: #8f9ea9;
}

.single-mysyndicates-block .ms-status.complete {
  background-color: #469edc;
}

.single-mysyndicates-block .ms-status.closed {
  background-color: #ec5f73;
}

.single-mysyndicates-block > div {
  float: left;
  width: 100%;
  min-height: 150px;
  margin: 0px;
  padding: 20px 15px 50px 15px;
  position: relative;
}

@media all and (max-width: 1050px) {
  .single-mysyndicates-block > div {
    padding: 20px 15px;
  }
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block > div {
    padding-bottom: 60px !important;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > div {
    padding: 10px !important;
  }
}

.single-mysyndicates-block > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 70px 0px 0px;
  font-family: 'latoblack';
  font-size: 19px;
  color: #7e7e7e;
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block > div > span {
    padding: 0;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > div > span {
    font-size: 16px;
    text-align: center;
  }
}

.single-mysyndicates-block > div > p {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #7e7e7e;
  line-height: 18px;
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block > div > p {
    min-height: 105px;
  }
}

@media all and (max-width: 560px) {
  .single-mysyndicates-block > div > p {
    min-height: 72px;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > div > p {
    min-height: auto;
    text-align: center;
  }
}

.single-mysyndicates-block > div .single-ms-action-buttons {
  position: absolute;
  bottom: 15px;
  right: 0px;
  margin: 0px;
  padding: 0px 15px;
  margin: 0px;
}

.single-mysyndicates-block > div .single-ms-action-buttons a {
  margin-right: 0;
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > div .single-ms-action-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: static !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .single-mysyndicates-block > div .single-ms-action-buttons a {
    margin: 10px auto;
  }
}

@media all and (max-width: 350px) {
  .single-mysyndicates-block > div .single-ms-action-buttons a {
    padding-left: 5px !important;
    flex-direction: column;
    justify-content: center;
  }
}

.single-mysyndicates-block > div .single-ms-action-buttons.my-syndicates-listing .ms-see-offer-btn {
  color: #ffffff;
  background-color: #469edc;
  background-image: url("../../../images/white-button-arrow.png");
}

.single-mysyndicates-block > div .single-ms-action-buttons > a {
  float: right;
  margin: 0px 0px 0px 10px;
  padding: 6px 35px 5px 10px;
  min-height: 26px;
  font-family: 'latoblack';
  font-size: 11px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url("../../images/blue-button-arrow.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.single-mysyndicates-block > div .single-ms-action-buttons > a:hover {
  background-position: right 7px center;
}

@media all and (max-width: 490px) {
  .single-mysyndicates-block > div .single-ms-action-buttons > a {
    margin: 0px 10px 0px 0px;
    background-image: none;
    padding: 6px 10px;
    margin-right: 0 !important;
  }
}

@media all and (max-width: 1050px) {
  .single-mysyndicates-block > div .single-ms-action-buttons {
    bottom: auto;
    right: auto;
    float: left;
    width: 100%;
    padding: 0px;
    margin: 5px 0px 0px 0px;
    position: relative;
  }
  .single-mysyndicates-block > div .single-ms-action-buttons > a {
    margin: 10px 10px 0px 0px;
  }
  .single-mysyndicates-block > div .single-ms-action-buttons > a:last-of-type {
    margin: 10px auto;
  }
}

.single-mysyndicates-block > div .ms-cr-info-action {
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 1050px) {
  .single-mysyndicates-block > div .ms-cr-info-action {
    float: left;
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: 10px;
  }
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block > div .ms-cr-info-action {
    position: absolute !important;
    left: -145px !important;
    bottom: 7px !important;
  }
}

@media all and (max-width: 560px) {
  .single-mysyndicates-block > div .ms-cr-info-action {
    left: -105px !important;
  }
}

@media all and (max-width: 480px) {
  .single-mysyndicates-block > div .ms-cr-info-action {
    position: relative !important;
    left: auto !important;
    bottom: auto !important;
    width: auto !important;
  }
}

.single-mysyndicates-block > div .ms-cr-info-action > span {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  clear: both;
}

@media all and (max-width: 620px) {
  .single-mysyndicates-block > div .ms-cr-info-action > span {
    font-size: 12px;
  }
}

.single-mysyndicates-block > div .ms-cr-info-action > a {
  float: left;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  font-family: "latoregular";
  font-size: 11px;
  color: #469edc;
  text-decoration: none;
  clear: both;
}

.single-mysyndicates-block > div .ms-cr-info-action > a:hover {
  text-decoration: underline;
}

.single-mysyndicates-block > div .ms-cr-info-action > p {
  float: left;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  font-family: "latoregular";
  font-size: 11px;
  color: #7e7e7e;
  text-decoration: none;
  clear: both;
}

/* single-mysyndicates-block ends here */
/* Landing Page Syndicates section */
.landing-top-btns {
  float: left !important;
  max-width: none !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center !important;
}

.landing-top-btns.blue a {
  background-color: #469edc;
}

.landing-top-btns.blue a:hover {
  background-color: #5ba9e0;
  border-style: solid;
  border-color: #5ba9e0;
  border-width: 1px;
}

.landing-top-btns a {
  float: none !important;
  display: inline-block;
  max-width: 215px;
  margin: 0 15px;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
  padding: 10px 35px 10px 15px;
  color: #ffffff;
  font-family: 'latoregular';
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.landing-top-btns a:hover {
  background-color: #469edc;
}

@media all and (max-width: 580px) {
  .landing-top-btns a {
    font-size: 12px;
    margin: 0 5px;
    max-width: 180px;
  }
}

@media all and (max-width: 420px) {
  .landing-top-btns a {
    margin: 0 10px 15px;
  }
}

.landing-top-btns a:first-child {
  margin-left: 0;
}

@media all and (max-width: 580px) {
  .landing-top-btns a:first-child {
    margin-left: 0;
  }
}

@media all and (max-width: 420px) {
  .landing-top-btns a:first-child {
    margin: 0 10px 15px;
  }
}

.landing-top-btns a:last-child {
  margin-right: 0;
}

@media all and (max-width: 580px) {
  .landing-top-btns a:last-child {
    margin-right: 0;
  }
}

@media all and (max-width: 420px) {
  .landing-top-btns a:last-child {
    margin: 0 10px 0;
  }
}

.landing-top-btns a.landing-browse-btn {
  background-image: url("../../../images/synd-browse-btn-icon@2x.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: right 7px center;
}

.landing-top-btns a.landing-lead-btn {
  background-image: url("../../../images/synd-lead-btn-icon@2x.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: right 7px center;
}

#home-top-wrapper {
  width: 100%;
  height: calc(100vh - 70px);
  background-image: url(../../images/home-top-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: table;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  #home-top-wrapper {
    height: 50%;
    padding: 50px 0;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap {
  background-image: url("../../../images/syndicates-lp.jpg");
}

#home-top-wrapper.landing-syndicates-top-wrap .htw-inner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#home-top-wrapper.landing-syndicates-top-wrap .htw-inner .mn-syndicate-title {
  font-size: 48px;
  font-family: latoregular;
  margin-bottom: 22px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 780px;
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  #home-top-wrapper.landing-syndicates-top-wrap .htw-inner .mn-syndicate-title {
    font-size: 36px;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap .htw-inner .mn-syndicate-subtitle {
  font-size: 24px;
  font-family: latolight;
  max-width: 780px;
  margin-bottom: 40px;
  line-height: 30px;
  text-align: center;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  #home-top-wrapper.landing-syndicates-top-wrap .htw-inner .mn-syndicate-subtitle {
    font-size: 18px;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-blue-btn {
  width: 253px;
  height: 55px;
  background-color: #469edc;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
  margin-right: 20px;
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-blue-btn:hover {
  background-color: rgba(70, 158, 220, 0.9);
}

@media all and (max-width: 560px) {
  #home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-blue-btn {
    margin-right: 0;
    margin-top: 15px;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-white-btn {
  width: 253px;
  height: 55px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media all and (max-width: 560px) {
  #home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-white-btn {
    margin-top: 15px;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicate-top-btns-cnt .syndicate-center-white-btn:hover {
  background-color: #469edc;
  border: 1px solid #469edc;
  color: #ffffff;
}

#home-top-wrapper.landing-syndicates-top-wrap {
  background-image: url("../../../images/syndicates-lp.jpg");
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner .mn-syndicate-title {
  font-size: 48px;
  font-family: latoregular;
  margin-bottom: 22px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 780px;
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  #home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner .mn-syndicate-title {
    font-size: 38px;
  }
}

@media all and (max-width: 600px) {
  #home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner .mn-syndicate-title {
    font-size: 32px;
  }
}

#home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner .mn-syndicate-subtitle {
  font-size: 24px;
  font-family: latolight;
  max-width: 780px;
  margin-bottom: 40px;
  line-height: 30px;
  text-align: center;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 768px) {
  #home-top-wrapper.landing-syndicates-top-wrap .syndicates-inner .mn-syndicate-subtitle {
    font-size: 18px;
  }
}

.syndicates-top-faq-link {
  color: #ffffff;
  margin: auto;
  display: block;
  margin-top: 30px;
  text-align: center;
  transition: .3s;
}

.syndicates-top-faq-link:hover {
  color: #469edc;
}

.general-link {
  float: none;
  display: inline-block;
  margin: 0;
  padding: 0 35px 0 10px;
  min-height: 25px;
  line-height: 25px;
  font-family: "latobold";
  font-size: 14px;
  text-transform: uppercase;
  color: #469edc;
  text-decoration: none;
  background-image: url("../../images/blue-button-arrow.png");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.general-link:hover {
  background-position: right 7px center;
}

.green-link {
  float: none;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  margin: 0;
  padding: 0 5px;
  color: #ffffff;
  background-color: #aad155;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.green-link:hover {
  background-color: #b4d669;
}

.dark-blue-link {
  float: none;
  display: inline-block;
  width: 100%;
  max-width: 250px;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  margin: 0;
  padding: 0 5px;
  font-family: 'latolight';
  color: #ffffff;
  background-color: #2c6892;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dark-blue-link:hover {
  background-color: #3276a6;
}

.landing-top-faq-link {
  float: left;
  color: #ffffff;
  font-family: "latoregular";
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 0 2px;
  padding: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.landing-top-faq-link:hover {
  color: #469edc;
  text-decoration: underline;
}

@media all and (max-width: 530px) {
  .landing-top-faq-link {
    display: inline-block;
    float: none;
    margin: 0;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .landing-top-faq-link {
    text-align: center;
    float: none;
  }
}

.homepage-cta-wrapper.landing-page > div {
  margin-top: 0;
}

.syndicate-hiw-blocks-wrapper.synd-landing-blocks-out {
  margin: 0;
  padding-bottom: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media all and (max-width: 650px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 0;
  }
}

@media all and (max-width: 1020px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block {
    max-width: 250px;
  }
}

@media all and (max-width: 860px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block {
    margin: 0;
  }
}

@media all and (max-width: 800px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block {
    max-width: 220px;
  }
}

@media all and (max-width: 710px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block {
    margin: 10px;
  }
}

@media all and (max-width: 510px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block {
    max-width: 300px;
    margin: 0 0 10px !important;
  }
}

@media all and (max-width: 860px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block:first-of-type {
    margin-right: 5px;
  }
}

@media all and (max-width: 860px) {
  .syndicate-hiw-blocks-wrapper.synd-landing-blocks-out .shiw-block:last-of-type {
    margin-left: 5px;
  }
}

.syndicate-top-btns-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.syndicate-top-btns-cnt .syndicate-center-blue-btn {
  width: 253px;
  height: 55px;
  background-color: #469edc;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
  margin-right: 20px;
}

.syndicate-top-btns-cnt .syndicate-center-blue-btn:hover {
  background-color: rgba(70, 158, 220, 0.9);
}

@media all and (max-width: 560px) {
  .syndicate-top-btns-cnt .syndicate-center-blue-btn {
    margin-right: 0;
    margin-top: 15px;
  }
}

.syndicate-top-btns-cnt .syndicate-center-white-btn {
  width: 253px;
  height: 55px;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
  margin-right: 20px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media all and (max-width: 560px) {
  .syndicate-top-btns-cnt .syndicate-center-white-btn {
    margin-right: 0;
    margin-top: 15px;
  }
}

.syndicate-top-btns-cnt .syndicate-center-white-btn:hover {
  background-color: #469edc;
  border: 1px solid #469edc;
  color: #ffffff;
}

.contact-syndicate.syndicate-landing {
  float: left;
  width: 100%;
  padding: 60px 0;
  background-color: #ebebeb;
}

.contact-syndicate.syndicate-landing .contact-syndicate-heading {
  float: left;
  width: 100%;
  padding: 0 5px;
  margin: 0;
  text-align: center;
  font-family: 'latobold';
  font-size: 18px;
  color: #333;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media all and (max-width: 580px) {
  .contact-syndicate.syndicate-landing .contact-syndicate-heading {
    font-size: 15px;
  }
}

.contact-syndicate.syndicate-landing .syndicate-contact-blake {
  float: left;
  width: 100%;
  padding: 30px 0 0;
  margin: 0;
  font-size: 20px;
  color: #ffffff;
}

@media all and (max-width: 580px) {
  .contact-syndicate.syndicate-landing .syndicate-contact-blake {
    font-size: 16px;
  }
}

.contact-syndicate.syndicate-landing .syndicate-contact-blake span {
  float: left;
  width: 100%;
  padding: 0px 10px 0;
  margin: 0;
  text-align: center;
  font-family: "latoregular";
  font-size: 18px;
  line-height: 150%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.contact-syndicate.syndicate-landing .syndicate-contact-blake span.bold {
  font-family: 'latobold';
  font-size: 25px;
}

@media all and (max-width: 580px) {
  .contact-syndicate.syndicate-landing .syndicate-contact-blake span.bold {
    font-size: 20px;
  }
}

.contact-syndicate.syndicate-landing .syndicate-contact-blake span.bottom {
  padding-top: 20px;
  font-size: 18px;
}

@media all and (max-width: 580px) {
  .contact-syndicate.syndicate-landing .syndicate-contact-blake span.bottom {
    font-size: 15px;
  }
}

.home-section.landing-page > div {
  max-width: 1300px;
  margin: 0 auto;
}

.what-wedo-section.landing-page p.home-section-title {
  margin: 0;
  padding: 0;
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out {
  max-width: 1300px;
  padding: 60px 0 50px;
  text-align: center;
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out > div {
  float: none;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  margin: 0 7px 10px;
  background-color: #ffffff;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media all and (max-width: 580px) {
  .what-wedo-section.landing-page > div > div.landing-featured-box-out > div {
    margin: 0 0 10px;
  }
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out > div .shiw-block {
  margin: 0;
  padding-top: 0;
  width: 100%;
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out > div .shiw-block img {
  border-radius: 50%;
  margin-top: 15px;
}

@media all and (max-width: 580px) {
  .what-wedo-section.landing-page > div > div.landing-featured-box-out > div .shiw-block {
    min-height: 320px;
  }
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out .syndicate-inner-cont {
  text-align: center;
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out .syndicate-inner-cont > a {
  float: none;
  display: inline-block;
  color: #898888;
  font-family: "latobold";
  font-size: 16px;
  margin: 0 0 15px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 135%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.what-wedo-section.landing-page > div > div.landing-featured-box-out .syndicate-inner-cont > a:hover {
  color: #5ba9e0;
}

.lp-syndicates-container .single-mysyndicates-block {
  width: 49%;
}

.lp-syndicates-container .single-mysyndicates-block:first-of-type {
  margin-top: 15px !important;
}

.lp-syndicates-container .single-mysyndicates-block:nth-child(odd) {
  margin-right: 0.5%;
}

.lp-syndicates-container .single-mysyndicates-block:nth-child(even) {
  margin-left: 0.5%;
}

@media all and (max-width: 1055px) {
  .lp-syndicates-container .single-mysyndicates-block {
    width: 100%;
  }
  .lp-syndicates-container .single-mysyndicates-block:nth-child(odd) {
    margin-right: 0px;
  }
  .lp-syndicates-container .single-mysyndicates-block:nth-child(even) {
    margin-left: 0px;
  }
}

.lp-syndicate-ia > div {
  float: left;
  margin: 0px;
  padding: 0px 15px 0px 0px;
}

.lp-syndicate-ia > div > span {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  clear: both;
}

.lp-syndicate-ia > div > p {
  float: left;
  margin: 3px 0px 0px 0px;
  padding: 0px;
  font-family: "latoregular";
  font-size: 11px;
  color: #7e7e7e;
  text-decoration: none;
  clear: both;
}

.syndicate-leads-cont {
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.syndicate-leads-cont .slc-half {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0px;
}

.syndicate-leads-cont .slc-half:first-of-type {
  padding-right: 10px;
}

.syndicate-leads-cont .slc-half:last-of-type {
  padding-left: 10px;
}

@media all and (max-width: 600px) {
  .syndicate-leads-cont .slc-half:last-of-type {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.syndicate-leads-cont .slc-half .default-info-tooltip > p {
  line-height: 15px !important;
}

.syndicate-leads-cont .slc-half .login-steps-container {
  margin-top: 0px;
  padding: 0;
}

.syndicate-leads-cont .slc-half .login-steps-container .ls-alternative-buttons p.button-description {
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 21px;
  margin-bottom: 10px;
}

.syndicate-leads-cont .slc-half .login-steps-container .ls-alternative-buttons > a {
  background-color: #6aa1c8;
  text-align: left;
  background-image: none;
  position: relative;
}

.syndicate-leads-cont .slc-half .login-steps-container .ls-alternative-buttons > a:after {
  top: 18px;
  right: 15px;
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 10px solid #ffffff;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.syndicate-leads-cont .slc-half .login-steps-container .ls-alternative-buttons > a:hover {
  background-color: #7dadcf;
}

.syndicate-leads-cont .slc-half .login-steps-container .ls-alternative-buttons:first-of-type {
  margin-top: 0px;
}

@media all and (max-width: 600px) {
  .syndicate-leads-cont .slc-half {
    width: 100%;
    padding: 0px !important;
  }
}

@media all and (max-width: 600px) {
  .syndicate-leads-cont {
    padding-top: 185px;
  }
}

.syndicate-lead-explainer {
  margin-top: 0;
}

.syndicate-lead-explainer h4 {
  margin-bottom: 25px;
  font-family: 'latobold';
  font-size: 17px;
  color: #6195b9;
}

.syndicate-lead-explainer p {
  font-size: 14px;
  line-height: 21px;
}

.syndicate-lead-explainer strong {
  margin-top: 12px;
  margin-bottom: 12px;
  display: inline-block;
  font-size: 14px;
  color: #6195b9;
}

.create-syndicate-description {
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 21px;
  margin-bottom: 20px;
}

@media (max-width: 500px) {
  .create-deal {
    display: none;
  }
}

#mo-buttons a.mo-buttons-items {
  height: 26px;
  flex-direction: row-reverse !important;
  margin-right: 10px !important;
}

@media (max-width: 480px) {
  #mo-buttons {
    display: flex;
    flex-direction: row-reverse;
  }
}
