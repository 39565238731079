/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#mn-nabo-tp-banner {
  background-image: url(/images/landing-pages/nabo-bk-img-min.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70%;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
}

@media all and (max-width: 736px) {
  #mn-nabo-tp-banner {
    height: 100%;
  }
}

#mn-nabo-tp-banner .mn-img-sm {
  width: 300px;
  margin-bottom: 20px;
}

@media all and (max-width: 736px) {
  #mn-nabo-tp-banner .mn-img-sm {
    width: 200px;
    height: auto;
  }
}

#mn-nabo-tp-banner .layer {
  background-color: rgba(1, 1, 1, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nabo-line-hight-52 {
  line-height: 52px;
}

.mn-left-ctn {
  background-color: #00AF92;
  height: 900px;
  position: relative;
}

.mn-laptop-img-cnt {
  position: absolute;
  left: 600px;
}

.mn-laptop-img-cnt .mn-laptop-img {
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}

.mn-laptop-img-cnt .mn-laptop-img .lap-top-img {
  height: 38.5625rem;
  max-width: none;
}

.mn-laptop-img-cnt .mn-laptop-img .mn-slide-img {
  position: absolute;
  top: 36px;
  width: 814px;
  left: 130px;
  pointer-events: none;
}

#mn-nabo-half-green-bk {
  background-image: url(/images/landing-pages/green-bk.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #ccc;
  position: relative;
}

@media all and (max-width: 1200px) {
  #mn-nabo-half-green-bk {
    background-image: none;
    background-color: #00AF92;
  }
}

@media screen and (min-width: 1400px) {
  #mn-nabo-half-green-bk .carousel-indicators-list {
    background-color: #8bcbc0;
    border: 1px solid #8bcbc0;
  }
}

.mn-landing-page-content-cnt {
  margin: 90px auto;
}

.nabo-form-field {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media all and (max-width: 768px) {
  .nabo-form-field {
    justify-content: center;
  }
}

.nabo-form-field #nabo-name {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
}

@media all and (max-width: 736px) {
  .nabo-form-field #nabo-name {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.nabo-form-field #nabo-offer {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
}

.nabo-form-field #nabo-email {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
}

@media all and (max-width: 736px) {
  .nabo-form-field #nabo-email {
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
}

.nabo-form-field #nabo-register {
  color: #fff;
  background-color: #00ae91;
  border-color: #00957c;
}

@media all and (max-width: 736px) {
  .nabo-form-field #nabo-register {
    border-radius: 0 4px 4px 0;
  }
}

.mn-form-center {
  justify-content: center;
}

.nabo-link {
  color: #8bcbc0;
}

.nabo-link:hover {
  color: #337ab7;
}

.nabo-text-link {
  color: #8bcbc0;
}

.nabo-text-link:hover {
  text-decoration: underline;
  color: #8bcbc0;
}

.nabo-green {
  color: #00AF92;
}
