/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#sofispritz-last-form-sec .sofispritz-last-form-sec-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#sofispritz-last-form-sec .sofispritz-last-form-sec-1, #sofispritz-last-form-sec .sofispritz-last-form-sec-3 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#sofispritz-last-form-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #sofispritz-last-form-sec {
    flex-wrap: wrap;
  }
}

#sofispritz-last-form-sec .sofispritz-last-form-sec-1 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/sofi/ss-5.jpg");
  background-size: cover;
}

@media all and (max-width: 1024px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-1 {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-1 {
    height: 180px;
  }
}

#sofispritz-last-form-sec .sofispritz-last-form-sec-2 {
  height: 400px;
  width: 32%;
  padding: 10px;
}

@media all and (max-width: 1420px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-2 {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-2 {
    width: 100%;
    order: 3;
  }
}

#sofispritz-last-form-sec .sofispritz-last-form-sec-3 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/sofi/ss-4.jpg");
}

@media all and (max-width: 1024px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-3 {
    width: 50%;
    order: 1;
  }
}

@media all and (max-width: 500px) {
  #sofispritz-last-form-sec .sofispritz-last-form-sec-3 {
    height: 180px;
  }
}
