/********************************/
/*   TIME LINE LANDING PAGE     */
/********************************/
/* Time line landing page 22.01.2016 */


.lp-header{
    width:100%;
    height:57px;
    float:left;
}

#lp-top-cont{
    width:100%;
    height:100%;
    float:left;
}
#lp-top-cont *{
    box-sizing: border-box;
}

.lp-heading{
    float:left;
    width:100%;
    text-align:center;
    position:relative;
    top:15%;
}
.lp-heading h1{
    width:100%;
    float:left;
    font-size:120px;
    font-family: 'montserratlight';
    text-align:center;
    padding:10px;
    color:#fff;
    text-indent:-9999px;
    background:url("../../images/lp-review-title.png") center center no-repeat;
}
.lp-line-cont{
    float:left;
    width:100%;
    position:relative;
    height:243px;
    top:20%;
    background:url("../../images/lp-line2015.png") center center no-repeat;
}

.lp-scroll-cont{
    float:left;
    width:100%;
    position:relative;
    height:243px;
    top:20%;
    text-align:center;
}
a.lp-scroll-btn{
    display:inline-block;
    padding:15px 20px;
    background:#45a0d8;
    font-family: 'montserratultra_light';
    font-size:20px;
    color:#fff;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
    text-decoration:none;
}
a.lp-scroll-btn:hover{
    background:#63bef6;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}

.lp-time-cont{
    float:left;
    width:100%;
    min-height:1000px;
    padding:50px 0px;
}
.lp-time-cont *{
    box-sizing: border-box;
}
.lp-time-line{
    width:100%;
    float:left;
    text-align:center;
    vertical-align:middle;
    position:relative;
    display:none;
}
.lp-time-date{
    display:inline-block;
    padding:10px 30px;
    vertical-align:middle;
    width:390px;
    color:#fff;
    font-family: 'montserratultra_light';
    font-size:27px;
    text-align:right;
}
.lp-time-icon{
    display:inline-block;
    padding:0px 30px;
    vertical-align:middle;
    width:140px;
}
.lp-time-desc{
    display:inline-block;
    padding:10px 30px;
    vertical-align:middle;
    width:390px;
    color:#fff;
    font-family: 'montserratultra_light';
    font-size:27px;
    text-align:left;
}
.lp-line-split{
    float:left;
    width:100%;
    text-align:center;
    height:50px;
    position:relative;
    top:-2px;
    display:none;
}
.lp-line-split span{
    display:inline-block;
    width:4px;
    height:56px;
    background:#fff;
}
.third-split{
    top:-16px;
}
.third-split span{
    height:68px;
}

.lp-text-two,.lp-text-three,.lp-text-four,.lp-text-five,.lp-text-six{
    display:none;
}

.lp-circles-cont{
    width:100%;
    float:left;
}
.lp-circles-cont-in{
    margin:0px auto;
    text-align:center;
    position:relative;
    width:960px;
}
.lp-total-circle{
    margin:0px auto;
    width:200px;
    height:200px;
    background:url("../../images/lp-total-circle.png") no-repeat;
    top:-3px;
    position:relative;
    display:none;
}
.lp-total-text{
    width:200px;
    height:200px;
    background:url("../../images/lp-total-text.png") no-repeat;
    top:-2px;
    left:380px;
    position:absolute;
    display:none;
}
.lp-circle-one{
    position:absolute;
    top:-30px;
    left:223px;
    width:171px;
    height:124px;
    background:url("../../images/lp-circle1.png") no-repeat;
    display:none;
}
.lp-circle-two{
    position:absolute;
    top:-30px;
    right:223px;
    width:171px;
    height:124px;
    background:url("../../images/lp-circle2.png") no-repeat;
    display:none;
}
.lp-circle-three{
    position:absolute;
    top:110px;
    left:240px;
    width:161px;
    height:121px;
    background:url("../../images/lp-circle3.png") no-repeat;
    display:none;
}
.lp-circle-four{
    position:absolute;
    top:110px;
    right:240px;
    width:161px;
    height:121px;
    background:url("../../images/lp-circle4.png") no-repeat;
    display:none;
}

.lp-subscribe-cont{
    float:left;
    width:100%;
    text-align:center;
    padding:50px 0px;
}
.lp-subscribe-cont span{
    color: #fff;
    display: inline-block;
    font-family: "montserratultra_light";
    font-size: 27px;
    padding: 30px 0px;
    text-align: left;
    vertical-align: middle;
    width: 960px;
    border-top:1px solid #fff;
    text-align:center;
}
.lp-inputs-cont{
    float:left;
    width:100%;
    text-align:center;
    padding:0px 0px;
}
.lp-email-field{
    border: 3px solid #898888;
    box-sizing: border-box;
    color: #898888;
    display: inline-block;
    font-family: "Montserrat";
    font-size: 16px;
    height: 50px;
    margin: 0px 30px 0px 0px;
    max-width: 425px;
    width: 370px;
    padding:10px;
    float:none;
}
.lp-submit-btn{
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: #7cbee5 url("../../images/check-icon.png") no-repeat scroll 96% 50%;
    border-color: -moz-use-text-color -moz-use-text-color #397ba2;
    border-image: none;
    border-style: none none solid;
    border-width: medium medium 1px;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-family: "montserratbold";
    font-size: 17px;
    text-align: left;
    width: 200px;
    height: 50px;
    display:inline-block;
    float:none!important;
    margin:0px!important;
}
.lp-submit-btn:hover{
    opacity:0.8;
}
.inv-type-select{
    display:inline-block;
    width:100%;
    padding:0px 0px 20px;
}
.inv-type-select label{
    display:inline-block;
    font-family: "montserratultra_light";
    font-size: 18px;
    padding: 15px 26px 15px 0px;
    text-align: center;
    color:#ffffff;

}
#lp-invetsor-type{
    padding:5px;
    margin-right:0px;
    display:inline-block;
    width: 200px;
}
.error-invtype-select{
    display:inline-block;
    font-family: "montserratultra_light";
    font-size: 18px;
    padding: 15px 26px 15px 0px;
    text-align: center;
    color:red;
    width:100%;
}
.timeline-invtype-link{
    color: #ffffff;
}
.timeline-invtype-info{
    position:relative;
}
.timeline-invtype-info span.tooltip
{
    background-color: #36a6bb;
    border-radius: 5px;
    bottom: 45px;
    color: #ffffff;
    font-family: "open_sansregular";
    font-size: 11px;
    left: -20px;
    line-height: 1.2;
    opacity: 0;
    position: absolute;
    text-align: justify;
    visibility: hidden;
    width: 305px;
    z-index: 1;
    padding:10px;
    border-top:0px;
    visibility:hidden;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}
.timeline-invtype-info img{
    bottom: -6px;
    position: absolute;
}

@media only screen
and (min-device-width : 581px)
and (max-device-width : 768px)  {
    .promo-lp-wrap{
        min-height:900px;

    }
}
@media only screen
and (min-device-width : 320px)
and (max-device-width : 580px)  {
    .promo-lp-wrap{
        min-height:1000px;

    }
}