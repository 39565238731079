@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
OFFER INVEST
---------------------------------------------------------- */
.oi-top-navigation {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    > ul {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > li {
            float: left;
            width: 33.33333333%;
            margin: 0px;
            padding: 0px 2px 0px 0px;
            list-style: none;
            &:first-of-type > a:before {
                border: none;
            }
            &:last-of-type > a:after {
                border: none;
            }
            > a {
                float: left;
                width: 100%;
                min-height: 40px;
                margin: 0px;
                padding: 10px 30px;
                font-family: 'latobold';
                font-size: 16px;
                color: #bdc7cc;
                text-decoration: none;
                @include transition(.3s);
                background-color: #ffffff;
                position: relative;
                &:hover {
                    cursor: pointer;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;

                    width: 0px;
                    height: 0px;

                    border-left: 15px solid #F3F5F6;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;

                    @include transition(.3s);

                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: -16px;

                    width: 0px;
                    height: 0px;

                    border-left: 16px solid #ffffff;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;

                    @include transition(.3s);

                    z-index: 3;
                }
                &.current {
                    color: #ffffff;
                    background-color: $default-blue-color;
                    &:after {
                        border-left-color: $default-blue-color;
                    }
                }
                &.completed {
                    color: #ffffff;
                    background-color: #b5c9d7;
                    &:after {
                        border-left-color: #b5c9d7;
                    }
                }
                @include mquery(1180px) {
                    padding: 11px 15px;
                    font-size: 14px;
                    &:before, &:after {
                        content: none;
                    }
                }
                &.completed-tab:hover {
                    cursor: default;
                }
            }
            @include mquery(960px) {
                width: 50%;
                margin-bottom: 1px;
            }
            @include mquery(500px) {
                width: 100%;
            }
        }
    }
}

/* Offer invest form container */
.oi-form-container {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    .oi-form-step-cont {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        &.oi-step-three {
            background-color: #ffffff;
            padding: 30px 20px;
            margin-top: 30px;
        }
        .oi-form-left {
            float: left;
            width: 50%;
            margin: 25px 0px 0px 0px;
            padding: 0px 15px 0px 0px;
        }
        .oi-form-right {
            float: left;
            width: 50%;
            margin: 25px 0px 0px 0px;
            padding: 0px 0px 0px 15px;
        }
        @include mquery(890px) {
            .oi-form-left, .oi-form-right {
                width: 100%;
                padding: 0px;
            }
        }
    }
}
.oi-thanks-text {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px;
    text-align: center;
    font-family: 'latobold';
    font-size: 26px;
    color: #7e7e7e;
}
.oi-thanks-subtext {
    float: left;
    width: 100%;
    margin: 20px 0px 20px 0px;
    padding: 0px;
    text-align: center;
    font-family: 'latobold';
    font-size: 18px;
    color: #7e7e7e;
}
.oi-thanks-links {
    float: left;
    width: 100%;
    margin: 25px 0px 0px 0px;
    padding: 0px;
}
.oi-thanks-links > li {
    float: left;
    width: 20%;
    margin: 0px;
    padding: 0px 0px 0px 1px;
    list-style: none;
    @include mquery(670px) {
        width: 100%;
        padding: 0px 0px 1px 0px;
    }
    &:first-of-type {
        padding-left: 0px;
    }
}
.oi-thanks-links > li > a {
    float: left;
    width: 100%;
    min-height: 40px;
    margin: 0px;
    padding: 11px 15px;
    text-decoration: none;
    background-color: #e1e1e1;
    font-family: 'latoblack';
    font-size: 15px;
    color: #ffffff;
    background-repeat: no-repeat;
    background-position: right 10px center;
    @include transition(.3s);
    &.oi-tl-fb {
        background-color: #3b5998;
        background-image: url(../../images/oi-thanks-fb-icon.png);
        &:hover {
            background-color: lighten(#3b5998, 10%);
        }
    }
    &.oi-tl-twitter {
        background-color: #33ccff;
        background-image: url(../../images/oi-thanks-twitter-icon.png);
        &:hover {
            background-color: lighten(#33ccff, 10%);
        }
    }
    &.oi-tl-linkedin {
        background-color: #0077b5;
        background-image: url(../../images/oi-thanks-linkedin-icon.png);
        &:hover {
            background-color: lighten(#0077b5, 10%);
        }
    }
    &.oi-tl-google {
        background-color: #dd4b39;
        background-image: url(../../images/oi-thanks-google-icon.png);
        &:hover {
            background-color: lighten(#dd4b39, 10%);
        }
    }
    &.oi-tl-email {
        background-color: #c6936a;
        background-image: url(../../images/oi-thanks-email-icon.png);
        &:hover {
            background-color: lighten(#c6936a, 10%);
        }
    }
}
.oi-form-block {
    float: left;
    width: 100%;
    margin: 25px 0px 0px 0px;
    padding: 30px 20px;
    background-color: #ffffff;
    &:first-of-type {
        margin-top: 0px;
    }
    .oi-form-block-form-fields {
        float: left;
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0px;
        .settings-form-group .sfg-field {
            padding-left: 0px;
            max-width: 395px;
        }
        .error-tooltip{
            z-index: 9999;
        }
    }
}
.download-ia-button {
    float: left;
    margin: 20px 0px 0px 0px;
    padding: 0px 55px 1px 10px;
    min-height: 40px;
    font-family: 'latoregular';
    font-size: 14px;
    color: $default-submitBtn-color;
    cursor: pointer;
    background-image: url(../../images/download-ia-button-icon.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-color: $default-blue-color;
    @include transition(.3s);
    text-transform: uppercase;
    border: 0px;
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
    /* Media queries */
    @include mquery(375px) {
        font-size: 12px;
    }
    @include mquery(330px) {
        font-family: 'latobold';
        font-size: 11px;
    }
}
.oi-id-groups {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    .oi-id-group {
        float: left;
        width: 100%;
        min-height: 46px;
        margin: 0px;
        padding: 15px 0px 14px;
        @include border(bottom, 1px, solid, #cad7de);
        &:last-of-type {
            border-bottom-width: 0px;
        }
        > span {
            float: left;
            width: 30%;
            margin: 0px;
            padding: 0px;
            font-family: 'latoregular';
            font-size: 14px;
            color: #7e7e7e;
        }
        > p {
            float: left;
            width: 70%;
            margin: 0px;
            padding: 0px;
            font-family: 'latoblack';
            font-size: 16px;
            color: $default-blue-color;
            text-align: right;
        }
    }
}
.oi-step-two-fields .form-group {
    padding: 10px;
}
.oi-step-two-fields .form-group .form-group-title {
    float: left;
    position: relative;
    max-width: 100%;
    top: auto;
    left: auto;
    padding: 0px;
}
.oi-account-number-field {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px 10px 0px 0px;
    position: relative;
}
.oi-account-number-field.first {
    max-width: 15%;
}
.oi-account-number-field.second {
    max-width: 100px;
}
.oi-account-number-field.third {
    max-width: 35%;
}
.oi-account-number-field.fourth {
    max-width: 30%;
    padding-right: 0px;
}
.form-submit-action {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    border-top-width: 1px;
    border-style: solid;
    border-color: #e0e3e5;

    .disabled {
        background-color: grey;

        &:hover {
            cursor: not-allowed;
        }
    }
}
.oi-multiple-fields {
    padding-left: 140px !important;
    .sfg-title {
        left: 0px;
        @include mquery(580px) {
            position: absolute !important;
            top: 10px;
            left: 5px;
        }
    }
    .sfg-field {
        max-width: 100% !important;

    }
    @include mquery(400px) {
        padding-left: 0px !important;
        .sfg-title {
            position: relative !important;
            left: auto;
            top: auto;
        }
        .sfg-field {
            padding-left: 10px !important;
            &.first {
                margin-top: 0px;
            }
        }
    }
}
