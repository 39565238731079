@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
PROGRESS BAR STYLE
---------------------------------------------------------- */
.offer-progress-bar {
    display: block;
    float: left;
    width: 100%;
    height: 20px;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    background-color: $od-progresbar-bg;
    overflow: hidden;
    > .proggress-loading {
        display: block;
        float: left;
        width: 0;
        height: 20px;
        margin: 0px;
        padding: 0px;
        background: $od-progresbar;
        color: $od-progresbar-txt-lightcolor;
        font-family: 'latoregular';
        font-size: 12px;
        line-height: 20px;
        text-align: center;
    }
}
