@import 'extensions/variables';
@import 'extensions/mixins';


.offer-info-sec {
  background-color: white;
  margin-top: 40px;
  .offer-img {
    width: 100%;
    object-fit:cover;
    display: block;
    border: 1px solid #ebebeb;
    padding: 20px;
    margin-bottom: 30px;
    @include mquery(767px) {
      width: 300px;
      margin-right: auto;
      margin-left: auto;

    }
    @include mquery(320px) {
      width: 100%;
    }
  }

  .offer-info {
    font-size: 18px;
    color: #7e7e7e;
    padding: 70px;

    .offer-info-detail {
      margin-bottom: 10px;
    }
  }

  .table-top-margin {
    margin-top: 159px;
    @include mquery(1024px) {
      margin-top: 34px;
    }
  }

  .blue-thead {
    background-color: #469edc;
    color: white;
  }

  .offer-check-deal-btn {
    color: #333;
    padding: 10px;
    border: 1px solid #333;
    transition: .3s;
    margin: 20px auto;
  }

  .offer-check-deal-btn:hover {
    background-color: #469edc;
    border: 1px solid #469edc;
    color: #fff;
  }

  .offer-name {
    border-top: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    padding: 10px;
    background-color: #f5f5f5;
    @include mquery(767px) {
      width: 300px;
      margin: auto;

    }
    @include mquery(320px) {
      width: 100%;
    }

  }

  .nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    margin: 4px;
    &:hover {
      background-color: #ebebeb;
    }
  }

  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #fff;
    cursor: default;
    background-color: #469edc;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    margin: 4px;

  }

  .btn-blue {
    color: #fff;
    background-color: #469edc;
    border-color: #469edc;
  }

  .nav-tabs>li {
    @include mquery(727px) {
      float: left;
      width: 50%;

    }

  }

}

.tab-content-style {
  padding-top: 50px;
  padding-bottom: 50px;
  border: 1px solid #ddd;
}

.filter-group-style{
  text-align: center;
  border: 1px solid #333;
  padding: 10px;
}

.btn-group:hover .dropdown-menu {
  display: block;
}

.btn-group-flex-center {
  margin-bottom: 50px;
  @include mquery(500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

  }
}

.dropdown-menu li{
  padding: 5px;
  cursor: pointer;
}

.graphCanvas{
  margin: 10px auto 80px auto;
  height: 400px;
}

.graphCanvas canvas{
  width: 100% !important;
}

.offer-flex-row {
 display: flex;
 align-items: center;
 justify-content: center;
 margin-bottom: 50px;
 @include mquery(767px) {
   flex-direction: column;
 }
}

.dailyandcomulative-button {
  text-align: center;
  float:right;
  @include mquery(767px) {
    float: none;
  }
}

.viewOffer-button {
  @include mquery(767px) {
    text-align: center;
  }
}



.offer-tab {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;

}

.mn-btn-responsive-xs-100 {
  @include mquery(500px) {
    min-width: 100%;
    border-radius: 4px !important;
    margin-bottom: 10px;
  }

}

.mn-push-xs {
  @include mquery(320px) {
    display: block;
  }
}


.mn-table-striped>tbody>tr:nth-child(odd)>td,
.mn-table-striped>tbody>tr:nth-child(odd)>th {
   background-color: #efefef; // Choose your own color here
 }


 .mn-table-striped>tbody>tr>td {
   text-align: center;
 }

 .mn-table-striped>thead>tr>th {
   text-align: center;
 }



// shareholder-report dashboard start ///////////

 .shareholder-report-main-cnt {
   margin-top: 20px;
   padding: 20px;
   background: white;

 }

 .shareholder-log-title {
    font-family: 'latobold';
    font-size: 20px;
    color: #7e7e7e !important;
    text-transform: uppercase;
    text-align: center;
 }

 .box-justify-center {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   max-width: 910px;
   margin: auto;
 }


    .mn-box {
      max-width: 283px;
      height: 133px;
      border: 1px solid #ddd;
      margin: 10px 10px 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mquery(768px) {
        width: 200px;
        height: 200px;
      }
      .mn-box-header {
        font-size: 28px;
        font-family: latobold;
        color: #7e7e7e;
        margin-bottom: 20px;
        margin-top: 30px;
      }
    }


    .graph-back {
      background: #F5F5F5;
      padding: 20px;
    }

    .text-commentary {
      width: 100%;
      background: #F5F5F5;
      margin-top: 20px;
      margin-bottom: 20px;

      .text-commentary-header {
        font-size: 16px;
        color: #7e7e7e;
        padding: 20px;
        line-height: 1.3rem;
      }
    }





 // shareholder-report dashboard end ///////////
