/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#ms-top-banner {
  position: relative;
  height: calc(100vh - 70px);
  width: 100%;
}

@media all and (max-width: 700px) {
  #ms-top-banner {
    height: 610px;
  }
}

#ms-top-banner .ms-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
}

@media all and (max-width: 700px) {
  #ms-top-banner .ms-banner {
    height: 610px;
  }
}

@media all and (max-width: 600px) {
  #ms-top-banner .ms-banner {
    background-position: right;
  }
}

#ms-top-banner .ms-logo {
  margin-bottom: 50px;
}

@media all and (max-width: 500px) {
  #ms-top-banner .ms-logo {
    width: 200px;
  }
}

#ms-top-banner .ms-carousel-control {
  width: 6% !important;
}

#ms-top-banner .ms-banner-one {
  background-image: url(/images/landing-pages/makerandson-banner-2.jpg);
}

@media all and (max-width: 600px) {
  #ms-top-banner .ms-banner-one {
    background-image: url(/images/landing-pages/makerandson-banner-2-mobile.jpg);
  }
}

#ms-top-banner .ms-banner-two {
  background-image: url(/images/landing-pages/makerandson-banner-5.jpg);
}

@media all and (max-width: 1024px) {
  #ms-top-banner .ms-banner-two {
    background-image: url(/images/landing-pages/makerandson-banner.jpg);
  }
}

@media all and (max-width: 600px) {
  #ms-top-banner .ms-banner-two {
    background-image: url(/images/landing-pages/makerandson-banner-mobile.jpg);
  }
}

#ms-top-banner .ms-banner-three {
  background-image: url(/images/landing-pages/makerandson-banner-3.jpg);
}

@media all and (max-width: 600px) {
  #ms-top-banner .ms-banner-three {
    background-image: url(/images/landing-pages/makerandson-banner-3-mobile.jpg);
  }
}

#ms-top-banner .ms-top-content-cnt {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: calc(100vh - 70px);
  padding-top: 150px;
  background-color: rgba(1, 1, 1, 0.5);
  padding-right: 15px;
  padding-left: 15px;
}

@media all and (max-width: 1024px) {
  #ms-top-banner .ms-top-content-cnt {
    width: 510px;
    padding-top: 50px;
  }
}

@media all and (max-width: 730px) {
  #ms-top-banner .ms-top-content-cnt {
    width: 100%;
  }
}

@media all and (max-width: 700px) {
  #ms-top-banner .ms-top-content-cnt {
    height: 610px;
  }
}

#ms-top-banner .ms-title-width {
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}

@media all and (max-width: 1650px) {
  #ms-top-banner .ms-title-width {
    max-width: 520px;
  }
}

#ms-top-banner .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

#ms-top-banner .carousel-fade .carousel-inner .active {
  opacity: 1;
}

#ms-top-banner .carousel-fade .carousel-inner .active.left,
#ms-top-banner .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

#ms-top-banner .carousel-fade .carousel-inner .next.left,
#ms-top-banner .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

#ms-top-banner .carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  #ms-top-banner .carousel-fade .carousel-inner > .item.next,
  #ms-top-banner .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #ms-top-banner .carousel-fade .carousel-inner > .item.prev,
  #ms-top-banner .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #ms-top-banner .carousel-fade .carousel-inner > .item.next.left,
  #ms-top-banner .carousel-fade .carousel-inner > .item.prev.right,
  #ms-top-banner .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

#ms-pro-banner .product-header {
  color: #171717;
  margin-bottom: 60px;
}

@media all and (max-width: 768px) {
  #ms-pro-banner .product-header {
    margin-bottom: 20px;
  }
}

.ms-first-content {
  padding: 40px;
}

@media all and (max-width: 1200px) {
  .ms-first-content {
    padding: 40px 40px 0 40px;
  }
}

.ms-second-content {
  padding: 40px;
}

@media all and (max-width: 1200px) {
  .ms-second-content {
    padding: 0 40px 40px 40px;
  }
}

.ms-equal-cnt-sec {
  display: flex;
  width: 100%;
}

@media all and (max-width: 1200px) {
  .ms-equal-cnt-sec {
    flex-direction: column;
  }
}

.ms-half-banner {
  background-image: url(/images/landing-pages/armchair-with-footstool.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media all and (max-width: 1200px) {
  .ms-half-banner {
    height: 424px;
  }
}

.ms-second-half-banner {
  background-image: url(/images/landing-pages/makerandson-half-banner.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media all and (max-width: 1200px) {
  .ms-second-half-banner {
    height: 424px;
  }
}

.no-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ms-button-style {
  background-color: #469edc;
  border: 1px solid #469edc;
}

.ms-view-offer-btn {
  border: 1px solid #469edc;
  color: white;
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #469edc;
  animation: bounce 3s;
}

.ms-video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background-color: black;
}

@media all and (max-width: 1024px) {
  .ms-video-iframe {
    height: 100%;
  }
}
