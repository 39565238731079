/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#goodments-top-banner .goodments-logo-cnt, #goodments-quote {
  display: flex;
  align-items: center;
  justify-content: center;
}

#goodments-top-banner {
  background-image: url(/images/landing-pages/banners/goodments-img_header.jpg);
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  display: table;
}

@media only screen and (min-width: 2000px) {
  #goodments-top-banner {
    background-image: url(/images/landing-pages/banners/goodments-img_header@2x.png);
  }
}

@media all and (max-width: 768px) {
  #goodments-top-banner {
    background-size: contain;
    background-image: url(/images/landing-pages/banners/goodments-header_mobile@3x.png);
  }
}

#goodments-top-banner .goodments-logo-cnt {
  width: 100%;
  display: none;
}

@media all and (max-width: 768px) {
  #goodments-top-banner .goodments-logo-cnt {
    display: block;
  }
}

#goodments-top-banner .goodments-logo-cnt .goodments-logo {
  margin-bottom: 0;
  width: 400px;
}

@media all and (max-width: 500px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    width: 250px;
  }
}

@media all and (max-width: 768px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    margin-top: 275px;
    margin-bottom: 40px;
    width: 300px;
  }
}

@media all and (max-width: 600px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    margin-top: 240px;
    margin-bottom: 20px;
    width: 250px;
  }
}

@media all and (max-width: 500px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    margin-top: 210px;
  }
}

@media all and (max-width: 400px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    margin-top: 180px;
  }
}

@media all and (max-width: 320px) {
  #goodments-top-banner .goodments-logo-cnt .goodments-logo {
    margin-top: 150px;
    width: 200px;
  }
}

#goodments-top-banner .goodments-register-cnt {
  margin-top: 250px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1800px) {
  #goodments-top-banner .goodments-register-cnt {
    margin-top: 260px;
  }
}

@media only screen and (min-width: 2000px) {
  #goodments-top-banner .goodments-register-cnt {
    margin-top: 310px;
  }
}

@media only screen and (min-width: 2400px) {
  #goodments-top-banner .goodments-register-cnt {
    margin-top: 350px;
  }
}

@media only screen and (min-width: 2700px) {
  #goodments-top-banner .goodments-register-cnt {
    margin-top: 380px;
  }
}

@media all and (max-width: 768px) {
  #goodments-top-banner .goodments-register-cnt {
    margin-top: 20px;
    position: relative;
  }
}

#goodments-top-banner .goodments-form-header {
  color: #3E61DF;
  font-size: 40px;
  max-width: 600px;
  margin-bottom: 40px;
  line-height: 1.2;
  letter-spacing: -1.14px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-top-banner .goodments-form-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-top-banner .goodments-form-header {
    font-size: 26px;
    margin-top: 0;
  }
}

#goodments-top-banner .goodments-form-subheader {
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.5;
  font-size: 18px;
}

#goodments-top-banner .goodments-form-register-text {
  font-size: 24px;
  letter-spacing: -0.5px;
}

#goodments-top-banner .goodments-form-filed-cnt {
  max-width: 849px;
  padding: 40px;
  background-color: #f8f8f8;
}

#goodments-top-banner .goodments-form-name {
  padding: 10px;
  margin-right: 20px;
  height: 50px;
  border: none;
  margin-bottom: 25px;
  width: 48%;
  border: 1px solid #bdbdbd;
}

#goodments-top-banner .goodments-form-name::placeholder {
  color: #D9D9D9;
}

@media all and (max-width: 720px) {
  #goodments-top-banner .goodments-form-name {
    width: 100%;
    margin-right: 0;
  }
}

#goodments-top-banner .goodments-form-email {
  padding: 10px;
  font-weight: 400;
  height: 50px;
  border: none;
  margin-bottom: 25px;
  width: 48%;
  border: 1px solid #bdbdbd;
}

#goodments-top-banner .goodments-form-email::placeholder {
  color: #D9D9D9;
}

@media all and (max-width: 720px) {
  #goodments-top-banner .goodments-form-email {
    width: 100%;
  }
}

#goodments-top-banner .goodments-form-button {
  width: 280px;
  color: white;
  border-radius: 34px;
  height: 50px;
  background-color: #32DB9A;
  border: 1px solid #32DB9A;
  transition: .5s;
  font-size: 20px;
  letter-spacing: -0.56px;
}

#goodments-top-banner .goodments-form-button:hover {
  background-color: #fff;
  color: #32DB9A;
  border: 1px solid #32DB9A;
}

@media all and (max-width: 720px) {
  #goodments-top-banner .goodments-form-button {
    width: 100%;
  }
}

#goodments-mission-sec {
  height: 424px;
  background-color: #3E61DF;
  position: relative;
}

#goodments-mission-sec .goodments-mission-title {
  font-size: 40px;
  color: #fff;
  margin-bottom: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-mission-sec .goodments-mission-title {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-mission-sec .goodments-mission-title {
    font-size: 26px;
  }
}

#goodments-mission-sec .goodments-mission-subtitle {
  font-size: 40px;
  color: #fff;
  font-size: 28px;
  max-width: 511px;
  line-height: 1.3;
}

@media all and (max-width: 768px) {
  #goodments-mission-sec .goodments-mission-subtitle {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-mission-sec .goodments-mission-subtitle {
    font-size: 26px;
  }
}

#goodments-mission-sec .goodments-iphone-cnt {
  height: 424px;
  width: 40%;
}

@media all and (max-width: 768px) {
  #goodments-mission-sec .goodments-iphone-cnt {
    width: 100%;
  }
}

#goodments-mission-sec .goodments-hand-icon {
  background-image: url(/images/landing-pages/icons/goodments_ic_rockHand.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  height: 315px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 1000px) {
  #goodments-mission-sec .goodments-hand-icon {
    height: 424px;
    background-position: center;
  }
}

#goodments-mission-sec .goodments-iphone {
  position: absolute;
  bottom: 0;
}

@media all and (max-width: 768px) {
  #goodments-mission-sec .goodments-iphone {
    right: 0;
  }
}

#goodments-video-sec {
  background-color: #000;
  width: 100%;
}

#goodments-video-sec .goodments-iframe-cnt {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

#goodments-video-sec .goodments-iframe-cnt .goodments-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#goodments-about-sec {
  padding: 0 0 60px;
}

#goodments-about-sec .goodments-about-header {
  color: #CDB3F1;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 1200px) {
  #goodments-about-sec .goodments-about-header {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  #goodments-about-sec .goodments-about-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-about-sec .goodments-about-header {
    font-size: 26px;
  }
}

#goodments-about-sec .goodments-about-copy {
  color: #4B4B4B;
  font-size: 18px;
  letter-spacing: -0.41px;
  line-height: 1.5;
  margin-bottom: 65px;
}

#goodments-about-sec .goodments-impact-so-far-cnt {
  border-radius: 8px;
  background-color: rgba(205, 179, 241, 0.7);
  padding: 44px;
}

#goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-header {
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 42px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-header {
    font-size: 26px;
  }
}

#goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-icon {
  margin-bottom: 20px;
}

#goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-copy {
  letter-spacing: -0.41px;
  line-height: 1.4;
  color: #2E3074;
}

@media all and (max-width: 1200px) {
  #goodments-about-sec .goodments-impact-so-far-cnt .goodments-impact-copy {
    margin-bottom: 40px;
  }
}

#goodments-sustainable-means {
  padding: 120px 0;
}

@media all and (max-width: 600px) {
  #goodments-sustainable-means {
    padding: 60px 0;
  }
}

#goodments-sustainable-means .goodments-ustainable-means-header {
  color: #CDB3F1;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-sustainable-means .goodments-ustainable-means-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-sustainable-means .goodments-ustainable-means-header {
    font-size: 26px;
  }
}

#goodments-sustainable-means .goodments-sustainable-means-subheader {
  color: #4B4B4B;
  font-size: 21px;
  letter-spacing: -0.48px;
  line-height: 1.5;
  margin-bottom: 50px;
  max-width: 639px;
}

#goodments-sustainable-means .goodments-sustainable-means-circle {
  width: 220px;
  height: 220px;
  border-radius: 100%;
  margin: auto;
  padding: 30px;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-header-cnt {
  padding: 0 23px;
  position: relative;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-orange-circle-header-cnt {
  padding: 0 3px;
  position: relative;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-header {
  font-size: 80px;
  letter-spacing: -2.05px;
  line-height: 109px;
  color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-header-dolor {
  color: #fff;
  font-size: 30px;
  font-family: latobold;
  position: absolute;
  top: 16px;
  left: -10px;
  font-family: 'Noto Sans JP', sans-serif;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-header-percent {
  color: #fff;
  font-size: 30px;
  font-family: latobold;
  position: absolute;
  top: 16px;
  font-family: 'Noto Sans JP', sans-serif;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-header-orange {
  font-size: 70px;
  letter-spacing: -1.82px;
  line-height: 109px;
  color: #fff;
  font-family: 'Noto Sans JP', sans-serif;
}

#goodments-sustainable-means .goodments-sustainable-means-circle .goodments-sustainable-means-circle-subheader {
  font-size: 15px;
  letter-spacing: -0.34px;
  line-height: 1.1;
  color: #2E3074;
  text-align: center;
}

#goodments-sustainable-means .how-goodments-track {
  background-color: #F8F8F8;
  padding: 30px;
  background-image: url(/images/landing-pages/icons/goodments-ic_rocket.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 328px;
  margin-top: 80px;
}

@media all and (max-width: 768px) {
  #goodments-sustainable-means .how-goodments-track {
    background-size: cover;
    height: 100%;
  }
}

#goodments-sustainable-means .how-goodments-track .how-goodments-track-top-header {
  color: #CDB3F1;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 40px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-sustainable-means .how-goodments-track .how-goodments-track-top-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-sustainable-means .how-goodments-track .how-goodments-track-top-header {
    font-size: 26px;
  }
}

#goodments-sustainable-means .how-goodments-track .how-goodments-track-header {
  color: #3E61DF;
  font-size: 60px;
  letter-spacing: -1.82px;
}

#goodments-sustainable-means .how-goodments-track .how-goodments-track-subheader {
  color: #4B4B4B;
  font-size: 21px;
  letter-spacing: -0.48px;
  line-height: 1.5;
}

@media all and (max-width: 767px) {
  #goodments-sustainable-means .how-goodments-track .how-goodments-track-subheader {
    margin-bottom: 20px;
  }
}

#goodments-sustainable-means .goodments-since-nov-text {
  color: #BDBDBD;
  font-size: 15px;
  letter-spacing: -0.34px;
  line-height: 1.5;
  text-align: center;
  text-align: center;
  margin-top: 89px;
}

#goodments-sustainable-means .goodments-sustainable-source-txt {
  color: #959595;
  letter-spacing: -0.23px;
  font-size: 10px;
  line-height: 12px;
  margin-top: 10px;
}

@media all and (max-width: 768px) {
  #goodments-sustainable-means .goodments-sustainable-source-txt {
    margin-bottom: 40px;
  }
}

#goodments-why-raise {
  background-color: #32DB9A;
  height: 430px;
  position: relative;
}

@media all and (max-width: 1200px) {
  #goodments-why-raise {
    height: initial;
    padding: 60px 0;
  }
}

#goodments-why-raise .goodments-why-raise-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1200px) {
  #goodments-why-raise .goodments-why-raise-align-center {
    flex-direction: column;
  }
}

@media all and (max-width: 1200px) {
  #goodments-why-raise .goodments-lululemon-iphone {
    margin-bottom: 40px;
  }
}

#goodments-why-raise .goodments-why-raise-header {
  font-size: 35px;
  letter-spacing: -0.8px;
  color: #fff;
  margin-bottom: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-why-raise .goodments-why-raise-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-why-raise .goodments-why-raise-header {
    font-size: 26px;
  }
}

#goodments-why-raise .goodments-why-raise-subheader {
  font-size: 21px;
  letter-spacing: -0.8px;
  color: #fff;
  line-height: 1.5;
  margin: auto;
  max-width: 639px;
}

@media all and (max-width: 1024px) {
  #goodments-why-raise .goodments-why-raise-subheader {
    margin: 0 auto 40px;
  }
}

#goodments-why-raise .goodments-partners-img {
  padding: 4px;
}

#goodments-quote {
  padding: 120px 0;
  background-color: #CDB3F1;
}

@media all and (max-width: 1200px) {
  #goodments-quote {
    height: initial;
    padding: 60px 0;
  }
}

#goodments-quote .goodments-quote-header {
  max-width: 1016px;
  margin: auto;
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

@media all and (max-width: 768px) {
  #goodments-quote .goodments-quote-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #goodments-quote .goodments-quote-header {
    font-size: 26px;
  }
}

#goodments-quote .goodments-quote-subheader {
  margin: auto;
  color: #fff;
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 1.2;
}

.goodments-link {
  color: #3E61DF;
}

.goodments-link:hover {
  text-decoration: underline;
  color: #3E61DF;
}

.goodments-blue {
  color: #3E61DF;
}

.goodments-purple {
  background-color: #CDB3F1;
}

.goodments-orange {
  background-color: #FFA377;
}

.goodments-green {
  background-color: #A7D9C5;
}
