@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';



#credi-top-banner {
  // @include top-banner('/images/landing-pages/banners/credi_loan_agreement.png', #2bbbed, '/images/landing-pages/banners/credi_loan_agreement-sm.jpg');
  // @extend %background-property;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  @include mquery(1440px) {
    height: initial;
    padding: 60px 0;
  }
  @include mquery(768px) {
    padding: 20px 0;
  }
  #credi-top-banner-layer {
    @extend %flex-center;
    height: calc(100vh - 70px);
    @include mquery(1440px) {
      height: initial;
    }
    @include mquery(1280px) {
      display: block;
    }
    .credi-logo {
      @include logo-style(40px, 300px, 250px);
      @include mquery(1440px) {
        width: 200px;
        padding-top: 20px;
        margin-bottom: 40px;
      }
      @include mquery(600px) {
        width: 150px;
      }
    }
  }

  .credi-title-width {
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
    @include mquery(767px) {
      max-width: 430px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .credi-top-banner-content {
    z-index: 10;
    padding: 0 15px;
    @include mquery(1440px) {
      max-width: 650px;
      margin: auto;
    }
  }

  .credi-subtitle-width {
    max-width: 630px;
    margin: 0 auto 60px;
  }
  .credi-top-banner-left-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
  }
  .credi-top-banner-right-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
  }
  .input-border-credi-grey {
    border: 1px solid #ccc;
  }
}

#credi-real-different-sec {
  background-color: #25ade4;
  padding: 60px 0;
  .credi-real-different-header {
    font-size: 40px;
    margin-bottom: 40px;
    @include mquery(768px) {
      font-size: 32px;
    }
  }
}

.credi-icon {
  margin-bottom: 20px;
}

.credi-icon-margin {
  @include mquery(1024px) {
    margin-bottom: 20px;
  }
}

#credi-mission-sec {
  background-color: #ff9e1d;
  padding: 60px 0;
  .credi-mission-img {
    width: 200px;
    @include mquery(767px) {
      display: block;
      margin: 0 auto 40px;
      width: 150px;
    }
  }
}

#credi-support-sec {
  background-color: #ff9e1d;
  padding: 60px 0;
  .credi-support-img{
    float: right;
    width: 400px;
    @include mquery(767px) {
      display: block;
      margin: 0 auto 40px;
      float: none;
      width:180px;
    }
  }
  .credi-change-order {
    @include mquery(766px) {
      display: flex;
      flex-direction: column;
    }
    .credi-change-order-0 {
      @include mquery(766px) {
       order: 1;
      }
    }
    .credi-change-order-1 {
      @include mquery(766px) {
       order: 0;
      }
    }
  }
}

.credi-sec-header {
  font-size: 40px;
  margin-bottom: 40px;
  @include mquery(1024px) {
    text-align: center;
  }
  @include mquery(768px) {
    font-size: 32px;
  }
}
.credi-blue {
  color: #25ade4;
}

#credi-market-size-sec {
  padding: 60px 0;
  .credi-market-size-img {
    @include mquery(1024px) {
      width: 100%;
    }
  }
}

#credi-story-sec {
  padding: 60px 0;
  .credi-story-bk {
    background: url(/images/landing-pages/banners/Credi_Logo_Asset-story.png) no-repeat center;
    background-size: contain;
    height: 360px;
  }
}

#credi-two-year-plan-sec {
  padding: 60px 0;
  background-color: #25ade4;
  .credi-triangle-down {
    margin: 40px auto;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 50px solid #fff;
  }
}


.credi-video {
  width: 100%;
  height: 304px;
  @include mquery(1200px) {
    height: 400px;
  }
  @include mquery(600px) {
    height: 200px;
  }
}

#credi-addressable-market-sec {
  padding: 60px 0;
}

.credi-link {
  @include link(#becf00, darken(#becf00, 2.5), underline);
}

.subscription_link_color {
  @include subscription_link_color(#becf00, darken(#af3e19, 2.5), underline)
}

.credi-button-style {
  @include button-style(#becf00, #fff, #abb703, #fff, 1);
}


#credi-paralax-sec {
  @include parallax-sec('/images/landing-pages/banners/credi-paralax-banner.jpg', '/images/landing-pages/banners/credi-paralax-banner-mobile.jpg');
  @include mquery(768px) {
    background-attachment: initial;
  }
  .credi-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.7));
  }
}
