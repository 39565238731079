@import 'extensions/variables';
@import 'extensions/mixins';


#mn-nabo-tp-banner {
  background-image: url(/images/landing-pages/nabo-bk-img-min.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70%;
  margin: 0px;
  padding: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
  @include mquery(736px) {
    height: 100%;
  }
  .mn-img-sm {
    width: 300px;
    margin-bottom: 20px;
    @include mquery(736px) {
      width: 200px;
      height: auto;
    }
  }
  .layer {
    background-color: rgba(1, 1, 1, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


}

.nabo-line-hight-52 {
  line-height: 52px;
}



.mn-left-ctn {
  background-color: #00AF92;
  height: 900px;
  position: relative;
}
  .mn-laptop-img-cnt {
    position: absolute;
    left: 600px;
    .mn-laptop-img {
      position: relative;
      // width: 306px;
      margin: 0 auto;
      margin-top: 50px;
      .lap-top-img {
        // width: 306px;
        // height: 617px;
        height: 38.5625rem;
        max-width: none;
      }
      .mn-slide-img {
        position: absolute;
        top: 36px;
        width: 814px;
        left: 130px;
        // height: 472px;
        pointer-events: none;
      }
    }



  }


  #mn-nabo-half-green-bk {
    background-image: url(/images/landing-pages/green-bk.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #ccc;
    position: relative;
    @include mquery(1200px) {
      background-image: none;
      background-color: #00AF92;
    }
    .carousel-indicators-list {
      @media screen and (min-width: 1400px) {
        background-color: #8bcbc0;
        border: 1px solid #8bcbc0;
      }

    }
  }




.mn-landing-page-content-cnt {
  margin: 90px auto;
  }

  .nabo-form-field {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include mquery(768px) {
      justify-content: center;
      // align-items: center;
    }
    #nabo-name {
      padding: 10px;
      border-radius: 4px;
      margin-right: 20px;
      font-weight: 400;
      height: 50px;
      border: none;
      @include mquery(736px) {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
    #nabo-offer {
      padding: 10px;
      border-radius: 4px;
      margin-right: 20px;
      font-weight: 400;
      height: 50px;
      border: none;
    }
    #nabo-email {
      padding: 10px;
      border-radius: 4px;
      margin-right: 20px;
      font-weight: 400;
      height: 50px;
      border: none;
      @include mquery(736px) {
        margin-right: 0;
        border-radius: 4px 0 0 4px;
      }
    }
    #nabo-register {
      color: #fff;
      background-color: #00ae91;
      border-color: #00957c;
      @include mquery(736px) {
        border-radius: 0 4px 4px 0;
      }
    }
   }

   .mn-form-center {
     justify-content: center;
   }
   .nabo-link {
     color: #8bcbc0;

   }
   .nabo-link:hover {
     color: #337ab7;
   }

   .nabo-text-link {
      color: #8bcbc0;

   }
   .nabo-text-link:hover {
     text-decoration: underline;
     color: #8bcbc0;
   }

   .nabo-green {
     color: #00AF92;
   }
