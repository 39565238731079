@import 'extensions/variables';
@import 'extensions/mixins';


#mn-westwinds-tp-banner {
  height: 1080px;
  width: 100%;
  @media only screen and (min-width: 736px) and (max-width: 1440px) {
    height: 920px;
  }
  @include mquery(735px) {
   height: 670px;
  }

  .mn-westwinds-layer {
    height: 100%;
    width: 100%;
    background-color: rgba(1, 1, 1, 0.4);
    padding-bottom: 40px;
  }
  .mn-westwinds-top-content-ctn {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    .mn-westwinds-top-content-ctn-with-padding {
      padding-top: 50px;
      @include mquery(1440px) {
       padding-top: 0;
      }
    }

  }
    .mn-westwinds-header {

      font-family: 'Pathway Gothic One',"Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
      text-transform: uppercase;
      font-size: 40px;
      color: #fff;
      letter-spacing: 13px;
      display: inline-block;
      position: relative;
      line-height: 64px;
      text-align: center;

      @include mquery(735px) {
       letter-spacing: 0;
       font-size: 24px;
       line-height: 39px;
      }
    }

    .mn-westwinds-subheader {

      font-size: 40px;
      color: #fff;
      line-height: 64px;
      text-align: center;
      margin: 40px auto;
      font-family: latoregular;

      @include mquery(736px){
       font-size: 24px;
       line-height: 39px;
       letter-spacing: 0;
       margin-bottom: -20px;
      }

    }
      .mn-line-div {
        background-color: white;
        height: 3px;
        @include mquery(736px) {
         display: none;
        }
      }

      .mn-westwinds-logo {
        max-width: 300px;
        @media only screen and (min-width: 321px) and (max-width: 1280px) {
          max-width: 200px;
        }
        @include mquery(320px) {
        width: 100px;

        }
      }

      .mn-bottom-line {
        background-color: white;
        height: 3px;
        margin-top: 60px;
        margin-bottom: 30px;
        @include mquery(736px) {
        margin-top: 7px;

        }
      }

      .westwinds-form-field {
        margin-top: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 410px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        @include mquery(736px) {
        width: 320px;

        }
        @include mquery(320px) {
        width: 270px;

        }
        #westwinds-name  {
          max-width: 192px;
          padding: 10px;
          border-radius: 4px;
          margin-right: 20px;
          font-weight: 400;
          height: 50px;
          border: 2px solid white;
          background-color: transparent;
          color: white;
          @include mquery(736px) {
          margin-bottom: 10px;
          width: 105px;

          }
        }
        ::placeholder {
          color: white;
        }
        #westwinds-email {
          max-width: 192px;
          padding: 10px;
          border-radius: 4px;
          // margin-right: 20px;
          font-weight: 400;
          height: 50px;
          border: 2px solid white;
          background-color: transparent;
          color: white;
          @include mquery(320px){
            max-width: 142px;
          }
        }
        #westwinds-register {
          border: 2px solid #008cba;
          background-color: transparent;
          color: white;
          border-radius: 4px;
          width: 100%;
          height: 49px;
          background-color: #008cba;
          margin-top: 20px;
          @media only screen and (min-width: 737px) and (max-width:1280px) {
            width: 100%;
          }
          @include mquery(736px) {
          width: 100%;
          margin-top: 0;
          }

        }
        #westwinds-register:hover {
          background-color: lighten(#008cba, 1.9);
          transform: translate(.9);
        }

        .wwg-view-offer-btn {
          border: 2px solid #008cba;
          color: white;
          border-radius: 4px;
          height: 49px;
          width: 200px;
          background-color: #008cba;
          animation: bounce 3s;
        }


      }

      .westwings-blue {
        color: #008cba;

      }
      .westwings-blue:hover {
        text-decoration: underline;
        color: #008cba;

      }

      .carousel  {
        height: 100%;
      }

      .item {
        width: 100%;
        height: 100%;
        transition: opacity 1s;

      }

         .carousel-inner {
          height: 100%;

       }



     .carousel .one {
       background-image: url(/images/landing-pages/west-winds-gin-rocks-min.jpg);
       background-size: cover;
       background-repeat: no-repeat;
       background-position: center center;
       background-color: #002d72;
       @include mquery(736px) {
         background-image: url(/images/landing-pages/west-winds-gin-rocks-min-mobile.jpg);
       }

     }
     .carousel .two {
       background-image: url(/images/landing-pages/west-winds-BottlesOnTheDeck.jpg);
       background-size: cover;
       background-repeat: no-repeat;
       background-position: center center;
       background-color: #002d72;
       @include mquery(736px) {
         background-image: url(/images/landing-pages/west-winds-BottlesOnTheDeck-mobile-min.jpg);
       }
     }
     .carousel .three  {
       background-image: url(/images/landing-pages/west-winds-tasmania-min.jpg);
       background-size: cover;
       background-repeat: no-repeat;
       background-position: center center;
       background-color: #002d72;
       @include mquery(1200px) {
         background-image: url(/images/landing-pages/west-winds-tasmania-min-mobile.jpg);
       }

     }

 }



#mn-westwinds-content-section {
  position: relative;
  background-color: white;
  .mn-content-rightside-img {
    width: 600px;
    margin: auto;
  }
  .mn-westwinds-justify-content {

  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  }
  .mn-westwinds-content-ctn {
    margin: 90px auto;
    .mn-westwinds-content-header {
      font-size: 40px;
      margin-bottom: 2.5rem;
      font-family: latoregular;
      @include mquery(1200px) {
       text-align: center;
       font-size: 30px;
      }
    }


    .westwinds-link {
      color: #008cba;

    }
  }

  .west-winds-bottle-padding-top {
    padding-top: 114px;
    @media screen and(min-width: 1500px) {
      padding-top: 36px;
    }
    @include mquery(1200px) {
      padding-top: 0;
    }

  }

  .carousel  {
    height: 100%;
    @include mquery(1200px) {
      display: none;
    }
  }

  .item {
      width: 100%;
      height: 100%;
      transition: opacity 1s;

  }

     .carousel-inner {
      height: 100%;

   }

}

.wwg-button-style {
  background-color: #008cba;
  border: 1px solid #008cba;
  color: #fff;
  font-size: 18px;
  transition: all 0.5s ease;
}
.wwg-button-style:hover {
  color: white;
  opacity: .7;
  border: 1px solid #008cba;
}
