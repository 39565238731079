@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';

#urbotanica-last-form-sec {
    display: flex;
    flex-direction: row;
    @include mquery(1024px) {
      flex-wrap: wrap;
    }
  .urbotanica-last-form-sec-1 {
    @include lastFormSecOneImg(#000, '/images/landing-pages/urbotanica/plant.png');
    @extend %background-property;
  }

  .urbotanica-last-form-sec-2 {
    @extend %flex-center;
    height: 400px;
    width: 32%;
    padding: 10px;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
    }
  }
  .urbotanica-last-form-sec-3 {
    @include lastFormSecThreeImg(#000, '/images/landing-pages/urbotanica/herbs.jpg');
    @extend %background-property;
  }
}

#urbotanica-paralax-sec {
  @include parallax-sec('/images/landing-pages/urbotanica/urbi-dino.jpg', '/images/landing-pages/urbotanica/urbi-dino.jpg');
  .urbotanica-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.7));
  }
}