@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
PROFILE SETTINGS
---------------------------------------------------------- */
.settings-container {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 340px 0px 0px;
    position: relative;
    .settings-leftcol {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 20px;
        position: relative;
        background-color: $main-columns-bg;
        @include mquery(1023px) {
            margin-top: 30px;
        }
    }
    .profile-settings-container {
        position: relative;

        .reg-log-title { float: none; display: block;}
        .reg-log-subtitle { float: none; display: block;}
    }
    .settings-rightcol {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        max-width: 320px;
        margin: 0px;
        padding: 0px;
        @include mquery(1023px) {
            position: relative;
            top: auto;
            left: auto;
            max-width: 100%;
        }
    }
    @include mquery(1023px) {
        padding: 0px;
    }
}
.form-groups-wrapper {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    position: relative;
}
.selected-corporate-trustee{
    float: left;
    margin: 10px 0px 0px 0px;
}
.settings-form-group {
    float: left;
    width: 100%;
    min-height: 60px;
    margin: 10px 0px 0px 0px;
    padding: 0px 400px 0px 0px;
    position: relative;
    background-color: $form-group-bg;
    &:first-of-type {
        margin-top: 0px;
    }
    &.radio-form-group {
        padding: 0px 160px 0px 0px;
        @include mquery(580px) {
            padding: 0px;
        }

        .sfg-field{
            margin-top: 18px;
            max-width: 200px;
        }
    }
    .sfg-title {
        position: relative; // added by Dijana
        float: left;
        width: 100%;
        margin: 0px;
        padding: 22px 15px;
        font-family: 'latoregular';
        font-size: 14px;
        color: $input-field-text;
        @include mquery(580px) {
            padding: 10px;
        }
    }
    // added by Dijana
    .sfg-title-optional {
        font-family: "latoregular";
        font-style: italic;
    }
    .sfg-field {
        position: absolute;
        top: 0px;
        right: 0px;
        margin: 0px;
        padding: 0px 10px 0px 52px;
        width: 100%;
        max-width: 400px;
        @include mquery(670px) {
            max-width: 320px;
        }
        @include mquery(580px) {
            max-width: 100%;
            padding: 0px 10px 0px 62px;
            position: relative;
            float: left;
            top: auto;
            right: auto;
        }
        &.prof-sett-industries{
            padding-top: 9px;
            @include mquery(580px) {
                padding-top: 0;
            }
        }
        .sfg-input-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px 10px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            @include border(full, 1px, solid, $input-field-border);
            @include mquery(580px) {
                margin: 0px;
            }
        }
        .sfg-select-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px;
            background-color: #ffffff;
            @include border(full, 1px, solid, $input-field-border);
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-image: url(../../images/select-field-arrow.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #ffffff;
                z-index: 2;
            }
            > select {
                float: left;
                width: 100%;
                height: 40px;
                margin: 0px;
                padding: 0px 10px 1px;
                margin: 0px;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                border: 0px;
                position: relative;
                z-index: 3;
                background-color: transparent;
                cursor: pointer;
            }
            @include mquery(580px) {
                margin: 0px;
            }
        } /* sfg select field ends here */
        .sfg-file-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px;
            background-color: #ffffff;
            @include border(full, 1px, solid, $input-field-border);
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-image: url(../../images/file-field-icon.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #ffffff;
                z-index: 2;
            }

            > button.default-documents {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                cursor: pointer;
                background-color: #469edc;
                background-position: center center;
                background-image: url(../../images/cert-upload-btn-icon.png);
                background-repeat: no-repeat;
                border: 0px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
            }

            > button.legal-documents {
                position: absolute;
                top: 10px;
                right: 10px;
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                cursor: pointer;
                background-color: #469edc;
                background-position: center center;
                background-image: url(../../images/cert-upload-btn-icon.png);
                background-repeat: no-repeat;
                border: 0px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
            }

            > input {
                float: left;
                width: 100%;
                height: 40px;
                margin: 0px;
                padding: 0px 10px;
                margin: 0px;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                border: 0px;
                position: relative;
                z-index: 3;
                background-color: transparent;
                text-indent: -99999px;
                opacity: 0;
                cursor: pointer;
                &:disabled {
                    background-color: #ededed;
                }
            }
            > span {
                position: absolute;
                top: 12px;
                left: 0px;
                margin: 0px;
                padding: 0px 42px 3px 15px;
                width: 100%;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
            @include mquery(580px) {
                margin: 0px;
            }
        } /* sfg file input ends here */
        .sfg-textarea-field {
            float: left;
            width: 100%;
            min-height: 160px;
            margin: 9px 0px 0px 0px;
            padding: 10px 10px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            @include border(full, 1px, solid, $input-field-border);
            resize: none;
            @include mquery(580px) {
                margin: 0px;
            }
        }

    } /* sfg field ends here */
    .sfg-field-privacy {
        position: absolute;
        top: 9px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        width: 42px;
        display: none;
        overflow: hidden;

        &.visible {
            display: inline-block;
        }
        &.main-privacy-switch {
            top: auto;
            bottom: 9px;
            left: 10px;
        }
        @include mquery(580px) {
            top: 0px;
            left: 10px;
        }
    }
    &.multiple-fields {
        padding: 0px 0px 9px 0px;
        .sfg-title {
            position: absolute;
            padding: 22px 410px 22px 15px;
            @include mquery(580px) {
                padding: 10px;
                float: left;
                width: 100%;
                margin: 0px;
                position: relative;
            }
        }
        .sfg-field {
            top: auto;
            right: auto;
            float: right;
            position: relative;
            clear: both;
            @include mquery(580px) {
                margin-top: 9px;
            }
        }
        .main-privacy-switch {
            @include mquery(580px) {
                left: auto;
                bottom: auto;
                top: 10px;
                right: 10px;
            }
        }
    }
    &.address-multiple-fields {
        .sfg-field {
            @include mquery(580px) {
                padding: 0px 10px;
            }
            &.first {
                margin-top: 28px;
            }
        }
    }
    .profile-picture-preview {
        position: absolute;
        bottom: 9px;
        right: 10px;
        width: 164px;
        height: 164px;
        margin: 0px;
        padding: 0px;
        > img {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
        @include mquery(580px) {
            display: inline-block;
            position: relative;
            bottom: auto;
            right: auto;
            margin: 10px 0px 0px 0px;

        }
    }
    @include mquery(670px) {
        padding: 0px 330px 0px 0px;
    }
    @include mquery(580px) {
        padding: 0px 0px 10px 0px;
        text-align: center;
    }
} /* Settings form group ends here */
.include-field-link {
    min-height: 80px;
}
.sfg-field {
    max-width: 160px;
    padding: 0px 25px 0px 10px;
    margin-top: 18px;
    @include mquery(580px) {
        margin-top: 0px;
        padding: 0px 25px 10px 10px;
    }
}
.is-sfg-field {
    float: left;
    margin-top: 4px;
    padding: 0px 0px 0px 25px;
}
.existed-file {
    font-size: 11px;
    color: $default-blue-color;
    float: right;
    padding: 7px 0px;
    &:hover {
        text-decoration: underline;
    }
}
.cp-image-info{
    font-size: 11px;
    color: $default-blue-color;
    //float: right;
    padding: 7px 0px;
    display: block;

}

.cp-form-group-with-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 10px 10px 0 !important;
    .cp-form-group-title {
        float: none;
        display: block;
        flex: 1 0 200px;
        margin-top: 12px;
        margin-left: 15px;
    }

    .cp-fg-input-cont {
        position: relative;
        top: 0;
        left: 0;
        flex-basis: 280px;
        margin-left: 10px;
    }

    .cp-fg-image-preview {
        background-position: top right;
        background-size: cover;

        .cp-fg-ip-thumbnail-image {
            width: auto !important;

            img {
                max-height: 150px !important;
                height: 150px !important;
                width: auto !important;
                max-width: unset !important;
            }

        }
        &:hover {
            .cp-fg-ip-thumbnail-image {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }

    }
    @include mquery(580px) {
        display: block;
        padding-right: 10px;
        .cp-fg-input-cont {
            width: 100%;
            box-sizing: border-box;
        }
        .cp-form-group-title {
            position: relative;
            top: 0;
            left: 0;
            margin-top: 5px;
            margin-right: 10px;
        }
        .cp-form-helper {
            right: 15px;
        }
        .cp-fg-input-cont {
            padding-right: 15px !important;
        }
    }

    .cp-fg-file-field {
        float: none !important;
    }

    .cp-image-info {
        padding-top: 10px;
        display: block;
    }

}
.settings-sidebar-navigation {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
    > ul {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        background-color: #ffffff;
        > li {
            float: left;
            width: 100%;
            height: 50px;
            margin: 0px;
            list-style: none;

            > a {
                float: left;
                width: 100%;
                height: 50px;
                margin: 0px;
                padding: 16px 15px 15px 15px;
                background-color: $settings-sidebar-nav-bg;
                font-family: 'latoregular';
                font-size: 16px;
                color: $settings-sidebar-nav-color;
                text-align: right;
                @include transition(.3s);
                position: relative;
                @include border(bottom, 1px, solid, $form-group-bg);
                &:hover, &.active {
                    background-color: #e4ecf1;
                    &:after {
                        visibility: visible;
                        opacity: 1;
                        left: 15px;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 14px;
                    left: 40px;
                    background-image: url(../../images/settings-sidebar-nav-arrow.png);
                    background-repeat: no-repeat;
                    width: 22px;
                    height: 22px;
                    opacity: 0;
                    visibility: hidden;
                    @include transition(.7s);
                    @include mquery(1023px) {
                        content: none;
                    }
                }
                @include mquery(1023px) {
                    text-align: center;
                    font-size: 14px;
                    padding: 17px 15px 15px;
                }
                @include mquery(610px) {
                    padding: 17px 15px 15px !important;
                }
            }
            @include mquery(1023px) {
                width: 20%;
                @include border(right, 1px, solid, $form-group-bg);
            }
            @include mquery(610px) {
                width: 50%;
            }
            @include mquery(420px) {
                width: 100%;
            }

            /* nth-child media queries - sidebar navigation links text adjustment */
            &:nth-child(3) > a {
                @include mquery(990px) {
                    padding-top: 10px;
                }
            }
            &:nth-child(2) > a {
                @include mquery(818px) {
                    padding-top: 10px;
                }
            }
            &:nth-child(5) > a {
                @include mquery(798px) {
                    padding-top: 10px;
                }
            }
            &:nth-child(4) > a {
                @include mquery(747px) {
                    padding-top: 10px;
                }
            }
            &:nth-child(1) > a {
                @include mquery(652px) {
                    padding-top: 10px;
                }
            }
        } /* Navigation list items ends here */
    }
}

/* Investment settings */
.investment-settings-wrapper {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px;
    .is-action-buttons {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 18px;
        background-color: $form-group-bg;
        text-align: center;
        position: relative;
        .is-add-company-btn {
            float: left;
            width: 190px;
            height: 34px;
            margin: 0px;
            padding: 10px 10px;
            font-family: 'latoblack';
            font-size: 12px;
            color: $default-submitBtn-color;
            text-transform: uppercase;
            background-color: $default-save-btn-bg;
            text-decoration: none;
            background-image: url(../../images/is-addcompany-btn-icon.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            @include transition(.3s);
            @include mquery(690px) {
                width: 100%;
                margin-bottom: 10px;
            }
            &:hover {
                background-color: lighten($default-save-btn-bg, 5%);
            }
            &.investment-trust{
                display: inline-block;
                float: none;
            }
            &.investment-smsf{
                float: right;
            }
        }
        .is-back-btn {
            display: none;
            float: left;
            width: 190px;
            height: 34px;
            margin: 0px;
            padding: 10px 10px;
            font-family: 'latoblack';
            font-size: 12px;
            color: $default-submitBtn-color;
            text-transform: uppercase;
            background-color: $default-back-btn-bg;
            text-decoration: none;
            background-image: url(../../images/is-back-btn-icon.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            @include transition(.3s);
            &:hover {
                background-color: lighten($default-back-btn-bg, 5%);
            }
            @include mquery(690px) {
                width: 34px;
                text-indent: -99999px;
                background-position: right 12px center;
            }
            @include mquery(485px) {
                margin-top: 20px;
                text-indent: 0;
                width: 190px;
            }
        }
        .is-checkbox-group {
            display: none;
            float: right;
            margin: 2px 0px 0px 0px;
            padding: 0px 0px 0px 18px;
            @include mquery(485px) {
                padding: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                text-align: center;
            }
            > .default-checkbox-label {
                float: none;
                display: inline-block;
                padding: 0px 10px 0px 0px;
            }
            > .is-sfg-field {
                float: none;
                display: inline-block;
                padding: 0px 0px 0px 10px;
            }
        }
    } /* Investment settings action buttons ends here */

    .is-newcompany-form {
        float: left;
        width: 100%;
        margin: 20px 0px 0px 0px;
        padding: 0px;
        position: relative;
        display: none;
    }
}
.is-table-container {
        float: left;
        width: 100%;
        margin: 30px 0px 0px 0px;
        padding: 0px;
    }
a.invite-team-user {
    color: $default-blue-color;
    &:visited {
        color: $default-blue-color;
    }
}

.is-company-members-wrapper {
    float: left;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px;
    position: relative;

    &.create-corporate-synd{
        margin: 0;
        // padding: 20px 0 0;
    }
    .is-cm-tabs-content {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        text-align: center;
        .is-cd-cont {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
        .is-cs-cont {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            display: none;
        }
        .is-ci-cont {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            display: none;
        }
        .is-members-blocks {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 20px 0px 0px 0px;
            position: relative;
            > p {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                text-align: center;
                font-family: 'latoregular';
                font-size: 14px;
                color: $default-text-color;
            }
            .is-member-block {
                float: left;
                width: 50%;
                margin: 0px;
                padding: 20px 0px 0px 0px;
                &:nth-child(2n+1) {
                    padding: 20px 10px 0px 0px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                &:nth-child(2n+2) {
                    padding: 20px 0px 0px 10px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                &:nth-child(-n+2) {
                    padding-top: 0px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                @include mquery(768px) {
                    width: 100%;
                    &:first-of-type {
                        padding-top: 0px;
                    }
                }
                > div {
                    float: left;
                    width: 100%;
                    min-height: 90px;
                    margin: 0px;
                    padding: 0px 0px 0px 95px;
                    position: relative;
                    background-color: $form-group-bg;
                    > img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 90px;
                        height: 90px;
                        margin: 0px;
                        padding: 0px;
                    }
                    > div {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 10px 0px;
                        > span {
                            float: left;
                            width: 100%;
                            margin: 0px;
                            padding: 0px 25px 0px 10px;
                            font-family: 'latobold';
                            font-size: 15px;
                            color: $default-text-color;
                            &.member-position-title {
                                font-family: 'latoregular';
                                margin-top: 5px;
                                font-size: 12px;
                            }
                        }
                        > a {
                            background-image: url("../../images/blue-button-arrow.png");
                            background-position: right 10px center;
                            background-repeat: no-repeat;
                            @include border(full, 1px, solid, $default-blue-color);
                            color: $default-blue-color;
                            float: left;
                            font-family: "latoblack";
                            font-size: 11px;
                            margin: 12px 0 0 10px;
                            min-height: 26px;
                            padding: 6px 35px 5px 10px;
                            text-decoration: none;
                            text-transform: uppercase;
                            @include transition(.3s);
                            &:hover {
                                background-position: right 7px center;
                            }
                        }

                        a.see-profile-button {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            margin-bottom: 10px;
                            margin-right: 10px;
                        }
                    }

                    .is-member-block-remove {
                        position: absolute;
                        width: 11px;
                        height: 11px;
                        margin: 0px;
                        padding: 0px;
                        background-image: url(../../images/is-cm-remove-icon.png);
                        background-position: 0px 0px;
                        background-repeat: no-repeat;
                        top: 5px;
                        right: 5px;
                    }
                }
            }
        }
        .is-company-blocks {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 20px 0px 0px 0px;
            position: relative;
            > p {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                text-align: center;
                font-family: 'latoregular';
                font-size: 14px;
                color: $default-text-color;
            }
            .is-company-block {
                float: left;
                width: 50%;
                margin: 0px;
                padding: 20px 0px 0px 0px;
                &:nth-child(2n+1) {
                    padding: 20px 10px 0px 0px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                &:nth-child(2n+2) {
                    padding: 20px 0px 0px 10px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                &:nth-child(-n+2) {
                    padding-top: 0px;
                    @include mquery(768px) {
                        padding: 20px 0px 0px 0px;
                    }
                }
                @include mquery(768px) {
                    width: 100%;
                    &:first-of-type {
                        padding-top: 0px;
                    }
                }
                > div {
                    float: left;
                    width: 100%;
                    min-height: 75px;
                    margin: 0px;
                    padding: 0px 0px 0px 80px;
                    position: relative;
                    background-color: $form-group-bg;
                    > img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 75px;
                        height: 75px;
                        margin: 0px;
                        padding: 0px;
                    }
                    > div {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 10px 0px 0px 0px;
                        > span {
                            float: left;
                            width: 100%;
                            margin: 0px;
                            padding: 0px 25px 0px 10px;
                            font-family: 'latobold';
                            font-size: 15px;
                            color: $default-text-color;
                        }
                        > a {
                            background-image: url("../../images/blue-button-arrow.png");
                            background-position: right 10px center;
                            background-repeat: no-repeat;
                            @include border(full, 1px, solid, $default-blue-color);
                            color: $default-blue-color;
                            float: right;
                            font-family: "latoblack";
                            font-size: 11px;
                            margin: 0 10px 10px 0;
                            min-height: 26px;
                            padding: 6px 35px 5px 10px;
                            text-decoration: none;
                            text-transform: uppercase;
                            @include transition(.3s);
                            &:hover {
                                background-position: right 7px center;
                            }
                        }
                    }
                    .company-type {
                        font-family: 'latoregular';
                    }
                }
            }
        }
        .is-addnew-user {
            display: inline-block;
            width: 100%;
            max-width: 500px;
            margin: 20px 0;

            > span {
                width: 100%;
                margin: 20px 0;
                font-family: 'latolight';
                font-size: 14px;
                color: $default-text-color;
                text-align: center;
                line-height: 150%;
            }

            > .is-cm-filegroup {
                padding-right: 0;

                .invited_email {
                    margin-top: 0;
                    float: none;
                }
                .sfg-field button {
                    top: 10px;
                }
            }


            .remove-block-for-invite {
                top: 25px !important;
                right: -20px !important;

                @include mquery(480px) {
                    top: 5px !important;
                    right: 5px !important;
                }
            }
        }
    }

    .remove-block-for-invite {
        position: absolute;
        width: 11px;
        height: 11px;
        margin: 0px;
        padding: 0px;
        background-image: url(../../images/is-cm-remove-icon.png);
        background-position: 0px 0px;
        background-repeat: no-repeat;
        top: 5px;
        right: 5px;
    }
} /* Certificate tabs container ends here */
.is-cm-tabs {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    &.two-tabs > li {
        width: 50%;
    }
    &.three-tabs > li {
        width: 33.3%;
    }
    &.four-tabs > li {
        width: 25%;
    }
    &.five-tabs > li {
        width: 20%;
    }
    &.blue{
        > li{
            border-color: $white-border;
            > a {
                &:hover, &.active {
                    background-color: $main-blue-link-hover;
                }
            }
        }
    }
    > li {
        float: left;
        width: 50%;
        margin: 0px;
        padding: 0px;
        list-style: none;
        @include border(right, 1px, solid, $white-border);
        &:last-of-type {
            border: 0px;
        }
        @include mquery(420px) {
            width: 100%;
            border-right: 0px;
            @include border(bottom, 1px, solid, $up-tabs-border);
        }
        > a {
            float: left;
            width: 100%;
            height: 35px;
            margin: 0px;
            padding: 9px 0px;
            text-decoration: none;
            font-family: 'latobold';
            font-size: 14px;
            color: $up-tabs-links-color;
            background-color: $up-tabs-bg;
            text-align: center;
            @include transition(.3s);
            &:hover, &.active {
                background-color: $main-blue-link-hover;
            }
        }
    }
}/* Certificates tabs UL ends here */
.is-cm-filegroup .sfg-field {
    padding: 9px 60px 0px 10px;
    button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        background-color: $default-blue-color;
        background-position: center center;
        background-image: url(../../images/cert-upload-btn-icon.png);
        background-repeat: no-repeat;
        border: 0px;
        @include transition(.3s);
        &:hover {
            background-color: lighten($default-blue-color, 5%);
        }
        @include mquery(580px) {
            // top: 1px;
        }
        &.mob-position-fix{
            @include mquery(580px) {
                top: auto;
                bottom: 1px;
            }
        }
        &.add-company.mob-position-fix{
            @include mquery(580px) {
                top: auto;
                bottom: 0px;
            }
        }
    }
}
input[type=checkbox].sfg-privacy-checkbox {
    position:absolute;
    z-index:-1000;
    left:-1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height:1px;
    width:1px;
    margin:-1px;
    padding:0;
    border:0;
}
input[type=checkbox].sfg-privacy-checkbox + label.sfg-privacy-checkbox-label {
    padding-left:42px;
    height:42px;
    display:inline-block;
    line-height:42px;
    background-repeat:no-repeat;
    background-position: 0 0;
    font-size:42px;
    vertical-align:middle;
    cursor:pointer;
}
input[type=checkbox].sfg-privacy-checkbox:checked + label.sfg-privacy-checkbox-label {
    background-position: 0 -42px;
}
label.sfg-privacy-checkbox-label {
    background-image:url(../../images/sfg-privacy-checkbox.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-indent: -9999px;
}

.total-investments {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    color: #ffffff;
    // height: 40px;
    background-color: #8f9ea9;
    margin-bottom: 20px;
    padding: 11px 15px;

    p {
        font-family: 'latoregular';
        font-size: 16px;
    }

}


.identity-align-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.confirm-btn-cnt {
  padding: 10px 20px;
  margin-right: 10px;
}

.update-detail-btn {
  padding: 9px 20px;
  color: #469edc;
  &:hover {
    background-color: #469edc;
    transition: 0.3s;
    color: #fff;

  }
}
