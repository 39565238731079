.partners-container h2 {
  font-size: 36px;
  text-align: center;
}

@media (max-width: 500px) {
  .partners-container h2 {
    font-size: 32px;
  }
}

.partners-container .partners-info {
  padding-top: 165px;
  padding-bottom: 95px;
  background-color: #fff;
}

.partners-container .partners-info > header h2 {
  font-size: 36px;
  text-align: center;
}

.partners-container .partners-info > header p {
  text-align: center;
  width: 700px;
  margin: 35px auto;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 800px) {
  .partners-container .partners-info > header p {
    width: 100%;
    padding: 20px;
    margin: 0;
  }
}

.partners-container .partners-info section.partner-logos {
  margin: 50px auto;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.partners-container .partners-info section.partner-logos .partner-logo-item {
  background-color: black;
  margin: 7px;
  border: 1px solid #ccc;
  position: relative;
}

.partners-container .partners-info section.partner-logos .partner-logo-item:hover .overlay {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.95);
  opacity: .95;
}

.partners-container .partners-info section.partner-logos .partner-logo-item .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.95);
}

.partners-container .partners-info section.partner-logos .partner-logo-item .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.partners-container .partners-info section.partner-logos .partner-logo-item .text h4 {
  color: white;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 400px) {
  .partners-container .partners-info section.partner-logos .partner-logo-item .text {
    font-size: 24px;
    line-height: 36px;
    width: 85%;
  }
}

.partners-container .partners-info section.partner-logos .partner-logo-item .text p {
  text-transform: uppercase;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
}

@media (max-width: 290px) {
  .partners-container .partners-info section.partner-logos .partner-logo-item .text h4 {
    font-size: 24px;
    line-height: 36px;
  }
}

.partners-container .partners-info section.partner-logos .partner-logo-item img {
  width: 100%;
  display: block;
}

.partners-container section.reason-why {
  padding: 105px 15px 0 15px;
}

.partners-container section.reason-why .reason-why-container {
  max-width: 1300px;
  margin: 40px auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.partners-container section.reason-why .reason-why-container .why-statements {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  margin-top: 60px;
}

.partners-container section.reason-why .reason-why-container .why-statements p {
  margin: 35px auto;
  margin-bottom: 0;
  text-align: center;
  width: 270px;
}

.partners-container section.partner-why-equitise {
  margin-top: 125px;
  background-image: url("../../images/partners-form-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.partners-container section.partner-why-equitise p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px auto;
  margin-bottom: 25px;
  width: 350px;
}

.partners-container section.partner-why-equitise .form {
  width: 560px;
}

@media (max-width: 630px) {
  .partners-container section.partner-why-equitise .form {
    width: 100%;
    padding: 20px;
  }
  .partners-container section.partner-why-equitise .form p {
    width: 100%;
    padding: 20px;
  }
}

.partners-container section.partner-why-equitise .form input {
  border: 1px solid #fff;
  width: 100%;
  padding: 20px;
  height: 50px;
  font-size: 16px;
  font-family: 'latolight';
  color: #fff;
  margin-top: 30px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.4);
}

.partners-container section.partner-why-equitise .form input.error {
  border-left: 2px solid #DD1C52;
  animation: fadein 2s;
}

.partners-container section.partner-why-equitise .form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  letter-spacing: 1px;
}

.partners-container section.partner-why-equitise .form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  letter-spacing: 1px;
}

.partners-container section.partner-why-equitise .form :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  letter-spacing: 1px;
}

.partners-container section.partner-why-equitise .form :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  letter-spacing: 1px;
}

.partners-container section.partner-why-equitise .form #partnerSubmit {
  background-color: #44A4E7;
  margin: 0 auto;
  display: inherit;
  margin-top: 30px;
  height: 50px;
  font-size: 16px;
  font-family: 'latolight';
  width: 200px;
  color: #fff;
  border: none;
}

.partners-container section.partner-why-equitise .form #partnerSubmit[disabled]:hover {
  cursor: progress;
}

.partners-container section.partner-why-equitise .form #partnerSubmit:hover {
  cursor: pointer;
}
