@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
CREATE COMPANY
---------------------------------------------------------- */
#company-registration{
    .cp-form-group{
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mquery(580px) {
            display: block;
            padding: 15px;
        }
    }
}