/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
LOGIN & REGISTER PAGES
---------------------------------------------------------- */
/* Register page */
.reg-log-page-content {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px 400px 0px 0px;
  position: relative;
  /* Media queries */
}

@media all and (max-width: 885px) {
  .reg-log-page-content {
    padding: 0px;
  }
}

.reg-log-page-content .default-success-tooltip {
  padding: 10px 10px 10px 70px;
}

.reg-log-title {
  margin-top: 40px;
  font-family: 'latoregular';
  font-size: 22px;
  color: #7e7e7e;
  text-transform: uppercase;
  text-align: left;
}

.warning-desc {
  line-height: 1.7rem;
  margin-bottom: 20px;
}

.reg-log-subtitle {
  float: left;
  width: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
}

.reg-log-subtitle > a {
  margin: 0px;
  padding: 0px;
  color: #469edc;
  text-decoration: none;
}

.reg-log-subtitle > a:hover {
  text-decoration: underline;
}

.reg-log-subtitle a {
  color: #7e7e7e;
  text-decoration: underline;
}

/* Register & Login Leftcol */
.reg-log-leftcol {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px;
  background-color: #ffffff;
  position: relative;
}

.reg-log-leftcol > div {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

.reg-log-leftcol > div .reg-log-column {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0px;
  position: relative;
  /* Media queries */
}

.reg-log-leftcol > div .reg-log-column:nth-child(1) {
  padding-right: 5px;
}

.reg-log-leftcol > div .reg-log-column:nth-child(2) {
  padding-left: 5px;
}

@media all and (max-width: 1220px) {
  .reg-log-leftcol > div .reg-log-column {
    width: 100%;
  }
  .reg-log-leftcol > div .reg-log-column:nth-child(1), .reg-log-leftcol > div .reg-log-column:nth-child(2) {
    padding: 0px;
  }
}

/* Register & Login leftcol ends here */
.forgot-password-form {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 20px !important;
  margin-top: 0px !important;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.forgot-password-form > div {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.forgot-password-form.fpf-visible {
  opacity: 1;
  visibility: visible;
}

.forgot-pass-link {
  float: left;
  margin: 33px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 12px;
  color: #9b9c9c;
  text-decoration: none;
}

.forgot-pass-link:hover {
  color: #469edc;
  text-decoration: underline;
}

/* Register & Login Rightcol */
.reg-log-rightcol {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 380px;
  margin: 0px;
  padding: 20px;
  background-color: #ffffff;
}

@media all and (max-width: 885px) {
  .reg-log-rightcol {
    position: relative;
    float: left;
    top: auto;
    right: auto;
    max-width: 100%;
    margin-top: 30px;
  }
}

.reg-log-rightcol > div {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

.reg-log-rightcol > div > a {
  float: left;
  width: 100%;
  height: 46px;
  margin: 0px;
  padding: 13px 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: 'latolight';
  font-size: 17px;
  color: #ffffff;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: right 15px center;
}

.reg-log-rightcol > div > a.fb-login {
  background-color: #3b5998;
  background-image: url("../../images/fb-btn-icon.png");
}

.reg-log-rightcol > div > a.fb-login:hover {
  background-color: #4264aa;
}

.reg-log-rightcol > div > a.lin-login {
  background-color: #0077b5;
  background-image: url("../../images/linkedin-btn-icon.png");
}

.reg-log-rightcol > div > a.lin-login:hover {
  background-color: #0088cf;
}

.reg-log-rightcol > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 0px;
  font-family: 'latoregular';
  font-size: 12px;
  color: #b2b2b2;
  text-align: center;
}

.reg-log-rightcol > div > span.rc-faq-question {
  font-family: 'latobold';
  font-size: 15px;
  color: #666666;
  text-align: left;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  line-height: 22px;
}

.reg-log-rightcol > div > span.rc-faq-question:first-of-type {
  margin-top: 0px;
}

.reg-log-rightcol > div .rc-faq-answer {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 14px;
  color: #666666;
  line-height: 18px;
}

/* ----------------------------------------------------------
LOGIN STEPS
---------------------------------------------------------- */
.login-steps-separator {
  float: left;
  width: 100%;
  text-align: center;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  margin: 20px 0px 0px 0px;
  position: relative;
}

.login-steps-separator:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7e7e7e;
  top: 8px;
  left: 0px;
  width: 45%;
}

.login-steps-separator:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #7e7e7e;
  top: 8px;
  right: 0px;
  width: 45%;
}

.ls-main-container {
  text-align: center;
}

.login-steps-container {
  display: inline-block;
  max-width: 600px;
  width: 100%;
  margin: 70px 0px 0px 0px;
  padding: 25px;
  background-color: #ffffff;
}

.login-steps-buttons {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

.ls-button {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px 0px 10px 0px;
  position: relative;
  /* Login steps button */
  /* Login steps button helper */
}

.ls-button > a {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px;
  padding: 22px 10px 15px 60px;
  background-color: #6aa1c8;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'latobold';
  font-size: 14px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-position: 15px 10px;
  background-repeat: no-repeat;
}

.ls-button > a:hover {
  background-color: #7dadcf;
}

.ls-button > a:before {
  content: "";
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 9px;
  left: 59px;
  font-family: 'latolight';
  font-size: 11px;
  color: #ffffff;
  text-transform: none;
}

.ls-button > a.required:before {
  content: "Required";
}

.ls-button > a.optional:before {
  content: "Optional";
}

.ls-button > a.completed {
  background-color: #f3f5f6;
  color: #a7a7a7;
  background-position: 15px -60px;
}

.ls-button > a.completed:before {
  color: #a7a7a7;
}

.ls-button > a.completed ~ .ls-button-helper {
  border-color: #ffffff;
  text-indent: -99999px;
  background-image: url(../../images/login-steps-checkmark.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 10px;
  background-color: #aad155;
}

.ls-button > a.ls-validate-id {
  background-image: url(../../images/ls-validate-id-icon.png);
}

.ls-button > a.ls-investor-check {
  background-image: url(../../images/ls-investor-check.png);
}

.ls-button > a.ls-company-trust {
  background-image: url(../../images/ls-company-trust.png);
}

.ls-button .ls-button-helper {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 26px;
  height: 26px;
  border-radius: 16px;
  background-color: #ffffff;
  border-width: 3px;
  border-style: solid;
  border-color: #82b9e0;
  text-align: center;
  font-family: 'latoblack';
  font-size: 16px;
  color: #9a9a99;
  cursor: pointer;
  padding-top: 2px;
}

.ls-button-helper-tooltip {
  position: absolute;
  bottom: 55px;
  right: 12px;
  margin: 0px;
  padding: 5px 15px 7px;
  font-family: 'latolight';
  font-size: 12px;
  color: #ffffff;
  background-color: #484848;
  border-radius: 3px;
  max-width: 300px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}

.ls-button-helper-tooltip:before {
  content: "";
  bottom: -6px;
  right: 10px;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 6px solid #484848;
  position: absolute;
}

.ls-button-helper:hover ~ .ls-button-helper-tooltip {
  bottom: 45px;
  opacity: 1;
  visibility: visible;
}

.ls-alternative-buttons {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

.ls-alternative-buttons > a {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px 0px 10px 0px;
  padding: 16px 15px;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  background-color: #aad155;
  text-align: right;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-position: 15px center;
}

.ls-alternative-buttons > a:hover {
  background-color: #b4d669;
}

.ls-alternative-buttons > a.ls-browse-offers {
  background-image: url(../../images/ls-browse-offer-icon.png);
}

.ls-alternative-buttons > a.ls-raise-capital {
  background-image: url(../../images/ls-raise-capital-icon.png);
  background-position: 15px 11px;
}
