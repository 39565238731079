/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#lp-3-sec-2 .lp-3-sec-2-align-center, #lp-3-sec-6 .lp-3-sec-6-video-bk {
  display: flex;
  align-items: center;
  justify-content: center;
}

#lp-hero-banner, #lp-1-sec-2 .lp-1-sec-2-img, #lp-1-sec-3 .lp-1-sec-3-img, #lp-2-sec-1 .lp-2-sec-1-half-img, #lp-3-sec-6 .lp-3-sec-6-video-bk {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.mn-landing-page-content {
  width: 100%;
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media all and (max-width: 1024px) {
  .mn-landing-page-content {
    text-align: center;
  }
}

.mn-p-width {
  max-width: 780px;
  margin: auto;
}

.mn-landing-page-content-container {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 700px;
}

.mn-small-risk-warning-note {
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  max-width: 391px;
  display: block;
  margin: 20px auto;
}

@media all and (max-width: 1024px) {
  .mn-small-risk-warning-note {
    text-align: center;
  }
}

@media all and (max-width: 320px) {
  .mn-small-risk-warning-note {
    font-size: 13px;
  }
}

.mn-landing-page-laptop {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
  margin-top: 34px;
}

.mn-landing-page-laptop .mn-landing-page-screen {
  box-sizing: border-box;
  background: url(/images/laptop-mockup-sm.png) center center no-repeat;
  background-size: contain;
  padding: 9.4% 11.9% 12.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .mn-landing-page-laptop .mn-landing-page-screen {
    padding-top: 10.4%;
  }
}

@media all and (max-width: 736px) {
  .mn-landing-page-laptop .mn-landing-page-screen {
    padding-top: 11.9%;
  }
}

.my-return-thanks-registering {
  text-align: center;
  font-family: 'latoregular';
  font-size: 40px;
  margin-bottom: 34px;
}

@media all and (max-width: 736px) {
  .my-return-thanks-registering {
    font-size: 28px;
  }
}

@media all and (max-width: 320px) {
  .my-return-thanks-registering {
    font-size: 24px;
  }
}

.my-return-message-subscription {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 20px;
  text-align: center;
  max-width: 780px;
  margin: auto;
  line-height: 1.5;
}

@media all and (max-width: 600px) {
  .my-return-message-subscription {
    font-size: 19px;
  }
}

.mn-justify-content {
  align-items: center;
  justify-content: center;
  display: flex;
}

.landing-page-flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.landing-page-form-field .landing-page-form-name {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
  margin-bottom: 10px;
  width: 192px;
}

@media all and (max-width: 736px) {
  .landing-page-form-field .landing-page-form-name {
    width: 105px;
  }
}

@media all and (max-width: 320px) {
  .landing-page-form-field .landing-page-form-name {
    width: 95px;
  }
}

.landing-page-form-field .landing-page-form-email {
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  height: 50px;
  border: none;
  margin-bottom: 10px;
  width: 197px;
}

@media all and (max-width: 320px) {
  .landing-page-form-field .landing-page-form-email {
    width: 175px;
  }
}

.landing-page-form-field .hidden {
  display: none;
}

.landing-page-form-field .landing-page-form-register {
  width: 100%;
  color: white;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
  font-size: 18px;
  font-family: latoregular;
}

@media all and (max-width: 736px) {
  .landing-page-form-field .landing-page-form-register {
    width: 324px;
  }
}

@media all and (max-width: 320px) {
  .landing-page-form-field .landing-page-form-register {
    width: 100%;
  }
}

.landing-page-form-field .landingpage-button-border {
  background-color: transparent;
  color: white;
  border: 1px solid #e8e8e8;
}

.landing-page-form-field .landingpage-button-border-black {
  background-color: transparent;
  color: white;
  border: 1px solid #333;
}

.landing-page-title-width {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.landing-page-register-title {
  font-family: latolight;
  font-size: 36px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

@media all and (max-width: 736px) {
  .landing-page-register-title {
    font-size: 28px;
  }
}

.input-border-black {
  border: 1px solid rgba(1, 1, 1, 0.2) !important;
}

.register-form-last-has-3 {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  .register-form-last-has-3 {
    flex-wrap: wrap;
  }
}

.register-form-last-has-3 .img-sec-1 {
  background-size: cover;
  width: 34%;
  height: 460px;
}

@media all and (max-width: 1024px) {
  .register-form-last-has-3 .img-sec-1 {
    width: 50%;
  }
}

@media all and (max-width: 600px) {
  .register-form-last-has-3 .img-sec-1 {
    height: 180;
  }
}

.register-form-last-has-3 .form-sec-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 460px;
  width: 32%;
}

@media all and (max-width: 1420px) {
  .register-form-last-has-3 .form-sec-2 {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  .register-form-last-has-3 .form-sec-2 {
    width: 100%;
    order: 3;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.register-form-last-has-3 .img-sec-3 {
  background-size: cover;
  height: 460px;
  width: 34%;
}

@media all and (max-width: 1024px) {
  .register-form-last-has-3 .img-sec-3 {
    width: 50%;
    order: 1;
  }
}

.register-form-last-has-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #075f20;
  padding: 100px 0;
}

@media all and (max-width: 600px) {
  .register-form-last-has-1 {
    padding: 50px 0;
  }
}

.register-form-last-has-1 .form-sec {
  max-width: 600px;
}

.lp-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1024px) {
  .lp-flex-center {
    display: block;
    margin: auto;
    text-align: center;
  }
}

.view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.lp-hero-title {
  font-family: 'latoregular';
  margin-bottom: 34px;
  line-height: 2.7rem;
  font-size: 40px;
  padding: 0 15px;
}

@media all and (max-width: 1440px) {
  .lp-hero-title {
    font-size: 38px;
  }
}

@media all and (max-width: 600px) {
  .lp-hero-title {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.lp-title-register {
  font-family: 'latolight';
  margin-bottom: 34px;
  line-height: 2.7rem;
  font-size: 36px;
  padding: 0 15px;
}

@media all and (max-width: 1440px) {
  .lp-title-register {
    font-size: 32px;
  }
}

@media all and (max-width: 600px) {
  .lp-title-register {
    font-size: 28px;
  }
}

#lp-hero-banner {
  background-image: url("");
  height: calc(100vh - 70px);
  width: 100%;
  background-color: #b7b7b7;
  display: table;
}

@media all and (max-width: 600px) {
  #lp-hero-banner {
    background-image: url("");
  }
}

#lp-hero-banner #lp-hero-banner-layer {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  height: calc(100vh - 70px);
  padding: 0;
}

#lp-hero-banner #lp-hero-banner-layer .lp-logo {
  margin-bottom: 40px;
  width: 300px;
}

@media all and (max-width: 500px) {
  #lp-hero-banner #lp-hero-banner-layer .lp-logo {
    width: 250px;
  }
}

#lp-hero-banner #lp-hero-banner-layer .lp-hero-title-width {
  max-width: 600px;
  margin: 0 auto 40px;
}

.lp-button-style {
  background-color: #469edc;
  color: #fff;
  transition: all 300ms ease;
}

.lp-button-style:hover {
  background-color: #469edc;
  color: #fff;
  opacity: .8;
}

.lp-link {
  color: #469edc;
}

.lp-link:hover {
  color: #469edc;
  text-decoration: underline;
}

#lp-1-sec-1 {
  padding: 60px 0;
}

#lp-1-sec-1 .lp-1-sec-1-header {
  color: #333;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 60px;
}

@media all and (max-width: 1200px) {
  #lp-1-sec-1 .lp-1-sec-1-header {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  #lp-1-sec-1 .lp-1-sec-1-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #lp-1-sec-1 .lp-1-sec-1-header {
    font-size: 26px;
  }
}

#lp-1-sec-1 .lp-1-sec-1-copy {
  color: #333;
  font-size: 18px;
  letter-spacing: -0.41px;
  line-height: 1.5;
  margin-bottom: 65px;
}

#lp-1-sec-1 .lp-1-sec-1-stats-cnt {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 44px;
}

#lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-circle {
  width: 122px;
  height: 122px;
  margin: 0 auto 40px;
  background-color: #fff;
  border-radius: 100%;
}

#lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-header {
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 42px;
}

@media all and (max-width: 768px) {
  #lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-header {
    font-size: 26px;
  }
}

#lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-copy {
  letter-spacing: -0.41px;
  line-height: 1.4;
  color: #fff;
  max-width: 300px;
  margin: 0 auto;
}

@media all and (max-width: 1200px) {
  #lp-1-sec-1 .lp-1-sec-1-stats-cnt .lp-1-sec-1-stats-copy {
    margin-bottom: 40px;
  }
}

#lp-1-sec-2 {
  padding: 30px 0 60px;
}

@media all and (max-width: 1200px) {
  #lp-1-sec-2 {
    padding: 60px 0;
  }
}

@media all and (max-width: 768px) {
  #lp-1-sec-2 {
    padding: 0 0 60px;
  }
}

#lp-1-sec-2 .lp-1-sec-2-img {
  margin: 0 0 40px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 309px;
}

#lp-1-sec-2 .lp-1-sec-2-img .img-1 {
  background-image: url("");
}

#lp-1-sec-2 .lp-1-sec-2-img .img-2 {
  background-image: url("");
}

#lp-1-sec-2 .lp-1-sec-2-img {
  margin-bottom: 40px;
}

@media all and (max-width: 1200px) {
  #lp-1-sec-2 .lp-1-sec-2-left-col-cnt {
    margin-bottom: 60px;
  }
}

.lp-view-offer-button {
  border-radius: 4px;
  height: 52px;
  width: 250px;
  color: #fff;
  font-size: 20px;
}

.lp-content {
  width: 100%;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media all and (max-width: 1200px) {
  .lp-content {
    text-align: center;
  }
}

#lp-1-sec-3 {
  padding: 0 0 60px;
}

#lp-1-sec-3 .lp-1-sec-3-img {
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 500px;
  background-image: url("");
}

#lp-paralax-sec {
  background-attachment: fixed;
  background-image: url("");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
}

@media all and (max-width: 600px) {
  #lp-paralax-sec {
    background-image: url("");
  }
}

@media all and (max-width: 768px) {
  #lp-paralax-sec {
    background-attachment: initial;
  }
}

#lp-paralax-sec .lp-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

@media all and (max-width: 1200px) {
  #lp-paralax-sec .lp-paralax-layer {
    padding: 60px 0;
  }
}

#lp-last-form-cnt {
  padding: 60px 0;
}

#lp-last-form-cnt .lp-bottom-link {
  color: #469edc;
}

#lp-last-form-cnt .lp-bottom-link:hover {
  color: #469edc;
  text-decoration: underline;
}

#lp-last-form-cnt .lp-bottom-button-style {
  background-color: #469edc;
  color: #fff;
  transition: all 300ms ease;
}

#lp-last-form-cnt .lp-bottom-button-style:hover {
  background-color: #469edc;
  color: #fff;
  opacity: .8;
}

#lp-2-sec-1 .lp-2-sec-1-half-img-cnt {
  padding: 0;
}

#lp-2-sec-1 .lp-2-sec-1-half-img {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 700px;
  display: table;
  background-image: url("");
}

@media all and (max-width: 1200px) {
  #lp-2-sec-1 .lp-2-sec-1-half-img {
    height: 350px;
  }
}

#lp-2-sec-1 .lp-2-sec-1-content-cnt {
  width: 100%;
  height: 700px;
  max-width: 780px;
  margin: auto;
  padding: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1200px) {
  #lp-2-sec-1 .lp-2-sec-1-content-cnt {
    max-width: 100%;
    padding: 60px 0;
    height: 100%;
  }
}

@media all and (max-width: 1200px) {
  #lp-2-sec-1 .lp-2-sec-1-change-order {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 1200px) {
  #lp-2-sec-1 .lp-2-sec-1-change-order .lp-2-sec-1-order-1 {
    order: 1;
  }
}

@media all and (max-width: 1200px) {
  #lp-2-sec-1 .lp-2-sec-1-change-order .lp-2-sec-1-order-0 {
    order: 0;
  }
}

#lp-3-sec-1 {
  padding: 60px 0;
}

#lp-3-sec-1 .lp-3-sec-1-header {
  color: #333;
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

@media all and (max-width: 1200px) {
  #lp-3-sec-1 .lp-3-sec-1-header {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  #lp-3-sec-1 .lp-3-sec-1-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #lp-3-sec-1 .lp-3-sec-1-header {
    font-size: 26px;
  }
}

#lp-3-sec-2 {
  background-color: rgba(0, 0, 0, 0.6);
  height: 430px;
  position: relative;
}

@media all and (max-width: 1200px) {
  #lp-3-sec-2 {
    height: initial;
    padding: 60px 0;
  }
}

@media all and (max-width: 1200px) {
  #lp-3-sec-2 .lp-3-sec-2-align-center {
    flex-direction: column;
  }
}

#lp-3-sec-2 .lp-3-sec-2-header {
  font-size: 35px;
  letter-spacing: -0.8px;
  color: #fff;
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  #lp-3-sec-2 .lp-3-sec-2-header {
    font-size: 30px;
  }
}

#lp-3-sec-2 .lp-3-sec-2-subheader {
  font-size: 21px;
  letter-spacing: -0.8px;
  color: #fff;
  line-height: 1.5;
  margin: auto;
  max-width: 639px;
}

@media all and (max-width: 1024px) {
  #lp-3-sec-2 .lp-3-sec-2-subheader {
    margin: 0 auto 40px;
  }
}

#lp-3-sec-2 .lp-3-sec-2-img-cnt {
  background-color: #cbcbcb;
  background-image: url("");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 430px;
}

#lp-3-sec-3 {
  padding: 60px 0;
}

#lp-3-sec-4 .lp-3-sec-4-img {
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 500px;
  background-image: url("");
}

@media all and (max-width: 1200px) {
  #lp-3-sec-4 .lp-3-sec-4-img {
    margin-bottom: 0;
  }
}

@media all and (max-width: 768px) {
  #lp-3-sec-4 .lp-3-sec-4-img {
    height: 210px;
  }
}

#lp-3-sec-5 {
  padding: 60px 0;
}

#lp-3-sec-5 .lp-3-sec-5-stats-circle {
  width: 122px;
  height: 122px;
  margin: 0 auto 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  padding: 26px 10px 24px 10px;
}

#lp-3-sec-5 .lp-3-sec-5-stats-copy {
  letter-spacing: -0.41px;
  line-height: 1.4;
  color: #333;
}

@media all and (max-width: 767px) {
  #lp-3-sec-5 .lp-3-sec-5-stats-copy {
    margin-bottom: 40px;
  }
}

#lp-3-sec-6 {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #lp-3-sec-6 {
    padding: 0 0 60px;
  }
}

#lp-3-sec-6 .lp-3-sec-6-video-bk {
  background-image: url("");
  background-color: rgba(0, 0, 0, 0.6);
  height: 440px;
}

@media all and (max-width: 767px) {
  #lp-3-sec-6 .lp-3-sec-6-video-bk {
    height: 210px;
  }
}

#lp-3-sec-6 .lp-3-sec-6-video-bk .lp-3-sec-6-video-info-cnt {
  z-index: 1;
}

#lp-3-sec-6 .lp-3-sec-6-video-bk .lp-3-sec-6-video-btn {
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  transition: .3s;
  width: 120px;
  text-align: center;
}

#lp-3-sec-6 .lp-3-sec-6-content-cnt {
  padding: 0 50px;
}

@media all and (max-width: 1200px) {
  #lp-3-sec-6 .lp-3-sec-6-content-cnt {
    padding: 0;
    margin-top: 60px;
  }
}
