/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#bit-trade-top-banner-cnt {
  background-image: url(/images/landing-pages/bit-trade-hero-bg-black.png);
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #2e142c;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: table;
  position: relative;
}

#bit-trade-top-banner-cnt .bit-trade-layer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bit-trade-logo {
  width: 350px;
}

@media all and (max-width: 500px) {
  .bit-trade-logo {
    width: 200px;
  }
}

.bit-trade-button-style {
  background-color: #e5390d;
  color: white;
  border: 1px solid #e5390d;
}

.bit-trade-button-style:hover {
  box-shadow: inset 0 0 0 20rem rgba(38, 13, 36, 0.06);
}

.bit-trade-link {
  color: #e5390d;
}

.bit-trade-link:hover {
  color: #e1380d;
}

.bit-trade-first-half-img {
  padding: 0;
}

.bit-trade-sec-two-content {
  padding: 50px;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  height: 100%;
}

@media only screen and (min-width: 1511px) and (max-width: 1765px) {
  .bit-trade-landing-page-content {
    font-size: 1.05rem !important;
  }
}

@media only screen and (min-width: 1461px) and (max-width: 1510px) {
  .bit-trade-landing-page-content {
    font-size: 1.0rem !important;
  }
}

@media only screen and (min-width: 1405px) and (max-width: 1460px) {
  .bit-trade-landing-page-content {
    font-size: 0.95rem !important;
  }
}

@media only screen and (min-width: 1245px) and (max-width: 1404px) {
  .bit-trade-landing-page-content {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1246px) {
  .bit-trade-landing-page-content {
    font-size: 0.75rem !important;
  }
}

.bit-trade-align-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

@media all and (max-width: 700px) {
  .bit-trade-cicle-order-2 {
    order: 2;
  }
}

.bit-trade-p-width {
  padding: 50px;
}

@media all and (max-width: 1024px) {
  .bit-trade-p-width {
    padding: 50px 0;
  }
}

.bit-trade-sec-two-row {
  overflow: hidden;
}

.bit-trade-features-circle {
  width: 170px;
  height: 170px;
  background-color: #dbdbdb;
  border-radius: 100%;
  object-fit: cover;
}

.bit-trade-features-circle.one {
  background-image: url(http://www.wwf.org.au/images/UserUploadedImages/403/img-thumb-antarctica-penguin-dive-200x200.jpg);
}

.bit-trade-features-circle.two {
  background-image: url(http://www.wwf.org.au/ecThemes/3/Images/img-thumbnail-homepage-species-200x200.jpg);
}

.bit-trade-features-circle.three {
  background-image: url(http://www.wwf.org.au/ecThemes/3/Images/img-thumbnail-home-climate-200x200.png);
}

/*#bit-trade-thrid-sec {
   background-color: #2e142c;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   height: calc(100vh - 70px);
   background-image: url(/images/landing-pages/bit-trade-liquidity-gateway-showcase.png);
 }*/
.bit-trade-half-mobile-bk {
  background: url(/images/landing-pages/bit-trade-hero-bg-half-black.png);
  background-color: #2e142c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding: 50px;
}

#bit-trade-iphone-sec {
  height: 80%;
  overflow: hidden;
}

@media all and (max-width: 1300px) {
  #bit-trade-iphone-sec {
    height: 800px;
  }
}

@media all and (max-width: 1200px) {
  #bit-trade-iphone-sec {
    height: fit-content;
  }
}

.bit-trade-iphone-sec-cont-cnt {
  background-color: #e5390d;
  height: 100%;
}

.bit-trade-iphone-sec-header {
  font-size: 26px;
  max-width: 390px;
  display: block;
  margin: 0 auto 40px;
  line-height: 1.5;
}

@media all and (max-width: 1024px) {
  .bit-trade-hero-smartphones {
    width: 280px;
  }
}

#bit-trade-paralax-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/bit-trade-what-is-equity-crowdfunding-bg.png);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

#bit-trade-paralax-sec .bit-trade-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

.bit-trade-user-stock-one-img {
  background-color: #2e142c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  width: 34%;
  background-image: url(/images/landing-pages/btrade-bottom-left.png);
}

@media all and (max-width: 1024px) {
  .bit-trade-user-stock-one-img {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  .bit-trade-user-stock-one-img {
    height: 180px;
  }
}

.bit-trade-user-stock-two-img {
  background-color: #2e142c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  width: 34%;
  background-image: url(/images/landing-pages/btrade-bottom-right.png);
}

@media all and (max-width: 1024px) {
  .bit-trade-user-stock-two-img {
    width: 50%;
    order: 1;
  }
}

@media all and (max-width: 500px) {
  .bit-trade-user-stock-two-img {
    height: 180px;
  }
}

#bit-trade-last-form-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #bit-trade-last-form-sec {
    flex-wrap: wrap;
  }
}

.bit-trade-last-form-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 32%;
}

@media all and (max-width: 1420px) {
  .bit-trade-last-form-cnt {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  .bit-trade-last-form-cnt {
    width: 100%;
    order: 3;
  }
}

.bit-trade-last-form-cnt .bit-trade-last-form-title {
  text-align: center;
  font-family: 'latoregular';
  font-size: 30px;
  margin-bottom: 34px;
}
