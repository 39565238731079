@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
Raise Funds
---------------------------------------------------------- */

#mn-raise-fund-top-banner {
  background-image: url(../../images/raise-funds-header.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: table;
  overflow: hidden;

  @include mquery(768px) {
    height: 50%;
    padding: 50px 0;
  }

  .mn-raise-fund-banner-content-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mn-raise-title {
    font-size: 48px;
    font-family: latoregular;
    margin-bottom: 22px;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
    @include mquery(768px) {
      font-size: 38px;
    }
    @include mquery(600px) {
      font-size: 32px;
    }
  }
  .mn-raise-subtitle {
    font-size: 24px;
    font-family: latolight;
    max-width: 780px;
    margin-bottom: 40px;
    line-height: 30px;
    text-align: center;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    @include mquery(768px) {
      font-size: 18px;
    }
  }

}

.mn-sm-icon {
  width: 145px;
  height: 130px;
  margin-bottom: 39px;
  margin: auto;
  text-align: center;
}
.mn-raise-case-study-cnt{
  @include mquery(1200px) {
    margin-bottom: 80px;
  }
  .mn-raise-case-study-img-cnt {
    text-align: center;
    .mn-raise-case-study-img {
      width: 100%;
    }
  }
}


.mn-raise-case-study-content-cnt{
  margin-top: 25px;
  .mn-raise-case-study-p-cnt {
    height: 195px;
    @include mquery(736px) {
      height: 250px;
    }
    .mn-raise-case-study-p{
      font-size: 18px;
      text-align: center;
      line-height: 24px;
      font-family: latolight;
      margin-bottom: 45px;
    }
  }


  .mn-raise-case-study-title {
    font-size: 18px;
    font-family: latoregular;
    text-align: center;
    margin-bottom: 24px;
  }
  .mn-raise-case-study-subtitle {
    font-size: 30px;
    font-family: latoregular;
    text-align: center;
    margin-bottom: 11px;
  }
  .mn-raise-case-study-sub-subtitle {
    font-size: 18px;
    text-align: center;
  }

}

.mn-padding-top {
  padding-top: 14px;
}

.mn-fund-raise-case-study-ctn {
  padding-top: 52px;
}





// timeline/////////////////
///////////////////////////////////////
///////////////////////////////////////

.mn-timeline-cnt {
  background-image: url(../../images/timeline-bg.jpg);
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    /* The actual timeline (the vertical ruler) */

    .timeline-round {
      border-radius: 100%;
      background: #469edc;
      padding: 26px 10px 24px 10px;
      width: 116px;
      height: 116px;
      margin: auto;
      z-index: 1;
      position: relative;
      .back-round-img {
        display: block;
        margin: auto;
      }
    }
    /* Container around content */
    .timeline-container {
        padding: 10px 40px;
        position: relative;
        background-color: inherit;
        width: 50%;
        margin-top: -90px;
        /* The actual content */
        .content {
            padding: 25px 23px;
            background-color: white;
            position: relative;
            border-radius: 6px;
            .timeline-heading {
              font-size: 20px;
              margin-bottom: 18px;
            }

            .timeline-body {
              font-size: 16px;
              line-height: 24px;

            }
        }
    }
    /* Place the container to the left */
    .mn-left {
        left: 0;
    }

    /* Place the container to the right */
    .mn-right {
        left: 50%;
        /* Add arrows to the right container (pointing left) */
    }

}

/* Add arrows to the left container (pointing right) */
.mn-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

.mn-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}
/* Fix the circle for containers on the right side */
.mn-right::after {
    left: -16px;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
}




/* Media queries - Responsive timeline on screens less than 600px wide */
@media all and (max-width: 600px) {

  .timeline {

  /* Full-width containers */
  .timeline-container {
    width: 94%;
    padding-left: 70px;
    padding-right: 25px;
    margin-top: -81px;
    margin-left: 30px;

    .content {
      padding: 16px;
    }

  }

  /* Make all right containers behave like the left ones */
  .mn-right {
    left: 0%;
  }

   .timeline-round {
      margin: 0;
      padding: 20px 10px 24px 10px;
      width: 100px;
      height: 100px;
    }


      .text-right {
        text-align: left;
      }

}

/* Make sure all circles are at the same spot */
.mn-left::after, .mn-right::after {
  left: 15px;
}

/* Make sure that all arrows are pointing leftwards */
.timeline-container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Place the timelime to the left */
.timeline::after {
  left: 49px;

}



}


// timeline end///////////////


.mn-raise-fund-list {
  font-size: 16px;
  line-height: 70px;
}
.mn-raise-fund-p {
  font-size: 18px;
  line-height: 24px;
  max-width: 847px;
  margin: auto;
  padding-top: 40px;
}


.mn-raise-free-container {
  margin-top: 50px;
}
