@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
FOOTER
---------------------------------------------------------- */
#footer {
    float: left;
    width: 100%;
    margin: 0px;
    background-color: #ebebeb;
    text-align: center;
    padding: 5px 0 30px;
    .warning-statement-section {
        background-color: #fff;
        padding-top: 40px;
        padding-bottom: 40px;

        .link-wrap {
            padding-top: 25px;
        }
    }
    .find-out-more-section {
        background-color: #ebebeb;
        color: #fff;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        .find-out-more-text p {
            color: #333;
            font-size: 14px;
            line-height: 21px;
        }
    }
    .footer-main {
        min-height: 350px;
        display: block;
        margin: 0 auto;
        padding-top: 30px;
        // max-width: 1170px;
        // border-top: 1px solid #ccc;
        @media only screen and (min-width: 1400px) {
            // max-width: 1410px;
        }
    }
    > div {
        // margin: 0px auto;
        // padding: 30px 10px;
        // width: 100%;
        // text-align: center;
        .footer-navigation {
            // float: left;
            width: 100%;
            max-width: 770px;
            // @include border(right, 2px, solid, $footer-border-color);
            > ul {
                float: left;
                width: 33.3%;
                margin: 0px;
                padding: 0px 30px 0px 10px;
                > li {
                    float: left;
                    width: 100%;
                    margin: 8px 0px 0px 0px;
                    // padding: 0px 0px 0px 15px;
                    list-style: none;
                    position: relative;
                    // background-image: url(../../images/list-style-dot.png);
                    background-repeat: no-repeat;
                    background-position: left 4px;
                    &:first-of-type {
                        background-image: none;
                        padding-left: 0px;
                        padding-bottom: 5px;
                        margin-top: 0px;
                    }
                    > span {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 0px 0px 10px 0px;
                        font-family: 'latoregular';
                        font-size: 16px;
                        color: #333;
                        // @include border(bottom, 2px, solid, $footer-nav-border-color);
                    }
                    > a {
                        float: left;
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latolight';
                        font-size: 14px;
                        color: #6b7984;
                        text-decoration: none;
                        &:hover {
                            color: #333
                        }
                    }
                }
            }
            /* Media queries */
            @include mquery(1210px) {
                max-width: 100%;
                text-align: center;
                & > ul {
                    display: inline-block;
                    float: none;
                    max-width: 220px;
                    vertical-align: top;
                    @include mquery(705px) {
                        width: 100%;
                        max-width: 100%;
                        margin: 30px 0px 0px 0px;
                        > li {
                            // width: 50%;
                            &:first-of-type {
                                // width: 100%;
                            }
                            @include mquery(440px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            @include mquery(1210px) {
                padding-bottom: 30px;
                border-right: 0px;
                @include border(bottom, 2px, solid, #ccc);
            }
        } /* Footer navigation ends here */

        .footer-newsletter {
            float: right;
            margin: 0px 0px 0px 10px;
            // max-width: 390px;
            // width: 100%;
            @include mquery(1200px) {
                 max-width: 390px;
                 text-align: center;
                 display: block;
                 float: none;
                 margin: 30px auto;
            }
            // @include mquery(736px) {
            //     text-align: center;
            //     margin: 30px auto;
            // }


            .mn-footer-newsletter-header {
                // float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                font-family: 'latoregular';
                font-size: 16px;
                color: #333;
                margin-bottom: 20px;
                @include mquery(1210px) {
                    text-align: center;
                }

            }
            > form {
                margin: 0px;
                padding: 0px;
                text-align: center;
                margin-top: 20px;
                display: inline-block;
                .footer-newsletter-input {
                    float: left;
                    width: 100%;
                    height: 45px;
                    margin: 0px;
                    padding: 0px;
                    color: $footer-text;
                    margin: 20px 0px 0px 0px;
                    padding: 0px 10px;
                    font-family: 'latolight';
                    font-size: 16px;
                    background-color: $footer-input;
                    border-width: 0px;
                    @include border(bottom, 2px, solid, $footer-input-border);
                    /* Media queries */
                    @include mquery(1210px) {
                        float: none;
                        display: inline-block;
                    }
                }

                > span {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 5px 0px 0px 0px;
                    font-family: 'latolight';
                    font-size: 18px;
                    color: #333;
                }
                .footer-subscribe-button {
                    display: inline-block;
                    cursor: pointer;
                    margin: 40px 0px 0px 0px;
                    padding: 10px 120px 10px 10px;
                    border: none;
                    overflow: hidden;
                    @include transition(.3s);
                    position: relative;
                    font-size: 18px;
                    font-family: 'latolight';
                    color: $footer-text;
                    background-color: $subscribe-btn-bg;
                    &:hover {
                        background-color: #4095d0;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        z-index: 1;
                        margin: -5px 0px 0px -5px;
                        height: 200%;
                        background-color: rgba(255, 255, 255, 0.1);
                        -webkit-transform: rotate(-20deg);
                        -moz-transform: rotate(-20deg);
                        -o-transform: rotate(-20deg);
                        transform: rotate(-20deg);
                        transform-origin: 0 0 0;
                        width: 30%;
                        @include transition(.3s);
                    }
                    &:hover:after {
                        width: 40%;
                    }
                    &:before {
                        font-size: 140%;
                        height: 100%;
                        left: auto;
                        line-height: 3;
                        position: absolute;
                        right: 10px;
                        top: 0px;
                        width: 60px;
                        z-index: 2;
                        content: "";
                        background-image: url(../../images/footer-news-arrow.png);
                        background-repeat: no-repeat;
                        background-position: right 15px top 15px;
                    }
                }
            }


        } /* Footer newsletter ends here */

        .form-inline {
          margin-top: 20px;
        }
        .form-control {
          height: 51px;
          width: 260px;
          padding: 10px;
          @include mquery(736px) {
             border-radius: 0;
             text-align: center;
          }

        }
        .footer-btn-default {
          height: 51px;
          background-color: #469edc;
          color: white;
        }
        .footer-copyright {
            float: left;
            width: 100%;
            margin: 30px 0px 30px 0px;
            padding: 20px 0px 0px 0px;
            // @include border(top, 2px, solid, $footer-border-color);
            text-align: center;

            > p {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 0px;
                max-width: 978px;
                font-size: 13px;
                font-family: 'latolight';
                color: #6b7984;
                line-height: 18px;
                // @include mquery(1210px) {
                //     max-width: 100%;
                //     text-align: center;
                // }
                @include respond-to(medium-screens) {
                  font-size: 14px;
                  text-align: center;
                }
                @include mquery(736px) {
                    font-size: 13px;
                    text-align: center;
                }
            }
            .logo-grey {
              float: left;
              margin-right: 30px;
              margin-top: 6px;
              margin-bottom: 10px;
              @include mquery(1210px) {
                margin-bottom: 30px;
                float: none;
                display: inline-block;
                text-align: center;
              }
              @include mquery(736px) {
                  margin-left: 0;
                  margin: 10px;
              }

            }
        } /* Footer copyright ends here */
    } /* Footer inner div ends here */
    // @include mquery(1210px) {
    //     padding: 30px 10px;
    // }
}

.footer-content {
  padding: 10px;
  background-color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  font-size: 15px;
  opacity: 0.75;
  @include mquery(736px) {
      padding: 1rem 4.5rem 1rem 1rem;
      text-align: left;
      font-size: .75rem;
  }
  @include respond-to(medium-screens) {
      padding: 1rem 4.5rem 1rem 1rem;
      font-size: 17px;
      text-align: left;
  }
}

.footer-social-btns {
    // float: right;
    margin: 0px;
    padding: 0px;
    > h1 {
        // float: left;
        margin: 20px 0px 0px;
        padding: 0px;
        font-family: 'latoregular';
        font-size: 14px;
        color: #333;
        @include mquery(1210px) {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 30px;
        }
    }
    > div {
        // float: left;
        margin-top: 20px;
        padding: 0px;
        > a {
            float: left;
            height: 25px;
            width: 24px;
            margin-right: 20px;
            @include mquery(1210px) {
              margin-left: 20px;
            }
            @include transition(.3s);
            &.footer-fb {
                background-image: url(../../images/icons/005-facebook-logo.png);
                background-repeat: no-repeat;
            }
            &.footer-tw {
                background-image: url(../../images/icons/twitter-1.png);
                background-repeat: no-repeat;
            }
            &.footer-ins {
                background-image: url(../../images/icons/001-instagram.png);
                background-repeat: no-repeat;
            }
            &.footer-li {
                background-image: url(../../images/icons/linkedin.png);
                background-repeat: no-repeat;
            }
            &.footer-g {
                background-image: url(../../images/icons/003-google-plus.png);
                background-repeat: no-repeat;
            }
        }
    }
    @include mquery(1210px) {
        display: inline-block;
        float: none;
        padding-bottom: 20px;
    }
} /* Footer social buttons ends here */
.mn-input-group-btn {
  @include mquery(736px) {
    white-space: normal;
    display: block;
 }
}

.mn-btn-default {
  @include mquery(736px) {
    width: 260px;
    border-radius: 0;
    margin-top: 10px;
 }
}
