%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%background-property {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

%background-property-right {
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

%background-property-left {
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
