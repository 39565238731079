@import 'extensions/variables';
@import 'extensions/mixins';

.xinja-container {
  background-image: url(../../images/xinja-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding: 80px 100px;
  @include mquery(500px) {
    padding: 10px;
  }

  @include mquery(800px) {
    .container {
      flex-direction: column;
    }

    .phone {
      display: block;
    }
  }

  .container {
    display: flex;
    flex-direction: row;

    @include mquery(900px) {
      flex-direction: column;
      width: 100%;
    }

    .phone {
      position: relative;
      width: 306px;
      margin: 0 auto;
      margin-top: 50px;

      img {
        width: 306px;
        height: 617px;
        height: 38.5625rem;
        max-width: none;
      }

      iframe {
        position: absolute;
        top: 71px;
        width: 266px;
        left: 20px;
        height: 472px;
        pointer-events: none;
      }

      @include mquery(1000px) {
        img {
          max-width: none;
          // width: 250px;
          height: auto;
          // margin-top: 50px;
        }

        iframe {
          top: 70px;
          // width: 13.525rem;
          // left: 17px;
        }
      }

      @include mquery(550px) {
        width: 250px;

        img {
          width: 250px;
        }

        iframe {
          top: 59px;
          width: 216px;
          height: 384px;
          left: 17px;
        }
      }
    }

    .text-content {
      padding: 0px 60px;
      margin-left: 80px;

      @include mquery(1030px) {
        margin-left: 0;
      }

      h1 {
        color: #fff;
        font-family: "Montserrat";
        font-weight: 300;
        font-size: 3rem;
        line-height: 1.2;
        margin-bottom: 40px;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.5;
        color: #fff;
        margin-bottom: 20px;
      }

      @include mquery(1000px) {
        padding: 30px;

        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 1.2rem;
        }

      }

      p.nb {
        font-size: 15px;
        margin-top: 50px;
      }

      @include mquery(800px) {
        padding: 50px 0;

        h1 {
          font-size: 2rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      .xinja-form-fields {
        margin-top: 40px;
        display: flex;
        flex-direction: row;

        @include mquery(800px) {
          flex-direction: column;
        }

        #xinja-name, #xinja-email {
          padding: 10px;
          border-radius: 4px;
          margin-right: 20px;
          font-weight: 400;
          height: 50px;
          border: none;

          @include mquery(800px) {
            flex-direction: column;
            margin-bottom: 30px;
          }
        }

      }
    }
  }
}

.mn-xinja-btn {
  color: #fff;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  background: none;
  border: none;
  background-color: #ab337a;
  padding: 10px 20px;
  border-radius: 11px;

  &:hover {
    background-color: #c14b90;
    opacity: 0.7;
    transition: opacity 0.5s linear;
  }
}

.xinja-landing-page-text {

  article {
    padding-top: 100px;
    padding-bottom: 100px;

    .container {
      padding-right: 30px;
      padding-left: 30px;
    }

    h2 {
      min-width: 150px;
      margin: 0px 0px 35px;
      padding: 0px 0px 10px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      font-family: 'latolight';
      font-size: 34px;
      line-height: 41px;
      color: #FFF;

      @include mquery(650px) {
        font-size: 26px;
        line-height: 36px;
      }
    }

    p {
      font-size: 20px;
      line-height: 30px;
      color: #FFF;
      margin-bottom: 28px;
    }
  }

  .ground-floor {
    background-image: url('../../images/xinja-build-own-bank.jpg');
    background-position: center;
    background-size: cover;

    .ground-floor-content {
      width: 45%;
    }

    @include mquery(800px) {
      //background-image: url('../../images/girl-bg-mobile.jpg');
      background-position: left;
      .ground-floor-content {
        width: 100%;
      }
    }
  }

  .so-why {
    h2, p {
      color: #333;
    }
  }
}

section.xinja-form-below {
  background-image: url(../../images/xinja-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
  height: 500px;
  min-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin: 20px auto;
    margin-bottom: 25px;
    width: 350px;
  }

  .form {
    width: 560px;

    h2 {
      min-width: 150px;
      margin: 0px 0px 35px;
      padding: 0px 0px 10px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      font-family: 'latolight';
      font-size: 34px;
      line-height: 41px;
      color: #FFF;
    }

    @media (max-width: 630px) {
      width: 100%;
      padding: 20px;

      p {
        width: 100%;
        padding: 20px;
      }

    }

    input {
      border: 1px solid #fff;
      width: 100%;
      padding: 20px;
      height: 50px;
      font-size: 16px;
      font-family: 'latolight';
      color: #fff;
      margin-top: 30px;
      margin-bottom: 8px;
      background: rgba(255, 255, 255, 0.4);

      &.error {
        border-left: 2px solid #DD1C52;
        animation: fadein 2s;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #fff;
      letter-spacing: 1px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #fff;
      letter-spacing: 1px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #fff;
      letter-spacing: 1px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #fff;
      letter-spacing: 1px;
    }

    #partnerSubmit {
      background-color: #44A4E7;
      margin: 0 auto;
      display: inherit;
      margin-top: 30px;
      height: 50px;
      font-size: 16px;
      font-family: 'latolight';
      width: 200px;
      color: #fff;
      border: none;

      &[disabled]:hover {
        cursor: progress;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#social-proof {
  padding-top: 80px;
  padding-bottom: 120px;
  background-color: #fff;

  h2 {
    min-width: 150px;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-family: 'latolight';
    font-size: 34px;
    line-height: 41px;
    color: #333;
    display: block;
  }

  img {
    vertical-align: middle;
    height: auto;
    width: auto;
    margin: 0 auto;

    @include mquery(992px) {
      max-width: 250px;
    }
  }

  .brands-item {
    min-height: 70px;

    a {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 0 auto;
      text-align: center;
      justify-content: center;
    }
  }
}

#partnerForm {
  p.nb {
    font-size: 15px !important;
    line-height: 18px !important;
  }
}

// Xinja 2019 landing page css /////////////////////////////////////////////////////////

#xinja-hero-banner {
  background: url(/images/landing-pages/xinja_nov_18_landing_page_general_top_section_2000x.png) no-repeat center;
  background-size: cover;
  display: table;
  position: relative;
  width: 100%;
}
.xinja-hero-height-600 {
  height: 600px;
}
.xinja-hero-height-680 {
  height: 680px;
}

.xinja-hero-height-100 {
  height: calc(100vh - 70px);
}

.xinja-hero-height-540 {
  height: 540px;
}


.xinja-logo {
  width: 200px;
  margin-bottom: 20px;
  margin-bottom: 34px;
  @include mquery(600px) {
    width: 160px;
    margin-bottom: 24px;
  }
}
.xinja-hero-title {
  text-align: left;
  margin-bottom: 34px;
  font-family: 'latoregular';
  @include mquery(768px) {
    text-align: center;
  }
}
.xinja-size-70 {
  font-size: 70px;
  @include mquery(600px) {
    font-size: 32px;
  }
}
.xinja-size-40 {
  font-size: 40px;
  @include mquery(600px) {
    font-size: 32px;
  }
}
.xinja-hero-subtitle {
  font-size: 24px;
  text-align: left;
  line-height: 35px;
  margin-bottom: 34px;
  @include mquery(768px) {
    text-align: center;
  }
  @include mquery(600px) {
    font-size: 19px;
    margin-bottom: 24px;
  }
}
.xinja-register-title {
  font-size: 36px;
  margin-bottom: 34px;
  text-align: left;
  @include mquery(600px) {
    font-size: 32px;
    margin-bottom: 24px;
  }
}
.xinja-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-left: 0;
  text-align: center;
}
.xinja-form-field-center-md {
  @include mquery(1024px) {
    margin: auto;
  }
}
.xinja-form-field-center-sm {
  @include mquery(768px) {
    margin: auto;
  }
}
.xinja-risk-warning-note {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #3d3d3c;
}
.xinja-risk-warning-width {
  max-width: 391px;
}
.xinja-risk-warning-center-sm {
  @include mquery(768px) {
    margin: auto;
    text-align: center;
    color: #fff;
  }
}
.xinja-link {
  color: #37c0c7;
  &:hover {
    color: #e41970;
    text-decoration: underline;
  }
}
.xinja-button-style {
  background-color: #37c0c7;
  background: linear-gradient(to right, #37c0c7 0%, #5f6db3 100%);
  border: none;
  &:hover {
    background: linear-gradient(to right, #e41970 0%, #744495 100%);
    background-color: #e41970;
    color: #fff;
  }
}

.xinja-view-offer-btn-cnt {
  @include mquery(768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.xinja-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #37c0c7;
  background: linear-gradient(to right, #37c0c7 0%, #5f6db3 100%);
  border: none;
  color: #fff;
  transition: all 0.5s ease;
  animation: bounce 3s;
  margin-bottom: 20px;
}

.xinja-view-offer-btn:hover {
  background: linear-gradient(to right, #e41970 0%, #744495 100%);
  background-color: #e41970;
  transition: .5s;
}
.xinja-hero-content-cnt {
  position: absolute;
  top: 57px;
  max-width: 560px;
  @include mquery(768px) {
    position: relative;
    margin: auto;
    text-align: center;
  }
}
.xinja-thanks-registering{
  text-align: left;
  font-family: 'latoregular';
  font-size: 40px;
  margin-bottom: 34px;
}
.xinja-return-message-subscription {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 20px;
  text-align: left;
  max-width: 780px;
  margin: auto;
  line-height: 1.5;
  margin-bottom: 20px;
}
.xinja-pink {
  color: #e41970
}
.xinja-text-grey {
  color: #3d3d3c;
}
.xinja-landing-page-content {
  width: 100%;
  line-height: 1.5;
  margin-bottom: 20px;
}
.xinja-text-center-md {
  @include mquery(1024px) {
    text-align: center;
  }
}
.xinja-text-center-sm {
  @include mquery(768px) {
    text-align: center;
  }
}
.xinja-36 {
  font-size: 36px;
}
.xinja-18 {
  font-size: 18px;
}
.xinja-back-grey {
  background-color: #f2f1f2;
}
.important-white {
  color: #fff !important;
}
#xinja-sec-3 {
  background: url(/images/landing-pages/xinja_nov_18_landing_page_general_section_3_option_1_2000x.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  @include mquery(600px) {
    padding: 50px 0;
  }
}
.xinja-list {
  list-style: disc inside;
  @include mquery(1024px) {
    padding-left: 0;
  }
  li:nth-child(1), li:nth-child(5), li:nth-child(9) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_LIGHT_BLUE-02.svg');
  }
  li:nth-child(2), li:nth-child(6), li:nth-child(10)  {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_LILAC-13.svg');
  }
  li:nth-child(3), li:nth-child(7) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_NAVY_BLUE-03.svg');
  }
  li:nth-child(4), li:nth-child(8) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_PINK-01.svg');
  }
  li {
    margin-bottom: 10px;
    line-height: 1.7rem;
  }
  li::before {
    content: "";
    padding-left: 10px;
  }
}
.xinja-list-2 {
  list-style: disc inside;
  li:nth-child(1), li:nth-child(5), li:nth-child(9) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_LIGHT_BLUE-02.svg');
  }
  li:nth-child(2), li:nth-child(6), li:nth-child(10)  {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_LILAC-13.svg');
  }
  li:nth-child(3), li:nth-child(7) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_NAVY_BLUE-03.svg');
  }
  li:nth-child(4), li:nth-child(8) {
    list-style-image: url('/images/landing-pages/xinja-ninja_emojis_PINK-01.svg');
  }
  li {
    margin-bottom: 10px;
    line-height: 1.7rem;
  }
  li::before {
    content: "";
    padding-left: 10px;
  }
}
.xinja-empty-container {
  padding: 10px 0;
  @include mquery(1024px) {
    padding: 0;
  }
  @include mquery(768px) {
    padding: 30px 0;
  }
  @include mquery(600px) {
    padding: 60px 0;
  }
}
.xinja-raise-empty-container {
  padding: 10px 0 100px;
  @include mquery(1024px) {
    padding: 70px 0 100px;
  }
}
.xinja-2-container {
  padding: 70px 0 40px;
  @include mquery(768px) {
    padding: 10px 0 40px;
  }
}
#xinja-sec-4 {
  padding: 100px 0;
  @include mquery(600px) {
    padding: 50px 0;
  }
}
.xinja-sec-5-content {
  padding: 100px 0;
  @include mquery(600px) {
    padding: 50px 0;
  }
}
#xinja-last-form-sec{
  padding: 100px 0;
  @include mquery(600px) {
    padding: 50px 0;
  }
}

.xinja-video-wrap-2 {
  .xinja-mobile-video-wrap-2 {
    position: absolute;
    top: 70px;
    right: 23px;
    width: 306px;
    margin: 0 auto;
    margin-top: 50px;
    @include mquery(1440px) {
      top: 40px;
    }
    @include mquery(768px) {
      position: relative;
      margin: auto;
      right: 0;
      top: 130px;
    }
    .xinja-thumb-2 {
      width: 306px;
      height: 617px;
      max-width: none;
    }
    .xinja-video-2 {
      position: absolute;
      top: 70px;
      width: 268px;
      left: 19px;
      height: 475px;
    }
  }
}

.xinja-video-wrap {
  position: absolute;
  overflow: hidden;
  top: 130px;
  width: 317px;
  right: 23px;
  @include mquery(1440px) {
    top: 40px;
  }
  @include mquery(768px) {
    position: relative;
    margin: auto;
    width: 267px;
    right: 0;
    top: 130px;
  }
  .xinja-thumb {
    position: relative;
    z-index: 2;
    margin: 0;
    pointer-events: none;
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
  .xinja-video {
    position: absolute;
    left: 18px;
    top: 13px;
    right: 17px;
    bottom: 13px;
    background: #fff;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
    }
  }
}
