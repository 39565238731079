@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
NOTIFICATIONS
---------------------------------------------------------- */ 
.notifications-container {
    float: left;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    .single-notification {
        float: left;
        width: 100%;
        min-height: 65px;
        margin: 15px 0px 0px 0px;
        padding: 0px 0px 0px 80px;
        background-color: $form-group-bg;
        position: relative;
        &:first-of-type {
            margin-top: 0px;
        }
        > img {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 65px;
            height: 65px;
            margin: 0px;
            padding: 0px;
        }
        > div {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            > span {
                float: left;
                width: 100%;
                margin: 15px 0px 0px 0px;
                padding: 0px;
                font-family: 'latoregular';
                font-size: 14px;
                color: $default-text-color;
            }
            > p {
                float: left;
                width: 100%;
                margin: 7px 0px 0px 0px;
                padding: 0px;
                font-family: 'latoregular';
                font-size: 12px;
                color: $default-text-color;
            }
        }
        .remove-notification-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 10px;
            height: 10px;
            margin: 0px;
            padding: 0px;
            background-image: url('../../images/remove-notification-btn.png');
            background-repeat: no-repeat;
            background-position: 0px 0px;
            &:hover {
                background-position: 0px -10px;
            }
        }
    }
}