@import 'extensions/variables';
@import 'extensions/mixins';




#abs-hero-banner  {
  background: url(/images/landing-pages/abs-bg-1-min.jpeg) no-repeat center;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
  display: table;
  position: relative;
  @include mquery(1024px) {
    height: 0;
  }
  .abs-hero-layer  {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: calc(100vh - 70px);
    display: table;
    padding: 70px 0;
    @include mquery(1440px) {
      padding: 20px 0;
    }
    @include mquery(1024px) {
      padding: 140px 0;
    }
    @include mquery(500px) {
      padding: 0;
    }
    .abs-logo {
      margin-bottom: 20px;
      width: 300px;
      @include mquery (1440px) {
        width: 230px;
        margin-top: 20px;
      }
      @include mquery (500px) {
        width: 160px;
      }
    }
  }

  .abs-hero-title {
    font-family: 'latoregular';
    font-size: 36px;
    margin-bottom: 10px;
    line-height: 2.7rem;
    @include mquery (1440px) {
      font-size: 27px;
    }
    @include mquery (768px) {
      font-size: 22px;
      line-height: 2rem;
      margin-bottom: 0;
    }
  }
}

#abs-quote-sec {
  background: url(/images/landing-pages/abs-background_still-min.png) no-repeat;
  background-size: contain;
  background-position: 100% 100%;
  padding: 140px 0;
  @include mquery(500px) {
    padding: 70px 0;
  }
  .abs-quote-header {
    font-size: 48px;
    line-height: 1.1;
    color: #bca090;
    margin: 0 0 20px 0;
    @include mquery(500px) {
      font-size: 30px;
    }
  }

  .abs-quote-subheader {
    font-size: 28px;
    line-height: 1.4;
    color: #bca090;
    margin-bottom: 40px;
    @include mquery(500px) {
      font-size: 20px;
    }
  }

  .abs-quote-info {
    font-size: 20px;
    line-height: 1.5;
    color: #bca090;
    margin-bottom: 20px;
    @include mquery(500px) {
      font-size: 17px;
    }
  }

  .abs-border-left {
    border-left: 1px solid #423a35;
    padding-left: 90px;
    @include mquery(1024px) {
      padding-left: 20px;
    }
  }
}


#abs-first-carousel {
  background: url(/images/landing-pages/abs-bg-2-min.jpeg) no-repeat;
  background-position: 50% 50%;;
  background-size: cover;
  background-attachment: fixed;
  min-height: 50vh;
  @include mquery(1024px) {
    min-height: 30vh;
  }
  @include mquery(768px) {
    background-attachment: initial;
  }
}

#abs-about-sec {
  background: url(/images/landing-pages/home-restaurant-bg-min.png) no-repeat;
  background-color: #fff;
  background-position: 100% 100%;
  background-size: cover;
  padding: 140px 0;
  @include mquery(500px) {
    padding: 70px 0;
  }
  .abs-bottlelineup-img {
    width: 100%;
    margin-bottom: 50px;
  }

  .abs-fisrt-info-row {
    margin-bottom: 80px;
    @include mquery(1024px) {
     margin-bottom: 0;
    }
  }
}


#abs-about-equitise-carousel {
  background: url(/images/landing-pages/abs-bg-3-min.jpeg)no-repeat center;
  background-attachment: fixed;
  width: 100%;
  background-size: cover;
  position: relative;
  @include mquery(768px) {
    background-attachment: initial;
    background-position: 20%;
  }
  .abs-paralax-layer {
    height: 100%;
    background-color: rgba(1, 1, 1, 0.5);
    padding-top: 70px;
    padding-bottom: 70px;
  }
}


#abs-last-form-sec {
  background: #1d1d1d;
  padding-top: 70px;
  padding-bottom: 70px;
}


.abs-text-color {
  color: #bca090;
}

.abs-text-darker-color {
  color: #232222;
}

.abs-button-style {
  background-color: #8a7468;
  border: 1px solid #8a7468;
  transition: .5s;
  &:hover {
    background-color: #372f2c;
    border: 1px solid #372f2c;
  }
}

.abs-link {
  color: #8a7468;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: #372f2c;
  }
}

.abs-white-link {
  color: #fff;
  text-decoration: underline;
  &:hover {
    color: #8a7468;
    text-decoration: underline;
  }
}

.abs-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mquery(1200px) {
    display: block;
  }
}
