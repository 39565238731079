@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
TERMS AND CONDITIONS
---------------------------------------------------------- */
.text-page-section {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 30px 0px;
    .mn-agreement-p {
      color: $default-text-color;
      font-family: "latolight";
      font-size: 15px;
      line-height: 150%;
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
    }
    p {
        color: $default-text-color;
        float: left;
        font-family: "latolight";
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 40px;
        padding: 0;
        width: 100%;

        > a {
            color: #469edc;
            &:hover {
                text-decoration: underline
            }
        }
    }
    span.tp-heading {
        color: #7e7e7e;
        float: left;
        font-family: "latoregular";
        font-size: 20px;
        margin: 50px 0 0;
        padding: 0 0 20px;
        width: 100%;
    }

    ul.tp-list {
        float: left;
        margin: 0px;
        padding: 20px 0px 20px 55px;
        > li {
            float: left;
            clear: both;
            // list-style: outside none lower-roman;
            margin: 20px 0px 0px 0px;
            padding: 0px;
            font-family: "latolight";
            font-size: 15px;
            color: $default-text-color;
            list-style: lower-roman;
            &:first-of-type {
                margin-top: 0px;
            }
            a {
                color: #469edc;
                &:hover {
                    text-decoration: underline
                }
            }
        }
    }
}
.print-button {
    float: right;
    width: 100px;
    height: 45px;
    margin: 30px 0px 0px 0px;
    padding: 0px;
    font-family: 'latobold';
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    border: 0px;
    background-color: $default-blue-color;
    @include transition(.3s);
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
}
