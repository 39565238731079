@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
MY SYNDICATES
---------------------------------------------------------- */
.my-syndicates-wrapper {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px 340px 0px 0px;
    position: relative;
    @include mquery(960px) {
        padding: 0px;
    }
    &.mysindy-outer{
        .my-synd-save-wrap{
            position: absolute;
            top: 20px;
            right: 20px;
            text-align: right;

            @include mquery(580px) {
                position: relative;
                width: 100%;
                margin: 0 20px 20px;
                text-align: left;
                a {
                    margin-top: 0;
                }
            }
            .default-submit-button.large {
                display: inline-block;
                float: none;
            }
        }
    }
    .reg-log-title, .reg-log-subtitle {
        display: block;
        float: none;
    }

    .ms-leftcol.cr-leftcol{
        padding-top: 20px;
    }
}
.ms-leftcol {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 20px;
    background-color: $mySyndicates-leftcol-bg;
    position: relative;
}
.ms-rightcol {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    max-width: 320px;
    margin: 0px;
    padding: 0px;
    @include mquery(960px) {
        position: relative;
        right: auto;
        top: auto;
        float: left;
        max-width: 100%;
        padding: 0px;
    }
    & .default-rightcol-widget:first-of-type {
        margin-top: 0px;
        @include mquery(960px) {
            margin-top: 15px;
        }
    }
}
.my-syndicates-container {
     float: left;
     width: 100%;
     margin: 30px 0px 0px 0px;
     padding: 0px;
}
.single-mysyndicates-block {
    float: left;
    width: 100%;
    min-height: 150px;
    margin: 15px 0px 0px 0px;
    padding: 0px 0px 0px 150px;
    position: relative;
    background-color: $up-cr-block-bg;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    &:first-of-type {
        margin-top: 0px;
    }
    .long, .short {
        font: inherit;
    }
    .short {
        display: none;
    }
    @include mquery(560px) {
        padding: 0px 0px 0px 115px;
    }
    @include mquery(480px) {
        padding: 120px 0 0 0;
        .long {
            // display: none;
        }
        .short {
            display: unset;
        }
    }
    &.raising-syndi-blocks{
        > div{
            @include mquery(480px) {
                min-height: 110px;
            }
            .ms-cr-info-action{
                @include mquery(480px) {
                    width: 100% !important;
                }
                span,
                p{
                    @include mquery(620px) {
                        float: none;
                        display: inline;
                        padding: 0 5px 0 0;
                    }
                }
            }
        }
    }
    > img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 150px;
        height: 150px;
        margin: 0px;
        padding: 0px;
        @include mquery(560px) {
            // top: 10px;
            left: 10px;
            width: 110px;
            height: 110px;
        }
        @include mquery(480px) {
            left: 50%;
            margin-left: -55px;
        }
    }
    .ms-status {
        position: absolute;
        top: 0px;
        right: 20px;
        height: 19px;
        font-family: 'latoregular';
        font-size: 11px;
        color: #ffffff;
        margin: 0px;
        padding: 3px 10px;
        background-color: $cr-role-bg;
        width: 90px;
        text-align: center;
        &.active {
            background-color: #aad155;
        }
        &.pending {
            background-color: #f8b93e;
        }
        &.disapproved {
            background-color: #ec5f73;
        }
        &.draft {
            background-color: #8f9ea9;
        }
        &.complete {
            background-color: $default-blue-color;
        }
        &.closed {
            background-color: #ec5f73;
        }
    }
    > div {
        float: left;
        width: 100%;
        min-height: 150px;
        margin: 0px;
        padding: 20px 15px 50px 15px;
        position: relative;
        @include mquery(1050px) {
            padding: 20px 15px;
        }
        @include mquery(620px) {
            padding-bottom: 60px !important;
        }
        @include mquery(480px) {
            padding: 10px !important;
        }
        // @include mquery(490px) {
        //    padding: 20px 15px;
        // }
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px 70px 0px 0px;
            font-family: 'latoblack';
            font-size: 19px;
            color: $default-text-color;
            @include mquery(620px) {
                padding: 0;
            }
            @include mquery(480px) {
                font-size: 16px;
                text-align: center;
            }
        }
        > p {
            float: left;
            width: 100%;
            margin: 5px 0px 0px 0px;
            padding: 0px;
            font-family: 'latoregular';
            font-size: 13px;
            color: $default-text-color;
            line-height: 18px;

            @include mquery(620px) {
                min-height: 105px;
            }
            @include mquery(560px) {
                min-height: 72px;
            }
            @include mquery(480px) {
                min-height: auto;
                text-align: center;
            }
        }
         .single-ms-action-buttons {
            position: absolute;
            bottom: 15px;
            right: 0px;
            margin: 0px;
            padding: 0px 15px;
            margin: 0px;

            a {
                margin-right: 0;
            }

            @include mquery(620px) {
                // position: absolute !important;
                // left: -145px !important;
                // bottom: 25px !important;
                // width:  auto !important;

            }
            @include mquery(560px) {
                // left: -105px !important;
            }

             @include mquery(480px) {

                 display: flex;
                 flex-wrap: wrap;
                 justify-content: space-around;
                 position: static !important;
                 width: 100% !important;
                 margin: 0 !important;

                 a {
                    //  flex: 1;
                    //  padding-right: 15px !important;
                     margin: 10px auto;
                 }
             }

             @include mquery(350px) {
                 a {
                     padding-left: 5px !important;
                    //  display: flex;
                     flex-direction: column;
                     justify-content: center;
                 }
             }
            //@include mquery(480px) {
            //    position: relative !important;
            //    left: auto !important;
            //    bottom: auto !important;
            //    width:  auto !important;
            //    margin: 0;
            //}
            //@include mquery(490px) {
            //    position: relative;
            //    bottom: auto;
            //    right: auto;
            //    margin: 20px 0px 0px 0px;
            //    padding: 0px;
            //    float: left;
            //}
            &.my-syndicates-listing{
                .ms-see-offer-btn {
                    color: $mySyndicates-text-lightcolor;
                    background-color: $default-blue-color;
                    background-image: url("../../../images/white-button-arrow.png");
                }
            }
            > a {
                float: right;
                margin: 0px 0px 0px 10px;
                padding: 6px 35px 5px 10px;;
                min-height: 26px;
                font-family: 'latoblack';
                font-size: 11px;
                text-transform: uppercase;
                color: $default-blue-color;
                text-decoration: none;
                background-image: url('../../images/blue-button-arrow.png');
                background-repeat: no-repeat;
                background-position: right 10px center;
                @include border(full, 1px, solid, $default-blue-color);
                @include transition(.3s);
                &:hover {
                    background-position: right 7px center;
                }
                @include mquery(490px) {
                    margin: 0px 10px 0px 0px;
                    background-image: none;
                    padding: 6px 10px;
                    margin-right: 0 !important;
                }
            }
            @include mquery(1050px) {
                bottom: auto;
                right: auto;
                float: left;
                width: 100%;
                padding: 0px;
                margin: 5px 0px 0px 0px;
                position: relative;
                > a {
                    margin: 10px 10px 0px 0px;
                }
                > a:last-of-type {
                    // margin-right: 0px;
                    margin: 10px auto;
                }
            }
         }
         .ms-cr-info-action {
            position: absolute;
            bottom: 15px;
            left: 15px;
            margin: 0px;
            padding: 0px;
            @include mquery(1050px) {
                float: left;
                position: relative;
                left: auto;
                bottom: auto;
                margin-top: 10px;
            }
            @include mquery(620px) {
                position: absolute !important;
                left: -145px !important;
                bottom: 7px !important;
            }
            @include mquery(560px) {
                left: -105px !important;
            }
            @include mquery(480px) {
                position: relative !important;
                left: auto !important;
                bottom: auto !important;
                width:  auto !important;
            }
            > span {
                float: left;
                margin: 0px;
                padding: 0px;
                font-family: 'latobold';
                font-size: 14px;
                color: $default-text-color;
                clear: both;
                @include mquery(620px) {
                    font-size: 12px;
                }
            }
            > a {
                float: left;
                margin: 3px 0px 0px 0px;
                padding: 0px;
                font-family: "latoregular";
                font-size: 11px;
                color: $default-blue-color;
                text-decoration: none;
                clear: both;
                &:hover {
                    text-decoration: underline;
                }
            }
            > p {
                float: left;
                margin: 3px 0px 0px 0px;
                padding: 0px;
                font-family: "latoregular";
                font-size: 11px;
                color: $default-text-color;
                text-decoration: none;
                clear: both;
            }
         }
    }
} /* single-mysyndicates-block ends here */

/* Landing Page Syndicates section */
.landing-top-btns{
    float: left !important;
    max-width: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center !important;
    &.blue{
        a{
            background-color: $htw-btn-border;
            &:hover {
                background-color: $htw-btn-blue-hover;
                @include border(full, 1px, solid, $htw-btn-blue-hover);
            }
        }
    }
    a{
        float: none !important;
        display: inline-block;
        max-width: 215px;
        margin: 0 15px;
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        padding: 10px 35px 10px 15px;
        color: $htw-text;
        font-family: 'latoregular';
        @include border(full, 1px, solid, $htw-btn-border);
        @include transition(.3s);

        &:hover {
            background-color: $htw-btn-bg;
        }
        @include mquery(580px) {
            font-size: 12px;
            margin: 0 5px;
            max-width: 180px;
        }
        @include mquery(420px) {
            margin: 0 10px 15px ;
        }
        &:first-child{
            margin-left: 0;
            @include mquery(580px) {
                margin-left: 0;
            }
            @include mquery(420px) {
                margin: 0 10px 15px;
            }
        }
        &:last-child{
            margin-right: 0;
            @include mquery(580px) {
                margin-right: 0;
            }
            @include mquery(420px) {
                margin: 0 10px 0;
            }
        }
        &.landing-browse-btn{
            background-image: url('../../../images/synd-browse-btn-icon@2x.png');
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: right 7px center;
        }
        &.landing-lead-btn{
            background-image: url('../../../images/synd-lead-btn-icon@2x.png');
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: right 7px center;
        }
    }
}
#home-top-wrapper {
    width: 100%;
    height: calc(100vh - 70px);
    background-image: url(../../images/home-top-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    display: table;
    overflow: hidden;
      @include mquery(768px) {
        height: 50%;
        padding: 50px 0;
      }

}
#home-top-wrapper.landing-syndicates-top-wrap{
    background-image: url('../../../images/syndicates-lp.jpg');
    .htw-inner{
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      .mn-syndicate-title {
        font-size: 48px;
        font-family: latoregular;
        margin-bottom: 22px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        max-width: 780px;
        padding-right: 15px;
        padding-left: 15px;
        @include mquery(768px) {
          font-size: 36px;
        }
      }
       .mn-syndicate-subtitle {
          font-size: 24px;
          font-family: latolight;
          max-width: 780px;
          margin-bottom: 40px;
          line-height: 30px;
          text-align: center;
          margin: auto;
          padding-right: 15px;
          padding-left: 15px;
          @include mquery(768px) {
            font-size: 18px;
          }
       }

    }

    .syndicate-top-btns-cnt {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .syndicate-center-blue-btn {
        width: 253px;
        height: 55px;
        background-color: #469edc;
        padding: 20px;
        color: #ffffff;
        text-align: center;
        font-size: 15px;
        font-family: latoregular;
        margin-top: 40px;
        transition: .3s;
        margin-right: 20px;
        &:hover {
          background-color: rgba(70,158,220, .9);
        }
        @include mquery(560px) {
           margin-right: 0;
           margin-top: 15px;

        }

      }

      .syndicate-center-white-btn {
        width: 253px;
        height: 55px;
        background-color: transparent;
        border: 1px solid #fff;
        padding: 20px;
        color: #fff;
        text-align: center;
        font-size: 15px;
        font-family: latoregular;
        margin-top: 40px;
        transition: .3s;
        @include mquery(560px) {
           margin-top: 15px;
        }
        @include transition(.3s);
        &:hover {
            background-color: $htw-btn-bg;
            border: 1px solid $default-blue-color;
            color: $txt-white;

        }
      }
    }
}

#home-top-wrapper.landing-syndicates-top-wrap{
    background-image: url('../../../images/syndicates-lp.jpg');
    .syndicates-inner{
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;

      .mn-syndicate-title {
        font-size: 48px;
        font-family: latoregular;
        margin-bottom: 22px;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        max-width: 780px;
        padding-right: 15px;
        padding-left: 15px;
        @include mquery(768px) {
          font-size: 38px;
        }
        @include mquery(600px) {
          font-size: 32px;
        }
      }
       .mn-syndicate-subtitle {
          font-size: 24px;
          font-family: latolight;
          max-width: 780px;
          margin-bottom: 40px;
          line-height: 30px;
          text-align: center;
          margin: auto;          
          padding-right: 15px;
          padding-left: 15px;
          @include mquery(768px) {
            font-size: 18px;
          }
       }

    }
}

.syndicates-top-faq-link {
  color: $txt-white;
  margin: auto;
  display: block;
  margin-top: 30px;
  text-align: center;
  transition: .3s;
  &:hover {
    color:$default-blue-color;
  }
}

.general-link{
    float: none;
    display: inline-block;
    margin: 0;
    padding: 0 35px 0 10px;;
    min-height: 25px;
    line-height: 25px;
    font-family: "latobold";
    font-size: 14px;
    text-transform: uppercase;
    color: $default-blue-color;
    text-decoration: none;
    background-image: url('../../images/blue-button-arrow.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
    @include border(full, 1px, solid, $default-blue-color);
    @include transition(.3s);
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    &:hover {
        background-position: right 7px center;
    }
}
.green-link{
    float: none;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    margin: 0;
    padding: 0 5px;
    // font-family: 'latoblack';
    color: $default-submitBtn-color;
    background-color: $default-save-btn-bg;
    @include transition(.3s);
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    &:hover {
        background-color: lighten($default-save-btn-bg, 5%);
    }
}
.dark-blue-link{
    float: none;
    display: inline-block;
    width: 100%;
    max-width: 250px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    margin: 0;
    padding: 0 5px;
    font-family: 'latolight';
    color: $txt-white;
    background-color: $main-darker-bg;
    @include transition(.3s);
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    &:hover {
        background-color: lighten($main-darker-bg, 5%);
    }
}
.landing-top-faq-link{
    float: left;
    color: $htw-text;
    font-family: "latoregular";
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 2px;
    padding: 0;
    @include transition(.3s);
    &:hover{
        color: $htw-btn-border;
        text-decoration: underline;
    }
    @include mquery(530px) {
        display: inline-block;
        float: none;
        margin: 0;
    }
    @include respond-to(medium-screens) {
        text-align: center;
        float: none;
    }

}
.homepage-cta-wrapper.landing-page {
    > div{
        margin-top: 0;
    }
}
.syndicate-hiw-blocks-wrapper.synd-landing-blocks-out{
    margin: 0;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include mquery(650px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 0;
    }

    .shiw-block{
        @include mquery(1020px) {
            max-width: 250px;
        }
        @include mquery(860px) {
            // margin-top: 20px;
            margin: 0;
        }
        @include mquery(800px) {
            max-width: 220px;
        }
        @include mquery(710px) {
            margin: 10px;
        }
        @include mquery(510px) {
            max-width: 300px;
            margin: 0 0 10px !important;
        }
        &:first-of-type{
            @include mquery(860px) {
                margin-right: 5px;
            }
        }
        &:last-of-type{
            @include mquery(860px) {
                margin-left: 5px;
            }
        }
    }
}

.syndicate-top-btns-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .syndicate-center-blue-btn {
    width: 253px;
    height: 55px;
    background-color: #469edc;
    padding: 20px;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-family: latoregular;
    margin-top: 40px;
    transition: .3s;
    margin-right: 20px;
    &:hover {
      background-color: rgba(70,158,220, .9);
    }
    @include mquery(560px) {
       margin-right: 0;
       margin-top: 15px;

    }

  }

  .syndicate-center-white-btn {
    width: 253px;
    height: 55px;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 20px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-family: latoregular;
    margin-top: 40px;
    transition: .3s;
    margin-right: 20px;
    @include mquery(560px) {
      margin-right: 0;
      margin-top: 15px;


    }
    @include transition(.3s);
    &:hover {
        background-color: $htw-btn-bg;
        border: 1px solid $default-blue-color;
        color: $txt-white;

    }
  }
}
// New Contant style on Landing Syndicate page
.contact-syndicate.syndicate-landing{
    float: left;
    width: 100%;
    padding: 60px 0;
    background-color: #ebebeb;
    .contact-syndicate-heading{
        float: left;
        width: 100%;
        padding: 0 5px;
        margin: 0;
        text-align: center;
        font-family: 'latobold';
        font-size: 18px;
        color: #333;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        @include mquery(580px) {
            font-size: 15px;
        }
    }
    .syndicate-contact-blake{
        float: left;
        width: 100%;
        padding: 30px 0 0;
        margin: 0;
        font-size: 20px;
        color: $htw-text;
        @include mquery(580px) {
            font-size: 16px;
        }
        span{
            float: left;
            width: 100%;
            padding: 0px 10px 0;
            margin: 0;
            text-align: center;
            font-family: "latoregular";
            font-size: 18px;
            line-height: 150%;
            //color: $home-section-title-bg;
            -webkit-box-sizing:border-box;
            -moz-box-sizing:border-box;
            box-sizing:border-box;
            &.bold{
                font-family: 'latobold';
                font-size: 25px;
                @include mquery(580px) {
                    font-size: 20px;
                }
            }
            &.bottom{
                padding-top: 20px;
                font-size: 18px;
                @include mquery(580px) {
                    font-size: 15px;
                }
            }
        }
    }
}
// New Contant style on Landing Syndicate page - end
.home-section.landing-page > div{
    max-width: 1300px;
    margin: 0 auto;
}
.what-wedo-section.landing-page{
    p.home-section-title{
        margin: 0;
        padding: 0;
    }
}
.what-wedo-section.landing-page > div > div.landing-featured-box-out{
    max-width: 1300px;
    padding: 60px 0 50px;
    text-align: center;
    > div{
        float: none;
        display: inline-block;
        width: 100%;
        max-width: 300px;
        margin: 0 7px 10px;;
        background-color: #ffffff;
        padding: 5px;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        @include mquery(580px) {
            margin: 0 0 10px;
        }
        .shiw-block{
            margin: 0;
            padding-top: 0;
            width: 100%;

            img {
                border-radius: 50%;
                margin-top: 15px;
            }

            @include mquery(580px) {
                min-height: 320px;
            }
        }
    }
    .syndicate-inner-cont{
        text-align: center;
        > a {
            float: none;
            display: inline-block;
            color: #898888;
            font-family: "latobold";
            font-size: 16px;
            margin: 0 0 15px;
            padding: 0;
            text-align: center;
            text-transform: uppercase;
            line-height: 135%;
            @include transition(.3s);
            &:hover{
                color: $text-link-hover;
            }
        }
    }
}

.lp-syndicates-container .single-mysyndicates-block {
    width: 49%;
    &:first-of-type {
        margin-top: 15px !important;
    }
    &:nth-child(odd) {
        margin-right: 0.5%;
    }
    &:nth-child(even) {
        margin-left: 0.5%;
    }
    @include mquery(1055px) {
        width: 100%;
        &:nth-child(odd) {
            margin-right: 0px;
        }
        &:nth-child(even) {
            margin-left: 0px;
        }
    }
}
.lp-syndicate-ia {
    > div {
        float: left;
        margin: 0px;
        padding: 0px 15px 0px 0px;
        > span {
            float: left;
            margin: 0px;
            padding: 0px;
            font-family: 'latobold';
            font-size: 14px;
            color: $default-text-color;
            clear: both;
        }
        > p {
            float: left;
            margin: 3px 0px 0px 0px;
            padding: 0px;
            font-family: "latoregular";
            font-size: 11px;
            color: $default-text-color;
            text-decoration: none;
            clear: both;
        }
    }
}

.syndicate-leads-cont {
    // float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    position: relative;
    .slc-half {
        float: left;
        width: 50%;
        margin: 0px;
        padding: 0px;
        &:first-of-type {
            padding-right: 10px;
        }
        &:last-of-type {
            padding-left: 10px;
            @include mquery(600px) {
                position: absolute;
                top: 0px;
                left: 0px;
            }
        }
        .default-info-tooltip {
            > p {
                line-height: 15px !important;
            }
        }
        .login-steps-container {
            margin-top: 0px;
            padding: 0;
            .ls-alternative-buttons {
                p.button-description {
                    font-family: 'latolight';
                    font-size: 14px;
                    color: #7e7e7e;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
                & > a {
                    background-color: #6aa1c8;
                    text-align: left;
                    background-image: none;
                    position: relative;
                    &:after {
                        top: 18px;
                        right: 15px;
                        position: absolute;
                        content: "";
                        width: 0px;
                        height: 0px;

                        border-left: 10px solid #ffffff;
                        border-top: 6px solid transparent;
                        border-bottom: 6px solid transparent;

                    };
                    &:hover {
                        background-color: #7dadcf;
                    }
                }
                &:first-of-type {
                    margin-top: 0px;
                }
            }
        }
        @include mquery(600px) {
            width: 100%;
            padding: 0px !important;
        }
    }
    @include mquery(600px) {
        padding-top: 185px;
    }
}

.syndicate-lead-explainer {
    margin-top: 0;

    h4 {
        margin-bottom: 25px;
        font-family: 'latobold';
        font-size: 17px;
        color: #6195b9;
    }

    p {
        font-size: 14px;
        line-height: 21px;
    }

    strong {
        margin-top: 12px;
        margin-bottom: 12px;
        display: inline-block;
        font-size: 14px;
        color: #6195b9;
    }
}

 .create-syndicate-description {
    font-family: 'latolight';
    font-size: 14px;
    color: #7e7e7e;
    line-height: 21px;
    margin-bottom: 20px;
}

@media (max-width: 500px) {
    .create-deal {
        display: none;
    }
}

#mo-buttons {
    a.mo-buttons-items {
        height: 26px;
        flex-direction: row-reverse !important;
        margin-right: 10px !important;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: row-reverse;
    }
}
