@import 'extensions/variables';
@import 'extensions/mixins';



.dealsContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 80px;
  padding-bottom: 80px;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonFilter {
    margin-bottom: 50px;
    justify-content: center;
  }

  button.filterButton {
    margin: 10px;
    padding: 10px;
    min-width: 140px;
    font-size: 16px;
    font-family: 'latolight';
    background: none;
    border: 1px solid #469edc;
    color: #469edc;
    border-radius: 2px;
    transition: background 0.3s ease;

    &:hover, &.buttonFilterSelected {
      cursor: pointer;
      background: #469edc;
      color: #fff;
    }
  }

  .selectFilter {
    margin: 10px;
    padding: 10px;
    min-width: 140px;
    font-size: 16px;
    font-family: 'latolight';
    background: none;
    border: 1px solid #469edc;
    color: #469edc;
    border-radius: 2px;
    background: url('../../images/blue-button-arrow-down.png') no-repeat 90%;
  }

  .filterOption {
    width: 44px;
    height: 44px;
    margin: 10px;
    padding: 10px;
    padding-top: 13px;
    padding-bottom: 7px;
    font-size: 16px;
    font-family: 'latolight';
    background: url('../../images/filter-icon.png') no-repeat;
    background-position: center;
    border: 1px solid #469edc;
    color: #469edc;
    border-radius: 2px;
    transition: background-color 0.3s ease;

    &:hover {
      background: url('../../images/filter-icon-hover.png') no-repeat;
      background-color: #469edc;
      background-position: center;
      cursor: pointer;
    }
  }

  .center {
    text-align: center;
  }

  .filterOptions {
    margin: 10px;
    margin-top: 0;
    margin-bottom: 40px;
    padding: 10px;
    padding-top: 0;
    transition: background-color 0.3s ease;
    border-radius: 2px;

    header {
      input[type="text"] {
        border: 1px solid #469edc;
        background: none;
        height: 44px;
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        font-family: 'FontAwesome', 'latolight';
      }
    }

    .filterOptionsContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      .filterOptionsContainerColumn {
        display: flex;
        flex-direction: column;

        h4 {
          text-align: center;
          font-family: 'latolight';
          color: #333;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin: -10px 0 0 -10px;
    margin: -10px 0 0 10px;
    justify-content: center;
    align-items: flex-start;

    .dealSectionItem {
      display: inline-block;
      align-items: flex-start;
      justify-content: flex-start;
      width: calc(100% * (1/4) - 10px - 1px);
      margin-bottom: 20px;
      position: relative;

      @media (max-width: 700px) {
        width: calc(100% * (1/2) - 10px - 1px);
      }

      @media (max-width: 450px) {
        width: calc(100% - 10px - 1px);
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 95%;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(.45,.45,.45,.90);
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .overlayHeader {
          justify-content: flex-start;

          h4 {
            font-family: 'latoregular';
            font-size: 20px;
            line-height: 30px;
            color: #fff;
          }
        }

        .overlayContainer {
          p {
            color: white;
            font-family: 'latolight';
            font-size: 18px;
            line-height: 27px;

            @media (max-width: 450px) {
              display: none;
            }

          }

          .registerInterest {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-content: center;

            input[type=text] {
              margin-bottom: 10px;
              font-size: 15px;
              height: 40px;
              width: 100%;
              border-radius: 2px;
              font-family: 'latolight';
              border: none;
              padding: 10px;
              color: #333;
            }

            .registerButton {
              padding: 10px;
              min-width: 140px;
              font-size: 16px;
              font-family: 'latolight';
              background: #469edc;
              border: 1px solid #469edc;
              color: #fff;
              border-radius: 2px;
              transition: background 0.3s ease;

              &:hover, &.buttonFilterSelected {
                cursor: pointer;
                background: #469edc;
                color: #fff;
              }
            }

            p.thankyou {
              display: block;
            }
          }
        }

        .amountFundedBar {
          width: 100%;
          height: 4px;
          margin-top: 10px;
          margin-bottom: 10px;
          background: #fff;

          .amountFundedTotal {
            width: 75%;
            background: #aad155;
            height: 4px;
          }
        }
      }

      &:hover .overlay {
        opacity: 1;
        cursor: pointer;
      }

      .private {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        padding: 15px;
        padding-bottom: 0;
        margin-top: -60px;

        @media (max-width: 800px) {
          margin-top: -35px;
          padding: 0;
        }

        @media (max-width: 600px) {
          padding: 0;
        }

        img {
          border-radius: 50px;
          width: 60px;
          height: 60px;
          display: inherit;
          margin-left: auto;

          @media (max-width: 900px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 650px) {
            margin: 0 auto;
          }
        }
      }

      .public {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        padding: 15px;
        padding-bottom: 0;
        margin-top: -60px;

        @media (max-width: 800px) {
          margin-top: -35px;
          padding: 0;
        }

        @media (max-width: 600px) {
          padding: 0;
        }

        img {
          border-radius: 50px;
          width: 60px;
          height: 60px;
          display: inherit;
          margin-left: auto;

          @media (max-width: 900px) {
            width: 50px;
            height: 50px;
          }

          @media (max-width: 800px) {
            // width: 40px;
            // height: 40px;
          }

          @media (max-width: 650px) {
            margin: auto;
          }
        }
      }

      article {
        color: #fff;
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media (max-width: 450px) {
          border-top: 1px solid #ccc;
          margin-top: 20px;
        }

        img {
          width: 100%;

          @media (max-width: 450px) {
            display: none;
          }
        }

        &:hover .overlay {
          opacity: 1;
        }

        .articleContainer {
          display: flex;
          flex-direction: column;
          border: 1px solid #ccc;
          border-top: none;
          font-size: 16px;
          line-height: 24px;
          padding: 15px;
          color: #333;
          min-height: 220px;

          @media (max-width: 1000px) {
            min-height: 250px;
          }

          @media (max-width: 450px) {
            min-height: 200px;
          }

          h4 {
            font-family: 'latoregular';
            font-size: 18px;
            line-height: 27px;
          }

          p {
            font-size: 15px;
            line-height: 22.5px;
          }
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .loadMoreButton {
      margin: 10px;
      padding: 10px;
      min-width: 140px;
      font-size: 16px;
      font-family: 'latolight';
      background: none;
      border: 1px solid #469edc;
      color: #469edc;
      border-radius: 2px;
      transition: background 0.3s ease;

      &:hover, &.buttonFilterSelected {
        cursor: pointer;
        background: #469edc;
        color: #fff;
      }
    }

  }
}




/*---------------------------
      Mina CSS
-----------------------------*/


/* deals page  */


//  mn-common css -------------------------------------------

main {
  background-color: #fff;
  }
  .full {
    width: 100%;
    height: 380.5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;

    .mn-country-background {
      width: 250px;
      height: 50px;
      background-color: #ebebeb;
      border-radius: 30px;
      margin: auto;
      text-align: right;
      padding: 15px;
      position: relative;
      margin-bottom: 31px;
      .mn-country-toggle {
        width: 125px;
        height: 44px;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        padding: 12px;
        text-align: center
      }
      .mn-country-toggle.active{
        background-color: #469edc;
        color: #FFFFFF;
      }
    }
    .mn-deal-title {
      font-family: latoregular;
      font-size: 44px;
      text-align: center;
      color: #ffffff;
      margin-top: 43px;
      margin: auto;
      line-height: 40px;
      @include respond-to(medium-screens) {
        font-size: 35px;
      }
      @include mquery(736px) {
        font-size: 29px;
      }
      @include respond-to(x-small-screens) {
        font-size: 24px;
      }
    }

    .mn-deal-subtitle {
        line-height: 35px;
        margin: 34px 0 0 0;
        font-family: 'latolight';
        font-weight: 300;
        font-size: 24px;
        color: #fff;
        text-align: center;
        max-width: 950px;
        padding-right: 20px;
        padding-left: 20px;
        @include respond-to(medium-screens) {
          font-size: 19px;
        }
        @include mquery(736px) {
          font-size: 15px;
          margin: 14px 0 0 0;
        }
        @include respond-to(x-small-screens) {
          font-size: 13px;
        }
    }
  }



  .selectpicker {
    font-size: 16px;
    height: 34px;
    width: 150px;
    padding-left: 10px;
    border: none;
    background-color: white;
    border-bottom: 1px solid #ccc;
    border-radius: 0;

  }
  .selectpicker:not(.is-multiple)::after {
    border: 1px solid #3273dc;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 4;
  }
  .mn-filter-option{
    margin-top: 70px;
    @include mquery(736px) {
        margin-top: 30px;
    }
    .mn-filter-container  {
      margin-bottom: 34px;
      @include respond-to(medium-screens) {
        margin-bottom: 26px;
      }
    }
    .mn-filter-blue {
      background-color:#469edc;
      color: white;
    }
    .mn-deals-filter-title {
      font-size: 27px;
      font-family: latoregular;
      margin-right: 40px;
    }
    .navbar-form {
      margin-left: 254px;
      padding-right: 0;
      @include respond-to(medium-screens) {
        margin-left: 0;
      }
      @include mquery(736px) {
          margin-left: 0;
      }

    }
  }

  .mn-all-delas-container  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

    .mn-tile-container {
      margin-bottom: 30px;
      width: 356px;
      position: relative;
      margin: 12px;
      @include mquery(736px) {
        width: 352px;
      }
      @include mquery(320px) {
        width: 292px;
      }
      .mn-offer-tile-up {
        width: 100%;
        height: 152px;
        margin: 0px;
        padding: 0px;
        // overflow: hidden;
        position: relative;
        .mn-tile-banner {
          width: 100%;
          height: 100%;
          border-top: 1px solid #e1e1e1;
          border-right: 1px solid #e1e1e1;
          border-left: 1px solid #e1e1e1;
        }

        .circle-icon {
          // position: absolute;
          // top: 110px;
          // left: 131px;
        }
        .mn-tile-circle-logo {
          position: absolute;
          top: 100%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);

        }
      }
    }
    .tileHover {
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.15);
    }



      .mn-deal-tile-content {
        border: 1px solid #e1e1e1;
        .mn-tile-content-container {
          padding: 76px 20px 21px;
          background-color: white;
          height: 440px;
          position: relative;
        }

        .underline {
          text-decoration: underline;
        }
        .mn-tile-desc-black {
          color: #333;
          font-size: 16px;
          font-family: latolight;
          text-align: center;
          margin-bottom: 26px;
        }
        .mn-tile-desc-black-live {
          color: #333;
          font-size: 16px;
          font-family: latolight;
          text-align: center;
          margin-bottom: 17px;
        }

        .mn-deals-page-button {
          margin: auto;
          max-width: 277px;
          height: 55px;
          padding: 20px;
          text-align: center;
          font-size: 16px;
          font-family: latoregular;
          margin-top: 16px;
          margin-bottom: 20px;
          cursor: pointer;

        }

        .mn-deals-page-button:hover {
          filter: brightness(85%);
          transition: opacity 0.15s linear;
        }
      }

      .mn-tile-desc {
        font-size: 20px;
        font-family: latoregular;
        text-align: center;

      }


      .mn-margin-4 {
        margin-bottom: 4px;
      }

      .mn-margin-40 {
        margin-bottom: 40px;
      }




  //
  // .btn-default {
  //   border: none;
  //   padding: 4.5px 12px;
  // }

  .mn-form {
    position: relative;
    @include respond-to(medium-screens)  {
      text-align: center;
    }
  }

  .mn-glyphicon {
    width: 20px;
    @include mquery(736px) {
      width: 20px;
      position: absolute;
      top: -4px;
      left: 312px;
    }
    @include mquery(400px)  {
      left:254px;
    }
  }
  .mn-filter-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    .select-box {
      margin: 10px 10px 10px 19px;
      padding: 10px;
      min-width: 170px;
      font-size: 16px;
      font-family: 'latolight';
      background: none;
      border: none;
      border-bottom: 1px solid #469edc;
      color: #469edc;
      border-radius: 2px;
      @include mquery(736px) {
        min-width: 102px;
      }

     }
     .select-box-wide {
       @include mquery(736px) {
         min-width: 140px;
       }
     }
     .white-arrow {
       background: url(../../images/white-arrow-horiz.png) no-repeat 90%;
     }
     .blue-arrow {
       background: url(../../images/blue-button-arrow-down.png) no-repeat 90%;
     }

  }

  .search-container {
    margin-top: 7px;
    @include respond-to(medium-screens) {
      // float: right;
    }
    @include mquery(736px) {
      margin-top: 20px;
    }
    .search-box {
     border:none;
     border-bottom: 1px solid #469edc;
     padding: 8px;
     font-size: 17px;
     font-family: latolight;
     color: #469edc;
     width: 314px;
     margin-left: 190px;
     @include respond-to(medium-screens) {
       width: 364px;
       margin-left: 0;
     }
     @include mquery(736px) {
       margin-top: -13px;
       margin-left: 0px;
       margin-right: 40px;
       width: 304px;
       float: right;

     }
     @include mquery(400px) {
       width: 246px;
     }
    }
    input::placeholder {
     color: #469edc;
    }
    button {
     border: none;
     background-color: white;
     @include mquery(736px) {
         margin-top: 10px;
     }
    }

  }

   .mn-label {
     color: #7e7e7e;
   }

   .loading-snipper {
     height: 561px;
     width: 100%;

     .loading-snipper-img{
       display: block;
       margin: auto;
     }
   }
