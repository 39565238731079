/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
.booktopia-link {
  color: #009a3e;
  text-decoration: none;
}

.booktopia-link:hover {
  color: #333;
}

.booktopia-button-style {
  background-color: #009a3e;
  border: none;
  transition: .5s;
}

.booktopia-button-style:hover {
  background: linear-gradient(to bottom, #009a3e 0%, #075f20 100%);
  transition: .5s;
}

.booktopia-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #009a3e;
  border: 1px solid #009a3e;
  color: #fff;
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.booktopia-view-offer-btn:hover {
  background: linear-gradient(to bottom, #009a3e 0%, #075f20 100%);
  transition: .5s;
}

.booktopia-1-half-img {
  background: url(/images/landing-pages/booktopia-sec-1.JPG) no-repeat center;
  background-size: cover;
  background-color: #009a3e;
  background-size: cover;
  height: 500px;
  background-position: right;
}

@media all and (max-width: 1549px) {
  .booktopia-1-half-img {
    height: 600px;
  }
}

.booktopia-1-content {
  padding: 0 50px;
}

@media all and (max-width: 1200px) {
  .booktopia-1-content {
    padding: 50px 30px 0;
  }
}

@media all and (max-width: 600px) {
  .booktopia-1-content {
    padding: 50px 15px 0;
  }
}

.booktopia-2-content {
  padding: 0 50px;
}

@media all and (max-width: 1200px) {
  .booktopia-2-content {
    padding: 0 30px 50px;
  }
}

@media all and (max-width: 600px) {
  .booktopia-2-content {
    padding: 0 15px 50px;
  }
}

.booktopia-2-half-img {
  background: url(/images/landing-pages/booktopia-sec-2.JPG) no-repeat center;
  background-size: cover;
  background-color: #009a3e;
  background-size: cover;
  height: 500px;
  background-position: right;
}

@media all and (max-width: 1549px) {
  .booktopia-2-half-img {
    height: 600px;
  }
}

#booktopia-paralax-sec {
  background-attachment: fixed;
  background-color: #075f20;
  background-image: url(/images/landing-pages/booktopia-warehouse.JPG);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media all and (max-width: 1024px) {
  #booktopia-paralax-sec {
    background-attachment: initial;
  }
}

#booktopia-paralax-sec .booktopia-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}

@media all and (max-width: 600px) {
  #booktopia-paralax-sec .booktopia-paralax-layer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#booktopia-hero-video {
  position: relative;
  height: calc(100vh - 70px);
  width: 100%;
  overflow: hidden;
  display: table;
}

.booktopia-hero-video-cnt {
  position: relative;
  display: table;
  width: 100%;
  height: calc( 100vh - 70px);
  padding: 10% 0;
  background-color: rgba(1, 1, 1, 0.7);
}

@media all and (max-width: 1024px) {
  .booktopia-hero-video-cnt {
    padding-top: 30%;
  }
}

@media all and (max-width: 600px) {
  .booktopia-hero-video-cnt {
    padding-top: 10%;
  }
}

.booktopia-video {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.77777778vh;
}

.booktopia-logo {
  margin-bottom: 40px;
}

@media all and (max-width: 600px) {
  .booktopia-logo {
    width: 270px;
    margin-bottom: 30px;
  }
}

.booktopia-rc-all-video-cnt {
  padding-top: 70px;
}

@media all and (max-width: 600px) {
  .booktopia-rc-all-video-cnt {
    padding-top: 20px;
  }
}

.booktopia-video-card {
  margin-top: 30px;
  position: relative;
  box-shadow: 0 10px 27px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid #ebebeb;
}

#booktopia-resource-center-hero-banner {
  background: url(/images/landing-pages/booktopia-hero-resource-center.jpg) no-repeat center;
  background-size: cover;
  height: 369px;
  width: 100%;
  display: table;
  position: relative;
}

@media all and (max-width: 600px) {
  #booktopia-resource-center-hero-banner {
    background-image: url(/images/landing-pages/booktopia-hero-resource-center-sm.jpg);
    background-repeat: no-repeat;
    background-position: center;
    height: 219px;
  }
}

#booktopia-resource-center-hero-banner .booktopia-rc-hero-layer {
  background-color: rgba(1, 1, 1, 0.65);
  width: 100%;
  height: 369px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 600px) {
  #booktopia-resource-center-hero-banner .booktopia-rc-hero-layer {
    height: 100%;
    padding: 20px;
  }
}

#booktopia-resource-center-hero-banner .booktopia-rc-hero-layer .booktopia-rc-hero-title {
  font-size: 3rem;
  font-family: latoregular;
  line-height: 34px;
  margin-bottom: 1.5rem;
}

@media all and (max-width: 600px) {
  #booktopia-resource-center-hero-banner .booktopia-rc-hero-layer .booktopia-rc-hero-title {
    font-size: 2rem;
  }
}

#booktopia-resource-center-hero-banner .booktopia-rc-hero-layer .booktopia-rc-hero-subtitle {
  font-size: 1.5rem;
}

@media all and (max-width: 600px) {
  #booktopia-resource-center-hero-banner .booktopia-rc-hero-layer .booktopia-rc-hero-subtitle {
    font-size: 1.25rem;
  }
}

.booktopia-video-card-content {
  border-top: 1px solid #ebebeb;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#view-offer-btn-cnt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 40px;
}

.booktopia-event-header {
  padding: 70px 25px;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
}
