/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
FOOTER
---------------------------------------------------------- */
.my-investments-wrapper {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px 340px 0px 0px;
  position: relative;
}

@media all and (max-width: 960px) {
  .my-investments-wrapper {
    padding: 0px;
  }
}

.id-info-tooltip {
  margin-bottom: 15px;
}

.id-tabs {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 600px) {
  .id-tabs {
    background-color: #8f9ea9;
  }
}

.id-tabs > li {
  float: left;
  width: 33.3%;
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #7c8b97;
  border-right-width: 1px;
}

.id-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 600px) {
  .id-tabs > li {
    width: 50%;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
  .id-tabs > li:last-of-type {
    border-style: solid;
    border-color: #7c8b97;
    border-right-width: 1px;
  }
}

.id-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 0px 0px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.id-tabs > li > a:hover, .id-tabs > li > a.active {
  background-color: #9daab4;
}

/* Investments details tabs ends here */
.id-inner-tabs {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 600px) {
  .id-inner-tabs {
    background-color: #8f9ea9;
  }
}

.id-inner-tabs > li {
  float: left;
  width: 20%;
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #7c8b97;
  border-right-width: 1px;
}

.id-inner-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 600px) {
  .id-inner-tabs > li {
    width: 50%;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
  .id-inner-tabs > li:last-of-type {
    border-style: solid;
    border-color: #7c8b97;
    border-right-width: 1px;
  }
}

.id-inner-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 0px 0px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.id-inner-tabs > li > a:hover, .id-inner-tabs > li > a.active {
  background-color: #9daab4;
}

/* Investments details inner tabs ends here */
