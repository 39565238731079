@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
GENERAL
---------------------------------------------------------- */
.no-padding {
    padding: 0px !important;
}
.no-padd-left {
    padding-left: 0px !important;
}
.fl-right {
    float: right !important;
}
.fl-left {
    float: left !important;
}
.fl-none {
    float: none !important;
}
.default-submit-button {
    float: left;
    margin: 20px 0px 0px 0px;
    padding: 0px 40px 1px 10px;
    min-height: 40px;
    font-family: 'latolight';
    font-size: 14px;
    color: $default-submitBtn-color;
    cursor: pointer;
    background-image: url(../../images/submit-btn-arrow.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-color: $default-blue-color;
    @include transition(.3s);
    text-transform: uppercase;
    border: 0px;
    &:hover {
        background-color: lighten($default-blue-color, 5%);
        background-position: right 12px center;
    }
    /* Media queries */
    @include mquery(375px) {
        font-size: 12px;
    }
    @include mquery(330px) {
        font-family: 'latobold';
        font-size: 11px;
    }
    &.syndicate-back-btn {
        background-image: url("../../../images/back-btn-arrow.png");
        &:hover {
            background-position: right 18px center;
        }
    }
    &.large {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 40px 10px 10px;
    }
}
.default-back-button {
    float: left;
    margin: 20px 0px 0px 0px;
    padding: 0px 10px 1px 40px;
    min-height: 40px;
    font-family: 'latoblack';
    font-size: 14px;
    color: $default-submitBtn-color;
    cursor: pointer;
    background-image: url(../../images/back-btn-arrow.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-color: #AEAEAE;
    @include transition(.3s);
    text-transform: uppercase;
    border: 0px;
    text-align: right;
    &:hover {
        background-color: lighten(#AEAEAE, 5%);
        background-position: 12px center;
    }
    /* Media queries */
    @include mquery(375px) {
        font-size: 12px;
    }
    @include mquery(330px) {
        font-family: 'latobold';
        font-size: 11px;
    }
}
.submit-buttons-cont {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    @include border(top, 1px, solid, $input-field-border);
}
.default-save-button {
    float: right;
    margin: 20px 0px 0px 0px;
    padding: 15px 70px 15px 15px;
    font-family: 'latoblack';
    color: $default-submitBtn-color;
    background-color: $default-save-btn-bg;
    @include transition(.3s);
    background-image: url(../../images/save-btn-icon.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        background-color: lighten($default-save-btn-bg, 5%);
    }
    &.large {
        height: 52px;
        font-size: 14px;
        line-height: 20px;
    }
}

.default-error-button {
    float: right;
    margin: 20px 0px 0px 0px;
    padding: 15px 70px 15px 15px;
    font-family: 'latoblack';
    color: $default-submitBtn-color;
    background-color: red;
    @include transition(.3s);
    background-image: url(../../images/icons/error-btn-icon.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    border: 0px;
    text-transform: uppercase;
    cursor: pointer;
    &.large {
        height: 52px;
        font-size: 14px;
        line-height: 20px;
    }
}

.input-margin-bottom {
    margin-bottom: 10px;
}
.all-syndicates-blocks-out{
    .single-mysyndicates-block{
        @include mquery(1055px) {
            min-height: 140px;
        }
        @include mquery(580px) {
            min-height: 165px;
            //padding-bottom: 45px;
            p {
                // text-align: center;
            }
        }
        > div{
            @include mquery(1055px) {
                padding-bottom: 10px;
            }
            @include mquery(600px) {
                padding-top: 10px;
            }
            @include mquery(580px) {
                min-height: 120px;
                padding-bottom: 0;
            }
            > span{
                padding-right: 0;
                @include mquery(600px) {
                    font-size: 15px;
                }
                @include mquery(380px) {
                    font-size: 13px;
                }
            }
            > p {
                @include mquery(580px) {
                    font-size: 12px;
                }
            }
            .ms-cr-info-action{
                @include mquery(580px) {
                    position: absolute;
                    left: -105px;
                    bottom: -35px;
                }
                > div > span{
                    @include mquery(580px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

/* Main header wrapper */
.main-header-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 70px;
    z-index: 10000;

    .alert.alert-danger {
        background-color: #e04b71;

        .wrapper {
            max-width: 1200px;
            margin: 0 auto;
            padding: 15px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            img {
                display: inline-block;
                margin-right: 20px;
                width: 40px;
                height: 40px;
            }

            p {
                display: inline-block;
                max-width: 1200px;
                color: #fff;
            }

            .header-button-verify {
                background-color: white;
                display: inline-block;
                margin-left: auto;
                text-transform: uppercase;
                cursor: pointer;
                padding: 15px 70px 15px 15px;
                border: none;
                overflow: hidden;
                position: relative;
                font-size: 16px;
                font-family: 'latoregular';
                color: #7e7e7e;
                background-image: url(../../images/circle-arrow-right.png);
                background-repeat: no-repeat;
                background-position: right 15px center;

                &:hover {
                    background-color: #f3f5f6;
                }
            }
        }


    }

}
.mhw-modified {
    position: relative;
    .header-dark {
        background-color: $dark-header-bg;
        min-height: 70px;
        // position: relative;
    }
}

#main-container {
    margin: 0px auto;
    padding: 0px 10px 60px;
    width: 100%;
    max-width: 1200px;
    clear: both;
    min-height: 80vh;
    &.create-synd-main-cont,
    &.edit-synd-main-cont{
        padding-bottom: 40px;
    }
}
/* Form group */
.form-group {
    float: left;
    width: 100%;
    min-height: 60px;
    margin: 10px 0px 0px 0px;
    padding: 0px 10px 0px 140px;;
    position: relative;
    background-color: $form-group-bg;
    &.checkbox-padding {
        padding: 15px !important;
    }
    .form-group-title {
        position: absolute;
        left: 0px;
        top: 21px;
        margin: 0px;
        padding: 0px 15px;
        max-width: 140px;
        width: 100%;
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 1px 0px 0px 0px;
            font-family: 'latoregular';
            font-size: 14px;
            color: $default-text-color;
        }
        .input-helper {
            position: absolute;
            top: 0px;
            right: 10px;
            margin: 0px;
            padding: 0px;
            z-index: 1002;
            > span {
                float: left;
                width: 16px;
                height: 16px;
                margin: 0px;
                padding: 1px 0px 0px 0px;
                font-family: 'latoblack';
                font-size: 12px;
                color: $form-group-helper-color;
                text-align: center;
                background-color: $form-group-helper-bg;
                border-radius: 10px;
                cursor: pointer;
                &:hover ~ .helper-text {
                    opacity: 1;
                    visibility: visible;
                }
            }
            > p {
                position: absolute;
                bottom: 24px;
                right: -175px;
                min-width: 200px;
                min-height: 22px;
                background-color: $fg-helper-tooltip-bg;
                margin: 0px;
                padding: 3px 10px;
                font-family: 'latolight';
                font-size: 12px;
                color: $form-group-helper-color;
                @include transition(.3s);
                opacity: 0;
                visibility: hidden;
                text-align: center;
                border-radius: 2px;
                &:after {
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    bottom: -6px;
                    left: 10px;
                    border-right: 6px solid transparent;
                    border-left: 6px solid transparent;
                    border-top: 6px solid $fg-helper-tooltip-bg;
                }
                /* Media Queries */
                @include mquery(400px) {
                    right: -9px;
                    &:after {
                        right: 10px;
                        left: auto;
                    }
                }
            }
            /* Media queries */
            @include mquery(400px) {
                right: 0px;
            }
        }/* Input helper ends here */
        /* Media queries */
        @include mquery(400px) {
            float: left;
            position: relative;
            left: auto;
            top: auto;
            padding: 0px 25px 0px 0px;
            margin: 10px 0px 0px 0px;
            max-width: 100%;
        }
    } /* Form group title ends here */
    input {
        float: left;
        width: 100%;
        height: 42px;
        margin: 9px 0px 0px 0px;
        padding: 0px 10px;
        font-family: 'latobold';
        font-size: 14px;
        color: $input-field-text;
        @include border(full, 1px, solid, $input-field-border);
    }
    &.radio-form-group {
        padding: 0px 160px 0px 0px;
        @include mquery(580px) {
            padding: 0px;
        }
        .sfg-field {
            max-width: 160px;
            padding: 0px 25px 0px 10px;
            margin-top: 18px;
            @include mquery(580px) {
                margin-top: 0px;
                padding: 0px 25px 10px 10px;
            }
        }
    }
    .sfg-title {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 22px 15px;
        font-family: 'latoregular';
        font-size: 14px;
        color: $input-field-text;
        @include mquery(580px) {
            padding: 10px;
        }
    }
    .sfg-field {
        position: absolute;
        top: 0px;
        right: 0px;
        margin: 0px;
        padding: 0px 10px 0px 140px;
        width: 100%;
        max-width: 400px;
        @include mquery(670px) {
            max-width: 320px;
        }
        @include mquery(580px) {
            max-width: 100%;
            padding: 0;
            position: relative;
            float: left;
            top: auto;
            right: auto;
        }
        &.invest-type-unput{
            float: left;
            position: relative;
            right: auto;
            top: auto;
            max-width: none !important;
            @include mquery(580px) {
                margin-top: 9px;
            }
        }
        &.hidden-cont{
            float: left;
            position: relative;
            right: auto;
            top: auto;
            max-width: none !important;
            @include mquery(580px) {
                margin-top: 9px;
            }
            .sfg-select-field{
                max-width: none !important;
            }
            .select2-container{
                width: 100% !important;
            }
            .select2-container--default .select2-selection--single{
                border: none;
            }
            .select2-selection__rendered{
                line-height: 41px;
            }
        }
        .sfg-input-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px 10px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            @include border(full, 1px, solid, $input-field-border);
            @include mquery(580px) {
                margin: 0px;
            }
        }
        .sfg-select-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px;
            background-color: #ffffff;
            @include border(full, 1px, solid, $input-field-border);
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-image: url(../../images/select-field-arrow.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #ffffff;
                z-index: 2;
            }
            > select {
                float: left;
                width: 100%;
                height: 40px;
                margin: 0px;
                padding: 0px 10px 1px;
                margin: 0px;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                border: 0px;
                position: relative;
                z-index: 3;
                background-color: transparent;
                cursor: pointer;
            }
        } /* sfg select field ends here */
        .sfg-file-field {
            float: left;
            width: 100%;
            height: 42px;
            margin: 9px 0px 0px 0px;
            padding: 0px;
            background-color: #ffffff;
            @include border(full, 1px, solid, $input-field-border);
            position: relative;
            z-index: 1;
            &:after {
                content: "";
                width: 40px;
                height: 40px;
                margin: 0px;
                padding: 0px;
                position: absolute;
                top: 0px;
                right: 0px;
                background-image: url(../../images/file-field-icon.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #ffffff;
                z-index: 2;
            }
            > input {
                float: left;
                width: 100%;
                height: 40px;
                margin: 0px;
                padding: 0px 10px;
                margin: 0px;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                border: 0px;
                position: relative;
                z-index: 3;
                background-color: transparent;
                text-indent: -99999px;
                opacity: 0;
                cursor: pointer;
            }
            > span {
                position: absolute;
                top: 12px;
                left: 0px;
                margin: 0px;
                padding: 0px 42px 3px 15px;
                width: 100%;
                font-family: 'latobold';
                font-size: 14px;
                color: $input-field-text;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
            @include mquery(580px) {
                margin: 0px;
            }
        } /* sfg file input ends here */
        .sfg-textarea-field {
            float: left;
            width: 100%;
            min-height: 160px;
            margin: 9px 0px 0px 0px;
            padding: 10px 10px;
            font-family: 'latobold';
            font-size: 14px;
            color: $input-field-text;
            @include border(full, 1px, solid, $input-field-border);
            resize: none;
            @include mquery(580px) {
                margin: 0px;
            }
        }

    } /* sfg field ends here */
    .sfg-field-privacy {
        position: absolute;
        top: 9px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        width: 42px;
        display: none;
        overflow: hidden;

        &.visible {
            display: inline-block;
        }
        &.main-privacy-switch {

        }
        @include mquery(580px) {
            top: 0px;
            left: 10px;
        }
    }
    &.multiple-fields {
        padding: 0px 0px 9px 0px;
        .sfg-title {
            position: absolute;
            padding: 22px 410px 22px 15px;
            @include mquery(580px) {
                padding: 10px;
                float: left;
                width: 100%;
                margin: 0px;
                position: relative;
            }
        }
        .sfg-field {
            top: auto;
            right: auto;
            float: right;
            position: relative;
            clear: both;
            @include mquery(580px) {
                margin-top: 9px;
            }
        }
    }
    &.min-height {
        min-height: 235px;
    }
    .profile-picture-preview {
        position: absolute;
        bottom: 9px;
        right: 10px;
        width: 164px;
        height: 164px;
        margin: 0px;
        padding: 0px;
        > img {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
        }
        @include mquery(580px) {
            display: inline-block;
            position: relative;
            bottom: auto;
            right: auto;
            margin: 10px 0px 0px 0px;

        }
    }


    /* Media queries */
    @include mquery(400px) {
        padding: 0px 10px 10px;
    }

}/* Form Group ends */

input[type=checkbox].default-checkbox {
    position:absolute;
    z-index:-1000;
    left:-1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height:1px;
    width:1px;
    margin:-1px;
    padding:0;
    border:0;
}
input[type=checkbox].default-checkbox + label.default-checkbox-label {
    padding-left:50px;
    height:30px;
    display:inline-block;
    line-height:30px;
    background-repeat:no-repeat;
    background-position: 0 0;
    font-size:30px;
    vertical-align:middle;
    cursor:pointer;
    font-family: 'latoregular';
    font-size: 14px;
    color: $default-text-color;
    /* Media queries */
    @include mquery(479px) {
        line-height: 16px;
    }
    @include mquery(330px) {
        font-size: 12px;
        padding-top: 5px;
    }
}
input[type=checkbox].default-checkbox:checked + label.default-checkbox-label {
    background-position: 0 -30px;
}
label.default-checkbox-label {
    background-image:url(../../images/checkbox-sprite.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    > a {
        text-decoration: none;
        color: $default-blue-color;
        font-family: 'latoregular';
        &:hover {
            text-decoration: underline;
        }
    }
}
span.default-checkbox-label {
    float: left;
    margin: 9px 0px 0px 0px;
    padding: 0px;
    font-family: 'latoregular';
    font-size: 14px;
    color: $default-text-color;
}
input[type=radio].default-radiobutton {
    position:absolute;
    z-index:-1000;
    left:-1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height:1px;
    width:1px;
    margin:-1px;
    padding:0;
    border:0;
}
input[type=radio].default-radiobutton + label.default-radiobutton-label {
    padding-left:29px;
    height:24px;
    display:inline-block;
    line-height:23px;
    font-family: 'latobold';
    background-repeat:no-repeat;
    background-position: 0 0;
    font-size:14px;
    color: $default-text-color;
    vertical-align:middle;
    cursor:pointer;
    margin: 0px 0px 0px 20px;
    &:first-of-type {
        margin: 0px;
    }
}
input[type=radio].default-radiobutton:checked + label.default-radiobutton-label {
    background-position: 0 -24px;
}
label.default-radiobutton-label {
    background-image:url(../../images/radio-btn-sprite.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Default section title */
.default-section-title {
    /*float: left;*/
    margin: 0px;
    padding: 0px 0px 20px;
    font-size: 22px;
    font-family: 'latobold';
    text-transform: uppercase;
    position: relative;
    color: $default-section-title;
    &:after {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 85px;
        height: 3px;
        background-color: $default-blue-color;
        content: "";
    }
}
.default-info-tooltip {
    position: relative;
    width: 100%;
    margin: 70px 0 0 0;
    padding: 24px 20px 39px 63px;

    background-color: $default-info-tooltip;
    background-image: url('../../images/default-info-tooltip-icon.png');
    background-repeat: no-repeat;
    background-position: 20px center;

    clear: both;

    > span {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        font-family: 'latoblack';
        font-size: 16px;
        color: $default-info-tooltip-text;
    }
    > p {
        font-family: 'latoregular'!important;
        font-size: 13px!important;
        color: $default-info-tooltip-text!important;
        line-height: 18px !important;
    }
}

.default-warning-tooltip {
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
    padding: 20px 20px 20px 63px;
    background-color: #f6e69c;
    background-image: url('../../images/default-warning-icon.png');
    background-repeat: no-repeat;
    background-position: 22px 27px;
    clear: both;
    > p {
        font-family: 'latoregular';
        font-size: 13px;
        color: #b7810d;
        line-height: 18px;
        > a {
            color: $default-blue-color;
            font-family: 'latoregular';
        }
    }
    > span {
        color: #b7810d;
        float: left;
        font-family: "latoblack";
        font-size: 16px;
        margin: 0;
        padding: 0;
        width: 100%;
    }
}
.default-success-tooltip {
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
    margin-bottom: 20px;
    padding: 20px 20px 20px 63px;
    background-color: #C7F79C;
    background-image: url('../../images/default-success-icon.png');
    background-repeat: no-repeat;
    background-position: 22px center;
    clear: both;

    > span {
        color: #6aa536;
        float: left;
        font-family: "latoblack";
        font-size: 16px;
        margin: 0 0px 5px 0px;
        padding: 0;
        width: 100%;
    }
    > p {
        font-family: 'latoregular';
        font-size: 13px;
        color: #6aa536;
        line-height: 18px;
        > a {
            color: $default-blue-color;
            font-family: 'latoregular';
        }
    }
}
.default-error-tooltip {
    position: relative;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 20px 20px 20px 63px;

    background-color: #F2BAC7;
    background-image: url('../../images/default-error-icon.png');
    background-repeat: no-repeat;
    background-position: 22px center;
    clear: both;

    > span {
        color: #d62851;
        float: left;
        font-family: "latoblack";
        font-size: 16px;
        margin: 0 0px 5px 0px;
        padding: 0;
        width: 100%;
    }
    > p {
        font-family: 'latoregular';
        font-size: 13px;
        color: #d62851;
        line-height: 18px;
        > a {
            color: $default-blue-color;
            font-family: 'latoregular';
        }
    }
}
.formatting-container {
    // float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    > p {
        color: $default-text-color;
        // float: left;
        font-family: "latolight";
        font-size: 16px;
        line-height: 24px;
        padding: .5em 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        // line-height: 1.5;
        &.side-padding{
            padding: 0 10px;
        }
        em{
            font-style: italic;
        }
        strong{
            font-family: 'latobold';
        }
        em strong {
            font-style: italic;
            font-family: 'latobold';
        }
    }
    ul, ol {
        color: $default-text-color;
        // float: left;
        font-family: "latolight";
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        list-style-position: inside;
        margin-left: 20px;
        li {
            color: $default-text-color;
            // float: left;
            font-family: "latolight";
            font-size: 16px;
            line-height: 24px;
            list-style-position: outside;
            padding-left: 5px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            padding: .5em 0;
        }
    }
    ul{
        list-style-type: disc;
    }
    ol{
        list-style-type: decimal;
    }
    h1,h2,h3,h4,h5,h6{
        color: $default-text-color;
        // float: left;
        font-family: "latolight";
        padding: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        // line-height: 1.5;
    }
    h1 {
        font-size: 30px;
        line-height: 45px;
    }
    h2 {
        font-size: 25px;
        line-height: 36px;
    }
    h3 {
        font-size: 22px;
        line-height: 33px;
    }
    h4 {
        font-size: 18px;
        line-height: 27px;
    }
    h5 {
        font-size: 18px;
        line-height: 20px;
    }
    h6 {
        font-size: 16px;
        line-height: 18px;
    }
    // Remove the prolific use of float: left on this class
    // without breaking all other uses
    &.no-float {

        float: none;

        > p { float: none; }
        h1,h2,h3,h4,h5,h6 { float: none; }
        ul, ol { float: none; }

    }
}
.error-tooltip,
.cp-error-tooltip {
    position: absolute;
    z-index: 10;
    bottom: 55px;
    right: 0px;
    margin: 0px;
    padding: 5px 10px;
    min-height: 23px;
    background-color: $error-tooltip-bg;
    font-family: 'latolight';
    font-size: 11px;
    color: #ffffff;
    border-radius: 2px;
    &:before {
        content: "";
        width: 0px;
        height: 0px;

        position: absolute;
        bottom: -7px;
        right: 20px;

        border-right: 6px solid transparent;
        border-left: 6px solid transparent;

        border-top: 7px solid $error-tooltip-bg;
    }
}

.cp-error-tooltip.division {
    opacity: 0;
    transition: all 0.3s;
}
input:focus + .cp-error-tooltip.division {
    opacity: 1;
}
/* Dijana - 08.11.2016 */
.hidden-element {
    display: none;
}
.textarea-info {
    font-family: "latoregular";
    font-size: 12px;
}
/* Dijana - 08.11.2016 */


// Mina general scss/////////////////////////
.mn-round-container {
  margin-bottom: 69px;
}
.round {
  border-radius: 100%;
  background: $default-blue-color;
  padding: 26px 10px 24px 10px;
  width: 116px;
  height: 116px;
  margin: auto;
  cursor: pointer;
  .back-round-img {
    display: block;
    margin: auto auto;
  }
}

.txt-center  {
text-align: center;
margin-top: 20px;
max-width: 292px;
margin-right: auto;
margin-left: auto;
.txt-center-title {
 font-size: 20px;
 font-weight: 500;
 text-align: center;
 font-family: latoregular;
}
.txt-center-subtitle {
 text-align: center;
 margin-top: 10px;
 font-family: latolight;
 font-size: 16px;
 font-weight: 300;
 text-align: center;
 line-height: 24px;

}
}
.blue {
  color: $default-blue-color;
}
.black {
   color: #333333;
}
.white {
  color: #fff;
}
.green {
  color: #68cfad;
}
.blue-border {
  border: 1px solid #469edc;
}
.white-border {
  border: 1px solid #fff;
}
.grey-back {
  background-color: #ebebeb;
}
.white-back {
  background-color: #fff;
}
.blue-back  {
  background-color: #469edc;
}
.green-back {
  background-color: #68cfad;
}
.pink-back {
  background-color: #de518e;
}

.pink {
  color: #de518e;
}

.btn-blue {
  color: #fff;
  background-color: $default-blue-color;
  border-color: $default-blue-color;
  &:hover {
    color: #fff;
    opacity: .8;
  }
  &:focus {
    color: #fff;
  }
}

.mn-40-title {
  text-align: center;
  font-family: 'latoregular';
  font-size: 40px;
  margin-bottom: 34px;
  @include mquery(736px) {
     font-size: 28px;

  }
  @include mquery(320px) {
     font-size: 24px;

  }
}

.mn-register-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 34px;
  @include mquery(736px) {
     font-size: 26px;
  }
  @include mquery(320px) {
     font-size: 22px;

  }
}

.mn-36-title {
  font-family: 'latoregular';
  font-size: 36px;
  margin-bottom: 34px;
  line-height: 2.7rem;

  @include mquery(1440px) {
     font-size: 27px;

  }

  @include mquery(767px) {
     font-size: 22px;
     line-height: 2rem;

  }
}
.mn-24-subtitle {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 24px;
  text-align: center;
  margin-bottom: 80px;
  @include mquery(600px) {
     font-size: 19px;

  }
}

.mn-center-btn {
  margin: auto;
  max-width: 253px;
  height: 55px;
  background-color: $default-blue-color;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
  @include mquery(600px) {
     margin-top: 32px;

  }
  &:hover {
    background-color: rgba(70,158,220, .9);
  }
}


.mn-border-btn {
  width: 253px;
  height: 55px;
  padding: 19px 10px;
  font-size: 15px;
  text-align: center;
  font-family: latoregular;
  transition: 0.3s;
  &:hover {
    background-color: $default-blue-color;
    border: 1px solid $default-blue-color;
    color: #fff;
  }
}

// how to invest page blue and white button//////


    .mn-hti-btn-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 41px;

    .mn-hti-blue-btn {
      width: 216px;
      height: 55px;
      background-color: #469edc;
      padding: 19px 35px;
      color: #fff;
      font-size: 15px;
      transition: 0.3s;
      margin-right: 10px;
      text-align: center;
      font-family: latoregular;
      @include mquery(560px) {
       width: 253px;
       margin-bottom: 15px;
       margin-right: 0;
      }

    }
    .mn-hti-border-btn {
      width: 253px;
      height: 55px;
      padding: 19px 10px;
      font-size: 15px;
      text-align: center;
      font-family: latoregular;
      @include transition(.3s);
      &:hover {
          background-color: $htw-btn-bg;
          border: 1px solid #469edc;
          color: white;

      }
     }
    }

  // how to invest page blue and white button end//////

    .mn-main-container {
      padding-top: 109px;
      padding-bottom: 109px;
      @include mquery(500px) {
        padding-top: 72px;
        padding-bottom: 72px;

      }
    }

  // Deals common css////////

    .mn-progress-bar {
      width: 100%;
      height: 11px;
      padding: 0px;
      overflow: hidden;
    }

    .mn-tile-desc-sm {
      font-size: 14px;
      font-family: latoregular;
      text-align: center;
      // margin-top: 50px;
    }

    .mn-tile-percent-funded {
      font-size: 14px;
      font-family: latoregular;
      text-align: center;
      // margin-top: 69px;
    }


    .mn-loadMore {
      font-size: 18px;
      font-family: latoregular;
      color: #469edc;
      text-decoration: underline;
      text-align: center;
      margin-top: 47px;
      margin-bottom: 111px;
    }

      // Deals common css end////////

      .mn-home-blue-title {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        text-align: center;
        font-family: 'latoregular';
        font-size: 40px;
        font-weight: 500;
        color: $default-blue-color;
        @include mquery(600px) {
            font-size: 35px;
        }

      }


        .mn-subtitle {
          float: left;
          width: 100%;
          line-height: 35px;
          margin: 34px 0 0 0;
          padding: 0px;
          font-family: 'latolight';
          font-weight: 300;
          font-size: 24px;
          color: #333333;
          text-align: center;
          @include mquery(600px) {
              text-align: center;
              font-size: 19px;
          }

        }

        .rounded {
          width: 110px;
          height: 110px;
          border-radius: 100%;
          position: relative;
          z-index: 1;
        }

        .offer-description {
            overflow: hidden;
            line-height: 1.2em;
            height: 3.6em;
        }

        // investor agreement page

        .mn-list {
          float: left;
          margin: 0px;
          padding: 20px 0px 20px 55px;
          .mn-list-lower-alpha {
            float: left;
            clear: both;
            margin: 20px 0px 0px 0px;
            padding: 0px;
            font-family: "latolight";
            font-size: 15px;
            color: $default-text-color;
            list-style: lower-alpha;
          }
        }

  // investor agreement page end

  .mn-popup-header {
    font-family: latolight;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .mn-popup-p {
    margin-bottom: 20px;
    text-align: center;
  }

  .mn-popup-btn {
    border-radius: 3px;
    padding: 10px 20px;
    margin: 10px;
    background: #68cfad !important;
    color: #FFFFFF !important;
    border: 1px solid #68cfad;
  }

  .mn-modal-body {
    position: relative;
    padding: 15px;
  }

.carousel-control-no-width{
  background-image: none;
  width: 0;
}

.mn-default-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 768px) {
      width: 750px;
  }
  @media only screen and (min-width: 992px) {
      width: 970px;
  }

  @media only screen and (min-width: 1200px) {
      width: 1170px;
  }
  @media only screen and (min-width: 1400px) {
      width: 90%;
  }


}

.mn-mt-60 {
  margin-top: 60px;
}

.mn-info-tooltip-p {
  font-family: "latoregular" !important;
  font-size: 13px !important;
  color: #6195b9 !important;
  line-height: 18px !important;
  width: 100% !important;
  padding: 24px 20px 39px 63px !important;
}

.create-project-wrapper {
    float: left;
    width: 100%;
    margin: 25px 0px 25px 0px;
    padding: 20px;
    background-color: #ffffff;
  }

  .mn-margin-bt-20 {
    margin-bottom: 20px;
  }
  .mn-margin-tp-20 {
    margin-top: 20px;
  }


  .home-down-arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 0px;
      right: 0px;
      display: inline-block;
      cursor: pointer;
      z-index: 2;
      background-image: url(../../images/icons/scroll-home-arrow.png);
      margin-left: auto;
      margin-right: auto;
      animation: bounce 2s infinite;
      @include transition(.3s);
      @include mquery(1280px) {
          bottom: -30px;
      }
      @include mquery(1024px) {
          display: none;
      }
  }


  .mn-top-75 {
    top: 75%;
  }

  .mn-top-76 {
    top: 76%;
  }


  .bounce {
      -moz-animation: bounce 3s infinite;
      -webkit-animation: bounce 3s infinite;
      animation: bounce 3s infinite;
  }

  @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
      }
      40% {
          transform: translateY(-30px);
      }
      60% {
          transform: translateY(-15px);
      }
}


nav {
  width: 100%;
  margin: 0 auto;
}

/* applied after scroll height reached */
.fixed-nav {
  position: fixed !important;
  top:0;
  z-index: 1;
  animation:slide-down 0.7s;
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}


.nav-nodisplay {
  display: none;
}

.mn-invest-offer-button {
    float: right;
    margin: 10px 0px 0px 10px;
    padding: 15px 70px 15px 15px;
    font-family: 'latoblack';
    color: #ffffff;
    border: 1px solid white;
    transition: 0.3s;
    background-image: url(../../images/invest-btn-icon.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    text-transform: uppercase;
    cursor: pointer;
    @include respond-to(medium-screens) {
       margin-right: 10px;
    }

    @include mquery(736px) {
      margin: 13px 10px 0px 10px;
      padding: 15px 47px 15px 15px;
      font-size: 12px;
    }
    @include mquery(320px) {
      padding: 12px 46px 12px 13px;
    }
}

.mn-invest-offer-button:hover {
  background-color: #469edc;
  border: 1px solid #469edc;
  color: #fff;
  transition: 0.3s;
}

button.scroll_down {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 50px;
  width: 30px;
  bottom: 110px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}
button.scroll_down:before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: scroll_down_btn-animation 2s infinite;
  animation: scroll_down_btn-animation 2s infinite;
  box-sizing: border-box;
}

@keyframes scroll_down_btn-animation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.scroll-text {
  color: white;
  bottom: 45px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 50px;
  width: 50px;
}



/////////////////////////////////////////////////
//Offer page loader///
/////////////////////////////////////////////////////

#loader{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 70px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.92);
  z-index: 99999;

}

#loader-6{
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  // width: 150px;
  // height: 150px;
  transform: translate(-50%, -50%);
}

#loader-6 span{
  display: inline-block;
  width: 10px;
  height: 40px;
  background-color: #3498db;
}

#loader-6 span:nth-child(1){
  animation: grow 1s ease-in-out infinite;
}

#loader-6 span:nth-child(2){
  animation: grow 1s ease-in-out 0.15s infinite;
}

#loader-6 span:nth-child(3){
  animation: grow 1s ease-in-out 0.30s infinite;
}

#loader-6 span:nth-child(4){
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow{
  0%, 100%{
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50%{
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

.lato-regular {
  font-family: latoregular;
}


.p-less-margin {
  margin-top: 6px !important;
}
/////////////////////////////////////////////////
//Offer page loader end///
/////////////////////////////////////////////////////

.fullLoadingPlaceholder {
  width: 100%;
  height: 380.5px;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f2f2f2;
  .fullHeader {
    max-width: 850px;
    height: 30px;
    margin-bottom: 34px;
    width: 100%;
    margin: 0 auto 34px;
    @include mquery(600px) {
      width: 350px;
    }
    @include mquery(320px) {
      width: 300px;
    }
  }
  .fullSubHeader-1 {
    width: 100%;
    height: 20px;
    margin: 0 auto 24px;
    max-width: 950px;
    @include mquery(600px) {
      width: 300px;
    }
  }
  .fullSubHeader-2 {
    width: 100%;
    height: 20px;
    margin: 0 auto 24px;
    max-width: 750px;
    @include mquery(600px) {
      width: 250px;
    }
  }
  .fullChooseCountry {
    width: 100%;
    height: 20px;
    margin: 0 auto 24px;
    max-width: 500px;
    @include mquery(600px) {
      width: 150px;
    }
  }
}

.placeHolderShimmer {
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  position: relative;
}
@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.filter-option-LoadingLoader {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  margin-bottom: 84px;
  .filter-option-LoadingLoader-country {
    height: 30px;
  }
  .filter-option-LoadingLoader-search {
    height: 30px;
  }
}
.all-offer-tile-cnt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .offer-tile-cnt {
    width: 356px;
    height: 594px;
    position: relative;
    margin: 12px;
    @include mquery ( 768px) {
      width: 352px;
    }
    .offer-tile-img {
      width: 100%;
      height: 152px;
      position: relative;
      border: 1px solid #e1e1e1;
      .tile-circle {
        width: 110px;
        height: 110px;
        border-radius: 110px;
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
      .offer-tile-bannr {
        position: absolute;
        top: 0;
        min-width: 120px;
        height: 36px;
      }
    }
    .offer-tile-content {
      height: 440px;
      padding: 76px 20px 21px;
      position: relative;
      border: 1px solid #e1e1e1;
      .offer-tile-content-title {
        width: 318px;
        height: 20px;
        margin-bottom: 20px;
      }
      .offer-tile-content-subtitle{
        width: 318px;
        height: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

.offer-info {
  max-width: 400px;
  height: 550px;
  background-image: url(../../images/offer-details-ts-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  @include mquery (1440px) {
    max-width: 100%;
    height: 100%;
  }
  @include mquery (1200px) {
    height: 100%;
    margin-bottom: 40px;
  }
  .offer-info__dark-layer {
    height: 100%;
    width: 100%;
    background: rgba(1,1,1,.8);
    padding: 1.5rem;
    .offer-info__logo {
      width: 195px;
      height: 195px;
      margin: 0px;
      padding: 0px;
      @include mquery(1024px){
        width: 60px;
        height: 60px;
        display: block;
        margin: auto;
      }
    }
    .offer-info__title{
      font-size: 1.5rem;
      padding: 20px 0;
      @include mquery(1024px){
        text-align: center;
        font-size: 1.1875rem;
      }
    }
    .offer-info__subtitle {
      font-size: 1rem;
      padding: 10px 0;
      line-height: 1.2rem;
      @include mquery(1024px){
        text-align: center;
      }
    }
  }
}


/////////////////////////////////////////////////
//Offer page facebook content placeholder loader///
/////////////////////////////////////////////////////




/////////////////////////////////////////////////
//Offer page facebook content placeholder loader end///
/////////////////////////////////////////////////////



/////////////////////////////////////////////////////
// main-header-notification banner background color
/////////////////////////////////////////////////////
.xinja-pink-bk {
 background-color: #E73C81;
}

.wwg-blue-bk {
  background-color: #008cba;
}

.equitise-blue-bk {
  background-color: #469edc;
}

/////////////////////////////////////////////////////////
// main-header-notification banner background color end
//////////////////////////////////////////////////////////




// Mina general scss end/////////////////////////

.overlay-join {
  background: hsla(0,0,0,.85);
  color: #fff;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 0 1em;
  position: fixed;
  text-align: center;
  transform: translateY(0);
  transition: height 1s;
  width: 100%;
  z-index: 10;
  height: 0;
}

.overlay-join__text {
  margin: 2rem 0;
}

.pink-logIn {
  color: $default-pink-color;
  font-family: latoregular;
}

.overlay-join-popUp {
  background: rgba(0,0,0,.85);
  color: #fff;
  height: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  transition: height 1s;
  width: 100%;
  z-index: 10;
}
.overlay-join-popUp_close_icon_bg {
  width: 20px;
  position: absolute;
  right: 30px;
  top: 90px;
  opacity: .5;
  cursor: pointer;
  z-index: 20;
}

.overlay-join-popUp_close_icon_sm {
  display: none;
  width: 20px;
  position: absolute;
  right: 30px;
  top: 20px;
  opacity: .5;
  cursor: pointer;
  z-index: 20;
  @include mquery(768px) {
    display: block;
  }
  @include mquery(600px) {
    top: 15px;
    right: 15px;
  }
}
.overlay-join-popup_form {
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .overlay-join-popup_input {
    padding: 17px;
    height: 54px;
    border: 1px solid #ccc;
    color: #000;
  }
  .overlay-join-popup_input_sm_width {
    width: 60%;
  }
  .overlay-join-popup_input_bg_width {
    width: 100%;
    margin-bottom: 10px;
  }
  .overlay-join-popup_btn {
    background-color: $default-blue-color;
    padding: 18px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-family: latoregular;
    transition: .3s;
    width: 20%;
    border: 1px solid #469edc;
    &:hover {
      background-color: rgba(70, 158, 220, 0.9);
    }
  }
  .overlay-join-popup_btn_sm_width {
    width: 30%;
  }
  .overlay-join-popup_btn_bg_width {
    width: 100%;
  }
}

.popUp--close__btn__cnt {
  position: absolute;
  top: 70px;
  right: 0;
  width: 100%;
  height: 40px;
  background-color: #fff;
  z-index: 30;
}

#overlay-join__intro__popUp__close__btn {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 30;
  cursor: pointer;
}

.overlay-join-popUp__active {
  top: 0;
}

.no-scroll {
  overflow-y: scroll;
  @include mquery (768px) {
    overflow: hidden;
  }
}

.offer-room-info {
  max-width: 600px;
  height: 550px;
  background-image: url(../../images/offer-details-ts-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  @include mquery (1440px) {
    max-width: 100%;
    height: 100%;
  }
  @include mquery (1200px) {
    height: 100%;
    margin-bottom: 40px;
  }
  .offer-room-info-wrapper {
    display: flex;
    flex: auto;
    flex-direction: row;
    @include mquery (1024px) {
      flex-direction: column;
    }
  }
  .offer-room-details-container {
    padding-left: 15px;
  }
  .offer-room-info__dark-layer {
    height: 100%;
    width: 100%;
    background: rgba(1,1,1,.8);
    padding: 1.5rem;
    .offer-room-info__logo {
      width: 195px;
      height: 195px;
      margin: 0px;
      padding: 0px;
      @include mquery(1024px){
        width: 120px;
        height: 120px;
        display: block;
        margin: auto;
      }
    }
    .offer-room-info__title{
      font-size: 1.5rem;
      padding: 20px 0;
      @include mquery(1024px){
        text-align: center;
        font-size: 1.1875rem;
      }
    }
    .offer-room-info__subtitle {
      font-size: 1rem;
      padding: 30px 0;
      line-height: 1.2rem;
      @include mquery(1024px){
        text-align: center;
      }
    }
  }
  .offer-link-color {
    text-decoration: underline;
    color: #469edc;
    transition: .3s;
    &:hover {
      color: darken(#469edc, 1.2);
    }
  }
}

.overlay-join__intro__popUp {
  height: 100%;
  margin: 0 auto;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  @include mquery (600px) {
    display: block;
  }
}
  .overlay-join__header {
    font-size: 1.5rem;
    margin: 43px 0 20px;
    text-align: center;
    font-family: latoregular;
    line-height: 1.5;
    @include mquery (600px) {
      font-size: 1rem;
      font-family: latobold;
    }
  }
  .offer-join-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .offer-join-btn__fb-login {
      background-color: #3b5998;
      background-image: url(../../images/fb-btn-icon.png);
      background-position: right 15px center;
      background-repeat: no-repeat;
      width: 340px;
      height: 46px;
      margin: 0px;
      padding: 13px 15px;
      color: #fff;
      font-size: 17px;
      margin-bottom: 15px;
      transition: .3s;
      &:hover {
        filter: brightness(85%);
        transition: opacity 0.15s linear;
      }

      @include mquery(375px) {
        width: 100%;
      }
    }
    .offer-join-btn__lin-login {
      background-color: #0077b5;
      background-image: url(../../images/linkedin-btn-icon.png);
      background-position: right 15px center;
      background-repeat: no-repeat;
      width: 340px;
      height: 46px;
      margin: 0px;
      padding: 13px 15px;
      color: #fff;
      font-size: 17px;
      margin-bottom: 15px;
      transition: .3s;
      &:hover {
        filter: brightness(85%);
        transition: opacity 0.15s linear;
      }

      @include mquery(375px) {
        width: 100%;
      }
    }

    .offer-join-btn__equitise-login {
      background-color: #469edc;
      background-image: url(../../images/icons/email-btn-icon.png);
      background-position: right 15px center;
      background-repeat: no-repeat;
      width: 340px;
      height: 46px;
      margin: 0px;
      padding: 13px 15px;
      color: #fff;
      font-size: 17px;
      margin-bottom: 15px;
      transition: .3s;
      &:hover {
        filter: brightness(85%);
        transition: opacity 0.15s linear;
      }
      @include mquery(375px) {
        width: 100%;
      }
    }
  }

.overlay-join__header__popup {
  font-size: 24px;
  padding: 112px 0 48px;
  text-align: center;
  font-family: latoregular;
  line-height: 1.5;
  @include mquery (600px) {
    font-size: 16px;
    font-family: latobold;
    padding: 126px 0 16px;
  }
}

.overlay-join__footer__popup {
  font-size: 1rem;
  text-align: center;
  max-width: 770px;
  margin: 0 auto;
  line-height: 1.3rem;
  padding: 24px 0;
  @include mquery (768px) {
    font-size: .8rem;
    padding: 0.6rem 0 4.6rem;
  }
}

// toggle swich button for subscription setup

.onoffswitch {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    width: 88px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
}
.onoffswitch-label-mobile {
  @include mquery(600px) {
    position: relative;
    float: right;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    width: 88px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 20px;
  }
}
.onoffswitch-label-mobile-xs {
  @include mquery(320px) {
    position: relative;
    float: right;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    width: 88px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 20px;
  }
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 18px;
    position: relative;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition:
    margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 9px;
    background-color: #6f8899;
    color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 9px;
    background-color: #EEEEEE;
    color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 30px;
    margin: 3px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 48px;
    border: 2px solid #999999;
    border-radius: 18px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}


// toggle swich button for subscription setup end


.download-offer-doc-btn {
  padding: 15px;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}



.lato-bold {
  font-family: latobold;
}

.lato-regular {
  font-family: latoregular;
}