@import 'extensions/variables';
@import 'extensions/mixins';




#endeavour-top-banner {
  background-image: url(/images/landing-pages/endeavour-banner-min.jpg);
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #2e142c;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: table;
  position: relative;

  .endeavour-layer {
    background-color: rgba(1,1,1,.5);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;

  }

  .endeavour-logo {
    margin-bottom: 40px;
    @include mquery(500px) {
      width: 250px;
    }
  }
}


.endeavour-link {
  color: #34ba00;
  &:hover {
    color: #34ba00;
    text-decoration: underline;
  }
}



.endeavour-button-style {
  background-color: #34ba00;
  border: 1px solid #34ba00;
  transition: all 300ms ease;
  &:hover {
    background-color: #191919;
    color: #34ba00;
    border: 1px solid #191919;

  }
}

.endeavour-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #34ba00;
  border: 1px solid #34ba00;
  color: #fff;
  transition: all 300ms ease;  
  animation: bounce 3s;
}

.endeavour-view-offer-btn:hover {
  background-color: #191919;
  color: #34ba00;
  border: 1px solid #191919;
}



#endeavour-info-sec {
  .endeavour-init-info {
    @include mquery(1024px) {
      width: 100%;
      left: 0;
      top: 0;
      position: relative;
      border-radius: 0;
    }
  }

  .endeavour-init-2-info {
    border-radius: 52px;
    @include mquery(1024px) {
      width: 100%;
      right: 0;
      top: 0px;
      position: relative;
      border-radius: 0;
      padding: 40px 0;

    }
  }
}

.endeavour-row-2 {
  margin: 50px 0;
  @include mquery(1024px) {
  margin: 0;
  }
}

#endeavour-paralax-sec {
  background-attachment: fixed;
    background-image: url(/images/landing-pages/endeavour-banner-2-min.jpg);
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -10;
    position: relative;

    .endeavour-paralax-layer {
      height: 100%;
      background-color: rgba(1, 1, 1, 0.7);
      padding-top: 100px;
      padding-bottom: 100px;
    }
}


#endeavour-last-form-sec{
  display: flex;
  flex-direction: row;
  @include mquery(1024px) {
    flex-wrap: wrap;
  }
  .endeavour-last-form-cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 32%;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
    }
  }

  .endeavour-user-stock-one-img {
    background-color: #2e142c;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
    width: 34%;
    background-image: url(/images/landing-pages/endeavour-argyle-ale-min.jpg);
    @include mquery(1024px) {
      width: 50%;
    }
  }

  .endeavour-user-stock-two-img {
    background-color: #2e142c;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 400px;
    width: 34%;
    background-image: url(/images/landing-pages/endeavour-hops-resized-min.jpg);
    @include mquery(1024px) {
      width: 50%;
      order: 1;
    }
  }

}
