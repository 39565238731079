/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#cake-top-banner .cake-top-banner-flex-center, #cake-story-sec .cake-revenue-bk, #cake-story-sec .cake-story-bk {
  display: flex;
  align-items: center;
  justify-content: center;
}

#cake-story-sec .cake-revenue-bk, #cake-story-sec .cake-story-bk {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#cake-top-banner .cake-top-banner-flex-center {
  height: calc(100vh - 70px);
}

#cake-top-banner .cake-top-banner-flex-center .cake-logo {
  width: 220px;
  margin-bottom: 10px;
}

@media all and (max-width: 600px) {
  #cake-top-banner .cake-top-banner-flex-center .cake-logo {
    width: 170px;
    margin-top: 40px;
  }
}

#cake-top-banner .cake-top-banner-flex-center .cake-subtitle {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  text-align: center;
}

@media all and (max-width: 600px) {
  .cake-top-quote {
    padding: 60px 0 0;
  }
}

.cake-wholesale-text {
  font-size: 24px;
}

.cake-title {
  font-size: 36px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

.cake-register-title {
  font-size: 30px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

.cake-big-title {
  font-size: 40px;
  padding-bottom: 32px;
}

@media all and (max-width: 600px) {
  .cake-big-title {
    font-size: 30px;
  }
}

#cake-intro-sec {
  padding: 60px 0;
}

#cake-intro-sec .cake-intro-text {
  padding: 0 20px;
}

#cake-quote-sec {
  padding: 60px 0;
}

@media all and (max-width: 600px) {
  #cake-quote-sec {
    padding: 90px 0 0;
  }
}

.cake-dashed-border {
  border: 6px dashed #d9db2f;
  padding: 40px 30px;
  position: relative;
}

.cake-icon {
  position: absolute;
  top: -98px;
  right: -100px;
  width: 100px;
}

@media all and (max-width: 1280px) {
  .cake-icon {
    right: -6px;
  }
}

#cake-story-sec {
  padding: 60px 0;
}

@media all and (max-width: 1200px) {
  #cake-story-sec {
    padding: 0;
  }
}

#cake-story-sec .cake-list {
  list-style: disc inside;
  font-size: 17px;
}

#cake-story-sec .cake-list li:nth-child(1), #cake-story-sec .cake-list li:nth-child(2), #cake-story-sec .cake-list li:nth-child(3), #cake-story-sec .cake-list li:nth-child(4), #cake-story-sec .cake-list li:nth-child(5), #cake-story-sec .cake-list li:nth-child(6) {
  list-style-image: url("/images/landing-pages/cake-list-dotts.png");
}

#cake-story-sec .cake-list li {
  margin-bottom: 10px;
  line-height: 1.7rem;
}

#cake-story-sec .cake-list li::before {
  content: "";
  padding-left: 10px;
}

#cake-story-sec .cake-story-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #cake-story-sec .cake-story-text {
    padding: 60px 0 0;
  }
}

#cake-story-sec .cake-story-text-2 {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #cake-story-sec .cake-story-text-2 {
    padding: 0 0 60px;
  }
}

#cake-story-sec .cake-revenue-bk {
  background-image: url(/images/landing-pages/banners/cake-half-banner-1.jpg);
  height: 592px;
}

@media all and (max-width: 768px) {
  #cake-story-sec .cake-revenue-bk {
    height: 600px;
  }
}

@media all and (max-width: 600px) {
  #cake-story-sec .cake-revenue-bk {
    height: 310px;
  }
}

#cake-story-sec .cake-story-bk {
  background-image: url(/images/landing-pages/banners/cake-half-banner-2.jpg);
  height: 520px;
}

.cake-link {
  color: #d8da2f;
}

.cake-link:hover {
  color: #a43a17;
  text-decoration: underline;
}

.subscription_link_color {
  color: #d8da2f;
}

.subscription_link_color:hover {
  color: #d4d626;
  text-decoration: underline;
}

.cake-button-style {
  background-color: #d8da2f;
  color: #fff;
  transition: all 300ms ease;
}

.cake-button-style:hover {
  background-color: #bfbf2b;
  color: #fff;
  opacity: 0.8;
}
