@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';

#agunity-last-form-sec {
    display: flex;
    flex-direction: row;
    @include mquery(1024px) {
      flex-wrap: wrap;
    }
  .agunity-last-form-sec-1 {
    @include lastFormSecOneImg(#000, '/images/landing-pages/agunity/form-1.png');
    @extend %background-property;
  }

  .agunity-last-form-sec-2 {
    background-color: (0,0,0,0.65);
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 400px;
    width: 32%;
    padding: 10px;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
    }
  }
  .agunity-last-form-sec-3 {
    @include lastFormSecThreeImg(#000, '/images/landing-pages/agunity/form-2.png');
    @extend %background-property;
  }
}