/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#douugh-hero .douugh-hero-bg {
  background: black;
  background-size: cover;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 130px;
  background-position: right bottom;
}

@media all and (max-width: 1440px) {
  #douugh-hero .douugh-hero-bg {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media all and (max-width: 768px) {
  #douugh-hero .douugh-hero-bg {
    background-position: left;
  }
}

#douugh-hero .douugh-logo {
  width: 200px;
  margin-bottom: 20px;
}

@media all and (max-width: 500px) {
  #douugh-hero .douugh-logo {
    width: 170px;
  }
}

#douugh-hero .douugh-hero-title {
  font-family: 'latoregular';
  font-size: 36px;
  margin-bottom: 10px;
  line-height: 2.7rem;
}

@media all and (max-width: 500px) {
  #douugh-hero .douugh-hero-title {
    font-size: 30px;
  }
}

#douugh-hero .douugh-hero-subtitle {
  font-family: 'latolight';
  font-size: 26px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

@media all and (max-width: 1440px) {
  #douugh-hero .douugh-hero-subtitle {
    margin-bottom: 14px;
  }
}

@media all and (max-width: 500px) {
  #douugh-hero .douugh-hero-subtitle {
    font-size: 19px;
  }
}

#douugh-hero .douugh-hero-subtitle-register {
  font-family: 'latoregular';
  font-size: 22px;
  margin-bottom: 34px;
  line-height: 2.7rem;
}

@media all and (max-width: 768px) {
  #douugh-hero .douugh-hero-subtitle-register {
    font-size: 20px;
    line-height: 2rem;
  }
}

@media all and (max-width: 1024px) {
  #douugh-hero .douugh-hero-image-cnt {
    padding-top: 20px;
  }
}

@media all and (max-width: 500px) {
  #douugh-hero .douugh-hero-image-cnt {
    padding-top: 0;
  }
}

#douugh-hero .douugh-hero-image {
  background: url(/images/landing-pages/douugh/hero.png) no-repeat center;
  background-size: contain;
  height: 550px;
}

@media all and (max-width: 500px) {
  #douugh-hero .douugh-hero-image {
    height: 250px;
  }
}

.douugh-gradient-bk {
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
}

.douugh-mastercard-logo-cnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.douugh-mastercard-logo-cnt .mastercard-logo {
  opacity: 0.5;
  width: 100px;
  margin-bottom: 20px;
}

.douugh-mastercard-logo-cnt .chart-logo {
  opacity: 0.5;
  width: 80px;
  margin-bottom: 20px;
}

.douugh-button-style {
  background: linear-gradient(160.34deg, #FFC303 0.05%, #FE4D1F 100%, #FE4D1F 100%);
  border: none;
}

.douugh-button-style:hover {
  background: #FF8800;
}

.douugh-link {
  color: #FF8800;
}

.douugh-link:hover {
  color: #FE4D1F;
}

.douugh-grey-color {
  color: #707070;
}

#quote-sec {
  padding: 100px 10px 20px;
}

@media all and (max-width: 768px) {
  #quote-sec {
    padding: 50px 10px 50px;
  }
}

#quote-sec p {
  font-size: 36px;
  color: #707070;
}

@media all and (max-width: 768px) {
  #quote-sec p {
    font-size: 24px;
  }
}

#douugh-iphone-sec {
  background: black;
}

@media all and (max-width: 768px) {
  #douugh-iphone-sec {
    padding: 50px 0;
  }
}

@media all and (max-width: 1024px) {
  #douugh-iphone-sec .douugh-iphone-cnt {
    padding-top: 10px;
  }
}

@media all and (max-width: 768px) {
  #douugh-iphone-sec .douugh-iphone-cnt {
    order: 1;
  }
}

#douugh-iphone-sec .douugh-iphone-slider {
  width: 324px;
  background: url(/images/landing-pages/douugh-iPhoneX-clay-light3_2x-min.png) no-repeat center;
  background-size: contain;
  margin: auto;
  padding: 78px 21px 103px 21px;
}

@media all and (max-width: 1440px) {
  #douugh-iphone-sec .douugh-iphone-slider {
    width: 264px;
  }
}

@media all and (max-width: 500px) {
  #douugh-iphone-sec .douugh-iphone-slider {
    width: 224px;
  }
}

#douugh-iphone-sec .douugh-partnership-header {
  font-size: 26px;
  line-height: 1.33;
  height: 68px;
}

@media all and (max-width: 768px) {
  #douugh-iphone-sec .douugh-partnership-header {
    height: 100%;
  }
}

#douugh-iphone-sec .douugh-partnership-subheader {
  font-size: 18px;
  height: 162px;
}

@media all and (max-width: 768px) {
  #douugh-iphone-sec .douugh-partnership-subheader {
    height: 100%;
  }
}

#douugh-iphone-sec .douugh-align-center {
  display: flex;
  align-items: center;
}

@media all and (max-width: 768px) {
  #douugh-iphone-sec .douugh-align-center {
    flex-wrap: wrap;
    justify-content: center;
  }
}

#douugh-first-info-sec {
  padding: 30px 0 100px 0;
}

@media all and (max-width: 768px) {
  #douugh-first-info-sec {
    padding: 0 0 50px;
  }
}

.douugh-landing-page-content-header {
  width: 100%;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
}

#douugh-third-info-sec {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media all and (max-width: 768px) {
  #douugh-third-info-sec {
    padding: 50px 0;
  }
}

#douugh-paralax-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/douugh-notifications-background-03-min.jpg);
  width: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 768px) {
  #douugh-paralax-sec {
    background-image: url(/images/landing-pages/douugh-notifications-background-mobile-min.jpg);
  }
}

@media all and (max-width: 500px) {
  #douugh-paralax-sec {
    background-attachment: inherit;
  }
}

#douugh-paralax-sec .douugh-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  padding: 100px 50px;
  width: 760px;
}

@media all and (max-width: 1024px) {
  #douugh-paralax-sec .douugh-paralax-layer {
    width: 100%;
    padding: 100px 10px;
  }
}

@media all and (max-width: 768px) {
  #douugh-paralax-sec .douugh-paralax-layer {
    padding: 50px 0;
  }
}

.douugh-be-part-of-image {
  background: url(/images/landing-pages/douugh-phone-motion-min.png) no-repeat center;
  background-size: contain;
  height: 440px;
}

@media all and (max-width: 500px) {
  .douugh-be-part-of-image {
    height: 240px;
  }
}

.douugh-be-part-of-content {
  padding-top: 110px;
  padding-bottom: 0;
}

@media all and (max-width: 1024px) {
  .douugh-be-part-of-content {
    padding-bottom: 110px;
  }
}

@media all and (max-width: 768px) {
  .douugh-be-part-of-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#douugh-last-form-sec {
  padding: 50px 0;
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
}

@media all and (max-width: 768px) {
  #douugh-last-form-sec {
    padding: 50px 0;
  }
}

.douugh-be-part-of-title {
  width: 100%;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .douugh-be-part-of-title {
    font-size: 28px;
  }
}

#douugh-video-sec {
  position: relative;
  width: 100%;
  padding-top: 28.25%;
  background-image: linear-gradient(135deg, #6d4de2, #00f5a1);
  opacity: 0.9;
}

@media all and (max-width: 1024px) {
  #douugh-video-sec {
    padding-top: 56.25%;
  }
}

#douugh-video-sec .douugh-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
