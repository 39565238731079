@import 'extensions/variables';
@import 'extensions/mixins';




#macro-top-banner {
  background-image: url(/images/landing-pages/macrovue-vr-top-banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  display: table;
  @include mquery(1200px) {
    height: fit-content;
  }

  @include mquery(768px) {
    height: calc(100vh - 70px);
  }



  .macro-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, .6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include mquery(1440px) {
      align-items: baseline;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .macro-logo {
      margin-bottom: 50px;
      @include mquery(1440px) {
        margin-bottom: 20px;
      }
      @include mquery(400px) {
        width: 250px;
      }
    }

    .macro-title-width {
      max-width: 660px;
      margin: 0 auto 40px;

    }

  }

  .macro-mobile-img {
    @include mquery(1440px) {
      width: 330px;
    }
    @include mquery(768px) {
      width: 200px;
    }
  }
}

.mm::before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 background: inherit;
 -webkit-filter: hue-rotate(180deg); /* Safari 6.0 - 9.0 */
  filter: hue-rotate(180deg);
  transition: .8;
}


#section-two {

  .macro-half-banner {
    background-image: url(/images/landing-pages/macrovie-content-car-of-the-future.jpg);
    background-repeat: no-repeat;
    background-position: center;
    /*background-size: cover;*/
    height: 806px;
    padding-right: 0;
    padding-left: 0;

    background-size: contain;
    background-position-y: center;
    @include mquery(1451px) {
      height: 789px;
    }
    @include mquery(1236px) {
      height: 851px;
    }
    @include mquery(1024px) {
      height: 320px;
    }
  }

  .second-img-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.3);
  }

  .macro-first-content-cnt {
    padding: 50px;
    @include mquery(1200px) {
      padding: 50px 0;
    }
  }

}

.macro-third-half-banner {
  background-image: url(/images/landing-pages/macrovue-apple_tech-Stars.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 736px;
  padding-right: 0;
  padding-left: 0;

  @include mquery(1521px) {
    height: 816px;
  }
  @include mquery(1329px) {
    height: 876px;
  }
  @include mquery(1024px) {
    height: 320px;
  }
}


#second-content-sec {
  .macro-second-content-cnt {
    @media only screen and (min-width: 1440px) {
        padding: 50px 130px;
    }
    @include mquery(1440px) {
      padding: 50px;
    }

    @include mquery(1200px) {
      padding: 50px 0 50px;
      max-width: 780px;
      margin: auto;
    }
  }
}



#second-form-section {
  background: rgba(1,1,1,.9);
  .flex-items {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 100px;
    padding-bottom: 100px;

  }
  .center-video {
    display: flex;
    justify-content: center;

    @include mquery(1046px) {
      margin-bottom: 80px;
    }
  }

}

.macro-third-content-cnt {
  padding: 50px;
  @include mquery(1200px) {
    padding: 0 0 50px;
  }
}



.macro-button-style {
  background-color: rgb(76,184,84);
  border: 1px rgb(76,184,84);
  color: rgb(255,255,255);

}

.macro-create-account-btn {
  width: 240px;
  color: white;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
  @include mquery(1200px) {
    display: block;
    margin: auto;
  }
}


#mySVG {
  position: fixed;
  top: 15%;
  width: 410px;
  height: 1000px;
  margin-left:-50px;
}

.ms-link {
  color: rgb(76,184,84);
}

.ms-link:hover {
  color: #38913e;
}

.ms-iframe {
  width: 516px;
  height: 315px;

  @include mquery(600px) {
    width: 100%;
    height: 100%;
  }
}


ul.li {
  list-style-type: circle;
  padding-left: 40px;
  color: white;
}


#macro-forth-sec {
  background-image: url(/images/landing-pages/macrovue-Car_of_the_Future.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  position: relative;

  .macro-forth-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, .6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    .macro-forth-content-cnt {

      @media only screen and (min-width: 1440px) {
          padding: 50px 130px;
      }
      @include mquery(1440px) {
        padding: 50px;
      }
      @include mquery(1200px) {
        padding: 50px 0;
        max-width: 780px;
      }
    }

  }
}
