@import 'extensions/variables';
@import 'extensions/mixins';



#republic-top-banner {
  background-image: url(/images/landing-pages/republic-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  height: calc(100vh - 70px);
  width: 100%;
  display: table;
  position: relative;

  .republic-align-top-table {
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.flex-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  &.column {
    flex-direction: column
  }

  @include mquery(500px) {
    flex-direction: column;
  }
}


.x-style {
  width: 40px;
  height: 89px;
  font-size: 30px;
  font-family: latobold;
  color: #469edc;
  @include mquery(500px) {
    width: 10px;
    height: 29px;
    font-size: 30px;

  }
}


.republic-button-style {
  background-color: #1560FA;
  border: 1px solid #1560FA;
  color: #fff;

  &:hover {
    background-color: #3d7bfb;
    text-decoration: underline;
  }
}


.equitise-republic-logo {
  margin-right: 20px;
  margin-bottom: 50px;
  width: 300px;
  @include mquery(500px) {
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 200px;
  }
  @include mquery(320px) {
    margin-top: 30px;
    width: 150px;
  }
}




.republic-logo {
  margin-bottom: 50px;
  width: 300px;
  margin-left: 20px;
  @include mquery(500px) {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 200px;
    margin-left: 0;
  }
  @include mquery(320px) {
    width: 150px;
  }
}


.republic-link {
  color: #1560FA;
  &:hover {
    color: #3d7bfb;
  }
}

#republic-sec-two {
  background: url(/images/landing-pages/republic-half-img.svg) no-repeat left bottom;
  background-size: cover;
  background-color: #fff;
  // @include mquery(1600px) {
  //   background-size: contain;
  // }
}


.republic-sec-two-content {
  padding: 80px 70px;
  // padding-bottom: 1000px;
  // margin-bottom: -1000px;
  background-color: rgba(255,255,255, .8);
  height: 100%;
  // background-image: url(/images/landing-pages/republic-cloud.svg);
  // background-repeat: no-repeat;

  @include mquery(1200px) {
    padding: 50px 15px;

  }

}

// .republic-sec-two-row {
//   overflow: hidden;
//   display: flex;
//   align-items: flex-end;
// }
.republic-first-half-img {
  padding: 0;
}

#republic-third-name-sec-banner {
  background-image: url(/images/landing-pages/republic-name-banner.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 288px;
}


#republic-last-table {
  background-color: #ceeaff;
}

.republic-last-table-cnt {
  padding-bottom: 214px;
  padding-top: 64px;
  background: url(/images/landing-pages/republic-founders.svg) no-repeat right 20% bottom;

  @include mquery(1024px) {
    padding-bottom: 254px;
  }

  @include mquery(500px) {
    padding-bottom: 50px;
    padding-top: 50px;
    background: none;
  }

}


#republic-third-sec {
  padding: 50px 0;
}




.no-margin-sm {
  @include mquery(768px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
