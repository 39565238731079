/* ----------------------------------------------------------
IDENTITY VALIDATION SETTINGS
---------------------------------------------------------- */
.iv-date-fields {
  float: left !important;
  width: 29.8% !important;
}

@media all and (max-width: 670px) {
  .iv-date-fields {
    width: 28.7% !important;
  }
}

.iv-date-separator {
  float: left;
  font-size: 18px;
  padding: 21px 5px;
  color: #bcbcbc;
}
