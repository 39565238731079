/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#bricklane-top-banner #bricklane-top-banner-layer {
  display: flex;
  align-items: center;
  justify-content: center;
}

#bricklane-top-banner, .bricklane-intro-bk, .bricklane-why-raise-bk {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#bricklane-top-banner {
  background-image: url("/images/landing-pages/banners/bricklane-banner_home.png");
  height: calc(100vh - 70px);
  width: 100%;
  background-color: #000;
  display: table;
}

@media all and (max-width: 600px) {
  #bricklane-top-banner {
    background-image: url("/images/landing-pages/banners/bricklane-banner_home.png");
  }
}

#bricklane-top-banner #bricklane-top-banner-layer {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  padding: 50px 0;
}

#bricklane-top-banner #bricklane-top-banner-layer .bricklane-logo {
  margin-bottom: 40px;
  width: 350px;
}

@media all and (max-width: 500px) {
  #bricklane-top-banner #bricklane-top-banner-layer .bricklane-logo {
    width: 250px;
  }
}

#bricklane-top-banner #bricklane-top-banner-layer .bricklane-title-width {
  max-width: 800px;
  margin: 0 auto 40px;
}

#bricklane-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/banners/bricklane-paralax-banner.JPG");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 600px) {
  #bricklane-paralax-sec {
    background-image: url("/images/landing-pages/banners/bricklane-paralax-banner-mobile.jpg");
  }
}

#bricklane-paralax-sec .bricklane-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}

.bricklane-intro-bk {
  background-image: url(/images/landing-pages/banners/BrickLane_QVM_KateShanasy_Feb2019-2838.jpg);
  height: 360px;
}

.bricklane-why-raise-bk {
  background-image: url(/images/landing-pages/banners/BrickLaneBrewing_KateShanasy_Oct2018-9424.jpg);
  height: 360px;
}

#bricklane-story-sec {
  padding: 60px 0;
}

#bricklane-story-sec .bricklane-story-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #bricklane-story-sec .bricklane-story-text {
    padding: 60px 0 0;
  }
}

#bricklane-why-raise-sec {
  padding: 60px 0;
}

#bricklane-why-raise-sec .bricklane-why-raise-text {
  padding: 0 20px;
}

@media all and (max-width: 1200px) {
  #bricklane-why-raise-sec .bricklane-why-raise-text {
    padding: 60px 0 0;
  }
}

#bricklane-partnership-with-sec {
  background: #ef5d6a;
  padding: 60px 0;
}

#bricklane-last-form-cnt {
  background: #ef5d6a;
  padding: 60px 0;
}

.bricklane-link {
  color: #ef5d6a;
}

.bricklane-link:hover {
  color: #ef5d6a;
  text-decoration: underline;
}

.bricklane-bottom-link {
  color: #26333b;
}

.bricklane-bottom-link:hover {
  color: #fff;
  text-decoration: underline;
}

.bricklane-bottom-button-style {
  background-color: #26333b;
  color: #fff;
  transition: all 300ms ease;
}

.bricklane-bottom-button-style:hover {
  background-color: #f5f1e5;
  color: #26333b;
  opacity: 1;
}

.bricklane-button-style {
  background-color: #ef5d6a;
  color: #fff;
  transition: all 300ms ease;
}

.bricklane-button-style:hover {
  background-color: #f5f1e5;
  color: #26333b;
  opacity: 1;
}
