/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#credi-top-banner #credi-top-banner-layer {
  display: flex;
  align-items: center;
  justify-content: center;
}

#credi-top-banner {
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
}

@media all and (max-width: 1440px) {
  #credi-top-banner {
    height: initial;
    padding: 60px 0;
  }
}

@media all and (max-width: 768px) {
  #credi-top-banner {
    padding: 20px 0;
  }
}

#credi-top-banner #credi-top-banner-layer {
  height: calc(100vh - 70px);
}

@media all and (max-width: 1440px) {
  #credi-top-banner #credi-top-banner-layer {
    height: initial;
  }
}

@media all and (max-width: 1280px) {
  #credi-top-banner #credi-top-banner-layer {
    display: block;
  }
}

#credi-top-banner #credi-top-banner-layer .credi-logo {
  margin-bottom: 40px;
  width: 300px;
}

@media all and (max-width: 500px) {
  #credi-top-banner #credi-top-banner-layer .credi-logo {
    width: 250px;
  }
}

@media all and (max-width: 1440px) {
  #credi-top-banner #credi-top-banner-layer .credi-logo {
    width: 200px;
    padding-top: 20px;
    margin-bottom: 40px;
  }
}

@media all and (max-width: 600px) {
  #credi-top-banner #credi-top-banner-layer .credi-logo {
    width: 150px;
  }
}

#credi-top-banner .credi-title-width {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

@media all and (max-width: 767px) {
  #credi-top-banner .credi-title-width {
    max-width: 430px;
    margin-right: auto;
    margin-left: auto;
  }
}

#credi-top-banner .credi-top-banner-content {
  z-index: 10;
  padding: 0 15px;
}

@media all and (max-width: 1440px) {
  #credi-top-banner .credi-top-banner-content {
    max-width: 650px;
    margin: auto;
  }
}

#credi-top-banner .credi-subtitle-width {
  max-width: 630px;
  margin: 0 auto 60px;
}

#credi-top-banner .credi-top-banner-left-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
}

#credi-top-banner .credi-top-banner-right-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
}

#credi-top-banner .input-border-credi-grey {
  border: 1px solid #ccc;
}

#credi-real-different-sec {
  background-color: #25ade4;
  padding: 60px 0;
}

#credi-real-different-sec .credi-real-different-header {
  font-size: 40px;
  margin-bottom: 40px;
}

@media all and (max-width: 768px) {
  #credi-real-different-sec .credi-real-different-header {
    font-size: 32px;
  }
}

.credi-icon {
  margin-bottom: 20px;
}

@media all and (max-width: 1024px) {
  .credi-icon-margin {
    margin-bottom: 20px;
  }
}

#credi-mission-sec {
  background-color: #ff9e1d;
  padding: 60px 0;
}

#credi-mission-sec .credi-mission-img {
  width: 200px;
}

@media all and (max-width: 767px) {
  #credi-mission-sec .credi-mission-img {
    display: block;
    margin: 0 auto 40px;
    width: 150px;
  }
}

#credi-support-sec {
  background-color: #ff9e1d;
  padding: 60px 0;
}

#credi-support-sec .credi-support-img {
  float: right;
  width: 400px;
}

@media all and (max-width: 767px) {
  #credi-support-sec .credi-support-img {
    display: block;
    margin: 0 auto 40px;
    float: none;
    width: 180px;
  }
}

@media all and (max-width: 766px) {
  #credi-support-sec .credi-change-order {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 766px) {
  #credi-support-sec .credi-change-order .credi-change-order-0 {
    order: 1;
  }
}

@media all and (max-width: 766px) {
  #credi-support-sec .credi-change-order .credi-change-order-1 {
    order: 0;
  }
}

.credi-sec-header {
  font-size: 40px;
  margin-bottom: 40px;
}

@media all and (max-width: 1024px) {
  .credi-sec-header {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  .credi-sec-header {
    font-size: 32px;
  }
}

.credi-blue {
  color: #25ade4;
}

#credi-market-size-sec {
  padding: 60px 0;
}

@media all and (max-width: 1024px) {
  #credi-market-size-sec .credi-market-size-img {
    width: 100%;
  }
}

#credi-story-sec {
  padding: 60px 0;
}

#credi-story-sec .credi-story-bk {
  background: url(/images/landing-pages/banners/Credi_Logo_Asset-story.png) no-repeat center;
  background-size: contain;
  height: 360px;
}

#credi-two-year-plan-sec {
  padding: 60px 0;
  background-color: #25ade4;
}

#credi-two-year-plan-sec .credi-triangle-down {
  margin: 40px auto;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 50px solid #fff;
}

.credi-video {
  width: 100%;
  height: 304px;
}

@media all and (max-width: 1200px) {
  .credi-video {
    height: 400px;
  }
}

@media all and (max-width: 600px) {
  .credi-video {
    height: 200px;
  }
}

#credi-addressable-market-sec {
  padding: 60px 0;
}

.credi-link {
  color: #becf00;
}

.credi-link:hover {
  color: #b2c200;
  text-decoration: underline;
}

.subscription_link_color {
  color: #becf00;
}

.subscription_link_color:hover {
  color: #a43a17;
  text-decoration: underline;
}

.credi-button-style {
  background-color: #becf00;
  color: #fff;
  transition: all 300ms ease;
}

.credi-button-style:hover {
  background-color: #abb703;
  color: #fff;
  opacity: 1;
}

#credi-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/banners/credi-paralax-banner.jpg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

@media all and (max-width: 600px) {
  #credi-paralax-sec {
    background-image: url("/images/landing-pages/banners/credi-paralax-banner-mobile.jpg");
  }
}

@media all and (max-width: 768px) {
  #credi-paralax-sec {
    background-attachment: initial;
  }
}

#credi-paralax-sec .credi-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}
