@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
PAGINATION STYLE
---------------------------------------------------------- */
.pagination-out-wrap{
    float: left;
    width: 100%;
    margin: 0;
    padding: 20px 0 0;
    text-align: center;
}
.pagination{
    float: none;
    display: inline-block;
    li{
        float: left;
        width: 25px;
        height: 25px;
        margin: 0 3px;
        display: table;
        a,
        span{
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            font-family: 'latoregular';
            color: $pagination-a-color;
            font-size: 14px;
        }
        &.active,
        &:hover{
            a,
            span{
                background-color: $pagination-active-bg;
                color: $pagination-active-color;
            }
        }
        &.disabled,
        &.disabled:hover{
            a,
            span{
                background-color: $pagination-disabled-bg;
                color: $pagination-disable-color;
            }
        }
        &:first-child,
        &:last-child{
            a,
            span{
                font-size: 18px;
                padding-bottom: 4px;
            }
        }
        
    }
}