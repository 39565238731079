@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
EQUITISE TEAM
---------------------------------------------------------- */
.team-wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
  .team-title{
    font-size: 36px;
    color: #7e7e7e;
    text-align: center;
    font-family: latoregular;
  }
  .team-section {
      float: left;
      width: 100%;
      margin: 50px 0px 0px 0px;
      padding: 0px;
      > span.team-subheading {
          float: left;
          width: 100%;
          margin: 0px;
          padding: 0px 0px 20px 0px;
          font-family: 'latoregular';
          font-size: 20px;
          color: $default-text-color;
          text-align: center;
      }
      .team-blocks {
          // float: left;
          // width: 100%;
          // margin: 0px;
          // padding: 0px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: relative;
          @include mquery(1200px) {
            justify-content: center;
          }
          > .team-block {
              float: left;
              width: 300px;
              margin: 0px;
              // padding: 40px;
              min-height: 515px;
              margin: 40px;
              // @include mquery(1200px) {
              //   width: 50%;
              // }
              // @include mquery(736px) {
              //   padding: 40px 0;
              // }

              > div {
                  float: left;
                  width: 100%;
                  margin: 0px;
                  padding: 0px;
                  text-align: center;
                  position: relative;
                  > img {
                      display: inline-block;
                      margin: 0px;
                      padding: 0px;
                      width: 300px;
                      height: 300px;
                      @include mquery(320px) {
                        width: 250px;
                        height: 250px;
                      }

                  }
                  span.team-member-name {
                      float: left;
                      width: 100%;
                      margin: 0px;
                      padding: 20px 0px 10px;
                      text-align: center;
                      font-family: 'latobold';
                      font-size: 18px;
                      color: $default-text-color;
                  }
                  span.team-member-role {
                      float: left;
                      width: 100%;
                      margin: 0px;
                      padding: 0px;
                      font-family: 'latoregular';
                      font-size: 15px;
                      color: $default-blue-color;
                      text-align: center;
                  }
                  a.team-member-linkedin {
                      display: inline-block;
                      width: 21px;
                      height: 21px;
                      margin: 20px 0px 0px 0px;
                      padding: 0px;
                      background-image: url(../../images/eq-team-linkedin.png);
                      background-repeat: no-repeat;
                  }
        
                  .member-info-cnt {
                    height: 96px;



                  .member-info {
                      width: 100%;
                      margin: 15px 0px 0px 0px;
                      padding: 0px;
                      font-family: 'latolight';
                      font-size: 14px;
                      color: $default-text-color;
                      line-height: 150%;
                      line-height: 1.2em;
                      height: 3.6em;
                      overflow: hidden;
                      display: block;
                      display: -webkit-box;
                      max-width: 400px;
                      -webkit-line-clamp: 3;
                      /* autoprefixer: off */

                      -webkit-box-orient: vertical;

                      /* autoprefixer: on */
                      overflow: hidden;
                      text-overflow: ellipsis;

                  }


                  .expand {
                    line-height: 150%;
                    position: absolute;
                    top: 0;
                    background-color: rgba(1, 1, 1, .7);
                    color: white;
                    width: 100%;
                    padding: 20px;
                    height: 500px;
                    line-height: 150%;
                    font-size: 14px;
                    margin: 0;
                    overflow: hidden;
                    transition: height .5s;
                    // cancel line clamp in expand desc
                    -webkit-line-clamp: 50;
                  }
                }
                .more-less {
                  padding: 8px;
                  border: 1px solid #7e7e7e;
                  cursor: pointer;
                  float: left;
                  width: 140px;
                  text-align: center;
                }
                .more-less:hover {
                  background-color: rgba(1, 1, 1, 0.7);
                  border: none;
                  color: white;
                }
              }
          }
      }
  }
}
