@import 'extensions/variables';
@import 'extensions/mixins';



#travello-top-banner {
  background-image: url(/images/travello-top-bk.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  .travello-logo {
    
  }
}
.travello-top-banner-overlay {
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
