/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#republic-top-banner {
  background-image: url(/images/landing-pages/republic-header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  height: calc(100vh - 70px);
  width: 100%;
  display: table;
  position: relative;
}

#republic-top-banner .republic-align-top-table {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.flex-logos.column {
  flex-direction: column;
}

@media all and (max-width: 500px) {
  .flex-logos {
    flex-direction: column;
  }
}

.x-style {
  width: 40px;
  height: 89px;
  font-size: 30px;
  font-family: latobold;
  color: #469edc;
}

@media all and (max-width: 500px) {
  .x-style {
    width: 10px;
    height: 29px;
    font-size: 30px;
  }
}

.republic-button-style {
  background-color: #1560FA;
  border: 1px solid #1560FA;
  color: #fff;
}

.republic-button-style:hover {
  background-color: #3d7bfb;
  text-decoration: underline;
}

.equitise-republic-logo {
  margin-right: 20px;
  margin-bottom: 50px;
  width: 300px;
}

@media all and (max-width: 500px) {
  .equitise-republic-logo {
    margin-right: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 200px;
  }
}

@media all and (max-width: 320px) {
  .equitise-republic-logo {
    margin-top: 30px;
    width: 150px;
  }
}

.republic-logo {
  margin-bottom: 50px;
  width: 300px;
  margin-left: 20px;
}

@media all and (max-width: 500px) {
  .republic-logo {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 200px;
    margin-left: 0;
  }
}

@media all and (max-width: 320px) {
  .republic-logo {
    width: 150px;
  }
}

.republic-link {
  color: #1560FA;
}

.republic-link:hover {
  color: #3d7bfb;
}

#republic-sec-two {
  background: url(/images/landing-pages/republic-half-img.svg) no-repeat left bottom;
  background-size: cover;
  background-color: #fff;
}

.republic-sec-two-content {
  padding: 80px 70px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
}

@media all and (max-width: 1200px) {
  .republic-sec-two-content {
    padding: 50px 15px;
  }
}

.republic-first-half-img {
  padding: 0;
}

#republic-third-name-sec-banner {
  background-image: url(/images/landing-pages/republic-name-banner.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 288px;
}

#republic-last-table {
  background-color: #ceeaff;
}

.republic-last-table-cnt {
  padding-bottom: 214px;
  padding-top: 64px;
  background: url(/images/landing-pages/republic-founders.svg) no-repeat right 20% bottom;
}

@media all and (max-width: 1024px) {
  .republic-last-table-cnt {
    padding-bottom: 254px;
  }
}

@media all and (max-width: 500px) {
  .republic-last-table-cnt {
    padding-bottom: 50px;
    padding-top: 50px;
    background: none;
  }
}

#republic-third-sec {
  padding: 50px 0;
}

@media all and (max-width: 768px) {
  .no-margin-sm {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
