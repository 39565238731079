/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#soda-top-banner-cnt {
  height: calc(100vh - 70px);
  display: table;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: url(/images/landing-pages/soda-top-banner-new.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media all and (max-width: 320px) {
  #soda-top-banner-cnt {
    height: 100vh;
  }
}

.soda-layer {
  background-color: rgba(1, 1, 1, 0.6);
  height: 100%;
  width: 100%;
}

.soda-layer .soda-top-content-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.soda-layer .soda-top-content-cnt .soda-logo {
  margin-bottom: 70px;
}

@media all and (max-width: 414px) {
  .soda-layer .soda-top-content-cnt .soda-logo {
    margin-bottom: 30px;
    width: 200px;
  }
}

.soda-link {
  color: #66e5f2;
  cursor: pointer;
}

.soda-link:hover {
  color: #62c7d1;
}

.soda-button-style {
  background: #66e5f2;
  border: 1px solid #66e5f2;
  color: #333;
  transition: .3s;
}

.soda-button-style:hover {
  background: #62c7d1;
  border: 1px solid #62c7d1;
  color: #fff;
}

.first-half-image {
  width: 50%;
  display: block;
  margin: auto;
}

#soda-second-content-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/soda-paralax-banner.JPG);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

.soda-second-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

.soda-add-top-padd {
  padding-top: 100px;
  padding-bottom: 100px;
}

.soda-empty-white-div {
  height: 100%;
  position: relative;
}

#soda-video {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media all and (max-width: 1200px) {
  #soda-video {
    padding: 0;
  }
}

.soda-video-iframe {
  width: 90%;
  height: 400px;
  background-color: black;
}

@media all and (max-width: 1200px) {
  .soda-video-iframe {
    width: 100%;
  }
}

.soda-square-img-cnt {
  margin-top: 80px;
}

.soda-first-half-img {
  background-image: url(/images/landing-pages/soda-half-mobile.png);
  background-size: contain;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: 50px;
}

@media all and (max-width: 769px) {
  .soda-first-half-img {
    height: 300px;
  }
}

.soda-sec-form {
  padding-top: 80px;
  padding-bottom: 34px;
}

.soda-p-width {
  padding-top: 50px;
  padding-bottom: 50px;
}

.soda-last-half-img {
  background-image: url(/images/landing-pages/soda-half-banner-new-2.jpg);
  background-size: cover;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
}

@media all and (max-width: 767px) {
  .soda-last-half-img {
    height: 260px;
  }
}
