@mixin border($bside, $bwidth, $bstyle, $bcolor) {
	border-style: $bstyle;
	border-color: $bcolor;

	@if $bside == 'full' {
		border-width: $bwidth;
	} @else if $bside == 'left' {
		border-left-width: $bwidth;
	} @else if $bside == 'right' {
		border-right-width: $bwidth;
	} @else if $bside == 'top' {
		border-top-width: $bwidth;
	} @else if $bside == 'bottom' {
		border-bottom-width: $bwidth;
	}
}

@mixin transition($tspeed) {
	-webkit-transition: $tspeed;
	-moz-transition: $tspeed;
	-o-transition: $tspeed;
	transition: $tspeed;
}

@mixin mquery($max-width) {
	@media all and (max-width: $max-width) {	@content; }
}

@mixin respond-to($media) {
	@if $media == medium-screens {
	    @media only screen and (min-device-width: 737px) and (max-device-width: 1200px)
			{ @content; }
	}
}




@mixin btn($margin: 10px, $base-color: #469edc) {
	margin: $margin;
	padding: 10px 30px;
	min-width: 140px;
	font-size: 16px;
	line-height: 24px;
	font-family: 'latolight';
	text-align: center;
	background: none;
	border: 1px solid $base-color;
	color: $base-color;
	border-radius: 2px;
	transition: background 0.3s ease;

	&:hover {
		cursor: pointer;
		background: #469edc;
		color: #fff;
		border: none;
	}
}

@mixin btn-solid($margin, $base-color, $text-hover-color) {
	@include btn($margin);
	color: #fff;

	&:hover {
		cursor: pointer;
		background: #fff;
		color: $text-hover-color;
		border: none;
	}
}

// mixin for landing pages

@mixin top-banner($img, $background-color, $mobile-img) {
	background-image: url($img);
  height: calc(100vh - 70px);
	width: 100%;
  background-color: $background-color;
  display: table;
	@include mquery(600px) {
		background-image: url($mobile-img);
	}
}
@mixin top-banner-layer($rgba) {
	background-color: $rgba;
	height: 100%;
	padding: 50px 0;
}

@mixin logo-style($margin, $widthBg, $widthSm){
	margin-bottom: $margin;
	width: $widthBg;
	@include mquery(500px) {
		width: $widthSm;
	}
}

@mixin button-style($bk-color, $color, $hover-bk-color, $hover-color, $opacity) {
	background-color: $bk-color;
	color: $color;
  transition: all 300ms ease;
	&:hover {
		background-color: $hover-bk-color;
    color: $hover-color;
		opacity: $opacity;
	}
}

@mixin link($color, $hover-color, $text-decoraion){
	color: $color;
	&:hover {
		color: $hover-color;
		text-decoration: $text-decoraion;
	}
}

@mixin subscription_link_color($color, $hover-color, $text-decoraion) {
	color: $color;
	&:hover {
		color: $hover-color;
		text-decoration: $text-decoraion;
	}
}

@mixin parallax-sec($bk-img, $mobile-img){
	background-attachment: fixed;
  background-image: url($bk-img);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
	@include mquery(600px) {
		background-image: url($mobile-img);
	}
}

@mixin parallax-layer($bk-color) {
	height: 100%;
	background-color: $bk-color;
	padding-top: 100px;
	padding-bottom: 100px;
}

@mixin lastFormSecOneImg($bk-color, $bk-img) {
	background-color: $bk-color;
  height: 400px;
  width: 34%;
  background-image: url($bk-img);
  @include mquery(1024px) {
    width: 50%;
  }
  @include mquery(500px) {
    height: 180px;
  }
}

@mixin lastFormSecThreeImg($bk-color, $bk-img) {
	background-color: $bk-color;
  height: 400px;
  width: 34%;
  background-image: url($bk-img);
  @include mquery(1024px) {
    width: 50%;
    order: 1;
  }
  @include mquery(500px) {
    height: 180px;
  }
}
