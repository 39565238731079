/**************************
*      GENERAL STYLES     *
**************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	outline: none;
    font-family: 'latolight';
	text-decoration:none;
	line-height:100%;
	text-align:left;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body{
    height: 100%;
	
}

body {
	line-height: 1;
    background-color: #f3f5f6;
	
}
// ol, ul {
// ul {
// 	list-style: none;
// }
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a:active{outline: none;}
* :focus {outline: none;}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.clear{
	clear:both;
}
.no-margin{
  margin: 0 !important;
}
.no-padding{
  padding: 0 !important;
}
.no-padd-top{
  padding-top: 0 !important;
}

/**************************
*         FONTS 	      *
**************************/
@font-face {
  font-family: 'fontello';
  src: url('../../css/fonts/fontello/fontello.eot?35311783');
  src: url('../../css/fonts/fontello/fontello.eot?35311783#iefix') format('embedded-opentype'),
       url('../../css/fonts/fontello/fontello.woff2?35311783') format('woff2'),
       url('../../css/fonts/fontello/fontello.woff?35311783') format('woff'),
       url('../../css/fonts/fontello/fontello.ttf?35311783') format('truetype'),
       url('../../css/fonts/fontello/fontello.svg?35311783#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'latoblack';
    src: url('../../css/fonts/lato-black-webfont.eot');
    src: url('../../css/fonts/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../css/fonts/lato-black-webfont.woff2') format('woff2'),
         url('../../css/fonts/lato-black-webfont.woff') format('woff'),
         url('../../css/fonts/lato-black-webfont.ttf') format('truetype'),
         url('../../css/fonts/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../../css/fonts/lato-bold-webfont.eot');
    src: url('../../css/fonts/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../css/fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../../css/fonts/lato-bold-webfont.woff') format('woff'),
         url('../../css/fonts/lato-bold-webfont.ttf') format('truetype'),
         url('../../css/fonts/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latolight';
    src: url('../../css/fonts/lato-light-webfont.eot');
    src: url('../../css/fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../css/fonts/lato-light-webfont.woff2') format('woff2'),
         url('../../css/fonts/lato-light-webfont.woff') format('woff'),
         url('../../css/fonts/lato-light-webfont.ttf') format('truetype'),
         url('../../css/fonts/lato-light-webfont.svg#latolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoregular';
    src: url('../../css/fonts/lato-regular_0-webfont.eot');
    src: url('../../css/fonts/lato-regular_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../css/fonts/lato-regular_0-webfont.woff2') format('woff2'),
         url('../../css/fonts/lato-regular_0-webfont.woff') format('woff'),
         url('../../css/fonts/lato-regular_0-webfont.ttf') format('truetype'),
         url('../../css/fonts/lato-regular_0-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: "icons";
  src: url("../../css/fonts/icons.eot");
  src: url("../../css/fonts/icons.eot?#iefix") format("embedded-opentype"),
       url("../../css/fonts/icons.woff") format("woff"),
       url("../../css/fonts/icons.ttf") format("truetype"),
       url("../../css/fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSansRounded-1000";
  src: url("../../css/fonts/364270_0_0.eot");
  src: url("../../css/fonts/364270_0_0.eot?#iefix") format("embedded-opentype"),
      url("../../css/fonts/364270_0_0.woff") format("woff"),
      url("../../css/fonts/364270_0_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icons";
    src: url("../../css/fonts/icons.svg#icons") format("svg");
  }
}

span[class^="icon-"]:before{
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 17px;
}

.icon-ball:before { content: "\f100"; }
.icon-bars:before { content: "\f101"; }
.icon-building:before { content: "\f102"; }
.icon-circle:before { content: "\f103"; }
.icon-commerce:before { content: "\f104"; }
.icon-computer:before { content: "\f105"; }
.icon-education:before { content: "\f106"; }
.icon-internet:before { content: "\f107"; }
.icon-biotechnology:before { content: "\f108"; }
.icon-medical-1:before { content: "\f109"; }
.icon-nature:before { content: "\f10a"; }
.icon-art-and-design:before { content: "\f10b"; }
.icon-technology:before { content: "\f10c"; }
.icon-technology-1:before { content: "\f10d"; }
.icon-technology-2:before { content: "\f10e"; }


[class^="od-id-"]:before, [class*="od-id-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-right: 0;
  text-align: left;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 35px;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-heart:before { content: '\e800'; } /* '' */
.icon-user:before { content: '\e802'; } /* '' */
.icon-down:before { content: '\e804'; } /* '' */
.icon-star-1:before { content: '\e805'; } /* '' */
.od-id-web:before { content: '\e801'; } /* '' */
.od-id-twitter:before { content: '\e803'; } /* '' */
.od-id-facebook:before { content: '\e806'; } /* '' */
.od-id-linkedin:before { content: '\e807'; } /* '' */
.od-id-pinterest:before { content: '\e808'; } /* '' */
.od-id-rabble:before { content: '\f25d'; } /* '' */
.od-id-instagram:before { content: '\f31e'; } /* '' */
    
/**************************
*      MEDIA QUERIES      *
**************************/
@media screen and (max-device-width: 800px){
  body{
    -webkit-text-size-adjust: none;
  }
}


@media screen and (max-width: 767px) {
  /* Responsive Team Block */
  .team-section .team-blocks > .team-block, .about-equitise-steps > .about-equitise-step {
    width: 95% !important;
  }
  
  /* Remove Padding for Mobile after about-equtise-steps */
  .about-equitise-steps{
    padding: 0 !important;
  }

  /* Tooltip profile was not aligning in responsive */
  .diff-left-aligned-helper {
    margin: 10px !important;
  }

  /* Remove profile picture preview on mobile */
  .cp-fg-image-preview-mobile-hide{
    display: none;
  }
}