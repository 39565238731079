@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
FOOTER
---------------------------------------------------------- */ 
.my-investments-wrapper {
    float: left;
    width: 100%;
    margin: 60px 0px 0px 0px;
    padding: 0px 340px 0px 0px;
    position: relative;
    @include mquery(960px) {
        padding: 0px;
    }
}
.id-info-tooltip {
    margin-bottom: 15px;
}
.id-tabs {
    float: left;
    width: 100%;
    min-height: 40px;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    position: relative;
    @include mquery(600px) {
        background-color: $up-tabs-bg;
    }
    > li {
        float: left;
        width: 33.3%;
        min-height: 40px;
        margin: 0px;
        padding: 0px;
        list-style: none;
        @include border(right, 1px, solid, $up-tabs-border);
        &:last-of-type {
            border: 0px;
        }
        @include mquery(600px) {
            width: 50%;
            @include border(bottom, 1px, solid, $up-tabs-border);
            &:last-of-type {
                @include border(right, 1px, solid, $up-tabs-border);
            }
        }
        > a {
            float: left;
            width: 100%;
            min-height: 40px;
            margin: 0px;
            padding: 11px 0px 0px 0px;
            text-decoration: none;
            font-family: 'latobold';
            font-size: 15px;
            color: $up-tabs-links-color;
            background-color: $up-tabs-bg;
            text-align: center;
            @include transition(.3s);
            &:hover, &.active {
                background-color: lighten($up-tabs-bg, 5%);
            }
        }
    }
} /* Investments details tabs ends here */
.id-inner-tabs {
    float: left;
    width: 100%;
    min-height: 40px;
    margin: 15px 0px 0px 0px;
    padding: 0px;
    position: relative;
    @include mquery(600px) {
        background-color: $up-tabs-bg;
    }
    > li {
        float: left;
        width: 20%;
        min-height: 40px;
        margin: 0px;
        padding: 0px;
        list-style: none;
        @include border(right, 1px, solid, $up-tabs-border);
        &:last-of-type {
            border: 0px;
        }
        @include mquery(600px) {
            width: 50%;
            @include border(bottom, 1px, solid, $up-tabs-border);
            &:last-of-type {
                @include border(right, 1px, solid, $up-tabs-border);
            }
        }
        > a {

            float: left;
            width: 100%;
            min-height: 40px;
            margin: 0px;
            padding: 11px 0px 0px 0px;
            text-decoration: none;
            font-family: 'latobold';
            font-size: 15px;
            color: $up-tabs-links-color;
            background-color: $up-tabs-bg;
            text-align: center;
            @include transition(.3s);
            &:hover, &.active {
                background-color: lighten($up-tabs-bg, 5%);
            }
        }
    }
} /* Investments details inner tabs ends here */
