/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#mangras-landing-page {
  background-image: url(/images/landing-pages/manrags-banner-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 800px;
  width: 100%;
  position: relative;
  display: table;
}

@media all and (max-width: 768px) {
  #mangras-landing-page {
    background-image: url(/images/landing-pages/manrags-banner-mobile.jpg);
  }
}

#mangras-landing-page .layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 767px) {
  #mangras-landing-page .layer {
    background-color: rgba(1, 1, 1, 0.2);
    align-items: start;
  }
}

#mangras-landing-page .manrags-title-width {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

#mangras-landing-page .manrags-logo {
  display: block;
  margin: 0 auto 50px;
}

@media all and (max-width: 767px) {
  #mangras-landing-page .manrags-logo {
    width: 280px;
    margin: 30px auto;
  }
}

.manrags-line-banner {
  width: 100%;
}

#manrags-content-section {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1200px) {
  #manrags-content-section {
    flex-direction: column-reverse;
  }
}

#manrags-content-section .manrags-half-banner {
  background-image: url(/images/landing-pages/manrags-002-min.jpeg);
  background-size: contain;
  background-position: top;
  background-color: #fff;
  background-repeat: repeat-y;
}

#manrags-content-section .manrags-half-content {
  padding: 80px 50px;
}

@media all and (max-width: 768px) {
  #manrags-content-section .manrags-half-content {
    padding: 80px 20px;
  }
}

.sec-eoi {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

@media all and (max-width: 1024px) {
  .sec-eoi {
    height: 400px;
  }
}

.manrags-button-style {
  background-color: rgba(1, 1, 1, 0.8);
  border: 1px solid black;
  transition: .5s;
}

.manrags-button-style:hover {
  color: #fff;
  opacity: .7;
}

.manrags-down-half-banner-top {
  background-image: url(/images/landing-pages/manrags-001-min.jpg);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  margin: 0px -15px 0px -15px;
  height: 500px;
}

.manrags-view-offer-button-style {
  border: 1px solid black;
  color: white;
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: rgba(1, 1, 1, 0.8);
  animation: bounce 3s;
  transition: .3s;
}

.manrags-view-offer-button-style:hover {
  background-color: #3d3c3c;
  border: 1px solid #3d3c3c;
}
