/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#moneyball-top-banner {
  padding-top: 120px;
  position: relative;
  background-color: #fff;
  height: calc(100vh - 70px);
}

@media all and (max-width: 1200px) {
  #moneyball-top-banner {
    padding-top: 20px;
  }
}

#moneyball-top-banner .moneyball-top-banner-layer {
  position: absolute;
  background-color: rgba(1, 1, 1, 0.7);
  height: calc(100vh - 70px);
  top: 0;
  width: 100%;
}

#moneyball-top-banner .half-banner-iphone {
  background-image: url(/images/landing-pages/moneyball-iphone.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 715px;
}

@media all and (max-width: 1200px) {
  #moneyball-top-banner .half-banner-iphone {
    height: 340px;
  }
}

#moneyball-top-banner .moneyball-logo {
  margin: 0 auto 70px;
  display: block;
  width: 300px;
}

@media all and (max-width: 768px) {
  #moneyball-top-banner .moneyball-logo {
    margin: 0 auto 30px;
  }
}

@media all and (max-width: 320px) {
  #moneyball-top-banner .moneyball-logo {
    width: 200px;
  }
}

#moneyball-top-banner .moneyball-title-max-width {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

#moneyball-top-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(/images/landing-pages/moneynball-banner-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 70px);
  width: 100%;
}

#moneyball-content-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media all and (max-width: 1200px) {
  #moneyball-content-section {
    flex-direction: column;
  }
}

#moneyball-content-section .half-banner-trophy-guy {
  background-image: url(/images/landing-pages/moneyball-happy-punter.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 580px;
}

@media all and (max-width: 1200px) {
  #moneyball-content-section .half-banner-trophy-guy {
    height: 340px;
  }
}

#moneyball-content-section .moneyball-content-cnt {
  padding: 30px 0;
}

@media all and (max-width: 1200px) {
  #moneyball-content-section .moneyball-content-cnt {
    padding: 20px 15px;
  }
}

@media all and (max-width: 320px) {
  #moneyball-content-section .moneyball-content-cnt {
    padding: 20px 7px;
  }
}

.second-form {
  background-color: #455a64;
  padding: 30px 30px;
}

@media all and (max-width: 768px) {
  .second-form {
    padding: 30px 0;
  }
}

@media all and (max-width: 320px) {
  .second-form {
    padding-right: 7px;
    padding-left: 7px;
  }
}

.moneyball-button-style {
  background-color: #48B115;
  border: none;
}

.moneyball-button-style:hover {
  opacity: .9;
  transition: .9s;
}

.moneyball-link-color {
  color: #48B115;
}

.moneyball-link-color:hover {
  color: #30770d;
}

.equitise-about-box {
  padding: 15px 30px;
  height: 100%;
}

@media all and (max-width: 768px) {
  .equitise-about-box {
    padding: 15px 15px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media all and (max-width: 1200px) {
  .first-form {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media all and (max-width: 1200px) {
  .moneyball-down-form {
    padding: 40px 0;
  }
}

#moneyball-video .maneyball-video-row {
  width: 100%;
  display: flex;
  align-items: center;
}

@media all and (max-width: 1200px) {
  #moneyball-video .maneyball-video-row {
    display: flex;
    flex-direction: column;
  }
}

#moneyball-video .video {
  max-width: 100%;
  height: auto;
}

#moneyball-video .no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media all and (max-width: 1200px) {
  #moneyball-video .manyball-second-table {
    flex-basis: 100%;
    order: 1;
  }
}
