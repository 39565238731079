/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#mn-hashching-tp-banner {
  background-image: url(/images/landing-pages/hashching-bk-img-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ccc;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 70px);
  display: table;
}

@media all and (max-width: 736px) {
  #mn-hashching-tp-banner {
    background-image: url(/images/landing-pages/hashching-mobile.jpg);
  }
}

#mn-hashching-tp-banner .hashching-layer {
  background-color: rgba(23, 50, 77, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

#mn-hashching-tp-banner .mn-max-width {
  max-width: 470px;
  display: block;
  margin: auto;
}

#mn-hashching-tp-banner .mn-hashching-logo {
  width: 430px;
  margin-bottom: 80px;
}

@media all and (max-width: 736px) {
  #mn-hashching-tp-banner .mn-hashching-logo {
    margin-bottom: 30px;
  }
}

.hashching-BlueGreen {
  color: #157B98;
}

.hashching-BabyBlue {
  color: #0AB5DD;
}

.hashching-BabyBlue:hover {
  color: #747474;
}

.hashching-RoyalBlue {
  color: #1F3F5F;
}

.hashching-RoyalBlue-bk {
  background-color: #1F3F5F;
}

.padding-top-150 {
  padding-top: 150px;
}

@media all and (max-width: 736px) {
  .padding-top-150 {
    padding-top: 50px;
  }
}

#mn-hashching-blue-bk {
  background-color: #0ab5dd;
  position: relative;
}

@media all and (max-width: 1200px) {
  #mn-hashching-blue-bk {
    background-image: none;
    background-color: #0AB5DD;
    padding-bottom: 60px;
  }
}

.mn-hashching-content-cnt {
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 90px;
}

@media all and (max-width: 1024px) {
  .mn-hashching-content-cnt {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.mn-socila-proof {
  padding: 60px 0;
  left: 0;
  min-height: 170px;
}

.mn-socila-proof .mn-brand-cnt .col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media all and (max-width: 1200px) {
  .mn-socila-proof .mn-brand-cnt .col {
    display: block;
  }
}

.mn-socila-proof .mn-brand-cnt .brands-img {
  width: 200px;
  margin-bottom: 20px;
}

.mn-socila-proof .mn-brand-cnt .brands-img-bigger {
  width: 300px;
  margin-bottom: 20px;
}

.mn-socila-proof .mn-brand-cnt .brands-img-small {
  width: 146px;
  margin-bottom: 20px;
}

.mn-socila-proof .mn-brand-cnt .center-block {
  display: table;
  margin: auto;
  margin-bottom: 10px;
}

.hashching-broker-view-offer-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ff5b09;
  padding: 15px 30px;
  color: white;
  border-radius: 4px;
  margin-left: -70px;
  width: 140px;
}

@media all and (max-width: 736px) {
  .hashching-broker-view-offer-btn {
    position: inherit;
    display: block;
    margin: auto;
  }
}

.hashching-btn-bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

.hashching-broker-live-text {
  position: absolute;
  top: 30%;
  left: 50%;
  text-align: center;
  margin-left: -260px;
  max-width: 500px;
  font-size: 50px;
  font-family: latoregular;
}

.hashching-down-form {
  margin-top: 90px;
  margin-bottom: 90px;
  height: 270px;
}

@media all and (max-width: 1024px) {
  .hashching-down-form {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.hashching-down-register {
  width: 100%;
  background-color: white;
  border-color: white;
  color: grey;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
}

.hashching-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.hashching-form-field .hashching-name {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
  margin-bottom: 10px;
  width: 192px;
  border: 1px solid #e8e8e8;
}

@media all and (max-width: 736px) {
  .hashching-form-field .hashching-name {
    width: 105px;
  }
}

@media all and (max-width: 320px) {
  .hashching-form-field .hashching-name {
    width: 193px;
    margin-right: 0;
  }
}

.hashching-form-field .hashching-email {
  padding: 10px;
  border-radius: 4px;
  /* margin-right: 20px; */
  font-weight: 400;
  height: 50px;
  border: none;
  margin-bottom: 10px;
  width: 192px;
  border: 1px solid #e8e8e8;
}

.hashching-btn {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 49px;
}

@media all and (max-width: 736px) {
  .hashching-btn {
    width: 324px;
  }
}

@media all and (max-width: 320px) {
  .hashching-btn {
    width: 193px;
  }
}

.hashching-view-offer-btn {
  width: 200px;
  animation: bounce 3s;
}

.hashching-button-style {
  background-color: #157B98;
  border: none;
  color: white;
}

.hashching-button-style:hover {
  background-color: #0f576b;
}
