/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
CAPITAL RAISINGS
---------------------------------------------------------- */
.capital-raisings-tabs {
  float: left;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  width: 100%;
}

.capital-raisings-tabs > li {
  float: left;
  width: 50%;
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

@media all and (max-width: 420px) {
  .capital-raisings-tabs > li {
    width: 100%;
  }
}

.capital-raisings-tabs > li:last-of-type {
  border: 0px;
}

.capital-raisings-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 0px 0px;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-decoration: none;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.capital-raisings-tabs > li > a:hover, .capital-raisings-tabs > li > a.active {
  background-color: #469edc;
}

@media all and (max-width: 730px) {
  .cr-leftcol {
    padding-top: 60px;
  }
}

@media all and (max-width: 420px) {
  .cr-leftcol {
    padding-top: 110px;
  }
}

.cr-tabs-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* Capital raisings tabs content ends here */
}

.cr-tabs-cont .cr-tabs-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 0px;
  background-color: #ffffff;
}

.cr-tabs-cont .cr-tabs-content .cr-statistics, .cr-tabs-cont .cr-tabs-content .cr-investments, .cr-tabs-cont .cr-tabs-content .cr-announcements {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

/* Capital raisings tabs container ends here */
.cr-tabs {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 600px) {
  .cr-tabs {
    background-color: #8f9ea9;
  }
}

.cr-tabs > li {
  float: left;
  width: 33.3%;
  min-height: 40px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

.cr-tabs > li:last-of-type {
  border: 0px;
}

@media all and (max-width: 600px) {
  .cr-tabs > li {
    width: 50%;
    border-style: solid;
    border-color: #7c8b97;
    border-bottom-width: 1px;
  }
  .cr-tabs > li:last-of-type {
    border-style: solid;
    border-color: #7c8b97;
    border-right-width: 1px;
  }
}

.cr-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 11px 0px 0px 0px;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #8f9ea9;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cr-tabs > li > a:hover, .cr-tabs > li > a.active {
  background-color: #469edc;
}

/* User profile tabs ends here */
.cr-table-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.cr-investments {
  display: none;
}

.cr-announcements {
  display: none;
}
