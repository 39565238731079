@import 'extensions/mixins';

.unknown-address {
    display: inline-block;
}

.unknown-address, .search-address {
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    color: #7e7e7e;
    &:hover {
        cursor: pointer;
    }
}

.address-wrapper, .search-address {
    display: none;
}

ul.addResults {
    position: absolute;
    z-index: 5;
    background-color: white;
    top: 51px;

    > li {
        padding: 10px;
        font-size: 15px;
        border: 1px solid #dfe6eb;
        border-top: none;
        top: 51px;

        &:hover {
            cursor: pointer;
            background-color: #e4ecf1;
        }
    }
}

@media (min-width: 670px) {
    ul.addResults {
        > li {
            width: 338px;
        }
    }
}

.add_new_wbi {
    font-size: 14px !important;
}

#company_id {
    font-size: 14px !important;
}

@media (max-width: 321px) {
    .ui-autocomplete {
        width: 230px !important;
        font-size: 11px !important;
    }

    .company-autocomplete > li > a.ui-corner-all > button {
        background-color: #469edc;
        border: 0 none;
        color: #fff;
        font-family: "latoregular";
        font-size: 12px;
        cursor: pointer;
        display: inline-block;
    }
}

.address-wrapper {
  display: none;
}

// Is this outputted?

input[readonly="readonly"], input[disabled="disabled"], textarea[readonly="readonly"], textarea[disabled="disabled"] {
    background-color: #f3f5f6 !important;
    border: none !important;
}

.remodal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ipo-fsg-prospectus{
    .notice{
        font-weight: bold;
        padding-bottom: 20px;
        text-align: center;
    }
}

.api-investment-errors {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .exclamation-mark {
        height: 55px;
        width: 55px;
        margin-bottom: 10px;
    }

    .modal-heading {
        color: #2b2e38;
        font-size: 22px;
        font-family: 'latoregular';
        margin-bottom: 22px;
    }

    @media (max-width: 321px) {
        padding: 25px;

        .exclamation-mark, .modal-heading {
            display: none;
        }
    }

    .my-modal-container {
        display: flex;
        flex-direction: column;
        box-shadow: none;

        .my-modal-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 35px;
            max-width: 650px;

            .icon {
                width: auto !important;
            }

            p.modal-p {
                font-size: 16px;
                line-height: 24px;
                // Could be a better solution to this
                // instead of padding-left
                margin-left: 15px;
                display: inline-block;
                width: initial;
                padding-bottom: 0;
                float: none;
            }
        }

        a.modal-link {
            color: #71baed;
        }
    }

    button.close {
        border-radius: 3px;
        padding: 10px 20px;
        margin: 10px;
    }

    button.cancel {
        border-radius: 3px;
        padding: 10px 20px;
        background-color: #f0ad4e;
        margin: 10px;
    }
}

.up-bio-maintext:empty:after {
    color: #7e7e7e;
    font-family: "latolight";
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 32px;
}

.img-responsive img {
    width: 100%;
}

.submitting-modal {
    background-color: white;
    border-radius: 5px;

    .partner-subscribe-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        padding-bottom: 0;

        h2 {
            font-size: 30px;
            margin-bottom: 45px;
            text-align: center;
        }

        .exclamation-mark {
            text-align: center;
            width: 60px;
            margin-bottom: 30px;
        }

        button {
            border-radius: 3px;
        }
    }

    .submitting-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;

        h2 {
            font-size: 30px;
            margin-bottom: 45px;
        }
    }

    #circleG{
        width: 140px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:auto;
    }

    .circleG{
        background-color:rgb(91,169,224);
        // float:left;
        height:30px;
        // margin-left:19px;
        width:30px;
        animation-name:bounce_circleG;
            -o-animation-name:bounce_circleG;
            -ms-animation-name:bounce_circleG;
            -webkit-animation-name:bounce_circleG;
            -moz-animation-name:bounce_circleG;
        animation-duration:2.24s;
            -o-animation-duration:2.24s;
            -ms-animation-duration:2.24s;
            -webkit-animation-duration:2.24s;
            -moz-animation-duration:2.24s;
        animation-iteration-count:infinite;
            -o-animation-iteration-count:infinite;
            -ms-animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite;
            -moz-animation-iteration-count:infinite;
        animation-direction:normal;
            -o-animation-direction:normal;
            -ms-animation-direction:normal;
            -webkit-animation-direction:normal;
            -moz-animation-direction:normal;
        border-radius:24px;
            -o-border-radius:24px;
            -ms-border-radius:24px;
            -webkit-border-radius:24px;
            -moz-border-radius:24px;
    }

    #circleG_1{
        margin-left: 0;
        animation-delay:0.45s;
            -o-animation-delay:0.45s;
            -ms-animation-delay:0.45s;
            -webkit-animation-delay:0.45s;
            -moz-animation-delay:0.45s;
    }

    #circleG_2{
        animation-delay:1.05s;
            -o-animation-delay:1.05s;
            -ms-animation-delay:1.05s;
            -webkit-animation-delay:1.05s;
            -moz-animation-delay:1.05s;
    }

    #circleG_3{
        margin-right: right;
        animation-delay:1.35s;
            -o-animation-delay:1.35s;
            -ms-animation-delay:1.35s;
            -webkit-animation-delay:1.35s;
            -moz-animation-delay:1.35s;
    }



    @keyframes bounce_circleG{
        0%{}

        50%{
            background-color:rgb(255,255,255);
        }

        100%{}
    }

    @-o-keyframes bounce_circleG{
        0%{}

        50%{
            background-color:rgb(255,255,255);
        }

        100%{}
    }

    @-ms-keyframes bounce_circleG{
        0%{}

        50%{
            background-color:rgb(255,255,255);
        }

        100%{}
    }

    @-webkit-keyframes bounce_circleG{
        0%{}

        50%{
            background-color:rgb(255,255,255);
        }

        100%{}
    }

    @-moz-keyframes bounce_circleG{
        0%{}

        50%{
            background-color:rgb(255,255,255);
        }

        100%{}
    }
}

a.completedNoGo:hover {
    cursor: default !important;
}

#agreement-text {
    h2 {
        margin-bottom: 10px;
        font-size: 18px;
    }
}

#accept-terms-direct-debits {
  padding-left: 20px;
  padding-right: 20px;
    ol {
        margin-left: 20px;
        list-style-type: lower-alpha;

        p {
            margin-bottom: 5px;
            line-height: 16px;
        }

        li {
            margin-bottom: 10px;
            line-height: 16px;

            ol.inner-list {
                margin-top: 5px;
                list-style-type: lower-roman;
            }
        }
    }

}
strong {
    font-family: 'latoregular';
}

#agreement-text {
    p {
        margin-bottom: 10px;
    }

    h3 {
        font-family: 'latoregular';
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.warning-static-ul {
    margin-bottom: 10px;

    li {
        margin-bottom: 10px;
    }
}

#warning-text {
    p, h3 {
        margin-bottom: 10px;
    }

    h3 {
        font-family: 'latoregular';
        font-size: 18px;
    }
}

a.invest-link {
    font-size: 14px;
    font-family: 'latolight';
    vertical-align: middle;
}

.reg-log-leftcol > .default-warning-tooltip {
    padding: 20px 20px 20px 60px;
    background-position: 20px 17px;
}

#invest-text {
    padding-bottom: 60px;

    h1 {
        margin: 0px;
        padding: 0px 0px 20px 0px;
        font-family: 'latolight';
        font-size: 26px;
        color: #7e7e7e;
        position: relative;
        text-transform: uppercase;

        &:after {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 85px;
            height: 3px;
            background-color: #469edc;
            content: "";
        }
    }

    h2 {
        margin-top: 50px;
        font-family: "latolight";
        font-size: 24px;
        color: #7c7c7c;
    }

    h3 {
        margin-top: 30px;
        font-family: "latolight";
        font-size: 20px;
        color: #7e7e7e;
    }

    p {
        margin-top: 10px;
        font-size: 16px;
        line-height: 24px;
    }

    li {
        margin-top: 5px;
        font-size: 16px;
        line-height: 24px;
    }
}

.center {
    text-align: center;
    display: block;
}

.prospectus-info {
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;

    p {
        padding: 20px;
        color: #7e7e7e;
        font-family: "latolight";
        font-size: 16px;
        line-height: 24px;
    }

    a {
        background-color: #68cfad;
        color: white;
        font-family: "latoregular";
        padding: 15px;
        width: 250px;
        display: block;
        margin: 0 auto;
        @include mquery( 414px) {
          width: 100%;
        }
    }
}

a:hover {
    text-decoration: none;
}

.modal {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    @include mquery(736px) {
      margin-top: 200px;
    }
}

#mySophisticatedModal2, #mySophisticatedModal0 {
    .modal-body {
        text-align: center;
        padding: 20px;

        .glyphicon.glyphicon-thumbs-up {
            font-size: 38px;
            color: #469edc;
            margin: 15px;
        }

        .glyphicon.glyphicon-exclamation-sign {
            font-size: 38px;
            color: #FF7F00;
            margin: 15px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            font-family: 'latoregular';
            margin-top: 20px;
        }

        button {
            margin: 10px;
            margin-top: 60px;
            padding: 10px;
            min-width: 140px;
            font-size: 16px;
            font-family: 'latolight';
            background: none;
            border: 1px solid #469edc;
            color: #469edc;
            border-radius: 2px;
            transition: background 0.3s ease;

            &:hover {
                cursor: pointer;
                background: #469edc;
                color: #fff;
            }
        }
    }
}

.syndicates-buttons-container {
    text-align: center;
}

#number-swapper {
    .od-mc-td-amount-invested {
        font-size: 40px;
        color: #68cfad;
        float: right;
        font-family: 'latoregular';
        @include mquery(1023px) {
            font-size: 25px;
        }
    }

    .underarm-investment {
        display: none;
        font-size: 40px;
        color: #469EDC;
        float: right;
        font-family: 'latoregular';
        @include mquery(1023px) {
            font-size: 25px;
        }
    }
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont p.private-p {
    margin-top: 10px;
}

.p-underwrite-remaining {
    display: none;
}
