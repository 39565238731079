/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#chmpagen-logo-top-banner {
  margin: 0;
  padding: 33.33333px 0;
  background-color: #0b253b;
  color: #a7925b;
  display: flex;
  align-items: center;
  justify-content: center;
}

#chmpagen-logo-top-banner .champagne-logo {
  width: 400px;
}

@media all and (max-width: 768px) {
  #chmpagen-logo-top-banner .champagne-logo {
    width: 200px;
  }
}

#champagne-hero-video {
  position: relative;
  height: calc(100vh - 207.66px);
  width: 100%;
  overflow: hidden;
  display: table;
}

#champagne-hero-video .champagne-video {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.77777778vh;
}

#champagne-hero-video .champagne-hero-video-cnt {
  position: relative;
  display: table;
  width: 100%;
  height: calc(100vh - 207.66px);
  padding: 80px 0;
  background-color: rgba(11, 37, 59, 0.7);
}

#champagne-hero-top-banner {
  background-image: url(/images/landing-pages/banners/champagne-Napoleon.jpg);
  width: 100%;
  background-color: #0b253b;
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 110px 0;
}

@media all and (max-width: 1024px) {
  #champagne-hero-top-banner {
    padding: 60px 0;
  }
}

@media all and (max-width: 767px) {
  #champagne-hero-top-banner {
    background-image: url(/images/landing-pages/banners/champagne-Napoleon-mobile.jpg);
  }
}

#champagne-nepoleon-quote {
  padding: 60px 0;
}

#champagne-nepoleon-quote .champagne-nepoleon-quote-header {
  font-size: 40px;
  letter-spacing: -0.91px;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  #champagne-nepoleon-quote .champagne-nepoleon-quote-header {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #champagne-nepoleon-quote .champagne-nepoleon-quote-header {
    font-size: 26px;
  }
}

#champagne-nepoleon-quote .champagne-nepoleon-quote-subheader {
  font-size: 20px;
  letter-spacing: -0.25px;
  line-height: 1.2;
}

@media all and (max-width: 768px) {
  #champagne-nepoleon-quote .champagne-nepoleon-quote-subheader {
    font-size: 17px;
  }
}

#champagne-intro-sec {
  padding: 30px 0 60px;
}

@media all and (max-width: 768px) {
  #champagne-intro-sec {
    padding: 0 0 60px;
  }
}

.champagne-content {
  width: 100%;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media all and (max-width: 1200px) {
  .champagne-content {
    text-align: center;
  }
}

#champagne-stats-sec {
  padding: 30px 0 60px;
}

@media all and (max-width: 768px) {
  #champagne-stats-sec {
    padding: 0 0 60px;
  }
}

#champagne-stats-sec .champagne-stats-cnt {
  border-radius: 8px;
  background-color: #0b253b;
  padding: 80px;
}

#champagne-stats-sec .champagne-stats-cnt .champagne-stats-circle {
  width: 120px;
  margin: 0 auto 20px;
  height: 120px;
  border-radius: 100%;
}

#champagne-stats-sec .champagne-stats-cnt .champagne-stats-text {
  color: #fff;
  line-height: 1.3;
}

@media all and (max-width: 767px) {
  #champagne-stats-sec .champagne-stats-cnt .champagne-stats-text {
    margin-bottom: 40px;
  }
}

#champagne-stats-sec .champagne-stats-cnt .champagne-stats-img {
  width: 120px;
}

#champagne-story-sec {
  padding: 30px 0 60px;
}

@media all and (max-width: 1200px) {
  #champagne-story-sec {
    padding: 60px 0;
  }
}

@media all and (max-width: 768px) {
  #champagne-story-sec {
    padding: 0 0 60px;
  }
}

@media all and (max-width: 1200px) {
  #champagne-story-sec .champagne-story-sec-text {
    margin-bottom: 60px !important;
  }
}

#champagne-story-sec .champagne-story-sec-img {
  margin: 0 0 40px;
}

#champagne-story-sec .champagne-story-sec-img {
  margin-bottom: 40px;
}

#champagne-quote-sec {
  padding: 60px 0;
  background-color: #0b253b;
}

#champagne-quote-sec .champagne-quote-header {
  max-width: 1016px;
  margin: auto;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
}

@media all and (max-width: 768px) {
  #champagne-quote-sec .champagne-quote-header {
    text-align: center;
  }
}

@media all and (max-width: 767px) {
  #champagne-quote-sec .champagne-quote-header {
    font-size: 24px;
  }
}

#champagne-quote-sec .champagne-quote-subheader {
  margin: auto;
  font-size: 20px;
  line-height: 1.2;
}

@media all and (max-width: 768px) {
  #champagne-quote-sec .champagne-quote-subheader {
    text-align: center;
  }
}

@media all and (max-width: 767px) {
  #champagne-quote-sec .champagne-quote-subheader {
    font-size: 14px;
  }
}

#champagne-quote-sec .champagne-quote-img {
  border: 1px solid #a7925b;
  padding: 20px;
}

#champagne-footnote {
  background-color: #0b253b;
  color: #fff;
  font-size: 14px;
  padding: 20px;
}

@media all and (max-width: 1200px) {
  #champagne-footnote {
    padding: 0 0 60px;
  }
}

@media all and (max-width: 990px) {
  #champagne-footnote .champagne-footnote-p {
    text-align: center;
    font-size: 14px;
  }
}

#champagne-partners-sec {
  background-image: url(/images/landing-pages/banners/champagne-newyear1.jpg);
  background-repeat: no-repeat;
  background-color: white;
  background-size: cover;
  background-position: center;
}

@media all and (max-width: 767px) {
  #champagne-partners-sec {
    background-image: url(/images/landing-pages/banners/champagne-newyear1-sm.jpg);
  }
}

#champagne-partners-sec .champagne-partners-sec-layer {
  padding: 120px 0;
  background-color: #fff;
  opacity: .8;
}

@media all and (max-width: 1200px) {
  #champagne-partners-sec .champagne-partners-sec-layer {
    padding: 60px 0;
  }
}

#champagne-revenue-sec {
  padding: 60px 0;
}

#champagne-revenue-sec .champagne-revenue-sec-layer {
  padding: 120px 0;
  background-color: #fff;
  opacity: .8;
}

@media all and (max-width: 1200px) {
  #champagne-revenue-sec .champagne-revenue-sec-layer {
    padding: 60px 0;
  }
}

#champagne-revenue-sec .champagne-revenue-img {
  margin-top: 40px;
}

#champagne-last-sec {
  padding: 120px 0;
}

@media all and (max-width: 768px) {
  #champagne-last-sec {
    padding: 60px 0;
  }
}

#champagne-last-sec .champagne-last-sec-text {
  max-width: 1016px;
  margin: auto;
  color: #a7925b;
  font-size: 40px;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: latobold;
  text-align: center;
}

@media all and (max-width: 768px) {
  #champagne-last-sec .champagne-last-sec-text {
    font-size: 30px;
  }
}

@media all and (max-width: 600px) {
  #champagne-last-sec .champagne-last-sec-text {
    font-size: 26px;
  }
}

.champagne-link {
  color: #a7925b;
  text-decoration: underline;
  transition: all 300ms ease-out;
}

.champagne-link:hover {
  color: #a7925b;
}

.champagne-button-style {
  color: #fff;
  background-color: #a7925b;
  border: 1px solid #a7925b;
  transition: all 300ms ease-out;
}

.champagne-button-style:hover {
  background-color: #736748;
  border-color: #736748;
  color: #fff;
  cursor: pointer;
}

.champagne-navy {
  color: #0b253b;
}

.champagne-gold {
  color: #a7925b;
}

#champagne-paralax-sec {
  background-attachment: fixed;
  background-image: url("/images/landing-pages/banners/champagne-background-grid.svg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
  background-color: #0b253b;
}

@media all and (max-width: 600px) {
  #champagne-paralax-sec {
    background-image: url("/images/landing-pages/banners/champagne-background-grid.svg");
  }
}

@media all and (max-width: 768px) {
  #champagne-paralax-sec {
    background-attachment: initial;
  }
}

#champagne-paralax-sec .champagne-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

@media all and (max-width: 1200px) {
  #champagne-paralax-sec .champagne-paralax-layer {
    padding: 60px 0;
  }
}
