/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#wse-top-banner {
  background: url(/images/landing-pages/wse-top-banner.jpg) no-repeat center center;
  background-size: cover;
  height: calc(100vh - 70px);
  width: 100%;
  position: relative;
  display: table;
}

#wse-top-banner .wse-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  background-color: rgba(1, 1, 1, 0.5);
}

@media all and (max-width: 1024px) {
  #wse-top-banner .wse-align-center {
    width: 100%;
  }
}

.wse-button-style {
  background-color: #3754bf;
  border: 1px solid #3754bf;
  color: #fff;
}

.wse-button-style:hover {
  opacity: .5;
}

.wse-link {
  color: #50d253;
}

.wse-link:hover {
  color: #d88713;
}

.lp-flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.lp-flex-row .lp-flex-col {
  width: 50%;
}

@media all and (max-width: 1200px) {
  .lp-flex-row .lp-flex-col {
    width: 100%;
  }
}

.wse-first-half-content {
  padding: 60px;
}

@media all and (max-width: 1500px) {
  .wse-first-half-content {
    padding: 20px 60px;
  }
}

@media all and (max-width: 1250px) {
  .wse-first-half-content {
    padding: 20px 40px;
  }
}

@media all and (max-width: 768px) {
  .wse-first-half-content {
    padding: 25px;
  }
}

.wse-first-half-img {
  background: url(/images/landing-pages/wse-second-banner.jpg) no-repeat center center;
  background-size: cover;
  height: 731px;
}

@media all and (max-width: 1200px) {
  .wse-first-half-img {
    width: 100%;
    display: none;
  }
}

.wse-section-three {
  padding: 20px 0;
}

@media all and (max-width: 1280px) {
  .wse-section-three {
    padding: 0;
  }
}

@media all and (max-width: 1200px) {
  .wse-section-three {
    height: auto;
    padding: 50px 0 0;
  }
}

@media all and (max-width: 768px) {
  .wse-section-three {
    height: auto;
    padding: 0;
  }
}

#wse-full-height-img {
  background: url(/images/landing-pages/wse-full-height-img.jpg) no-repeat center center;
  background-size: cover;
  height: calc(100vh);
}

.wse-second-half-img {
  background: url(/images/landing-pages/wse-third-banner.jpg) no-repeat center center;
  background-size: contain;
  height: 481px;
}

@media all and (max-width: 768px) {
  .wse-second-half-img {
    background-size: contain;
    height: 511px;
  }
}

@media all and (max-width: 600px) {
  .wse-second-half-img {
    background-size: contain;
    height: 251px;
  }
}

.wse-second-half-content {
  padding: 60px;
}

@media all and (max-width: 1350px) {
  .wse-second-half-content {
    padding: 50px;
  }
}

@media all and (max-width: 768px) {
  .wse-second-half-content {
    padding: 25px;
  }
}

.wse-bluegrey-back {
  background-color: #6a7c88;
}

#wse-paralax-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/wse-paralex-img.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

#wse-paralax-sec .wse-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 50px;
  padding-bottom: 50px;
}

#wse-last-table-sec {
  background-color: #fff;
  padding: 50px 0;
}

.wse-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #3754bf;
  border: 1px solid #3754bf;
  color: #fff;
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.wse-view-offer-btn:hover {
  opacity: .5;
}
