/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#agunity-last-form-sec .agunity-last-form-sec-1, #agunity-last-form-sec .agunity-last-form-sec-3 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#agunity-last-form-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #agunity-last-form-sec {
    flex-wrap: wrap;
  }
}

#agunity-last-form-sec .agunity-last-form-sec-1 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/agunity/form-1.png");
}

@media all and (max-width: 1024px) {
  #agunity-last-form-sec .agunity-last-form-sec-1 {
    width: 50%;
  }
}

@media all and (max-width: 500px) {
  #agunity-last-form-sec .agunity-last-form-sec-1 {
    height: 180px;
  }
}

#agunity-last-form-sec .agunity-last-form-sec-2 {
  background-color: 0, 0, 0, 0.65;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 400px;
  width: 32%;
  padding: 10px;
}

@media all and (max-width: 1420px) {
  #agunity-last-form-sec .agunity-last-form-sec-2 {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  #agunity-last-form-sec .agunity-last-form-sec-2 {
    width: 100%;
    order: 3;
  }
}

#agunity-last-form-sec .agunity-last-form-sec-3 {
  background-color: #000;
  height: 400px;
  width: 34%;
  background-image: url("/images/landing-pages/agunity/form-2.png");
}

@media all and (max-width: 1024px) {
  #agunity-last-form-sec .agunity-last-form-sec-3 {
    width: 50%;
    order: 1;
  }
}

@media all and (max-width: 500px) {
  #agunity-last-form-sec .agunity-last-form-sec-3 {
    height: 180px;
  }
}
