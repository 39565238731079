@import 'extensions/variables';
@import 'extensions/mixins';


.hop-top-banner-ctn {
  padding-top: 80px;
  position: relative;
  background-color: #fff;
  @include mquery(1280px) {
    padding-top: 46px;
    padding-bottom: 80px;
  }
  @include mquery(1024px) {
    padding-bottom: 0;
  }

  @include mquery(736px) {
    padding-top: 19px;
  }
  .hop-layer {
    position: absolute;
    background-color: rgba(1,1,1,.3);
    height: 750px;
    top: 0;
    width: 100%;
    @include mquery(736px) {
      height: 830px;
    }
  }

  .vertical-align {
    display: flex;
    align-items: center;
  }
    .hop-logo {
      margin: 16px auto 50px;
      @include mquery(736px) {
         margin: 30px auto;
      }

    }

    .hop-red-bk-color {
      background-color: #e74444;
      border: none;

    }

    ::placeholder {
      color: #fff;
    }


  }

  .iphone-slider {
    width: 344px;
    // height: 714px;
    padding: 78px 21px 103px 21px;
    background-image: url(/images/top-banner-iphone.png);
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    @include mquery(1280px) {
      width: 284px;
      padding: 64px 17px 103px 17px;
    }
    }


.hop-top-banner-ctn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 750px;
  background-image: url(/images/hop-top-banner-min-min.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include mquery(736px) {
    height: 830px
  }
}


    .hop-red-bk-color:hover {
      background-color: lighten(#e74444, 9%);
      transition: 3s;
    }

    .hop-red-color {
      color: #e74444;
    }

    .hop-red-color:hover {
      text-decoration: underline;
    }


.hop-second-section {
  background-color: #fff;
  padding: 67px 0 110px;
}
