@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
FAQ PAGE
---------------------------------------------------------- */
.faq-question{
    float: left;
    width: 100%;
    margin: 50px 0px 0px 0px;
    padding: 0px 0px 0px 40px;
    font-family: 'latolight';
    font-size: 24px;
    color: #3f3e3e;
    position: relative;
    line-height: 150%;
    &:before {
        position: absolute;
        top: 3px;
        left: 0px;
        content: "Q: ";
        font-family: 'latobold';
        font-size: 20px;
        color: #3f3e3e;
        line-height: 150%;
    }
}
.faq-answer{
    float: left;
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 4px 0px 0px 40px;
    font-family: 'latolight';
    font-size: 16px;
    color: $default-text-color;
    position: relative;
    line-height: 150%;
    &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "A: ";
        font-family: 'latobold';
        font-size: 20px;
        color: #3f3e3e;
        line-height: 150%;
    }
}
.faq-search {
    float: left;
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding: 10px 60px 10px 10px;
    background-color: #ffffff;
    position: relative;
}
.faq-search > input[type="text"] {
    float: left;
    width: 100%;
    height: 42px;
    margin: 0px;
    padding: 0px 10px;
    border: 1px solid #ededed;
    font-family: 'latobold';
    font-size: 14px;
    color: #7e7e7e;
}
.faq-search > input[type="submit"] {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 42px;
    height: 42px;
    background-color: $default-blue-color;
    border: 0px;
    text-indent: -99999px;
    cursor: pointer;
    @include transition(.3s);
    background-image: url(../../images/faq-search-icon.png);
    background-position: center center;
    background-repeat: no-repeat;
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
}