@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';


#sofispritz-last-form-sec {
    display: flex;
    flex-direction: row;
    @include mquery(1024px) {
      flex-wrap: wrap;
    }
  .sofispritz-last-form-sec-1 {
    @include lastFormSecOneImg(#000, '/images/landing-pages/sofi/ss-5.jpg');
    @extend %background-property;
    background-size: cover;
  }

  .sofispritz-last-form-sec-2 {
    @extend %flex-center;
    height: 400px;
    width: 32%;
    padding: 10px;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
    }
  }
  .sofispritz-last-form-sec-3 {
    @include lastFormSecThreeImg(#000, '/images/landing-pages/sofi/ss-4.jpg');
    @extend %background-property;
  }
}