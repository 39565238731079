@import 'extensions/variables';
@import 'extensions/mixins';

.mn-cor-title {
  margin: 0px;
  padding: 30px 0px 20px 0px;
  font-family: 'latolight';
  font-size: 26px;
  color: #7e7e7e;
  position: relative;
  text-transform: uppercase;
}

.mn-sm {
  font-size: 22px;
}

.mn-cor-p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
}
