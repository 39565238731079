/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
OFFER DETAILS
---------------------------------------------------------- */
.od-project-name-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 60px;
  background-color: #313131;
  z-index: 1005;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.od-project-name-header.od-pnh-visible {
  visibility: visible;
  opacity: 1;
}

.od-project-name-header > div {
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  padding: 0px;
  min-height: 60px;
  position: relative;
}

.od-project-name-header > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 17px 240px 3px 55px;
  font-family: 'latobold';
  font-size: 22px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media all and (max-width: 768px) {
  .od-project-name-header > div > span {
    padding: 21px 130px 3px 55px;
  }
}

@media all and (max-width: 550px) {
  .od-project-name-header > div > span {
    font-size: 16px;
  }
}

.od-project-name-header > div > a {
  background-color: #aad155;
  background-image: url("../../images/invest-btn-icon.png");
  background-position: right 15px center;
  background-repeat: no-repeat;
  border: 0 none;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0px;
  font-family: "latoblack";
  font-size: 14px;
  margin: 0;
  padding: 13px 45px 15px 15px;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 220px;
  height: 40px;
}

.od-project-name-header > div > a:before {
  content: "Invest in this offer";
}

@media all and (max-width: 768px) {
  .od-project-name-header > div > a {
    width: 110px;
  }
  .od-project-name-header > div > a:before {
    content: "Invest";
  }
}

.od-project-name-header > div > a:hover {
  background-color: #b4d669;
}

@media all and (max-width: 1240px) {
  .od-project-name-header > div > a {
    right: 10px;
  }
}

.od-pnh-share-cont {
  position: absolute;
  top: 10px;
  left: 0px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 1240px) {
  .od-pnh-share-cont {
    left: 10px;
  }
}

.od-pnh-share-cont > a {
  float: left;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  border-radius: 20px;
  background-color: #68cfad;
  background-image: url(../../images/offer-details-share-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  z-index: 2;
}

.od-pnh-share-cont > a:hover {
  background-color: #55c9a2;
}

.od-pnh-share-cont > div {
  float: left;
  margin: 0px;
  padding: 8px 0px 0px 0px;
  height: 40px;
  border-radius: 0px 20px 20px 0px;
  background-color: #68cfad;
  position: relative;
  left: -20px;
  z-index: 1;
  width: 0;
  padding-left: 0;
  overflow: hidden;
}

.od-pnh-share-cont > div > a {
  float: left;
  margin: 0px 10px 10px 0px;
  padding: 0px;
}

.od-pnh-share-cont > div > a > img {
  float: left;
  margin: 0px;
  padding: 0px;
}

.od-ts-share-cont {
  right: 15px;
  top: -70px;
  left: auto;
}

.od-ts-share-cont > a {
  float: right;
}

.od-ts-share-cont > div {
  border-radius: 20px 0px 0px 20px;
  left: 20px;
}

#od-key-statistics {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.offer-details-top-section {
  float: left;
  width: 100%;
  min-height: 440px;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-image: url("../../images/offer-details-ts-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media all and (max-width: 1240px) {
  .offer-details-top-section {
    padding: 0px 10px;
  }
}

.offer-details-top-section:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.offer-details-top-section .od-ts-inner-cont {
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  padding: 0px;
  position: relative;
  /* Offer Details info container ends here */
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont {
  float: left;
  width: 100%;
  margin: 95px 0px 0px 0px;
  padding: 0px 0px 0px 220px;
  position: relative;
  text-align: center;
  /* Offer Details logo container ends here */
  /* Offer Details info details ends here */
}

@media all and (max-width: 1240px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont {
    padding: 0px 0px 150px 180px;
  }
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont {
    padding: 0px 0px 150px 0px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-logo {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-logo {
    position: relative;
    top: auto;
    left: auto;
    display: inline-block;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-logo > img {
  float: left;
  width: 195px;
  height: 195px;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 1240px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-logo > img {
    width: 160px;
    height: 160px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  /* Order Details top section buttons */
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 18px;
  color: #ffffff;
  line-height: 1.5;
}

@media all and (max-width: 1240px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > p {
    font-size: 15px;
  }
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > p {
    text-align: center;
    margin-top: 10px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span {
    text-align: center;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span.od-ts-id-title {
  font-size: 35px;
  font-family: 'latobold';
}

@media all and (max-width: 1240px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span.od-ts-id-title {
    font-size: 30px;
    margin-top: 10px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span.od-ts-id-subtitle {
  font-family: 'latolight';
  font-size: 18px;
  line-height: 1.5;
}

@media all and (max-width: 1240px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > span.od-ts-id-subtitle {
    font-size: 15px;
    margin-top: 10px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  text-align: center;
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div > a {
  float: left;
  margin: 0px 10px 10px 0px;
  padding: 0px;
  text-decoration: none;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 35px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div > a:hover {
  color: #469edc;
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div > a.od-id-follow {
  width: 125px;
  height: 36px;
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  padding: 8px 10px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 3px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-image: url("../../images/od-id-follow-arrow.png");
  background-position: right 15px center;
  border-radius: 20px;
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div > a.od-id-follow:hover {
  background-position: right 10px center;
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > div > a {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  text-align: center;
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul {
    margin-top: 15px;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul > li {
  float: left;
  margin: 10px 10px 0px 0px;
  padding: 0px;
  list-style: none;
}

@media all and (max-width: 580px) {
  .offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul > li {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul > li > span {
  float: left;
  height: 26px;
  margin: 0px;
  padding: 6px 10px 6px 10px;
  background-color: rgba(0, 0, 0, 0.25);
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  position: relative;
}

.offer-details-top-section .od-ts-inner-cont .od-ts-info-cont .od-ts-info-details > ul > li > span:before {
  content: none;
}

/* Offer Details top section ends here */
.manrags-offer-image {
  background-image: url(/images/manrags-offers-image.jpg);
}

@media all and (max-width: 768px) {
  .manrags-offer-image {
    background-image: url(/images/manrags-offer-sm-min.jpg);
  }
}

.offer-details-main-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 1240px) {
  .offer-details-main-content {
    padding: 0px 10px;
  }
}

.offer-details-main-content .od-mc-inner-cont {
  position: relative;
  top: -65px;
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  padding: 0px;
  background-color: #ffffff;
  clear: both;
  z-index: 10;
  /* Offer details main content top section ends here */
}

.offer-details-main-content .od-mc-inner-cont .offer-details-status-wrapper {
  position: absolute;
  top: -74px;
  left: 0px;
  width: 100%;
  margin: 0px;
  padding: 0px 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
  -webkit-animation: odStatusAnimation 1.5s forwards;
  /* Safari 4+ */
  -moz-animation: odStatusAnimation 1.5s forwards;
  /* Fx 5+ */
  -o-animation: odStatusAnimation 1.5s forwards;
  /* Opera 12+ */
  animation: odStatusAnimation 1.5s forwards;
}

.offer-details-main-content .od-mc-inner-cont .offer-details-status-wrapper > div {
  float: left;
  width: 100%;
  min-height: 56px;
  margin: 0px;
  padding: 0px 0px 0px 50px;
  border-radius: 5px 5px 0px 0px;
  background-color: #469edc;
  background-image: url("../../images/overfunding-status-icon.png");
  background-repeat: no-repeat;
  background-position: 15px center;
}

.offer-details-main-content .od-mc-inner-cont .offer-details-status-wrapper > div .offer-details-status {
  float: left;
  margin: 0px;
  padding: 13px 0px 0px;
  font-size: 20px;
  font-family: 'latoblack';
  color: #fff;
}

.offer-details-main-content .od-mc-inner-cont .offer-details-status-wrapper > div > p {
  float: right;
  margin: 0px;
  padding: 16px 15px;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top {
  float: left;
  width: 100%;
  margin: 0px;
  position: relative;
  z-index: 2;
  min-height: 506px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top > div {
  flex: 1;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top {
    display: block;
    padding: 30px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video {
  float: left;
  width: auto;
  margin: 0px;
  padding: 0px;
  background-color: #000000;
  text-align: center;
  display: block;
  height: 508px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div {
  width: auto;
  display: block;
  vertical-align: middle;
  position: relative;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div iframe, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div object, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div embed {
  height: 508px;
  width: 800px;
}

@media all and (max-width: 1220px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div iframe, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div object, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div embed {
    width: 600px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video > div > img {
  max-height: 100%;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video {
    height: auto;
    width: 100%;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video iframe, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video object, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video embed {
    width: 100% !important;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video img {
    width: 100%;
    max-height: unset !important;
  }
}

@media all and (max-width: 600px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video iframe, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video object, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video embed {
    height: 300px !important;
  }
}

@media all and (max-width: 450px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video iframe, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video object, .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-video embed {
    height: 200px !important;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details {
  box-sizing: border-box;
  margin: 0px;
  padding: 30px;
  max-width: 400px;
  flex: 1 0 400px;
  /* Top details list ends here */
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details {
    max-width: 100%;
    float: left;
    position: relative;
    right: 0px;
    padding: 0px;
    top: 0px;
    margin-top: 30px;
    text-align: center;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style: none;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li.bar-li > p {
  margin-top: 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:first-of-type {
  margin-top: 0px;
}

@media all and (max-width: 950px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:first-of-type {
    padding: 8px 12px;
  }
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li {
    width: 16.5%;
    padding: 0px 15px;
    margin-top: 0px;
    min-height: 60px;
    border-style: solid;
    border-color: #ededed;
    border-right-width: 1px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:last-of-type {
    border-right-width: 0px;
  }
}

@media all and (max-width: 950px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li {
    width: 33.3%;
    padding: 12px 15px;
    border-style: solid;
    border-color: #ededed;
    border-bottom-width: 1px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(3n+3) {
    border-right-width: 0px;
  }
}

@media all and (max-width: 950px) and (max-width: 690px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(3n+3) {
    border-right-width: 1px;
  }
}

@media all and (max-width: 950px) and (max-width: 480px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(3n+3) {
    border-right-width: 0px;
  }
}

@media all and (max-width: 950px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(1n+4) {
    border-bottom-width: 0px;
  }
}

@media all and (max-width: 950px) and (max-width: 690px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(1n+4) {
    border-bottom-width: 1px;
  }
}

@media all and (max-width: 690px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li {
    width: 50%;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(1n+5) {
    border-bottom-width: 0px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(2n+2) {
    border-right-width: 0px;
  }
}

@media all and (max-width: 480px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li {
    width: 100%;
    border-right-width: 0px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li:nth-child(1n+5) {
    border-bottom-width: 1px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoblack';
  font-size: 18px;
  color: #7e7e7e;
  text-align: right;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > span {
    font-size: 13px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > span.od-mc-td-amount-invested {
  font-size: 40px;
  color: #68cfad;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > span.od-mc-td-amount-invested {
    font-size: 25px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > p {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 18px;
  color: #b3b3b3;
  text-align: right;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li > p {
    font-size: 13px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li .od-mc-td-progresbar {
  float: left;
  width: 100%;
  height: 10px;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  background-color: #eff1eb;
  overflow: hidden;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details > ul > li .od-mc-td-progresbar > div {
  float: left;
  height: 30px;
  margin: 0px;
  padding: 0px;
  background: #68cfad;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .invest-offer-button {
  float: right;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 15px 70px 15px 15px;
  font-family: 'latoblack';
  color: #ffffff;
  background-color: #68cfad;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-image: url("../../images/invest-btn-icon.png");
  background-repeat: no-repeat;
  background-position: right 15px center;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .invest-offer-button:hover {
  background-color: #55c9a2;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .invest-offer-button.large {
  height: 52px;
  font-size: 14px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .invest-offer-button.funding-complete-inactive {
  background-color: grey;
  cursor: default;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .invest-offer-button {
    max-width: 320px;
    float: none;
    display: inline-block;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus {
  float: left;
  color: #7e7e7e;
  font-family: "latolight";
  font-size: 15px;
  margin: 5px 0 0;
  padding: 0;
  width: 100%;
  text-align: right;
}

@media all and (max-width: 1023px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus {
    text-align: center;
    margin: 15px 0 0;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus input[type=checkbox].default-checkbox + label.default-checkbox-label {
  padding-left: 30px;
  height: 20px;
  line-height: 20px;
  font-family: "latolight";
  font-size: 14px;
  /* Media queries */
}

@media all and (max-width: 479px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus input[type=checkbox].default-checkbox + label.default-checkbox-label {
    line-height: 16px;
  }
}

@media all and (max-width: 330px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus input[type=checkbox].default-checkbox + label.default-checkbox-label {
    font-size: 12px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus input[type=checkbox].default-checkbox:checked + label.default-checkbox-label {
  background-position: 0 -20px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus label.default-checkbox-label {
  background-image: url("../../../images/checkbox-sprite-small.png");
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus label.default-checkbox-label > a {
  text-decoration: none;
  color: #71baed;
  font-family: 'latoregular';
}

.offer-details-main-content .od-mc-inner-cont .od-mc-top .od-mc-top-details .check-prospectus label.default-checkbox-label > a:hover {
  text-decoration: underline;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation {
  position: relative;
  z-index: 100;
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* Offer details main tabs ends here */
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation > div {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px 0px 1px 0px;
  padding: 0px;
  background-color: #469edc;
  display: none;
}

@media all and (max-width: 580px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation > div {
    display: inline-block;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation > div > span {
  float: left;
  margin: 0px;
  padding: 13px 15px;
  font-family: 'latoblack';
  font-size: 13px;
  color: #ffffff;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation > div .od-mc-main-tabs-menu {
  float: right;
  width: 22px;
  height: 18px;
  margin: 11px;
  padding: 0px;
  background-image: url("../../images/od-tabs-menu-icon.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation > div .od-mc-main-tabs-menu.active {
  background-position: 0px -18px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

@media all and (max-width: 580px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs {
    display: none;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li {
  float: left;
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  border-style: solid;
  border-color: #ffffff;
  border-right-width: 1px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:last-of-type {
  border-right: 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 13px 10px;
  text-decoration: none;
  background-color: #71baed;
  font-family: 'latobold';
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li > a:hover, .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li > a.active {
  background-color: #44a4e7;
}

@media all and (max-width: 930px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li > a {
    font-size: 11px;
  }
}

@media all and (max-width: 800px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li > a {
    min-height: 48px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li .od-mc-main-tabs-counter {
  position: absolute;
  top: -15px;
  right: 7px;
  margin: 0px;
  padding: 4px 0px;
  width: 26px;
  height: 26px;
  background-color: #dd1c52;
  border-radius: 15px;
  text-align: center;
  font-family: 'latobold';
  font-size: 9px;
  color: #ffffff;
  z-index: 10;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
}

@media all and (max-width: 950px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li .od-mc-main-tabs-counter {
    top: auto;
    bottom: -12px;
  }
}

@media all and (max-width: 420px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li .od-mc-main-tabs-counter {
    bottom: 6px;
  }
}

@media all and (max-width: 950px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li {
    width: 33.3% !important;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(-n+3) {
    border-bottom-width: 1px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(3) {
    border-right: 0px;
  }
}

@media all and (max-width: 580px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li {
    width: 50% !important;
    border-bottom-width: 1px;
    border-right-width: 1px !important;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(-n+3) {
    border-bottom-width: 1px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(3) {
    border-right: 1px solid #ffffff !important;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(2n+2) {
    border-right: none;
  }
}

@media all and (max-width: 420px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li {
    width: 100% !important;
    border-right: 0px;
  }
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs > li:nth-child(3) {
    border-right: none !important;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs.six-tabs > li {
  width: 16.66%;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs.five-tabs > li {
  width: 20%;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs.four-tabs > li {
  width: 25%;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-mc-main-tabs-navigation .od-mc-main-tabs.three-tabs > li {
  width: 33.3%;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: #ffffff;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab.od-main-tab-padding-right {
  padding-right: 340px;
}

@media all and (max-width: 920px) {
  .offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab.od-main-tab-padding-right {
    padding-right: 0px;
  }
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab .od-main-tabs-cont-leftcol {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 30px 20px;
  position: relative;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab .od-main-tabs-cont-leftcol .default-section-title {
  margin-top: 40px;
  margin-bottom: 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab .od-main-tabs-cont-leftcol .default-section-title:first-of-type {
  margin-top: 0px;
}

.offer-details-main-content .od-mc-inner-cont .od-mc-content-wrapper .od-main-tabs-content .od-main-tab .no-padding {
  margin-top: 20px;
}

.offer-details-main-content .offer-details-status-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  margin: 0px;
  padding: 0px 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
  -webkit-animation: odStatusAnimation 1.5s forwards;
  /* Safari 4+ */
  -moz-animation: odStatusAnimation 1.5s forwards;
  /* Fx 5+ */
  -o-animation: odStatusAnimation 1.5s forwards;
  /* Opera 12+ */
  animation: odStatusAnimation 1.5s forwards;
}

.offer-details-main-content .offer-details-status-wrapper > div {
  width: 100%;
  max-width: 1200px;
  min-height: 46px;
  margin: 0px auto;
  padding: 0px 15px;
}

.offer-details-main-content .offer-details-status-wrapper > div > div {
  float: left;
  width: 100%;
  min-height: 46px;
  margin: 0px;
  padding: 0px 0px 0px 50px;
  border-radius: 5px 5px 0px 0px;
  background-image: url("../../images/overfunding-status-icon.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  position: relative;
  border-radius: 5px 5px 0px 0px;
  background-color: #469edc;
}

@media all and (max-width: 720px) {
  .offer-details-main-content .offer-details-status-wrapper > div > div {
    min-height: 65px;
  }
}

.offer-details-main-content .offer-details-status-wrapper > div > div .offer-details-status {
  float: left;
  margin: 0px;
  padding: 13px 0px 0px;
  font-size: 20px;
  font-family: 'latoblack';
  color: #fff;
}

@media all and (max-width: 620px) {
  .offer-details-main-content .offer-details-status-wrapper > div > div .offer-details-status {
    font-size: 16px;
  }
}

.offer-details-main-content .offer-details-status-wrapper > div > div > p {
  float: right;
  margin: 0px;
  padding: 16px 15px;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
}

@media all and (max-width: 720px) {
  .offer-details-main-content .offer-details-status-wrapper > div > div > p {
    float: left;
    width: 100%;
    padding: 5px 10px 0px 0px;
  }
}

@media all and (max-width: 620px) {
  .offer-details-main-content .offer-details-status-wrapper > div > div > p {
    font-size: 10px;
  }
}

.mn-container {
  margin-top: 46px;
  margin-left: 20px;
  margin-right: 20px;
}

@media all and (max-width: 768px) {
  .mn-container {
    margin-left: 0;
    margin-right: 0;
  }
}

.mn-container .mn-amount-raise-invest-container {
  display: inline-flex;
  margin: 10px;
}

.mn-container .mn-amount-raise-invest-container .mn-amount-raise-invest-content {
  margin-right: 20px;
}

.mn-container .mn-amount-raise-invest-container .mn-amount-raise-invest-content .total-title {
  font-family: latobold;
  color: #6f8899;
  line-height: 30px;
}

@media all and (max-width: 500px) {
  .mn-container .mn-amount-raise-invest-container .mn-amount-raise-invest-content .total-title {
    font-size: 13px;
  }
}

.mn-container .list-container {
  background-color: #f3f5f6;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #e4ecf1;
}

.mn-container .list-container li {
  font-family: latobold;
  /* background-color: #f3f5f6; */
  padding: 16px 15px 15px 15px;
  /* border-bottom: 1px solid white; */
  width: 100%;
  height: 50px;
}

.mn-container .list-container li .tooltip-li {
  position: relative;
  display: inline-block;
}

.mn-container .list-container li .tooltip-li .title {
  font-family: latobold;
  font-size: 16px;
  color: #6f8899;
}

@media all and (max-width: 500px) {
  .mn-container .list-container li .tooltip-li .title:after {
    content: "\a";
    white-space: pre;
  }
}

.mn-container .list-container li .tooltip-li .mn-info {
  font-size: 15px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #6f8899;
}

.mn-container .list-container li .tooltip-li .mn-no-border {
  border-bottom: none;
}

.mn-container .list-container li .mn-link-black {
  font-size: 15px;
  font-family: "latolight";
  color: #333;
}

.mn-container .list-container li .mn-link-black .a-blue {
  color: #333;
  font-family: latolight;
}

.mn-container .list-container li .tooltip-li .tooltiptext {
  display: none;
  width: 100%;
  height: auto;
  background-color: #6f8899;
  color: white;
  font-family: latoregular;
  text-align: left;
  /* border-radius: 6px; */
  padding: 15px 10px;
  position: absolute;
  z-index: 999999;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  font-size: 13px;
  line-height: 20px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-container .list-container li .tooltip-li .tooltiptext {
    width: 90%;
  }
}

.mn-container .list-container li .tooltip-li .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #6f8899 transparent transparent transparent;
}

.mn-container .list-container li .tooltip-li:hover .tooltiptext {
  display: flex;
}

.od-main-tabs-cont-rightcol {
  margin: 0;
  max-width: 320px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 45px;
  width: 100%;
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation {
  float: left;
  width: 100%;
  padding: 0px;
  position: relative;
  margin-bottom: 10px;
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  /* Navigation list items ends here */
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px 0px 1px 0px;
  list-style: none;
  /* nth-child media queries - sidebar navigation links text adjustment */
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a {
  float: left;
  width: 100%;
  height: 50px;
  margin: 0px;
  padding: 16px 15px 15px 15px;
  background-color: #f3f5f6;
  font-family: 'latobold';
  font-size: 16px;
  color: #6f8899;
  text-align: right;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  border-style: solid;
  border-color: #f3f5f6;
  border-bottom-width: 1px;
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a:hover, .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a.active {
  background-color: #e4ecf1;
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a:hover:after, .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a.active:after {
  visibility: visible;
  opacity: 1;
  left: 15px;
}

.od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 40px;
  background-image: url(../../images/settings-sidebar-nav-arrow.png);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

@media all and (max-width: 610px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li > a {
    padding: 17px 15px 15px !important;
  }
}

@media all and (max-width: 610px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li {
    width: 50%;
  }
}

@media all and (max-width: 420px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li {
    width: 100%;
  }
}

@media all and (max-width: 990px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li:nth-child(3) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 818px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li:nth-child(2) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 798px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li:nth-child(5) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 747px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li:nth-child(4) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 652px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation > ul > li:nth-child(1) > a {
    padding-top: 10px;
  }
}

@media all and (max-width: 992px) {
  .od-main-tabs-cont-rightcol .od-maintabs-sidebar-navigation {
    display: none;
  }
}

@media all and (max-width: 992px) {
  .od-main-tabs-cont-rightcol {
    position: relative;
    top: 0 !important;
    margin: 0 auto 50px;
    position: relative;
    max-width: 100%;
    text-align: center;
  }
}

/* Offer details main tabs rightcol */
/*  Add this class to fix the right col nav  */
.fix-right-nav {
  margin: 0;
  max-width: 320px;
  padding: 0;
  top: 10px;
  width: 100%;
  position: fixed;
}

@media all and (max-width: 991px) {
  .fix-right-nav {
    position: relative;
  }
}

/* Offer details rightcol navigation under 950px */
.od-rightcol-fixed-nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  background-color: #8f9ea9;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.od-rightcol-fixed-nav.odfixednav-visible {
  opacity: 1;
  visibility: visible;
}

.od-rightcol-fixed-nav .od-rc-fixed-nav-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.od-rightcol-fixed-nav .od-rc-fixed-nav-title > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 50px;
  font-family: 'latobold';
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

.od-rightcol-fixed-nav .od-rc-fixed-nav-title > a {
  position: absolute;
  width: 22px;
  height: 22px;
  margin: 0px;
  padding: 0px;
  background-image: url("../../images/offer-details-fixed-arrows.png");
  background-repeat: no-repeat;
}

.od-rightcol-fixed-nav .od-rc-fixed-nav-title > a:first-of-type {
  top: 9px;
  left: 15px;
  background-position: 0px 0px;
}

.od-rightcol-fixed-nav .od-rc-fixed-nav-title > a:last-of-type {
  top: 9px;
  right: 15px;
  background-position: -22px 0px;
}

.od-tabs-file-blocks {
  float: left;
  width: 100%;
  margin: 10px 0px 0px 0px;
  padding: 0px 10px 30px;
  position: relative;
}

.od-tabs-file-blocks .od-tabs-file-block {
  float: left;
  width: 33.3%;
  margin: 20px 0px 0px 0px;
  padding: 0px 10px;
}

@media all and (max-width: 650px) {
  .od-tabs-file-blocks .od-tabs-file-block {
    padding: 0px !important;
    width: 100%;
    margin-top: 20px !important;
  }
  .od-tabs-file-blocks .od-tabs-file-block:first-of-type {
    margin-top: 0px !important;
  }
}

.od-tabs-file-blocks .od-tabs-file-block > div {
  float: left;
  width: 100%;
  min-height: 110px;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: #f3f5f6;
  /* Certificate file titlebar */
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar {
  float: left;
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding: 0px 20px 0px 40px;
  position: relative;
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > span {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
  background-color: #e7e7e7;
  background-repeat: no-repeat;
  background-position: center center;
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > span.text-file {
  background-color: #dc6e88;
  background-image: url(../../images/cert-file-icon.png);
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > span.text-file.docx-file {
  background-color: #8fb9cd;
  background-image: url(../../images/cert-text-file-icon.png);
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > span.text-file.pdf-file {
  background-color: #dc6e88;
  background-image: url(../../images/cert-pdf-file-icon.png);
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > span.text-file.doc-file {
  background-color: #8fb9cd;
  background-image: url(../../images/cert-text-file-icon.png);
}

.od-tabs-file-blocks .od-tabs-file-block > div .up-cert-fileblock-titlebar > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 11px 0px 11px 15px;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.od-tabs-file-blocks .od-tabs-file-block > div > p {
  float: left;
  width: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px 15px 50px;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 18px;
  height: 150px;
}

.od-tabs-file-blocks .od-tabs-file-block > div .cert-file-download-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  margin: 0px;
  padding: 7px 40px 7px 10px;
  height: 26px;
  color: #61a0cb;
  text-decoration: none;
  font-family: 'latobold';
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  background-image: url(../../images/cert-file-download-btn-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-style: solid;
  border-color: #61a0cb;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.od-tabs-file-blocks .od-tabs-file-block > div .cert-file-download-btn:hover {
  background-position: right 10px top 6px;
}

@-webkit-keyframes odStatusAnimation {
  0% {
    top: 0px;
  }
  50% {
    top: 0px;
  }
  100% {
    top: -110px;
  }
}

@-moz-keyframes odStatusAnimation {
  0% {
    top: 0px;
  }
  50% {
    top: 0px;
  }
  100% {
    top: -110px;
  }
}

@-o-keyframes odStatusAnimation {
  0% {
    top: 0px;
  }
  50% {
    top: 0px;
  }
  100% {
    top: -110px;
  }
}

@keyframes odStatusAnimation {
  0% {
    top: 0px;
  }
  50% {
    top: 0px;
  }
  100% {
    top: -110px;
  }
}

@media all and (max-width: 720px) {
  @-webkit-keyframes odStatusAnimation {
    0% {
      top: 0px;
    }
    50% {
      top: 0px;
    }
    100% {
      top: -130px;
    }
  }
  @-moz-keyframes odStatusAnimation {
    0% {
      top: 0px;
    }
    50% {
      top: 0px;
    }
    100% {
      top: -130px;
    }
  }
  @-o-keyframes odStatusAnimation {
    0% {
      top: 0px;
    }
    50% {
      top: 0px;
    }
    100% {
      top: -130px;
    }
  }
  @keyframes odStatusAnimation {
    0% {
      top: 0px;
    }
    50% {
      top: 0px;
    }
    100% {
      top: -130px;
    }
  }
}

.offer-details-team-tab {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 30px 20px 0px;
}

.offer-details-team-tab > p {
  color: #7e7e7e;
  float: left;
  font-family: "latolight";
  font-size: 15px;
  line-height: 1.5;
  margin: 30px 0 0;
  padding-bottom: 20px;
  width: 100%;
}

.offer-details-team-section {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0 10px;
}

@media all and (max-width: 660px) {
  .offer-details-team-section {
    padding: 20px;
  }
}

.offer-details-team-section .od-team-section-block {
  float: left;
  width: 33.3%;
  margin: 0px;
  padding: 10px;
}

@media all and (max-width: 1023px) {
  .offer-details-team-section .od-team-section-block {
    width: 50%;
  }
}

@media all and (max-width: 660px) {
  .offer-details-team-section .od-team-section-block {
    width: 100%;
    padding: 10px 0px;
  }
}

.offer-details-team-section .od-team-section-block > div {
  float: left;
  width: 100%;
  min-height: 95px;
  margin: 0px;
  padding: 0px 0px 0px 95px;
  position: relative;
  background-color: #f3f5f6;
  border-style: solid;
  border-color: #e9eaea;
  border-width: 1px;
}

.offer-details-team-section .od-team-section-block > div > img {
  position: absolute;
  top: 17px;
  left: 15px;
  margin: 0px;
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 40px;
  border-style: solid;
  border-color: #d8dadb;
  border-width: 4px;
}

.offer-details-team-section .od-team-section-block > div > div {
  float: left;
  width: 100%;
  height: 100%;
  min-height: 110px;
  margin: 0px;
  padding: 13px 15px 35px 15px;
  position: relative;
}

.offer-details-team-section .od-team-section-block > div > div > span {
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  text-transform: uppercase;
  line-height: 18px;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  float: left;
  text-align: right;
  overflow: hidden;
}

.offer-details-team-section .od-team-section-block > div > div > p {
  margin: 6px 0px 0px 0px;
  font-family: 'latolight';
  font-size: 13px;
  color: #7e7e7e;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  text-align: right;
}

.offer-details-team-section .od-team-section-block > div > div > p > span {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  text-align: right;
}

.offer-details-team-section .od-team-section-block > div > div > a {
  position: absolute;
  bottom: 12px;
  right: 15px;
  margin: 0px;
  padding: 5px 20px 3px 10px;
  font-family: 'latoregular';
  font-size: 10px;
  text-transform: uppercase;
  background-color: #469edc;
  background-image: url(../../images/submit-btn-arrow.png);
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 5px;
  color: white;
  text-decoration: none;
}

.offer-details-team-section .od-team-section-block > div > div > a:hover {
  text-decoration: underline;
}

.offer-details-team-section .od-team-section-block > div .od-team-section-block-remove {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 12px;
  height: 12px;
  background-image: url(../../images/cr-result-block-remove.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.offer-investor-limit-height-950 {
  height: 950px;
  overflow: hidden;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.offer-details-team-tabs-wrapper {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.od-team-tabs {
  padding: 0px 20px;
  margin-top: 62px;
}

@media all and (max-width: 600px) {
  .od-team-tabs {
    padding: 0px;
  }
}

@media all and (max-width: 600px) {
  .od-team-tabs > li {
    width: 100%;
    border-right: 0px;
  }
  .od-team-tabs > li:last-of-type {
    border-right: 0px;
  }
}

.offer-details-team-tabs-content {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.od-warning-statement-section {
  margin-top: 70px;
  display: block;
  margin: 0 -20px;
  width: calc(100% + 40px);
}

.od-warning-statement-section .warning-statement {
  max-width: unset;
}

.od-warning-statement-section .ws-text {
  padding-left: 20px;
  padding-right: 20px;
}

.od-warning-statement-section .ws-title {
  padding-left: 0 !important;
}

.od-tab-second, .od-tab-third, .od-tab-fourth, .od-tab-fifth, .od-tab-sixth {
  display: none;
}

.od-members-blocks {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  position: relative;
}

.od-members-blocks > p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.od-members-blocks .od-member-block {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 20px 0px 0px 0px;
}

.od-members-blocks .od-member-block:nth-child(2n+1) {
  padding: 20px 10px 0px 0px;
}

@media all and (max-width: 768px) {
  .od-members-blocks .od-member-block:nth-child(2n+1) {
    padding: 20px 0px 0px 0px;
  }
}

.od-members-blocks .od-member-block:nth-child(2n+2) {
  padding: 20px 0px 0px 10px;
}

@media all and (max-width: 768px) {
  .od-members-blocks .od-member-block:nth-child(2n+2) {
    padding: 20px 0px 0px 0px;
  }
}

.od-members-blocks .od-member-block:nth-child(-n+2) {
  padding-top: 0px;
}

@media all and (max-width: 768px) {
  .od-members-blocks .od-member-block:nth-child(-n+2) {
    padding: 20px 0px 0px 0px;
  }
}

@media all and (max-width: 768px) {
  .od-members-blocks .od-member-block {
    width: 100%;
  }
  .od-members-blocks .od-member-block:first-of-type {
    padding-top: 0px;
  }
}

.od-members-blocks .od-member-block > div {
  float: left;
  width: 100%;
  min-height: 75px;
  margin: 0px;
  padding: 0px 0px 0px 90px;
  position: relative;
  background-color: #f3f5f6;
}

.od-members-blocks .od-member-block > div.no-image {
  padding: 0px;
}

.od-members-blocks .od-member-block > div > img {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 75px;
  height: 75px;
  margin: 0px;
  padding: 0px;
}

.od-members-blocks .od-member-block > div > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.od-members-blocks .od-member-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px 0px 10px 0px;
  padding: 0px 25px 0px 10px;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
}

.od-members-blocks .od-member-block > div > div > p {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px 10px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #7e7e7e;
}

.od-members-blocks .od-member-block > div > div > p > span {
  margin: 0px;
  padding: 0px;
  font-family: 'latobold';
  color: #7e7e7e;
}

.od-members-blocks .od-member-block > div > div > a {
  background-image: url("../../images/blue-button-arrow.png");
  background-position: right 10px center;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #469edc;
  border-width: 1px;
  color: #469edc;
  float: left;
  font-family: "latoblack";
  font-size: 11px;
  margin: 15px 0 0 10px;
  min-height: 26px;
  padding: 6px 35px 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.od-members-blocks .od-member-block > div > div > a:hover {
  background-position: right 7px center;
}

.od-members-blocks .od-member-block > div .od-member-block-remove {
  position: absolute;
  width: 11px;
  height: 11px;
  margin: 0px;
  padding: 0px;
  background-image: url(../../images/is-cm-remove-icon.png);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  top: 5px;
  right: 5px;
}

/* Updates and Q.A. */
.od-uq-tab {
  padding: 30px 20px !important;
}

.uq-comments-wrapper {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.uq-comments-blocks {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
}

.uq-comment-block {
  float: left;
  width: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.uq-comment-block > div {
  float: left;
  width: 100%;
  min-height: 165px;
  margin: 0px;
  padding: 0px 20px 0px 170px;
  background-color: #85afcd;
  position: relative;
}

@media all and (max-width: 580px) {
  .uq-comment-block > div {
    padding: 0px 20px 0px 100px;
  }
}

.uq-comment-block > div > img {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 100%;
  max-width: 150px;
  margin: 0px;
  padding: 0px;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
}

@media all and (max-width: 580px) {
  .uq-comment-block > div > img {
    max-width: 80px;
  }
}

.uq-comment-block > div > .overlay-pic {
  position: absolute;
  width: 50px;
}

@media all and (max-width: 580px) {
  .uq-comment-block > div > .overlay-pic {
    width: 30px;
  }
}

.uq-comment-block > div > div {
  float: left;
  width: 100%;
  min-height: 165px;
  margin: 0px;
  padding: 15px 0px 35px;
  position: relative;
}

@media all and (max-width: 580px) {
  .uq-comment-block > div > div {
    padding: 15px 0px 50px;
  }
}

.uq-comment-block > div > div > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 20px;
  color: #ffffff;
}

.uq-comment-block > div > div > p {
  float: left;
  width: 100%;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 14px;
  color: #ffffff;
  line-height: 1.5;
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for WebKit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.uq-comment-block > div > div .uq-comment-users {
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: absolute;
  bottom: 15px;
  left: 0px;
}

.uq-comment-block > div > div .uq-comment-users > span {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 12px;
  color: #ffffff;
}

.uq-comment-block > div > div .uq-comment-users > p {
  float: right;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 12px;
  color: #ffffff;
}

.uq-comment-block.reply {
  margin-top: 2px;
  padding: 0px 0px 0px 30px;
}

.uq-comment-block.reply > div {
  background-color: #e8edf0;
}

@media all and (max-width: 840px) {
  .uq-comment-block.reply > div > div {
    padding-bottom: 50px;
  }
}

@media all and (max-width: 580px) {
  .uq-comment-block.reply > div > div {
    padding-bottom: 65px;
  }
}

.uq-comment-block.reply > div > div > span, .uq-comment-block.reply > div > div > p, .uq-comment-block.reply > div > div .uq-comment-users > span, .uq-comment-block.reply > div > div .uq-comment-users > p {
  color: #7e7e7e;
}

@media all and (max-width: 840px) {
  .uq-comment-block.reply > div > div .uq-comment-users > p {
    float: left;
    clear: both;
    margin-top: 3px;
  }
}

.uq-reply-btn {
  position: absolute;
  top: 15px;
  right: -5px;
  margin: 0px;
  padding: 0px;
  width: 17px;
  height: 13px;
  background-image: url(../../images/uqa-reply-icon.png);
  background-repeat: no-repeat;
}

.uq-new-comment {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 20px;
  background-color: #e8edf0;
  position: relative;
}

.uq-new-comment > input {
  float: left;
  width: 100%;
  height: 36px;
  margin: 0px;
  padding: 0px 10px;
  font-family: 'latolight';
  font-size: 13px;
  color: #7e7e7e;
  border: 0px;
}

.uq-new-comment > textarea {
  float: left;
  width: 100%;
  min-height: 95px;
  height: 36px;
  margin: 10px 0px 0px 0px;
  padding: 10px;
  font-family: 'latolight';
  font-size: 13px;
  color: #7e7e7e;
  border: 0px;
  resize: none;
}

.uq-new-comment > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.uq-new-comment > div > span {
  float: left;
  margin: 0px;
  padding: 27px 0px 0px 0px;
  font-family: 'latolight';
  font-size: 12px;
  color: #7e7e7e;
}

@media all and (max-width: 480px) {
  .uq-new-comment > div > span {
    width: 100%;
    text-align: center;
  }
}

.uq-new-comment > div > span > a {
  color: #469edc;
  font-weight: bold;
  text-decoration: none;
}

.uq-new-comment > div > span > a:hover {
  text-decoration: underline;
}

.uq-new-comment > div > button {
  float: right;
  width: 110px;
  height: 28px;
  margin: 20px 0px 0px 0px;
  padding: 0px 10px;
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
  border: 0px;
  background-color: #469edc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  background-image: url(../../images/eq-new-comment-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.uq-new-comment > div > button:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 480px) {
  .uq-new-comment > div > button {
    display: inline-block;
    float: none;
  }
}

.uq-new-comment > div > input {
  float: right;
  width: 110px;
  height: 28px;
  margin: 20px 0px 0px 0px;
  padding: 0px 10px;
  font-family: 'latobold';
  font-size: 12px;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
  border: 0px;
  background-color: #469edc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  background-image: url(../../images/eq-new-comment-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.uq-new-comment > div > input:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 480px) {
  .uq-new-comment > div > input {
    display: inline-block;
    float: none;
  }
}

.new-reply {
  display: none;
}

.new-reply .uq-new-comment {
  margin: 3px 0px 0px 0px;
}

.error-comment {
  position: relative;
}

.error-comment > .error-tooltip {
  color: #ffffff;
  padding: 5px 10px;
}

/* Prefunding form wrapper */
.prefunding-form-wrapper {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px 50px 0px 0px;
  background-color: #F3F5F6;
  position: relative;
  display: none;
}

@media all and (max-width: 580px) {
  .prefunding-form-wrapper {
    padding: 0px 0px 50px 0px;
  }
}

ul.prefunding-form {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

ul.prefunding-form > li {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0px;
}

@media all and (max-width: 1060px) {
  ul.prefunding-form > li {
    width: 100%;
  }
}

.prefunding-form-group {
  float: left;
  margin: 0;
  min-height: 60px;
  padding: 22px 300px 22px 15px;
  position: relative;
  width: 100%;
  background-color: #F3F5F6;
}

@media all and (max-width: 580px) {
  .prefunding-form-group {
    padding: 15px;
  }
  .prefunding-form-group.pfg-radio {
    padding: 15px 155px 15px 15px;
  }
}

.prefunding-form-group-title {
  color: #7e7e7e;
  float: left;
  font-family: "latoregular";
  font-size: 14px;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;
  width: 100%;
}

.prefunding-input-cont {
  max-width: 280px;
  position: absolute;
  right: 10px;
  top: 9px;
  width: 100%;
}

@media all and (max-width: 580px) {
  .prefunding-input-cont {
    position: relative;
    top: auto;
    right: auto;
    float: left;
    max-width: 100%;
    margin-top: 10px;
  }
}

.prefunding-input-cont > input[type="text"],
.prefunding-input-cont > input[type="password"] {
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 14px;
  height: 42px;
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.prefunding-add-member {
  background-color: #469edc;
  background-image: url("../../images/cert-upload-btn-icon.png");
  background-position: center center;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 40px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.prefunding-add-member:hover {
  background-color: #5ba9e0;
}

@media all and (max-width: 580px) {
  .prefunding-add-member {
    right: 50%;
    margin-right: -20px;
  }
}

.prefunding-radio-input-cont {
  max-width: 130px;
  position: absolute;
  right: 10px;
  top: 16px;
  width: 100%;
}

.prefunding-members-blocks {
  display: none;
}

.select2-container {
  margin: 0px;
}

.select2-container--default .select2-selection--single {
  height: 40px;
  border-radius: 0px;
  border-color: #dfe6eb;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-width: 7px 6px 0;
  margin-left: -15px;
  margin-top: 3px;
  border-color: #6f8899 transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0px 6px 7px !important;
  margin-left: -15px;
  margin-top: 3px;
  border-color: transparent transparent #6f8899 !important;
}

.header_span {
  padding: 30px 0 10px 0;
  font-weight: bold;
}

.header_span_sub {
  padding: 15px 0 5px 0;
  font-weight: bold;
}

/** Financial services **/
.financial-service-table {
  margin: 15px 0px;
  width: 50%;
}

.financial-service-table tr {
  border-top: 1px solid #7cbee5;
}

.financial-service-table tr:last-child {
  border-top: 1px solid #7cbee5;
  border-bottom: 1px solid #7cbee5;
}

.financial-service-table td {
  padding: 5px;
}

.financial-service-circle-ul {
  list-style-type: circle;
  margin-left: 30px;
}

.disc-investor-list {
  list-style: disc;
  margin-left: 30px;
}

.mn-offer-event-img-cnt {
  display: inline-block;
  position: relative;
  width: 100%;
}

@media all and (max-width: 991px) {
  .mn-offer-event-img-cnt {
    display: block;
  }
}

.offer-event-flex {
  display: inline-flex;
}

.offer-event-image {
  width: 320px;
  height: 160px;
  object-fit: cover;
}

@media all and (max-width: 991px) {
  .offer-event-image {
    width: 100%;
    height: 240px;
  }
}

.offer-event-image-offer-page {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media all and (max-width: 991px) {
  .offer-event-image-offer-page {
    width: 100%;
    height: 160px;
  }
}

.mn-offer-event-content {
  width: 100%;
  display: inline-block;
  background-color: #f3f5f6;
  padding: 20px 9px;
  color: #6f8899;
}

.mn-offer-event-content .mn-offer-event-title {
  margin-bottom: 8px;
  font-size: 16px;
}

.mn-offer-event-content .mn-offer-event-desc {
  margin-bottom: 8px;
  font-family: latoregular;
}

.event-banner {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  background-color: rgba(1, 1, 1, 0.6);
  width: 100%;
  padding: 13px;
  color: white;
  font-size: 24px;
}

#offer-left-sidebar {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media all and (max-width: 768px) {
  #offer-left-sidebar {
    padding-right: 0;
    padding-left: 0;
    float: none;
  }
}

.fixed-top {
  position: fixed;
  top: 110px;
  max-width: 320px;
}

@media all and (max-width: 768px) {
  .fixed-top {
    position: relative;
    top: auto;
    left: auto;
    max-width: 100%;
  }
}

.fixed-bottom {
  position: relative;
  bottom: 14705px;
}
