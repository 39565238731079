@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
USER PROFILE PAGE
---------------------------------------------------------- */
.user-profile-maincont {
    float: left;
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 340px 0px 0px;
    position: relative;
    @include mquery(960px) {
        padding: 0px;
    }
    .up-leftcol {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;

        .up-top-section {
            position: relative;
            min-height: 300px;
            padding: 30px 30px 30px 300px;
            background-color: $main-columns-bg;

            .up-badges {
                position: absolute;
                top: -30px;
                left: 30px;
                margin: 0px;
                padding: 0px;
                > li {
                    float: left;
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    > span {
                        float: left;
                        width: 38px;
                        height: 30px;
                        margin: 0px 5px 0px 0px;
                        padding: 0px;
                        background-repeat: no-repeat;
                        background-position: 0px 0px;
                        display: none;
                        &.visible {
                            display: inline-block;
                        }
                        &.up-sophisticated-badge {
                            background-image: url(../../images/up-star-badge.png);
                        }
                        &.up-syndicated-badge {
                            background-image: url(../../images/up-syndicate-badge.png);
                        }
                    }
                }
            }


            > .left {
                position: absolute;
                left: 30px;
                top: 30px;
                width: 240px;
                min-height: 240px;

                > img {
                    width: 155px;
                    height: 155px;
                    margin: 0px;
                    padding: 0px;
                    @include mquery(600px) {
                        float: none;
                    }
                }
                > div {
                    //position: absolute;
                    bottom: 0px;
                    left: 0px;
                    margin: 0px;
                    padding: 0px;
                    margin-top: 10px;
                    width: 100%;
                    @include mquery(600px) {
                        position: relative;
                        float: left;
                        left: auto;
                        bottom: auto;
                        margin: 20px 0px 0px 0px;
                        padding: 0px;
                    }
                    .up-social-links {
                        float: left;
                        width: 100%;
                        margin: 10px 0px 0px 0px;
                        padding: 0px;
                        text-align: center;
                        > a {
                            float: left;
                            width: 20px;
                            height: 20px;
                            margin: 0px 7px 0px 0px;
                            padding: 0px;
                            @include transition(.3s);
                            &:hover {
                                opacity: 0.7;
                            }
                            > img {
                                float: left;
                                width: 20px;
                                height: 20px;
                                margin: 0px;
                                padding: 0px;
                            }
                            @include mquery(600px) {
                                display: inline-block;
                                float: none;
                                &:last-of-type {
                                    margin: 0px;
                                }
                            }
                        }
                    }
                    .user-profile-name {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latoblack';
                        font-size: 22px;
                        color: $default-text-color;
                        @include mquery(600px) {
                            text-align: center;
                        }
                    }
                    .user-profile-title {
                        float: left;
                        width: 100%;
                        margin: 5px 0px 0px 0px;
                        padding: 0px;
                        font-family: 'latoregular';
                        font-size: 14px;
                        color: $up-subtitle-color;
                        line-height: 1.4em;
                        @include mquery(600px) {
                            text-align: center;
                        }
                    }
                }
            }
            > .right {
                position: relative;
                display: inline-block;
                width: 100%;
                min-height: 240px;
                text-align: right;
                margin-top: 30px;

                .up-detail-text {
                    margin: 15px 0px 0px 0px;
                    padding: 0px;
                    text-align: inherit;

                    &:first-of-type {
                        margin-top: 0px;
                    }
                    &.up-contact-details {
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        @include mquery(600px) {
                            position: relative;
                            bottom: auto;
                            right: auto;
                            margin: 20px 0px 0px 0px;
                        }
                    }
                    > span {
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latoregular';
                        font-size: 13px;
                        color: $up-subtitle-color;
                    }
                    > p {
                        margin: 5px 0px 0px 0px;
                        padding: 0px;
                        font-family: 'latobold';
                        font-size: 15px;
                        color: $default-text-color;
                        line-height: 18px;
                        text-align: inherit;
                    }
                    @include mquery(600px) {
                        &:first-of-type {
                            margin-top: 20px;
                        }
                        > span {
                            text-align: center;
                        }
                        > p {
                            text-align: center;
                        }
                    }
                }
            }
            .up-edit-profile-btn {
                position: absolute;
                top: 30px;
                right: 30px;
                padding: 5px 10px 4px 10px;
                text-decoration: none;
                font-family: 'latobold';
                font-size: 12px;
                color: $up-edit-profile-btn-color;
                background-color: $up-edit-profile-bg;
                z-index: 1;

                @include transition(.3s);

                &:hover {
                    background-color: $main-blue-link-hover;
                }
                span {
                    font-family: inherit;
                }
            }

            @include mquery(600px) {
                padding: 30px;
                > .left {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    min-height: 220px;
                    text-align: center;


                    > img {
                        display: inline-block;
                        width: 164px;
                        height: 164px;
                        margin: 0px;
                        padding: 0px;
                    }
                }
                > .right {
                    width: 100%;
                    min-height: 220px;
                    text-align: center;
                    margin-top: 0;
                }
                .up-edit-profile-btn {
                    top: 8px;
                    right: 8px;
                    background: transparent;
                    color: #0077B5;
                    text-decoration: underline;
                    font-size: 14px;
                    .hide-on-small {
                        display: none;
                    }
                    &:hover {
                        background-color: inherit;
                    }
                }
            }
        } /* User profile top section ends here */
        .up-tabs-cont {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            .up-tabs {
                float: left;
                width: 100%;
                min-height: 40px;
                margin: 15px 0px 0px 0px;
                padding: 0px;
                position: relative;
                @include mquery(600px) {
                    background-color: $up-tabs-bg;
                }
                > li {
                    float: left;
                    width: 100%;
                    min-height: 40px;
                    margin: 0px;
                    padding: 0px;
                    list-style: none;
                    @include border(right, 1px, solid, $white-border);
                    &:last-of-type {
                        border: 0px;
                    }
                    @include mquery(600px) {
                        width: 50%;
                        @include border(bottom, 1px, solid, $white-border);
                        &:last-of-type {
                            @include border(right, 1px, solid, $white-border);
                            @include border(bottom, 1px, solid, $up-tabs-border);
                        }
                    }
                    > a {
                        float: left;
                        width: 100%;
                        min-height: 40px;
                        margin: 0px;
                        padding: 11px 0px 0px 0px;
                        text-decoration: none;
                        font-family: 'latobold';
                        font-size: 15px;
                        color: $up-tabs-links-color;
                        background-color: $up-tabs-bg;
                        text-align: center;
                        @include transition(.3s);
                        &:hover, &.active {
                            background-color: $main-blue-link-hover;
                        }
                        &.inv-team-link{
                            padding: 0 5px;
                            display: table;
                            height: 40px;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            -o-box-sizing: border-box;
                            box-sizing: border-box;
                            span{
                                display: table-cell;
                                vertical-align: middle;
                                text-align: center;
                                font-family: 'latobold';
                                font-size: 15px;
                                color: $up-tabs-links-color;
                                @include mquery(580px){
                                    font-size: 12px;
                                }
                            }
                            &:hover, &.active {
                                background-color: $main-blue-link-hover;
                            }
                        }
                    }
                }
                &.three-tabs > li {
                    width: 33.3%;
                }
                &.four-tabs > li {
                    width: 25%;
                    @include mquery(600px) {
                        @include border(bottom, 0px, solid, $white-border);
                        &:last-of-type {
                            @include border(bottom, 0px, solid, $up-tabs-border);
                        }
                    }
                    a {
                        padding: 0 5px;
                        display: table;
                        height: 40px;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        -o-box-sizing: border-box;
                        box-sizing: border-box;
                        span {
                            display: table-cell;
                            vertical-align: middle;
                            text-align: center;
                            font-family: 'latoregular';
                            font-size: 15px;
                            color: $up-tabs-links-color;
                            @include mquery(580px){
                                font-size: 12px;
                            }
                        }
                        &:hover, &.active {
                            background-color: $main-blue-link-hover;
                        }
                    }
                }
                &.five-tabs > li {
                    width: 20%;
                }
                &.six-tabs > li {
                    width: 16.66%;
                }
            } /* User profile tabs ends here */
            .up-tabs-content {
                float: left;
                width: 100%;
                margin: 0px;
                padding: 15px 20px;
                background-color: $main-columns-bg;
                .up-bio-content {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    // padding: 5px 0px;
                    padding: 0;
                    position: relative;
                    .up-bio-maintext {
                        // float: left;
                        width: 100%;
                        padding: 0px;
                        color: #7e7e7e;
                        font-family: "latolight";
                        font-size: 16px;
                        line-height: 24px;
                        white-space: pre-wrap;

                        &:empty:after {
                            display: inline-block;
                            content: "This investor has not yet entered a biography.";
                        }
                    }

                }
                .no-show {
                    display: none;
                }

                 /* User profile BIO tab ends here */
                .up-cr-content {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    // padding: 5px 0px 5px 0px;
                    position: relative;
                    // display: none;

                    .formatting-container {
                        margin: 15px 0;
                    }

                    .up-bio-maintext {
                        color: #7e7e7e;
                        font-family: "latolight";
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                .up-fo-content {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    padding: 5px 0px 5px 0px;
                    position: relative;
                    display: none;
                }
                .up-synd-content {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    padding: 5px 0px 5px 0px;
                    position: relative;
                    display: none;

                    &.my-profile{
                        .actions {
                            text-align: right;
                            margin: 0 0 8px 0;
                        }

                        .synd-link,
                        .learn-link{
                            display: inline-block;
                            margin: 4px;
                            padding: 7px 35px 5px 10px;
                            min-height: 26px;
                            font-family: 'latoblack';
                            font-size: 11px;
                            text-transform: uppercase;
                            color: $default-blue-color;
                            text-decoration: none;
                            background-image: url('../../images/blue-button-arrow.png');
                            background-repeat: no-repeat;
                            background-position: right 10px center;
                            @include border(full, 1px, solid, $default-blue-color);
                            @include transition(.3s);
                            &:hover {
                                background-position: right 7px center;
                            }
                            @include mquery(538px) {
                                padding-right: 22px;
                            }
                        }
                    }
                    > p {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latoregular';
                        font-size: 14px;
                        color: $default-text-color;
                        line-height: 18px;
                        > a {
                            color: $default-blue-color;
                            text-decoration: none;
                            font-family: 'latoregular';
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .up-cert-content {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    padding: 5px 0px 5px 0px;
                    position: relative;
                    display: none;
                    > p {
                        float: left;
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        font-family: 'latoregular';
                        font-size: 14px;
                        color: $default-text-color;
                        line-height: 18px;
                        > a {
                            color: $default-blue-color;
                            text-decoration: none;
                            font-family: 'latoregular';
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        > span {
                            color: $default-blue-color;
                            font-family: 'latoregular';
                        }
                    }
                }
                .single-cr-fo-block {
                    min-height: 135px;
                    height: 100%;
                    margin: 15px 0px 0px 0px;
                    padding: 0px 0px 0px 150px;
                    position: relative;
                    background-color: $up-cr-block-bg;

                    @include mquery(560px) {
                        padding: 0px 0px 0px 115px;
                    }
                    @include mquery(480px) {
                        padding: 120px 0 0 0;
                    }
                    > img {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 135px;
                        height: 135px;
                        height: 100%;
                        margin: 0px;
                        padding: 0px;
                        @include mquery(560px) {
                            top: 10px;
                            left: 10px;
                            width: 110px;
                            height: 110px;
                        }
                        @include mquery(480px) {
                            left: 50%;
                            margin-left: -55px;
                        }
                    }
                    .cr-unfollow {
                        position: absolute;
                        top: 0px;
                        right: 20px;
                        height: 19px;
                        font-family: 'latoregular';
                        font-size: 11px;
                        color: #ffffff;
                        margin: 0px;
                        padding: 3px 10px;
                        background-color: $cr-unfollow-bg;
                        @include transition(.3s);
                        z-index: 5;
                        &:hover {
                            background-color: lighten($cr-unfollow-bg, 5%);
                        }
                    }
                    .up-cr-role {
                        position: absolute;
                        top: 0px;
                        right: 20px;
                        height: 19px;
                        font-family: 'latoregular';
                        font-size: 11px;
                        color: #ffffff;
                        margin: 0px;
                        padding: 3px 10px;
                        background-color: $cr-role-bg;
                        z-index: 5;
                        cursor: default;
                    }
                    > div {
                        //  width: 100%;
                        //  min-height: 150px;
                        //  margin: 0px;
                        // //  padding: 20px 15px 50px 15px;
                        //  position: relative;
                         @include mquery(620px) {
                            padding-bottom: 60px !important;
                        }
                        @include mquery(480px) {
                            padding: 10px !important;
                            min-height: 110px;
                            text-align: center;

                            > p {
                                // margin-top: 20px !important;
                                display: block !important;
                                text-align: center;
                                float: none;
                                clear: both;
                                font-size: 16px !important;
                            }

                        }
                         @include mquery(490px) {
                            padding: 20px 15px;
                         }
                         > span {
                            width: 100%;
                            margin: 0px;
                            padding: 0px 70px 0px 0px;
                            font-family: 'latoblack';
                            font-size: 19px;
                            color: $default-text-color;
                            @include mquery(620px) {
                                padding: 0;
                            }
                            @include mquery(480px) {
                                font-size: 16px;

                            }
                         }
                         > p {
                            width: 100%;
                            margin: 5px 0px 0px 0px;
                            padding: 0px;
                            font-family: 'latoregular';
                            font-size: 13px;
                            color: $default-text-color;
                            line-height: 18px;
                             white-space: pre-wrap;
                            @include mquery(620px) {
                                min-height: 105px;
                            }
                            @include mquery(560px) {
                                min-height: 72px;
                            }
                            @include mquery(480px) {
                                min-height: auto;
                            }
                         }
                         .cr-project-invested {
                            position: absolute;
                            bottom: 15px;
                            left: 15px;
                            margin: 0px;
                            padding: 0px;
                            font-size: 14px;
                            color: #333333;
                            @include mquery(620px) {
                                // position: absolute !important;
                                // left: -145px !important;
                                // bottom: 7px !important;
                            }
                            @include mquery(560px) {
                                // left: -105px !important;
                            }
                            @include mquery(480px) {
                                // position: relative !important;
                                // left: auto !important;
                                // bottom: auto !important;
                                // width:  100% !important;
                                text-align: center;
                            }
                            @include mquery(490px)
                                position: relative;
                                width: 100%;
                                margin: 10px 0px 0px 0px;
                                padding: 0px;
                                left: auto;
                                bottom: auto;
                            }
                            > span {
                                float: left;
                                margin: 0px;
                                padding: 0px;
                                font-family: 'latoblack';
                                font-size: 14px;
                                color: $default-text-color;
                                clear: both;
                                @include mquery(620px) {
                                    font-size: 18px;
                                    font-family: "latobold";
                                }
                            }
                            > p {
                                float: left;
                                margin: 3px 0px 0px 0px;
                                padding: 0px;
                                font-family: 'latoregular';
                                font-size: 12px;
                                color: $default-text-color;
                                clear: both;
                            }
                            span,
                            p {
                                @include mquery(620px) {
                                    float: none;
                                    display: inline;
                                    padding: 0 0px 0 0;
                                    margin: 0;
                                }
                            }
                         }
                     .single-cr-fo-buttons {
                        position: absolute;
                        bottom: 15px;
                        right: 0px;
                        margin: 0px;
                        padding: 0px 15px;
                        margin: 0px;

                        @include mquery(620px) {
                            // position: absolute !important;
                            // left: -145px !important;
                            // bottom: 25px !important;
                            // width:  auto !important;
                            // padding: 0 !important;
                        }
                        @include mquery(560px) {
                            // left: -105px !important;
                        }
                        @include mquery(480px) {
                            // position: relative !important;
                            // left: auto !important;
                            // bottom: auto !important;
                            // width:  auto !important;
                            margin: 0;
                            width: 100%;
                            text-align: center;
                        }
                        @include mquery(490px) {
                            position: relative;
                            bottom: auto;
                            right: auto;
                            margin: 15px 0px 5px 0;
                            padding: 0px;
                        }
                        > a {
                            display: inline-block;
                            margin: 0px 0px 0px 10px;
                            padding: 7px 35px 5px 10px;
                            min-height: 26px;
                            font-family: 'latoblack';
                            font-size: 11px;
                            text-transform: uppercase;
                            color: $default-blue-color;
                            text-decoration: none;
                            background-image: url('../../images/blue-button-arrow.png');
                            background-repeat: no-repeat;
                            background-position: right 10px center;
                            @include border(full, 1px, solid, $default-blue-color);
                            @include transition(.3s);
                            &:hover {
                                background-position: right 7px center;
                            }
                            @include mquery(620px) {
                                margin: 0px 10px 0px 0px;
                            }
                            @include mquery(490px) {
                            }
                            &.cr-fo-see-profile-btn {
                                @include mquery(490px) {
                                    margin: 10px 10px 0px 0px;
                                }
                            }
                        }
                    }
                } /* Single capital raisings block ends here */
            } /* User profile tabs content ends here */
        } /* User porfile tabs container ends here */
    } /* User profile leftcol ends here */
    .up-rightcol {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        max-width: 320px;
        margin: 0px;
        padding: 0px 0px 0px 0px;
        @include mquery(960px) {
            position: relative;
            right: auto;
            top: auto;
            float: left;
            max-width: 100%;
            padding: 0px;
        }
    }
    .up-comple-invest-cont {
        //position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        max-width: 320px;
        margin: 0px;
        padding: 0px;
        @include mquery(960px) {
            position: relative;
            float: left;
            max-width: 100%;
        }
        .up-completeness-widget {
            float: left;
            width: 100%;
            min-height: 140px;
            margin: 0 0 20px 0;
            padding: 0px 15px;
            background-color: $main-columns-bg;
            text-align: center;
            @include mquery(960px) {
                width: 49%;
                margin-top: 20px;
            }
            @include mquery(650px) {
                width: 100%;
            }
            .up-completeness-bar {
                display: inline-block;
                width: 100%;
                max-width: 260px;
                height: 44px;
                margin: 35px 0px 0px 0px;
                padding: 0px;
                background-color: $completeness-bar-bg;
                @include border(full, 2px, solid, $completeness-bar-border);
                border-radius: 6px;
                overflow: hidden;
                position: relative;
                > div {
                    float: left;
                    height: 40px;
                    margin: 0px;
                    padding: 0px;
                    background-color: $completeness-bar-active-bg;
                    @include transition(.3s);
                    > span {
                        float: left;
                        width: 100%;
                        margin: 10px 0px 0px 0px;
                        padding: 0px;
                        font-family: 'latoblack';
                        font-size: 19px;
                        color: $completeness-bar-text;
                        text-align: center;
                    }
                }

                .up-click-text {
                    position: absolute;
                    width: 100%;
                    color: #8f9ea9;
                    display: none;
                    font-size: 16px;
                    text-decoration: underline;
                    font-weight: normal;
                    margin: 14px 0px 0px 0px;
                }
                &:hover {
                    .up-completeness-percentage {
                        display: none;
                    }
                    .up-click-text {
                        display: block;
                    }
                }
            }
            > span {
                float: left;
                width: 100%;
                margin: 20px 0px 0px 0px;
                padding: 0px;
                text-align: center;
                font-family: 'latoregular';
                font-size: 13px;
                color: $up-subtitle-color;
            }

        } /* User profile completeness widget ends here */
        .up-investment-widget {
            float: left;
            width: 100%;
            min-height: 140px;
            margin: 0px 0px 20px 0px;
            padding: 0px;
            background-color: $main-columns-bg;
            text-align: center;
            @include mquery(960px) {
                width: 49%;
                float: right;
            }
            @include mquery(650px) {
                width: 100%;
            }
            > span {
                float: left;
                width: 100%;
                margin: 38px 0px 0px 0px;
                padding: 0px;
                font-family: 'latoblack';
                font-size: 32px;
                color: $default-text-color;
                text-align: center;
            }
            > p {
                float: left;
                width: 100%;
                margin: 20px 0px 0px 0px;
                padding: 0px;
                text-align: center;
                font-family: 'latoregular';
                font-size: 13px;
                color: $up-subtitle-color;
            }
        }
    }
}
.syndicate-profile-maincont {
    .up-rightcol {
        padding: 0px;
        > .default-rightcol-widget:first-of-type {
            margin-top: 0px;
        }
    }
    .up-tabs-cont {
        .up-tabs {
            > li {
                @include border(right, 1px, solid, $white-border !important);
                &:last-of-type {
                    border: 0px;
                }
            }
        }
    }
}
.syndicate-profile-team-section {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    @include mquery(660px) {
        //padding: 20px;
    }
    .sp-team-section-block {
        float: left;
        width: 50%;
        margin: 0px;
        padding: 10px;
        @include mquery(1023px) {
            width: 50%;
        }
        @include mquery(660px) {
            width: 100%;
            padding: 10px 0px;
        }
        > div {
            float: left;
            width: 100%;
            min-height: 95px;
            margin: 0px;
            padding: 0px 0px 0px 95px;
            position: relative;
            background-color: $cs-cr-rb-bg-color;
            @include border(full, 1px, solid, $cs-cr-rb-border-color);
            > img {
                position: absolute;
                top: 17px;
                left: 15px;
                margin: 0px;
                padding: 0px;
                width: 78px;
                height: 78px;
                border-radius: 40px;
                @include border(full, 4px, solid, $cs-cr-rb-imgBorder-color);
            }
            > div {
                float: left;
                width: 100%;
                height: 100%;
                min-height: 110px;
                margin: 0px;
                padding: 15px;
                position: relative;
                > span {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    text-align: right;
                    font-family: 'latobold';
                    font-size: 14px;
                    color: $default-text-color;
                    text-transform: uppercase;
                    line-height: 18px;
                }
                > p {
                    float: left;
                    width: 100%;
                    margin: 10px 0px 10px 0px;
                    text-align: right;
                    font-family: 'latolight';
                    font-size: 13px;
                    color: $default-text-color;
                }
                > a {
                    margin: 0px 0px 0px 10px;
                    padding: 7px 35px 5px 10px;
                    min-height: 26px;
                    font-family: 'latoblack';
                    font-size: 11px;
                    text-transform: uppercase;
                    color: #469edc;
                    text-decoration: none;
                    background-image: url(../../images/blue-button-arrow.png);
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                    border-style: solid;
                    border-color: #469edc;
                    border-width: 1px;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    margin-top: 10px;
                    float: right;
                    &:hover {
                        background-position: right 7px center;
                    }
                }
            }
            .od-team-section-block-remove {
                position: absolute;
                top: 5px;
                left: 5px;
                width: 12px;
                height: 12px;
                background-image: url(../../images/cr-result-block-remove.png);
                background-repeat: no-repeat;
                background-position: 0px 0px;
            }
        }

        .sp-team-section-single {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            h5 {
                font-family: 'latoregular';
                font-size: 16px;
                color: $default-text-color;
                text-transform: uppercase;
                line-height: 18px;
                text-align: right;
                // white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            a.see-profile-button {
                width: 120px;
                margin: 0;
            }
        }
    }
}


.up-add-portfolio-item {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 0px;

    .up-add-portfolio-item {
        select, input {
            &::placeholder {
                font-family: 'latolight';
                font-weight: 'normal';
            }
        }
    }
}
.up-api-inner {
    position: relative;
    width: 100%;
    margin: 0px;
    padding: 7px 60px 7px 10px;
    background-color: #f3f5f6;

    > div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin: 0px -2px;
        padding: 0px;
        @include mquery(600px) {
            width: 100%;
        }
        .up-api-formgroup {
            width: 100%;
            margin: 0px;
            padding: 10px;
            position:relative;
            > span {
                width: 100%;
                margin: 0px;
                padding: 0px;
                font-family: 'latoregular';
                font-size: 14px;
                color: #7e7e7e;
            }
            > input {
                @include border(full, 1px, solid, #dfe6eb);
                color: #7e7e7e;
                font-family: "latobold";
                font-size: 14px;
                height: 42px;
                margin: 9px 0 0;
                padding: 0 10px;
                width: 100%;
            }
            > span.select2 {
                margin-top:10px;
            }
            > span.error-tooltip {
                color: #ffffff;
                width: auto;
                padding: 5px 10px;
            }
        }
    }
    .up-api-add-item {
        background-color: #469edc;
        background-image: url("../../images/cert-upload-btn-icon.png");
        background-position: center center;
        background-repeat: no-repeat;
        border: 0 none;
        cursor: pointer;
        height: 40px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 20px;
        bottom: 18px;
        transition: all 0.3s ease 0s;
        width: 40px;
    }
}
.up-cert-tabs-container {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 0px;
    position: relative;
    .up-cert-tabs {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > li {
            float: left;
            width: 50%;
            margin: 0px;
            padding: 0px;
            list-style: none;
            @include border(right, 1px, solid, $white-border);
            &:last-of-type {
                border: 0px;
            }
            @include mquery(420px) {
                width: 100%;
                border-right: 0px;
                @include border(bottom, 1px, solid, $up-tabs-border);
            }
            > a {
                float: left;
                width: 100%;
                height: 35px;
                margin: 0px;
                padding: 9px 0px;
                text-decoration: none;
                font-family: 'latobold';
                font-size: 14px;
                color: $up-tabs-links-color;
                background-color: $up-tabs-bg;
                text-align: center;
                @include transition(.3s);
                &:hover, &.active {
                    background-color: $main-blue-link-hover;
                }
            }
        }
    }/* Certificates tabs UL ends here */
    .up-cert-ad-cont {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > span,
        .certificate-accaunt-spans{
            float: left;
            width: 100%;
            margin: 20px 0px 0px 0px;
            padding: 0px;
            font-family: 'latoregular';
            font-size: 14px;
            color: $default-text-color;
            line-height: 18px;
        }
        .form-groups-wrapper {
            margin-top: 20px;
        }
    } /* Certificates tabs accountants details content ends here */
    .up-cert-mu-cont {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: none;

    } /* Certificates manual upload container ends here */
}

.upload-doc {
    width: 120px;
    height: 40px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    background-color: $default-blue-color;
    border: 0px;
    float: right;
    color: #fff;
    font-size: 14px;
    margin: 10px;
    text-transform: uppercase;
    @include transition(.3s);
    &:hover {
        background-color: lighten($default-blue-color, 5%);
    }
    @include mquery(580px) {
        top: 1px;
    }
}

/* Certificate tabs container ends here */
.up-cert-mu-filegroup .sfg-field {
    padding: 0px 60px 0px 10px;
    > .upload-doc {
        width: 70px;
        height: 40px;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        background-color: $default-blue-color;
        background-position: center center;
        background-repeat: no-repeat;
        border: 0px;
        @include transition(.3s);
        &:hover {
            background-color: lighten($default-blue-color, 5%);
        }
        @include mquery(580px) {
            top: 1px;
        }
    }

    > button.default-documents {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        margin: 0px;
        padding: 0px;
        cursor: pointer;
        background-color: #469edc;
        background-position: center center;
        background-image: url(../../images/cert-upload-btn-icon.png);
        background-repeat: no-repeat;
        border: 0px;
        -webkit-transition: 0.3s;
        transition: 0.3s;
    }

}
.portfolio-user-role {
    font-size: 11px !important;
    font-family: 'latoregular' !important;
    margin-top: 5px !important;
}
.up-cert-file-blocks {
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    padding: 0px;
    position: relative;
    .up-cert-file-block {
        float: left;
        width: 49%;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        &:nth-child(-n+2) {
            margin-top: 0px;
        }
        @include mquery(650px) {
            padding: 0px !important;
            width: 100%;
            margin-top: 20px !important;
            &:first-of-type {
                margin-top: 0px !important;
            }
        }
        div {
            float: left;
            width: 100%;
            // min-height: 180px;
            margin: 0;
            // padding: 0;
            position: relative;
            background-color: $up-cr-block-bg;
            .up-cert-fileblock-titlebar {
                float: left;
                width: 100%;
                min-height: 40px;
                margin: 0px;
                padding: 0px 20px 0px 40px;
                position: relative;
                > span {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 40px;
                    height: 40px;
                    background-color: $cert-file-default-bg;
                    background-repeat: no-repeat;
                    background-position: center center;
                    &.text-file {
                        background-color: $cert-file-pdf-bg;
                        background-image: url(../../images/cert-file-icon.png);
                        &.docx-file {
                            background-color: $cert-file-text-bg;
                            background-image: url(../../images/cert-text-file-icon.png);
                        }
                        &.pdf-file {
                            background-color: $cert-file-pdf-bg;
                            background-image: url(../../images/cert-pdf-file-icon.png);
                        }
                        &.doc-file {
                            background-color: $cert-file-text-bg;
                            background-image: url(../../images/cert-text-file-icon.png);
                        }
                    }
                }
                > p {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 11px 0px 11px 15px;
                    font-family: 'latobold';
                    font-size: 18px;
                    color: $default-text-color;
                }
            } /* Certificate file titlebar */
            > p {
                float: left;
                width: 100%;
                margin: 15px 0px 0px 0px;
                padding: 0px 15px 50px;
                font-family: 'latolight';
                font-size: 14px;
                color: $default-text-color;
                line-height: 18px;
            }
            .cert-file-download-btn {
                position: absolute;
                bottom: 15px;
                right: 15px;
                margin: 0px;
                padding: 7px 40px 7px 10px;
                height: 26px;
                color: $cert-file-downld-btn-color;
                text-decoration: none;
                font-family: 'latobold';
                font-size: 10px;
                text-transform: uppercase;
                text-align: left;
                background-image: url(../../images/cert-file-download-btn-icon.png);
                background-repeat: no-repeat;
                background-position: right 10px center;
                @include border(full, 1px, solid, $cert-file-downld-btn-color);
                @include transition(.3s);
                &:hover {
                    background-position: right 10px top 6px;
                }
            }
            .cert-file-remove-btn {
                position: absolute;
                bottom: 15px;
                right: 15px;
                margin: 0px;
                padding: 7px 40px 7px 10px;
                height: 26px;
                color: #EA7483;
                text-decoration: none;
                font-family: 'latobold';
                font-size: 10px;
                text-transform: uppercase;
                text-align: left;
                background-image: url(../../images/cert-file-remove-btn-icon.png);
                background-repeat: no-repeat;
                background-position: right 10px center;
                @include border(full, 1px, solid, #EA7483);
                @include transition(.3s);
                &:hover {
                    background-position: right 7px center;
                }
            }
        }
    }
}
.default-rightcol-widget {
    float: left;
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding: 0px;
    position: relative;
    @include mquery(960px) {
        width: 49%;
    }
    @include mquery(650px) {
        width: 100%;
    }
    &.right {
        @include mquery(960px) {
            float: right;
            width: 49%;
        }
        @include mquery(650px) {
            width: 100%;
        }
    }
    // Latest platform investment
    &.latest-ptf-invest-widget {
        .up-li-person-block {
            > div {
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 10px;
                background-color: transparent;
                a {
                    float: left;
                    font-family: 'latobold';
                    font-size: 14px;
                    color: $default-text-color;
                    @include transition(.3s);
                    &:hover{
                        color: $main-blue-link-hover;
                    }
                }
                .bold{
                    font-family: 'latobold';
                    display: inline;
                }
                > .title{
                    float: left;
                    width: 100%;
                    min-height: 30px;
                    float: left;
                    font-family: 'latobold';
                    font-size: 14px;
                    color: $default-text-color;
                    &.status{
                        text-align: right;
                    }
                }
                > .invest-from{
                    float: left;
                    width: 100%;
                    color: $default-text-color;
                    font-size: 12px;
                    font-family: 'latoregular';
                    text-align: left;
                    padding: 10px 0 0;
                    a {
                        display: inline;
                        float: none;
                        font-size: 12px;
                        margin-right: 3px;
                    }
                }
                > .investment {
                    float: left;
                    font-family: 'latoregular';
                    font-size: 14px;
                    color: $txt-green-color;
                    .bold{
                        font-size: 16px;
                        padding-left: 2px;
                    }
                }
            }
        }
    }
    // Latest platform investment - end
    // People like
    &.people-like-widget{
        // .drw-content{
        //     min-height: auto;
        // }
        .up-ply-person-block{
            > div{
                background-color: transparent;
                padding: 0 0 0 10px;
                span,
                p{
                    text-align: left;
                }
                .user-profile-title{
                    font-family: 'latoregular';
                    font-size: 12px;
                }
            }
        }
    }
    // People like - end
    // View links style
    .view-link{
        float: none;
        width: 93px;
        margin: 10px 0 0 0;
        padding: 0px 0px 0px 5px;;
        height: 21px;
        line-height: 20px;
        font-family: 'latolight' !important;
        font-size: 9px !important;
        text-transform: uppercase;
        color: $default-blue-color !important;
        text-decoration: none;
        background-image: url('../../images/blue-button-arrow.png');
        background-repeat: no-repeat;
        background-position: right 5px center;
        @include border(full, 1px, solid, $default-blue-color);
        @include transition(.3s);
        &:hover {
            background-position: right 3px center;
        }
        &.investor,
        &.profile{
            float: left !important;
        }
        &.offer{
            float: right !important;
        }
    }
    // View links style - end
    .drw-title {
        float: left;
        width: 100%;
        height: 40px;
        margin: 0px;
        padding: 11px 15px;
        background-color: $drw-title-bg;
        > span {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 0px;
            font-family: 'latoregular';
            font-size: 16px;
            color: $up-tabs-links-color;
        }
    }
    .drw-content {
        float: left;
        width: 100%;
        min-height: 120px;
        margin: 0px;
        padding: 10px;
        position: relative;
        background-color: $main-columns-bg;
    }
}
.up-li-person-block {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px 0px 0px 100px;
    position: relative;
    > img {
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        width: 100px;
        height: 100px;
    }
    > div {
        float: left;
        width: 100%;
        min-height: 100px;
        margin: 0px;
        padding: 10px 15px;
        background-color: $up-last-investment-block-bg;
        > span {
            float: left;
            width: 100%;
            margin: 5px 0px 0px 0px;
            padding: 0px;
            text-align: right;
            &:first-of-type {
                margin-top: 0px;
            }
            &:nth-child(1), &:nth-child(4) {
                font-family: 'latoregular';
                font-size: 12px;
                color: $default-text-color;
            }
            &:nth-child(2) {
                font-family: 'latoblack';
                font-size: 25px;
                color: $up-li-sum-color;
            }
            &:nth-child(3) {
                font-family: 'latobold';
                font-size: 14px;
                color: $default-text-color;
            }
        }
    }
}
.up-ply-person-block {
    float: left;
    width: 100%;
    min-height: 60px;
    margin: 15px 0px 0px 0px;
    padding: 0px 0px 0px 60px;
    position: relative;
    &:first-of-type {
        margin-top: 0px;
    }
    > img {
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 0px;
        padding: 0px;
        width: 60px;
        height: 60px;
    }
    > div {
        float: left;
        width: 100%;
        min-height: 60px;
        margin: 0px;
        padding: 10px 15px;
        background-color: $up-last-investment-block-bg;
        > span {
            float: left;
            width: 100%;
            margin: 3px 0px 0px 0px;
            padding: 0px;
            text-align: right;
            font-family: 'latobold';
            font-size: 14px;
            color: $default-text-color;
        }
        > p {
            float: left;
            width: 100%;
            margin: 5px 0px 0px 0px;
            padding: 0px;
            text-align: right;
            font-family: 'latoregular';
            font-size: 12px;
            color: $default-text-color;
        }
    }
}

// This should probably have it's own scss file
// card.scss

.card {
    padding: 20px;
    min-height: 135px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #7e7e7e;

    @include mquery(560px) {
        min-height: 160px;
        padding: 20px 15px 50px;
    }
    .cr-project-invested{
        @include mquery(560px) {
            margin: 0;
            padding: 0;
        }
        @include mquery(380px) {
        }
        span {
            @include mquery(380px){
                font-size: 12px;
            }
        }
        p {
            @include mquery(380px){
                font-size: 10px;
            }
        }
    }

    h4 {
        font-family: 'latoregular';
        font-size: 18px;
        line-height: 27px;

        span {
            font-size: 14px;
        }
    }

    .card-description {
        p {
            font-size: 14px;
            line-height: 21px;
        }
    }

    .see-offer-button {
        display: inline-block;
        margin: 0;
        padding: 7px 35px 5px 10px;
        min-height: 26px;
        font-family: 'latoblack';
        font-size: 11px;
        text-transform: uppercase;
        color: #469edc;
        text-decoration: none;
        background-image: url(../../images/blue-button-arrow.png);
        background-repeat: no-repeat;
        background-position: right 10px center;
        border-style: solid;
        border-color: #469edc;
        border-width: 1px;
        transition: 0.3s;
        width: 145px;
        margin-left: auto;
    }

    .single-cr-fo-buttons{
        @include mquery(560px) {
            padding: 0;
            width: auto;
        }
        .cr-fo-see-offer-btn{
            @include mquery(560px){
                padding: 6px 20px 5px 10px;
                margin: 0 0 0 10px;
            }
        }
    }
}

span.text-file {
    background-color: $cert-file-pdf-bg;
    background-image: url(../../images/cert-file-icon.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    height: 32px;
    display: inline-block;
}

.file-upload-card {
    display: flex;
    flex-direction: row;
    background-color: #f3f5f6;

    .file-info {
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 100%;

        h4 {
            font-size: 16px;
            line-height: 24px;
            font-family: "latoregular";
            color: #7e7e7e;
        }

        a.file-download-button {
            display: inline-block;
            margin: 0px;
            padding: 7px 40px 7px 10px;
            height: 26px;
            color: #61a0cb;
            text-decoration: none;
            font-family: 'latobold';
            font-size: 10px;
            text-transform: uppercase;
            text-align: left;
            background-image: url(../../images/cert-file-download-btn-icon.png);
            background-repeat: no-repeat;
            background-position: right 10px center;
            border-style: solid;
            border-color: #61a0cb;
            border-width: 1px;
            transition: 0.3s;
            margin-left: auto;
        }
    }
}

@media (max-width: 250px) {
    .investor {
        float: right;
    }
}

.investment-table {
    margin-top: 20px;
    color: #7e7e7e;

    th {
        background-color: #8f9ea9;
        color: #fff;
        font-family: latoregular;
    }

    .download-ia-icon {
        color: #b9d879;

        &:hover {
            cursor: pointer;
        }
    }
}
