/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
QUESTIONAIRE PAGE
---------------------------------------------------------- */
#main-container.questionaire-main-container {
  text-align: center;
}

@media all and (max-width: 890px) {
  #main-container.questionaire-main-container {
    padding-bottom: 10px !important;
  }
}

.questionaire-wrapper {
  width: 100%;
  max-width: 980px;
  display: inline-block;
  margin: 60px 0px 0px 0px;
  padding: 20px;
  position: relative;
  background-color: #ffffff;
}

@media all and (max-width: 890px) {
  .questionaire-wrapper {
    margin-top: 10px;
  }
}

.questionaire-wrapper .default-success-tooltip {
  margin-top: 0px;
  margin-bottom: 20px;
}

.q-form-group-wrapper {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 0px;
  position: relative;
}

.q-form-group-wrapper > form {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.q-form-group {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 10px 0px 0px 0px;
  padding: 9px 355px 9px 15px;
  position: relative;
  background-color: #f3f5f6;
  display: table;
  height: 60px;
}

@media all and (max-width: 650px) {
  .q-form-group {
    padding: 15px;
    height: auto;
  }
}

.q-form-group:first-of-type {
  margin-top: 0px;
}

.q-form-group .q-form-question {
  position: absolute;
  width: 100%;
  background: white none repeat scroll 0% 0%;
  z-index: 2;
  top: 0px;
  left: 0px;
  height: 60px;
  opacity: 0.6;
}

.q-form-group .q-form-group-hidden {
  height: 100% !important;
}

.q-form-group .q-form-group-title {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0px;
  padding: 0 40px 0 0;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@media all and (max-width: 650px) {
  .q-form-group .q-form-group-title {
    display: block;
    padding-right: 25px;
  }
}

.q-form-group .q-form-group-title .cp-form-helper {
  top: 50%;
  margin-top: -8px;
}

@media all and (max-width: 890px) {
  .q-form-group .q-form-group-title .cp-form-helper {
    right: 0;
  }
}

@media all and (max-width: 650px) {
  .q-form-group .q-form-group-title .cp-form-helper {
    right: 1px;
    left: auto;
    top: 0;
    margin: 0 0 0 10px;
  }
}

.q-form-group .q-fg-input-cont {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 100%;
  max-width: 335px;
  /* sfg file input ends here */
}

@media all and (max-width: 650px) {
  .q-form-group .q-fg-input-cont {
    float: left;
    margin-top: 10px;
    max-width: 100%;
    position: relative;
    right: auto;
    top: auto;
    text-align: center;
  }
}

.q-form-group .q-fg-input-cont > input[type="text"], .q-form-group .q-fg-input-cont > input[type="password"], .q-form-group .q-fg-input-cont textarea {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 0px 10px;
  font-family: 'latoregular';
  font-size: 14px;
}

.q-form-group .q-fg-input-cont textarea[name=description] {
  padding: 10px;
}

.q-form-group .q-fg-input-cont .q-fg-select-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.q-form-group .q-fg-input-cont .q-fg-select-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/select-field-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.q-form-group .q-fg-input-cont .q-fg-select-field > select {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px 1px;
  margin: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
}

@media all and (max-width: 580px) {
  .q-form-group .q-fg-input-cont .q-fg-select-field {
    margin: 0px;
  }
}

.q-form-group .q-fg-input-cont .q-fg-radiobutton-cont {
  float: right;
  padding: 9px 10px;
}

@media all and (max-width: 650px) {
  .q-form-group .q-fg-input-cont .q-fg-radiobutton-cont {
    float: none;
    display: inline-block;
  }
}

.q-form-group .q-fg-input-cont .q-fg-file-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.q-form-group .q-fg-input-cont .q-fg-file-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/file-field-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.q-form-group .q-fg-input-cont .q-fg-file-field > input {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px;
  margin: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  text-indent: -99999px;
  opacity: 0;
  cursor: pointer;
}

.q-form-group .q-fg-input-cont .q-fg-file-field > span {
  position: absolute;
  top: 12px;
  left: 0px;
  margin: 0px;
  padding: 0px 42px 3px 15px;
  width: 100%;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media all and (max-width: 580px) {
  .q-form-group .q-fg-input-cont .q-fg-file-field {
    margin: 0px;
  }
}

ul.custom-autocomplete {
  width: 420px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow: hidden;
}

ul.custom-autocomplete li {
  width: 100%;
  display: inline-block;
  padding: 7px;
  font-size: 14px;
  border-bottom: 2px solid #ededed;
}

ul.custom-autocomplete li a {
  display: inline-block;
  width: 100%;
  font-family: "latoregular";
  color: #469edc;
}

ul.custom-autocomplete li a:hover {
  color: #5ba9e0;
}

ul.custom-autocomplete > li > .no-results-autocomplete {
  display: inline-block;
  width: 100%;
  color: #7cbee5;
  font-family: "latoregular";
  font-size: 15px;
  padding: 10px 0 0;
  height: 30px;
}

.autocomplete-pr-image {
  display: inline-block;
  width: 40px;
  height: 30px;
}

.autocomplete-pr-name {
  display: inline-block;
  font-family: "latoregular";
  font-size: 13px;
  overflow: hidden;
  padding: 8px 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: #469edc;
}

.autocomplete-pr-name:hover {
  text-decoration: underline;
  color: #5ba9e0;
}

.simple-row-name {
  width: 380px;
}

li.add_new_wbi a:hover {
  text-decoration: underline;
}

ul.custom-autocomplete {
  left: auto;
}

.new-company-autocomplete {
  color: #7cbee5;
  display: inline-block;
  font-family: "latoregular";
  padding: 5px;
  font-size: 14px;
  color: #7e7e7e;
}

.new-company-autocomplete > a {
  color: #469edc !important;
}

.new-company-autocomplete > a:hover {
  color: #5ba9e0;
  text-decoration: underline;
}

ul.custom-autocomplete > li > span.new-company-autocomplete > a {
  display: inline;
  padding: 0;
}

.company-autocomplete > li > a.ui-corner-all > button {
  background-color: #469edc;
  border: 0 none;
  color: #fff;
  float: right;
  font-family: "latoregular";
  font-size: 12px;
  height: 30px;
  cursor: pointer;
}

.company-autocomplete > li > a.ui-corner-all > button:hover {
  background-color: #5ba9e0;
}

.loading-spinner {
  display: inline-block;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 12px;
  vertical-align: middle;
  width: 16px;
}

.spinner-smaller-input {
  right: 20px;
  top: 20px;
}

#loading.isloading {
  background-image: url("/images/loading-spinner.gif");
}

.sfg-select-field .loading-spinner {
  display: block;
  z-index: 10 !important;
}

@media all and (max-width: 768px) {
  #ui-id-1 {
    width: 333px;
  }
}

@media all and (max-width: 415px) {
  #ui-id-1 {
    width: 322px;
  }
}

@media all and (max-width: 376px) {
  #ui-id-1 {
    width: 284px;
  }
}

@media all and (max-width: 360px) {
  #ui-id-1 {
    width: 268px;
  }
}
