@import 'extensions/variables';
@import 'extensions/mixins';



#choovie-tp-banner {
  background-image: url(/images/landing-pages/choovie-bk.jpg);
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  position: relative;
  display: table;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  // padding-bottom: 20%;
  @include mquery(1440px) {
    background-image: url(/images/landing-pages/choovie-artboard.jpg);
  }
  @include mquery(736px) {
    display: block;
  }
  .choovie-landing-page-content-container {
    padding-top: 50px;
    @include mquery(736px) {
      padding-top: 20px;
    }

    #choovie-top-header {
      font-family: 'MuseoSansRounded-1000', Arial, sans-serif !important;
      font-size: 40px;
      text-align: center;
      letter-spacing: 1px;
      line-height: 40px;
      max-width: 800px;
      margin: 0 auto 110px auto;
      @include mquery(736px) {
        font-size: 24px;
        margin: 0 auto 15px auto;
        line-height: 27px;
      }
      @include mquery(320px) {
        font-size: 20px;

      }

    }

    #choovie-resgister-subheader {
      font-size: 32px;
      text-align: center;
      letter-spacing: 1px;
      max-width: 800px;
      margin: 0 auto 30px auto;
      @include mquery(736px) {
        font-size: 24px;
      }
      @include mquery(320px) {
        font-size: 20px;

      }
    }
  }
  .choovie-head-logo-cnt {
    text-align: center;
    margin-bottom: 20px;
    @include mquery(736px) {
      margin-bottom: 10px;
    }
    .choovie-head-logo {
      @include mquery(736px) {
        width: 300px;
      }
    }
  }


  .choovie-mobile {
    display: block;
    margin: auto;
  }


  .choovie-button-style {
    border: 1px solid #fff;
    color: #fff;
    font-size: 18px;
    transition: all 0.5s ease;
  }
  .choovie-button-style:hover {
    color: white;
    box-shadow: 1px 2px 4px rgba(1, 1, 1, .5);
  }

  ::placeholder {
    color: white;
  }
}
#Choovie-content-section {
  .choovie-content-cnt {
    display: flex;
    overflow: hidden;
  }
  .choovie-content-logo {
    width: 400px;
    display: block;
    margin: auto;
  }
  .choovie-eggplant-bk-color {
        background-color: #2E2B3D;
        padding: 80px 30px;
        flex: 1;
        order: 0;
        @include mquery(736px) {
          padding: 80px 15px;
        }


        .choovie-link {
          text-decoration: underline;
          color: white;
          cursor: pointer;
        }
        .choovie-link:hover {
          color: #F05030;
          text-decoration: underline;
          cursor: pointer;
        }

  }
  .choovie-orange-bk-color {
    background-color: #F05030;
    background-image: url(/images/landing-pages/choovie-banner-3.jpg);
    background-size: cover;
    background-position: center;
    padding: 200px 30px;
    flex: 1;
    order:1;
    display: flex;
    align-items: center;
    justify-content: center;
    .choovie-orange-bk-content-cnt {

    }

    .choovie-content-title {
       text-align: center;
       font-family: 'latoregular';
       font-size: 40px;
       margin-bottom: 34px;
       line-height: 50px;
       max-width: 707px;
       display: block;
       margin: auto;
       margin-top: 50px;
    }
  }

}


.choovie-eggplant-bk {
  background-color: #2E2B3D;
}
.choovie-orange-bk {
  background-color: #F05030;
}


.choovie-eggplant-color {
  color: #2E2B3D;
}



.choovie-orange-color {
  color: #F05030;
}

.choovie-link-white {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
.choovie-link-black:hover {

  color: black;
  cursor: pointer;
}

.choovie-justify {
  text-align: justify;
  text-justify:distribute;
}


.choovie-view-offer-btn {
  color: #fff;
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: rgba(1,1,1,.8);
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.choovie-view-offer-btn:hover {
  background-color: #F05030;
  color: white;
}
