/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
.greenid-form-groups-wrapper {
  margin: 30px 0 0 0;
  position: relative;
}

.greenid-form-groups-wrapper .greenId-row-margin {
  margin-top: 20px;
}

.greenid-form-groups-wrapper .greenId-form-group {
  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  position: relative;
  padding: 22px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #8da3b1;
  cursor: pointer;
}

.greenid-form-groups-wrapper .greenId-form-group .greenId-list-header {
  font-family: 'latobold';
  padding-bottom: 10px;
}

.greenid-form-groups-wrapper .greenId-form-group:hover {
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
}

.greenid-form-groups-normal {
  background-color: #f3f5f6;
}

.greenid-form-groups-success {
  background-color: #d4edda;
  cursor: default;
}

.greenid-form-groups-error {
  background-color: #f8d7da;
  cursor: default;
}

.greenId-form-group-cnt {
  margin-bottom: 20px;
}

.greenId-each-sec-ins {
  background-color: #fff;
  padding: 20px;
  line-height: 1.4;
  color: #6f8899;
  margin-bottom: 10px;
}

.greenId-each-sec-header {
  font-family: latoregular;
  font-size: 20px;
  color: #6f8899;
  margin-bottom: 20px;
}

.greenId-field-required {
  color: red;
}

.greenId-field-required {
  font-size: 12px;
  margin: 20px 0;
}

.greenId-select-cert-header {
  margin: 20px 0;
}

.greenId-mandatory-text {
  margin: 20px 0;
  font-size: 12px;
}

.certLink {
  width: 100%;
  height: 206px;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
}

.certLink-label {
  font-size: 12px;
  padding: 10px;
  text-align: center;
  background-color: #6c8697;
  color: #fff;
  height: 44px;
}

.greenId-filterDiv {
  display: none;
}

.greenId-filterDiv-show {
  display: block !important;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filter-greenId-source {
  display: none;
}

.greenId-source-list-active {
  background-color: #fff !important;
  border: 1px solid #f3f5f6 !important;
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1) !important;
}

.greenId-1 {
  background: url(/images/select-citizenshipcertificatedvs-1.jpg) no-repeat center center;
}

.greenId-2 {
  background: url(/images/select-citizenshipcertificatedvs-2.jpg) no-repeat center center;
}

.greenId-3 {
  background: url(/images/select-citizenshipcertificatedvs-3.jpg) no-repeat center center;
}

.greenId-4 {
  background: url(/images/select-citizenshipcertificatedvs-4.jpg) no-repeat center center;
}

.greenId-5 {
  background: url(/images/select-citizenshipcertificatedvs-other.png) no-repeat center center;
}

.chooseMedicareSelect {
  width: 100%;
  height: 100px;
  padding: 20px;
  margin: 40px 0;
}

#creditHistory-title {
  margin: 30px 0;
  line-height: 1.3;
}

#creditHistory-desc {
  padding: 20px;
  border: 1px solid #ccc;
  line-height: 1.3;
}

.greenId-uploadDoc-desc {
  padding: 20px;
  border: 1px solid #ccc;
  line-height: 1.3;
  margin: 30px 0;
}

.greenId-uploadDoc-header {
  margin: 0 0 20px;
}

.greenId-uploadDoc-number {
  font-size: 80px;
  color: #6c8697;
}

.greenId-submit-btn-cnt {
  margin: 40px 0 10px;
  float: right;
}

.greenId-default-source {
  padding: 20px;
  background: #ffffcc;
  border: #FC0 1px solid;
}

@media all and (max-width: 1024px) {
  .greenId-labels {
    margin: 15px 0;
  }
}

.greenId-certificateShow {
  display: block;
}

.greenId-certificateHide {
  display: none;
}

.hideReturnBtn {
  display: none !important;
}
