@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';

.mn-landing-page-content {
  width: 100%;
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
  @include mquery(1024px) {
    text-align: center;
  }
}

.mn-p-width {
  max-width: 780px;
  margin: auto;
}


.mn-landing-page-content-container {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 700px;
  // @include mquery(768px) {
  //   padding-top: 0;
  // }

}


.mn-small-risk-warning-note {
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  max-width: 391px;
  display: block;
  margin: 20px auto;
  @include mquery(1024px) {
    text-align: center;
  }
  @include mquery(320px) {
    font-size: 13px;
  }
}


.mn-landing-page-laptop {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
  margin-top: 34px;

  .mn-landing-page-screen {
    box-sizing: border-box;
    background: url(/images/laptop-mockup-sm.png) center center no-repeat;
    background-size: contain;
    padding: 9.4% 11.9% 12.8%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media screen
      and (min-device-width: 1200px)
      and (max-device-width: 1600px)  {
        padding-top: 10.4%;
      }
    @include mquery(736px) {
    padding-top: 11.9%;
    }
  }
}

.my-return-thanks-registering {
text-align: center;
font-family: 'latoregular';
font-size: 40px;
margin-bottom: 34px;
@include mquery(736px) {
  font-size: 28px;

}
@include mquery(320px) {
  font-size: 24px;

}
}

.my-return-message-subscription {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 20px;
  text-align: center;
  max-width: 780px;
  margin: auto;
  line-height: 1.5;
  @include mquery(600px) {
    font-size: 19px;

  }
}

.mn-justify-content {
  align-items: center;
  justify-content: center;
  display: flex;
}


.landing-page-flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.landing-page-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  .landing-page-form-name {
    padding: 10px;
    border-radius: 4px;
    margin-right: 20px;
    font-weight: 400;
    height: 50px;
    border: none;
    margin-bottom: 10px;
    width: 192px;
    @include mquery(736px){
      width: 105px;
    }
    @include mquery(320px){
      width: 95px;
    }
  }
  .landing-page-form-email {
    padding: 10px;
    border-radius: 4px;
    font-weight: 400;
    height: 50px;
    border: none;
    margin-bottom: 10px;
    width: 197px;
    @include mquery(320px){
      width: 175px;
    }
  }
  .hidden {
    display: none;
  }
  .landing-page-form-register {
    width: 100%;
    color: white;
    margin-bottom: 20px;
    border-radius: 4px;
    height: 49px;
    font-size: 18px;
    font-family: latoregular;
    @include mquery(736px){
      width: 324px;
    }
    @include mquery(320px){
      width: 100%;
    }
  }

  .landingpage-button-border {
    background-color: transparent;
    color: white;
    border: 1px solid #e8e8e8;
  }

  .landingpage-button-border-black {
    background-color: transparent;
    color: white;
    border: 1px solid #333;
  }
}


.landing-page-title-width {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.landing-page-register-title {
    font-family: latolight;
    font-size: 36px;
    margin-bottom: 34px;
    line-height: 2.7rem;
    @include mquery(736px) {
      font-size: 28px;
    }
}


.input-border-black {
  border: 1px solid rgba(1, 1, 1, 0.2) !important;
}



.register-form-last-has-3 {
  display: flex;
  flex-direction: row;
  @include mquery(1024px) {
    flex-wrap: wrap;
  }
  .img-sec-1 {
    background-size: cover;
    width: 34%;
    height: 460px;
    @include mquery(1024px) {
      width: 50%;
    }
    @include mquery(600px) {
      height: 180;
    }
  }
  .form-sec-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 460px;
    width: 32%;
    @include mquery(1420px) {
      width: 52%;
    }
    @include mquery(1024px) {
      width: 100%;
      order: 3;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .img-sec-3 {
    background-size: cover;
    height: 460px;
    width: 34%;
    @include mquery(1024px) {
      width: 50%;
      order: 1;
    }
  }
}
.register-form-last-has-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #075f20;
  padding: 100px 0;
  @include mquery(600px) {
    padding: 50px 0;
  }
  .form-sec {
    max-width: 600px;
  }
}
.lp-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mquery(1024px) {
    display: block;
    margin: auto;
    text-align: center;
  }
}
.view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  transition: all 0.5s ease;
  animation: bounce 3s;
}

.lp-hero-title {
  font-family: 'latoregular';
  margin-bottom: 34px;
  line-height: 2.7rem;
  font-size: 40px;
  padding: 0 15px;
  @include mquery(1440px) {
    font-size: 38px;
  }
  @include mquery(600px) {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.lp-title-register {
  font-family: 'latolight';
  margin-bottom: 34px;
  line-height: 2.7rem;
  font-size: 36px;
  padding: 0 15px;
  @include mquery(1440px) {
    font-size: 32px;
  }
  @include mquery(600px) {
    font-size: 28px;
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                          //Landing page template 1//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////


#lp-hero-banner {
  @include top-banner('', #b7b7b7, '');
	@extend %background-property;
  #lp-hero-banner-layer {
    @include top-banner-layer(rgba(0,0,0,.6));
    display: flex;
    align-items: center;
    height: calc(100vh - 70px);
    padding: 0;
    .lp-logo {
      @include logo-style(40px, 300px, 250px);
    }
    .lp-hero-title-width {
      max-width: 600px;
      margin: 0 auto 40px;
    }
  }
}

.lp-button-style {
  background-color: #469edc;
  color: #fff;
  transition: all 300ms ease;
  &:hover {
    background-color: #469edc;
    color: #fff;
    opacity: .8;
  }
}

.lp-link {
  color: #469edc;
  &:hover {
    color: #469edc;
    text-decoration: underline;
  }
}


#lp-1-sec-1 {
  padding: 60px 0;
  .lp-1-sec-1-header {
    color: #333;
    font-size: 40px;
    letter-spacing: -0.91px;
    line-height: 1.2;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
    @include mquery(1200px) {
      text-align: center;
    }
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
  .lp-1-sec-1-copy {
    color: #333;
    font-size: 18px;
    letter-spacing: -0.41px;
    line-height: 1.5;
    margin-bottom: 65px;
  }

  .lp-1-sec-1-stats-cnt {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 44px;
    .lp-1-sec-1-stats-circle {
      width: 122px;
      height: 122px;
      margin: 0 auto 40px;
      background-color: #fff;
      border-radius: 100%;
    }
    .lp-1-sec-1-stats-header {
      color: #fff;
      font-size: 40px;
      letter-spacing: -0.91px;
      line-height: 1.2;
      margin-bottom: 42px;
      @include mquery (768px) {
        font-size: 30px;
      }
      @include mquery (600px) {
        font-size: 26px;
      }
    }
    .lp-1-sec-1-stats-copy {
      letter-spacing: -0.41px;
      line-height: 1.4;
      color: #fff;
      max-width: 300px;
      margin: 0 auto;
      @include mquery(1200px) {
        margin-bottom: 40px;
      }

    }
  }
}

#lp-1-sec-2 {
  padding: 30px 0 60px;
  @include mquery(1200px) {
      padding: 60px 0;
    }
  @include mquery(768px) {
      padding: 0 0 60px;
  }
  .lp-1-sec-2-img {
      margin: 0 0 40px;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 309px;
      @extend %background-property;
      .img-1 {
        background-image: url('');
      }
      .img-2 {
        background-image: url('');
      }
  }
  .lp-1-sec-2-img {
      margin-bottom: 40px;
  }
  .lp-1-sec-2-left-col-cnt {
    @include mquery(1200px) {
      margin-bottom: 60px;
    }
  }
}

.lp-view-offer-button {
  border-radius: 4px;
  height: 52px;
  width: 250px;
  color: #fff;
  font-size: 20px;
}

.lp-content {
  width: 100%;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  @include mquery(1200px) {
      text-align: center;
  }
}


#lp-1-sec-3 {
  padding: 0 0 60px;
  .lp-1-sec-3-img {
      margin-bottom: 40px;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 500px;
      @extend %background-property;
      background-image: url('');
  }
}

#lp-paralax-sec {
  @include parallax-sec('', '');
  background-color: rgba(0, 0, 0, 0.6);
  @include mquery(768px) {
      background-attachment: initial;
  }
  .lp-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.4));
    @include mquery(1200px) { 
      padding: 60px 0;
   }
  }
}

#lp-last-form-cnt {
  padding: 60px 0;
  .lp-bottom-link {
    color: #469edc;
    &:hover {
      color: #469edc;
      text-decoration: underline;
    }
  }
  .lp-bottom-button-style {
    background-color: #469edc;
    color: #fff;
    transition: all 300ms ease;
    &:hover {
      background-color: #469edc;
      color: #fff;
      opacity: .8;
    }
  }
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                          //Landing page template 2//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

#lp-2-sec-1 {
  .lp-2-sec-1-half-img-cnt {
    padding: 0;
  }
  .lp-2-sec-1-half-img {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 700px;
    @extend %background-property;
    display: table;
    background-image: url('');
    @include mquery(1200px) {
      height: 350px;
    }
  }
  .lp-2-sec-1-content-cnt {
    width: 100%;
    height: 700px;
    max-width: 780px;
    margin: auto;
    padding: 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mquery(1200px) {
      max-width: 100%;
      padding: 60px 0;
      height: 100%;
    }
  }
  .lp-2-sec-1-change-order {
    @include mquery(1200px) {
      display: flex;
      flex-direction: column;
    }
    .lp-2-sec-1-order-1 {
      @include mquery(1200px) {
        order: 1;
      }
    }
    .lp-2-sec-1-order-0 {
      @include mquery(1200px) {
        order: 0;
      }
    }
  }
}








////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                          //Landing page template 3//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////


#lp-3-sec-1 {
  padding: 60px 0;
  .lp-3-sec-1-header {
    color: #333;
    font-size: 40px;
    letter-spacing: -0.91px;
    line-height: 1.2;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    @include mquery(1200px) {
      text-align: center;
    }
    @include mquery (768px) {
      font-size: 30px;
    }
    @include mquery (600px) {
      font-size: 26px;
    }
  }
}

#lp-3-sec-2 {
  background-color: rgba(0, 0, 0, 0.6);
  height: 430px;
  position: relative;
  @include mquery (1200px) {
    height: initial;
    padding: 60px 0;
  }
  .lp-3-sec-2-align-center {
    @extend %flex-center;
    @include mquery (1200px) {
      flex-direction: column;
    }
  }
  .lp-3-sec-2-header {
    font-size: 35px;
    letter-spacing: -0.8px;
    color: #fff;
    margin-bottom: 20px;
    @include mquery (768px) {
      font-size: 30px;
    }
  }
  .lp-3-sec-2-subheader {
    font-size: 21px;
    letter-spacing: -0.8px;
    color: #fff;
    line-height: 1.5;
    margin: auto;
    max-width: 639px;
    @include mquery (1024px) {
      margin: 0 auto 40px;
    }
  }
  .lp-3-sec-2-img-cnt {
    background-color: #cbcbcb;
    background-image: url('');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 430px;
  }
}

#lp-3-sec-3 {
  padding: 60px 0;
}

#lp-3-sec-4 {
  .lp-3-sec-4-img {
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 500px;
    background-image: url('');
    @include mquery(1200px) {
      margin-bottom: 0;
    }
    @include mquery(768px) {
      height: 210px;
    }
  }
}

#lp-3-sec-5{
  padding: 60px 0;
  .lp-3-sec-5-stats-circle {
    width: 122px;
    height: 122px;
    margin: 0 auto 40px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 100%;
    padding: 26px 10px 24px 10px;
  }
  .lp-3-sec-5-stats-copy {
    letter-spacing: -0.41px;
    line-height: 1.4;
    color: #333;
    @include mquery(767px) {
      margin-bottom:40px; 
    }
  }
}

#lp-3-sec-6 {
  padding: 60px 0;
  @include mquery(1200px) {
    padding: 0 0 60px;
  }
  .lp-3-sec-6-video-bk {
    background-image: url('');
    @extend %background-property;
    @extend %flex-center;
    background-color: rgba(0, 0, 0, 0.6);
    height: 440px;
    @include mquery(767px) {
      height: 210px;
    }
    .lp-3-sec-6-video-info-cnt {
      z-index: 1;
    }
    .lp-3-sec-6-video-btn {
      border: 1px solid #fff;
      color: #fff;
      padding: 10px 20px;
      transition: .3s;
      width: 120px;
      text-align: center;
    }
  }
  .lp-3-sec-6-content-cnt {
    padding: 0 50px;
    @include mquery(1200px) {
      padding: 0;
      margin-top: 60px;
    }
  }
}
