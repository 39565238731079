/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#mn-westwinds-tp-banner {
  height: 1080px;
  width: 100%;
}

@media only screen and (min-width: 736px) and (max-width: 1440px) {
  #mn-westwinds-tp-banner {
    height: 920px;
  }
}

@media all and (max-width: 735px) {
  #mn-westwinds-tp-banner {
    height: 670px;
  }
}

#mn-westwinds-tp-banner .mn-westwinds-layer {
  height: 100%;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  padding-bottom: 40px;
}

#mn-westwinds-tp-banner .mn-westwinds-top-content-ctn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#mn-westwinds-tp-banner .mn-westwinds-top-content-ctn .mn-westwinds-top-content-ctn-with-padding {
  padding-top: 50px;
}

@media all and (max-width: 1440px) {
  #mn-westwinds-tp-banner .mn-westwinds-top-content-ctn .mn-westwinds-top-content-ctn-with-padding {
    padding-top: 0;
  }
}

#mn-westwinds-tp-banner .mn-westwinds-header {
  font-family: 'Pathway Gothic One',"Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  color: #fff;
  letter-spacing: 13px;
  display: inline-block;
  position: relative;
  line-height: 64px;
  text-align: center;
}

@media all and (max-width: 735px) {
  #mn-westwinds-tp-banner .mn-westwinds-header {
    letter-spacing: 0;
    font-size: 24px;
    line-height: 39px;
  }
}

#mn-westwinds-tp-banner .mn-westwinds-subheader {
  font-size: 40px;
  color: #fff;
  line-height: 64px;
  text-align: center;
  margin: 40px auto;
  font-family: latoregular;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .mn-westwinds-subheader {
    font-size: 24px;
    line-height: 39px;
    letter-spacing: 0;
    margin-bottom: -20px;
  }
}

#mn-westwinds-tp-banner .mn-line-div {
  background-color: white;
  height: 3px;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .mn-line-div {
    display: none;
  }
}

#mn-westwinds-tp-banner .mn-westwinds-logo {
  max-width: 300px;
}

@media only screen and (min-width: 321px) and (max-width: 1280px) {
  #mn-westwinds-tp-banner .mn-westwinds-logo {
    max-width: 200px;
  }
}

@media all and (max-width: 320px) {
  #mn-westwinds-tp-banner .mn-westwinds-logo {
    width: 100px;
  }
}

#mn-westwinds-tp-banner .mn-bottom-line {
  background-color: white;
  height: 3px;
  margin-top: 60px;
  margin-bottom: 30px;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .mn-bottom-line {
    margin-top: 7px;
  }
}

#mn-westwinds-tp-banner .westwinds-form-field {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 410px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .westwinds-form-field {
    width: 320px;
  }
}

@media all and (max-width: 320px) {
  #mn-westwinds-tp-banner .westwinds-form-field {
    width: 270px;
  }
}

#mn-westwinds-tp-banner .westwinds-form-field #westwinds-name {
  max-width: 192px;
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .westwinds-form-field #westwinds-name {
    margin-bottom: 10px;
    width: 105px;
  }
}

#mn-westwinds-tp-banner .westwinds-form-field ::placeholder {
  color: white;
}

#mn-westwinds-tp-banner .westwinds-form-field #westwinds-email {
  max-width: 192px;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  height: 50px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

@media all and (max-width: 320px) {
  #mn-westwinds-tp-banner .westwinds-form-field #westwinds-email {
    max-width: 142px;
  }
}

#mn-westwinds-tp-banner .westwinds-form-field #westwinds-register {
  border: 2px solid #008cba;
  background-color: transparent;
  color: white;
  border-radius: 4px;
  width: 100%;
  height: 49px;
  background-color: #008cba;
  margin-top: 20px;
}

@media only screen and (min-width: 737px) and (max-width: 1280px) {
  #mn-westwinds-tp-banner .westwinds-form-field #westwinds-register {
    width: 100%;
  }
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .westwinds-form-field #westwinds-register {
    width: 100%;
    margin-top: 0;
  }
}

#mn-westwinds-tp-banner .westwinds-form-field #westwinds-register:hover {
  background-color: #0093c4;
  transform: translate(0.9);
}

#mn-westwinds-tp-banner .westwinds-form-field .wwg-view-offer-btn {
  border: 2px solid #008cba;
  color: white;
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #008cba;
  animation: bounce 3s;
}

#mn-westwinds-tp-banner .westwings-blue {
  color: #008cba;
}

#mn-westwinds-tp-banner .westwings-blue:hover {
  text-decoration: underline;
  color: #008cba;
}

#mn-westwinds-tp-banner .carousel {
  height: 100%;
}

#mn-westwinds-tp-banner .item {
  width: 100%;
  height: 100%;
  transition: opacity 1s;
}

#mn-westwinds-tp-banner .carousel-inner {
  height: 100%;
}

#mn-westwinds-tp-banner .carousel .one {
  background-image: url(/images/landing-pages/west-winds-gin-rocks-min.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #002d72;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .carousel .one {
    background-image: url(/images/landing-pages/west-winds-gin-rocks-min-mobile.jpg);
  }
}

#mn-westwinds-tp-banner .carousel .two {
  background-image: url(/images/landing-pages/west-winds-BottlesOnTheDeck.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #002d72;
}

@media all and (max-width: 736px) {
  #mn-westwinds-tp-banner .carousel .two {
    background-image: url(/images/landing-pages/west-winds-BottlesOnTheDeck-mobile-min.jpg);
  }
}

#mn-westwinds-tp-banner .carousel .three {
  background-image: url(/images/landing-pages/west-winds-tasmania-min.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #002d72;
}

@media all and (max-width: 1200px) {
  #mn-westwinds-tp-banner .carousel .three {
    background-image: url(/images/landing-pages/west-winds-tasmania-min-mobile.jpg);
  }
}

#mn-westwinds-content-section {
  position: relative;
  background-color: white;
}

#mn-westwinds-content-section .mn-content-rightside-img {
  width: 600px;
  margin: auto;
}

#mn-westwinds-content-section .mn-westwinds-justify-content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

#mn-westwinds-content-section .mn-westwinds-content-ctn {
  margin: 90px auto;
}

#mn-westwinds-content-section .mn-westwinds-content-ctn .mn-westwinds-content-header {
  font-size: 40px;
  margin-bottom: 2.5rem;
  font-family: latoregular;
}

@media all and (max-width: 1200px) {
  #mn-westwinds-content-section .mn-westwinds-content-ctn .mn-westwinds-content-header {
    text-align: center;
    font-size: 30px;
  }
}

#mn-westwinds-content-section .mn-westwinds-content-ctn .westwinds-link {
  color: #008cba;
}

#mn-westwinds-content-section .west-winds-bottle-padding-top {
  padding-top: 114px;
}

@media screen and (min-width: 1500px) {
  #mn-westwinds-content-section .west-winds-bottle-padding-top {
    padding-top: 36px;
  }
}

@media all and (max-width: 1200px) {
  #mn-westwinds-content-section .west-winds-bottle-padding-top {
    padding-top: 0;
  }
}

#mn-westwinds-content-section .carousel {
  height: 100%;
}

@media all and (max-width: 1200px) {
  #mn-westwinds-content-section .carousel {
    display: none;
  }
}

#mn-westwinds-content-section .item {
  width: 100%;
  height: 100%;
  transition: opacity 1s;
}

#mn-westwinds-content-section .carousel-inner {
  height: 100%;
}

.wwg-button-style {
  background-color: #008cba;
  border: 1px solid #008cba;
  color: #fff;
  font-size: 18px;
  transition: all 0.5s ease;
}

.wwg-button-style:hover {
  color: white;
  opacity: .7;
  border: 1px solid #008cba;
}
