/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#endeavour-top-banner {
  background-image: url(/images/landing-pages/endeavour-banner-min.jpg);
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #2e142c;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: table;
  position: relative;
}

#endeavour-top-banner .endeavour-layer {
  background-color: rgba(1, 1, 1, 0.5);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

#endeavour-top-banner .endeavour-logo {
  margin-bottom: 40px;
}

@media all and (max-width: 500px) {
  #endeavour-top-banner .endeavour-logo {
    width: 250px;
  }
}

.endeavour-link {
  color: #34ba00;
}

.endeavour-link:hover {
  color: #34ba00;
  text-decoration: underline;
}

.endeavour-button-style {
  background-color: #34ba00;
  border: 1px solid #34ba00;
  transition: all 300ms ease;
}

.endeavour-button-style:hover {
  background-color: #191919;
  color: #34ba00;
  border: 1px solid #191919;
}

.endeavour-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #34ba00;
  border: 1px solid #34ba00;
  color: #fff;
  transition: all 300ms ease;
  animation: bounce 3s;
}

.endeavour-view-offer-btn:hover {
  background-color: #191919;
  color: #34ba00;
  border: 1px solid #191919;
}

@media all and (max-width: 1024px) {
  #endeavour-info-sec .endeavour-init-info {
    width: 100%;
    left: 0;
    top: 0;
    position: relative;
    border-radius: 0;
  }
}

#endeavour-info-sec .endeavour-init-2-info {
  border-radius: 52px;
}

@media all and (max-width: 1024px) {
  #endeavour-info-sec .endeavour-init-2-info {
    width: 100%;
    right: 0;
    top: 0px;
    position: relative;
    border-radius: 0;
    padding: 40px 0;
  }
}

.endeavour-row-2 {
  margin: 50px 0;
}

@media all and (max-width: 1024px) {
  .endeavour-row-2 {
    margin: 0;
  }
}

#endeavour-paralax-sec {
  background-attachment: fixed;
  background-image: url(/images/landing-pages/endeavour-banner-2-min.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
  position: relative;
}

#endeavour-paralax-sec .endeavour-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.7);
  padding-top: 100px;
  padding-bottom: 100px;
}

#endeavour-last-form-sec {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 1024px) {
  #endeavour-last-form-sec {
    flex-wrap: wrap;
  }
}

#endeavour-last-form-sec .endeavour-last-form-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 32%;
}

@media all and (max-width: 1420px) {
  #endeavour-last-form-sec .endeavour-last-form-cnt {
    width: 52%;
  }
}

@media all and (max-width: 1024px) {
  #endeavour-last-form-sec .endeavour-last-form-cnt {
    width: 100%;
    order: 3;
  }
}

#endeavour-last-form-sec .endeavour-user-stock-one-img {
  background-color: #2e142c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  width: 34%;
  background-image: url(/images/landing-pages/endeavour-argyle-ale-min.jpg);
}

@media all and (max-width: 1024px) {
  #endeavour-last-form-sec .endeavour-user-stock-one-img {
    width: 50%;
  }
}

#endeavour-last-form-sec .endeavour-user-stock-two-img {
  background-color: #2e142c;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  width: 34%;
  background-image: url(/images/landing-pages/endeavour-hops-resized-min.jpg);
}

@media all and (max-width: 1024px) {
  #endeavour-last-form-sec .endeavour-user-stock-two-img {
    width: 50%;
    order: 1;
  }
}
