@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';


#chmpagen-logo-top-banner {
    margin: 0;
    padding: 33.33333px 0;
    background-color: #0b253b;
    color: #a7925b;
    display: flex;
    align-items: center;
    justify-content: center;
    .champagne-logo {
        width: 400px;
        @include mquery(768px) {
            width: 200px;
        }
    }
}

#champagne-hero-video {
    position: relative;
    height: calc(100vh - 207.66px);
    width: 100%;
    overflow: hidden;
    display: table;
    .champagne-video {
        box-sizing: border-box;
        height: 56.25vw;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh;
    }
    .champagne-hero-video-cnt {
        position: relative;
        display: table;
        width: 100%;
        height: calc(100vh - 207.66px);
        padding: 80px 0;
        background-color:rgba(11, 37, 59, 0.7);
    }
}

#champagne-hero-top-banner {
    background-image: url(/images/landing-pages/banners/champagne-Napoleon.jpg);
    width: 100%;
    background-color: #0b253b;
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 110px 0;
    @include mquery(1024px) {
        padding: 60px 0; 
    }
    @include mquery(767px) {
        background-image: url(/images/landing-pages/banners/champagne-Napoleon-mobile.jpg);
    }
}

#champagne-nepoleon-quote {
    padding: 60px 0;
    .champagne-nepoleon-quote-header {
        font-size: 40px;
        letter-spacing: -0.91px;
        line-height: 1.2;
        margin-bottom: 20px;
        @include mquery(768px) {
            font-size: 30px;
        }
        @include mquery(600px) {
            font-size: 26px;
        }
    }
    .champagne-nepoleon-quote-subheader {
        font-size: 20px;
        letter-spacing: -0.25px;
        line-height: 1.2;
        @include mquery(768px) {
            font-size: 17px;
        }
    }
}


#champagne-intro-sec {
    padding: 30px 0 60px;
    @include mquery(768px) {
        padding: 0 0 60px;
    }
}

.champagne-content {
    width: 100%;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    @include mquery(1200px) {
        text-align: center;
    }
}

#champagne-stats-sec {
    padding: 30px 0 60px;
    @include mquery(768px) {
        padding: 0 0 60px;
    }
    .champagne-stats-cnt {
        border-radius: 8px;
        background-color: #0b253b;
        padding: 80px;
        .champagne-stats-circle {
            width: 120px;
            margin: 0 auto 20px;
            height: 120px;
            border-radius: 100%;
        }
        .champagne-stats-text {
            color: #fff;
            line-height: 1.3;
            @include mquery(767px) {
                margin-bottom: 40px;
            }
        }
        .champagne-stats-img {
            width: 120px;
        }
    }
}


#champagne-story-sec {
    padding: 30px 0 60px;
    @include mquery(1200px) { 
        padding: 60px 0;
     }
    @include mquery(768px) {
        padding: 0 0 60px;
    }
    .champagne-story-sec-text {
        @include mquery(1200px) { 
            margin-bottom: 60px !important;
         }
    }
    .champagne-story-sec-img {
        margin: 0 0 40px;
    }
    .champagne-story-sec-img {
        margin-bottom: 40px;
    }
}


#champagne-quote-sec {
    padding: 60px 0;
    background-color: #0b253b;
    .champagne-quote-header {
        max-width: 1016px;
        margin: auto;
        font-size: 32px;
        line-height: 1.2;
        margin-bottom: 20px;
        letter-spacing: -0.5px;
        @include mquery(768px) {
            text-align: center;
        }
        @include mquery(767px) {
            font-size: 24px;
        }
    }
    .champagne-quote-subheader {
        margin: auto;
        font-size: 20px;
        line-height: 1.2;
        @include mquery(768px) {
            text-align: center;
        }
        @include mquery(767px) {
            font-size: 14px;
        }
    }
    .champagne-quote-img {
        border: 1px solid #a7925b;
        padding: 20px;
    }
}

#champagne-footnote {
    background-color: #0b253b;
    color: #fff;
    font-size: 14px;
    padding: 20px;
    @include mquery(1200px) {
        padding: 0 0 60px;
    }
    .champagne-footnote-p {
        @include mquery(990px) {
            text-align: center;
            font-size: 14px;
        }
    }
}

#champagne-partners-sec {
    background-image: url(/images/landing-pages/banners/champagne-newyear1.jpg);
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center;
    @include mquery(767px) {
        background-image: url(/images/landing-pages/banners/champagne-newyear1-sm.jpg);
    }
    .champagne-partners-sec-layer {
    padding: 120px 0;
    background-color: #fff;
    opacity: .8;
    @include mquery(1200px) {
        padding: 60px 0;
        }
    }
}


#champagne-revenue-sec {
    padding: 60px 0;
    .champagne-revenue-sec-layer {
    padding: 120px 0;
    background-color: #fff;
    opacity: .8;
        @include mquery(1200px) {
            padding: 60px 0;
            }
    }
    .champagne-revenue-img {
        margin-top: 40px;
    }
}

#champagne-last-sec {
    padding: 120px 0;
    @include mquery(768px) {
        padding: 60px 0;
    }
    .champagne-last-sec-text {
        max-width: 1016px;
        margin: auto;
        color: #a7925b;
        font-size: 40px;
        letter-spacing: -0.5px;
        line-height: 1.2;
        margin-bottom: 20px;
        font-family: latobold;
        text-align: center;
        @include mquery(768px) {
            font-size: 30px;
        }
        @include mquery(600px) {
            font-size: 26px;
        }
    }
}
.champagne-link {
    color : #a7925b;
    text-decoration: underline;
    transition: all 300ms ease-out;
    &:hover {
        color: #a7925b;
    }
}

.champagne-button-style {
    color: #fff;
    background-color: #a7925b;
    border: 1px solid #a7925b;
    transition: all 300ms ease-out;
    &:hover {
        background-color: #736748;
        border-color: #736748;
        color: #fff;
        cursor: pointer;
    }
}

.champagne-navy {
    color: #0b253b;
}

.champagne-gold {
    color: #a7925b;
}


#champagne-paralax-sec {
    @include parallax-sec('/images/landing-pages/banners/champagne-background-grid.svg', '/images/landing-pages/banners/champagne-background-grid.svg');
    background-color: #0b253b;
    @include mquery(768px) {
        background-attachment: initial;
    }
    .champagne-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.4));
    @include mquery(1200px) {
        padding: 60px 0;
    }
    }
}