/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
HOMEPAGE
---------------------------------------------------------- */
.hot-details-tooltip {
  position: absolute;
  bottom: 40px;
  right: 0px;
  padding: 5px 10px 6px !important;
  min-height: 24px;
  min-width: 170px;
  border-radius: 2px;
  background-color: #7089a3;
  font-family: 'latolight';
  line-height: 15px;
  font-size: 12px;
  color: #ffffff !important;
  margin: 0px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.hot-details-tooltip:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #7089a3;
  bottom: -6px;
  content: "";
  height: 0px;
  left: 10px;
  position: absolute;
  width: 0px;
}

.syndicate-badge-tooltip {
  position: absolute;
  bottom: 45px;
  right: 0px;
  padding: 5px 10px 6px !important;
  min-height: 24px;
  min-width: 170px;
  border-radius: 2px;
  background-color: #7089a3;
  font-family: 'latolight';
  line-height: 15px;
  font-size: 12px;
  color: #ffffff !important;
  margin: 0px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: visible;
  z-index: 10;
}

.syndicate-badge-tooltip:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #7089a3;
  bottom: -6px;
  content: "";
  height: 0px;
  right: 10px;
  position: absolute;
  width: 0px;
}

/* Main header notification */
#main-header-notification {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

#main-header-notification > div {
  position: relative;
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  padding: 0px;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 10px 21px;
  display: flex;
  flex-direction: row;
}

#main-header-notification > div > span {
  margin: 0px;
  padding: 20px;
  font-size: 17px;
  color: #ffffff;
  font-family: 'latoregular';
}

@media all and (max-width: 600px) {
  #main-header-notification > div > span {
    display: none;
  }
}

#main-header-notification > div > img {
  margin: 14px;
}

#main-header-notification > div .subscribe-xinja {
  color: white;
  text-decoration: underline;
  font-family: "Montserrat";
  padding: 21px 10px;
}

@media all and (max-width: 700px) {
  #main-header-notification > div .subscribe-xinja {
    padding: 20px 0px 0px 5px;
    overflow: hidden;
  }
}

/* Homepage header */
#homepage-header {
  float: left;
  width: 100%;
  display: block;
  margin: 0px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}

#homepage-header > div {
  margin: 0px auto;
  padding: 0px;
  width: 100%;
  max-width: 1200px;
}

#homepage-header .main-logo {
  float: left;
  margin-top: 15px;
  padding: 10px 0px 5px 0;
  height: 40px;
}

@media all and (max-width: 320px) {
  #homepage-header .main-logo {
    width: 138px;
    padding: 5px 0px 0px 5px;
    overflow: hidden;
  }
}

#homepage-header .main-logo .mn-header-logo {
  float: left;
  margin: 0px;
  padding: 0px;
  width: 127px;
}

#homepage-header ul.header-login-reg-nav {
  float: right;
  margin: 18px 0px 0px 0px;
  padding: 0px;
}

#homepage-header ul.header-login-reg-nav > li {
  float: left;
  list-style: none;
  margin: 0px;
  padding: 10px 15px 0px;
  /* Navigation register button */
}

#homepage-header ul.header-login-reg-nav > li > a {
  float: left;
  padding: 0px 0px 10px;
  margin: 0px;
  position: relative;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  font-family: 'latolight';
}

#homepage-header ul.header-login-reg-nav > li > a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

#homepage-header ul.header-login-reg-nav > li > a:after {
  background-color: rgba(255, 255, 255, 0.7);
  content: "";
  height: 2px;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

#homepage-header ul.header-login-reg-nav > li.nav-register {
  padding: 0;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a {
  float: left;
  padding: 10px 20px;
  position: relative;
  text-transform: uppercase;
  margin: 0px;
  font-size: 14px;
  font-family: 'latolight';
  color: #ffffff;
  overflow: hidden;
  background-color: #469edc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a.reg-icon:before {
  content: "";
  height: 30px;
  width: 100%;
  background-image: url(../../images/reg-btn-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a:before {
  font-size: 180%;
  height: 100%;
  line-height: 2.5;
  position: absolute;
  width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  left: 0px;
  top: -100%;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a:hover {
  background-color: #5ba9e0;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a:hover:before {
  top: 0px;
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a:hover > span {
  -webkit-transform: translateY(300%);
  -moz-transform: translateY(300%);
  -ms-transform: translateY(300%);
  -o-transform: translateY(300%);
  transform: translateY(300%);
}

#homepage-header ul.header-login-reg-nav > li.nav-register > a > span {
  backface-visibility: hidden;
  display: inline-block;
  font-family: 'latolight';
  width: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#homepage-header ul.header-navigation {
  float: right;
  border-right-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  margin: 18px 0px 0px;
}

@media all and (max-width: 1024px) {
  #homepage-header ul.header-navigation {
    border: none;
  }
}

#homepage-header ul.header-navigation li.header-event-list {
  float: left;
  padding: 0;
  position: relative;
  list-style: none;
}

#homepage-header ul.header-navigation li {
  float: left;
  padding: 10px 15px 0px;
  position: relative;
  list-style: none;
}

#homepage-header ul.header-navigation li:hover {
  cursor: pointer;
}

#homepage-header ul.header-navigation li a.header-invest-link {
  border: 1px solid white;
  margin-top: -2px;
  padding: 10px 20px;
  position: relative;
}

#homepage-header ul.header-navigation li a.header-invest-link:hover .header-tooltiptext {
  display: flex;
}

#homepage-header ul.header-navigation li a.header-invest-link:hover:after {
  opacity: 0;
}

#homepage-header ul.header-navigation li .header-justify-content {
  text-align: center;
  display: inline-table;
}

@media only screen and (min-width: 1200px) {
  #homepage-header ul.header-navigation li .header-justify-content {
    min-width: 160px;
    margin: auto;
  }
}

#homepage-header ul.header-navigation li .tooltip-cnt {
  position: absolute;
  top: 47px;
  left: -160px;
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation li .tooltip-cnt {
    left: 70px;
  }
}

@media only screen and (min-width: 1118px) {
  #homepage-header ul.header-navigation li .not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

#homepage-header ul.header-navigation li .header-tooltiptext {
  display: none;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 10;
  margin-bottom: 2px;
  transition: all 0.2s fadeIn;
  position: relative;
}

@media all and (max-width: 767px) {
  #homepage-header ul.header-navigation li .header-tooltiptext {
    display: none;
  }
}

#homepage-header ul.header-navigation li .header-tooltiptext:hover {
  display: flex;
  transition: 2s fadeIn linear;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#homepage-header ul.header-navigation li .event-btn {
  border: 1px solid white;
  margin-top: -2px;
  padding: 10px 20px;
  position: relative;
  color: white;
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation li .event-btn {
    text-align: center;
  }
}

#homepage-header ul.header-navigation li .tooltip-text-cnt {
  width: 210px;
  padding-right: 8px;
  color: #333;
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation li .tooltip-text-cnt {
    padding: 8px;
    width: 240px;
  }
}

#homepage-header ul.header-navigation li .tooltip-title {
  margin-bottom: 15px;
  font-size: 16px;
  margin-top: 13px;
}

#homepage-header ul.header-navigation li .tooltip-desc {
  margin-bottom: 25px;
  font-family: latoregular;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#homepage-header ul.header-navigation li .tooltip-date {
  position: absolute;
  bottom: 7px;
  font-size: 13px;
}

#homepage-header ul.header-navigation li .tooltip-image {
  width: 240px;
  max-height: 130px;
  min-height: 130px;
  height: 100%;
  padding: 2px 8px;
  object-fit: cover;
}

#homepage-header ul.header-navigation li .header-tooltiptext:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
}

#homepage-header ul.header-navigation li a {
  float: left;
  padding: 0px 0px 10px;
  margin: 0px;
  position: relative;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  font-family: 'latolight';
  display: flex;
}

#homepage-header ul.header-navigation li a:hover:after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

#homepage-header ul.header-navigation li a:after {
  background-color: rgba(255, 255, 255, 0.7);
  content: "";
  height: 2px;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
  cursor: pointer;
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation li a {
    width: 100%;
    padding: 13px 15px;
    text-align: center;
    transition: 0.3s;
    display: block;
    float: none;
  }
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation {
    width: 100%;
    background-color: #252424;
    display: none;
    position: absolute;
    top: 70px;
    left: 0px;
    margin-top: 0px;
  }
  #homepage-header ul.header-navigation > li {
    width: 25%;
    padding: 0px;
    border-style: solid;
    border-color: #3E3E3E;
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
}

@media all and (max-width: 1118px) and (max-width: 600px) {
  #homepage-header ul.header-navigation > li {
    width: 50%;
  }
}

@media all and (max-width: 1118px) and (max-width: 1045px) {
  #homepage-header ul.header-navigation > li {
    width: 100%;
    border-right: 0px;
  }
}

@media all and (max-width: 1118px) {
  #homepage-header ul.header-navigation > li > a {
    width: 100%;
    padding: 13px 15px;
    text-align: center;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
  #homepage-header ul.header-navigation > li > a:after {
    content: none;
  }
  #homepage-header ul.header-navigation > li > a:hover {
    background-color: #181717;
  }
}

@media all and (max-width: 1118px) and (max-width: 736px) {
  #homepage-header ul.header-navigation > li > a {
    background-color: #252424;
  }
}

@media all and (max-width: 1118px) and (max-width: 736px) {
  #homepage-header ul.header-navigation {
    background-color: none;
  }
}

.open-nav-btn-li {
  display: none;
  float: left;
  margin: 0px;
  padding: 12px 2px 12px 12px !important;
}

.open-nav-btn-li > a {
  float: left;
  width: 25px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  background-image: url("../../images/open-nav-icon.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.open-nav-btn-li > a.active {
  background-position: 0px -20px;
}

.open-nav-btn-li > a:after {
  content: none;
}

@media all and (max-width: 1118px) {
  .open-nav-btn-li {
    display: inline-block;
  }
}

.open-nav-btn {
  padding: 21px 21px 12px 12px;
}

.onb-logged-in {
  padding: 25px 21px 12px !important;
}

@media all and (max-width: 320px) {
  .onb-logged-in {
    padding: 25px 4px 25px 9px !important;
  }
}

ul.header-nav-dropdown {
  display: none;
  background-color: #469edc;
  left: -30px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  min-width: 130px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}

@media all and (max-width: 1045px) {
  ul.header-nav-dropdown {
    display: none;
    left: 0;
  }
}

ul.header-nav-dropdown:before {
  border-bottom: 7px solid #469edc;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  left: 50%;
  margin: 0 0 0 -5px;
  padding: 0;
  position: absolute;
  top: -7px;
}

ul.header-nav-dropdown > li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

ul.header-nav-dropdown > li > a {
  color: #ffffff;
  float: left;
  font-family: "latoregular";
  font-size: 12px;
  height: 35px;
  margin: 0;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}

ul.header-nav-dropdown > li > a:hover {
  background-color: #2f87c5;
}

.header-logged-in {
  float: right;
  margin: 0px;
  padding: 0px;
}

.header-logged-in ~ .header-navigation {
  border: 0px !important;
}

.header-nav-more {
  position: relative !important;
}

.header-logged-user {
  float: left;
  margin: 0px;
  padding: 20px 10px 20px 60px;
  height: 70px;
  min-width: 220px;
  border-style: solid;
  border-color: #151515;
  border-left-width: 1px;
  border-right-width: 1px;
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-logged-user:hover {
  cursor: pointer;
  background-color: #3D3D3D;
}

@media all and (max-width: 480px) {
  .header-logged-user {
    min-width: 60px;
    padding-right: 0;
    background-image: none;
  }
}

.header-logged-user > img {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #151515;
  border-width: 1px;
}

.header-logged-user > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 0px 0px 0px;
  font-size: 12px;
  color: #ffffff;
  font-family: 'latolight';
  position: relative;
}

@media all and (max-width: 480px) {
  .header-logged-user > span {
    display: none;
  }
}

.header-logged-user > span:before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "Hi, ";
  font-family: 'latobold';
}

.header-logged-user-dropdown {
  position: absolute;
  top: 70px;
  right: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background-color: #469edc;
  display: none;
}

@media all and (max-width: 480px) {
  .header-logged-user-dropdown {
    min-width: 220px;
  }
}

.header-logged-user-dropdown > li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.header-logged-user-dropdown > li > a {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 10px;
  text-decoration: none;
  font-family: 'latoregular';
  font-size: 12px;
  color: #ffffff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-image: url(../../images/user-dropdown-icons.png);
  background-repeat: no-repeat;
}

.header-logged-user-dropdown > li > a:hover {
  background-color: #2f87c5;
}

.header-logged-user-dropdown > li > a.hlud-profile {
  background-position: right 0px top -83px;
}

.header-logged-user-dropdown > li > a.hlud-capital-raisings {
  background-position: right 0px top 11px;
}

.header-logged-user-dropdown > li > a.hlud-users {
  background-position: right 2px top -147px;
}

.header-logged-user-dropdown > li > a.hlud-settings {
  background-position: right 0px top -21px;
}

.header-logged-user-dropdown > li > a.hlud-logout {
  background-position: right 0px top -54px;
}

.header-logged-notification {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #151515;
  border-right-width: 1px;
  position: relative;
}

@media all and (max-width: 450px) {
  .header-logged-notification {
    float: right;
  }
}

.header-logged-notification > a {
  float: left;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  background-image: url(../../images/header-notification-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.header-logged-notification > a:hover {
  background-color: #3D3D3D;
}

.header-logged-notification > a > span {
  position: absolute;
  top: 6px;
  right: 6px;
  font-family: 'latobold';
  font-size: 10px;
  color: #ffffff;
  text-align: center;
  background-color: #dd1c52;
  border-radius: 6px;
  margin: 0px;
  padding: 1px 5px 2px;
}

.header-logged-notification-dropdown {
  position: absolute;
  top: 70px;
  right: 0px;
  width: 100%;
  min-width: 340px;
  margin: 0px;
  padding: 0px;
  background-color: #5fa3b4;
  display: none;
}

.header-logged-notification-dropdown > ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.header-logged-notification-dropdown > ul > li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: relative;
  border-style: solid;
  border-color: #6EB4C8;
  border-bottom-width: 1px;
}

.header-logged-notification-dropdown > ul > li > a {
  float: left;
  width: 100%;
  height: 60px;
  margin: 0px;
  padding: 12px 10px 12px 60px;
  text-decoration: none;
  position: relative;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-logged-notification-dropdown > ul > li > a:hover {
  background-color: #6EB4C8;
}

.header-logged-notification-dropdown > ul > li > a > img {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 2px;
}

.header-logged-notification-dropdown > ul > li > a > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 13px;
  color: #ffffff;
}

.header-logged-notification-dropdown > ul > li > a > p {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 12px;
  color: #b8f4ff;
}

.header-notification-see-all {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 10px;
  font-family: 'latoregular';
  font-size: 13px;
  color: #ffffff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header-notification-see-all:hover {
  background-color: #6EB4C8;
}

.header-logged-home .header-logged-user:hover {
  background-color: transparent;
}

.header-logged-home .header-logged-notification > a {
  background-image: url(../../images/header-notification-icon-white.png);
}

.header-logged-home .header-logged-notification > a:hover {
  background-color: transparent;
}

.header-logged-home .header-logged-user .header-logged-user-dropdown:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  margin: 0px 0px 0px -5px;
  padding: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #5fa3b4;
}

.header-logged-home .header-logged-notification .header-logged-notification-dropdown:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 27px;
  margin: 0px;
  padding: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #5fa3b4;
}

/* Home top section wrapper */
#home-top-carousel-wrapper .homepage-carousel-banner {
  background-size: cover;
  height: 600px;
}

@media all and (max-width: 1280px) {
  #home-top-carousel-wrapper .homepage-carousel-banner {
    height: calc(100vh - 70px);
  }
}

@media all and (max-width: 1024px) {
  #home-top-carousel-wrapper .homepage-carousel-banner {
    height: 600px;
  }
}

@media all and (max-width: 767px) {
  #home-top-carousel-wrapper .homepage-carousel-banner {
    height: 530px;
  }
}

#home-top-carousel-wrapper .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

#home-top-carousel-wrapper .carousel-fade .carousel-inner .active {
  opacity: 1;
}

#home-top-carousel-wrapper .carousel-fade .carousel-inner .active.left,
#home-top-carousel-wrapper .carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

#home-top-carousel-wrapper .carousel-fade .carousel-inner .next.left,
#home-top-carousel-wrapper .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

#home-top-carousel-wrapper .carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.next,
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.prev,
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.next.left,
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.prev.right,
  #home-top-carousel-wrapper .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

#home-top-carousel-wrapper .one {
  background: url(/images/homepage-carousel.jpg) no-repeat center center;
  background-size: cover;
}

#home-top-carousel-wrapper .three {
  background: url(/images/banners/rhinohide-banner2.jpg) no-repeat center center;
  background-size: cover;
}

#home-top-carousel-wrapper .two {
  background: url(/images/banners/agunity.png) no-repeat center right;
  background-size: cover;
}

#home-top-carousel-wrapper .four {
  background: url(/images/banners/fini-awards.png) no-repeat center;
  background-size: cover;
}

#home-top-carousel-wrapper .five {
  background: url(/images/homepage-carousel-deal-banner.jpg) no-repeat center center;
  background-size: cover;
}

@media all and (max-width: 800px) {
  #home-top-carousel-wrapper .five {
    background: url(/images/homepage-carousel-deal-mobile.jpg) no-repeat top right;
  }
}

#home-top-carousel-wrapper .homepage-flex-logos {
  height: 170px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 767px) {
  #home-top-carousel-wrapper .homepage-flex-logos {
    height: 120px;
  }
}

#home-top-carousel-wrapper .homepage-carousel-xinja-logo {
  width: 290px;
}

@media all and (max-width: 768px) {
  #home-top-carousel-wrapper .homepage-carousel-xinja-logo {
    width: 260px;
  }
}

@media all and (max-width: 600px) {
  #home-top-carousel-wrapper .homepage-carousel-xinja-logo {
    width: 220px;
  }
}

@media all and (max-width: 320px) {
  #home-top-carousel-wrapper .homepage-carousel-xinja-logo {
    width: 200px;
  }
}

#home-top-carousel-wrapper .homepage-carousel-dit-logo {
  width: 350px;
}

@media all and (max-width: 768px) {
  #home-top-carousel-wrapper .homepage-carousel-dit-logo {
    width: 300px;
  }
}

@media all and (max-width: 600px) {
  #home-top-carousel-wrapper .homepage-carousel-dit-logo {
    width: 270px;
  }
}

@media all and (max-width: 320px) {
  #home-top-carousel-wrapper .homepage-carousel-dit-logo {
    width: 250px;
  }
}

#home-top-carousel-wrapper .homepage-carousel-booktopia-logo {
  width: 350px;
}

@media all and (max-width: 768px) {
  #home-top-carousel-wrapper .homepage-carousel-booktopia-logo {
    width: 300px;
  }
}

@media all and (max-width: 600px) {
  #home-top-carousel-wrapper .homepage-carousel-booktopia-logo {
    width: 230px;
  }
}

@media all and (max-width: 320px) {
  #home-top-carousel-wrapper .homepage-carousel-booktopia-logo {
    width: 200px;
  }
}

.homepage-carousel-control {
  width: 5%;
  z-index: 10;
}

.carousel-x-img {
  padding: 10px;
  box-sizing: border-box;
  width: 59px;
  height: 59px;
}

@media all and (max-width: 600px) {
  .carousel-x-img {
    padding: 3px;
    width: 25px;
    height: 25px;
  }
}

.homepage-carousel-layer {
  height: 600px;
  background-color: rgba(1, 1, 1, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1280px) {
  .homepage-carousel-layer {
    height: calc(100vh - 70px);
  }
}

@media all and (max-width: 1024px) {
  .homepage-carousel-layer {
    height: 600px;
  }
}

@media all and (max-width: 767px) {
  .homepage-carousel-layer {
    height: 530px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media all and (max-width: 600px) {
  .homepage-carousel-layer .homepage-carousel-layer-content-cnt {
    width: 345px;
    height: 447px;
  }
}

.homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-title-cnt {
  height: 170px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 600px) {
  .homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-title-cnt {
    height: 120px;
  }
}

.homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-title-cnt .carousel-title {
  max-width: 764px;
  font-size: 48px;
  font-family: 'latoregular';
  text-align: center;
}

@media all and (max-width: 768px) {
  .homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-title-cnt .carousel-title {
    font-size: 38px;
  }
}

.homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-subtitle-cnt {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-subtitle-cnt .carousel-subtitle {
  font-size: 24px;
  font-family: latolight;
  max-width: 680px;
  line-height: 30px;
  text-align: center;
  margin: auto;
}

@media all and (max-width: 768px) {
  .homepage-carousel-layer .homepage-carousel-layer-content-cnt .homepage-carousel-subtitle-cnt .carousel-subtitle {
    font-size: 18px;
    line-height: 24px;
  }
}

.homepage-btn-container {
  padding: 30px 0px;
  height: 115px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

@media all and (max-width: 600px) {
  .homepage-btn-container {
    height: 200px;
  }
}

.homepage-btn-container > a {
  float: left;
  width: 100%;
  font-size: 15px;
  margin: 0px;
  padding: 17px;
  text-align: center;
  color: #ffffff;
  font-family: latoregular;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  /* Media queries */
}

.homepage-btn-container > a:hover {
  background-color: #469edc;
}

.homepage-btn-container > a.mn-home-blue-btn {
  margin: 0px 30px 0px 30px;
  height: 55px;
  width: 216px;
  background-color: #469edc;
  transition: .3s;
  border-style: solid;
  border-color: #469edc;
  border-width: 2px;
}

@media all and (max-width: 600px) {
  .homepage-btn-container > a.mn-home-blue-btn {
    margin-right: 0;
    width: 253px;
    margin-left: 0;
  }
}

.homepage-btn-container > a.mn-home-blue-btn:hover {
  opacity: .8;
}

.homepage-btn-container > a.mn-home-border-white-btn {
  border-style: solid;
  border-color: #ffffff;
  border-width: 2px;
  height: 55px;
  width: 258px;
}

.homepage-btn-container > a.mn-home-border-white-btn:hover {
  cursor: pointer;
  border-style: solid;
  border-color: #469edc;
  border-width: 2px;
}

@media all and (max-width: 481px) {
  .homepage-btn-container > a.mn-home-border-white-btn {
    margin-top: 15px;
    min-width: 243px;
  }
}

@media all and (max-width: 700px) {
  .homepage-btn-container > a {
    max-width: 260px;
  }
}

@media all and (max-width: 600px) {
  .homepage-btn-container > a {
    font-size: 15px;
    background-position: right 15px center;
  }
}

@media all and (max-width: 530px) {
  .homepage-btn-container > a {
    float: none;
    display: inline-block;
  }
}

@media all and (max-width: 530px) {
  .homepage-btn-container {
    text-align: center;
  }
}

/* Home top buttons ends here */
#home-additional-offer {
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: row;
}

#home-additional-offer .container {
  width: 100%;
  max-width: 1200px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}

#home-additional-offer .container .info-container {
  padding-top: 0;
  display: flex;
  max-width: 920px;
  width: 100%;
}

@media (max-width: 825px) {
  #home-additional-offer .container .info-container {
    flex-direction: column;
  }
  #home-additional-offer .container .info-container .aos-completed-offers,
  #home-additional-offer .container .info-container .aos-coming-soon-offers {
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    flex: 1;
  }
}

/* Home section */
.home-section {
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  text-align: center;
  background-color: #ffffff;
}

.home-section header {
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
}

.home-section > div {
  max-width: 1200px;
  padding: 80px 10px;
  font-size: 0;
  display: inline-block;
  text-align: center;
  position: relative;
}

.home-section > div .home-section-title {
  min-width: 150px;
  display: inline-block;
  margin: 0px 0px 35px;
  padding: 0px 0px 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  color: #3f3e3e;
}

.home-section > div .home-section-title.white {
  color: #ffffff;
}

.home-section > div .home-section-title.tagline {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
  text-transform: initial;
}

.home-section > div .home-section-title.tagline:after {
  content: none;
}

.home-section > div .home-section-title:after {
  bottom: 0px;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  position: absolute;
  width: 150px;
  background-color: #469edc;
}

.home-section .home-offer-tiles-wrapper {
  margin: 0px;
  padding: 20px 7px 0;
  display: inline-block;
  vertical-align: top;
  /* Home offer tile top container ends */
  /* Home offer tile bottom container ends */
}

.home-section .home-offer-tiles-wrapper .home-offer-tile {
  overflow: hidden;
  float: left;
  width: 100%;
  max-width: 280px;
  min-height: 400px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #efefef;
  border-width: 1px;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-bottom-cont {
  display: block;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .private-hot-hover-overlay {
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .hot-hover-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 2;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .hot-hover-overlay div {
  position: absolute;
  top: 0;
  left: 0px;
  margin: 0px;
  padding: 0px 10px;
  width: 140px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .hot-hover-overlay div a {
  float: left;
  width: auto;
  height: 35px;
  margin: 0px;
  padding: 0px;
  background-color: #469edc;
  background-image: url(../../images/view-invest-offer-icon.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  border-radius: 0 0 4px 4px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .hot-hover-overlay div a span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 9px 10px 0px 25px;
  text-align: right;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .hot-hover-overlay div span {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 0px;
  background-color: #469edc;
  background-image: url(../../images/view-invest-offer-icon.png);
  background-repeat: no-repeat;
  background-position: 95px center;
  border-radius: 0 0 4px 4px;
  padding: 9px 30px 0px 10px;
  text-align: left;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile:hover .private-hot-bottom-cont {
  display: none;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .syndicate-badge-wrapper {
  position: absolute;
  top: 0px;
  right: 10px;
  margin: 0px;
  padding: 0px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .syndicate-badge-wrapper .syndicate-badge {
  float: left;
  width: 29px;
  height: 29px;
  margin: 0px;
  padding: 0px;
  background-image: url(../../images/hot-tile-syndicate-icon.png);
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .syndicate-badge-wrapper .syndicate-badge:hover ~ .syndicate-badge-tooltip {
  opacity: 1;
  visibility: visible;
  bottom: 35px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay {
  opacity: 0;
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 160px;
  padding-left: 0;
  padding-right: 0;
  align-items: start;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form h2 {
  margin: 10px 0px;
  font-family: 'latoregular';
  font-size: 16.5px;
  line-height: 30px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form .form-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form .form-fields input[type=text], .home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form .form-fields input[type=email] {
  height: 40px;
  flex: 1;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  color: #333;
  font-size: 15px;
  border-radius: 2px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form button.request-access {
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: #469edc;
  border: 1px solid #469edc;
  color: #fff;
  border-radius: 2px;
  transition: background 0.3s ease;
  border-radius: 3px;
  width: 100%;
  height: 40px;
}

.home-section .home-offer-tiles-wrapper .home-offer-tile .private-hot-hover-overlay .private-offer-request-form button.request-access:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont {
  float: left;
  width: 100%;
  height: 152px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont a.logo-link {
  float: left;
  width: 100%;
  height: 100%;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont a.logo-link img:last-child {
  top: unset !important;
  bottom: -20px !important;
  left: 10px !important;
  width: 70px !important;
  background-color: white;
  z-index: 3;
  border-style: solid;
  border-color: #efefef;
  border-width: 1px;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont img {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  max-height: 100%;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .hot-hover-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .hot-hover-overlay div {
  position: absolute;
  top: -35px;
  left: 0px;
  margin: 0px;
  padding: 0px 10px;
  width: 140px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .hot-hover-overlay div a {
  float: left;
  width: auto;
  height: 35px;
  margin: 0px;
  padding: 0px;
  background-color: #469edc;
  background-image: url(../../images/view-invest-offer-icon.png);
  background-repeat: no-repeat;
  background-position: 10px center;
  border-radius: 0 0 4px 4px;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .hot-hover-overlay div a span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 9px 10px 0px 25px;
  text-align: right;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .hot-hover-overlay div span {
  float: left;
  width: 100%;
  height: 35px;
  margin: 0px;
  padding: 0px;
  background-color: #469edc;
  background-image: url(../../images/view-invest-offer-icon.png);
  background-repeat: no-repeat;
  background-position: 95px center;
  border-radius: 0 0 4px 4px;
  padding: 9px 30px 0px 10px;
  text-align: left;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .corner-ribbon {
  width: 120px;
  background: #2c7;
  position: relative;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 120px;
  color: #f0f0f0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.home-section .home-offer-tiles-wrapper .hot-top-cont .top-right {
  top: -178px;
  right: -127px;
  bottom: 0;
  font-size: 16px;
  font-family: 'latoregular';
  left: auto;
  padding: 0;
  transform: rotate(-315deg);
  -webkit-transform: rotate(-315deg);
  position: relative;
  height: 75px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 10px;
  padding-top: 20px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div span,
.home-section .home-offer-tiles-wrapper .hot-bottom-cont div a {
  float: left;
  max-width: 100%;
  margin: 15px 0px;
  font-family: 'latobold';
  font-size: 16.5px;
  color: #5f5f5f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div span:hover,
.home-section .home-offer-tiles-wrapper .hot-bottom-cont div a:hover {
  color: #469edc;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div p {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 13px;
  color: #666666;
  line-height: 18px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details {
  float: left;
  width: 100%;
  margin: 5px 0px 0px 0px;
  padding: 0px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 8px 0px 10px;
  list-style: none;
  position: relative;
  border-style: solid;
  border-color: #efefef;
  border-bottom-width: 1px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li span {
  float: left;
  margin: 0px;
  padding: 0px;
  color: #469edc;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li span.name {
  width: 40%;
  font-family: 'latoregular';
  font-size: 14px;
  text-align: left;
  padding-top: 2px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li span.value {
  width: 60%;
  font-family: 'latobold';
  font-size: 16px;
  text-align: right;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li span.name:hover ~ .hot-details-tooltip, .home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-details li span.value:hover ~ .hot-details-tooltip {
  visibility: visible;
  opacity: 1;
  bottom: 30px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 0px 15px 25px;
  background-image: url(../../images/offer-tile-tags.png);
  background-repeat: no-repeat;
  background-position: 0px 14px;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags li {
  float: left;
  padding: 5px 5px 0px 0px;
  margin: 0px;
  list-style: none;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags li a {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 12px;
  color: #666666;
  text-decoration: none;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags li a:hover {
  text-decoration: underline;
  color: #469edc;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags li a:after {
  content: ", ";
  font-family: 'latolight';
  font-size: 12px;
  color: #469edc;
}

.home-section .home-offer-tiles-wrapper .hot-bottom-cont div .hot-tags li:last-of-type a:after {
  content: "";
}

.home-section .link-wrap {
  padding-top: 55px;
}

.home-section-title {
  min-width: 150px;
  display: inline-block;
  margin: 0px 0px 35px;
  padding: 0px 0px 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  color: #3f3e3e;
}

.home-section-title.white {
  color: #ffffff;
}

.home-section-title.tagline {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
  text-transform: initial;
}

.home-section-title.tagline:after {
  content: none;
}

.home-section-title:after {
  bottom: 0px;
  content: "";
  display: inline-block;
  height: 2px;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  position: absolute;
  width: 150px;
  background-color: #469edc;
}

.no-live-offers {
  padding-top: 0 !important;
}

.no-live-offers p {
  text-align: center;
  font-size: 20px;
  color: #3f3e3e;
}

#home-curr-off {
  padding-top: 80px;
  padding-bottom: 120px;
  border-style: solid;
  border-color: rgba(179, 179, 179, 0.3);
  border-bottom-width: 1px;
}

#home-feat-syndi {
  background-image: none;
}

#home-feat-syndi > div {
  min-height: auto;
  padding: 85px 0;
}

#home-feat-syndi .link-wrap {
  padding-top: 0;
}

#home-raise-capital {
  background-image: none;
  background-color: #333333;
}

#home-raise-capital .landing-featured-box-out {
  padding-top: 50px;
}

#home-raise-capital .single-capital-box {
  background-color: transparent;
  max-width: 370px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

@media all and (max-width: 1200px) {
  #home-raise-capital .single-capital-box {
    max-width: 300px;
  }
}

#home-raise-capital .single-capital-box img {
  float: none;
  display: inline-block;
  max-width: 300px;
}

@media all and (max-width: 580px) {
  #home-raise-capital .single-capital-box img {
    max-width: 250px;
  }
}

#home-raise-capital .single-capital-box span {
  float: left;
  width: 100%;
  font-family: 'latolight';
  font-size: 25px;
  color: #ffffff;
  text-align: center;
}

@media all and (max-width: 580px) {
  #home-raise-capital .single-capital-box span {
    font-size: 20px;
  }
}

#home-raise-capital .single-capital-box p {
  float: left;
  width: 100%;
  font-family: 'latolight';
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 0;
  margin: 35px 0 0;
  line-height: 24px;
}

@media all and (max-width: 580px) {
  #home-raise-capital .single-capital-box p {
    font-size: 16px;
  }
}

#home-raise-capital .link-wrap {
  padding-top: 0;
  padding-bottom: 5px;
}

/* Homepage call to action section */
.homepage-cta-wrapper > div {
  min-height: auto;
  margin-top: 40px;
}

.home-cta-section {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.home-cta-section .hcs-buttons {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.home-cta-section .hcs-buttons a {
  display: inline-block;
  width: 100%;
  max-width: 300px;
  height: 50px;
  margin: 0px 10px;
  padding: 14px 0px 0px 65px;
  font-family: 'latobold';
  font-size: 15px;
  color: #469edc;
  border-style: solid;
  border-color: #469edc;
  border-width: 2px;
  text-align: left;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-cta-section .hcs-buttons a:hover {
  background-color: #5ba9e0;
  color: #ffffff;
}

@media all and (max-width: 1000px) {
  .home-cta-section .hcs-buttons a {
    max-width: 235px;
  }
}

@media all and (max-width: 805px) {
  .home-cta-section .hcs-buttons a {
    font-size: 12px;
    padding: 17px 0px 0px 65px;
    max-width: 200px;
  }
}

@media all and (max-width: 700px) {
  .home-cta-section .hcs-buttons a {
    max-width: 400px;
    font-size: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 14px 0px 0px 65px;
  }
}

.home-cta-section .hcs-buttons a span {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 46px;
  height: 46px;
  margin: 0px;
  padding: 14px 0px;
  text-align: center;
  font-family: 'latobold';
  font-size: 15px;
  color: #ffffff;
  background-color: #469edc;
}

.home-cta-section .hcs-text {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px;
}

.home-cta-section .hcs-text span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 34px;
  color: #3f3e3e;
  text-align: center;
}

.home-cta-section .hcs-text p {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 18px;
  color: #7e7e7e;
  line-height: 150%;
  text-align: center;
}

.aos-completed-offers {
  background-image: none;
}

.aos-completed-offers h4 {
  background-image: url("../../../images/single-person.svg");
  background-size: 38px 38px;
  background-repeat: no-repeat;
  padding: 5px 0px 5px 55px;
  font-size: 28px;
  color: #3f3e3e;
}

.aos-coming-soon-offers {
  background-image: none;
  display: flex;
  flex-direction: column;
}

.aos-coming-soon-offers h4 {
  background-image: url("../../../images/people.svg");
  background-size: 38px 38px;
  background-repeat: no-repeat;
  background-position: right center;
  padding: 5px 65px 5px 0px;
  text-align: right;
  font-size: 28px;
  color: #3f3e3e;
}

.additional-offers-section {
  margin: 0px;
  padding: 0px;
  min-height: 250px;
  position: relative;
  background-color: white;
}

.additional-offers-section .green-link {
  display: inline-block;
  width: 100%;
  max-width: 250px;
  text-align: center;
  font-size: 16px;
  font-family: 'latolight';
  margin: 0;
  margin-top: 40px;
  padding: 0 5px;
  color: #ffffff;
  background-color: #aad155;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 0px;
  text-transform: uppercase;
  cursor: pointer;
  box-sizing: border-box;
}

.additional-offers-section .foo-button {
  margin: 0;
  padding: 10px 30px;
  min-width: 140px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'latolight';
  text-align: center;
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.additional-offers-section .foo-button:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
  border: none;
}

@media all and (max-width: 825px) {
  .additional-offers-section.home-section {
    background-color: white;
  }
}

@media all and (max-width: 825px) {
  .additional-offers-section.home-section div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px;
  }
}

.additional-offers-section.home-section div .aos-completed-offers,
.additional-offers-section.home-section div .aos-coming-soon-offers {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  padding-left: 20px;
  padding-right: 20px;
}

@media all and (max-width: 825px) {
  .additional-offers-section.home-section div .aos-completed-offers,
  .additional-offers-section.home-section div .aos-coming-soon-offers {
    width: 100%;
    max-width: none;
    text-align: center;
  }
  .additional-offers-section.home-section div .aos-completed-offers span,
  .additional-offers-section.home-section div .aos-coming-soon-offers span {
    float: none;
    display: inline-block !important;
    width: auto !important;
  }
  .additional-offers-section.home-section div .aos-completed-offers p,
  .additional-offers-section.home-section div .aos-coming-soon-offers p {
    text-align: center;
  }
}

.additional-offers-section.home-section div .aos-completed-offers {
  align-items: flex-start;
}

.additional-offers-section.home-section div .aos-coming-soon-offers {
  align-items: flex-end;
}

.additional-offers-section div {
  margin: 0px auto;
  max-width: 1200px;
  padding: 0px;
  position: relative;
  z-index: 2;
}

.aos-completed-offers {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  padding: 0px 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: right 10px bottom 0px;
}

.aos-completed-offers span {
  margin: 30px 0px 0px 0px;
  padding: 5px 0px 5px 55px;
  font-size: 28px;
  color: black;
  background-repeat: no-repeat;
  background-position: left center;
}

@media all and (max-width: 410px) {
  .aos-completed-offers span {
    font-size: 24px;
    padding: 9px 0px 9px 65px;
  }
}

.aos-completed-offers p {
  float: left;
  width: 100%;
  margin: 20px 0px 40px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 16px;
  color: black;
  line-height: 22px;
}

@media all and (max-width: 350px) {
  .aos-completed-offers p {
    margin-top: 10px;
  }
}

@media all and (max-width: 825px) {
  .aos-completed-offers {
    width: 100%;
    background-color: white;
  }
}

.aos-coming-soon-offers {
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0px;
  padding: 0px 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: left 10px bottom 0px;
}

.aos-coming-soon-offers span {
  float: left;
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 5px 65px 5px 0px;
  font-size: 28px;
  color: black;
  background-image: url(../../images/coming-soon-offers-icon.png);
  background-repeat: no-repeat;
  background-position: right center;
  text-align: right;
}

@media all and (max-width: 825px) {
  .aos-coming-soon-offers span {
    text-align: left;
    padding: 5px 0px 5px 65px;
    background-position: left center;
  }
}

@media all and (max-width: 410px) {
  .aos-coming-soon-offers span {
    font-size: 24px;
    padding: 9px 0px 9px 55px;
  }
}

.aos-coming-soon-offers p {
  float: left;
  width: 100%;
  margin: 20px 0px 40px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 16px;
  color: black;
  text-align: right;
  line-height: 22px;
}

@media all and (max-width: 825px) {
  .aos-coming-soon-offers p {
    text-align: left;
  }
}

@media all and (max-width: 350px) {
  .aos-coming-soon-offers p {
    margin-top: 10px;
  }
}

@media all and (max-width: 825px) {
  .aos-coming-soon-offers {
    width: 100%;
    background-color: white;
    background-position: right 10px bottom 0px;
  }
}

/* aos-coming-soon-offers ends here */
/* Additional offer section ends */
.what-wedo-section {
  background-image: url("../../../images/space-bg-l.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media all and (max-width: 650px) {
  .what-wedo-section {
    background-image: url("../../../images/space-bg-p.jpg");
  }
}

.what-wedo-section > div > div {
  display: inline-block;
  max-width: 960px;
  padding: 20px 0px 0px 0px;
  width: 100%;
}

.find-out-more-section {
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.find-out-more-section .find-out-more-text {
  color: #666666 !important;
  max-width: 950px;
  margin: 0 auto;
}

.find-out-more-section .find-out-more-text p {
  margin: 20px 0;
  line-height: 1.25;
}

.find-out-more-section.show {
  padding: 75px 0 75px 0 !important;
  max-height: 1000px;
}

.warning-statement {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 200px;
  position: relative;
}

.warning-statement .ws-exclamation {
  display: none;
  position: absolute;
  left: -40px;
  top: -40px;
  width: 95px;
  height: 95px;
  margin: 0px;
  padding: 0px;
  background-image: url(../../images/ws-exclamation.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2c6892;
}

@media all and (max-width: 670px) {
  .warning-statement .ws-exclamation {
    width: 60px;
    height: 60px;
    background-size: 30px 30px;
    left: 50%;
    margin-left: -30px;
  }
}

.warning-statement .ws-text .ws-title {
  float: left;
  width: 100%;
  margin: 0px;
  font-family: 'latoregular';
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

.warning-statement .ws-text > ul {
  float: left;
  margin: 3px 0px 0px;
  width: 100%;
}

.warning-statement .ws-text > ul > li {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 4px 0px;
  font-family: 'latolight';
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.home-button {
  margin: 0;
  padding: 10px 30px;
  min-width: 140px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'latolight';
  text-align: center;
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.home-button:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
  border: none;
}

.home-button-reverse {
  margin: 10px;
  padding: 10px 30px;
  min-width: 140px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'latolight';
  text-align: center;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2px;
  transition: background 0.3s ease;
  background: rgba(100, 100, 100, 0.3);
  min-width: 190px;
  display: inline-block;
}

.home-button-reverse:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
  border: none;
}

.home-button-reverse:hover {
  border: none;
}

.find-out-more-button {
  margin: 0;
  padding: 10px 30px;
  min-width: 140px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'latolight';
  text-align: center;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2px;
  transition: background 0.3s ease;
  border: 1px solid #469edc;
  color: #469edc;
}

.find-out-more-button:hover {
  cursor: pointer;
  background: #469edc;
  color: #fff;
  border: none;
}

#equitise-raise-banner {
  background: url(../../images/equitise-raise-banner.png);
  min-height: 300px;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  letter-spacing: .5px;
  padding: 40px;
}

@media all and (max-width: 950px) {
  #equitise-raise-banner {
    flex-direction: column;
    padding: 40px;
  }
  #equitise-raise-banner .equitise-raise-banner-info {
    text-align: center;
  }
  #equitise-raise-banner .equitise-raise-banner-info h3 {
    text-align: center;
    line-height: 26px;
    margin-bottom: 10px;
  }
  #equitise-raise-banner .equitise-raise-banner-info span {
    text-align: center;
    line-height: initial;
  }
}

#equitise-raise-banner h3 {
  font-family: latoregular;
  font-size: 24px;
  line-height: 36px;
}

#equitise-raise-banner span {
  font-family: latolight;
  max-width: 600px;
}

#equitise-raise-banner button {
  margin: 10px;
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: #fff;
  border: 1px solid #fff;
  color: #469edc;
  border-radius: 2px;
  transition: background border 0.3s ease;
  width: 200px;
}

#equitise-raise-banner button:hover {
  background: #469edc;
  border: 1px solid #469edc;
  color: #fff;
  cursor: pointer;
}

#equitise-raise-banner .equitise-raise-banner-info {
  width: 100%;
  max-width: 650px;
  line-height: 24px;
  margin-bottom: 5px;
}

#equitise-raise-banner .clock-timer {
  max-width: 400px;
}

@media all and (max-width: 950px) {
  #equitise-raise-banner .clock-timer {
    text-align: center;
    margin: 0 auto;
  }
}

@media all and (max-width: 950px) {
  #equitise-raise-banner #wrapping-clock {
    text-align: center;
  }
}

#equitise-raise-banner #CountDownCanvas {
  width: 500px;
}

@media all and (max-width: 950px) {
  #equitise-raise-banner #CountDownCanvas {
    width: 350px;
    margin: 25px auto 0;
    display: block;
  }
}

@media all and (max-width: 650px) {
  #equitise-raise-banner #CountDownCanvas {
    width: 300px;
  }
}

@media all and (max-width: 570px) {
  #equitise-raise-banner #CountDownCanvas {
    width: 250px;
  }
}

#equitise-raise-banner .equitise-raise-form {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

@media all and (max-width: 950px) {
  #equitise-raise-banner .equitise-raise-form {
    margin: 40px;
  }
}

#equitise-raise-banner .equitise-raise-form input {
  height: 40px;
  width: 200px;
  margin: 10px;
  text-align: center;
  font-size: 16px;
  font-family: 'latolight';
  border-radius: 2px;
  border: 1px solid #fff;
  color: #5f5f5f;
}

/*---------------------------
      Mina CSS
-----------------------------*/
/* Homepage - Equity Crowdfunding */
.mn-video-container {
  text-align: center;
  border: 1px solid #dad6d6;
  position: relative;
}

.mn-video-container .img-play-botton {
  position: absolute;
  top: 33%;
  right: 45%;
  width: 120px;
  cursor: pointer;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-video-container .img-play-botton {
    right: 42%;
  }
}

@media all and (max-width: 736px) {
  .mn-video-container .img-play-botton {
    right: 43%;
    width: 42px;
  }
}

.mn-dollar-sign-banner {
  background-image: url(../../images/callout-bg-min.jpg);
  width: 100%;
  height: 380.5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 94px;
  position: relative;
  align-items: center;
}

@media all and (max-width: 736px) {
  .mn-dollar-sign-banner {
    padding-top: 30px;
  }
}

.mn-dollar-sign-banner img {
  text-align: center;
  display: block;
  margin: auto;
  margin-bottom: 43px;
}

.mn-dollar-sign-banner .mn-invest-50dollar {
  font-family: latolight;
  font-size: 29.9px;
  text-align: center;
  color: #ffffff;
  margin-top: 43px;
  margin: auto;
  max-width: 564px;
  line-height: 40px;
}

@media all and (max-width: 736px) {
  .mn-dollar-sign-banner .mn-invest-50dollar {
    font-size: 20px;
    width: 80%;
  }
}

@media all and (max-width: 1200px) {
  .mn-dollar-sign-banner {
    background-image: url(../../images/home-callout-bg-min.jpg);
  }
}

.mn-case-study {
  background-image: url(../../images/case-study-bg-min.jpg);
  width: 100%;
  height: 602px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 94px;
  position: relative;
  align-items: center;
  color: #fff;
  line-height: 10px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study {
    padding-top: 244px;
  }
}

@media all and (max-width: 736px) {
  .mn-case-study {
    padding-top: 203px;
  }
}

.mn-case-study .container {
  margin-left: 0;
  padding-left: 0;
}

@media all and (max-width: 736px) {
  .mn-case-study .container {
    padding-right: 0;
  }
}

.mn-case-study .container .mn-case-study-briefcase {
  margin-left: 252px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #de518e;
  vertical-align: middle;
}

@media all and (max-width: 736px) {
  .mn-case-study .container .mn-case-study-briefcase {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study .container .mn-case-study-briefcase {
    margin-left: 116px;
  }
}

.mn-case-study .container .mn-ceo-name {
  margin-left: 252px;
  margin-bottom: 10px;
  font-size: 20px;
}

@media all and (max-width: 736px) {
  .mn-case-study .container .mn-ceo-name {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study .container .mn-ceo-name {
    margin-left: 116px;
  }
}

.mn-case-study .mn-case-study-pink {
  width: 654px;
  height: 67px;
  margin-top: 30px;
  margin-bottom: 60px;
  background-color: #de518e;
  padding-top: 15px;
}

@media all and (max-width: 736px) {
  .mn-case-study .mn-case-study-pink {
    width: 100%;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study .mn-case-study-pink {
    width: 511px;
  }
}

.mn-case-study .mn-case-study-pink .mn-raise {
  margin-left: 252px;
  font-size: 18px;
}

@media all and (max-width: 736px) {
  .mn-case-study .mn-case-study-pink .mn-raise {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study .mn-case-study-pink .mn-raise {
    margin-left: 116px;
  }
}

.mn-case-study .mn-case-study-pink .mn-raise #mn-bg-num {
  font-size: 30px;
}

@media all and (max-width: 736px) {
  .mn-case-study .mn-case-study-pink .mn-raise #mn-bg-num {
    font-size: 25px;
  }
}

.mn-case-study .mn-case-study-pink .mn-customer-quote {
  margin-left: 252px;
  margin-top: 60px;
}

@media all and (max-width: 736px) {
  .mn-case-study .mn-case-study-pink .mn-customer-quote {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-case-study .mn-case-study-pink .mn-customer-quote {
    margin-left: 116px;
  }
}

@media all and (max-width: 736px) {
  .mn-case-study {
    background-image: url(../../images/home-case-study-bg-min.jpg);
  }
}

#awards {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #ebebeb;
}

@media all and (max-width: 736px) {
  #awards {
    display: none;
  }
}

#awards .awards-item h4, #awards .awards-item p {
  text-align: center;
  line-height: 24px;
}

#awards .awards-item .img-wrap {
  min-height: 40px;
  position: relative;
  margin-top: 60px;
  margin-bottom: 20px;
  cursor: pointer;
}

#awards .awards-item img {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 300px;
}

#awards .awards-item h4 {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 700;
}

#brands {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #fff;
}

@media all and (max-width: 992px) {
  #brands {
    padding-bottom: 60px;
  }
}

#brands .mn-brands-content {
  margin-top: 94px;
  margin-bottom: 121px;
}

@media all and (max-width: 992px) {
  #brands .mn-brands-content {
    margin-bottom: 30px;
  }
}

#brands img {
  vertical-align: middle;
  height: auto;
  width: auto;
  margin: 0 auto;
}

@media all and (max-width: 992px) {
  #brands img {
    max-width: 250px;
  }
}

.mn-home-blur-box-container {
  padding-left: 0;
  padding-right: 5px;
  background-color: white;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-home-blur-box-container {
    padding-right: 0;
  }
}

@media all and (max-width: 736px) {
  .mn-home-blur-box-container {
    padding-right: 0;
  }
}

.mn-home-pink-blur-box-container {
  padding-right: 0;
  background-color: white;
  padding-left: 5px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-home-pink-blur-box-container {
    padding-left: 0;
  }
}

@media all and (max-width: 736px) {
  .mn-home-pink-blur-box-container {
    padding-left: 0;
  }
}

.mn-home-box-title-cont {
  width: 100%;
  height: 226px;
}

@media all and (max-width: 736px) {
  .mn-home-box-title-cont {
    height: 200px;
  }
}

.mn-home-box-title-cont .mn-home-box-title {
  font-size: 30px;
  text-align: center;
  font-family: latoregular;
}

@media all and (max-width: 736px) {
  .mn-home-box-title-cont .mn-home-box-title {
    font-size: 24px;
  }
}

.mn-home-box-title-cont .subtitle-white {
  color: white;
  font-size: 24px;
  font-family: latolight;
  max-width: 462px;
  margin: 40px auto;
  text-align: center;
  line-height: 27px;
}

@media all and (max-width: 736px) {
  .mn-home-box-title-cont .subtitle-white {
    font-size: 20px;
  }
}

.first-col {
  background-image: url(../../images/blue-blur.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 114px;
  position: relative;
  text-align: center;
  color: #fff;
}

@media all and (max-width: 736px) {
  .first-col {
    padding-top: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.second-col {
  background-image: url(../../images/pink-blur.jpg);
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding-top: 114px;
  position: relative;
  text-align: center;
  color: #fff;
}

@media all and (max-width: 736px) {
  .second-col {
    padding-top: 70px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.mn-home-white-center-btn {
  margin: auto;
  max-width: 253px;
  height: 55px;
  background-color: #fff;
  padding: 20px;
  color: #469edc;
  text-align: center;
  font-size: 15px;
  font-family: latoregular;
  margin-top: 40px;
  transition: .3s;
}

.mn-home-white-center-btn:hover {
  opacity: .8;
}

.home-btn-container-blur {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 736px) {
  .home-btn-container-blur {
    padding-left: 0;
  }
}

.home-btn-container-blur .mn-home-blue-btn-center {
  height: 55px;
  background-color: #ffffff;
  padding: 19px 35px;
  color: #469edc;
  margin-right: 20px;
  min-width: 253px;
  text-align: center;
  font-family: latoregular;
  transition: .3s;
}

@media all and (max-width: 500px) {
  .home-btn-container-blur .mn-home-blue-btn-center {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.home-btn-container-blur .mn-home-blue-btn-center:hover {
  opacity: .8;
}

.home-btn-container-blur .pink {
  color: #de518e;
}

.home-btn-container-blur .mn-home-border-white-btn-center {
  width: 253px;
  height: 55px;
  border: solid 2px #ffffff;
  padding: 16px 25px;
  color: #fff;
  text-align: center;
  font-family: latoregular;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-btn-container-blur .mn-home-border-white-btn-center:hover {
  background-color: #469edc;
  border-style: solid;
  border-color: #469edc;
  border-width: 2px;
}

.home-btn-container-blur .mn-pink-hover {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.home-btn-container-blur .mn-pink-hover:hover {
  background-color: #de518e;
  border-style: solid;
  border-color: #de518e;
  border-width: 2px;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
#awards-mobile {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ebebeb;
}

#awards-mobile .container {
  padding-right: 0;
  padding-left: 0;
}

#awards-mobile .carousel-inner {
  height: 250px;
}

#awards-mobile .carousel-control .glyphicon-chevron-right, #awards-mobile .carousel-control .icon-next {
  margin-right: 0 !important;
}

#awards-mobile .carousel-control .glyphicon-chevron-left, #awards-mobile .carousel-control .icon-prev {
  margin-left: 0 !important;
}

#awards-mobile .awards-item h4, #awards-mobile .awards-item p {
  text-align: center;
  line-height: 24px;
}

#awards-mobile .awards-item .img-wrap {
  min-height: 40px;
  position: relative;
  margin-top: 60px;
  margin-bottom: 20px;
}

@media all and (max-width: 400px) {
  #awards-mobile .awards-item .img-wrap {
    margin-top: 37px;
  }
}

#awards-mobile .awards-item img {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 300px;
}

@media all and (max-width: 400px) {
  #awards-mobile .awards-item img {
    max-width: 270px;
  }
}

#awards-mobile .awards-item h4 {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 700;
}

@media screen and (min-width: 736px) {
  #awards-mobile {
    display: none;
  }
}
