@import 'extensions/variables';
@import 'extensions/mixins';




#ms-top-banner {
  position: relative;
  height: calc(100vh - 70px);
  width: 100%;
  @include mquery(700px) {
    height: 610px;
  }

  .ms-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 70px);
    @include mquery(700px) {
      height: 610px;
    }
    @include mquery(600px) {
      background-position: right;
    }
  }

  .ms-logo {
     margin-bottom: 50px;
     @include mquery(500px) {
       width: 200px;
     }
  }

  .ms-carousel-control{
     width: 6% !important;
  }


    .ms-banner-one {
      background-image: url(/images/landing-pages/makerandson-banner-2.jpg);
      @include mquery(600px) {
        background-image: url(/images/landing-pages/makerandson-banner-2-mobile.jpg);
      }
    }

    .ms-banner-two {
      background-image: url(/images/landing-pages/makerandson-banner-5.jpg);
      @include mquery(1024px) {
        background-image: url(/images/landing-pages/makerandson-banner.jpg);
      }
      @include mquery(600px) {
        background-image: url(/images/landing-pages/makerandson-banner-mobile.jpg);
      }
    }

    .ms-banner-three {
      background-image: url(/images/landing-pages/makerandson-banner-3.jpg);
      @include mquery(600px) {
        background-image: url(/images/landing-pages/makerandson-banner-3-mobile.jpg);
      }
    }


    .ms-top-content-cnt {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate( -50%, 0);
      height: calc(100vh - 70px);
      padding-top: 150px;
      background-color: rgba(1,1,1,.5);
      padding-right: 15px;
      padding-left: 15px;

      @include mquery(1024px) {
        width: 510px;
        padding-top: 50px;
      }

      @include mquery(730px) {
        width: 100%;
      }

      @include mquery(700px) {
        height: 610px;
      }

    }

    .ms-title-width {
      max-width: 660px;
      margin-left: auto;
      margin-right: auto;
      @include mquery(1650px) {
        max-width: 520px;
      }
    }

//carousel fade control

  .carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
  }

  .carousel-fade .carousel-inner .active {
    opacity: 1;
  }

  .carousel-fade .carousel-inner .active.left,
  .carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
  }

  .carousel-fade .carousel-inner .next.left,
  .carousel-fade .carousel-inner .prev.right {
    opacity: 1;
  }

  .carousel-fade .carousel-control {
    z-index: 2;
  }


  @media all and (transform-3d), (-webkit-transform-3d) {
      .carousel-fade .carousel-inner > .item.next,
      .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.prev,
      .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
      .carousel-fade .carousel-inner > .item.next.left,
      .carousel-fade .carousel-inner > .item.prev.right,
      .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
      }
  }

}





#ms-pro-banner {
  .product-header {
    color: #171717;
    margin-bottom: 60px;
    @include mquery(768px) {
      margin-bottom: 20px;
    }
  }
}


.ms-first-content {
  padding: 40px;
  @include mquery(1200px) {
    padding: 40px 40px 0 40px;
  }
}


.ms-second-content {
  padding: 40px;
  @include mquery(1200px) {
    padding: 0 40px 40px 40px;
  }
}


.ms-equal-cnt-sec {
  display: flex;
  width: 100%;
  @include mquery(1200px) {
    flex-direction: column;
  }

}


  .ms-half-banner {
      background-image: url(/images/landing-pages/armchair-with-footstool.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mquery(1200px) {
        height: 424px;
      }

  }

  .ms-second-half-banner {
      background-image: url(/images/landing-pages/makerandson-half-banner.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include mquery(1200px) {
        height: 424px;
      }
  }

  .no-margin {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ms-button-style {
    background-color: #469edc;
    border: 1px solid #469edc;
  }

  .ms-view-offer-btn {
    border: 1px solid #469edc;
    color: white;
    border-radius: 4px;
    height: 49px;
    width: 200px;
    background-color: #469edc;
    animation: bounce 3s;
  }


  .ms-video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: black;
    @include mquery(1024px) {
      height: 100%;
    }
  }
