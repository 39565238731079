/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
BROWSE OFFERS
---------------------------------------------------------- */
.invest-filter-options-wrapper {
  float: left;
  width: 100%;
  min-height: 70px;
  margin: 30px 0px 0px 0px;
  padding: 0px 15px 0px 140px;
  position: relative;
  background-color: #469edc;
}

.invest-filter-options-wrapper .invest-filter-title {
  position: absolute;
  top: 20px;
  left: 20px;
  margin: 0px;
  padding: 0px;
  font-size: 28px;
  font-family: 'latolight';
  color: #ffffff;
}

.invest-filter-options-wrapper .invest-filter-options {
  float: right;
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.invest-filter-options-wrapper .invest-filter-options > form {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group {
  float: left;
  margin: 0px;
  padding: 0px 10px 15px 0px;
  position: relative;
  /* if-select-field ends here */
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group:last-of-type {
  padding-right: 0px;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a {
  float: left;
  height: 38px;
  width: 130px;
  margin: 0px;
  padding: 9px 34px 0px 10px;
  font-family: 'latolight';
  font-size: 14px;
  color: #ffffff;
  background-position: right 10px center;
  background-repeat: no-repeat;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a.active, .invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a:hover {
  background-color: #ffffff;
  color: #469edc;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a.if-latest-filter {
  background-image: url("../../images/if-latest-filter-icon.png");
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a.if-popular-filter {
  background-image: url("../../images/if-popular-filter-icon.png");
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a.if-funded-filter {
  background-image: url("../../images/if-funded-filter-icon.png");
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group > a.if-comingsoon-filter {
  background-image: url("../../images/if-comingsoon-filter-icon.png");
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field {
  float: left;
  width: 130px;
  height: 38px;
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field:before {
  background-color: #469edc;
  background-image: url(../../images/if-select-field-arrow.png);
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  height: 36px;
  width: 36px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field > select {
  float: left;
  width: 100%;
  height: 36px;
  margin: 0px;
  padding: 0px 35px 3px 10px;
  font-family: 'latolight';
  font-size: 14px;
  color: #ffffff;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  z-index: 3;
  position: relative;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field > select > option {
  background-color: #BBBBBB;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff;
  opacity: 1;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-select-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field {
  float: left;
  width: 130px;
  height: 38px;
  margin: 0px;
  padding: 0px 36px 3px 10px;
  font-family: 'latolight';
  font-size: 14px;
  color: #ffffff;
  background-color: #469edc;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field:hover, .invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field:focus {
  background-color: #2686c9;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff;
  opacity: 1;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.invest-filter-options-wrapper .invest-filter-options > form .if-form-group .if-search-field-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  background-image: url("../../images/if-search-icon.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.invest-tiles-container {
  float: left;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
}

.invest-tiles-container .invest-tiles-half {
  float: left;
  margin: 0px;
  padding: 10px 0px 10px 0px;
  width: 50%;
  /* Invest tile status ends here */
  /* Invest tile syndicate badge ends here */
}

.invest-tiles-container .invest-tiles-half:nth-child(2n+1) {
  padding-right: 10px;
}

.invest-tiles-container .invest-tiles-half:nth-child(2n+2) {
  padding-left: 10px;
}

@media all and (max-width: 580px) {
  .invest-tiles-container .invest-tiles-half {
    display: inline-block;
    float: none;
    width: 100%;
    max-width: 360px;
  }
  .invest-tiles-container .invest-tiles-half:nth-child(2n+1) {
    padding-right: 0px;
  }
  .invest-tiles-container .invest-tiles-half:nth-child(2n+2) {
    padding-left: 0px;
  }
}

.invest-tiles-container .invest-tiles-half .single-invest-tile {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  background-color: #ffffff;
  border-style: solid;
  border-color: #f1f1f1;
  border-width: 1px;
  -webkit-box-shadow: 1px 1px 2px #f1f1f1;
  -moz-box-shadow: 1px 1px 2px #f1f1f1;
  -o-box-shadow: 1px 1px 2px #f1f1f1;
  box-shadow: 1px 1px 2px #f1f1f1;
}

.invest-tiles-container .invest-tiles-half .sit-status {
  position: absolute;
  top: 17px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  max-width: 130px;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-status > div {
  float: left;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span {
  float: left;
  width: 100%;
  height: 30px;
  margin: 0px;
  padding: 8px 10px;
  font-family: 'latoblack';
  font-size: 13px;
  color: #ffffff;
  text-align: left;
  position: relative;
  z-index: 1;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.completed {
  display: none;
  background-color: #aad155;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.completed:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: 0px;
  right: -5px;
  z-index: 2;
  border-bottom: 15px solid transparent;
  border-left: 5px solid #aad155;
  border-top: 15px solid transparent;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.completed:before {
  background-image: url("../../images/hot-tile-completed-checkmark.png");
  background-repeat: no-repeat;
  bottom: 0px;
  right: -3px;
  width: 27px;
  height: 22px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  content: "";
  z-index: 3;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.completed.active {
  display: inline-block;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.closed {
  display: none;
  background-color: #dd5871;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.closed:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: 0px;
  right: -5px;
  z-index: 2;
  border-bottom: 15px solid transparent;
  border-left: 5px solid #dd5871;
  border-top: 15px solid transparent;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.closed:before {
  background-image: url("../../images/hot-tile-closed-icon.png");
  background-repeat: no-repeat;
  bottom: 0px;
  right: -3px;
  width: 23px;
  height: 22px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  content: "";
  z-index: 3;
}

.invest-tiles-container .invest-tiles-half .sit-status > div > span.closed.active {
  display: inline-block;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper {
  position: absolute;
  top: 0px;
  right: 10px;
  margin: 0px;
  padding: 0px;
  z-index: 5;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper .sit-syndicate-badge {
  float: left;
  width: 29px;
  height: 24px;
  margin: 0px;
  padding: 0px;
  background-color: #5adfd1;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper .sit-syndicate-badge:after {
  position: absolute;
  bottom: -5px;
  right: 0px;
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  border-right: 14px solid transparent;
  border-left: 15px solid transparent;
  border-top: 5px solid #5adfd1;
  content: "";
  z-index: 2;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper .sit-syndicate-badge:before {
  position: absolute;
  bottom: -13px;
  right: -11px;
  margin: 0px;
  padding: 0px;
  width: 32px;
  height: 30px;
  background-image: url("../../images/hot-tile-syndicate-icon2.png");
  background-repeat: no-repeat;
  background-size: 21px 20px;
  content: "";
  z-index: 3;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper .sit-badges-tooltip {
  background-color: #7089a3;
  border-radius: 2px;
  bottom: 35px;
  color: #ffffff;
  font-family: "latolight";
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  min-height: 24px;
  min-width: 170px;
  opacity: 0;
  padding: 5px 10px 6px;
  position: absolute;
  right: -2px;
  visibility: hidden;
  z-index: 100;
}

.invest-tiles-container .invest-tiles-half .sit-syndicate-badge-wrapper .sit-badges-tooltip:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #7089a3;
  bottom: -6px;
  content: "";
  height: 0;
  position: absolute;
  right: 10px;
  width: 0;
}

.invest-tiles-container .invest-tiles-half .sit-top-info {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 10px 10px 10px 150px;
  min-height: 150px;
  position: relative;
}

@media all and (max-width: 775px) {
  .invest-tiles-container .invest-tiles-half .sit-top-info {
    padding: 0px;
  }
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-offer-image {
  height: 130px;
  left: 10px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 10px;
  width: 130px;
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-offer-image > img {
  height: 100%;
  left: -40px;
  margin: 0;
  padding: 0;
  position: absolute;
}

@media all and (max-width: 775px) {
  .invest-tiles-container .invest-tiles-half .sit-top-info .sit-offer-image {
    float: left;
    height: auto;
    left: 0px;
    top: 0px;
    width: 100%;
    position: relative;
  }
  .invest-tiles-container .invest-tiles-half .sit-top-info .sit-offer-image > img {
    float: left;
    width: 100%;
    left: 0px;
    position: relative;
    height: auto;
  }
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info {
  float: left;
  margin: 0;
  min-height: 130px;
  padding: 0 0 35px;
  position: relative;
  width: 100%;
}

@media all and (max-width: 775px) {
  .invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info {
    padding: 0px 10px 35px;
  }
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info .sit-info-title {
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 20px;
  margin: 10px 0 0;
  padding: 0 35px 0 0;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info p.sit-info-desc {
  color: #666666;
  float: left;
  font-family: "latolight";
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0 0;
  padding: 0;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info span.sit-info-location {
  background-image: url("../../images/offer-tile-location.png");
  background-position: 2px center;
  background-repeat: no-repeat;
  bottom: 10px;
  color: #666666;
  font-family: "latolight";
  font-size: 12px;
  left: 0;
  margin: 0;
  padding: 0 0 0 20px;
  position: absolute;
  width: 100%;
}

@media all and (max-width: 775px) {
  .invest-tiles-container .invest-tiles-half .sit-top-info .sit-brief-info span.sit-info-location {
    background-position: 12px center;
    bottom: 14px;
    padding: 0px 0px 0px 30px;
  }
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont {
  background-color: #BCC5CA;
  border-style: solid;
  border-color: #ebeef0;
  border-top-width: 1px;
  border-bottom-width: 1px;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li {
  border-style: solid;
  border-color: #c2d1dc;
  border-right-width: 1px;
  float: left;
  height: 60px;
  margin: 0;
  padding: 0;
  width: 25%;
}

@media all and (max-width: 1110px) {
  .invest-tiles-container .invest-tiles-half .sit-stats-cont > li {
    width: 50%;
  }
  .invest-tiles-container .invest-tiles-half .sit-stats-cont > li:nth-child(2n+2) {
    border-right: 0px;
  }
  .invest-tiles-container .invest-tiles-half .sit-stats-cont > li:nth-child(-n+2) {
    border-style: solid;
    border-color: #c2d1dc;
    border-bottom-width: 1px;
  }
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li.last-stat {
  width: 100%;
  border-top-width: 1px;
  height: 38px;
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li.last-stat > span {
  width: 50%;
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li.last-stat > span.sit-stat-name {
  margin-top: 10px;
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li.last-stat > span.sit-stat-value {
  text-align: right;
}

@media all and (max-width: 1110px) {
  .invest-tiles-container .invest-tiles-half .sit-stats-cont > li.last-stat > span.sit-stat-value {
    text-align: left;
  }
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li .sit-stat-name {
  color: #ffffff;
  float: left;
  font-family: "latolight";
  font-size: 13px;
  margin: 9px 0 0;
  padding: 0 10px;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-stats-cont > li .sit-stat-value {
  color: #ffffff;
  float: left;
  font-family: "latobold";
  font-size: 16px;
  margin: 9px 0 0;
  padding: 0 10px;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-tags-cont {
  background-image: url("../../images/offer-tile-tags.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  float: left;
  margin: 0;
  padding: 10px 0 12px 32px;
  width: 100%;
}

.invest-tiles-container .invest-tiles-half .sit-tags-cont > li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.invest-tiles-container .invest-tiles-half .sit-tags-cont > li > a {
  color: #666666;
  float: left;
  font-family: "latolight";
  font-size: 12px;
  margin: 0 5px 0 0;
  padding: 0;
  text-decoration: none;
}

.invest-tiles-container .invest-tiles-half .sit-tags-cont > li > a:after {
  color: #469edc;
  content: ", ";
  font-family: "latolight";
  font-size: 12px;
}

.invest-tiles-container .invest-tiles-half .sit-tags-cont > li > a:hover {
  text-decoration: underline;
}
