@import 'extensions/variables';
@import 'extensions/mixins';
@import 'extensions/extend';



#bricklane-top-banner {
  @include top-banner('/images/landing-pages/banners/bricklane-banner_home.png', #000, '/images/landing-pages/banners/bricklane-banner_home.png');
	@extend %background-property;
  #bricklane-top-banner-layer {
    @include top-banner-layer(rgba(0,0,0,.6));
    @extend %flex-center;
    .bricklane-logo {
      @include logo-style(40px, 350px, 250px);
    }
    .bricklane-title-width {
      max-width: 800px;
      margin: 0 auto 40px;
    }
  }
}

#bricklane-paralax-sec {
  @include parallax-sec('/images/landing-pages/banners/bricklane-paralax-banner.JPG', '/images/landing-pages/banners/bricklane-paralax-banner-mobile.jpg');
  .bricklane-paralax-layer {
    @include parallax-layer(rgba(1, 1, 1, 0.7));
  }
}

.bricklane-intro-bk {
  background-image: url(/images/landing-pages/banners/BrickLane_QVM_KateShanasy_Feb2019-2838.jpg);
  @extend %background-property;
  height: 360px;
}

.bricklane-why-raise-bk {
  background-image: url(/images/landing-pages/banners/BrickLaneBrewing_KateShanasy_Oct2018-9424.jpg);
  @extend %background-property;
  height: 360px;
}

#bricklane-story-sec {
  padding: 60px 0;
  .bricklane-story-text {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 60px 0 0;
    }
  }
}

#bricklane-why-raise-sec {
  padding: 60px 0;
  .bricklane-why-raise-text {
    padding: 0 20px;
    @include mquery(1200px) {
      padding: 60px 0 0;
    }
  }
}

#bricklane-partnership-with-sec {
  background: #ef5d6a;
  padding: 60px 0;
}

#bricklane-last-form-cnt {
  background: #ef5d6a;
  padding: 60px 0;
}

.bricklane-link {
  @include link(#ef5d6a, #ef5d6a, underline);
}

.bricklane-bottom-link {
  @include link(#26333b, #fff, underline);
}

.bricklane-bottom-button-style {
  @include button-style(#26333b, #fff, #f5f1e5, #26333b, 1);
}

.bricklane-button-style {
  @include button-style(#ef5d6a, #fff, #f5f1e5, #26333b, 1);
}
