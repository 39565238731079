/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
EQUITISE TEAM
---------------------------------------------------------- */
.team-wrapper {
  padding-top: 80px;
  padding-bottom: 120px;
}

.team-wrapper .team-title {
  font-size: 36px;
  color: #7e7e7e;
  text-align: center;
  font-family: latoregular;
}

.team-wrapper .team-section {
  float: left;
  width: 100%;
  margin: 50px 0px 0px 0px;
  padding: 0px;
}

.team-wrapper .team-section > span.team-subheading {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  font-family: 'latoregular';
  font-size: 20px;
  color: #7e7e7e;
  text-align: center;
}

.team-wrapper .team-section .team-blocks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

@media all and (max-width: 1200px) {
  .team-wrapper .team-section .team-blocks {
    justify-content: center;
  }
}

.team-wrapper .team-section .team-blocks > .team-block {
  float: left;
  width: 300px;
  margin: 0px;
  min-height: 515px;
  margin: 40px;
}

.team-wrapper .team-section .team-blocks > .team-block > div {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  position: relative;
}

.team-wrapper .team-section .team-blocks > .team-block > div > img {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  width: 300px;
  height: 300px;
}

@media all and (max-width: 320px) {
  .team-wrapper .team-section .team-blocks > .team-block > div > img {
    width: 250px;
    height: 250px;
  }
}

.team-wrapper .team-section .team-blocks > .team-block > div span.team-member-name {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 10px;
  text-align: center;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
}

.team-wrapper .team-section .team-blocks > .team-block > div span.team-member-role {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 15px;
  color: #469edc;
  text-align: center;
}

.team-wrapper .team-section .team-blocks > .team-block > div a.team-member-linkedin {
  display: inline-block;
  width: 21px;
  height: 21px;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  background-image: url(../../images/eq-team-linkedin.png);
  background-repeat: no-repeat;
}

.team-wrapper .team-section .team-blocks > .team-block > div .member-info-cnt {
  height: 96px;
}

.team-wrapper .team-section .team-blocks > .team-block > div .member-info-cnt .member-info {
  width: 100%;
  margin: 15px 0px 0px 0px;
  padding: 0px;
  font-family: 'latolight';
  font-size: 14px;
  color: #7e7e7e;
  line-height: 150%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
}

.team-wrapper .team-section .team-blocks > .team-block > div .member-info-cnt .expand {
  line-height: 150%;
  position: absolute;
  top: 0;
  background-color: rgba(1, 1, 1, 0.7);
  color: white;
  width: 100%;
  padding: 20px;
  height: 500px;
  line-height: 150%;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  transition: height .5s;
  -webkit-line-clamp: 50;
}

.team-wrapper .team-section .team-blocks > .team-block > div .more-less {
  padding: 8px;
  border: 1px solid #7e7e7e;
  cursor: pointer;
  float: left;
  width: 140px;
  text-align: center;
}

.team-wrapper .team-section .team-blocks > .team-block > div .more-less:hover {
  background-color: rgba(1, 1, 1, 0.7);
  border: none;
  color: white;
}
