/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#ph-top-banner {
  background-image: url(/images/landing-pages/purahealth-leaf-desktop.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: relative;
  height: 800px;
}

@media all and (max-width: 1440px) {
  #ph-top-banner {
    height: calc(100vh - 70px);
  }
}

@media all and (max-width: 1024px) {
  #ph-top-banner {
    background-image: url(/images/landing-pages/purahealth-leaf-ipad.jpg);
    height: 650px;
  }
}

@media all and (max-width: 600px) {
  #ph-top-banner {
    background-image: url(/images/landing-pages/purahealth-leaf-mobile.jpg);
    height: calc(100vh - 70px);
  }
}

#ph-top-banner .ph-middle-tone-title {
  font-size: 46px;
}

@media all and (max-width: 768px) {
  #ph-top-banner .ph-middle-tone-title {
    font-size: 26px;
  }
}

#ph-top-banner .ph-layer-removed {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

#ph-top-banner .ph-logo {
  margin-bottom: 50px;
}

@media all and (max-width: 768px) {
  #ph-top-banner .ph-logo {
    width: 250px;
    margin-top: 50px;
  }
}

#ph-top-banner .ph-title-width {
  max-width: 780px;
  margin-right: auto;
  margin-left: auto;
}

#ph-top-banner .ph-layer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.4);
}

@media all and (max-width: 768px) {
  #ph-top-banner .ph-layer {
    height: 100%;
    width: 100%;
  }
}

#ph-top-banner .ph-top-banner-cnt {
  width: 660px;
  height: 100%;
  padding: 10% 15px 0;
  position: absolute;
  left: 10%;
  background-color: rgba(1, 1, 1, 0.4);
}

@media all and (max-width: 1024px) {
  #ph-top-banner .ph-top-banner-cnt {
    width: 100%;
    left: 0;
  }
}

@media all and (max-width: 767px) {
  #ph-top-banner .ph-top-banner-cnt {
    padding-top: 0;
  }
}

#middle-tone {
  padding-top: 80px;
  padding-bottom: 60px;
}

@media all and (max-width: 768px) {
  #middle-tone {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

#middle-tone .middle-tone-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 34px;
  line-height: 2rem;
}

@media (max-width: 736px) {
  #middle-tone .middle-tone-title {
    font-size: 28px;
  }
}

#ph-first-content-cnt .ph-first-content-cnt {
  padding-top: 30px;
}

#ph-first-content-cnt .ph-first-half-banner {
  background: url(/images/landing-pages/purahealth-image-half-min.jpg) no-repeat center;
  background-size: cover;
  height: 100%;
  /*height: 1200px;
    @include mquery(1350px) {
      height: 1350px
    }
    @include mquery(1200px) {
      height: 210px;
      background-position: center;
    }*/
}

.ph-first-half-banner-cnt {
  height: 1064px;
  overflow: hidden;
  padding-left: 0;
}

@media all and (max-width: 1500px) {
  .ph-first-half-banner-cnt {
    height: 1220px;
  }
}

#ph-sec-content-cnt .ph-sec-half-banner {
  background-image: url(/images/landing-pages/purehealth-half-banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 660px;
}

@media all and (max-width: 1504px) {
  #ph-sec-content-cnt .ph-sec-half-banner {
    height: 760px;
  }
}

@media all and (max-width: 1272px) {
  #ph-sec-content-cnt .ph-sec-half-banner {
    height: 760px;
  }
}

@media all and (max-width: 1200px) {
  #ph-sec-content-cnt .ph-sec-half-banner {
    height: 210px;
  }
}

#ph-sec-content-cnt .ph-sec-content {
  padding-top: 50px;
}

@media all and (max-width: 1024px) {
  #ph-sec-content-cnt .ph-sec-content {
    padding-top: 0;
  }
}

.ph-button-style {
  background-color: rgba(1, 1, 1, 0.8);
  transition: all 0.5s ease;
}

.ph-button-style:hover {
  background-color: black;
}

.ph-view-offer-btn {
  border-radius: 4px;
  width: 200px;
  text-align: center;
  background-color: rgba(1, 1, 1, 0.8);
  transition: all 0.5s ease;
  animation: bounce 3s;
  color: #fff;
  padding: 16px;
}

.ph-view-offer-btn:hover {
  background-color: black;
  color: #fff;
}

#sec-form-sec {
  padding-top: 70px;
  padding-bottom: 70px;
}

.purahealth-list {
  font-size: 1.05rem;
  padding-left: 30px;
  margin: 20px 0;
}

.purahealth-list > li {
  line-height: 1.7rem;
}
