/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
/* ----------------------------------------------------------
CREATE PORJECT PAGE
---------------------------------------------------------- */
/* Create project tabs */
ul.create-project-tabs {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px;
}

ul.create-project-tabs > li {
  float: left;
  width: 16.66%;
  min-height: 39px;
  margin: 0px;
  padding: 0px 0px 0px 1px;
  list-style: none;
  position: relative;
}

ul.create-project-tabs > li:first-of-type {
  padding-left: 0px;
}

@media all and (max-width: 920px) {
  ul.create-project-tabs > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 500px) {
  ul.create-project-tabs > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 400px) {
  ul.create-project-tabs > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 39px;
  margin: 0px;
  padding: 10px;
  background-color: #ffffff;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
  text-decoration: none;
  text-align: center;
}

ul.create-project-tabs > li > a:hover {
  text-decoration: underline;
}

ul.create-project-tabs > li > a.active {
  background-color: #469edc;
  color: #ffffff;
}

ul.create-project-tabs > li > a.active:hover {
  text-decoration: none;
}

ul.create-project-tabs > li > a.error {
  background-color: #f67f7f;
  color: #ffffff;
}

ul.create-project-tabs > li > a.error:hover {
  text-decoration: none;
}

ul.create-project-tabs > li > a.error ~ span.cpt-error-number {
  display: inline-block;
}

ul.create-project-tabs > li > span.cpt-error-number {
  display: none;
  position: absolute;
  top: -13px;
  right: 7px;
  width: 24px;
  height: 24px;
  margin: 0px;
  padding: 4px 0px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 12px;
  font-family: 'latoblack';
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  background-color: #f67f7f;
}

ul.create-project-tabs.three-tabs > li {
  width: 33.33%;
}

@media all and (max-width: 850px) {
  ul.create-project-tabs.three-tabs > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 560px) {
  ul.create-project-tabs.three-tabs > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.four-tabs > li {
  width: 25%;
}

@media all and (max-width: 800px) {
  ul.create-project-tabs.four-tabs > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 460px) {
  ul.create-project-tabs.four-tabs > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.five-tabs > li {
  width: 20%;
}

@media all and (max-width: 850px) {
  ul.create-project-tabs.five-tabs > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 550px) {
  ul.create-project-tabs.five-tabs > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 430px) {
  ul.create-project-tabs.five-tabs > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.six-tabs > li {
  width: 16.66%;
}

@media all and (max-width: 920px) {
  ul.create-project-tabs.six-tabs > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 500px) {
  ul.create-project-tabs.six-tabs > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 400px) {
  ul.create-project-tabs.six-tabs > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

/* Create project wrapper */
.create-project-wrapper {
  float: left;
  width: 100%;
  margin: 25px 0px 25px 0px;
  padding: 20px;
  background-color: #ffffff;
}

.create-project-wrapper .cp-form-group-wrapper {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.create-project-wrapper .cp-form-group-wrapper > form {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.create-project-wrapper .default-success-tooltip {
  margin-bottom: 25px;
  margin-top: 0;
}

.create-project-wrapper .default-warning-tooltip.id-info-tooltip {
  display: none;
}

.cp-add-block-btn {
  float: left;
  width: 100%;
  min-height: 200px;
  margin: 0px;
  padding: 125px 100px 56px;
  background-color: #e6f1f8;
  background-image: url(../../images/add-new-block-icon.png);
  background-repeat: no-repeat;
  background-position: center top 60px;
  font-family: 'latobold';
  font-size: 18px;
  color: #99b9cf;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cp-add-block-btn:hover {
  background-color: #f0f5f8;
}

.cp-sections-title {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
  text-transform: uppercase;
}

.cp-sections-title:first-of-type {
  margin-top: 0px;
}

.cp-form-group-ul {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.cp-form-group-ul > li {
  float: left;
  width: 50%;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}

.cp-form-group-ul > li:nth-child(odd) {
  padding-right: 5px;
}

.cp-form-group-ul > li:nth-child(even) {
  padding-left: 5px;
}

@media all and (max-width: 950px) {
  .cp-form-group-ul > li {
    width: 100%;
  }
  .cp-form-group-ul > li:nth-child(odd) {
    padding-right: 0px;
  }
  .cp-form-group-ul > li:nth-child(even) {
    padding-left: 0px;
  }
}

.cp-form-group-ul > li .cp-fg-textarea-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px;
  background-color: #f3f5f6;
  position: relative;
}

.cp-form-group-ul > li .cp-fg-textarea-container .cp-fg-tc-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cp-form-group-ul > li .cp-fg-textarea-container .cp-fg-tc-title > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 15px;
  color: #7e7e7e;
}

.cp-form-group-ul > li .cp-fg-textarea-container > textarea {
  fborder-color: #dfe6eb;
  border-style: solid;
  border-width: 1px;
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 14px;
  height: 140px;
  margin: 15px 0 0;
  padding: 10px;
  width: 100%;
  resize: none;
}

.cp-form-group {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 0px;
  padding: 22px 300px 22px 15px;
  position: relative;
  background-color: #f3f5f6;
}

@media all and (max-width: 1023px) {
  .cp-form-group {
    padding: 22px 235px 22px 15px;
  }
}

@media all and (max-width: 950px) {
  .cp-form-group {
    padding: 22px 365px 22px 15px;
  }
}

@media all and (max-width: 750px) {
  .cp-form-group {
    padding: 22px 265px 22px 15px;
  }
}

@media all and (max-width: 580px) {
  .cp-form-group {
    padding: 15px;
  }
}

.cp-form-group-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 20px 0px 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  position: relative;
}

.cr-fg-input-cont {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 100%;
  max-width: 280px;
}

@media all and (max-width: 1023px) {
  .cr-fg-input-cont {
    max-width: 215px;
  }
}

@media all and (max-width: 950px) {
  .cr-fg-input-cont {
    max-width: 340px;
  }
}

@media all and (max-width: 745px) {
  .cr-fg-input-cont {
    max-width: 240px;
  }
}

@media all and (max-width: 580px) {
  .cr-fg-input-cont {
    position: relative;
    top: auto;
    right: auto;
    float: left;
    max-width: 100%;
    margin-top: 10px;
  }
}

.cr-fg-input-cont > textarea {
  float: left;
  width: 100%;
  height: 112px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 9px 10px;
  font-family: 'latobold';
  font-size: 14px;
  resize: none;
  z-index: 1000;
}

.cp-fg-input-cont {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 100%;
  max-width: 280px;
  /* sfg file input ends here */
}

@media all and (max-width: 1023px) {
  .cp-fg-input-cont {
    max-width: 215px;
  }
}

@media all and (max-width: 950px) {
  .cp-fg-input-cont {
    max-width: 340px;
  }
}

@media all and (max-width: 745px) {
  .cp-fg-input-cont {
    max-width: 240px;
  }
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont {
    position: relative;
    top: auto;
    right: auto;
    float: left;
    max-width: 100%;
    margin-top: 10px;
  }
}

.cp-fg-input-cont > textarea {
  float: left;
  width: 100%;
  height: 112px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 9px 10px;
  font-family: 'latobold';
  font-size: 14px;
  resize: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cp-fg-input-cont > textarea:focus {
  height: 112px;
  z-index: 1000;
}

.cp-fg-input-cont > input[type="text"], .cp-fg-input-cont > input[type="password"] {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 0px 10px;
  font-family: 'latobold';
  font-size: 14px;
}

.cp-fg-input-cont .cp-fg-select-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.cp-fg-input-cont .cp-fg-select-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/select-field-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.cp-fg-input-cont .cp-fg-select-field > select {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px 1px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont .cp-fg-select-field {
    margin: 0px;
  }
}

.cp-fg-input-cont .cp-fg-radiobutton-cont {
  float: right;
  padding: 9px 10px;
}

.cp-fg-input-cont .cp-fg-file-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.cp-fg-input-cont .cp-fg-file-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/file-field-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.cp-fg-input-cont .cp-fg-file-field > input {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  text-indent: -99999px;
  opacity: 0;
  cursor: pointer;
}

.cp-fg-input-cont .cp-fg-file-field > span {
  position: absolute;
  top: 12px;
  left: 0px;
  margin: 0px;
  padding: 0px 42px 3px 15px;
  width: 100%;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont .cp-fg-file-field {
    margin: 0px;
  }
}

.cp-fg-image-preview {
  position: absolute;
  top: 1px;
  left: -45px;
  width: 39px;
  height: 39px;
  cursor: pointer;
  text-align: center;
}

.cp-fg-image-preview .cp-fg-ip-thumbnail {
  float: none;
  display: inline-block;
  max-width: 39px;
  max-height: 39px;
}

.cp-fg-ip-thumbnail-image {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 45px;
  left: 0px;
  width: 150px;
  height: 150px;
  padding: 1px;
  background-color: #e9e9e9;
  border-radius: 3px;
  z-index: 1005;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cp-fg-ip-thumbnail-image:before {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 14px;
  margin: 0px;
  padding: 0px;
  border-top: 6px solid #e9e9e9;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.cp-fg-ip-thumbnail-image > img {
  float: left;
  max-width: 100%;
  max-height: 100%;
  margin: 0px;
  padding: 0px;
  border-radius: 3px;
}

.cp-fg-image-preview > img:hover ~ .cp-fg-ip-thumbnail-image {
  opacity: 1;
  visibility: visible;
}

.cp-form-helper {
  background-color: #9ec0d1;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-family: "latoblack";
  font-size: 12px;
  height: 16px;
  margin: 0;
  padding: 2px 0 0;
  text-align: center;
  width: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cp-form-helper:hover .cp-form-helper-text {
  opacity: 1;
  visibility: visible;
}

.cp-form-helper-text {
  background-color: #6c99af;
  border-radius: 2px;
  bottom: 24px;
  color: #ffffff;
  font-family: "latolight";
  font-size: 12px;
  margin: 0;
  min-height: 22px;
  min-width: 200px;
  padding: 10px;
  position: absolute;
  right: -8px;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  line-height: 130%;
}

.cp-form-helper-text:before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #6c99af;
  bottom: -5px;
  content: "";
  height: 0;
  right: 10px;
  position: absolute;
  width: 0;
}

.cp-action-buttons-cont {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  background-color: #3b739a;
  z-index: 1005;
}

@media all and (max-width: 420px) {
  .cp-action-buttons-cont {
    height: 60px;
    min-height: 60px !important;
    max-height: 60px;
  }
}

.cp-action-buttons-cont > div {
  margin: 0px auto;
  width: 100%;
  max-width: 1200px;
  padding: 8px 10px 0px;
}

@media all and (max-width: 420px) {
  .cp-action-buttons-cont > div {
    padding: 0;
  }
}

.cp-action-buttons-info {
  float: left;
  width: 100%;
  max-width: 300px;
  margin: 0px;
  background-color: #e0edf6;
  background-image: url("../../images/default-info-tooltip-icon.png");
  background-position: 20px 20px;
  background-repeat: no-repeat;
  padding: 14px 15px 16px 63px;
  position: relative;
}

@media all and (max-width: 980px) {
  .cp-action-buttons-info {
    max-width: 100%;
    background-position: 20px 12px;
  }
}

.cp-action-buttons-info > span {
  color: #6195b9;
  float: left;
  font-family: "latoregular";
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cp-action-buttons {
  float: right;
  margin: 0px;
  padding: 15px 0px;
  min-width: 630px;
}

@media all and (max-width: 980px) {
  .cp-action-buttons {
    min-width: 100%;
    text-align: center;
    padding: 15px 20px;
  }
  .cp-action-buttons > a {
    float: none !important;
    display: inline-block;
    margin-bottom: 10px !important;
  }
}

@media all and (max-width: 980px) and (max-width: 456px) {
  .cp-action-buttons > a {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media all and (max-width: 700px) {
  .cp-action-buttons {
    display: flex;
    flex-direction: row;
  }
  .cp-action-buttons a {
    flex: 1;
  }
}

.cp-action-buttons .offer-word {
  font: inherit;
}

@media all and (max-width: 580px) {
  .cp-action-buttons .offer-word {
    display: none;
  }
}

@media all and (max-width: 420px) {
  .cp-action-buttons {
    padding: 5px 0px;
  }
  .cp-action-buttons a {
    margin: 5px !important;
    font-size: 12px;
  }
}

.cp-action-buttons > a {
  float: left;
  width: 100%;
  max-width: 200px;
  height: 40px;
  margin: 0px 15px 0px 0px;
  padding: 12px 15px;
  font-family: 'latobold';
  font-size: 14px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: right 15px center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cp-action-buttons > a:last-of-type {
  margin-right: 0px;
}

.cp-action-buttons .cp-save-offer {
  background-color: #ff9e20;
  background-image: url(../../images/cp-save-icon.png);
}

.cp-action-buttons .cp-save-offer:hover {
  background-color: #ffa93a;
}

.cp-action-buttons .cp-preview-offer {
  background-color: #469edc;
  background-image: url(../../images/cp-preview-icon.png);
}

.cp-action-buttons .cp-preview-offer:hover {
  background-color: #5ba9e0;
}

.cp-action-buttons .cp-submit-offer {
  background-color: #aad155;
  background-image: url(../../images/cp-submit-icon.png);
}

.cp-action-buttons .cp-submit-offer:hover {
  background-color: #b4d669;
}

.cp-formgroup-textarea {
  min-height: 130px !important;
}

.cr-announcements-form > .cp-form-group {
  margin-top: 10px;
}

.cr-announcements-form > .cp-form-group:first-of-type {
  margin-top: 0px;
}

.cp-add-announcement-btn {
  float: right;
  width: 100%;
  max-width: 260px;
  height: 45px;
  margin: 20px 0px 0px 0px;
  padding: 14px 15px;
  font-family: 'latoblack';
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  background-color: #469edc;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-transform: uppercase;
  background-image: url("../../images/cert-upload-btn-icon.png");
  background-repeat: no-repeat;
  background-position: right 15px center;
}

.cp-add-announcement-btn:hover {
  background-color: #5ba9e0;
}

.document-cont-list .error-tooltip {
  margin-right: 50px !important;
}

/* Create project who is behind tab */
.cp-who-behind-wrapper {
  float: left;
  width: 100%;
  margin: 25px 0px 0px 0px;
  padding: 20px;
  background-color: #ffffff;
}

.cp-documents-browse-input {
  margin-top: 2px;
}

.cp-editable-input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cp-editable-input > input {
  border-color: #dfe6eb;
  border-style: solid;
  border-width: 1px;
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 14px;
  height: 42px;
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.cp-editable-block > textarea {
  height: 113px !important;
}

.tabs-control-tab {
  display: none;
}

.tabs-control-tab.active {
  display: block;
}

/* ----------------------------------------------------------
CREATE PORJECT PAGE
---------------------------------------------------------- */
/* Create project tabs */
ul.create-project-tabs {
  float: left;
  width: 100%;
  margin: 60px 0px 0px 0px;
  padding: 0px;
}

ul.create-project-tabs > li {
  float: left;
  min-height: 39px;
  margin: 0px;
  padding: 0px 0px 0px 1px;
  list-style: none;
  position: relative;
}

ul.create-project-tabs > li:first-of-type {
  padding-left: 0px;
}

ul.create-project-tabs > li > a {
  float: left;
  width: 100%;
  min-height: 39px;
  margin: 0px;
  padding: 10px;
  background-color: #ffffff;
  font-family: 'latobold';
  font-size: 15px;
  color: #7e7e7e;
  text-decoration: none;
  text-align: center;
}

ul.create-project-tabs > li > a:hover {
  text-decoration: underline;
}

ul.create-project-tabs > li > a.active {
  background-color: #469edc;
  color: #ffffff;
}

ul.create-project-tabs > li > a.active:hover {
  text-decoration: none;
}

ul.create-project-tabs > li > a.error {
  background-color: #f67f7f;
  color: #ffffff;
}

ul.create-project-tabs > li > a.error:hover {
  text-decoration: none;
}

ul.create-project-tabs > li > a.error ~ span.cpt-error-number {
  display: inline-block;
}

ul.create-project-tabs > li > span.cpt-error-number {
  display: none;
  position: absolute;
  top: -13px;
  right: 7px;
  width: 24px;
  height: 24px;
  margin: 0px;
  padding: 4px 0px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 2px;
  border-radius: 12px;
  font-family: 'latoblack';
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  background-color: #f67f7f;
}

ul.create-project-tabs.tabs-3 > li {
  width: 33.33%;
}

@media all and (max-width: 850px) {
  ul.create-project-tabs.tabs-3 > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 560px) {
  ul.create-project-tabs.tabs-3 > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.tabs-4 > li {
  width: 25%;
}

@media all and (max-width: 800px) {
  ul.create-project-tabs.tabs-4 > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 460px) {
  ul.create-project-tabs.tabs-4 > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.tabs-5 > li {
  width: 20%;
}

@media all and (max-width: 850px) {
  ul.create-project-tabs.tabs-5 > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 550px) {
  ul.create-project-tabs.tabs-5 > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 430px) {
  ul.create-project-tabs.tabs-5 > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

ul.create-project-tabs.tabs-6 > li {
  width: 16.66%;
}

@media all and (max-width: 920px) {
  ul.create-project-tabs.tabs-6 > li {
    width: 33.3%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 500px) {
  ul.create-project-tabs.tabs-6 > li {
    width: 50%;
    padding: 0px 0px 1px 1px;
  }
}

@media all and (max-width: 400px) {
  ul.create-project-tabs.tabs-6 > li {
    width: 100%;
    padding: 0px 0px 1px 0px;
  }
}

/* Create project wrapper */
.create-project-wrapper {
  float: left;
  width: 100%;
  margin: 25px 0px 25px 0px;
  padding: 20px;
  background-color: #ffffff;
}

.create-project-wrapper .cp-form-group-wrapper {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.create-project-wrapper .cp-form-group-wrapper > form {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cp-add-block-btn {
  float: left;
  width: 100%;
  min-height: 200px;
  margin: 0px;
  padding: 125px 100px 56px;
  background-color: #e6f1f8;
  background-image: url(../../images/add-new-block-icon.png);
  background-repeat: no-repeat;
  background-position: center top 60px;
  font-family: 'latobold';
  font-size: 18px;
  color: #99b9cf;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.cp-add-block-btn:hover {
  background-color: #f0f5f8;
}

.cp-sections-title {
  float: left;
  width: 100%;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  font-family: 'latobold';
  font-size: 18px;
  color: #7e7e7e;
  text-transform: uppercase;
}

.cp-sections-title:first-of-type {
  margin-top: 0px;
}

.cp-form-group-ul {
  float: left;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.cp-form-group-ul > li {
  float: left;
  width: 50%;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}

.cp-form-group-ul > li:nth-child(odd) {
  padding-right: 5px;
}

.cp-form-group-ul > li:nth-child(even) {
  padding-left: 5px;
}

@media all and (max-width: 950px) {
  .cp-form-group-ul > li {
    width: 100%;
  }
  .cp-form-group-ul > li:nth-child(odd) {
    padding-right: 0px;
  }
  .cp-form-group-ul > li:nth-child(even) {
    padding-left: 0px;
  }
}

.cp-form-group-ul > li .cp-fg-textarea-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px;
  background-color: #f3f5f6;
}

.cp-form-group-ul > li .cp-fg-textarea-container .cp-fg-tc-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cp-form-group-ul > li .cp-fg-textarea-container .cp-fg-tc-title > span {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
}

.cp-form-group-ul > li .cp-fg-textarea-container > textarea {
  border-color: #dfe6eb;
  border-style: solid;
  border-width: 1px;
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 14px;
  height: 140px;
  margin: 15px 0 0;
  padding: 10px;
  width: 100%;
  resize: none;
}

.cp-form-group {
  float: left;
  width: 100%;
  min-height: 60px;
  margin: 0px;
  padding: 22px 300px 22px 15px;
  position: relative;
  background-color: #f3f5f6;
}

@media all and (max-width: 1023px) {
  .cp-form-group {
    padding: 22px 235px 22px 15px;
  }
}

@media all and (max-width: 950px) {
  .cp-form-group {
    padding: 22px 365px 22px 15px;
  }
}

@media all and (max-width: 750px) {
  .cp-form-group {
    padding: 22px 265px 22px 15px;
  }
}

@media all and (max-width: 580px) {
  .cp-form-group {
    padding: 15px;
  }
}

.cp-form-group-title {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px 20px 0px 0px;
  font-family: 'latoregular';
  font-size: 14px;
  color: #7e7e7e;
  position: relative;
}

.cp-fg-input-cont {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 100%;
  max-width: 280px;
  min-height: 42px;
  /* sfg file input ends here */
}

@media all and (max-width: 1023px) {
  .cp-fg-input-cont {
    max-width: 215px;
  }
}

@media all and (max-width: 950px) {
  .cp-fg-input-cont {
    max-width: 340px;
  }
}

@media all and (max-width: 745px) {
  .cp-fg-input-cont {
    max-width: 240px;
  }
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont {
    position: relative;
    top: auto;
    right: auto;
    float: left;
    max-width: 100%;
    margin-top: 10px;
  }
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont.mob-img-input-fix {
    padding-left: 45px;
  }
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont.mob-img-input-fix .cp-fg-image-preview {
    left: 0;
  }
}

.cp-fg-input-cont > textarea {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 9px 10px;
  font-family: 'latobold';
  font-size: 14px;
  resize: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cp-fg-input-cont > textarea:focus {
  height: 112px;
  z-index: 1000;
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont > textarea {
    position: relative;
    top: auto;
    right: auto;
  }
}

.cp-fg-input-cont > input[type="text"], .cp-fg-input-cont > input[type="password"] {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  color: #7e7e7e;
  padding: 0px 10px;
  font-family: 'latobold';
  font-size: 14px;
}

.cp-fg-input-cont .cp-fg-select-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.cp-fg-input-cont .cp-fg-select-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/select-field-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.cp-fg-input-cont .cp-fg-select-field > select {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px 1px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont .cp-fg-select-field {
    margin: 0px;
  }
}

.cp-fg-input-cont .cp-fg-radiobutton-cont {
  float: right;
  padding: 9px 10px;
}

.cp-fg-input-cont .cp-fg-file-field {
  float: left;
  width: 100%;
  height: 42px;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #dfe6eb;
  border-width: 1px;
  position: relative;
  z-index: 1;
}

.cp-fg-input-cont .cp-fg-file-field:after {
  content: "";
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-image: url(../../images/file-field-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffffff;
  z-index: 2;
}

.cp-fg-input-cont .cp-fg-file-field > input {
  float: left;
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 10px;
  margin: 0px;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  border: 0px;
  position: relative;
  z-index: 3;
  background-color: transparent;
  text-indent: -99999px;
  opacity: 0;
  cursor: pointer;
}

.cp-fg-input-cont .cp-fg-file-field > span {
  position: absolute;
  top: 12px;
  left: 0px;
  margin: 0px;
  padding: 0px 42px 3px 15px;
  width: 100%;
  font-family: 'latobold';
  font-size: 14px;
  color: #7e7e7e;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media all and (max-width: 580px) {
  .cp-fg-input-cont .cp-fg-file-field {
    margin: 0px;
  }
}

.cp-form-helper {
  background-color: #9ec0d1;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-family: "latoblack";
  font-size: 12px;
  height: 16px;
  margin: 0;
  padding: 2px 0 0;
  text-align: center;
  width: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.cp-form-helper:hover .cp-form-helper-text {
  opacity: 1;
  visibility: visible;
}

.cp-form-helper-text {
  background-color: #6c99af;
  border-radius: 2px;
  bottom: 24px;
  color: #ffffff;
  font-family: "latolight";
  font-size: 12px;
  margin: 0;
  min-height: 22px;
  min-width: 200px;
  padding: 10px;
  position: absolute;
  right: -8px;
  text-align: center;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  line-height: 130%;
}

.cp-form-helper-text:before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #6c99af;
  bottom: -5px;
  content: "";
  height: 0;
  right: 10px;
  position: absolute;
  width: 0;
}

.left-aligned-form-helper {
  margin: 0px 10px;
  left: auto;
  right: initial;
}

.diff-left-aligned-helper {
  margin: 20px 10px;
  left: auto;
  right: initial;
}

.cp-action-buttons-cont {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  background-color: #3b739a;
  z-index: 1001;
}

/* Create project who is behind tab */
.cp-who-behind-wrapper {
  float: left;
  width: 100%;
  margin: 25px 0px 0px 0px;
  padding: 20px;
  background-color: #ffffff;
}

.cp-documents-browse-input {
  margin-top: 2px;
}

.cp-editable-input {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.cp-editable-input > input {
  border-color: #dfe6eb;
  border-style: solid;
  border-width: 1px;
  color: #7e7e7e;
  float: left;
  font-family: "latobold";
  font-size: 14px;
  height: 42px;
  margin: 0;
  padding: 0 10px;
  width: 60%;
}

.cp-editable-block > textarea {
  height: 113px !important;
}
