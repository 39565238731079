/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
.dealsContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

.dealsContainer header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dealsContainer .buttonFilter {
  margin-bottom: 50px;
  justify-content: center;
}

.dealsContainer button.filterButton {
  margin: 10px;
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.dealsContainer button.filterButton:hover, .dealsContainer button.filterButton.buttonFilterSelected {
  cursor: pointer;
  background: #469edc;
  color: #fff;
}

.dealsContainer .selectFilter {
  margin: 10px;
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  background: url("../../images/blue-button-arrow-down.png") no-repeat 90%;
}

.dealsContainer .filterOption {
  width: 44px;
  height: 44px;
  margin: 10px;
  padding: 10px;
  padding-top: 13px;
  padding-bottom: 7px;
  font-size: 16px;
  font-family: 'latolight';
  background: url("../../images/filter-icon.png") no-repeat;
  background-position: center;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background-color 0.3s ease;
}

.dealsContainer .filterOption:hover {
  background: url("../../images/filter-icon-hover.png") no-repeat;
  background-color: #469edc;
  background-position: center;
  cursor: pointer;
}

.dealsContainer .center {
  text-align: center;
}

.dealsContainer .filterOptions {
  margin: 10px;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 10px;
  padding-top: 0;
  transition: background-color 0.3s ease;
  border-radius: 2px;
}

.dealsContainer .filterOptions header input[type="text"] {
  border: 1px solid #469edc;
  background: none;
  height: 44px;
  border-radius: 2px;
  padding: 10px;
  font-size: 16px;
  font-family: 'FontAwesome', 'latolight';
}

.dealsContainer .filterOptions .filterOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.dealsContainer .filterOptions .filterOptionsContainer .filterOptionsContainerColumn {
  display: flex;
  flex-direction: column;
}

.dealsContainer .filterOptions .filterOptionsContainer .filterOptionsContainerColumn h4 {
  text-align: center;
  font-family: 'latolight';
  color: #333;
  font-size: 18px;
  line-height: 30px;
}

.dealsContainer section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px 0 0 10px;
  justify-content: center;
  align-items: flex-start;
}

.dealsContainer section .dealSectionItem {
  display: inline-block;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% * (1/4) - 10px - 1px);
  margin-bottom: 20px;
  position: relative;
}

@media (max-width: 700px) {
  .dealsContainer section .dealSectionItem {
    width: calc(100% * (1/2) - 10px - 1px);
  }
}

@media (max-width: 450px) {
  .dealsContainer section .dealSectionItem {
    width: calc(100% - 10px - 1px);
  }
}

.dealsContainer section .dealSectionItem .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 95%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dealsContainer section .dealSectionItem .overlay .overlayHeader {
  justify-content: flex-start;
}

.dealsContainer section .dealSectionItem .overlay .overlayHeader h4 {
  font-family: 'latoregular';
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer p {
  color: white;
  font-family: 'latolight';
  font-size: 18px;
  line-height: 27px;
}

@media (max-width: 450px) {
  .dealsContainer section .dealSectionItem .overlay .overlayContainer p {
    display: none;
  }
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest input[type=text] {
  margin-bottom: 10px;
  font-size: 15px;
  height: 40px;
  width: 100%;
  border-radius: 2px;
  font-family: 'latolight';
  border: none;
  padding: 10px;
  color: #333;
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest .registerButton {
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: #469edc;
  border: 1px solid #469edc;
  color: #fff;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest .registerButton:hover, .dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest .registerButton.buttonFilterSelected {
  cursor: pointer;
  background: #469edc;
  color: #fff;
}

.dealsContainer section .dealSectionItem .overlay .overlayContainer .registerInterest p.thankyou {
  display: block;
}

.dealsContainer section .dealSectionItem .overlay .amountFundedBar {
  width: 100%;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
}

.dealsContainer section .dealSectionItem .overlay .amountFundedBar .amountFundedTotal {
  width: 75%;
  background: #aad155;
  height: 4px;
}

.dealsContainer section .dealSectionItem:hover .overlay {
  opacity: 1;
  cursor: pointer;
}

.dealsContainer section .dealSectionItem .private {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  padding: 15px;
  padding-bottom: 0;
  margin-top: -60px;
}

@media (max-width: 800px) {
  .dealsContainer section .dealSectionItem .private {
    margin-top: -35px;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .dealsContainer section .dealSectionItem .private {
    padding: 0;
  }
}

.dealsContainer section .dealSectionItem .private img {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: inherit;
  margin-left: auto;
}

@media (max-width: 900px) {
  .dealsContainer section .dealSectionItem .private img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 650px) {
  .dealsContainer section .dealSectionItem .private img {
    margin: 0 auto;
  }
}

.dealsContainer section .dealSectionItem .public {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  padding: 15px;
  padding-bottom: 0;
  margin-top: -60px;
}

@media (max-width: 800px) {
  .dealsContainer section .dealSectionItem .public {
    margin-top: -35px;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .dealsContainer section .dealSectionItem .public {
    padding: 0;
  }
}

.dealsContainer section .dealSectionItem .public img {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: inherit;
  margin-left: auto;
}

@media (max-width: 900px) {
  .dealsContainer section .dealSectionItem .public img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 650px) {
  .dealsContainer section .dealSectionItem .public img {
    margin: auto;
  }
}

.dealsContainer section .dealSectionItem article {
  color: #fff;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 450px) {
  .dealsContainer section .dealSectionItem article {
    border-top: 1px solid #ccc;
    margin-top: 20px;
  }
}

.dealsContainer section .dealSectionItem article img {
  width: 100%;
}

@media (max-width: 450px) {
  .dealsContainer section .dealSectionItem article img {
    display: none;
  }
}

.dealsContainer section .dealSectionItem article:hover .overlay {
  opacity: 1;
}

.dealsContainer section .dealSectionItem article .articleContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-top: none;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;
  color: #333;
  min-height: 220px;
}

@media (max-width: 1000px) {
  .dealsContainer section .dealSectionItem article .articleContainer {
    min-height: 250px;
  }
}

@media (max-width: 450px) {
  .dealsContainer section .dealSectionItem article .articleContainer {
    min-height: 200px;
  }
}

.dealsContainer section .dealSectionItem article .articleContainer h4 {
  font-family: 'latoregular';
  font-size: 18px;
  line-height: 27px;
}

.dealsContainer section .dealSectionItem article .articleContainer p {
  font-size: 15px;
  line-height: 22.5px;
}

.dealsContainer footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dealsContainer footer .loadMoreButton {
  margin: 10px;
  padding: 10px;
  min-width: 140px;
  font-size: 16px;
  font-family: 'latolight';
  background: none;
  border: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
  transition: background 0.3s ease;
}

.dealsContainer footer .loadMoreButton:hover, .dealsContainer footer .loadMoreButton.buttonFilterSelected {
  cursor: pointer;
  background: #469edc;
  color: #fff;
}

/*---------------------------
      Mina CSS
-----------------------------*/
/* deals page  */
main {
  background-color: #fff;
}

.full {
  width: 100%;
  height: 380.5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0;
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
}

.full .mn-country-background {
  width: 250px;
  height: 50px;
  background-color: #ebebeb;
  border-radius: 30px;
  margin: auto;
  text-align: right;
  padding: 15px;
  position: relative;
  margin-bottom: 31px;
}

.full .mn-country-background .mn-country-toggle {
  width: 125px;
  height: 44px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  padding: 12px;
  text-align: center;
}

.full .mn-country-background .mn-country-toggle.active {
  background-color: #469edc;
  color: #FFFFFF;
}

.full .mn-deal-title {
  font-family: latoregular;
  font-size: 44px;
  text-align: center;
  color: #ffffff;
  margin-top: 43px;
  margin: auto;
  line-height: 40px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .full .mn-deal-title {
    font-size: 35px;
  }
}

@media all and (max-width: 736px) {
  .full .mn-deal-title {
    font-size: 29px;
  }
}

.full .mn-deal-subtitle {
  line-height: 35px;
  margin: 34px 0 0 0;
  font-family: 'latolight';
  font-weight: 300;
  font-size: 24px;
  color: #fff;
  text-align: center;
  max-width: 950px;
  padding-right: 20px;
  padding-left: 20px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .full .mn-deal-subtitle {
    font-size: 19px;
  }
}

@media all and (max-width: 736px) {
  .full .mn-deal-subtitle {
    font-size: 15px;
    margin: 14px 0 0 0;
  }
}

.selectpicker {
  font-size: 16px;
  height: 34px;
  width: 150px;
  padding-left: 10px;
  border: none;
  background-color: white;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.selectpicker:not(.is-multiple)::after {
  border: 1px solid #3273dc;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 4;
}

.mn-filter-option {
  margin-top: 70px;
}

@media all and (max-width: 736px) {
  .mn-filter-option {
    margin-top: 30px;
  }
}

.mn-filter-option .mn-filter-container {
  margin-bottom: 34px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-filter-option .mn-filter-container {
    margin-bottom: 26px;
  }
}

.mn-filter-option .mn-filter-blue {
  background-color: #469edc;
  color: white;
}

.mn-filter-option .mn-deals-filter-title {
  font-size: 27px;
  font-family: latoregular;
  margin-right: 40px;
}

.mn-filter-option .navbar-form {
  margin-left: 254px;
  padding-right: 0;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-filter-option .navbar-form {
    margin-left: 0;
  }
}

@media all and (max-width: 736px) {
  .mn-filter-option .navbar-form {
    margin-left: 0;
  }
}

.mn-all-delas-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mn-tile-container {
  margin-bottom: 30px;
  width: 356px;
  position: relative;
  margin: 12px;
}

@media all and (max-width: 736px) {
  .mn-tile-container {
    width: 352px;
  }
}

@media all and (max-width: 320px) {
  .mn-tile-container {
    width: 292px;
  }
}

.mn-tile-container .mn-offer-tile-up {
  width: 100%;
  height: 152px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.mn-tile-container .mn-offer-tile-up .mn-tile-banner {
  width: 100%;
  height: 100%;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
}

.mn-tile-container .mn-offer-tile-up .mn-tile-circle-logo {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.tileHover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
}

.mn-deal-tile-content {
  border: 1px solid #e1e1e1;
}

.mn-deal-tile-content .mn-tile-content-container {
  padding: 76px 20px 21px;
  background-color: white;
  height: 440px;
  position: relative;
}

.mn-deal-tile-content .underline {
  text-decoration: underline;
}

.mn-deal-tile-content .mn-tile-desc-black {
  color: #333;
  font-size: 16px;
  font-family: latolight;
  text-align: center;
  margin-bottom: 26px;
}

.mn-deal-tile-content .mn-tile-desc-black-live {
  color: #333;
  font-size: 16px;
  font-family: latolight;
  text-align: center;
  margin-bottom: 17px;
}

.mn-deal-tile-content .mn-deals-page-button {
  margin: auto;
  max-width: 277px;
  height: 55px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  font-family: latoregular;
  margin-top: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}

.mn-deal-tile-content .mn-deals-page-button:hover {
  filter: brightness(85%);
  transition: opacity 0.15s linear;
}

.mn-tile-desc {
  font-size: 20px;
  font-family: latoregular;
  text-align: center;
}

.mn-margin-4 {
  margin-bottom: 4px;
}

.mn-margin-40 {
  margin-bottom: 40px;
}

.mn-form {
  position: relative;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .mn-form {
    text-align: center;
  }
}

.mn-glyphicon {
  width: 20px;
}

@media all and (max-width: 736px) {
  .mn-glyphicon {
    width: 20px;
    position: absolute;
    top: -4px;
    left: 312px;
  }
}

@media all and (max-width: 400px) {
  .mn-glyphicon {
    left: 254px;
  }
}

.mn-filter-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.mn-filter-container .select-box {
  margin: 10px 10px 10px 19px;
  padding: 10px;
  min-width: 170px;
  font-size: 16px;
  font-family: 'latolight';
  background: none;
  border: none;
  border-bottom: 1px solid #469edc;
  color: #469edc;
  border-radius: 2px;
}

@media all and (max-width: 736px) {
  .mn-filter-container .select-box {
    min-width: 102px;
  }
}

@media all and (max-width: 736px) {
  .mn-filter-container .select-box-wide {
    min-width: 140px;
  }
}

.mn-filter-container .white-arrow {
  background: url(../../images/white-arrow-horiz.png) no-repeat 90%;
}

.mn-filter-container .blue-arrow {
  background: url(../../images/blue-button-arrow-down.png) no-repeat 90%;
}

.search-container {
  margin-top: 7px;
}

@media all and (max-width: 736px) {
  .search-container {
    margin-top: 20px;
  }
}

.search-container .search-box {
  border: none;
  border-bottom: 1px solid #469edc;
  padding: 8px;
  font-size: 17px;
  font-family: latolight;
  color: #469edc;
  width: 314px;
  margin-left: 190px;
}

@media only screen and (min-device-width: 737px) and (max-device-width: 1200px) {
  .search-container .search-box {
    width: 364px;
    margin-left: 0;
  }
}

@media all and (max-width: 736px) {
  .search-container .search-box {
    margin-top: -13px;
    margin-left: 0px;
    margin-right: 40px;
    width: 304px;
    float: right;
  }
}

@media all and (max-width: 400px) {
  .search-container .search-box {
    width: 246px;
  }
}

.search-container input::placeholder {
  color: #469edc;
}

.search-container button {
  border: none;
  background-color: white;
}

@media all and (max-width: 736px) {
  .search-container button {
    margin-top: 10px;
  }
}

.mn-label {
  color: #7e7e7e;
}

.loading-snipper {
  height: 561px;
  width: 100%;
}

.loading-snipper .loading-snipper-img {
  display: block;
  margin: auto;
}
