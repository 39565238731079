@import 'extensions/variables';
@import 'extensions/mixins';

/* ----------------------------------------------------------
SYNDICATED OFFER PAGE
---------------------------------------------------------- */ 
.syndicated-offer-half {
    float:left;
    width: 50%;
    margin: 0px;
    padding: 0px;
    @include mquery(1080px) {
        width: 100%;
        padding: 0px !important;
    }

    input[disabled] {
        background: #F3F5F6;
    }
}
div[class="syndicated-offer-half"]:nth-of-type(even){
    padding-left: 10px;
}
div[class="syndicated-offer-half"]:nth-of-type(odd){
    padding-right: 10px;
}
.syndicated-offer-half > ul.cp-form-group-ul > li {
    width: 100%;
    padding: 0px !important;
}
.syndicate-division-wrapper {
    float: left;
    width: 100%;
}
.so-editor-cont {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding: 15px;
    background-color: #F3F5F6;
    position: relative; /* Dijana - 11.07 */
}
.so-editor-cont > .cp-form-group-title {
    padding: 0px !important;
}
.mce-tinymce {
    margin-top: 15px !important;
    float: left !important;
    @include mquery(1080px) {
        width: 100% !important;
    }
}
.so-slider-container {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 15px 10px;
    background-color: #F3F5F6;
}
.so-slider-inputs {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px;
    > ul {
        float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        > li {
            float: left;
            width: 25%;
            margin: 0px;
            padding: 0px 5px;
        }
    }
}
.so-slider-input {
    @include border(full, 1px, solid, #dfe6eb);
    color: #7e7e7e;
    float: left;
    font-family: "latobold";
    font-size: 14px;
    height: 42px;
    margin: 0;
    padding: 0 10px;
    width: 100%;

    &[disabled] {
        background-color: transparent;
    }
}
.so-slider-range-cont {
    float: left;
    width: 100%;
    margin: 29px 0px;
    padding: 0px 27px 0px 12px;
}
.so-slider-range {
    border-color: #dfe6eb !important;   
    border-radius: 0px !important;
    height: 14px;
    border-left: 0px !important;
    border-right: 0px !important;
    &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: -7px;
        width: 15px;
        height: 12px;
        @include border(full, 1px, solid, #dfe6eb);
        border-right: 0px !important;
        background-color: #ffffff;
    }
    &:after {
        content: "";
        position: absolute;
        top: -1px;
        right: -21px;
        width: 30px;
        height: 12px;
        @include border(full, 1px, solid, #dfe6eb);
        border-left: 0px !important;
        background-color: #ffffff;
    }
}

.ui-slider-horizontal .ui-slider-range {
    background-color: $default-blue-color;
}
.mce-panel {
    border: 1px solid #dfe6eb !important;
}