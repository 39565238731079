/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
.xinja-container {
  background-image: url(../../images/xinja-bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  padding: 80px 100px;
}

@media all and (max-width: 500px) {
  .xinja-container {
    padding: 10px;
  }
}

@media all and (max-width: 800px) {
  .xinja-container .container {
    flex-direction: column;
  }
  .xinja-container .phone {
    display: block;
  }
}

.xinja-container .container {
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 900px) {
  .xinja-container .container {
    flex-direction: column;
    width: 100%;
  }
}

.xinja-container .container .phone {
  position: relative;
  width: 306px;
  margin: 0 auto;
  margin-top: 50px;
}

.xinja-container .container .phone img {
  width: 306px;
  height: 617px;
  height: 38.5625rem;
  max-width: none;
}

.xinja-container .container .phone iframe {
  position: absolute;
  top: 71px;
  width: 266px;
  left: 20px;
  height: 472px;
  pointer-events: none;
}

@media all and (max-width: 1000px) {
  .xinja-container .container .phone img {
    max-width: none;
    height: auto;
  }
  .xinja-container .container .phone iframe {
    top: 70px;
  }
}

@media all and (max-width: 550px) {
  .xinja-container .container .phone {
    width: 250px;
  }
  .xinja-container .container .phone img {
    width: 250px;
  }
  .xinja-container .container .phone iframe {
    top: 59px;
    width: 216px;
    height: 384px;
    left: 17px;
  }
}

.xinja-container .container .text-content {
  padding: 0px 60px;
  margin-left: 80px;
}

@media all and (max-width: 1030px) {
  .xinja-container .container .text-content {
    margin-left: 0;
  }
}

.xinja-container .container .text-content h1 {
  color: #fff;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 40px;
}

.xinja-container .container .text-content p {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 20px;
}

@media all and (max-width: 1000px) {
  .xinja-container .container .text-content {
    padding: 30px;
  }
  .xinja-container .container .text-content h1 {
    font-size: 2rem;
  }
  .xinja-container .container .text-content p {
    font-size: 1.2rem;
  }
}

.xinja-container .container .text-content p.nb {
  font-size: 15px;
  margin-top: 50px;
}

@media all and (max-width: 800px) {
  .xinja-container .container .text-content {
    padding: 50px 0;
  }
  .xinja-container .container .text-content h1 {
    font-size: 2rem;
  }
  .xinja-container .container .text-content p {
    font-size: 1.2rem;
  }
}

.xinja-container .container .text-content .xinja-form-fields {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}

@media all and (max-width: 800px) {
  .xinja-container .container .text-content .xinja-form-fields {
    flex-direction: column;
  }
}

.xinja-container .container .text-content .xinja-form-fields #xinja-name, .xinja-container .container .text-content .xinja-form-fields #xinja-email {
  padding: 10px;
  border-radius: 4px;
  margin-right: 20px;
  font-weight: 400;
  height: 50px;
  border: none;
}

@media all and (max-width: 800px) {
  .xinja-container .container .text-content .xinja-form-fields #xinja-name, .xinja-container .container .text-content .xinja-form-fields #xinja-email {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.mn-xinja-btn {
  color: #fff;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  background: none;
  border: none;
  background-color: #ab337a;
  padding: 10px 20px;
  border-radius: 11px;
}

.mn-xinja-btn:hover {
  background-color: #c14b90;
  opacity: 0.7;
  transition: opacity 0.5s linear;
}

.xinja-landing-page-text article {
  padding-top: 100px;
  padding-bottom: 100px;
}

.xinja-landing-page-text article .container {
  padding-right: 30px;
  padding-left: 30px;
}

.xinja-landing-page-text article h2 {
  min-width: 150px;
  margin: 0px 0px 35px;
  padding: 0px 0px 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  line-height: 41px;
  color: #FFF;
}

@media all and (max-width: 650px) {
  .xinja-landing-page-text article h2 {
    font-size: 26px;
    line-height: 36px;
  }
}

.xinja-landing-page-text article p {
  font-size: 20px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 28px;
}

.xinja-landing-page-text .ground-floor {
  background-image: url("../../images/xinja-build-own-bank.jpg");
  background-position: center;
  background-size: cover;
}

.xinja-landing-page-text .ground-floor .ground-floor-content {
  width: 45%;
}

@media all and (max-width: 800px) {
  .xinja-landing-page-text .ground-floor {
    background-position: left;
  }
  .xinja-landing-page-text .ground-floor .ground-floor-content {
    width: 100%;
  }
}

.xinja-landing-page-text .so-why h2, .xinja-landing-page-text .so-why p {
  color: #333;
}

section.xinja-form-below {
  background-image: url(../../images/xinja-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0px;
  left: 0px;
  display: table;
  height: 500px;
  min-height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

section.xinja-form-below p {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 20px auto;
  margin-bottom: 25px;
  width: 350px;
}

section.xinja-form-below .form {
  width: 560px;
}

section.xinja-form-below .form h2 {
  min-width: 150px;
  margin: 0px 0px 35px;
  padding: 0px 0px 10px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  line-height: 41px;
  color: #FFF;
}

@media (max-width: 630px) {
  section.xinja-form-below .form {
    width: 100%;
    padding: 20px;
  }
  section.xinja-form-below .form p {
    width: 100%;
    padding: 20px;
  }
}

section.xinja-form-below .form input {
  border: 1px solid #fff;
  width: 100%;
  padding: 20px;
  height: 50px;
  font-size: 16px;
  font-family: 'latolight';
  color: #fff;
  margin-top: 30px;
  margin-bottom: 8px;
  background: rgba(255, 255, 255, 0.4);
}

section.xinja-form-below .form input.error {
  border-left: 2px solid #DD1C52;
  animation: fadein 2s;
}

section.xinja-form-below .form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  letter-spacing: 1px;
}

section.xinja-form-below .form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  letter-spacing: 1px;
}

section.xinja-form-below .form :-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  letter-spacing: 1px;
}

section.xinja-form-below .form :-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  letter-spacing: 1px;
}

section.xinja-form-below .form #partnerSubmit {
  background-color: #44A4E7;
  margin: 0 auto;
  display: inherit;
  margin-top: 30px;
  height: 50px;
  font-size: 16px;
  font-family: 'latolight';
  width: 200px;
  color: #fff;
  border: none;
}

section.xinja-form-below .form #partnerSubmit[disabled]:hover {
  cursor: progress;
}

section.xinja-form-below .form #partnerSubmit:hover {
  cursor: pointer;
}

#social-proof {
  padding-top: 80px;
  padding-bottom: 120px;
  background-color: #fff;
}

#social-proof h2 {
  min-width: 150px;
  margin: 0;
  margin-bottom: 50px;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  font-family: 'latolight';
  font-size: 34px;
  line-height: 41px;
  color: #333;
  display: block;
}

#social-proof img {
  vertical-align: middle;
  height: auto;
  width: auto;
  margin: 0 auto;
}

@media all and (max-width: 992px) {
  #social-proof img {
    max-width: 250px;
  }
}

#social-proof .brands-item {
  min-height: 70px;
}

#social-proof .brands-item a {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

#partnerForm p.nb {
  font-size: 15px !important;
  line-height: 18px !important;
}

#xinja-hero-banner {
  background: url(/images/landing-pages/xinja_nov_18_landing_page_general_top_section_2000x.png) no-repeat center;
  background-size: cover;
  display: table;
  position: relative;
  width: 100%;
}

.xinja-hero-height-600 {
  height: 600px;
}

.xinja-hero-height-680 {
  height: 680px;
}

.xinja-hero-height-100 {
  height: calc(100vh - 70px);
}

.xinja-hero-height-540 {
  height: 540px;
}

.xinja-logo {
  width: 200px;
  margin-bottom: 20px;
  margin-bottom: 34px;
}

@media all and (max-width: 600px) {
  .xinja-logo {
    width: 160px;
    margin-bottom: 24px;
  }
}

.xinja-hero-title {
  text-align: left;
  margin-bottom: 34px;
  font-family: 'latoregular';
}

@media all and (max-width: 768px) {
  .xinja-hero-title {
    text-align: center;
  }
}

.xinja-size-70 {
  font-size: 70px;
}

@media all and (max-width: 600px) {
  .xinja-size-70 {
    font-size: 32px;
  }
}

.xinja-size-40 {
  font-size: 40px;
}

@media all and (max-width: 600px) {
  .xinja-size-40 {
    font-size: 32px;
  }
}

.xinja-hero-subtitle {
  font-size: 24px;
  text-align: left;
  line-height: 35px;
  margin-bottom: 34px;
}

@media all and (max-width: 768px) {
  .xinja-hero-subtitle {
    text-align: center;
  }
}

@media all and (max-width: 600px) {
  .xinja-hero-subtitle {
    font-size: 19px;
    margin-bottom: 24px;
  }
}

.xinja-register-title {
  font-size: 36px;
  margin-bottom: 34px;
  text-align: left;
}

@media all and (max-width: 600px) {
  .xinja-register-title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

.xinja-form-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 410px;
  margin-left: 0;
  text-align: center;
}

@media all and (max-width: 1024px) {
  .xinja-form-field-center-md {
    margin: auto;
  }
}

@media all and (max-width: 768px) {
  .xinja-form-field-center-sm {
    margin: auto;
  }
}

.xinja-risk-warning-note {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #3d3d3c;
}

.xinja-risk-warning-width {
  max-width: 391px;
}

@media all and (max-width: 768px) {
  .xinja-risk-warning-center-sm {
    margin: auto;
    text-align: center;
    color: #fff;
  }
}

.xinja-link {
  color: #37c0c7;
}

.xinja-link:hover {
  color: #e41970;
  text-decoration: underline;
}

.xinja-button-style {
  background-color: #37c0c7;
  background: linear-gradient(to right, #37c0c7 0%, #5f6db3 100%);
  border: none;
}

.xinja-button-style:hover {
  background: linear-gradient(to right, #e41970 0%, #744495 100%);
  background-color: #e41970;
  color: #fff;
}

@media all and (max-width: 768px) {
  .xinja-view-offer-btn-cnt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.xinja-view-offer-btn {
  border-radius: 4px;
  height: 49px;
  width: 200px;
  background-color: #37c0c7;
  background: linear-gradient(to right, #37c0c7 0%, #5f6db3 100%);
  border: none;
  color: #fff;
  transition: all 0.5s ease;
  animation: bounce 3s;
  margin-bottom: 20px;
}

.xinja-view-offer-btn:hover {
  background: linear-gradient(to right, #e41970 0%, #744495 100%);
  background-color: #e41970;
  transition: .5s;
}

.xinja-hero-content-cnt {
  position: absolute;
  top: 57px;
  max-width: 560px;
}

@media all and (max-width: 768px) {
  .xinja-hero-content-cnt {
    position: relative;
    margin: auto;
    text-align: center;
  }
}

.xinja-thanks-registering {
  text-align: left;
  font-family: 'latoregular';
  font-size: 40px;
  margin-bottom: 34px;
}

.xinja-return-message-subscription {
  line-height: 35px;
  font-family: 'latolight';
  font-size: 20px;
  text-align: left;
  max-width: 780px;
  margin: auto;
  line-height: 1.5;
  margin-bottom: 20px;
}

.xinja-pink {
  color: #e41970;
}

.xinja-text-grey {
  color: #3d3d3c;
}

.xinja-landing-page-content {
  width: 100%;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media all and (max-width: 1024px) {
  .xinja-text-center-md {
    text-align: center;
  }
}

@media all and (max-width: 768px) {
  .xinja-text-center-sm {
    text-align: center;
  }
}

.xinja-36 {
  font-size: 36px;
}

.xinja-18 {
  font-size: 18px;
}

.xinja-back-grey {
  background-color: #f2f1f2;
}

.important-white {
  color: #fff !important;
}

#xinja-sec-3 {
  background: url(/images/landing-pages/xinja_nov_18_landing_page_general_section_3_option_1_2000x.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
}

@media all and (max-width: 600px) {
  #xinja-sec-3 {
    padding: 50px 0;
  }
}

.xinja-list {
  list-style: disc inside;
}

@media all and (max-width: 1024px) {
  .xinja-list {
    padding-left: 0;
  }
}

.xinja-list li:nth-child(1), .xinja-list li:nth-child(5), .xinja-list li:nth-child(9) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_LIGHT_BLUE-02.svg");
}

.xinja-list li:nth-child(2), .xinja-list li:nth-child(6), .xinja-list li:nth-child(10) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_LILAC-13.svg");
}

.xinja-list li:nth-child(3), .xinja-list li:nth-child(7) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_NAVY_BLUE-03.svg");
}

.xinja-list li:nth-child(4), .xinja-list li:nth-child(8) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_PINK-01.svg");
}

.xinja-list li {
  margin-bottom: 10px;
  line-height: 1.7rem;
}

.xinja-list li::before {
  content: "";
  padding-left: 10px;
}

.xinja-list-2 {
  list-style: disc inside;
}

.xinja-list-2 li:nth-child(1), .xinja-list-2 li:nth-child(5), .xinja-list-2 li:nth-child(9) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_LIGHT_BLUE-02.svg");
}

.xinja-list-2 li:nth-child(2), .xinja-list-2 li:nth-child(6), .xinja-list-2 li:nth-child(10) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_LILAC-13.svg");
}

.xinja-list-2 li:nth-child(3), .xinja-list-2 li:nth-child(7) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_NAVY_BLUE-03.svg");
}

.xinja-list-2 li:nth-child(4), .xinja-list-2 li:nth-child(8) {
  list-style-image: url("/images/landing-pages/xinja-ninja_emojis_PINK-01.svg");
}

.xinja-list-2 li {
  margin-bottom: 10px;
  line-height: 1.7rem;
}

.xinja-list-2 li::before {
  content: "";
  padding-left: 10px;
}

.xinja-empty-container {
  padding: 10px 0;
}

@media all and (max-width: 1024px) {
  .xinja-empty-container {
    padding: 0;
  }
}

@media all and (max-width: 768px) {
  .xinja-empty-container {
    padding: 30px 0;
  }
}

@media all and (max-width: 600px) {
  .xinja-empty-container {
    padding: 60px 0;
  }
}

.xinja-raise-empty-container {
  padding: 10px 0 100px;
}

@media all and (max-width: 1024px) {
  .xinja-raise-empty-container {
    padding: 70px 0 100px;
  }
}

.xinja-2-container {
  padding: 70px 0 40px;
}

@media all and (max-width: 768px) {
  .xinja-2-container {
    padding: 10px 0 40px;
  }
}

#xinja-sec-4 {
  padding: 100px 0;
}

@media all and (max-width: 600px) {
  #xinja-sec-4 {
    padding: 50px 0;
  }
}

.xinja-sec-5-content {
  padding: 100px 0;
}

@media all and (max-width: 600px) {
  .xinja-sec-5-content {
    padding: 50px 0;
  }
}

#xinja-last-form-sec {
  padding: 100px 0;
}

@media all and (max-width: 600px) {
  #xinja-last-form-sec {
    padding: 50px 0;
  }
}

.xinja-video-wrap-2 .xinja-mobile-video-wrap-2 {
  position: absolute;
  top: 70px;
  right: 23px;
  width: 306px;
  margin: 0 auto;
  margin-top: 50px;
}

@media all and (max-width: 1440px) {
  .xinja-video-wrap-2 .xinja-mobile-video-wrap-2 {
    top: 40px;
  }
}

@media all and (max-width: 768px) {
  .xinja-video-wrap-2 .xinja-mobile-video-wrap-2 {
    position: relative;
    margin: auto;
    right: 0;
    top: 130px;
  }
}

.xinja-video-wrap-2 .xinja-mobile-video-wrap-2 .xinja-thumb-2 {
  width: 306px;
  height: 617px;
  max-width: none;
}

.xinja-video-wrap-2 .xinja-mobile-video-wrap-2 .xinja-video-2 {
  position: absolute;
  top: 70px;
  width: 268px;
  left: 19px;
  height: 475px;
}

.xinja-video-wrap {
  position: absolute;
  overflow: hidden;
  top: 130px;
  width: 317px;
  right: 23px;
}

@media all and (max-width: 1440px) {
  .xinja-video-wrap {
    top: 40px;
  }
}

@media all and (max-width: 768px) {
  .xinja-video-wrap {
    position: relative;
    margin: auto;
    width: 267px;
    right: 0;
    top: 130px;
  }
}

.xinja-video-wrap .xinja-thumb {
  position: relative;
  z-index: 2;
  margin: 0;
  pointer-events: none;
}

.xinja-video-wrap .xinja-thumb img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.xinja-video-wrap .xinja-video {
  position: absolute;
  left: 18px;
  top: 13px;
  right: 17px;
  bottom: 13px;
  background: #fff;
}

.xinja-video-wrap .xinja-video iframe {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
