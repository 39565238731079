/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
#abs-hero-banner {
  background: url(/images/landing-pages/abs-bg-1-min.jpeg) no-repeat center;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 70px);
  display: table;
  position: relative;
}

@media all and (max-width: 1024px) {
  #abs-hero-banner {
    height: 0;
  }
}

#abs-hero-banner .abs-hero-layer {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: calc(100vh - 70px);
  display: table;
  padding: 70px 0;
}

@media all and (max-width: 1440px) {
  #abs-hero-banner .abs-hero-layer {
    padding: 20px 0;
  }
}

@media all and (max-width: 1024px) {
  #abs-hero-banner .abs-hero-layer {
    padding: 140px 0;
  }
}

@media all and (max-width: 500px) {
  #abs-hero-banner .abs-hero-layer {
    padding: 0;
  }
}

#abs-hero-banner .abs-hero-layer .abs-logo {
  margin-bottom: 20px;
  width: 300px;
}

@media all and (max-width: 1440px) {
  #abs-hero-banner .abs-hero-layer .abs-logo {
    width: 230px;
    margin-top: 20px;
  }
}

@media all and (max-width: 500px) {
  #abs-hero-banner .abs-hero-layer .abs-logo {
    width: 160px;
  }
}

#abs-hero-banner .abs-hero-title {
  font-family: 'latoregular';
  font-size: 36px;
  margin-bottom: 10px;
  line-height: 2.7rem;
}

@media all and (max-width: 1440px) {
  #abs-hero-banner .abs-hero-title {
    font-size: 27px;
  }
}

@media all and (max-width: 768px) {
  #abs-hero-banner .abs-hero-title {
    font-size: 22px;
    line-height: 2rem;
    margin-bottom: 0;
  }
}

#abs-quote-sec {
  background: url(/images/landing-pages/abs-background_still-min.png) no-repeat;
  background-size: contain;
  background-position: 100% 100%;
  padding: 140px 0;
}

@media all and (max-width: 500px) {
  #abs-quote-sec {
    padding: 70px 0;
  }
}

#abs-quote-sec .abs-quote-header {
  font-size: 48px;
  line-height: 1.1;
  color: #bca090;
  margin: 0 0 20px 0;
}

@media all and (max-width: 500px) {
  #abs-quote-sec .abs-quote-header {
    font-size: 30px;
  }
}

#abs-quote-sec .abs-quote-subheader {
  font-size: 28px;
  line-height: 1.4;
  color: #bca090;
  margin-bottom: 40px;
}

@media all and (max-width: 500px) {
  #abs-quote-sec .abs-quote-subheader {
    font-size: 20px;
  }
}

#abs-quote-sec .abs-quote-info {
  font-size: 20px;
  line-height: 1.5;
  color: #bca090;
  margin-bottom: 20px;
}

@media all and (max-width: 500px) {
  #abs-quote-sec .abs-quote-info {
    font-size: 17px;
  }
}

#abs-quote-sec .abs-border-left {
  border-left: 1px solid #423a35;
  padding-left: 90px;
}

@media all and (max-width: 1024px) {
  #abs-quote-sec .abs-border-left {
    padding-left: 20px;
  }
}

#abs-first-carousel {
  background: url(/images/landing-pages/abs-bg-2-min.jpeg) no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  min-height: 50vh;
}

@media all and (max-width: 1024px) {
  #abs-first-carousel {
    min-height: 30vh;
  }
}

@media all and (max-width: 768px) {
  #abs-first-carousel {
    background-attachment: initial;
  }
}

#abs-about-sec {
  background: url(/images/landing-pages/home-restaurant-bg-min.png) no-repeat;
  background-color: #fff;
  background-position: 100% 100%;
  background-size: cover;
  padding: 140px 0;
}

@media all and (max-width: 500px) {
  #abs-about-sec {
    padding: 70px 0;
  }
}

#abs-about-sec .abs-bottlelineup-img {
  width: 100%;
  margin-bottom: 50px;
}

#abs-about-sec .abs-fisrt-info-row {
  margin-bottom: 80px;
}

@media all and (max-width: 1024px) {
  #abs-about-sec .abs-fisrt-info-row {
    margin-bottom: 0;
  }
}

#abs-about-equitise-carousel {
  background: url(/images/landing-pages/abs-bg-3-min.jpeg) no-repeat center;
  background-attachment: fixed;
  width: 100%;
  background-size: cover;
  position: relative;
}

@media all and (max-width: 768px) {
  #abs-about-equitise-carousel {
    background-attachment: initial;
    background-position: 20%;
  }
}

#abs-about-equitise-carousel .abs-paralax-layer {
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  padding-top: 70px;
  padding-bottom: 70px;
}

#abs-last-form-sec {
  background: #1d1d1d;
  padding-top: 70px;
  padding-bottom: 70px;
}

.abs-text-color {
  color: #bca090;
}

.abs-text-darker-color {
  color: #232222;
}

.abs-button-style {
  background-color: #8a7468;
  border: 1px solid #8a7468;
  transition: .5s;
}

.abs-button-style:hover {
  background-color: #372f2c;
  border: 1px solid #372f2c;
}

.abs-link {
  color: #8a7468;
  text-decoration: underline;
}

.abs-link:hover {
  text-decoration: underline;
  color: #372f2c;
}

.abs-white-link {
  color: #fff;
  text-decoration: underline;
}

.abs-white-link:hover {
  color: #8a7468;
  text-decoration: underline;
}

.abs-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1200px) {
  .abs-flex-center {
    display: block;
  }
}
