/* General variables */
/* Default rightcol widget */
/* Header & Home Top Wrapper variables */
/* Home Sections variables */
/* Offer tiles variables */
/* Additional offers section variables */
/* Warning statement variables */
/* Register & Login pages variables */
/* Settings pages variables */
/* User profile variables */
/* Create syndicate variables */
/* My syndicates colors */
/* Browse offers variables */
/* Offer details variables */
/* Footer variables */
.hop-top-banner-ctn {
  padding-top: 80px;
  position: relative;
  background-color: #fff;
}

@media all and (max-width: 1280px) {
  .hop-top-banner-ctn {
    padding-top: 46px;
    padding-bottom: 80px;
  }
}

@media all and (max-width: 1024px) {
  .hop-top-banner-ctn {
    padding-bottom: 0;
  }
}

@media all and (max-width: 736px) {
  .hop-top-banner-ctn {
    padding-top: 19px;
  }
}

.hop-top-banner-ctn .hop-layer {
  position: absolute;
  background-color: rgba(1, 1, 1, 0.3);
  height: 750px;
  top: 0;
  width: 100%;
}

@media all and (max-width: 736px) {
  .hop-top-banner-ctn .hop-layer {
    height: 830px;
  }
}

.hop-top-banner-ctn .vertical-align {
  display: flex;
  align-items: center;
}

.hop-top-banner-ctn .hop-logo {
  margin: 16px auto 50px;
}

@media all and (max-width: 736px) {
  .hop-top-banner-ctn .hop-logo {
    margin: 30px auto;
  }
}

.hop-top-banner-ctn .hop-red-bk-color {
  background-color: #e74444;
  border: none;
}

.hop-top-banner-ctn ::placeholder {
  color: #fff;
}

.iphone-slider {
  width: 344px;
  padding: 78px 21px 103px 21px;
  background-image: url(/images/top-banner-iphone.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}

@media all and (max-width: 1280px) {
  .iphone-slider {
    width: 284px;
    padding: 64px 17px 103px 17px;
  }
}

.hop-top-banner-ctn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 750px;
  background-image: url(/images/hop-top-banner-min-min.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media all and (max-width: 736px) {
  .hop-top-banner-ctn::before {
    height: 830px;
  }
}

.hop-red-bk-color:hover {
  background-color: #ec6d6d;
  transition: 3s;
}

.hop-red-color {
  color: #e74444;
}

.hop-red-color:hover {
  text-decoration: underline;
}

.hop-second-section {
  background-color: #fff;
  padding: 67px 0 110px;
}
